import { useMutation, UseMutationResult, useQueryClient } from 'react-query';

import { useAuth } from 'src/hooks';

import { useProgressStore } from 'src/hooks/useProgressStore';
import { syncProvider } from 'src/utils/urls';

export type TResult = any;
export type TError = any;
export type TVariable = {
  id: string;
};

export type TArguments = {
  id: string;
  token: string;
  tokenType: string;
};

async function updateSyncProvider({
  id,
  token,
  tokenType,
}: TArguments): Promise<TResult> {
  return await fetch(syncProvider(id), {
    method: 'POST',
    headers: {
      Authorization: `${tokenType} ${token}`,
    },
  });
}

function useSyncProvider(): UseMutationResult<
  TResult,
  TError,
  TVariable,
  unknown
> {
  const queryClient = useQueryClient();
  const { token, tokenType } = useAuth();
  const { setLoading } = useProgressStore();

  return useMutation(
    async ({ id }: TVariable) => {
      setLoading('isUpdateProviderLoading', true);
      const result = await updateSyncProvider({
        id,
        token,
        tokenType,
      });
      queryClient.invalidateQueries('get-resource-list');
      return result;
    },
    {
      onSettled: () => {
        setLoading('isUpdateProviderLoading', false);
      },
    },
  );
}

export { useSyncProvider };
