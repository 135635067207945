//Note: This component is used to render the textfields in basic details & coverage request deatils section.

import React, { ReactElement } from 'react';

import { TextField } from 'src/ui/components';

import { TextboxPropType } from '../types/requestType';

function renderTextField({
  id,
  type,
  value,
  label,
  key,
  disabled,
  required,
  onChange,
}: TextboxPropType): ReactElement {
  return (
    <TextField
      id={id}
      type={type}
      label={label}
      value={value}
      disabled={disabled}
      required={required}
      min={type === 'number' ? 0 : ''}
      onChange={e => {
        onChange(key, e.target.value);
      }}
      onKeyDown={e => {
        if (
          (e.key.toLocaleLowerCase() === 'e' || e.key === '-') &&
          type === 'number'
        )
          e.preventDefault();
      }}
    />
  );
}

export { renderTextField };
