import dayjs from 'dayjs';

import { CustomEmailNotificationSettingsType } from 'src/types/emailNotification';

const timerPickerDefaultState: CustomEmailNotificationSettingsType = {
  NotifyTime: '06:00',
  NotifyDateTime: dayjs(new Date(0)).utc().format(),
  EpochTimeValue: 0,
  Id: 0,
  UserEmailNotificationId: null,
};

const dailyAtSettingsId = 8;
const maximumPickerLength = 4;
const noDocumentSettingsIds: Array<number | string> = [1, 7, '1', '7'];

//convert the custom notify time to unix timestamp
function epocValueConversion(time: string) {
  const currentDate = dayjs();
  const [hours, minutes] = time.split(':');
  const dateTime = currentDate
    .set('hour', Number(hours))
    .set('minute', Number(minutes));
  return dateTime.unix();
}

export {
  timerPickerDefaultState,
  dailyAtSettingsId,
  noDocumentSettingsIds,
  maximumPickerLength,
  epocValueConversion,
};
