import React, { ReactElement } from 'react';

type SkeletonProps = { count?: number };

export function Skeleton({ count = 1 }: SkeletonProps): ReactElement {
  return (
    <div className="space-y-2">
      {Array(count)
        .fill(null)
        .map((_, index) => (
          <div
            key={index}
            className="rounded bg-white shadow dark:bg-neutral-800"
          >
            <div className="grid animate-pulse grid-cols-2 gap-5 p-2 sm:grid-cols-3 sm:p-3 md:grid-cols-4 lg:grid-cols-6">
              <div className="h-2 rounded bg-gray-200 dark:bg-neutral-600"></div>
              <div className="h-2 rounded bg-gray-200 dark:bg-neutral-600"></div>
              <div className="h-2 rounded bg-gray-200 dark:bg-neutral-600"></div>
              <div className="h-2 rounded bg-gray-200 dark:bg-neutral-600"></div>
              <div className="hidden h-2 rounded bg-gray-200 dark:bg-neutral-600 sm:block"></div>
              <div className="hidden h-2 rounded bg-gray-200 dark:bg-neutral-600 sm:block md:hidden lg:block"></div>
              <div className="hidden h-2 rounded bg-gray-200 dark:bg-neutral-600 md:block lg:block"></div>
              <div className="hidden h-2 rounded bg-gray-200 dark:bg-neutral-600 md:block lg:block"></div>
              <div className="hidden h-2 rounded bg-gray-200 dark:bg-neutral-600 md:block lg:block"></div>
              <div className="hidden h-2 rounded bg-gray-200 dark:bg-neutral-600 lg:block"></div>
              <div className="hidden h-2 rounded bg-gray-200 dark:bg-neutral-600 lg:block"></div>
              <div className="hidden h-2 rounded bg-gray-200 dark:bg-neutral-600 lg:block"></div>
            </div>
          </div>
        ))}
    </div>
  );
}
