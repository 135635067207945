import { create } from 'zustand';
import produce from 'immer';
import dayjs from 'dayjs';

import { fetchAccountInfo } from 'src/features/AddEditUsers/store/apiStore';
import { fetchExtraCoverageRequestInfo } from './apiStore';

import {
  isEndTimeAfterStartTime,
  hasAllValuesPresent,
  requestDetailsValidation,
  isPastDate,
  timeDifferenceInHours,
} from '../utils/storeHelper';
import {
  dateFormat,
  errMsgForTimeDifference,
} from '../utils/coverageRequestConstants';
import {
  getCurrentDate,
  getOffset,
  getTimeZone,
  isDateTimeInPast,
} from 'src/utils/datepickerHelper';
import { formattedPhoneNumber } from 'src/utils/common';

import { AccountInfo } from 'src/types/AccountInfo';
import { GetRequestType, RequestStore } from '../types/requestStoreTypes';
import { ExtraCoverageResponseType } from '../types/saveExtraCoverageRequest';
import { findTimeZone } from '../utils/coverageRequestHelper';

export const useExtracoverageRequestStore = create<RequestStore>(
  (set, get) => ({
    isLoading: false,
    accountsInfo: [], // selected account ids
    selectedAccountsDetails: [], // all details of selected accounts
    accountList: [],
    userMode: '',
    isSaveClicked: false,
    postAlertOnSave: false,
    sendEmailOnSave: false,
    extraCoverageRequestDet: {},
    hasBasicDetailsWithErr: false,
    isAdmin: false,
    isProtocallUser: false,
    isAlertPreviewModal: false,
    alertModalInfo: null,
    previousAlertModalInfo: null,
    isFromGrid: false,
    showPhnNumberError: false,
    restrictTimezoneChange: false,
    defaultEmail: {
      Recipients: '',
      Subject: '',
      Body: '',
      AdditionalRecipients: '',
      SystemEmailAddress: '',
    },
    previousdefaultEmail: {
      Recipients: '',
      Subject: '',
      Body: '',
      AdditionalRecipients: '',
      SystemEmailAddress: '',
    },
    isShowSendApproval: false,
    showError: false,
    warning: '',
    previousPostAccountAlert: false,
    previousSendEmail: false,
    hasSendEmailWarning: false,
    hasPostAccountAlertWarning: false,
    isEnteredBy: true,
    isOtherReceipents: true,
    isSystemConfirmation: true,
    filteredData: [],
    initialExtracoverageState: {},

    isSendEmailFieldModified: false,
    isPostAlertFieldModified: false,
    isUpdateApiCalled: false,
    isEditorModified: false,

    // Function called on component mount
    getRequestDetails: async (params: Partial<GetRequestType>) => {
      const {
        id,
        userMode,
        token,
        tokenType,
        email,
        isAdmin,
        isProtocallUser,
      } = params;

      set(
        produce(state => {
          state.isLoading = true;
          if (state.extraCoverageRequestDet === undefined) {
            state.extraCoverageRequestDet = {
              ReturnEmailAddress: email ? email : '',
            };
          } else {
            state.extraCoverageRequestDet['ReturnEmailAddress'] = email
              ? email
              : email;
          }
          state.isAdmin = isAdmin;
          state.isProtocallUser = isProtocallUser;
        }),
      );
      get().fetchAccounts(token, tokenType);
      set(
        produce(state => {
          state.extraCoverageRequestDet['CountryId'] = '1';
        }),
      );

      if (params.userMode !== '') {
        const reqestDetails = await fetchExtraCoverageRequestInfo({
          id,
          token,
          tokenType,
        });

        set(
          produce(state => {
            state.initialExtracoverageState = reqestDetails;
            state.restrictTimezoneChange = userMode === 'Copy' ? false : true;
            state.accountsInfo = reqestDetails?.Accounts
              ? [...reqestDetails?.Accounts.map(({ Id }) => Id)]
              : [];
            state.extraCoverageRequestDet = {
              ...reqestDetails,
              StartTime: reqestDetails?.StartTime,
              EndTime: reqestDetails?.EndTime,
              AccountAlertPosted:
                params.userMode == 'Copy'
                  ? false
                  : reqestDetails?.AccountAlertPosted,
              EmailSent:
                params.userMode == 'Copy' ? false : reqestDetails?.EmailSent,
              PhoneNumber:
                reqestDetails?.CountryId === null
                  ? formattedPhoneNumber(reqestDetails?.PhoneNumber, 1)
                  : reqestDetails?.PhoneNumber,
              CountryId: reqestDetails?.CountryId ?? 1,
              TimeZoneId:
                reqestDetails && userMode === 'Copy'
                  ? findTimeZone(reqestDetails)
                  : reqestDetails?.TimeZoneId,
            };
            state.previousPostAccountAlert = reqestDetails?.AccountAlertPosted;
            state.previousSendEmail = reqestDetails?.EmailSent;
          }),
        );
      }

      get().getBasicDetailsWarnings(get().extraCoverageRequestDet?.TimeZone);
      get().getBasicDetailsErrors(get().extraCoverageRequestDet?.TimeZone);
    },

    updateUserMode: (value: string) => {
      set(
        produce(state => {
          state.userMode = value;
        }),
      );
    },

    setRestrictTimezoneChange: (value: boolean) => {
      set(
        produce(state => {
          state.restrictTimezoneChange = value;
        }),
      );
    },

    updateFilteredData: (value: any) => {
      set(
        produce(state => {
          state.filteredData = [...value];
        }),
      );
    },

    updateAccountInfo: (value: Array<number>) => {
      set(state => ({
        accountsInfo: [...value],
      }));
    },

    // fetch all accounts based on pagination
    fetchAccounts: (
      token: string | undefined,
      tokenType: string | undefined,
      id?: string,
    ) => {
      fetchAccountInfo({
        token,
        tokenType,
        // id,
      }).then((account: Array<AccountInfo>) => {
        set(
          produce(state => {
            state.accountList = [...account];
            state.isLoading = false;
          }),
        );
      });
    },

    setShowPhnNumberError: (value: boolean) => {
      set(
        produce(state => {
          state.showPhnNumberError = value;
        }),
      );
    },

    setSelectedAccountDetails: (value: Array<AccountInfo>) => {
      set(
        produce(state => {
          const tempValues = value?.map(item => {
            const val = get().filteredData?.find(
              (fItem: any) => fItem?.AccountId === item?.AccountId,
            );

            if (val !== undefined) {
              return {
                ...item,
                TimeZone: val?.TimeZone,
                TimeZoneId: val?.TimeZoneId,
              };
            } else {
              let val = [];
              val = get().selectedAccountsDetails?.find(
                (fItem: any) => fItem?.AccountId === item?.AccountId,
              );
              if (val === undefined && get().userMode !== '') {
                const accounts = get().initialExtracoverageState?.Accounts;
                val = accounts?.find(
                  (fItem: any) => fItem?.Id === item?.AccountId,
                );
              }
              return val;
            }
          });

          state.selectedAccountsDetails = [...tempValues];
        }),
      );
      const timezoneValue = get().selectedAccountsDetails[0]?.TimeZoneId
        ? get().selectedAccountsDetails[0]?.TimeZoneId
        : null;
      const allSame = get().selectedAccountsDetails?.every(
        obj => obj['TimeZoneId'] === timezoneValue,
      );

      const timeZone = allSame && timezoneValue ? timezoneValue : -1;
      if (get().restrictTimezoneChange === false) {
        set(
          produce(state => {
            state.extraCoverageRequestDet.TimeZoneId = timeZone;
          }),
        );
      }
    },

    isAllRequiredFieldsFilled: (type: string): boolean => {
      switch (type) {
        case 'account-info':
          return get().accountsInfo && get().accountsInfo?.length > 0;
        case 'basic-details':
          return !hasAllValuesPresent(
            get().extraCoverageRequestDet,
            get().isProtocallUser,
          );
        case 'request-details':
          return !requestDetailsValidation(get().extraCoverageRequestDet);

        default:
          return false;
      }
    },

    updateExtraCoverageReqDet: (
      key: keyof ExtraCoverageResponseType,
      value: any,
    ) => {
      set(
        produce(state => {
          if (get().extraCoverageRequestDet === undefined) {
            state.extraCoverageRequestDet = {
              ...state?.extraCoverageRequestDet,
              key: value,
            };
          }
          state.extraCoverageRequestDet[key] = value;
        }),
      );
    },

    getBasicDetailsWarnings: (timeZoneValue: any): string => {
      const offset = timeZoneValue ? getOffset(timeZoneValue) : getTimeZone();
      let warningMsg = '';
      if (
        get().extraCoverageRequestDet?.ExtraCoverageDate &&
        get().isProtocallUser
      ) {
        const dateValue = `${dayjs(
          get().extraCoverageRequestDet?.ExtraCoverageDate,
        ).format('MM/DD/YYYY')} ${get().extraCoverageRequestDet.StartTime}`;

        let extraCoveragedate = dayjs(
          get().extraCoverageRequestDet?.ExtraCoverageDate,
        ).format(dateFormat);

        if (
          Date.parse(extraCoveragedate) < Date.parse(getCurrentDate(offset))
        ) {
          warningMsg = 'Please note: Alert dates are in the past.';
        } else if (
          get().extraCoverageRequestDet?.StartTime &&
          isDateTimeInPast(dateValue, offset)
        ) {
          warningMsg = 'Please note: Alert time is in the past';
        }
      }
      return warningMsg;
    },

    getBasicDetailsErrors: (timezone: any): string => {
      const offset =
        timezone !== undefined ? getOffset(timezone) : getTimeZone();
      const dateValue = `${dayjs(
        get().extraCoverageRequestDet?.ExtraCoverageDate,
      ).format('MM/DD/YYYY')} ${get().extraCoverageRequestDet?.StartTime}`;
      const requestInfo = get()?.extraCoverageRequestDet;
      let validationMsg = [];
      if (
        requestInfo &&
        requestInfo?.ExtraCoverageDate &&
        isPastDate(dayjs(requestInfo?.ExtraCoverageDate).format(dateFormat)) &&
        !get().isProtocallUser
      ) {
        validationMsg.push('The extra coverage date cannot be in the past');
      }
      if (
        get().extraCoverageRequestDet?.StartTime &&
        isDateTimeInPast(dateValue, offset) &&
        !get().isProtocallUser
      ) {
        validationMsg.push('The extra coverage time cannot be in the past');
      }
      if (
        requestInfo &&
        requestInfo?.StartTime !== '' &&
        requestInfo?.EndTime !== ''
      ) {
        if (
          isEndTimeAfterStartTime(requestInfo.StartTime, requestInfo.EndTime)
        ) {
          validationMsg.push('End time cannot be less than start time');
        }
      }

      if (
        requestInfo &&
        !get().isAdmin &&
        requestInfo?.ExtraCoverageDate &&
        requestInfo?.StartTime &&
        validationMsg.length === 0
      ) {
        if (
          timeDifferenceInHours(
            dayjs(requestInfo.ExtraCoverageDate).format(dateFormat),
            requestInfo.StartTime,
            offset,
          ) < 24
        ) {
          validationMsg.push(errMsgForTimeDifference);
        }
      }
      if (validationMsg.length > 0) {
        return validationMsg.join(', ');
      }
      return '';
    },

    deleteCoverageDetails: () => {
      set((state: any) => {
        return {
          ...state,
          extraCoverageRequestDet: undefined,
          accountsInfo: [],
          accountList: [],
          userMode: '',
          isSaveClicked: false,
          postAlertOnSave: false,
          sendEmailOnSave: false,
          isFromGrid: false,
          showPhnNumberError: false,
          restrictTimezoneChange: false,
          isAlertPreviewModal: false,
          alertModalInfo: null,
          previousAlertModalInfo: null,
          defaultEmail: {
            Recipients: '',
            Subject: '',
            Body: '',
            AdditionalRecipients: '',
            SystemEmailAddress: '',
          },
          previousdefaultEmail: {
            Recipients: '',
            Subject: '',
            Body: '',
            AdditionalRecipients: '',
            SystemEmailAddress: '',
          },
          isShowSendApproval: false,
          isEnteredBy: true,
          isOtherReceipents: true,
          isSystemConfirmation: true,
          showError: false,
          warning: '',
          previousPostAccountAlert: false,
          previousSendEmail: false,
          hasSendEmailWarning: false,
          hasPostAccountAlertWarning: false,
          isRequestModified: false,
          hasBasicDetailsWithErr: false,
          isPostAlertFieldModified: false,
          isSendEmailFieldModified: false,
          isUpdateApiCalled: false,
          isEditorModified: false,
        };
      });
    },

    setAlertModalInfo: (key: string, value: any) => {
      set(
        produce(state => {
          state.alertModalInfo = { ...get().alertModalInfo, [key]: value };
        }),
      );
    },

    updateStoreByKey: (key: keyof RequestStore, value: any) => {
      set(state => ({
        ...state,
        [key]: value,
      }));
    },
  }),
);
