import { getHomeSections } from 'src/utils/urls';

import { TVariables, ApiSectionType } from '../../types/apiStoreType';

async function fetchHomeSection({
  token,
  tokenType,
}: Partial<TVariables>): Promise<Array<ApiSectionType>> {
  const response = await fetch(getHomeSections, {
    method: 'GET',
    headers: {
      Authorization: `${tokenType} ${token}`,
      'Content-Type': 'application/json',
    },
  });
  return response.json();
}

export { fetchHomeSection };
