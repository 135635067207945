import React, { ReactElement, useMemo } from 'react';

import { Skeleton } from 'src/ui/components';

import { useGetColleagues } from '../api';

import { ColleagueType } from '../types/homeSectionType';

function MyCollegues(): ReactElement {
  const { data: collegues, isLoading } = useGetColleagues();

  const colleguesList = useMemo(() => {
    const sortedList =
      collegues &&
      collegues
        ?.map(item => {
          return {
            ...item,
            Name: item.FirstName + ' ' + item.LastName,
          };
        })
        ?.sort((a, b) => a.Name.localeCompare(b.Name));
    return sortedList;
  }, [collegues]);

  function renderCollegueList(
    item: ColleagueType,
    index: number,
  ): ReactElement {
    return (
      <div className="px-4 py-2">
        <p className="prevent-text-breakout grow font-semibold leading-tight">
          {item?.FirstName} {''} {item?.LastName}
        </p>
        <small
          className="prevent-text-breakout grow select-all text-sm leading-tight text-gray-400"
          key={`${item?.Id}${index}`}
        >
          {item?.Email}
        </small>
      </div>
    );
  }

  return (
    <div className="relative flex min-h-[300px] shrink-0 flex-col rounded-lg bg-white shadow dark:bg-neutral-800">
      <h4 className="border-b border-light-light px-2 py-2 text-lg font-semibold dark:border-dark-medium md:px-4">
        My colleagues
      </h4>
      <div className="max-h-96 min-h-0 grow divide-y divide-light-light overflow-y-auto overscroll-contain text-sm dark:divide-dark-medium lg:max-h-full">
        {!isLoading ? (
          colleguesList?.length ? (
            colleguesList?.map((item, index) => renderCollegueList(item, index))
          ) : (
            <div className="absolute inset-0 flex items-center justify-center">
              <p className=" text-center text-sm text-gray-500 dark:text-neutral-400">
                No information available
              </p>
            </div>
          )
        ) : (
          isLoading && <Skeleton count={8} />
        )}
      </div>
    </div>
  );
}

export { MyCollegues };
