export const statusOptions = [
  { id: 1, label: 'All', value: 'all' },
  { id: 2, label: 'Active', value: 'active' },
  { id: 3, label: 'Invitation sent', value: 'invitation-sent' },
  { id: 4, label: 'Invitation expired', value: 'invitation-expired' },
];

export const tagStatusOptions = [
  { id: 0, label: 'Please select', value: 'Please select' },
  { id: 1, label: 'Tag 11', value: 'Tag11' },
  { id: 2, label: 'Tag 12', value: 'Tag12' },
  { id: 3, label: 'Tag 13', value: 'Tag13' },
];

export const downloadOptions = [
  { id: 1, label: 'HTML', value: 'html' },
  { id: 2, label: 'PDF', value: 'pdf' },
  { id: 3, label: 'DOC', value: 'doc' },
];

export const stickyNoteMaxLength = 121;

export const locList = [
  { value: 0, label: 'Select All' },
  {
    value: 1,
    label: 'Blank',
  },
  {
    value: 2,
    label: 'Routine',
  },
  {
    value: 3,
    label: 'Urgent',
  },
  {
    value: 4,
    label: 'Emergent',
  },
];

export const sortList = [
  {
    value: 0,
    label: 'Call ID',
  },
  {
    value: 1,
    label: 'Case ID',
  },
  {
    value: 4,
    label: 'CID',
  },
  {
    value: 5,
    label: 'PID',
  },
  {
    value: 2,
    label: 'Start date',
  },
  {
    value: 3,
    label: 'Uploaded date',
  },
  {
    value: 6,
    label: 'Caller first name',
  },
  {
    value: 7,
    label: 'Caller last name',
  },
  {
    value: 8,
    label: 'POC first name',
  },
  {
    value: 9,
    label: 'POC last name',
  },
  {
    value: 10,
    label: 'Call type',
  },
  {
    value: 11,
    label: 'Level of care',
  },
  {
    value: 12,
    label: 'Clinical outcome',
  },
  {
    value: 14,
    label: 'Sticky notes',
  },
  {
    value: 13,
    label: 'Tag name',
  },
];

export const activeStyles =
  "after:pointer-events-none after:absolute after:inset-0 after:rounded-md after:border-2 after:border-primary after:content-['']";

export const tagStatusMsg: string =
  'Tag status applied to a document will be visible for all other users with access to the document. Do you wish to continue?';
