import { useMutation, UseMutationResult, useQueryClient } from 'react-query';

import { useAuth } from 'src/hooks';
import { useProgressStore } from 'src/hooks/useProgressStore';

import { changeUserEmail } from 'src/utils/urls';

export async function changeEmail({
  requestData,
  token,
  tokenType,
}: {
  requestData: any;
  token: string;
  tokenType: string;
}): Promise<TResult> {
  const response = await fetch(changeUserEmail, {
    method: 'POST',
    headers: {
      Authorization: `${tokenType} ${token}`,
      'content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
    },
    body: requestData,
  });

  if (response.status === 200 && response.redirected === false)
    return 'Success';
  if (response.status === 200 && response.redirected === true)
    return 'An error has occured';
  else return response.json();
}

export type ValidationError = { Message: string };
export type TResult = String | ValidationError;
export type TVariables = {
  UserId: string;
  NewEmailId: string;
  fromSettings?: boolean;
};
export type TError = {
  message: string;
};
export type TSnapshot = unknown;

function setRequestData(params: TVariables): FormData {
  const formData: any = new URLSearchParams();
  formData.append('UserId', `${params.UserId}`);
  formData.append('NewEmailId', `${params.NewEmailId}`);
  return formData;
}

function useChangeEmail(): UseMutationResult<
  TResult,
  TError,
  TVariables,
  TSnapshot
> {
  const { token, tokenType } = useAuth();
  const queryClient = useQueryClient();
  const { setLoading } = useProgressStore();
  return useMutation(
    async (params: TVariables) => {
      setLoading('isChangeEmailLoading', true);
      const requestData = setRequestData(params);
      const result = await changeEmail({
        requestData: requestData,
        token: token,
        tokenType: tokenType,
      });
      if (result === 'Success') {
        !params.fromSettings && queryClient.invalidateQueries('get_users_list');
        return result;
      } else throw result;
    },
    {
      onSettled: () => {
        setLoading('isChangeEmailLoading', false);
      },
    },
  );
}

export { useChangeEmail };
