import { useMutation, UseMutationResult, useQueryClient } from 'react-query';

import { useAuth } from 'src/hooks';

import { sendEmail } from 'src/utils/urls';

import { EmailType } from '../types/requestType';

type TArguments = {
  token: string;
  tokenType: string;
  params: TVariables;
};

type TVariables = EmailType & {
  id: number | undefined;
};

type TError = any;

type TResult = any;

async function postEmail({
  token,
  tokenType,
  params,
}: TArguments): Promise<TResult> {
  if (params?.id) {
    const response = await fetch(sendEmail(params.id), {
      method: 'POST',
      headers: {
        Authorization: `${tokenType} ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(params),
    });
    if (response.status === 200) return 'Success';
    else return response.json();
  }
  return Promise.resolve('');
}

function useSendEmail(): UseMutationResult<
  TResult,
  TError,
  TVariables,
  unknown
> {
  const queryClient = useQueryClient();
  const { token, tokenType } = useAuth();

  return useMutation(async (params: TVariables) => {
    if (params.id) {
      const result = await postEmail({
        token,
        tokenType,
        params: {
          ...params,
        },
      });
      queryClient.invalidateQueries('get_extra_coverage_requests');
      if (result === 'Success') return result;
      else throw result;
    }
  });
}

export { useSendEmail };
