import { create } from 'zustand';
import { produce } from 'immer';

import {
  addressInfo,
  formattedPhoneNumber,
  getComments,
} from 'src/utils/common';
import { fetchCaller, fetchCountryDetails } from 'src/store/apiStore';
import { fetchQuestionsAndSections } from './apiStore';

import { PersonRepoStoreType } from '../types/apiStoreType';

export const usePersonRepoStore = create<PersonRepoStoreType>((set, get) => ({
  personDetails: {},
  question: undefined,
  isPersonInformationLoading: false,
  userMode: 'Edit',
  isSaveClicked: false,
  showPrimaryNumberError: false,
  showSecondaryNumberError: false,
  countryInfo: [],

  //searched person data binding
  updatePersonInformation: async (
    accountId: number | undefined,
    personId: number | undefined,
    entityId: string | undefined,
    token: string,
    tokenType: string,
  ) => {
    set(
      produce(state => {
        state.isPersonInformationLoading = true;
      }),
    );

    const countryCode = await fetchCountryDetails({ token, tokenType });
    set(
      produce(state => {
        state.countryInfo = countryCode;
      }),
    );

    if (accountId && personId && entityId) {
      const response = await fetchCaller({
        token,
        tokenType,
        accountId,
        personId,
      });
      const primaryPhone = response?.PrimaryPhone;
      const secondaryPhone = response?.SecondaryPhone;
      set(
        produce(state => {
          state.personDetails = {
            ...response,
            Birthdate: response.Birthdate ? new Date(response.Birthdate) : null,
            PrimaryPhone: {
              ...response?.PrimaryPhone,
              Number:
                primaryPhone?.CountryId === null
                  ? formattedPhoneNumber(primaryPhone?.Number, 1)
                  : primaryPhone?.Number,
              CountryId: primaryPhone?.CountryId ?? 1,
              Comments: getComments(
                primaryPhone,
                primaryPhone?.CountryId,
                get().countryInfo,
              ),
            },
            SecondaryPhone: {
              ...response?.SecondaryPhone,
              Number:
                response?.SecondaryPhone?.CountryId === null
                  ? formattedPhoneNumber(response?.SecondaryPhone?.Number, 1)
                  : response?.SecondaryPhone?.Number,
              CountryId: response?.SecondaryPhone?.CountryId ?? 1,
              Comments: getComments(
                secondaryPhone,
                secondaryPhone?.CountryId,
                get().countryInfo,
              ),
            },
          };
        }),
      );

      const questions = await fetchQuestionsAndSections({
        token,
        tokenType,
        accountId,
        entityId,
      });

      set(
        produce(state => {
          state.question = questions;
        }),
      );
    }
    set(
      produce(state => {
        state.isPersonInformationLoading = false;
      }),
    );
  },

  updatePersonDetails: (key: string, value: any, personDetailKey: string) => {
    set(
      produce(state => {
        if (personDetailKey !== '') {
          if (
            state.personDetails === undefined ||
            state.personDetails === null
          ) {
            state.personDetails = {
              ...state?.personDetails,
            };
            state.personDetails = {
              ...state?.personDetails,
              [`${personDetailKey}`]: {
                [key]: value,
              },
            };
          } else {
            state.personDetails[`${personDetailKey}`] = {
              ...state?.personDetails[`${personDetailKey}`],
              [key]: value,
            };
          }
        } else {
          state.personDetails = {
            ...state?.personDetails,
            [key]: value,
          };
        }
      }),
    );
  },

  //searched address data binding
  updateAddressDetails: (item: any) => {
    const address = addressInfo(item);
    set(
      produce(state => {
        if (state?.personDetails === undefined) {
          state.personDetails = {
            ...state?.personDetails,
          };
          state.personDetails = {
            ['PrimaryAddress']: {
              ...address,
            },
          };
        } else {
          state.personDetails.PrimaryAddress = {
            ...address,
          };
        }
      }),
    );
  },

  //set a selected person
  setSelectedPerson: (person: any) => {
    set(
      produce(state => {
        state.selectedPerson = { ...person };
      }),
    );
  },

  //change user mode
  setUserMode: (mode: string) => {
    set(
      produce(state => {
        state.userMode = mode;
      }),
    );
  },

  setIsSaveClicked: (mode: boolean) => {
    set(
      produce(state => {
        state.isSaveClicked = mode;
      }),
    );
  },

  enableSaveBtn: (): boolean => {
    const firstName: string = get().personDetails?.FirstName?.trim();
    const lastName: string = get().personDetails?.LastName?.trim();
    return (
      firstName !== undefined &&
      firstName !== '' &&
      lastName !== undefined &&
      lastName !== ''
    );
  },

  setShowPhnValidation: (key: string, value: boolean) => {
    set({
      [key]: value,
    });
  },

  resetStore: () => {
    set(
      produce(state => {
        return {
          ...state,
          personDetails: {},
          userMode: 'Edit',
          isSaveClicked: false,
          showPrimaryNumberError: false,
          showSecondaryNumberError: false,
        };
      }),
    );
  },
}));
