import React, { ReactElement, useMemo } from 'react';

import { ResponseType } from 'src/features/PersonRepository/types/QuestionsTabType';

function AddressDisplay({
  question,
}: {
  question: ResponseType;
}): ReactElement {
  const addressDetails = useMemo(() => {
    if (question && question.Value) return JSON.parse(question.Value);
    else return null;
  }, [question]);

  return (
    <>
      {addressDetails !== null && (
        <div>
          <div>
            <label style={{ fontWeight: 700, marginRight: '8px' }}>
              Street 1:
            </label>
            <span>{addressDetails?.Street1}</span>
          </div>
          <div>
            <label style={{ fontWeight: 700, marginRight: '8px' }}>
              Street 2:
            </label>
            <span>{addressDetails?.Street2} </span>
          </div>
          <div>
            <label style={{ fontWeight: 700, marginRight: '8px' }}>
              Street 3:
            </label>
            <span>{addressDetails?.Street3} </span>
          </div>
          <div>
            <label style={{ fontWeight: 700, marginRight: '8px' }}>
              City, State/Province:
            </label>
            <span>
              {addressDetails?.City}

              {addressDetails?.StateProvince?.Name ? (
                <span>, {addressDetails?.StateProvince?.Name}</span>
              ) : (
                ''
              )}
            </span>
          </div>
          <div>
            <label style={{ fontWeight: 700, marginRight: '8px' }}>
              Zip/Postal Code:
            </label>
            <span>{addressDetails?.ZipPostalCode} </span>
          </div>
          <div>
            <label style={{ fontWeight: 700, marginRight: '8px' }}>
              County:
            </label>
            <span>{addressDetails?.County} </span>
          </div>
          <div>
            <label style={{ fontWeight: 700, marginRight: '8px' }}>
              Country:
            </label>
            <span>{addressDetails?.Country?.Name} </span>
          </div>
        </div>
      )}
    </>
  );
}

export { AddressDisplay };
