import { create } from 'zustand';

type PortalAlertStoreType = {
  pageParam: number;
  setPageParam: (val: number) => void;
};
export const portalAlertStore = create<PortalAlertStoreType>(set => ({
  pageParam: 0,
  setPageParam: (val: number) => {
    set(() => ({
      pageParam: val,
    }));
  },
}));
