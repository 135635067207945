import { ExtraCoverageRequestStatusType } from '../types/extraCoverageRequestLists';

function formatStatus(
  statusList: Array<ExtraCoverageRequestStatusType> | undefined,
  isFromList: boolean,
) {
  if (!statusList) return [];
  const defaultOption = { value: 0, label: 'Select All' };
  const optionValues = statusList.map(item => ({
    value: item?.Id,
    label: item?.Description,
  }));

  return isFromList ? [defaultOption, ...optionValues] : optionValues;
}

export { formatStatus };
