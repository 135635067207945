/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from 'react';
import DatePicker from 'rsuite/DatePicker';

import { shouldDisableDate } from 'src/utils/common';

type DatePickerType = {
  value: any;
  onChange: (e: any) => void;
  onKeyDown?: (e: any) => void;
  handleClose?: () => void;
  placeholder?: string;
  disabled?: boolean;
  container?: any;
};

function DateOfBirthPicker({
  value,
  onChange,
  handleClose,
  placeholder,
  disabled,
  container,
  onKeyDown,
}: DatePickerType) {
  //to close date picker on scroll
  const datePickerRef = useRef<any>();

  useEffect(() => {
    const handleScroll = () => {
      if (container && container?.current && datePickerRef?.current) {
        datePickerRef?.current.close();
      }
    };

    if (container && container?.current) {
      container?.current.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (container && container?.current) {
        container?.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  return (
    <div
      className="relative [&>*]:w-full"
      onKeyDown={e => onKeyDown && onKeyDown(e)}
    >
      <DatePicker
        onChange={e => onChange(e)}
        value={value}
        format="MM/dd/yyyy"
        shouldDisableDate={shouldDisableDate}
        onClose={() => {
          handleClose && handleClose();
        }}
        disabled={disabled}
        placeholder="mm/dd/yyyy"
        ref={datePickerRef}
        placement="autoVerticalStart"
        cleanable={disabled ? false : true}
      />
    </div>
  );
}
export { DateOfBirthPicker };
