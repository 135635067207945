import { useQuery, UseQueryResult } from 'react-query';

import { getTimezone } from 'src/utils/urls';

import { useAuth } from 'src/hooks';

import { Timezone } from 'src/types/timezone';

async function fetchTimezone({
  token,
  tokenType,
}: TVariables): Promise<APIResult> {
  const response = await fetch(getTimezone, {
    method: 'GET',
    headers: {
      Authorization: `${tokenType} ${token}`,
    },
  });
  return response.json();
}

export type APIResult = Array<Timezone>;

type TError = { message: string };
type TVariables = {
  token: string;
  tokenType: string;
};

function useGetTimezone(): UseQueryResult<APIResult, TError> {
  const { token, tokenType } = useAuth();
  return useQuery(
    ['get_timezone'],
    async () => {
      const result = await fetchTimezone({
        token,
        tokenType,
      });
      return result;
    },
    {
      staleTime: Infinity,
      refetchOnWindowFocus: false,
    },
  );
}

export { useGetTimezone };
