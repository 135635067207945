import { removeProvider } from 'src/utils/urls';

import { ResourceList } from '../types/resourceList';

async function fecthProviderDetails({
  token,
  tokenType,
  id,
}: {
  token: string;
  tokenType: string;
  id: string;
}): Promise<ResourceList> {
  const response = await fetch(removeProvider(id), {
    method: 'GET',
    headers: {
      Authorization: `${tokenType} ${token}`,
      'Content-Type': 'application/json',
    },
  });
  return response.json();
}

export { fecthProviderDetails };
