import React, { ReactElement, useCallback } from 'react';
import QRCode from 'react-qr-code';
import {
  ChevronUpIcon,
  InformationCircleIcon,
} from '@heroicons/react/16/solid';

import { userOnBoardingStore } from 'src/store/userOnboardingStore';
import { Alert } from 'src/ui/components';
import { Disclosure } from '@headlessui/react';

export function QrCodeGenerator(): ReactElement {
  const qrCode = userOnBoardingStore(useCallback(state => state.qrCode, []));

  return (
    <div className=" rounded-lg border border-gray-300 bg-white  shadow dark:border-dark-medium dark:bg-neutral-700 ">
      <div className="flex flex-col items-center justify-center gap-4 p-3 md:p-5 ">
        <p className="font-medium">
          Open your authentication app, scan this QR code and click continue.
        </p>
        <div className="flex items-center justify-between gap-4">
          <div
            className="h-32 w-32 rounded bg-gray-300"
            title={qrCode?.authenticatorUri}
          >
            <QRCode
              size={256}
              style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
              value={qrCode?.authenticatorUri ? qrCode?.authenticatorUri : ''}
              viewBox={`0 0 256 256`}
            />
          </div>

          <div>
            <p className="text-center text-sm text-gray-500 dark:text-neutral-400">
              Or enter the code manually and click continue.
            </p>
            <p className="text-center font-semibold">{qrCode?.sharedKey}</p>
          </div>
        </div>
      </div>
      <Disclosure as="div" className="w-full">
        {({ open }) => (
          <>
            <Disclosure.Button className="flex w-full justify-between rounded-b-lg bg-sky-50 px-4 py-2 text-left text-sm font-medium text-sky-900 hover:bg-sky-200 focus:outline-none focus-visible:ring focus-visible:ring-sky-500/75 ui-open:rounded-none dark:bg-sky-950 dark:text-sky-300">
              <span>Having trouble connecting?</span>
              <ChevronUpIcon
                className={`${
                  !open ? 'rotate-180 transform' : ''
                } h-5 w-5 text-sky-500`}
              />
            </Disclosure.Button>
            <Disclosure.Panel className="rounded-b-lg bg-sky-50 px-4 pb-2 pt-1 text-sm text-gray-500 dark:bg-sky-900 dark:text-sky-200">
              If your preferred app does not work with these steps, you may wish
              to contact your app&apos;s support team. If you know that your app
              does support TOTP and is still not working, please reach out to
              your Relationship Manager. In the meantime, if you wish to
              continue with setting up your account, click Cancel and select
              Email authentication.
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </div>
  );
}
