const feedbackOptions = [
  {
    id: 1,
    name: 'Issue or bug',
    desc: "A feature I'm trying to use is not working correctly or is missing",
  },
  {
    id: 2,
    name: 'Look and feel',
    desc: 'I find the old version easier to use, or simply like the look and feel better',
  },
  {
    id: 3,
    name: 'Neither',
    desc: 'Neither option fits',
  },
];

export { feedbackOptions };
