import React from 'react';
import { Column, TableInstance, ColumnInstance } from 'react-table';

import personSearchTableHeader from './personSearchTableHeader';

import { PersonInformation } from '../../types/findPersonInformation';

function contains(array: Array<string>, string: string): boolean {
  const index = array.findIndex(item => item === string);
  return index !== -1;
}

export default function usePersonSearchTableColumns(
  columnsList?: Array<string>,
): Array<Column<PersonInformation>> {
  return React.useMemo((): Array<Column<PersonInformation>> => {
    if (columnsList) {
      const columns = [];
      if (contains(columnsList, 'Id'))
        columns.push(getIdHeader(personSearchTableHeader));
      if (contains(columnsList, 'First name'))
        columns.push(getFirstNameHeader(personSearchTableHeader));
      if (contains(columnsList, 'Last name'))
        columns.push(getLastNameHeader(personSearchTableHeader));
      if (contains(columnsList, 'Alternate name'))
        columns.push(getAlternateNameHeader(personSearchTableHeader));
      if (contains(columnsList, 'Primary phone'))
        columns.push(getPrimaryPhoneHeader(personSearchTableHeader));
      if (contains(columnsList, 'Secondary phone'))
        columns.push(getSecondaryPhoneHeader(personSearchTableHeader));
      if (contains(columnsList, 'Dob'))
        columns.push(getDobHeader(personSearchTableHeader));
      if (contains(columnsList, 'City'))
        columns.push(getCityHeader(personSearchTableHeader));
      if (contains(columnsList, 'Ranking'))
        columns.push(getRankingHeader(personSearchTableHeader));
      return columns;
    }
    return [
      getIdHeader(personSearchTableHeader),
      getFirstNameHeader(personSearchTableHeader),
      getLastNameHeader(personSearchTableHeader),
      getAlternateNameHeader(personSearchTableHeader),
      getPrimaryPhoneHeader(personSearchTableHeader),
      getSecondaryPhoneHeader(personSearchTableHeader),
      getDobHeader(personSearchTableHeader),
      getCityHeader(personSearchTableHeader),
      getRankingHeader(personSearchTableHeader),
    ];
  }, [columnsList]);
}

function getIdHeader(
  Header: (
    props: TableInstance<PersonInformation> & {
      column: ColumnInstance<PersonInformation>;
    },
  ) => JSX.Element,
): Column<PersonInformation> {
  return {
    Header: Header,
    accessor: (row: PersonInformation) => `${row.Id || ''}`,
    id: 'Id',
  };
}

function getFirstNameHeader(
  Header: (
    props: TableInstance<PersonInformation> & {
      column: ColumnInstance<PersonInformation>;
    },
  ) => JSX.Element,
): Column<PersonInformation> {
  return {
    Header: Header,
    accessor: (row: PersonInformation) => `${row?.FirstName || ''}`,
    id: 'First name',
  };
}

function getLastNameHeader(
  Header: (
    props: TableInstance<PersonInformation> & {
      column: ColumnInstance<PersonInformation>;
    },
  ) => JSX.Element,
): Column<PersonInformation> {
  return {
    Header: Header,
    accessor: (row: PersonInformation) => `${row?.LastName || ''}`,
    id: 'Last name',
  };
}

function getAlternateNameHeader(
  Header: (
    props: TableInstance<PersonInformation> & {
      column: ColumnInstance<PersonInformation>;
    },
  ) => JSX.Element,
): Column<PersonInformation> {
  return {
    Header: Header,
    accessor: (row: PersonInformation) => `${row.PreferredName || ''}`,
    id: 'Alternate name',
  };
}

function getPrimaryPhoneHeader(
  Header: (
    props: TableInstance<PersonInformation> & {
      column: ColumnInstance<PersonInformation>;
    },
  ) => JSX.Element,
): Column<PersonInformation> {
  return {
    Header: Header,
    accessor: (row: PersonInformation) => `${row.PhoneNumber || ''}`,
    id: 'Primary phone',
  };
}

function getSecondaryPhoneHeader(
  Header: (
    props: TableInstance<PersonInformation> & {
      column: ColumnInstance<PersonInformation>;
    },
  ) => JSX.Element,
): Column<PersonInformation> {
  return {
    Header: Header,
    accessor: (row: PersonInformation) => `${row.SecondaryPhoneNumber || ''}`,
    id: 'Secondary phone',
  };
}

function getDobHeader(
  Header: (
    props: TableInstance<PersonInformation> & {
      column: ColumnInstance<PersonInformation>;
    },
  ) => JSX.Element,
): Column<PersonInformation> {
  return {
    Header: Header,
    accessor: (row: PersonInformation) => `${row.DateOfBirth || ''}`,
    id: 'Dob',
  };
}

function getCityHeader(
  Header: (
    props: TableInstance<PersonInformation> & {
      column: ColumnInstance<PersonInformation>;
    },
  ) => JSX.Element,
): Column<PersonInformation> {
  return {
    Header: Header,
    accessor: (row: PersonInformation) => `${row?.City || ''}`,
    id: 'City',
  };
}
function getRankingHeader(
  Header: (
    props: TableInstance<PersonInformation> & {
      column: ColumnInstance<PersonInformation>;
    },
  ) => JSX.Element,
): Column<PersonInformation> {
  return {
    Header: Header,
    accessor: (row: PersonInformation) => `${row?.Ranking || ''}`,
    id: 'Ranking',
  };
}
