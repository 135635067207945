import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Transition, Dialog } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/20/solid';

import { EmptyState, Skeleton, Spinner } from 'src/ui/components';
import { PersonAlertListItem } from './PersonAlertListItem';

import { usePersonRepoStore } from '../store/personRepoStore';
import { useGetPersonAlerts } from '../api/useGetPersonAlerts';

import { PersonCallLimit } from 'src/features/PersonAlerts/types/personAlerts';

function PersonAlertModal({
  openAlertModal,
  setOpenAlertModal,
}: {
  openAlertModal: boolean;
  setOpenAlertModal: (val: boolean) => void;
}) {
  const tbodyRef = useRef() as React.MutableRefObject<HTMLTableSectionElement>;
  const [callLimit, setCallLimit] = useState<PersonCallLimit | undefined>(
    undefined,
  );

  const selectedPerson = usePersonRepoStore(
    useCallback(state => state.selectedPerson, []),
  );

  const {
    data: personAlerts,
    hasNextPage,
    isLoading,
    isRefetching,
    fetchNextPage,
  } = useGetPersonAlerts(selectedPerson?.AccountId, selectedPerson?.EntityId);

  //person call limit from the first elemnt of the alert api
  useEffect(() => {
    if (
      personAlerts &&
      personAlerts?.pages &&
      personAlerts?.pages[0]?.length > 0
    ) {
      if (personAlerts?.pages[0][0].PersonCallLimit) {
        setCallLimit(personAlerts?.pages[0][0].PersonCallLimit);
      }
    }
  }, [personAlerts]);

  const handleScroll = useCallback(
    (e: any) => {
      if (tbodyRef.current === e.target) {
        const bottom =
          e.target.clientHeight - 10 <
            e.target.scrollHeight - e.target.scrollTop &&
          e.target.scrollHeight - e.target.scrollTop <
            e.target.clientHeight + 10;
        if (bottom) {
          hasNextPage && fetchNextPage();
        }
      }
    },
    [fetchNextPage, hasNextPage],
  );

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, true);
    return () => {
      window.removeEventListener('scroll', handleScroll, true);
    };
  }, [handleScroll]);

  const personNameInfo = useMemo(() => {
    if (!selectedPerson) return null;

    const callerName = `${
      selectedPerson.FirstName ? selectedPerson.FirstName : ''
    } ${selectedPerson.LastName ? selectedPerson.LastName : ''}`.trim();

    const alternativeName = selectedPerson.PreferredName
      ? selectedPerson.PreferredName.trim()
      : '';

    return {
      callerName,
      alternativeName,
    };
  }, [selectedPerson]);

  return (
    <>
      <Transition show={openAlertModal} appear as={Fragment}>
        <Dialog
          open={openAlertModal}
          onClose={() => setOpenAlertModal(false)}
          className="relative z-10"
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Backdrop className="pr-dialog__backdrop" />
          </Transition.Child>
          <div className="pr-dialog__wrapper">
            <div className="pr-dialog__panel__container">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="pr-dialog__panel flex h-[calc(100svh-110px)] max-h-[calc(100svh-110px)] flex-col sm:!max-w-5xl md:h-auto">
                  <div className="flex items-center gap-3 border-b px-4 py-3 dark:border-dark-medium">
                    <Dialog.Title
                      as="h3"
                      className="grow truncate text-lg font-medium leading-6 text-gray-900 dark:text-white"
                    >
                      Alerts
                    </Dialog.Title>

                    <button
                      type="button"
                      className=" text-gray-400 hover:text-gray-500 sm:right-4 sm:top-4"
                      onClick={() => setOpenAlertModal(false)}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>

                  <div className="grid grid-cols-1 gap-x-8 gap-y-2 border-b border-light-light bg-gray-100 px-4 py-2 dark:border-dark-medium dark:bg-neutral-700 md:grid-cols-3">
                    <div className="">
                      <label className="text-sm text-gray-600 dark:text-neutral-400">
                        Caller/POC
                      </label>
                      <p
                        className="font-semibold md:truncate"
                        title={personNameInfo?.callerName}
                      >
                        {personNameInfo?.callerName}
                      </p>
                    </div>
                    <div className="">
                      <label className="text-sm text-gray-600 dark:text-neutral-400">
                        Alternate name
                      </label>
                      <p
                        className="font-semibold md:truncate"
                        title={personNameInfo?.alternativeName}
                      >
                        {personNameInfo?.alternativeName}
                      </p>
                    </div>
                    <div className="">
                      <label className="text-sm text-gray-600 dark:text-neutral-400">
                        Call limit
                      </label>
                      {callLimit && (
                        <p className="truncate font-semibold">
                          Caller made {callLimit?.CallsCalled ?? 0} out of{' '}
                          {callLimit?.MaxCallCount} allowed calls
                        </p>
                      )}
                    </div>
                  </div>

                  <div
                    className="relative min-h-0 grow space-y-4 overflow-auto scroll-smooth p-4"
                    ref={tbodyRef}
                  >
                    {!isLoading && personAlerts?.pages ? (
                      <>
                        {' '}
                        {personAlerts?.pages.map((page: any, index: number) => (
                          <Fragment key={`${page} - ${index + 1}`}>
                            {page && page?.length ? (
                              page?.map((alertInfo: any, indx: number) => (
                                <PersonAlertListItem
                                  key={index}
                                  alertInfo={alertInfo}
                                />
                              ))
                            ) : (
                              <div className="grid min-h-64 place-content-center">
                                <EmptyState message="No information available" />
                              </div>
                            )}
                          </Fragment>
                        ))}
                      </>
                    ) : (
                      (isLoading || isRefetching) && <Skeleton count={8} />
                    )}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
          {(isLoading || isRefetching) && (
            <div className="bg-gray-900/8  absolute inset-0 z-50 grid place-items-center backdrop-blur-sm">
              <Spinner size="large" />
            </div>
          )}
        </Dialog>
      </Transition>
    </>
  );
}

export { PersonAlertModal };
