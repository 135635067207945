// form related to create and edit extra coverage request goes here
import React, {
  Fragment,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { useQueryClient, useQuery } from 'react-query';
import { ExclamationTriangleIcon } from '@heroicons/react/20/solid';

import {
  Button,
  RequiredWarningMessage,
  Spinner,
  CustomCancelModal,
  Alert,
} from 'src/ui/components';
import {
  BasicDetails,
  AccountSelection,
  RequestDetails,
} from './components/index';

import {
  useAuth,
  useGetStoredPermissions,
  useGetTimezone,
  useTitle,
} from 'src/hooks';
import { useMutableObserver } from 'src/hooks/useMutableObserver';
import { useCreateExtraCoverageRequest } from './api/useCreateExtraCoverageRequest';
import {
  usePostAccountAlert,
  useGetExtraCoverageRequestStatuses,
} from 'src/features/ExtraCoverageRequests/api';
import { useGetRequestType, useSendEmail } from './api';

import { useExtracoverageRequestStore } from './store/extracoverageStore';
import { showErrorToast, showSuccessToast } from 'src/utils/ToastNotification';
import {
  formatCoverageDetailsPayload,
  formatDateToRange,
  getDuration,
  getAlertDetailsForGrid,
} from './utils/coverageRequestHelper';
import {
  createSuccessMsg,
  updateSuccessMsg,
  postAlertMsg,
  extraCoverageTabs,
  extraCoverageTabSections,
} from './utils/coverageRequestConstants';
import { toCapitalise } from 'src/utils/common';
import { formatStatus } from '../ExtraCoverageRequests/utils/ExtraCoverageFilterHelper';
import { editorContent } from './utils/storeHelper';

import { Timezone } from 'src/types/timezone';

export default function AddEditExtraCoverageRequest(): ReactElement {
  const location = useLocation();
  const { id } = useParams();
  const navigate = useNavigate();
  const queryClient: any = useQueryClient();
  const permission = useGetStoredPermissions();

  const { token, tokenType, userInfo } = useAuth();

  const warningMsgRef = useRef<HTMLDivElement | null>(null);
  const datePickerRef = useRef<any>();

  const { isMessageChanged } = useMutableObserver(warningMsgRef);

  const { mutate: onSaveCoverageRequest, isLoading: covergaerequestLoading } =
    useCreateExtraCoverageRequest();

  const { data: requestStatus, isLoading: isStatusLoading } =
    useGetExtraCoverageRequestStatuses();

  const { data: timezone, isLoading: isTimezoneLoading } = useGetTimezone();

  const { data: coverageType, isLoading: isCoverageTypeLoading } =
    useGetRequestType();

  const { mutate: onSendEmail, isLoading: isSendingProgress } = useSendEmail();

  const isEnteredBy = useExtracoverageRequestStore(
    React.useCallback(state => state.isEnteredBy, []),
  );

  const isOtherReceipents = useExtracoverageRequestStore(
    React.useCallback(state => state.isOtherReceipents, []),
  );

  const isSystemConfirmation = useExtracoverageRequestStore(
    React.useCallback(state => state.isSystemConfirmation, []),
  );

  const isPageLoading = useExtracoverageRequestStore(
    useCallback(state => state.isLoading, []),
  );

  const [showCancelModal, setShowCancelModal] = useState(false);

  const userMode = useExtracoverageRequestStore(
    useCallback(state => state.userMode, []),
  );

  const updateUserMode = useExtracoverageRequestStore(
    useCallback(state => state.updateUserMode, []),
  );

  const getRequestDetails = useExtracoverageRequestStore(
    useCallback(state => state.getRequestDetails, []),
  );

  const getBasicDetailsErrors = useExtracoverageRequestStore(
    useCallback(state => state.getBasicDetailsErrors, []),
  );

  const isSaveClicked = useExtracoverageRequestStore(
    useCallback(state => state.isSaveClicked, []),
  );

  const extraCoverageRequestDet = useExtracoverageRequestStore(
    useCallback(state => state.extraCoverageRequestDet, []),
  );

  const requestStatusId = useExtracoverageRequestStore(
    useCallback(
      state => state.extraCoverageRequestDet?.ExtraCoverageRequestStatusId,
      [],
    ),
  );

  const postAlertOnSave = useExtracoverageRequestStore(
    useCallback(state => state.postAlertOnSave, []),
  );

  const sendEmailOnSave = useExtracoverageRequestStore(
    useCallback(state => state.sendEmailOnSave, []),
  );

  const accountsInfo = useExtracoverageRequestStore(
    useCallback(state => state.accountsInfo, []),
  );

  const isAllRequiredFieldsFilled = useExtracoverageRequestStore(
    React.useCallback(state => state.isAllRequiredFieldsFilled, []),
  );

  const accountList = useExtracoverageRequestStore(
    useCallback(state => state.accountList, []),
  );

  const alertModalInfo = useExtracoverageRequestStore(
    React.useCallback(state => state.alertModalInfo, []),
  );

  const defaultEmail = useExtracoverageRequestStore(
    React.useCallback(state => state.defaultEmail, []),
  );

  const resetStore = useExtracoverageRequestStore(
    React.useCallback(state => state.deleteCoverageDetails, []),
  );

  const updateStoreByKey = useExtracoverageRequestStore(
    React.useCallback(state => state.updateStoreByKey, []),
  );

  const hasPhnValiationError = useExtracoverageRequestStore(
    useCallback(state => state.showPhnNumberError, []),
  );

  const hasPostAccountAlertWarning = useExtracoverageRequestStore(
    useCallback(state => state.hasPostAccountAlertWarning, []),
  );

  const hasSendEmailWarning = useExtracoverageRequestStore(
    useCallback(state => state.hasSendEmailWarning, []),
  );

  const showError = useExtracoverageRequestStore(
    useCallback(state => state.showError, []),
  );

  const initialExtracoverageState = useExtracoverageRequestStore(
    useCallback(state => state.initialExtracoverageState, []),
  );

  const isPostAlertFieldModified = useExtracoverageRequestStore(
    useCallback(state => state.isPostAlertFieldModified, []),
  );

  const previousPostAccountAlert = useExtracoverageRequestStore(
    useCallback(state => state.previousPostAccountAlert, []),
  );
  const isSendEmailFieldModified = useExtracoverageRequestStore(
    useCallback(state => state.isSendEmailFieldModified, []),
  );
  const previousSendEmail = useExtracoverageRequestStore(
    useCallback(state => state.previousSendEmail, []),
  );
  const isUpdateApiCalled = useExtracoverageRequestStore(
    useCallback(state => state.isUpdateApiCalled, []),
  );

  //getting the timezone value to pass on daterangepciker
  const timeZoneValue = useMemo(() => {
    const timeZonVal = timezone?.find(
      (item: Timezone) =>
        item.Id?.toString() === extraCoverageRequestDet?.TimeZoneId?.toString(),
    );

    return timeZonVal;
  }, [extraCoverageRequestDet?.TimeZoneId, timezone]);

  const { mutate: postAccountAlerts, isLoading: isPostAccountAlertsLoading } =
    usePostAccountAlert();
  //fetch all apis for create/edit/copy request during component mount
  useQuery(
    ['add-edit-coverage-request', userMode, id],

    () => {
      getRequestDetails({
        id,
        userMode,
        token,
        tokenType,
        email: userInfo?.email !== undefined ? userInfo?.email : '',
        isAdmin: permission?.Admin,
        isProtocallUser: permission?.AllProtocallUsers,
      });
    },
    { staleTime: Infinity },
  );

  useEffect(() => {
    if (location?.pathname.split('/').includes('Approve'))
      updateUserMode && updateUserMode('Approve');
    if (location?.pathname.split('/').includes('Copy'))
      updateUserMode && updateUserMode('Copy');
  }, [location?.pathname, updateUserMode]);

  const coverageStatus = useMemo(() => {
    return formatStatus(requestStatus, false);
  }, [requestStatus]);

  const accountData = useMemo(() => {
    if (accountsInfo && accountList) {
      let result = accountList?.filter(o1 =>
        accountsInfo.some(o2 => o1?.AccountId === o2),
      );
      let newArray = result?.map(item => {
        return {
          Id: item?.AccountId,
          CID: item?.CID,
          SID: item?.SID,
          PID: item?.PID,
          StatusId: item?.StatusId,
          AccountServiceId: item?.AccountServiceId,
        };
      });
      return newArray;
    }
  }, [accountList, accountsInfo]);

  const getUserMode = useMemo(() => {
    if (userMode === '') return 'Create extra coverage request';
    if (userMode === 'Approve') return 'Edit extra coverage request';
    if (userMode === 'Copy') return 'Copy extra coverage request';
    return '';
  }, [userMode]);

  // to set final user action button label for protocall users
  const buttonLabel = useMemo(() => {
    if (requestStatusId && requestStatusId !== '0') {
      const label = coverageStatus?.find(
        item => item?.value === parseInt(requestStatusId),
      )?.label;
      if (label !== 'New') {
        return label;
      }
      return 'Update';
    } else {
      return 'Update';
    }
  }, [coverageStatus, requestStatusId]);

  const coverageRequestStatus = useMemo(() => {
    return coverageStatus?.find(
      item => item?.value === parseInt(requestStatusId),
    )?.label;
  }, [coverageStatus, requestStatusId]);

  //getting the PT timezone
  const ptTimeZone = useMemo(() => {
    return timezone?.find((item: Timezone) => item?.Abbreviation === 'PT');
  }, [timezone]);

  useTitle(toCapitalise(getUserMode));

  const isSpinnerLoading =
    isPageLoading ||
    covergaerequestLoading ||
    isStatusLoading ||
    isPostAccountAlertsLoading ||
    isTimezoneLoading ||
    isCoverageTypeLoading ||
    isSendingProgress;

  function renderPageHeader(): ReactElement {
    return (
      <div className="flex items-center justify-between gap-4 border-t border-gray-200 bg-white px-2 py-1 shadow dark:border-neutral-700/50 dark:bg-neutral-800 sm:px-3 lg:px-4">
        <div className="flex flex-1 shrink-0 items-end gap-4 sm:gap-8">
          <h2 className="truncate text-lg font-semibold text-gray-950 dark:text-white sm:text-xl sm:tracking-tight">
            {getUserMode}
          </h2>
        </div>
      </div>
    );
  }

  function handleSuccess() {
    showSuccessToast({
      message:
        userMode === '' || userMode === 'Copy'
          ? createSuccessMsg
          : updateSuccessMsg,
    });
    queryClient.invalidateQueries(['add-edit-coverage-request'], {
      refetchActive: false,
    });
    navigate('/ExtraCoverageRequests');
    resetStore();
  }

  function handleError(msg: any) {
    showErrorToast({
      message: msg?.Message,
    });
  }

  function onSubmitRequest() {
    if (
      !isAllRequiredFieldsFilled('account-info') ||
      !isAllRequiredFieldsFilled('basic-details') ||
      !isAllRequiredFieldsFilled('request-details') ||
      getBasicDetailsErrors(timeZoneValue)?.length !== 0 ||
      hasPhnValiationError
    )
      return;

    const coverageDetails: any = formatCoverageDetailsPayload(
      extraCoverageRequestDet,
      accountData,
      userMode,
      timezone,
      previousPostAccountAlert,
      isPostAlertFieldModified,
      previousSendEmail,
      isSendEmailFieldModified,
    );

    delete coverageDetails['Country'];

    onSaveCoverageRequest(
      {
        params: coverageDetails,
        action: userMode,
      },
      {
        onSuccess: async () => {
          if (userMode === 'Approve' && coverageRequestStatus === 'Approved') {
            const isSendemail = previousSendEmail
              ? isSendEmailFieldModified && sendEmailOnSave
              : sendEmailOnSave;
            if (isSendemail && id) {
              const body = {
                ...defaultEmail,
                Recipients: isEnteredBy ? defaultEmail?.Recipients : '',
                AdditionalRecipients: isOtherReceipents
                  ? defaultEmail?.AdditionalRecipients?.split(',')
                      ?.map(elem => elem.trim())
                      ?.filter(Boolean)
                      ?.join(',')
                  : '',
                SystemEmailAddress: isSystemConfirmation
                  ? defaultEmail?.SystemEmailAddress
                  : '',
              };
              await new Promise((resolve, reject) => {
                onSendEmail(
                  { ...body, id: parseInt(id) },
                  {
                    onSuccess: () => {
                      resolve(0);
                    },
                    onError: err => {
                      if (Array.isArray(err) && err.length) {
                        showErrorToast({ message: err[0] });
                      } else {
                        showErrorToast({ message: err?.Message });
                      }
                      reject(err);
                    },
                  },
                );
              });
            }
            const isPostAlert = previousPostAccountAlert
              ? isPostAlertFieldModified && postAlertOnSave
              : postAlertOnSave;
            if (isPostAlert) {
              // if user not try to edit the alert details posted need to create the post account alert object.
              let isAlertEdited = alertModalInfo !== null ? true : false;
              let coverageDate =
                extraCoverageRequestDet?.ExtraCoverageDate ?? '';
              let startTime = extraCoverageRequestDet?.StartTime ?? '';
              let endTime = extraCoverageRequestDet?.EndTime ?? '';

              let dateRange = isAlertEdited
                ? alertModalInfo?.dateRange
                : formatDateToRange(
                    coverageDate,
                    coverageDate,
                    startTime,
                    endTime,
                  );

              let message = isAlertEdited
                ? alertModalInfo?.message ?? ''
                : editorContent(
                    extraCoverageRequestDet?.ReasonForCoverage ?? '',
                    extraCoverageRequestDet?.AdditionalComments ?? '',
                    getDuration(
                      coverageDate,
                      startTime,
                      endTime,
                      extraCoverageRequestDet?.TimeZone?.Abbreviation,
                      extraCoverageRequestDet?.TimeZone,
                      ptTimeZone,
                    ),
                    extraCoverageRequestDet?.SpecialProcedures ?? false,
                    extraCoverageRequestDet?.SpecialProcedureMemo,
                  );

              const alertDetails = getAlertDetailsForGrid(
                dateRange,
                message,
                extraCoverageRequestDet?.TimeZone,
                ptTimeZone,
              );

              await new Promise((resolve, reject) => {
                postAccountAlerts(
                  {
                    Id: extraCoverageRequestDet?.Id,
                    AccountAlert: extraCoverageRequestDet?.AccountAlertId
                      ? {
                          ...alertDetails,
                          Id: extraCoverageRequestDet?.AccountAlertId,
                        }
                      : alertDetails,
                    isModified: extraCoverageRequestDet?.AccountAlertId
                      ? true
                      : false,
                  },
                  {
                    onSuccess: () => {
                      resolve(0);
                    },
                    onError: err => {
                      showErrorToast({ message: err });
                      reject(err);
                    },
                  },
                );
              });
            }
          }
          handleSuccess();
        },
        onError: (msg: any) => {
          handleError(msg);
        },
      },
    );
  }

  //on clicking cancel button
  function handleCancel() {
    if (userMode !== 'Approve' || !isUpdateApiCalled) {
      onCancelHandler();
    } else if (isUpdateApiCalled) {
      onSaveCoverageRequest(
        {
          params: initialExtracoverageState,
          action: userMode,
        },
        {
          onSuccess: () => {
            onCancelHandler();
          },
        },
      );
    }
  }

  function onCancelHandler() {
    queryClient.invalidateQueries(['add-edit-coverage-request'], {
      refetchActive: false,
    });

    resetStore();
    navigate('/ExtraCoverageRequests');
  }

  return (
    <main className="flex flex-1 flex-col">
      {renderPageHeader()}
      <div className="relative h-px grow">
        <div
          className="h-full max-h-full scroll-my-4 overflow-y-scroll"
          ref={datePickerRef}
        >
          <div className="container mx-auto flex h-full flex-col space-y-2 p-3 sm:space-y-4 lg:p-4">
            {!isPageLoading && (
              <Fragment>
                <Alert variant="info">
                  <div className="max-h-[115px] overflow-auto md:max-h-min md:overflow-visible">
                    To better assist you with extra coverage, please let{' '}
                    <strong className="font-bold ">
                      {` ${
                        process.env.REACT_APP_THEME === 'proteus'
                          ? 'Protocall'
                          : 'Linesforlife'
                      }`}
                    </strong>{' '}
                    know your needs 14 calendar days prior to the date needed.
                    Recommended days and hours for extra coverage are Tuesday,
                    Wednesday, and Thursday between 8am and 2pm PT (11am and 5pm
                    ET).
                    {` ${
                      process.env.REACT_APP_THEME === 'proteus'
                        ? 'Protocall'
                        : 'Linesforlife'
                    }`}{' '}
                    carefully assesses each extra coverage request and will make
                    every effort to accommodate these requests. However, we may
                    need to look at alternative dates and times, use a greeting
                    announcement, or deny the coverage when we do not have the
                    extra capacity to take additional calls during the time that
                    you have requested.
                  </div>
                </Alert>
                <AccountSelection
                  isApproveMode={
                    coverageRequestStatus === 'Approved' ? true : false
                  }
                />
                <BasicDetails
                  coverageStatus={coverageStatus}
                  timezoneObj={timezone}
                  coverageType={coverageType}
                  datePickerRef={datePickerRef}
                  isApproveMode={
                    coverageRequestStatus === 'Approved' ? true : false
                  }
                />
                <RequestDetails
                  accountData={accountData}
                  timeZone={timezone}
                  isApproveMode={
                    coverageRequestStatus === 'Approved' ? true : false
                  }
                />
              </Fragment>
            )}
          </div>
        </div>
      </div>

      <div
        className={`border-t border-light-light  bg-white dark:border-dark-medium dark:bg-neutral-800 ${
          isPageLoading ? 'blur-sm' : ''
        }`}
      >
        <div
          ref={warningMsgRef}
          className="container mx-auto flex flex-col flex-wrap gap-4 px-2 py-2 sm:justify-end md:flex-row md:flex-nowrap md:items-center md:px-5"
        >
          <div className=" self-center">
            {(hasPostAccountAlertWarning || hasSendEmailWarning) && (
              <div className="flex gap-1 text-sm ">
                <ExclamationTriangleIcon className="relative -bottom-1 h-4 w-4 shrink-0 text-yellow-600 dark:text-yellow-500" />
                <span className="text-yellow-800 dark:text-yellow-200">
                  Please recheck the
                  {hasPostAccountAlertWarning ? (
                    <strong> account alert </strong>
                  ) : (
                    ''
                  )}
                  {hasPostAccountAlertWarning && hasSendEmailWarning
                    ? 'and '
                    : ''}
                  {hasSendEmailWarning ? <strong> approval email</strong> : ''}{' '}
                  details as the extra coverage request details have been
                  updated.
                </span>
              </div>
            )}

            {isSaveClicked &&
              (!isAllRequiredFieldsFilled('account-info') ||
                !isAllRequiredFieldsFilled('basic-details') ||
                !isAllRequiredFieldsFilled('request-details')) && (
                <div
                  className={`flex items-center gap-2 ${
                    isMessageChanged ? 'animate-shake' : ''
                  }`}
                >
                  <span className="relative flex h-3 w-3 shrink-0 items-center justify-center">
                    <span className="absolute inline-flex h-full w-full animate-ping rounded-full bg-red-400 opacity-75" />
                    <span className="relative inline-flex h-2 w-2 rounded-full bg-red-500" />
                  </span>
                  <span className="text-sm font-medium text-red-700 dark:text-red-400">
                    <RequiredWarningMessage
                      tabs={extraCoverageTabs}
                      tabContents={extraCoverageTabSections}
                      enableTab={isAllRequiredFieldsFilled}
                    />
                  </span>
                </div>
              )}
          </div>

          <div className="flex gap-[1ch]">
            <Button
              className="grow md:grow-0"
              onClick={() => setShowCancelModal(true)}
              disabled={isSpinnerLoading}
            >
              Cancel
            </Button>

            <Button
              className="grow md:grow-0"
              variant="primary"
              onClick={() => {
                updateStoreByKey('isSaveClicked', true);
                onSubmitRequest();
              }}
              disabled={
                isSpinnerLoading ||
                getBasicDetailsErrors(timeZoneValue)?.length > 0 ||
                showError ||
                (previousPostAccountAlert &&
                  previousSendEmail &&
                  coverageRequestStatus === 'Approved' &&
                  userMode === 'Approve' &&
                  !(isPostAlertFieldModified || isSendEmailFieldModified))
              }
            >
              {permission?.AllProtocallUsers && userMode === 'Approve'
                ? isPostAlertFieldModified || isSendEmailFieldModified
                  ? 'Update'
                  : buttonLabel
                : 'Save'}
            </Button>
          </div>
        </div>
        {showCancelModal === true && (
          <CustomCancelModal
            showCancelModal={showCancelModal}
            setShowCancelModal={setShowCancelModal}
            handleCancel={handleCancel}
          />
        )}
      </div>
      {isSpinnerLoading && (
        <div className="bg-gray-900/8 absolute inset-0 z-20 grid place-content-center backdrop-blur-sm">
          <Spinner />
        </div>
      )}
    </main>
  );
}
