import { useReducer, useEffect, useMemo } from 'react';

import { CallDocumentFilterType } from '../types/filterOptionsType';
import { MultiSelectInputType } from 'src/types/MultiSelectInputType';

export const initialState: CallDocumentFilterType = {
  keywordSearch: '',
  sortType: '0',
  sortOrder: 'desc',
  callId: '',
  caseId: '',
  cid: '',
  pid: '',
  startDate: '',
  uploadedDate: '',
  callerFirstName: '',
  callerLastName: '',
  pocFirstName: '',
  pocLastName: '',
  callType: undefined,
  levelOfCare: undefined,
  clinicalOutcome: '',
  stickyNotes: '',
  tagStatus: undefined,
};

export function callDocumentFilter(
  state: CallDocumentFilterType,
  action: {
    type: keyof CallDocumentFilterType | 'reset';
    value: any;
    payload?: any;
    newState?: CallDocumentFilterType | undefined;
  },
) {
  switch (action.type) {
    case 'reset':
      return { ...action?.value };
    default:
      return {
        ...state,
        [action.type]: action.value,
      };
  }
}

export function useCallDocumentFilterReducer(): {
  filterOptions: CallDocumentFilterType;
  filterCount: number;
  setValues: (type: keyof CallDocumentFilterType, value: string) => void;
  resetFilters: () => void;
  isFiltersApplied: boolean;
  setCallTypes: (value: Array<MultiSelectInputType>) => void;
  setLoc: (value: Array<MultiSelectInputType>) => void;
  setTagStatus: (value: Array<MultiSelectInputType>) => void;
} {
  const [filterOptions, setFilterOptions] = useReducer(
    callDocumentFilter,
    {
      ...JSON.parse(JSON.stringify(initialState)),
    },
    (initial: CallDocumentFilterType) => {
      const callDocumentLogFilterString =
        window.localStorage.getItem('CallDocumentFilter');
      if (callDocumentLogFilterString) {
        const callDocumentFilter: CallDocumentFilterType = JSON.parse(
          callDocumentLogFilterString,
        );
        return {
          ...callDocumentFilter,
        };
      }

      return {
        ...initial,
      };
    },
  );

  //for filtercount
  const filterCount = useMemo(() => {
    let count: number = 0;
    const obj1: any = { ...initialState };
    const obj2: any = {
      ...JSON.parse(JSON.stringify(filterOptions)),
      callType:
        !filterOptions.callType ||
        filterOptions.callType.length === 0 ||
        filterOptions.callType.find(
          (item: MultiSelectInputType) => item.value === 0,
        )
          ? undefined
          : filterOptions.callType,
      levelOfCare:
        !filterOptions.levelOfCare ||
        filterOptions.levelOfCare.length === 0 ||
        filterOptions.levelOfCare.find(
          (item: MultiSelectInputType) => item.value === 0,
        )
          ? undefined
          : filterOptions.levelOfCare,
      tagStatus:
        !filterOptions.tagStatus ||
        filterOptions.tagStatus.length === 0 ||
        filterOptions.tagStatus.find(
          (item: MultiSelectInputType) => item.value === 0,
        )
          ? undefined
          : filterOptions.tagStatus,
    };

    delete obj1['keywordSearch'];
    delete obj1['sortOrder'];
    delete obj1['sortType'];

    Object.keys(obj1).forEach(key => {
      if (obj1[key] !== obj2[key]) {
        count++;
      }
    });

    return count;
  }, [filterOptions]);

  function setValues(type: keyof CallDocumentFilterType, value: string) {
    setFilterOptions({
      type,
      value,
    });
  }

  function setCallTypes(
    value: Array<{ value: number | string; label: string | undefined }>,
  ) {
    setFilterOptions({
      type: 'callType',
      value,
    });
  }

  function setLoc(
    value: Array<{ value: number | string; label: string | undefined }>,
  ) {
    setFilterOptions({
      type: 'levelOfCare',
      value,
    });
  }

  function setTagStatus(
    value: Array<{ value: number | string; label: string | undefined }>,
  ) {
    setFilterOptions({
      type: 'tagStatus',
      value,
    });
  }

  useEffect(() => {
    window.localStorage.setItem(
      'CallDocumentFilter',
      JSON.stringify(filterOptions),
    );
  }, [filterOptions]);

  function resetFilters() {
    setFilterOptions({
      type: 'reset',
      value: {
        ...JSON.parse(JSON.stringify(initialState)),
      },
    });
  }

  const isFiltersApplied: boolean = useMemo(() => {
    const obj1: Partial<CallDocumentFilterType> = {
      ...initialState,
    };
    const obj2: Partial<CallDocumentFilterType> = {
      ...JSON.parse(JSON.stringify(filterOptions)),
    };

    delete obj1['sortOrder'];
    let diff = false;
    (Object.keys(obj1) as Array<keyof CallDocumentFilterType>).forEach(
      (value: keyof CallDocumentFilterType) => {
        if (obj1[value] !== obj2[value]) {
          diff = true;
        }
      },
    );
    return diff;
  }, [filterOptions]);

  return {
    filterOptions,
    filterCount,
    setValues,
    resetFilters,
    isFiltersApplied,
    setCallTypes,
    setLoc,
    setTagStatus,
  };
}
