import React, { Fragment, ReactElement } from 'react';
import { XMarkIcon } from '@heroicons/react/20/solid';
import { Transition, Dialog } from '@headlessui/react';

import { Button } from '../Button';

import { cancelWarningMsg, searchResultEmptyMsg } from 'src/utils/appConstants';

type ParamType = {
  showCancelModal: boolean;
  setShowCancelModal: (
    value: boolean | ((prevState: boolean) => boolean),
  ) => void;
  handleCancel: () => void;
  component?: string;
};

function CustomCancelModal({
  showCancelModal,
  setShowCancelModal,
  handleCancel,
  component,
}: ParamType): ReactElement {
  return (
    <Transition appear show={showCancelModal} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => setShowCancelModal(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="pr-dialog__backdrop" />
        </Transition.Child>

        <div className="pr-dialog__wrapper">
          <div className="pr-dialog__panel__container">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="pr-dialog__panel">
                <Dialog.Title
                  as="h3"
                  className="py-6 pl-4 pr-16 font-medium leading-6 text-gray-900 dark:text-white"
                >
                  {component === 'resource'
                    ? searchResultEmptyMsg
                    : cancelWarningMsg}
                </Dialog.Title>
                <button
                  type="button"
                  className="absolute right-4 top-4 text-gray-400 hover:text-gray-500 sm:right-4 sm:top-4"
                  onClick={() => setShowCancelModal(false)}
                >
                  <span className="sr-only">Close</span>
                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </button>

                <div className="flex gap-[1ch] bg-gray-50 p-2 dark:bg-neutral-700/50 sm:justify-end sm:p-3">
                  <Button
                    variant="secondary"
                    onClick={() => setShowCancelModal(false)}
                    className="grow sm:grow-0"
                  >
                    No
                  </Button>
                  <Button
                    variant="primary"
                    onClick={() => {
                      setShowCancelModal(false);
                      handleCancel();
                    }}
                    className="grow sm:grow-0"
                  >
                    Yes
                  </Button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

export { CustomCancelModal };
