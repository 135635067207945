import React, { Fragment, ReactElement, useCallback, useRef } from 'react';
import { Dialog, Disclosure, Transition } from '@headlessui/react';
import { ChevronUpIcon, XMarkIcon } from '@heroicons/react/20/solid';
import { motion } from 'framer-motion';
import QRCode from 'react-qr-code';

import { Alert, Button, Spinner, TextField } from 'src/ui/components';

import {
  useAuth,
  useEmailAsTwofactorAuth,
  useGetQrCode,
  useVerifyGoogleAuth,
} from 'src/hooks';

import { userOnBoardingStore } from 'src/store/userOnboardingStore';
import {
  authenticatorInfo,
  links,
} from 'src/features/UserOnBoarding/utils/onBoardingConstants';
import { formatAuthType } from '../utils/SettingsHelper';
import { toCapitalise } from 'src/utils/common';

type ChangeAuthenticator = {
  changeAuthModal: boolean;
  setChangeAuthModal: (
    value: boolean | ((prevState: boolean) => boolean),
  ) => void;
};

export function ChangeAuthenticator({
  changeAuthModal,
  setChangeAuthModal,
}: ChangeAuthenticator): ReactElement {
  const SpecifiedTwoFacterAuthMethod = localStorage.getItem(
    'SpecifiedTwoFacterAuthMethod',
  );
  const { userInfo } = useAuth();

  const textInputRef = useRef(null);

  const { mutate: setEmailAsTwoFactorAuth, isLoading: isSetEmailLoading } =
    useEmailAsTwofactorAuth();

  const { mutate: getQRCode, isLoading: isLoadingQrCode } = useGetQrCode();

  const { mutate: verifyAuthCode, isLoading: isLoadingVerifyAuthcode } =
    useVerifyGoogleAuth();

  const isLoading =
    isLoadingQrCode || isLoadingVerifyAuthcode || isSetEmailLoading;

  const twofactorAuthType = userOnBoardingStore(
    useCallback(state => state.twofactorAuthType, []),
  );

  const setUpdateStore = userOnBoardingStore(
    useCallback(state => state.setUpdateStore, []),
  );

  const showAuthConfirmation = userOnBoardingStore(
    useCallback(state => state.showAuthConfirmation, []),
  );

  const showQrCode = userOnBoardingStore(
    useCallback(state => state.showQrCode, []),
  );

  const setQrCode = userOnBoardingStore(
    useCallback(state => state.setQrCode, []),
  );

  const showQrCodeKeySection = userOnBoardingStore(
    useCallback(state => state.showQrCodeKeySection, []),
  );

  const googleAuthCode = userOnBoardingStore(
    useCallback(state => state.googleAuthCode, []),
  );

  const showInvalidQrCodeError = userOnBoardingStore(
    useCallback(state => state.showInvalidQrCodeError, []),
  );

  const qrCode = userOnBoardingStore(useCallback(state => state.qrCode, []));

  function handleCancel() {
    setUpdateStore(
      'twofactorAuthType',
      formatAuthType(SpecifiedTwoFacterAuthMethod),
    );

    setUpdateStore('showQrCode', false);
    setUpdateStore('showQrCodeKeySection', false);
    setUpdateStore('googleAuthCode', '');
    setUpdateStore('showInvalidQrCodeError', false);
    setUpdateStore('qrCode', undefined);
    setUpdateStore('showAuthConfirmation', false);
    setChangeAuthModal(false);
  }

  function handleContinue() {
    if (twofactorAuthType.toLowerCase() === 'email') {
      if (getButtonLabel() === 'Continue') {
        setEmailAsTwoFactorAuth(
          { userName: userInfo?.email },
          {
            onSuccess: () => {
              setUpdateStore('showAuthConfirmation', true);
              localStorage.setItem(
                'SpecifiedTwoFacterAuthMethod',
                toCapitalise(twofactorAuthType),
              );
            },
            onError: () => {},
          },
        );
      } else {
        setUpdateStore('showAuthConfirmation', false);
        setChangeAuthModal(false);
      }
    } else if (twofactorAuthType.toLowerCase() === 'authenticator') {
      if (
        getButtonLabel() === 'Continue' &&
        !showQrCode &&
        !showQrCodeKeySection &&
        !showAuthConfirmation
      ) {
        getQRCode(
          { userName: userInfo?.email },
          {
            onSuccess: (code: any) => {
              setQrCode({
                authenticatorUri: code.authenticatorUri,
                sharedKey: code.sharedKey,
              });

              setUpdateStore('showQrCode', true);
            },
            onError: () => {},
          },
        );
      }
      if (getButtonLabel() === 'Continue' && showQrCode) {
        setUpdateStore('showQrCodeKeySection', true);
        setUpdateStore('showQrCode', false);
      }
      if (
        getButtonLabel() === 'Continue' &&
        !showQrCode &&
        showQrCodeKeySection &&
        !showAuthConfirmation
      ) {
        verifyAuthCode(
          {
            userName: userInfo?.email,
            googleAuthCode,
          },
          {
            onSuccess: response => {
              if (response) {
                setUpdateStore('showQrCodeKeySection', false);
                setUpdateStore('showAuthConfirmation', true);
                setUpdateStore('showQrCode', false);
                localStorage.setItem(
                  'SpecifiedTwoFacterAuthMethod',
                  toCapitalise(twofactorAuthType),
                );
              } else {
                setUpdateStore('showInvalidQrCodeError', true);
              }
            },
            onError: () => {},
          },
        );
      } else if (getButtonLabel() === 'Close') {
        setUpdateStore('showQrCode', false);
        setUpdateStore('showQrCodeKeySection', false);
        setUpdateStore('googleAuthCode', '');
        setUpdateStore('showInvalidQrCodeError', false);
        setUpdateStore('qrCode', undefined);
        setUpdateStore('showAuthConfirmation', false);
        setChangeAuthModal(false);
      }
    }
  }

  function getButtonLabel() {
    if (showAuthConfirmation) return 'Close';
    else return 'Continue';
  }

  function externalLink(): ReactElement {
    return (
      <>
        {links.map(item => (
          <li key={item.title}>
            {item.title} (
            <a
              href={item.playStoreLink}
              className="text-sky-700 after:content-['_↗'] dark:text-sky-400"
              target="_blank"
              rel="noreferrer"
            >
              PlayStore
            </a>{' '}
            or{' '}
            <a
              href={item.appStoreLink}
              className="text-sky-700 after:content-['_↗'] dark:text-sky-400"
              target="_blank"
              rel="noreferrer"
            >
              AppStore
            </a>
            )
          </li>
        ))}
      </>
    );
  }

  function renderEmail(): ReactElement {
    return (
      <div className="grid grid-cols-[theme(space.8)_1fr] gap-4 bg-white px-4 py-4 focus-within:bg-gray-100 dark:bg-neutral-800 dark:focus-within:bg-neutral-700">
        {renderRadioBtn('email', 'authType-email')}
        <div className="grid gap-1">
          <label htmlFor="authType-email" className="contents leading-none">
            <h3 className="font-medium">
              Email{' '}
              <small className="font-normal text-gray-500 dark:text-neutral-400">
                (Default)
              </small>
            </h3>
            <small className="text-sm text-gray-500 dark:text-neutral-400">
              Verification code will be sent to{' '}
              <strong>{userInfo?.email}</strong>
            </small>
          </label>
        </div>
      </div>
    );
  }

  function renderAuthenticator(): ReactElement {
    return (
      <div className="grid grid-cols-[theme(space.8)_1fr] gap-4 bg-white px-4 py-4 focus-within:bg-gray-100 dark:bg-neutral-800 dark:focus-within:bg-neutral-700">
        {renderRadioBtn('authenticator', 'authType-app')}
        <div className="grid gap-1">
          <label
            htmlFor="authType-app"
            className="contents space-y-1 leading-none"
          >
            <h3 className="font-medium">Authenticator app </h3>
            <small className="text-sm text-gray-500 dark:text-neutral-400">
              {authenticatorInfo}
            </small>
            <ul className="ml-8 text-sm text-gray-500 dark:text-neutral-400">
              {externalLink()}
            </ul>
          </label>
        </div>
      </div>
    );
  }

  function confirmAuthType(): ReactElement {
    return (
      <div className="flex flex-col items-center justify-center gap-2 rounded-lg border border-gray-300 bg-white p-3 shadow dark:border-dark-medium dark:bg-neutral-700 md:p-5">
        <p className="font-medium">
          {`${
            twofactorAuthType.toLowerCase() === 'email' ? 'Email' : 'Account'
          } Verified`}
        </p>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={24}
          height={24}
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth={1}
          strokeLinecap="round"
          strokeLinejoin="round"
          className="h-28 w-28 text-green-600 dark:text-green-400 md:h-40 md:w-40"
        >
          <motion.path
            initial={{ pathLength: 0 }}
            animate={{ pathLength: 1 }}
            transition={{
              delay: 0.2,
              type: 'tween',
              ease: 'easeOut',
              duration: 1,
            }}
            d="M22 11.08V12a10 10 0 1 1-5.93-9.14"
          />
          <motion.polyline
            initial={{ pathLength: 0 }}
            animate={{ pathLength: 1 }}
            transition={{
              delay: 0.2,
              type: 'tween',
              ease: 'easeOut',
              duration: 1,
            }}
            points="8 10 12 14 23 4"
          />
        </svg>
        <small className="text-sm text-gray-500 dark:text-neutral-400">
          {`From now on you will be able to use ${
            twofactorAuthType.toLowerCase() === 'email'
              ? 'Email'
              : 'Authenticator'
          } to sign in to Customer
          Portal.`}
        </small>
      </div>
    );
  }

  function renderRadioBtn(value: string, id: string): ReactElement {
    return (
      <input
        type="radio"
        name="authType"
        className="mt-0.5 self-start justify-self-center"
        id={id}
        value={value}
        checked={twofactorAuthType.toLowerCase() === value}
        onChange={() => setUpdateStore('twofactorAuthType', value)}
      />
    );
  }

  function renderQrCode() {
    return (
      <div className="flex flex-col items-center justify-center gap-3 ">
        <div className="flex flex-col items-center justify-center gap-4 p-3 pt-0 md:p-5 md:pt-0 ">
          <p className="mb-1 text-balance text-center font-medium">
            Open Authentication app, scan this QR code and click continue.
          </p>
          <div className="flex flex-col items-center justify-between gap-4 md:flex-row">
            <div
              className="h-32 w-32 rounded bg-gray-300"
              title={qrCode?.authenticatorUri}
            >
              <QRCode
                size={256}
                style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
                value={qrCode?.authenticatorUri ? qrCode?.authenticatorUri : ''}
                viewBox={`0 0 256 256`}
              />
            </div>

            <div>
              <p className="text-balance text-center text-sm text-gray-500 dark:text-neutral-400">
                Or enter the code manually and click continue.
              </p>
              <p className="text-balance text-center text-lg font-bold">
                {qrCode?.sharedKey}
              </p>
            </div>
          </div>
        </div>
        <Disclosure as="div" className="w-full">
          {({ open }) => (
            <>
              <Disclosure.Button
                className={`${
                  !open ? 'rounded-lg' : 'rounded-b-none rounded-t-lg'
                } flex w-full justify-between bg-sky-50 px-4 py-2 text-left text-sm font-medium text-sky-900 hover:bg-sky-200 focus:outline-none focus-visible:ring focus-visible:ring-sky-500/75   dark:bg-sky-950 dark:text-sky-300`}
              >
                <span>Having trouble connecting?</span>
                <ChevronUpIcon
                  className={`${
                    !open ? 'rotate-180 transform' : ''
                  } h-5 w-5 text-sky-500`}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="rounded-b-lg bg-sky-50 px-4 pb-2 pt-1 text-sm text-gray-500 dark:bg-sky-900 dark:text-sky-200">
                If your preferred app does not work with these steps, you may
                wish to contact your app&apos;s support team. If you know that
                your app does support TOTP and is still not working, please
                reach out to your Relationship Manager. In the meantime, if you
                wish to continue with setting up your account, click Cancel and
                select Email authentication.
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      </div>
    );
  }

  function renderKeySection(): ReactElement {
    return (
      <>
        <TextField
          id="2fa-id"
          label="Enter the 6-digit code from Authenticator app to verify"
          inputSize="large"
          maxLength={6}
          value={googleAuthCode}
          ref={textInputRef}
          onChange={e => {
            setUpdateStore('googleAuthCode', e.target.value);
            setUpdateStore('showInvalidQrCodeError', false);
          }}
        />
        {showInvalidQrCodeError && (
          <Alert variant="danger" title="" className="text-left text-sm">
            Invalid authenticator code
          </Alert>
        )}
      </>
    );
  }

  function renderFooterButtons(): ReactElement {
    return (
      <div className="flex gap-[1ch] bg-gray-50 p-4 dark:bg-neutral-700/50 sm:justify-end sm:p-3">
        <Button
          variant="secondary"
          onClick={() => handleCancel()}
          className="grow sm:grow-0"
        >
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={() => handleContinue()}
          className="grow sm:grow-0"
          disabled={showQrCodeKeySection && googleAuthCode === ''}
        >
          {getButtonLabel()}
        </Button>
      </div>
    );
  }

  return (
    <Transition appear show={changeAuthModal} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={textInputRef}
        onClose={() => setChangeAuthModal(true)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="pr-dialog__backdrop" />
        </Transition.Child>

        <div className="pr-dialog__wrapper">
          <div className="pr-dialog__panel__container">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="pr-dialog__panel sm:!max-w-xl">
                <Dialog.Title
                  as="h3"
                  className="bg-gray-50 p-4 px-4 pt-4 text-lg font-medium leading-6 text-gray-900 dark:bg-neutral-700/50 dark:text-white"
                >
                  Set up Two-factor authentication
                </Dialog.Title>
                <button
                  type="button"
                  className="absolute right-4 top-4 text-gray-400 hover:text-gray-500 sm:right-4 sm:top-4"
                  onClick={() => handleCancel()}
                >
                  <span className="sr-only">Close</span>
                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </button>

                <div className="mt-1">
                  <div className="container mx-auto grid max-w-2xl place-items-center gap-y-8 p-4 md:h-full md:p-6">
                    <div className="space-y-4">
                      {!showAuthConfirmation &&
                        !showQrCode &&
                        !showQrCodeKeySection && (
                          <fieldset className="grid shrink-0 gap-px overflow-hidden rounded-lg border border-gray-300 bg-gray-200 shadow transition-shadow duration-300 dark:border-neutral-700 dark:bg-neutral-700">
                            {renderEmail()}
                            {renderAuthenticator()}
                          </fieldset>
                        )}

                      {!showQrCode &&
                        !showAuthConfirmation &&
                        twofactorAuthType.toLowerCase() === 'authenticator' &&
                        !showQrCodeKeySection && (
                          <Alert
                            variant="warning"
                            title="Warning"
                            className="text-sm"
                          >
                            You will use your authentication app for
                            verification codes. The option to receive an emailed
                            code will still be available as a backup.
                          </Alert>
                        )}

                      {showAuthConfirmation && confirmAuthType()}

                      {twofactorAuthType.toLowerCase() === 'authenticator' &&
                        showQrCode &&
                        !showQrCodeKeySection &&
                        renderQrCode()}

                      {showQrCodeKeySection && renderKeySection()}
                    </div>
                  </div>
                </div>

                {renderFooterButtons()}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
        {isLoading && (
          <div className="fixed inset-0 z-20 grid place-content-center">
            <Spinner size="large" />
          </div>
        )}
      </Dialog>
    </Transition>
  );
}
