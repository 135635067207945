import React, { ReactElement, useCallback, useEffect, useState } from 'react';

import { Button } from 'src/ui/components';
import { ResetPassword, ChangeEmail, ChangeAuthenticator } from './index';

import { primaryEmailText, twoFText } from '../utils/constants';
import { formatAuthType } from '../utils/SettingsHelper';

import { userOnBoardingStore } from 'src/store/userOnboardingStore';

export function Security(): ReactElement {
  const SpecifiedTwoFacterAuthMethod = localStorage.getItem(
    'SpecifiedTwoFacterAuthMethod',
  );
  const [resetPassModal, setResetPassModal] = useState(false);
  const [changeEmailModal, setChangeEmailModal] = useState(false);
  const [changeAuthModal, setChangeAuthModal] = useState(false);

  const updateAuthTypeFromSettings = userOnBoardingStore(
    useCallback(state => state.updateAuthTypeFromSettings, []),
  );

  useEffect(() => {
    updateAuthTypeFromSettings(formatAuthType(SpecifiedTwoFacterAuthMethod));
  }, [SpecifiedTwoFacterAuthMethod, updateAuthTypeFromSettings]);

  return (
    <>
      <div className="divide-y divide-light-light dark:divide-dark-medium">
        <h3 className="px-3 py-2 text-xl font-medium md:px-4 md:py-4">
          Security
        </h3>
        <div className="flex flex-col gap-2 px-3 py-2  sm:flex-row sm:items-center md:px-4 md:py-3">
          <h4 className="grow font-semibold">Password</h4>
          <div className="min-w-[200px]">
            <Button className="w-full" onClick={() => setResetPassModal(true)}>
              Reset password
            </Button>
          </div>
        </div>
        <div className="flex flex-col gap-2 px-3 py-2 sm:flex-row sm:items-center md:px-4 md:py-3">
          <div className="grow">
            <h4 className="grow font-semibold">Two-factor authentication</h4>
            <p className="text-sm text-gray-500 dark:text-neutral-400">
              {twoFText}
            </p>
          </div>
          <div className="flex min-w-[200px] flex-col items-center gap-2">
            <Button className="w-full" onClick={() => setChangeAuthModal(true)}>
              Change authenticator
            </Button>
            <span className="text-sm opacity-70">
              <strong>{`${
                SpecifiedTwoFacterAuthMethod === 'Email'
                  ? 'Email'
                  : 'Authenticator'
              }`}</strong>
              {`${
                SpecifiedTwoFacterAuthMethod === 'Email'
                  ? ' authentication enabled'
                  : ' app enabled'
              }`}
            </span>
          </div>
        </div>
        <div className="flex flex-col gap-2 px-3 py-2 sm:flex-row sm:items-center md:px-4 md:py-3">
          <div className="grow">
            <h4 className="grow font-semibold">Primary email</h4>
            <p className="text-sm text-gray-500 dark:text-neutral-400">
              {primaryEmailText}
            </p>
          </div>
          <div className="min-w-[200px]">
            <Button
              className="w-full"
              onClick={() => setChangeEmailModal(true)}
            >
              Change email
            </Button>
          </div>
        </div>
      </div>
      <ResetPassword
        setResetPassModal={setResetPassModal}
        resetPassModal={resetPassModal}
      />
      <ChangeEmail
        setChangeEmailModal={setChangeEmailModal}
        changeEmailModal={changeEmailModal}
      />
      <ChangeAuthenticator
        setChangeAuthModal={setChangeAuthModal}
        changeAuthModal={changeAuthModal}
      />
    </>
  );
}
