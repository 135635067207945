import { useReducer, useEffect, useMemo } from 'react';

import { ActivityTrackerFilterType } from '../types/activityTrackerListType';

export const initialState: ActivityTrackerFilterType = {
  firstName: '',
  lastName: '',
  submittedOn: '',
  id: '',
  status: '0',
  formName: '0',
  sortOrder: 'desc',
  sortType: '2',
};

export function activityTrackerFilter(
  state: ActivityTrackerFilterType,
  action: {
    type: keyof ActivityTrackerFilterType | 'reset';
    value: any;
    payload?: any;
    newState?: ActivityTrackerFilterType | undefined;
  },
) {
  switch (action.type) {
    case 'reset':
      return { ...action?.value };
    default:
      return {
        ...state,
        [action.type]: action.value,
      };
  }
}

export function useActivityTrackerFilterReducer(): {
  filterOptions: ActivityTrackerFilterType;
  filterCount: number;
  setValues: (type: keyof ActivityTrackerFilterType, value: any) => void;
  resetFilters: () => void;
  isFiltersApplied: boolean;
} {
  const [filterOptions, setFilterOptions] = useReducer(
    activityTrackerFilter,
    {
      ...JSON.parse(JSON.stringify(initialState)),
    },
    (initial: ActivityTrackerFilterType) => {
      const activityTrackerFilterString =
        window.localStorage.getItem('ActivityTracker');
      if (activityTrackerFilterString) {
        const activityTrackerFilter: ActivityTrackerFilterType = JSON.parse(
          activityTrackerFilterString,
        );
        return {
          ...activityTrackerFilter,
        };
      }

      return {
        ...initial,
      };
    },
  );

  //for filtercount
  const filterCount = useMemo(() => {
    let count: number = 0;
    const obj1: any = { ...initialState };
    const obj2: any = {
      ...JSON.parse(JSON.stringify(filterOptions)),
    };
    delete obj1['sortOrder'];
    delete obj1['sortType'];
    Object.keys(obj1).forEach(key => {
      if (obj1[key] !== obj2[key]) {
        count++;
      }
    });

    if (obj2['submittedOn'] === null && count >= 1) count = count - 1;

    return count;
  }, [filterOptions]);

  function setValues(type: keyof ActivityTrackerFilterType, value: any) {
    setFilterOptions({
      type,
      value,
    });
  }

  useEffect(() => {
    window.localStorage.setItem(
      'ActivityTracker',
      JSON.stringify(filterOptions),
    );
  }, [filterOptions]);

  function resetFilters() {
    setFilterOptions({
      type: 'reset',
      value: {
        ...JSON.parse(JSON.stringify(initialState)),
      },
    });
  }

  const isFiltersApplied: boolean = useMemo(() => {
    const obj1: Partial<ActivityTrackerFilterType> = {
      ...initialState,
    };
    const obj2: Partial<ActivityTrackerFilterType> = {
      ...JSON.parse(JSON.stringify(filterOptions)),
    };

    let diff = false;
    (Object.keys(obj1) as Array<keyof ActivityTrackerFilterType>).forEach(
      (value: keyof ActivityTrackerFilterType) => {
        if (obj1[value] !== obj2[value]) {
          diff = true;
        }
      },
    );
    return diff;
  }, [filterOptions]);

  return {
    filterOptions,
    filterCount,
    setValues,
    resetFilters,
    isFiltersApplied,
  };
}
