import React, { ReactElement, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery, useQueryClient } from 'react-query';

import {
  Alert,
  Button,
  Checkbox,
  Spinner,
  CustomCancelModal,
} from 'src/ui/components';
import { MultiSelectDropdown } from './MultiSelectDropdown';

import { useAuth, useTitle } from 'src/hooks';
import { useSaveFieldConfiguration } from '../api/useSaveFieldConfiguration';

import { useFieldConfigurationStore } from '../store/fieldConfigurationStore';
import { showErrorToast, showSuccessToast } from 'src/utils/ToastNotification';

import { optionType } from '../types/fieldConfigurationStoreTypes';

function ProviderConfiguration(): ReactElement {
  useTitle('Add Provider Field Configuration');

  const { id } = useParams();
  const { token, tokenType } = useAuth();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const basicConfiguration = useFieldConfigurationStore(
    React.useCallback(state => state.basicConfiguration, []),
  );

  const getProviderConfiguration = useFieldConfigurationStore(
    React.useCallback(state => state.getProviderConfiguration, []),
  );

  const searchGroups = useFieldConfigurationStore(
    React.useCallback(state => state.searchGroups, []),
  );

  const isLoading = useFieldConfigurationStore(
    React.useCallback(state => state.isLoading, []),
  );

  const resourceFieldOptions = useFieldConfigurationStore(
    React.useCallback(state => state.resourceFieldOptions, []),
  );

  const selectedOptions = useFieldConfigurationStore(
    React.useCallback(state => state.selectedOptions, []),
  );

  const setSelectedOptions = useFieldConfigurationStore(
    React.useCallback(state => state.setSelectedOptions, []),
  );

  const sameAsSearchResult = useFieldConfigurationStore(
    React.useCallback(state => state.sameAsSearchResult, []),
  );

  const setSameAsSearchResult = useFieldConfigurationStore(
    React.useCallback(state => state.setSameAsSearchResult, []),
  );

  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);

  const deleteProviderStore = useFieldConfigurationStore(
    React.useCallback(state => state.deleteProviderStore, []),
  );

  const { mutate: saveConfiguartion, isLoading: isLoadSaving } =
    useSaveFieldConfiguration();

  useQuery(
    ['get-provider-config', id],
    () => {
      getProviderConfiguration({ token, tokenType, providerId: id });
    },
    { staleTime: Infinity },
  );

  useEffect(() => {
    return () => {
      queryClient.invalidateQueries(['get-provider-config'], {
        refetchActive: false,
      });
    };
  }, [queryClient]);

  function renderSelect({
    key,
    selected,
  }: {
    key: string;
    selected: optionType;
  }): ReactElement {
    return (
      <MultiSelectDropdown
        options={resourceFieldOptions}
        id={key}
        onChange={e => {
          setSelectedOptions(key, e);
          if (key === 'addToReport' || key === 'addToSearchResult')
            setSameAsSearchResult(false);
        }}
        selected={selected}
        selectedOptions={selectedOptions}
      />
    );
  }

  function payloadMapping(obj1: any) {
    return obj1.map((x: optionType) => ({
      fullHeirarchialName: x.label,
      resourceFieldId: x.value,
      resourceFieldName: null,
      resourceFieldType: x.resourceFieldType,
    }));
  }

  function configPayload(): any {
    let payload = { ...basicConfiguration };
    const searchableOptions = { ...selectedOptions };
    delete searchableOptions['addToReport'];
    delete searchableOptions['addToSearchResult'];
    if (selectedOptions) {
      payload = {
        ...payload,
        addToReport:
          selectedOptions['addToReport'] &&
          selectedOptions['addToReport'].length > 0
            ? payloadMapping(selectedOptions['addToReport'])
            : [],
        addToSearchResult:
          selectedOptions['addToSearchResult'] &&
          selectedOptions['addToSearchResult'].length > 0
            ? payloadMapping(selectedOptions['addToSearchResult'])
            : [],
        searchableFields: Object.keys(searchableOptions).flatMap(key => {
          return {
            groupId: key,
            groupName: findSearchGroupName(key),
            fields: payloadMapping(searchableOptions[key]),
          };
        }),
      };
    }
    return payload;
  }

  function findSearchGroupName(id: string): string {
    const group = searchGroups?.find(x => x.id == id);
    if (group) {
      return group.name;
    }
    return '';
  }

  function onSaveHandler() {
    saveConfiguartion(configPayload(), {
      onSuccess: () => {
        showSuccessToast({
          message: 'Provider configuration added successfully.',
        });
        deleteProviderStore();
        navigate('/Resources');
      },
      onError: () => {
        showErrorToast({
          message:
            'Error occured while saving provider configuration. Please try again!',
        });
        deleteProviderStore();
      },
    });
  }

  return (
    <>
      <main className="flex flex-1 flex-col overflow-y-auto overscroll-contain">
        <div className="flex flex-wrap items-center justify-between gap-2 border-t border-gray-200 bg-white p-2 shadow dark:border-neutral-700/50 dark:bg-neutral-800 sm:gap-4 sm:px-3 lg:px-4">
          <div className="flex flex-1 shrink-0 items-end gap-4 sm:gap-8">
            <h2 className="truncate text-lg font-semibold text-gray-950 dark:text-white sm:text-xl sm:tracking-tight">
              Provider configuration
            </h2>
          </div>
        </div>
        <div className="grow overflow-y-scroll p-3 lg:p-4">
          <div className="container mx-auto space-y-2 sm:space-y-4">
            <div>
              <div className="flex w-full flex-wrap items-center justify-between gap-2 rounded-t-lg bg-white px-4 py-2 text-left shadow-sm  focus:outline-none focus-visible:ring focus-visible:ring-light-focus dark:bg-neutral-800">
                <div className="space-y-2">
                  <h3 className="font-medium sm:text-lg">Searchable fields</h3>
                </div>
                <Alert variant="info" size="small">
                  You can select only upto 5 items from each searchable fields.
                </Alert>
              </div>
              <div className="rounded-b-lg border-t bg-gray-50 text-sm text-gray-500 shadow dark:border-neutral-700/50 dark:bg-neutral-800">
                <div className="space-y-5 p-4">
                  <div className="grid grid-cols-1 gap-2 sm:gap-4 lg:grid-cols-2">
                    {searchGroups &&
                      searchGroups.length > 0 &&
                      searchGroups.map(item => (
                        <div key={item.id}>
                          <label
                            htmlFor={`email-at-${item.id}`}
                            className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
                          >
                            {item.name}
                          </label>
                          {renderSelect({
                            key: item.id,
                            selected: selectedOptions[item.id],
                          })}
                        </div>
                      ))}
                  </div>
                  <div className="grid grid-cols-1 lg:grid-cols-2 lg:pr-2">
                    <div className="flex flex-col gap-4">
                      <div>
                        <label
                          htmlFor="email-at-4"
                          className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
                        >
                          Add to search results
                        </label>
                        {renderSelect({
                          key: 'addToSearchResult',
                          selected: selectedOptions['addToSearchResult'],
                        })}
                      </div>
                      <div>
                        <div className="mb-2 flex items-center gap-4">
                          <label
                            htmlFor="email-at-4"
                            className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
                          >
                            Add to report
                          </label>
                          <Checkbox
                            id="intervention"
                            label="Same as search results"
                            checked={sameAsSearchResult === true}
                            onChange={e =>
                              setSameAsSearchResult(e.target.checked)
                            }
                          />
                        </div>
                        {renderSelect({
                          key: 'addToReport',
                          selected: selectedOptions['addToReport'],
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="border-t border-light-light bg-white dark:border-dark-medium dark:bg-neutral-800">
          <div className="container mx-auto flex gap-[1ch] p-2 sm:justify-end sm:p-3">
            <Button
              className="grow sm:grow-0"
              onClick={() => {
                deleteProviderStore();
                navigate('/Resources');
              }}
            >
              Cancel
            </Button>
            <Button
              className="grow sm:grow-0"
              variant="primary"
              onClick={() => {
                if (selectedOptions['addToSearchResult']?.length > 0) {
                  onSaveHandler();
                } else setShowCancelModal(true);
              }}
            >
              Save
            </Button>
          </div>
        </div>
        {showCancelModal === true && (
          <CustomCancelModal
            showCancelModal={showCancelModal}
            setShowCancelModal={setShowCancelModal}
            handleCancel={onSaveHandler}
            component="resource"
          />
        )}
        {(isLoading || isLoadSaving) && (
          <div className="bg-gray-900/8 absolute inset-0 z-10 grid place-content-center backdrop-blur-sm">
            <Spinner />
          </div>
        )}
      </main>
    </>
  );
}

export { ProviderConfiguration };
