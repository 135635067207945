import { useMutation, UseMutationResult } from 'react-query';

import { useAuth } from 'src/hooks';
import { useProgressStore } from 'src/hooks/useProgressStore';

import { getStickyNote } from 'src/utils/urls';

import { StickyNotesType } from '../types/stickynotes';

async function fetchStickyNote({
  token,
  tokenType,
  callId,
}: TArguments): Promise<TResult> {
  const response = await fetch(getStickyNote(callId), {
    method: 'GET',
    headers: {
      Authorization: `${tokenType} ${token}`,
    },
  });
  return response.json();
}

export type TResult = StickyNotesType | undefined;
export type TError = { message: string };
export type TVariables = {
  callId: number;
};
export type TArguments = {
  token: string;
  tokenType: string;
  callId: number;
};

function useGetStickyNote(): UseMutationResult<
  TResult,
  TError,
  TVariables,
  unknown
> {
  const { token, tokenType } = useAuth();
  const { setLoading } = useProgressStore();
  return useMutation(
    async (params: TVariables) => {
      setLoading('isStickyNoteLoading', true);
      if (params.callId !== undefined) {
        const result = await fetchStickyNote({
          token,
          tokenType,
          callId: params.callId,
        });
        if (result) return result;
        else throw result;
      }
    },
    {
      onSettled: () => {
        setLoading('isStickyNoteLoading', false);
      },
    },
  );
}

export { useGetStickyNote };
