import { create } from 'zustand';
import produce from 'immer';
import dayjs from 'dayjs';

import {
  fetchUserDetails,
  fetchDefaultEmailNotificationSettings,
  fetchAccountInfo,
  fetchLimittedAcessLookup,
  fetchLimittedAccessLookupRecords,
  fetchAdminSettings,
  fetchLoggedInExternalUserDtPermissions,
} from './apiStore';
import {
  fetchEmailNotificationSettings,
  fetchEmailNotificationData,
} from 'src/hooks/useEmailNotificationStore';

import {
  hasAllValuesPresent,
  validateEmail,
  validateRequest,
} from '../utils/userHelper';
import {
  dailyAtSettingsId,
  timerPickerDefaultState,
} from 'src/utils/customEmailNotification';

import {
  UserStore,
  GetUserDetailsType,
  EmailSelectType,
  PermissionsType,
  AccountSettings,
  LimitedAccessRecords,
  OptionType,
} from '../types/userStoreTypes';
import { AccountInfo } from 'src/types/AccountInfo.d';
import { CustomEmailNotificationSettingsType } from 'src/types/emailNotification';
import { LimitedAccessLookup } from '../types/limitedAccessRecords';

export const useUserStore = create<UserStore>((set, get) => ({
  isExternalUser: false,
  isLoadingExternalUserPermission: false,
  prevInvalidEmail: '',
  loggedUserDTPermission: [],
  showValidationErr: false,
  isResponseLoading: false,
  isAllEmailNotificationLoading: false, // all notification api response loading
  basicUserDetails: {
    firstName: '',
    lastName: '',
    email: '',
    status: '',
    id: '',
  },

  allowReInvite: false,

  accountsInfo: [],

  userMode: '',

  userSettingsPermissions: [],

  includeEmptyNotification: false,
  customEmailNotificationSettings: [],
  defaultEmailNotification: undefined, //  email notification object initially from api and then as per user selection
  emailNotificationSettings: undefined, //all email notification list as required object
  defaultNotificationId: undefined, // default email notification id coming from api

  accountList: [],
  accountQuestionSettings: [],
  isLookupRecordFetching: false,
  selectedRecordId: [],
  selectedOptions: [],
  isLookupLoading: false,
  isLookupOptionLoading: false,
  isSaveClicked: false,
  isOnboardingCheckLoading: false,

  setPrevInvalidMailId: (val: string) => {
    set(state => ({
      prevInvalidEmail: val,
    }));
  },

  updateBasicUserDetails: (key: string, value: string) => {
    set(state => ({
      basicUserDetails: { ...state.basicUserDetails, [key]: value },
    }));
  },

  setShowValidationErr: (val: boolean) =>
    set(() => ({
      showValidationErr: val,
    })),

  updateAccountInfo: (value: Array<number>) => {
    set(state => ({
      accountsInfo: [...value],
    }));
  },

  getUserDetails: async ({
    id,
    userMode,
    token,
    tokenType,
    isExternalUser,
    userId,
  }: Partial<GetUserDetailsType>) => {
    // if external user, getting dt access of questions.
    if (isExternalUser) {
      set({
        isLoadingExternalUserPermission: true,
      });
      const loggedUserDtPermissions =
        await fetchLoggedInExternalUserDtPermissions({
          id: userId,
          token,
          tokenType,
        });
      const exteranalDtPermission = loggedUserDtPermissions.map(x => {
        return {
          AccountId: x.AccountId,
          AccessTypeId: x.AccessTypeID,
          isDisableFullAccess: x.AccessTypeID === 1,
          Questions:
            x.AccessTypeID == 1 && x.Questions.length > 0
              ? x.Questions.map(question => {
                  return {
                    Id: question.Id,
                    Records: question.Records.filter(
                      option => option.HasAccess === true,
                    ),
                    IsVisible: question.Records.some(
                      option => option.HasAccess === true,
                    ),
                  };
                })
              : [],
        };
      });
      set(() => ({
        isExternalUser: true,
        loggedUserDTPermission: [...exteranalDtPermission],
        isLoadingExternalUserPermission: false,
      }));
    }

    if (get().userMode !== '') {
      set(
        produce(state => {
          state.isResponseLoading = true;
        }),
      );

      const isCopyMode = get().userMode === 'Copy';

      const response = await fetchUserDetails({
        id: id,
        token: token,
        tokenType: tokenType,
      });
      set(
        produce(state => {
          state.basicUserDetails.firstName = isCopyMode
            ? ''
            : response?.FirstName;
          state.basicUserDetails.lastName = isCopyMode
            ? ''
            : response?.LastName;
          state.basicUserDetails.email = isCopyMode ? '' : response?.Email;
          state.basicUserDetails.status = response?.Status;
          state.basicUserDetails.id = isCopyMode ? null : response?.Id;
          state.accountsInfo = response?.AccountIds && [
            ...response?.AccountIds,
          ];
          state.userSettingsPermissions = response?.Permissions;
          state.allowReInvite = response?.AllowReInvite;
        }),
      );
    }

    set(
      produce(state => {
        state.isAllEmailNotificationLoading = true;
      }),
    );

    //get all email notification lists - live, custom, no email
    const allEMailNotifications = await fetchEmailNotificationSettings({
      token: token,
      tokenType: tokenType,
    });

    set(
      produce(state => {
        state.isAllEmailNotificationLoading = false;
        state.emailNotificationSettings = allEMailNotifications?.map(item => {
          return {
            id: item?.Id,
            label: item?.NotifiedAt,
            value: item?.NotificationTimeInterval,
          };
        });
      }),
    );

    // to get default email notification - selected email notification
    if (get().userMode !== 'Edit' && get().userMode !== 'Copy') {
      set(
        produce(state => {
          state.isAllEmailNotificationLoading = true;
        }),
      );

      const defaultNotification = await fetchDefaultEmailNotificationSettings({
        token: token,
        tokenType: tokenType,
      });

      set(
        produce(state => {
          state.defaultNotificationId = defaultNotification;
          const allEmailSettings = get().emailNotificationSettings;
          if (allEmailSettings !== undefined) {
            state.defaultEmailNotification = allEmailSettings?.find(
              item => item?.id == defaultNotification,
            );
          }
        }),
      );

      const adminSettings = await fetchAdminSettings({
        token: token,
        tokenType: tokenType,
      });

      set(
        produce(state => {
          state.isAllEmailNotificationLoading = false;
          const defaultNotification = get().defaultEmailNotification;
          if (
            adminSettings &&
            defaultNotification !== undefined &&
            defaultNotification.id === dailyAtSettingsId
          ) {
            let eachCustomValue = adminSettings?.NotifyTime.split(',');

            let newCustomValues = eachCustomValue?.map(value => {
              return {
                ...timerPickerDefaultState,
                NotifyTime:
                  value !== '' && value !== null
                    ? dayjs(value).format('HH:mm')
                    : timerPickerDefaultState.NotifyTime,
                EpochTimeValue:
                  value !== null && value !== ''
                    ? dayjs(value).unix()
                    : timerPickerDefaultState.EpochTimeValue,
              };
            });
            if (newCustomValues?.length !== 0)
              state.customEmailNotificationSettings = newCustomValues;
            else
              state.customEmailNotificationSettings = timerPickerDefaultState;
          }
        }),
      );
    }

    //on edit fetch email notifications of a particular user
    if (get().userMode === 'Edit' || get().userMode === 'Copy') {
      set(
        produce(state => {
          state.isAllEmailNotificationLoading = true;
        }),
      );

      const response = await fetchEmailNotificationData({
        token: token,
        tokenType: tokenType,
        id: id,
      });

      set(
        produce(state => {
          state.isAllEmailNotificationLoading = false;
          state.includeEmptyNotification = response?.IncludeEmptyNotification;
          if (response?.CustomEmailNotificationSettings?.length !== 0) {
            state.customEmailNotificationSettings =
              response?.CustomEmailNotificationSettings?.map(item => {
                return {
                  NotifyTime: dayjs(item?.NotifyDateTime).format('HH:mm'),
                  NotifyDateTime: item?.NotifyDateTime,
                  EpochTimeValue: item?.EpochTimeValue,
                  Id: item?.Id,
                  UserEmailNotificationId: item?.UserEmailNotificationId,
                };
              });
          } else {
            if (
              response?.EmailNotificationSettingsId ===
              get().defaultEmailNotification?.id
            )
              state.customEmailNotificationSettings = [timerPickerDefaultState];
          }

          let newEmailNotification = get().emailNotificationSettings?.find(
            item => item?.id === response?.EmailNotificationSettingsId,
          );
          if (newEmailNotification === undefined) {
            state.defaultEmailNotification =
              get().emailNotificationSettings?.find(item => item?.id === 1);
          } else {
            state.defaultEmailNotification = newEmailNotification;
          }
        }),
      );
      get().fetchAccountInfo(token, tokenType, id);
    }

    if (get().userMode === '') {
      get().fetchAccountInfo(token, tokenType);
    }
    set({
      isResponseLoading: false,
    });
  },

  deleteUserDetails: () => {
    set(state => {
      return {
        ...state,
        basicUserDetails: {
          firstName: '',
          lastName: '',
          email: '',
          status: '',
          id: '',
        },
        accountsInfo: [],
        accountQuestionSettings: [],
        userSettingsPermissions: [],
        isSaveClicked: false,
        allowReInvite: false,
        customEmailNotificationSettings: [],
        includeEmptyNotification: false,
        selectedRecordId: [],
        showValidationErr: false,
        prevInvalidEmail: '',
      };
    });
  },

  updateUserMode: (value: string) => {
    set(
      produce(state => {
        state.userMode = value;
      }),
    );
  },

  updatePermissions: (value: Array<PermissionsType>) => {
    set(
      produce(state => {
        state.userSettingsPermissions = value;
      }),
    );
  },

  updateDefaultNotification: (value: EmailSelectType) => {
    set(
      produce(state => {
        state.defaultEmailNotification = value;
      }),
    );
  },

  updateIncludeEmptyNotification: (value: boolean) => {
    set(
      produce(state => {
        state.includeEmptyNotification = value;
      }),
    );
  },

  updateCustomEmailNotificationSettings: (
    value: Array<CustomEmailNotificationSettingsType>,
  ) => {
    set(
      produce(state => {
        state.customEmailNotificationSettings = [...value];
      }),
    );
  },

  fetchAccountInfo: async (
    token: string | undefined,
    tokenType: string | undefined,
    id: string | undefined,
  ) => {
    fetchAccountInfo({
      token,
      tokenType,
      id,
    }).then((account: Array<AccountInfo>) => {
      if (account) {
        set(
          produce(state => {
            state.accountList = [...account];
          }),
        );
        if (get().userMode === 'Edit' || get().userMode === 'Copy') {
          const selectedAccounts = get().accountsInfo;
          const accountInfo: Array<AccountInfo> = get().accountList.filter(x =>
            selectedAccounts.includes(x.AccountId),
          );
          //to apply default selection in the record that already selected when edit a user
          const options: Array<string> | undefined | any = accountInfo
            .flatMap(({ Questions }) => Questions)
            .flatMap(({ Records }) => Records)
            .filter(x => x.HasAccess === true)
            .map(({ Id }) => Id);

          const selectedRecords = accountInfo
            .flatMap(({ Questions }) => Questions)
            .flatMap(({ Records }) => Records)
            .filter(x => x.HasAccess === true);
          const selectedQuestions = accountInfo.flatMap(
            ({ Questions }) => Questions,
          );

          const existingRecordOption = selectedRecords?.map(item => {
            return {
              AccountId:
                accountInfo?.find(info =>
                  info?.Questions?.find(question =>
                    question?.Records?.find(record => record?.Id === item?.Id),
                  ),
                )?.AccountId || -1,
              OptionId: item?.Id,
              QuestionId:
                selectedQuestions?.find(qn =>
                  qn?.Records?.find(record => record?.Id === item?.Id),
                )?.Id || -1,
            };
          });
          if (options.length > 0 || existingRecordOption?.length > 0) {
            get().setSelectedRecords(options);
            get().setSelectedOptions(existingRecordOption);
          }
          get().accountsInfo.forEach(id => {
            get().updateAccountSettings(id, true);
          });
        }
      }
    });
  },

  updateAccountSettings: (id: number, isUpdate: boolean) => {
    if (isUpdate) {
      const isAccountPresent =
        get().accountQuestionSettings &&
        get().accountQuestionSettings?.some(obj =>
          Object.values(obj).includes(id),
        );
      if (!isAccountPresent) {
        const accountInfo: AccountInfo | undefined = getAccountDetails(
          get().accountList,
          id,
        );

        if (accountInfo) {
          set(
            produce(state => {
              state.accountQuestionSettings = [
                ...state.accountQuestionSettings,
                {
                  AccountName: accountInfo.Name,
                  AccountId: accountInfo.AccountId,
                  CID: accountInfo.CID,
                  SID: accountInfo.SID,
                  PID: accountInfo.PID,
                  AccessTypeId:
                    get().isExternalUser && get().userMode == ''
                      ? get().loggedUserDTPermission.find(
                          acc => acc.AccountId === accountInfo.AccountId,
                        ).AccessTypeId
                      : accountInfo.AccessTypeID,
                  HasLimitedAccessLookups: accountInfo.HasLimitedAccessLookups,
                  Questions: accountInfo.Questions,
                },
              ];
            }),
          );
        }
      }
    } else {
      const settings: Array<AccountSettings> | undefined =
        get().accountQuestionSettings;
      set(
        produce(state => {
          state.accountQuestionSettings = settings?.filter(
            x => x.AccountId !== id,
          );
        }),
      );
    }
  },
  //update selected account information obj during select-all
  bulckUpdateAccountSettings: (accountIds: Array<number> | [] | undefined) => {
    if (accountIds && accountIds.length) {
      accountIds.forEach(id => {
        get().updateAccountSettings(id, true);
      });
    } else {
      set(
        produce(state => {
          state.accountQuestionSettings = [];
        }),
      );
    }
  },

  updateDtAccessSettings: (key, value, id) => {
    set(
      produce(state => {
        state.accountQuestionSettings = get().accountQuestionSettings?.map(
          x => {
            if (x.AccountId === id) {
              return { ...x, [key]: value };
            }
            return x;
          },
        );
      }),
    );
  },

  fetchLimittedAccessLookup: async (
    token: string,
    tokenType: string,
    id: number,
  ) => {
    set({
      isLookupLoading: true,
    });
    await fetchLimittedAcessLookup({
      token,
      tokenType,
      id,
    }).then((lookup: Array<LimitedAccessLookup> | undefined) => {
      if (lookup?.length) {
        const accountSettings = get().accountQuestionSettings;

        const limittedAccessLookups: Array<{
          TableName: string;
          Id: number;
          Columns: Array<string>;
        }> = lookup.map(({ TableName, Columns, Id }) => ({
          TableName,
          Columns,
          Id,
        }));

        let accountSettingsInfo = accountSettings?.find(
          settings => settings.AccountId === id,
        );
        if (accountSettingsInfo) {
          accountSettingsInfo = {
            ...accountSettingsInfo,
            Questions: [...limittedAccessLookups],
          };
        }

        set(
          produce(state => {
            state.accountQuestionSettings = accountSettings?.map(obj =>
              obj.AccountId === id ? { ...obj, ...accountSettingsInfo } : obj,
            );
            state.isLookupLoading = false;
          }),
        );
      }
    });
  },

  fetchLookupRecords: async (
    token: string,
    tokenType: string,
    accountId: number,
    recordId: number,
  ) => {
    set({
      isLookupOptionLoading: true,
    });
    await fetchLimittedAccessLookupRecords({
      token,
      tokenType,
      accountId,
      recordId,
    }).then((records: LimitedAccessLookup | undefined) => {
      if (records) {
        const accountSettings = get().accountQuestionSettings;
        const { Records } = records;
        set(
          produce(state => {
            state.accountQuestionSettings = accountSettings?.map(account => {
              if (account.AccountId === accountId) {
                const toUpdate: Array<LimitedAccessRecords> | undefined =
                  account.Questions?.map(lookup =>
                    lookup.Id === recordId
                      ? { ...lookup, Records: [...Records] }
                      : lookup,
                  );
                return {
                  ...account,
                  Questions: [...toUpdate],
                };
              }
              return account;
            });
            state.isLookupOptionLoading = false;
          }),
        );
      }
    });
  },

  setIsLookupRecordFetching: (value: boolean) => {
    set(
      produce(state => {
        state.isLookupRecordFetching = value;
      }),
    );
  },
  setSaveClicked: (value: boolean) => {
    set(
      produce(state => {
        state.isSaveClicked = value;
      }),
    );
  },

  setSelectedRecords: (ids: Array<string>) => {
    set(
      produce(state => {
        state.selectedRecordId = [...ids];
      }),
    );
  },

  setSelectedOptions: (options: Array<OptionType>) => {
    set(
      produce(state => {
        state.selectedOptions = [...options];
      }),
    );
  },

  setIsOnboardingCheckLoading: (value: boolean) => {
    set(
      produce(state => {
        state.isOnboardingCheckLoading = value;
      }),
    );
  },

  enableSettings: (type: string): any => {
    switch (type) {
      case 'basic-tab':
        return (
          hasAllValuesPresent(get().basicUserDetails) &&
          validateEmail(get().basicUserDetails.email)
        );
      case 'account-tab':
        return get().accountsInfo.length > 0;

      case 'permission-tab':
        return !validateRequest(
          get().selectedOptions,
          get().accountQuestionSettings,
          get().userSettingsPermissions,
        );

      default:
        return false;
    }
  },
}));

function getAccountDetails(accountList: Array<AccountInfo>, Id: number) {
  return accountList.find(x => x.AccountId === Id);
}
