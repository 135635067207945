import { create } from 'zustand';
import produce from 'immer';
import dayjs from 'dayjs';

import { MultiSelectInputType } from 'src/ui/components';

import { fetchHomeSection } from './apiStore/fetchHomeSection';
import { fetchClinicalCallCount } from './apiStore/fetchHomeSectionCalls';
import { getFormattedArray, getSectionValues } from '../utils/storeHelper';
import { isNullOrUndefined } from 'src/utils/common';
import { fetchAccounts } from './apiStore';

import {
  HomeStore,
  TVariables,
  Section,
  ApiSectionType,
  ChartReqPayloadType,
} from '../types/apiStoreType';

export const useHomeStore = create<HomeStore>((set, get) => ({
  isResponseLoading: false,
  isChartInfoLoading: false,
  sectionValues: [],
  assistanceInfo: [],
  customerCommunication: [],
  masterDashboardData: [],
  clinicalCallChartType: '',
  callData: [],
  chartData: null,
  clinicalCallResolution: {},
  accountIds: [],
  accountOptions: [],
  selectedOptions: [],
  filteredArr: [],
  authInfo: null,

  setSelectedOptions: (values: Array<MultiSelectInputType>) => {
    set(
      produce(state => {
        state.selectedOptions = [...values];
      }),
    );
    const auth = get().authInfo;
    get().getChartInformations({
      token: auth?.token,
      tokenType: auth?.tokenType,
    });
  },

  setFilteredArr: (values: Array<MultiSelectInputType>) => {
    set(
      produce(state => {
        state.filteredArr = [...values];
      }),
    );
  },

  getSectionInfo: async ({ token, tokenType }: Partial<TVariables>) => {
    if (token && tokenType) {
      set(
        produce(state => {
          state.isResponseLoading = true;
          state.authInfo = {
            token,
            tokenType,
          };
        }),
      );
      // load all sections
      const response = await fetchHomeSection({
        token,
        tokenType,
      });

      const groupedObjects: Array<Section> = [];
      const masterDashboardArr: Array<string> = [];

      //mapping the sections array response for rightpanel & chart data
      response.forEach((obj: ApiSectionType) => {
        const existingObjectIndex = groupedObjects.findIndex(
          item => item.sectionTypeId === obj.SectionTypeId,
        );

        if (existingObjectIndex !== -1) {
          //to format the sectionArray key values
          const value = getFormattedArray(obj.SectionValues);
          groupedObjects[existingObjectIndex].sectionValues =
            groupedObjects[existingObjectIndex].sectionValues.concat(value);
        } else {
          const value = getFormattedArray(obj.SectionValues);
          groupedObjects.push({
            sectionTypeId: obj.SectionTypeId,
            description: obj.SectionType.Description,
            sectionValues: [...value],
            template: obj.Template,
          });
        }
        if (obj.SectionType.Description === 'Dashboard') {
          masterDashboardArr.push(obj.Template);
        }
      });

      set(
        produce(state => {
          state.sectionValues = [...groupedObjects];
          state.assistanceInfo = getSectionValues(
            groupedObjects,
            'Assistance Info',
          )?.sectionValues;
          state.customerCommunication = getSectionValues(
            groupedObjects,
            'Customer Communication',
          )?.sectionValues;
          state.clinicalCallChartType = getSectionValues(
            groupedObjects,
            'Clinical Call Resolution',
          )?.template;

          state.callData = getSectionValues(groupedObjects, 'Call Data');
          state.masterDashboardData = [...masterDashboardArr];
        }),
      );
      get().getAccountInfo({ token, tokenType });
      get().getChartInformations({ token, tokenType });
    }

    set(
      produce(state => {
        state.isResponseLoading = false;
      }),
    );
  },
  // get doughnut chart informations
  getChartInformations: async ({ token, tokenType }: Partial<TVariables>) => {
    set(
      produce(state => {
        state.isChartInfoLoading = true;
      }),
    );
    const payload = get().getChartInfoReqPayload();
    const callCountObj: any = await fetchClinicalCallCount({
      token,
      tokenType,
      content: payload,
    });

    set(
      produce(state => {
        state.clinicalCallResolution = callCountObj;
        if (get().clinicalCallChartType === 'call-data-chart') {
          state.chartData = {
            labels: ['Clinical calls', 'Non-Clinical calls'],
            data: [
              isNullOrUndefined(callCountObj?.ClinicalCallsCount)
                ? 0
                : callCountObj?.ClinicalCallsCount,
              isNullOrUndefined(callCountObj?.NonClinicalCallsCount)
                ? 0
                : callCountObj?.NonClinicalCallsCount,
            ],
          };
        } else {
          state.chartData = {
            labels: [
              'Facilitated connection to higher level',
              'Resolved by Protocall',
            ],
            data: [
              isNullOrUndefined(
                callCountObj?.FacilitatedConnectionToHigherlevelCount,
              )
                ? 0
                : callCountObj?.FacilitatedConnectionToHigherlevelCount,
              isNullOrUndefined(callCountObj?.ResolvedByProtoCallCount)
                ? 0
                : callCountObj?.ResolvedByProtoCallCount,
            ],
          };
        }
        state.isChartInfoLoading = false;
      }),
    );
  },

  getChartInfoReqPayload: (): ChartReqPayloadType => {
    const payload = {
      accountIds: get().selectedOptions.map(x => x.value) ?? [],
      fromDate: dayjs().subtract(30, 'day').startOf('day').format(),
      toDate: dayjs().utc().endOf('day').format(),
      isCallData: get().clinicalCallChartType === 'call-data-chart',
    };
    return payload;
  },

  getAccountInfo: async ({ token, tokenType }: Partial<TVariables>) => {
    const accounts = await fetchAccounts({ token, tokenType });

    set(
      produce(state => {
        state.accountOptions = accounts.map(account => ({
          label: `${account.CID}-${account.SID}-${account.PID}-${account.Name}`,
          value: account.AccountId,
        }));
      }),
    );
  },
}));
