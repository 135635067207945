import React, { ReactElement, Fragment, useRef, useState } from 'react';
import * as Tooltip from '@radix-ui/react-tooltip';
import { InformationCircleIcon } from '@heroicons/react/20/solid';

import { Badge } from 'src/ui/components';

export type TooltipWithBadgeType = {
  label: string;
  values: JSX.Element[];
};

export function TooltipWithBadge({
  label,
  values,
}: TooltipWithBadgeType): ReactElement {
  const triggerRef = useRef(null);
  const [open, setOpen] = useState<boolean>(false);
  return (
    <Fragment>
      <Tooltip.Provider>
        <Tooltip.Root
          open={open}
          delayDuration={0}
          onOpenChange={e => {
            setOpen && setOpen(!open);
          }}
        >
          <Tooltip.Trigger
            asChild
            ref={triggerRef}
            onBlur={() => setOpen && setOpen(false)}
            onClick={e => {
              setOpen && setOpen(true);
              e.preventDefault();
            }}
            // onClick={e => e.preventDefault()}
          >
            <Badge className="cursor-pointer">
              {label}{' '}
              <InformationCircleIcon
                className="-mr-0.5 ml-1 h-5 w-5 text-primary"
                aria-hidden="true"
              />
            </Badge>
          </Tooltip.Trigger>
          <Tooltip.Portal>
            <Tooltip.Content
              className="max-h-96 max-w-[53ch] select-none overflow-auto rounded border border-gray-400 bg-white px-3 py-2 text-sm shadow-xl dark:border-transparent dark:bg-neutral-700"
              sideOffset={5}
              onPointerDownOutside={e => {
                if (e.target === triggerRef.current) e.preventDefault();
              }}
            >
              {values}
              <Tooltip.Arrow className="fill-gray-400 dark:fill-neutral-700" />
            </Tooltip.Content>
          </Tooltip.Portal>
        </Tooltip.Root>
      </Tooltip.Provider>
    </Fragment>
  );
}
