import React, { ReactElement, useCallback } from 'react';
import { Editor } from '@tinymce/tinymce-react';

import { Alert, inputLabelClasses } from 'src/ui/components';
import { TinymceEditor } from 'src/ui/components/TinymceEditor';
import {
  situationDescriptionMsg,
  telePhoneIntevensionMsg,
} from './AlertMessages';

import { usePersonAlertStore } from '../../store/personAlertStore';
import { applicationThemeStore } from 'src/store/applicationThemeStore';

type EditorAlertType = {
  title: string;
  keyVal: string;
  editorRef: any;
  required?: boolean;
};

export default function EditorAndAlerts({
  title,
  keyVal,
  editorRef,
  required,
}: EditorAlertType): ReactElement {
  const UpdateAlertInfo = usePersonAlertStore(
    useCallback(state => state.updateAlertDetails, []),
  );

  const alertInfo = usePersonAlertStore(
    useCallback(state => state?.alertDetails, []),
  );

  const theme = applicationThemeStore(
    useCallback(state => state.colourScheme, []),
  );

  return (
    <>
      <div className="grid grid-cols-1 gap-4 gap-y-2 md:grid-cols-2">
        <div className="z-0 order-2 md:order-1 ">
          <label
            className={`${inputLabelClasses} hidden  md:block ${
              required
                ? 'after:ml-0.5 after:text-red-500 after:content-["*"] dark:after:text-red-400'
                : ''
            }`}
          >
            {title}
          </label>
          <TinymceEditor
            reference={(instance: Editor | null) => {
              editorRef.current = instance;
            }}
            onChange={(content: string) => {
              UpdateAlertInfo(keyVal, content);
            }}
            value={alertInfo?.[keyVal]}
            name={keyVal}
            key={theme}
          />
        </div>
        <div className="order-1 md:order-2">
          <label
            className={`${inputLabelClasses} md:invisible ${
              required
                ? 'after:ml-0.5 after:text-red-500 after:content-["*"] dark:after:text-red-400'
                : ''
            } `}
          >
            {title}
          </label>
          <Alert variant="info" className="">
            <div className="space-y-4">
              {keyVal === 'SituationDescription'
                ? situationDescriptionMsg
                : telePhoneIntevensionMsg}
            </div>
          </Alert>
        </div>
      </div>
    </>
  );
}
