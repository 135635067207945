import React, { ReactElement } from 'react';
import { warningMessageSections } from 'src/utils/requiredWarningMsgHelper';

type WarningMsgType = {
  tabs: Array<string>;
  tabContents: Array<{ message: string; link: string }>;
  enableTab: any;
};
function RequiredWarningMessage({
  tabs,
  tabContents,
  enableTab,
}: WarningMsgType): ReactElement {
  // formatted anchor tag for the warning message
  function links(
    message: string,
    link: string,
    hasComma: boolean = false,
    hasAnd: boolean = false,
  ): ReactElement {
    return (
      <>
        {hasComma ? ', ' : ' '}
        {hasAnd ? 'and ' : ' '}
        <a href={link} className="inline-block font-bold underline">
          {message}
        </a>
      </>
    );
  }

  return (
    <div>
      <p>
        There are required field(s) to be filled in
        {warningMessageSections(tabs, tabContents, enableTab)?.length > 0 && (
          <>
            {warningMessageSections(tabs, tabContents, enableTab)?.map(
              (elm, indx) => (
                <>
                  {indx === 0
                    ? links(elm.message, elm.link)
                    : indx === 1
                    ? links(elm.message, elm.link, true)
                    : links(elm.message, elm.link, false, true)}
                </>
              ),
            )}
          </>
        )}
      </p>
    </div>
  );
}

export { RequiredWarningMessage };
