import { useInfiniteQuery, UseInfiniteQueryResult } from 'react-query';
import pDebounce from 'p-debounce';

import { useAuth } from 'src/hooks';

import { getCallDocumentList } from 'src/utils/urls';
import { pageLength } from 'src/utils/appConstants';
import { setCallDocListRequestData } from '../utils/apiHelper/callDocListData';
import { useCallDocStore } from '../store/callDocStore';

import { CallDocumentType, CallType } from '../types/callDocumentList';
import { CallDocumentFilterType } from '../types/filterOptionsType';

export async function fetchCallDocumentsList({
  requestData,
  pageParam,
  token,
  tokenType,
}: TVariables): Promise<TPage> {
  requestData.append('start', `${pageParam}`);
  useCallDocStore.getState().setListPageParam(pageParam);
  const response = await fetch(getCallDocumentList, {
    method: 'POST',
    headers: {
      Authorization: `${tokenType} ${token}`,
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: requestData,
  });
  return response.json();
}

export type TPage = {
  data: Array<CallDocumentType>;
  draw: number;
  error: Error | null;
  isAutoRefreshEnabled: boolean;
  recordsFiltered: number;
  recordsTotal: number;
};

export type TError = { message: string };

export type TVariables = {
  requestData: any;
  token: string;
  tokenType: string;
  pageParam: number;
};

const debouncedFetch = pDebounce(fetchCallDocumentsList, 500);

function useGetCallDocumentList(
  filterOptions: CallDocumentFilterType,
  callTypeDescription: Array<CallType> | undefined,
  hasOptions: boolean,
): UseInfiniteQueryResult<TPage, unknown> {
  const { token, tokenType } = useAuth();
  return useInfiniteQuery(
    ['get_call_documents', filterOptions],
    ({ pageParam = 0 }) => {
      const requestData = setCallDocListRequestData(
        filterOptions,
        callTypeDescription,
      );
      return debouncedFetch({
        token,
        tokenType,
        requestData,
        pageParam,
      });
    },
    {
      getNextPageParam: (lastPage: TPage, allPages: Array<TPage>) => {
        if (lastPage !== null && lastPage?.data?.length === pageLength)
          return allPages?.length * pageLength;
      },
      getPreviousPageParam: (firstPage: TPage, allPages: Array<TPage>) => {
        if (firstPage !== null && firstPage?.data?.length === pageLength)
          return allPages?.length - 1 * pageLength;
      },

      staleTime: 60 * 1000,
      refetchOnWindowFocus: false,
      enabled: hasOptions,
    },
  );
}
export { useGetCallDocumentList };
