export const pageLength = 100;

export const downloadCsvMsg: string =
  'You will receive an email with a link once the file is ready to be downloaded.';

export const bulkDownloadMsg: string =
  'You will receive an email with the link to download the archived report.';

export const localTzMsg: string =
  'All times are shown in your local timezone unless otherwise noted.';

export const adminNoteMsg: string =
  'Please note: Non-admin users will not see this page.';

export const activeStyles =
  "after:pointer-events-none after:absolute after:inset-0 after:rounded-md after:border-2 after:border-primary-500 after:content-['']  md:after:border-3";

export const passwordChangedSuccessMsg: string =
  'Password has been successfully changed.';

export const passwordMatchMsg: string =
  'New password and Confirm password must match.';

export const passwordEmptyMsg: string =
  'The Password fields must not be empty.';

export const noAccountWarning: string = 'Please select at least one account.';

export const cancelWarningMsg: string =
  'Are you sure you want to cancel? All changes will be lost.';

export const searchResultEmptyMsg: string =
  'The Add to search results field is empty and hence the resource will not appear in the search results in the call center. Are you sure you want to proceed?';

export const systemClockMsg = `The system time on your device appears to be inaccurate,
  potentially impacting application functionality. Please
  synchronize your device's clock with the correct date and
  time. Clicking ok will force you to sign out from the
  application.`;

export const tinymceTextAreaConfig = {
  menubar: false,
  statusbar: false,
  toolbar: false,
};

export const REASON_REGEX = /REASON:(.*?)DURATION:/s;
export const COMMENT_REGEX = /ADDITIONAL COMMENTS:(.*)/s;
export const countryCodeSwitchingWarning: string = `As you have switched to +1 country code, the number you have entered initially has been converted to xxx-xxx-xxxx format and the earlier number has been copied to the comments section`;

export const openStateAccountCount = 15;
export const closedStateAccountCount = 5;
export const usPhoneNumberLength = 12;
export const otherPhoneNumberLength = 15;
