import { useInfiniteQuery, UseInfiniteQueryResult } from 'react-query';
import dayjs from 'dayjs';
import pDebounce from 'p-debounce';

import { useAuth } from 'src/hooks';

import { getTrackerList } from 'src/utils/urls';
import { pageLength } from 'src/utils/appConstants';
import { trimValues } from 'src/utils/common';

import {
  ActivityTrackerListType,
  ActivityTrackerFilterType,
} from '../types/activityTrackerListType';

export async function fetchActivityTrackerList({
  requestData,
  pageParam,
  token,
  tokenType,
}: TVariables): Promise<TPage> {
  requestData.append('start', `${pageParam}`);

  const response = await fetch(getTrackerList, {
    method: 'POST',
    headers: {
      Authorization: `${tokenType} ${token}`,
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: requestData,
  });
  return response.json();
}

export type TPage = {
  data: Array<ActivityTrackerListType>;
  draw: number;
  error: Error | null;
  isAutoRefreshEnabled: boolean;
  recordsFiltered: number;
  recordsTotal: number;
};

export type TError = { message: string };

export type TVariables = {
  requestData: any;
  token: string;
  tokenType: string;
  pageParam: number;
};

const debouncedFetch = pDebounce(fetchActivityTrackerList, 500);

function setRequestData(filterOptions: ActivityTrackerFilterType): FormData {
  const formData: any = new URLSearchParams();

  formData.append('draw', '1');
  formData.append('length', `${100}`);
  formData.append('timezoneOffset', `${new Date().getTimezoneOffset()}`);
  formData.append('columns[0][data]', 'FirstName');
  formData.append('columns[0][name]', '');
  formData.append('columns[0][searchable]', 'true');
  formData.append('columns[0][orderable]', 'true');
  formData.append('columns[0][search][regex]', 'false');
  formData.append(
    'columns[0][search][value]',
    filterOptions?.firstName ? trimValues(filterOptions?.firstName) : '',
  );

  formData.append('columns[1][data]', 'LastName');
  formData.append('columns[1][name]', '');
  formData.append('columns[1][searchable]', 'true');
  formData.append('columns[1][orderable]', 'true');
  formData.append('columns[1][search][regex]', 'false');
  formData.append(
    'columns[1][search][value]',
    filterOptions?.lastName ? trimValues(filterOptions?.lastName) : '',
  );

  formData.append('columns[2][data]', 'SubmittedOn');
  formData.append('columns[2][name]', '');
  formData.append('columns[2][searchable]', 'true');
  formData.append('columns[2][orderable]', 'true');
  formData.append('columns[2][search][regex]', 'false');
  formData.append(
    'columns[2][search][value]',
    filterOptions?.submittedOn
      ? dayjs(filterOptions?.submittedOn)?.format('MM/DD/YYYY')
      : '',
  );

  formData.append('columns[3][data]', 'Id');
  formData.append('columns[3][name]', '');
  formData.append('columns[3][searchable]', 'true');
  formData.append('columns[3][orderable]', 'true');
  formData.append('columns[3][search][regex]', 'false');
  formData.append(
    'columns[3][search][value]',
    filterOptions?.id ? trimValues(filterOptions?.id) : '',
  );

  formData.append('columns[4][data]', 'IsActive');
  formData.append('columns[4][name]', '');
  formData.append('columns[4][searchable]', 'true');
  formData.append('columns[4][orderable]', 'true');
  formData.append('columns[4][search][regex]', 'false');
  formData.append(
    'columns[4][search][value]',
    filterOptions?.status && filterOptions?.status !== '0'
      ? filterOptions?.status
      : '',
  );

  formData.append('columns[5][data]', 'AccountId');
  formData.append('columns[5][name]', '');
  formData.append('columns[5][searchable]', 'true');
  formData.append('columns[5][orderable]', 'true');
  formData.append('columns[5][search][regex]', 'false');
  formData.append(
    'columns[5][search][value]',
    filterOptions?.formName && filterOptions?.formName !== '0'
      ? filterOptions?.formName
      : '',
  );

  formData.append('order[0][column]', filterOptions?.sortType);
  formData.append('order[0][dir]', filterOptions?.sortOrder);

  formData.append('search[value]', '');
  formData.append('search[regex]', 'false');

  return formData;
}

function useGetActivityTrackerList(
  filterOptions: ActivityTrackerFilterType,
): UseInfiniteQueryResult<TPage, unknown> {
  const { token, tokenType } = useAuth();

  return useInfiniteQuery(
    ['get_tracker_list', filterOptions],
    ({ pageParam = 0 }) => {
      const requestData = setRequestData(filterOptions);

      return debouncedFetch({
        token,
        tokenType,
        requestData,
        pageParam,
      });
    },
    {
      getNextPageParam: (lastPage: TPage, allPages: Array<TPage>) => {
        if (lastPage !== null && lastPage?.data?.length === pageLength)
          return allPages?.length * pageLength;
      },
      getPreviousPageParam: (firstPage: TPage, allPages: Array<TPage>) => {
        if (firstPage !== null && firstPage?.data?.length === pageLength)
          return allPages?.length - 1 * pageLength;
      },

      staleTime: 60 * 1000,
      refetchOnWindowFocus: false,
    },
  );
}
export { useGetActivityTrackerList };
