import React, {
  ReactElement,
  useCallback,
  useMemo,
  useState,
  useRef,
} from 'react';
import { ExclamationTriangleIcon, XMarkIcon } from '@heroicons/react/20/solid';
import * as Popover from '@radix-ui/react-popover';

import {
  inputLabelClasses,
  Select,
  InputMask,
  inputBaseClasses,
  inputSizeClasses,
  Textarea,
  ReactSelectWithFlags,
} from 'src/ui/components';

import { useGetPhoneType } from 'src/hooks';

import { usePersonRepoStore } from '../../../store/personRepoStore';
import { selectOptions, getphnType } from '../../../utils/personRepoConstants';
import * as utilityFn from 'src/utils/common';
import { optionList, getCountryObj } from 'src/utils/countryCodeCalculation';
import { countryCodeSwitchingWarning } from 'src/utils/appConstants';
import { classNames } from 'src/utils/className';

function ContactDetails({ dialogueRef }: { dialogueRef: any }) {
  const primaryRef = useRef(null);
  const secondaryRef = useRef(null);

  const { data: phoneTypes } = useGetPhoneType();

  const [showPopOverForPrimary, SetShowPopOverForPrimary] = useState(false);
  const [showPopOverForSeconadry, SetShowPopOverForSecondary] = useState(false);

  const userMode = usePersonRepoStore(useCallback(state => state.userMode, []));

  const personDetails = usePersonRepoStore(
    useCallback(state => state.personDetails, []),
  );

  const updatePersonDetails = usePersonRepoStore(
    useCallback(state => state.updatePersonDetails, []),
  );

  const showPrimaryNumberError = usePersonRepoStore(
    useCallback(state => state.showPrimaryNumberError, []),
  );

  const showSecondaryNumberError = usePersonRepoStore(
    useCallback(state => state.showSecondaryNumberError, []),
  );

  const setShowPhnValidation = usePersonRepoStore(
    useCallback(state => state.setShowPhnValidation, []),
  );

  const countryList = usePersonRepoStore(
    useCallback(state => state.countryInfo, []),
  );

  const phoneTypeDropDownList = utilityFn.formatSelectOptionWithoutSelect(
    phoneTypes,
    'Id',
    'Description',
    true,
  );

  const countryCode = useMemo(() => {
    if (countryList) {
      return utilityFn.countryOptions(countryList);
    }
  }, [countryList]);

  //Set mask pattern for primary phn input-mask based on country code
  const maskPatternForPrimaryPhn = useMemo(() => {
    if (personDetails?.PrimaryPhone?.CountryId)
      return utilityFn.patternForMask(
        countryCode,
        personDetails?.PrimaryPhone?.CountryId,
      );
    return '999-999-9999';
  }, [countryCode, personDetails?.PrimaryPhone?.CountryId]);

  //Set mask pattern for secondary phn input-mask based on country code
  const maskPatternForSecondaryPhn = useMemo(() => {
    if (personDetails?.SecondaryPhone?.CountryId)
      return utilityFn.patternForMask(
        countryCode,
        personDetails?.SecondaryPhone?.CountryId,
      );
    return '999-999-9999';
  }, [countryCode, personDetails?.SecondaryPhone?.CountryId]);

  function renderPhoneDetails(
    title: string,
    key: string,
    phoneDetails: any,
    reference: any,
  ): ReactElement {
    // To show validation error for phn number if it don't meet the length requirement.
    function onCountryCodeChange(
      countryId: number,
      phoneNumber: string | undefined,
      phoneType: string,
      comments: string,
      prevCountryId: number,
      key: string,
    ) {
      const isChangingToUSFormat = utilityFn.isCountryCodeInUSFormat(
        prevCountryId,
        countryId,
        countryCode,
      );

      updatePersonDetails('CountryId', countryId, phoneType);

      if (!utilityFn.isNullOrUndefined(phoneNumber)) {
        const formattedPhone = utilityFn.phnNumberToUSFormat(
          phoneNumber,
          countryId,
          countryCode,
        );
        updatePersonDetails('Number', formattedPhone, phoneType);
        if (isChangingToUSFormat) {
          updatePersonDetails(
            'Comments',
            utilityFn.appendToComments(comments, phoneNumber),
            phoneType,
          );
          key == 'PrimaryPhone'
            ? SetShowPopOverForPrimary(true)
            : SetShowPopOverForSecondary(true);
        }
        onPhoneNumberFocusOut(phoneNumber, countryId, key);
      }
    }

    function onPhoneNumberFocusOut(
      phnNumber: any,
      countryId: any,
      key: string,
    ) {
      const isInvalidPhoneNumber = utilityFn.isPhnIsInvalidOrNot(
        phnNumber,
        countryId,
        countryCode,
      );
      setShowPhnValidation(
        key === 'PrimaryPhone'
          ? 'showPrimaryNumberError'
          : 'showSecondaryNumberError',
        isInvalidPhoneNumber,
      );
    }

    return (
      <div className="space-y-1">
        <h4 className="font-semibold text-gray-500 dark:text-neutral-400">
          {title}
        </h4>
        <div className="flex flex-col gap-3 md:flex-row">
          <Select
            id={`${key}phone`}
            label="Phone type"
            options={phoneTypeDropDownList}
            value={phoneDetails?.PhoneTypeId ?? -1}
            onChange={e => {
              updatePersonDetails('PhoneTypeId', e.currentTarget.value, key);
              updatePersonDetails(
                'PhoneType',
                getphnType(phoneTypes, e.currentTarget.value),
                key,
              );
              getphnType(phoneTypes, e.currentTarget.value);
            }}
            disabled={userMode === 'Edit'}
          />
          <div className="grow">
            <label
              htmlFor={`${key}phone`}
              className={classNames(inputLabelClasses, 'mb-0.5')}
            >
              Phone number
            </label>
            <div
              className={`items-stretch" relative flex ${
                userMode === 'Edit' ? '[&>button]:cursor-default' : ''
              }`}
            >
              <Popover.Root
                open={
                  key === 'PrimaryPhone'
                    ? showPopOverForPrimary
                    : showPopOverForSeconadry
                }
                onOpenChange={() => {
                  if (key === 'PrimaryPhone' && showPopOverForPrimary)
                    SetShowPopOverForPrimary(false);
                  if (key !== 'PrimaryPhone' && showPopOverForSeconadry)
                    SetShowPopOverForSecondary(false);
                }}
              >
                <Popover.Trigger tabIndex={-1}>
                  <ReactSelectWithFlags
                    id={`${key}CountryCode`}
                    options={optionList(countryList)}
                    value={getCountryObj(
                      phoneDetails?.CountryId ?? 1,
                      countryList,
                    )}
                    setSelectedOptions={(e: any) => {
                      onCountryCodeChange(
                        parseInt(e.value),
                        phoneDetails?.Number,
                        key,
                        phoneDetails?.Comments,
                        phoneDetails?.CountryId,
                        key,
                      );
                    }}
                    isDisabled={userMode === 'Edit'}
                  />
                </Popover.Trigger>
                <InputMask
                  id={`${key}phonenumber`}
                  className={`${inputBaseClasses} ${inputSizeClasses.medium} !rounded-l-none bg-white outline-none`}
                  value={phoneDetails?.Number ?? ''}
                  onChange={(e: any) => {
                    if (
                      phoneDetails?.CountryId === null ||
                      phoneDetails?.CountryId === undefined
                    ) {
                      updatePersonDetails('CountryId', 1, key);
                    }
                    updatePersonDetails('Number', e?.target?.value, key);
                    if (e.target.value === '')
                      setShowPhnValidation(
                        key === 'PrimaryPhone'
                          ? 'showPrimaryNumberError'
                          : 'showSecondaryNumberError',
                        false,
                      );
                  }}
                  disabled={userMode === 'Edit'}
                  pattern={
                    key === 'PrimaryPhone'
                      ? maskPatternForPrimaryPhn
                      : maskPatternForSecondaryPhn
                  }
                  onBlur={(e: any) =>
                    onPhoneNumberFocusOut(
                      e.target.value,
                      phoneDetails?.CountryId,
                      key,
                    )
                  }
                  onDrop={(e: any) => {
                    e.preventDefault();
                    const droppedValue = e.dataTransfer.getData('Text');
                    const isUsFormat = utilityFn.hasUsFormat(
                      countryCode,
                      phoneDetails?.CountryId,
                    );
                    const phonenumber = utilityFn.formatPhoneNumber(
                      droppedValue,
                      isUsFormat,
                    );

                    updatePersonDetails('Number', phonenumber, key);
                    onPhoneNumberFocusOut(
                      phonenumber,
                      phoneDetails?.CountryId,
                      key,
                    );
                  }}
                />
                <Popover.Portal>
                  <Popover.Content
                    side="top"
                    hideWhenDetached={true}
                    className="relative max-w-60 rounded border border-sky-400 bg-sky-100 p-4 text-sky-950 shadow-xl dark:border-sky-800 dark:bg-sky-950 dark:text-sky-300"
                    collisionBoundary={dialogueRef.current}
                  >
                    <p className="text-balance text-sm">
                      {countryCodeSwitchingWarning}
                    </p>
                    <Popover.Close
                      className="PopoverClose absolute right-1 top-1"
                      aria-label="Close"
                    >
                      <XMarkIcon className="h-5 w-5" />
                    </Popover.Close>
                    <Popover.Arrow className="PopoverArrow fill-sky-600 dark:fill-sky-800" />
                  </Popover.Content>
                </Popover.Portal>
              </Popover.Root>
            </div>
            {(key === 'PrimaryPhone'
              ? showPrimaryNumberError
              : showSecondaryNumberError) && (
              <p className="mt-1 inline-flex items-start gap-1 text-xs text-red-600 dark:text-red-400 md:text-sm">
                <ExclamationTriangleIcon
                  className="relative -bottom-[2px] -ml-0.5 h-5 w-5"
                  aria-hidden="true"
                />
                {utilityFn.validationErrorMsgForPhone(
                  countryCode,
                  phoneDetails?.CountryId,
                )}
              </p>
            )}
          </div>
          <Select
            className="shrink-0"
            id={`${key}oktoleaveMsg`}
            options={selectOptions}
            label="Ok to leave message"
            value={
              phoneDetails?.OkToLeaveMessage !== undefined &&
              phoneDetails?.OkToLeaveMessage === true
                ? 'Yes'
                : phoneDetails?.OkToLeaveMessage === false
                ? 'No'
                : ''
            }
            onChange={(e: any) => {
              updatePersonDetails(
                'OkToLeaveMessage',
                e?.target?.value === 'Yes'
                  ? true
                  : e?.target?.value === 'No'
                  ? false
                  : '',
                key,
              );
            }}
            disabled={userMode === 'Edit'}
          />
        </div>
        <Textarea
          ref={reference}
          rows={1}
          label="Comments (Extension etc.)"
          id={`${key}Comments`}
          value={phoneDetails?.Comments}
          disabled={userMode === 'Edit'}
          onChange={e => updatePersonDetails('Comments', e.target.value, key)}
          onDrop={e => {
            e.preventDefault();
            const dropvalue = e.dataTransfer.getData('Text');
            const currentValue = reference.current.value;
            updatePersonDetails('Comments', currentValue + dropvalue, key);
          }}
        />
      </div>
    );
  }

  return (
    <div>
      <div className="space-y-6 lg:w-2/3">
        <h4 className="mb-3 text-xl text-black dark:text-white">
          Contact details
        </h4>
        {renderPhoneDetails(
          'Primary contact',
          'PrimaryPhone',
          personDetails?.PrimaryPhone,
          primaryRef,
        )}
        {renderPhoneDetails(
          'Secondary contact',
          'SecondaryPhone',
          personDetails?.SecondaryPhone,
          secondaryRef,
        )}
      </div>
    </div>
  );
}
export { ContactDetails };
