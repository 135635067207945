import React, { ReactElement, useCallback, useMemo } from 'react';
import {
  ArrowDownTrayIcon,
  EllipsisHorizontalIcon,
  EnvelopeOpenIcon,
  InformationCircleIcon,
} from '@heroicons/react/20/solid';
import { Disclosure } from '@headlessui/react';

import {
  Button,
  selectBaseClasses,
  selectInputSizeClasses,
  buttonBaseClasses,
  buttonSizeClasses,
  buttonVariantClasses,
  Checkbox,
} from 'src/ui/components';
import { TagStatus } from './index';

import { localTzMsg } from 'src/utils/appConstants';
import { useCallDocStore } from '../store/callDocStore';

import { CallDocumentType, TagType } from '../types/callDocumentList';

type UserActionType = {
  selectedDocs: Array<CallDocumentType>;
  handleSelectAll: () => void;
  isAllChecked: () => boolean;
  tagList: Array<TagType> | undefined;
  setSelectedDocs: (
    value:
      | Array<CallDocumentType>
      | ((prevState: Array<CallDocumentType>) => Array<CallDocumentType>),
  ) => void;
  selectedCount: number;
  isStickyNotePresent: () => void;
  setSelectedFormat: (format: string) => void;
  selectedFormat: string;
  changeReadStatus: any;
};

function UserActions({
  selectedDocs,
  handleSelectAll,
  isAllChecked,
  tagList,
  setSelectedDocs,
  selectedCount,
  isStickyNotePresent,
  setSelectedFormat,
  selectedFormat,
  changeReadStatus,
}: UserActionType): ReactElement {
  const callDocsData = useCallDocStore(
    useCallback(state => state.callDocsData, []),
  );

  const isAllCheckedStatus = useCallDocStore(
    useCallback(state => state.isAllChecked, []),
  );

  const renderedList = useMemo(() => {
    const commonObjects = callDocsData?.filter(objB =>
      selectedDocs?.some(objA => objA?.Id === objB?.Id),
    );
    const renderList = commonObjects?.filter(item => item?.IsPurged !== true);
    return renderList;
  }, [callDocsData, selectedDocs]);

  //for getting the button label
  function getReadStatusLabel() {
    const commonObjects = callDocsData?.filter(objB =>
      selectedDocs?.some(objA => objA?.Id === objB?.Id),
    );
    const val: Array<CallDocumentType> = commonObjects?.filter(
      item => item?.IsRead === false,
    );

    if (val?.length) {
      return 'Mark as read';
    } else return 'Mark as unread';
  }

  //for on changing read status
  function onChangeReadStatus() {
    let isRead: boolean;
    if (getReadStatusLabel() === 'Mark as read') isRead = true;
    else isRead = false;
    const mappingArray = callDocsData?.filter(objB =>
      selectedDocs?.some(objA => objA?.Id === objB?.Id),
    );

    const callIds: Array<{ Id: number; IsRead: boolean }> = mappingArray?.map(
      item => ({ Id: item?.Id, IsRead: isRead }),
    );

    if (callIds && callIds?.length > 0) {
      changeReadStatus(callIds, {
        onSuccess: () => {
          !isAllCheckedStatus && setSelectedDocs([]);
        },
      });
    }
  }

  return (
    <Disclosure>
      <p className="flex min-w-0 items-center justify-end gap-1 border-t bg-blue-50 p-1 font-medium  text-sky-700 shadow dark:border-neutral-700 dark:bg-neutral-800 dark:text-sky-300">
        <InformationCircleIcon
          className="-ml-0.5 hidden h-4 w-4 shrink-0 md:inline-block"
          aria-hidden="true"
        />
        <span className="text-xs">{localTzMsg}</span>
      </p>
      <div className="relative flex min-h-[40px] flex-wrap items-center justify-between gap-x-6 gap-y-3 p-2 py-1 sm:px-3 lg:px-4">
        <div className="flex min-w-0 grow flex-wrap items-center gap-x-3 gap-y-1 text-xs text-gray-600 dark:text-neutral-400 md:justify-start md:text-sm">
          <Checkbox
            id="selectAllItems"
            label={`Select all (${selectedCount})`}
            className="ml-2"
            checked={isAllChecked()}
            onClick={() => handleSelectAll()}
          />
          <span
            aria-hidden="true"
            className="mt-0.5 h-4 border border-l-gray-400"
          />
          <span className="mt-0.5 text-xs !leading-none dark:text-white">
            Showing <span className="font-bold">1</span> to{' '}
            <span className="font-bold">{callDocsData?.length}</span> entries
          </span>
        </div>

        {renderedList?.length >= 1 ? (
          <>
            <div className="hidden flex-wrap items-end gap-6 sm:flex sm:items-stretch">
              <Button
                variant="subtle"
                size="small"
                className="whitespace-nowrap"
                onClick={() => {
                  onChangeReadStatus();
                }}
              >
                <EnvelopeOpenIcon
                  className="-ml-0.5 -mt-1 h-5 w-5"
                  aria-hidden="true"
                />
                {getReadStatusLabel()}
              </Button>
              <TagStatus
                selectedDocs={selectedDocs}
                // setSelectedDocs={setSelectedDocs}
                tagList={tagList}
              />
              <div className="flex flex-col gap-1 sm:flex-row sm:items-center sm:gap-2">
                <label
                  htmlFor="download-format"
                  className="text-xs font-medium"
                >
                  Download as
                </label>
                <div className="relative flex flex-wrap items-stretch">
                  <select
                    id="download-format"
                    className={`${[
                      selectBaseClasses,
                      selectInputSizeClasses.small,
                    ].join(' ')} !w-max min-w-0 flex-auto !rounded-r-none`}
                    onChange={e => {
                      setSelectedFormat(e.currentTarget.value);
                      window.localStorage.setItem(
                        'selectedDowloadFormat',
                        e.currentTarget.value,
                      );
                    }}
                  >
                    <option
                      value="html"
                      selected={selectedFormat === 'html' ? true : false}
                    >
                      HTML
                    </option>
                    <option
                      value="pdf"
                      selected={selectedFormat === 'pdf' ? true : false}
                    >
                      PDF
                    </option>
                    <option
                      value="doc"
                      selected={selectedFormat === 'doc' ? true : false}
                    >
                      DOC
                    </option>
                  </select>
                  <button
                    className={`${[
                      buttonBaseClasses,
                      buttonSizeClasses.small,
                      buttonVariantClasses.subtle,
                    ].join(
                      ' ',
                    )} z-[2] !rounded-l-none ring-1 ring-inset ring-gray-300 dark:ring-neutral-700`}
                    type="button"
                    onClick={() => isStickyNotePresent()}
                  >
                    <ArrowDownTrayIcon
                      className="-ml-0.5 h-4 w-4"
                      aria-hidden="true"
                    />
                    Download
                  </button>
                </div>
              </div>
            </div>
            <Disclosure.Button
              as={Button}
              variant="secondary"
              size="small"
              className="sm:hidden"
            >
              <EllipsisHorizontalIcon className="h-5 w-5" />
            </Disclosure.Button>
          </>
        ) : null}
      </div>

      {/* Actions on mobile resolution - start */}
      {renderedList?.length >= 1 ? (
        <Disclosure.Panel className="w-full bg-gray-100 px-3 pb-5 dark:bg-neutral-950 sm:hidden">
          <div className="flex flex-col gap-3">
            <TagStatus
              selectedDocs={selectedDocs}
              // setSelectedDocs={setSelectedDocs}
              tagList={tagList}
            />
            <div className="flex flex-col gap-1 sm:flex-row sm:items-center sm:gap-2">
              <label htmlFor="download-format" className="text-xs font-medium">
                Download as
              </label>
              <div className="relative flex flex-wrap items-stretch">
                <select
                  id="download-format"
                  className={`${[
                    selectBaseClasses,
                    selectInputSizeClasses.small,
                  ].join(' ')} !w-max min-w-0 flex-auto !rounded-r-none`}
                  onChange={e => setSelectedFormat(e.currentTarget.value)}
                >
                  <option
                    value="html"
                    selected={selectedFormat === 'html' ? true : false}
                  >
                    HTML
                  </option>
                  <option
                    value="pdf"
                    selected={selectedFormat === 'pdf' ? true : false}
                  >
                    PDF
                  </option>
                  <option
                    value="doc"
                    selected={selectedFormat === 'doc' ? true : false}
                  >
                    DOC
                  </option>
                </select>
                <button
                  className={`${[
                    buttonBaseClasses,
                    buttonSizeClasses.small,
                    buttonVariantClasses.subtle,
                  ].join(
                    ' ',
                  )} z-[2] !rounded-l-none ring-1 ring-inset ring-gray-300 dark:ring-neutral-700`}
                  type="button"
                  onClick={() => isStickyNotePresent()}
                >
                  <ArrowDownTrayIcon
                    className="-ml-0.5 h-4 w-4"
                    aria-hidden="true"
                  />
                  Download
                </button>
              </div>
            </div>
            <Button
              variant="secondary"
              size="small"
              className="mt-2"
              onClick={() => {
                onChangeReadStatus();
              }}
            >
              <EnvelopeOpenIcon
                className="-ml-0.5 -mt-1 h-5 w-5"
                aria-hidden="true"
              />
              {getReadStatusLabel()}
            </Button>
          </div>
        </Disclosure.Panel>
      ) : null}
      {/* Actions on mobile resolution - end */}
    </Disclosure>
  );
}

export { UserActions };
