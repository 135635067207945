import React, { useEffect } from 'react';

import { useLocalStorageState } from 'src/hooks/useLocalStorageState';
import { applicationThemeStore } from 'src/store/applicationThemeStore';

export function ColorScheme() {
  const storageKey = 'theme-preference';
  const prefersDarkTheme = window.matchMedia(
    '(prefers-color-scheme: dark)',
  ).matches;
  const [colorScheme, setColorScheme] = useLocalStorageState(
    storageKey,
    prefersDarkTheme ? 'dark' : 'light',
  );

  const setColour = applicationThemeStore(
    React.useCallback(state => state.setColourScheme, []),
  );

  useEffect(() => {
    setColour(colorScheme);
  }, [colorScheme, setColour]);

  const handleColorScheme = () => {
    // flip current value
    theme.value = theme.value === 'light' ? 'dark' : 'light';
    setColour(theme.value);
    setPreference();
  };

  const setPreference = () => {
    setColorScheme(theme.value);
    reflectPreference();
  };

  const reflectPreference = () => {
    const root = window.document.documentElement;
    root.setAttribute('data-theme', theme.value);
    root.setAttribute(
      'data-customer',
      process.env.REACT_APP_THEME === 'lfl' ? 'lfl' : '',
    );

    root.className = '';
    root.classList.add(theme.value);
    if (theme.value === 'dark') {
      root.classList.add('rs-theme-dark');
    }
    if (theme.value === 'light') {
      root.classList.remove('rs-theme-dark');
    }
    document
      .querySelector('#theme-toggle')
      ?.setAttribute('aria-label', theme.value);
  };

  const theme = {
    value: colorScheme,
  };

  reflectPreference();

  window.onload = () => {
    // set on load so screen readers can see latest value on the button
    reflectPreference();
  };

  // sync with system changes
  window
    .matchMedia('(prefers-color-scheme: dark)')
    .addEventListener('change', ({ matches: isDark }) => {
      theme.value = isDark ? 'dark' : 'light';
      setPreference();
    });

  return (
    <button
      className="theme-toggle"
      id="theme-toggle"
      title="Toggles light & dark"
      aria-label="auto"
      aria-live="polite"
      onClick={() => {
        handleColorScheme();
      }}
    >
      <svg
        className="sun-and-moon"
        aria-hidden="true"
        width={24}
        height={24}
        viewBox="0 0 24 24"
      >
        <mask className="moon" id="moon-mask">
          <rect x={0} y={0} width="100%" height="100%" fill="white" />
          <circle cx={24} cy={10} r={6} fill="black" />
        </mask>
        <circle
          className="sun"
          cx={12}
          cy={12}
          r={6}
          mask="url(#moon-mask)"
          fill="currentColor"
        />
        <g className="sun-beams" stroke="currentColor">
          <line x1={12} y1={1} x2={12} y2={3} />
          <line x1={12} y1={21} x2={12} y2={23} />
          <line x1="4.22" y1="4.22" x2="5.64" y2="5.64" />
          <line x1="18.36" y1="18.36" x2="19.78" y2="19.78" />
          <line x1={1} y1={12} x2={3} y2={12} />
          <line x1={21} y1={12} x2={23} y2={12} />
          <line x1="4.22" y1="19.78" x2="5.64" y2="18.36" />
          <line x1="18.36" y1="5.64" x2="19.78" y2="4.22" />
        </g>
      </svg>
    </button>
  );
}
