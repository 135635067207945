import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

import {
  dailyAtSettingsId,
  noDocumentSettingsIds,
} from 'src/utils/customEmailNotification';

import {
  AccountSettings,
  DtAcessType,
  EmailSelectType,
  OptionType,
  PermissionsType,
  UserBasicInfo,
} from '../types/userStoreTypes';
import { CustomEmailNotificationSettingsType } from 'src/types/emailNotification';

export function formatSaveRequestPayload(
  basicDetails: UserBasicInfo,
  accountQuestionSettings: Array<AccountSettings> | undefined,
  selectedPermissions: Array<PermissionsType> | undefined,
  selectedEmailFreequency: EmailSelectType | undefined,
  includeEmptyNotification: boolean | undefined,
  customEmailNotificationSettings:
    | Array<CustomEmailNotificationSettingsType>
    | undefined,
  recordOptions: Array<OptionType>,
  defaultNotificationId: number | undefined,
) {
  return {
    Id: basicDetails.id,
    FirstName: basicDetails?.firstName.trim(),
    LastName: basicDetails?.lastName.trim(),
    UserName: '',
    Email: basicDetails?.email,
    AccountIds: getIds('AccountId', accountQuestionSettings),
    AccountCIDs: getIds('CID', accountQuestionSettings),
    AccountPIDs: getIds('PID', accountQuestionSettings),
    PermissionIds: getPermissionIds(selectedPermissions),
    UserEmailNotification: getUserEmailFeequencyDetails(
      selectedEmailFreequency,
      includeEmptyNotification,
      customEmailNotificationSettings,
      defaultNotificationId,
      selectedPermissions,
    ),
    DTAccess: getAccountDtAccess(accountQuestionSettings, recordOptions),
  };
}

//Get accountid.cid,pid
function getIds(
  key: keyof AccountSettings,
  accountQuestionSettings: Array<AccountSettings> | undefined,
): Array<string | number> {
  if (accountQuestionSettings) {
    switch (key) {
      case 'AccountId':
        return accountQuestionSettings?.map(({ AccountId }) => AccountId);
      case 'CID':
        return accountQuestionSettings?.map(({ CID }) => CID);
      case 'PID':
        return accountQuestionSettings?.map(({ PID }) => PID);
    }
  }
  return [];
}

//get selcted permission ids
function getPermissionIds(
  selectedPermissions: Array<PermissionsType> | undefined,
) {
  const ids = selectedPermissions?.map(({ PermissionId }) => PermissionId);
  if (ids) return ids;
  return [];
}

//email freequency details
function getUserEmailFeequencyDetails(
  selectedEmailFreequency: EmailSelectType | undefined,
  includeEmptyNotification: boolean | undefined,
  customEmailNotificationSettings:
    | Array<CustomEmailNotificationSettingsType>
    | undefined,
  defaultNotificationId: number | undefined,
  selectedPermissions: Array<PermissionsType> | undefined,
) {
  return {
    Id: 0,
    UserId: null,
    EmailNotificationSettingsId: selectedEmailFreequency?.id,
    LastDeliveredOn: dayjs(new Date()).utc().format(),
    EmailNotificationSetting: null,
    CustomEmailNotificationSettings:
      selectedPermissions &&
      selectedPermissions.length > 0 &&
      selectedPermissions.some(x => x.PermissionId === 7)
        ? selectedEmailFreequency?.id === dailyAtSettingsId
          ? getCustomEmailSetting(
              customEmailNotificationSettings,
              defaultNotificationId,
            )
          : null
        : null,
    EpochTimeValueForOnceTwice: 0,
    IncludeEmptyNotification:
      selectedEmailFreequency &&
      noDocumentSettingsIds.includes(selectedEmailFreequency?.id)
        ? false
        : includeEmptyNotification,
  };
}

//custom email freequency settings
function getCustomEmailSetting(
  customEmailNotificationSettings:
    | Array<CustomEmailNotificationSettingsType>
    | undefined,
  defaultNotificationId: number | undefined,
) {
  return customEmailNotificationSettings?.map(
    (settings: CustomEmailNotificationSettingsType) => {
      return {
        EpochTimeValue: epocValueConversion(settings.NotifyTime),
        Id: 0,
        NotifyDateTime: settings.NotifyDateTime,
        NotifyTime: settings.NotifyTime,
        UserEmailNotificationId: defaultNotificationId,
      };
    },
  );
}

//convert the custom notify time to unix timestamp
function epocValueConversion(time: string) {
  const currentDate = dayjs();
  const [hours, minutes] = time.split(':');
  const dateTime = currentDate
    .set('hour', Number(hours))
    .set('minute', Number(minutes));
  return dateTime.unix();
}

//Data access permission settings of questions
function getAccountDtAccess(
  accountQuestionSettings: Array<AccountSettings> | undefined,
  recordOptions: Array<OptionType>,
): Array<DtAcessType> {
  const mappedArray: Array<DtAcessType> = [];
  recordOptions.forEach(({ OptionId, AccountId, QuestionId }) => {
    const mappedObj = mappedArray.find(obj => obj.AccountId === AccountId);

    if (mappedObj) {
      const existingPermission = mappedObj.LimitedPermissions.find(
        p => p.QuestionId === QuestionId,
      );
      if (existingPermission) {
        existingPermission.OptionIds.push(OptionId);
      } else {
        mappedObj.LimitedPermissions.push({
          QuestionId,
          OptionIds: [OptionId],
        });
      }
    } else {
      mappedArray.push({
        AccountId,
        LimitedPermissions: [{ QuestionId, OptionIds: [OptionId] }],
        AccessTypeId: 1,
      });
    }
  });
  const fullAccessAccount: Array<AccountSettings> | undefined =
    accountQuestionSettings?.filter(
      (account: AccountSettings) => account.AccessTypeId === 0,
    );

  if (fullAccessAccount && fullAccessAccount?.length > 0) {
    const array1: Array<DtAcessType> | [] = fullAccessAccount.map(
      (settings: AccountSettings) => {
        const { AccountId, AccessTypeId } = settings;
        return {
          AccountId,
          AccessTypeId,
          LimitedPermissions: [],
        };
      },
    );
    return [...mappedArray, ...array1];
  }
  return mappedArray;
}
