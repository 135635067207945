import React, { useRef, useState, useEffect, useCallback } from 'react';
import Select, { Props as SelectProps, components } from 'react-select';

import {
  ResourceFieldOptionType,
  optionType,
} from '../types/fieldConfigurationStoreTypes';

type MultiSelectDropdownProps = {
  options: Array<ResourceFieldOptionType> | undefined;
  label?: string;
  id: string;
  onChange: (e: any) => void;
  selected: optionType;
  selectedOptions: {
    [key: string]: any;
  };
  menuPlacement?: 'auto' | 'bottom' | 'top';
};

function MultiSelectDropdown({
  options,
  onChange,
  id,
  selected,
  selectedOptions,
  menuPlacement,
}: MultiSelectDropdownProps) {
  const selectRef = useRef<any>(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const MenuList: React.FC<any> = ({ children, ...props }) => {
    // Display a custom message when 5 options are selected based on a specific property (id)
    const { value } = props.selectProps;
    const shouldDisplayMessage = value && value.length === 5;
    return (
      <components.MenuList {...props}>
        {shouldDisplayMessage ? (
          <div className="text-center text-red-600 dark:text-red-400">
            Only upto 5 values can be added.
          </div>
        ) : (
          children
        )}
      </components.MenuList>
    );
  };

  const handleMenuOpen = () => {
    setIsMenuOpen(true);
  };

  const handleMenuClose = () => {
    setIsMenuOpen(false);
    // Check if the selectRef and innerProps exist before calling blur
    if (selectRef.current && selectRef.current.select) {
      const { innerProps } = selectRef.current.select.props;
      if (innerProps && innerProps.onBlur) {
        innerProps.onBlur();
      }
    }
  };

  const handleBlur = useCallback(() => {
    // This function will be triggered on blur of the underlying input element
    if (isMenuOpen) {
      setIsMenuOpen(false);
    }
  }, [isMenuOpen]);

  useEffect(() => {
    const inputRef = selectRef.current?.inputRef;
    if (inputRef) {
      inputRef.addEventListener('blur', handleBlur);

      return () => {
        inputRef.removeEventListener('blur', handleBlur);
      };
    }
  }, [handleBlur, isMenuOpen]);

  return (
    <Select
      ref={ref => (selectRef.current = ref)}
      id={id}
      options={options}
      isMulti
      isSearchable={
        selectedOptions && selectedOptions[id] && selectedOptions[id].length < 5
      }
      closeMenuOnSelect={false}
      onChange={onChange}
      classNamePrefix="multiselect-dropdown"
      className={'multiselect-dropdown'}
      value={selected}
      noOptionsMessage={() => 'No choices to choose from'}
      menuPlacement={menuPlacement ?? 'auto'}
      maxMenuHeight={200}
      components={{ MenuList }}
      onMenuOpen={handleMenuOpen}
      onMenuClose={handleMenuClose}
      menuIsOpen={isMenuOpen}
    />
  );
}

export { MultiSelectDropdown };
