import { useEffect } from 'react';

function useExternalScripts(url: string) {
  useEffect(() => {
    const head = document.querySelector('head');
    const script = document.createElement('script');

    script.setAttribute('src', url);
    script.referrerPolicy = 'origin';
    head?.appendChild(script);

    return () => {
      head?.removeChild(script);
    };
  }, [url]);
}

export { useExternalScripts };
