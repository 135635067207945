import React, {
  ReactElement,
  Fragment,
  useRef,
  useState,
  useCallback,
  useEffect,
} from 'react';
import {
  FunnelIcon,
  ArrowPathIcon,
  InformationCircleIcon,
  NoSymbolIcon,
} from '@heroicons/react/20/solid';
import { Disclosure } from '@headlessui/react';

import { Button, EmptyState, Skeleton, Spinner } from 'src/ui/components';
import {
  ActivityTrackerListItem,
  ActivityTrackerFilter,
  ActivityTrackerPreview,
} from './components';

import { useGetPhoneType, useTitle } from 'src/hooks';
import {
  useGetActivityTrackerList,
  useCancelActivity,
  useGetActivityTrackerPreview,
} from './api/index';

import { pageLength, localTzMsg } from 'src/utils/appConstants';
import { showSuccessToast } from 'src/utils/ToastNotification';
import { useActivityTrackerFilterReducer } from './utils/useActivityTrackerFilterReducer';
import { cancelSuccessMsg } from './utils/activityTrackerConstants';

import { ActivityTrackerListType } from './types/activityTrackerListType';

export default function ActivityTracker(): ReactElement {
  useTitle('Activity Tracker');

  const tbodyRef = useRef() as React.MutableRefObject<HTMLTableSectionElement>;

  const [documentPreviewModal, setDocumentPreviewModal] = useState(false);
  const [selectedTracker, setSelectedTracker] = useState<
    ActivityTrackerListType | undefined
  >();

  const {
    filterOptions,
    setValues,
    filterCount,
    resetFilters,
    isFiltersApplied,
  } = useActivityTrackerFilterReducer();

  const { mutate: cancelActivityTracker, isLoading: isCancelling } =
    useCancelActivity();

  const { data: phoneType, isLoading: isPhoneTypeLoading } = useGetPhoneType();

  const {
    data: activityTrackerData,
    isLoading,
    isFetching,
    isRefetching,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  } = useGetActivityTrackerList(filterOptions);

  const {
    data: previewData,
    refetch: previewRefetch,
    isRefetching: isPreviewRefetching,
    isLoading: isPreviewLoading,
  } = useGetActivityTrackerPreview(selectedTracker?.Id);

  const isSpinnerLoading =
    isFetchingNextPage ||
    isRefetching ||
    isCancelling ||
    isPreviewRefetching ||
    isPreviewLoading;

  const handleScroll = useCallback(
    (e: any) => {
      if (tbodyRef.current === e.target) {
        const bottom =
          e.target.clientHeight - 10 <
            e.target.scrollHeight - e.target.scrollTop &&
          e.target.scrollHeight - e.target.scrollTop <
            e.target.clientHeight + 10;
        if (bottom) {
          hasNextPage && fetchNextPage();
        }
      }
    },
    [fetchNextPage, hasNextPage],
  );

  function cancelActivity() {
    if (selectedTracker) {
      cancelActivityTracker(selectedTracker?.Id, {
        onSuccess: () => {
          showSuccessToast({ message: cancelSuccessMsg });
          setSelectedTracker(undefined);
        },
      });
    }
  }

  function handleRowClick(item: ActivityTrackerListType) {
    if (selectedTracker?.Id === item?.Id) setSelectedTracker(undefined);
    else setSelectedTracker(item);
  }

  function onPreview(activityTracker: ActivityTrackerListType) {
    setSelectedTracker(activityTracker);
    setTimeout(() => {
      previewRefetch().then(() => {
        setDocumentPreviewModal(true);
      });
    }, 200);
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, true);
    return () => {
      window.removeEventListener('scroll', handleScroll, true);
    };
  }, [handleScroll]);

  function renderPageDetails(): ReactElement {
    return (
      <>
        {activityTrackerData?.pages &&
          activityTrackerData.pages.length > 0 &&
          activityTrackerData?.pages[0]?.data?.length > 0 &&
          activityTrackerData.pages[activityTrackerData.pages.length - 1]?.data
            .length && (
            <>
              Showing 1 to{' '}
              <strong>
                {activityTrackerData?.pages[
                  activityTrackerData?.pages?.length - 1
                ]?.data?.length === pageLength
                  ? activityTrackerData?.pages?.length * pageLength
                  : (activityTrackerData?.pages?.length - 1) * pageLength +
                    activityTrackerData?.pages[
                      activityTrackerData?.pages?.length - 1
                    ]?.data.length}{' '}
              </strong>
              of{' '}
              <strong>
                {
                  activityTrackerData?.pages[
                    activityTrackerData?.pages?.length - 1
                  ]?.recordsFiltered
                }
              </strong>{' '}
              entries (filtered from{' '}
              <strong> {activityTrackerData?.pages[0]?.recordsTotal} </strong>{' '}
              total entries)
              {isFetching ? ' | Processing' : ''}
            </>
          )}
      </>
    );
  }

  function renderHeader(): ReactElement {
    return (
      <Disclosure as="div" className="relative">
        {({ open }) => (
          <>
            <div className="flex items-center justify-between gap-4 border-t border-gray-200 bg-white px-2 py-1 shadow dark:border-neutral-700/50 dark:bg-neutral-800 sm:px-3 lg:px-4">
              <div className="flex min-w-0 flex-1 items-end gap-4 sm:gap-6">
                <h2 className="truncate text-lg font-semibold text-gray-950 dark:text-white sm:text-xl sm:tracking-tight">
                  Activity tracker
                </h2>
                <div className="mt-1 flex items-center gap-4 sm:mt-0">
                  <div className="relative inline-flex">
                    <Disclosure.Button
                      as={Button}
                      size="small"
                      variant={open ? 'primary' : 'subtle'}
                    >
                      <FunnelIcon
                        className="-ml-0.5 h-5 w-5"
                        aria-hidden="true"
                      />
                      <span className="hidden sm:inline-block">Filter</span>
                    </Disclosure.Button>
                    <span className="absolute right-0 top-0 -mr-1.5 -mt-1.5 flex h-5 w-5">
                      {filterCount > 0 ? (
                        <span
                          className={`relative inline-flex h-5 w-5 items-center justify-center rounded-full bg-red-600 text-xs font-bold leading-none text-white`}
                        >
                          {filterCount}
                        </span>
                      ) : null}
                    </span>
                  </div>

                  <Button
                    variant="subtle"
                    size="small"
                    onClick={() => {
                      setSelectedTracker(undefined);
                      resetFilters();
                    }}
                  >
                    <ArrowPathIcon
                      className="-ml-0.5 h-5 w-5"
                      aria-hidden="true"
                    />
                    <span className="hidden sm:block">Reset</span>
                  </Button>
                </div>
              </div>
            </div>
            <ActivityTrackerFilter
              setValues={setValues}
              filterOptions={filterOptions}
              setSelectedTracker={setSelectedTracker}
              resetFilters={resetFilters}
            />
          </>
        )}
      </Disclosure>
    );
  }

  function renderInfoText(): ReactElement {
    return (
      <p className="flex min-w-0 items-center justify-end gap-1 border-t bg-blue-50 p-1 font-medium  text-sky-700 shadow dark:border-neutral-700 dark:bg-neutral-800 dark:text-sky-300">
        <InformationCircleIcon
          className="-ml-0.5 hidden h-4 w-4 shrink-0 md:inline-block"
          aria-hidden="true"
        />
        <span className="text-xs">{localTzMsg}</span>
      </p>
    );
  }

  return (
    <main className="flex flex-1 flex-col overflow-y-auto overscroll-contain">
      {renderHeader()}
      {renderInfoText()}
      <div className="flex min-h-[40px] items-center justify-between gap-x-4 gap-y-2 px-2 py-1 sm:px-3 md:flex-wrap lg:px-4">
        <p className="shrink text-xs text-gray-600 dark:text-neutral-400">
          {renderPageDetails()}
        </p>

        {!isLoading && (
          <Button
            variant={`${
              selectedTracker?.FormStatus === 4 ||
              selectedTracker?.FormStatus === 3 ||
              selectedTracker === undefined
                ? 'subtle'
                : 'secondary'
            }`}
            size="small"
            onClick={() => cancelActivity()}
            // className={`${
            //   selectedTracker?.FormStatus === 4 ||
            //   selectedTracker?.FormStatus === 3 ||
            //   selectedTracker === undefined
            //     ? 'hidden'
            //     : ''
            // } md:me-4	`}
            className="md:me-4"
            disabled={
              selectedTracker?.FormStatus === 4 ||
              selectedTracker?.FormStatus === 3 ||
              selectedTracker === undefined
            }
          >
            <NoSymbolIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
            <span className="">Cancel</span>
          </Button>
        )}
      </div>

      <div
        className="relative flex-1 space-y-2 overflow-y-scroll px-2 pb-2 sm:px-3 sm:pb-3 lg:px-4 lg:pb-4"
        ref={tbodyRef}
      >
        {!isLoading && activityTrackerData?.pages ? (
          <>
            {activityTrackerData?.pages.map((page: any, index: number) => (
              <Fragment key={`${page} - ${index + 1}`}>
                {page?.data && page?.data?.length ? (
                  page?.data.map(
                    (
                      activityTracker: ActivityTrackerListType,
                      indx: number,
                    ) => (
                      <ActivityTrackerListItem
                        key={`${activityTracker.Id}-${indx + 1}`}
                        activityTracker={activityTracker}
                        handleRowClick={handleRowClick}
                        selectedTracker={selectedTracker}
                        onPreview={onPreview}
                      />
                    ),
                  )
                ) : (
                  <div className="absolute inset-0 grid place-content-center">
                    <EmptyState message="No information available" />
                  </div>
                )}
              </Fragment>
            ))}
          </>
        ) : (
          (isLoading || isFetching) && <Skeleton count={8} />
        )}
        {isSpinnerLoading && (
          <div className="fixed inset-0 z-20 grid place-content-center">
            <Spinner size="large" />
          </div>
        )}
      </div>
      {previewData && !isPreviewLoading && (
        <ActivityTrackerPreview
          documentPreviewModal={documentPreviewModal}
          setDocumentPreviewModal={setDocumentPreviewModal}
          previewData={previewData}
          phoneType={phoneType}
          setSelectedTracker={setSelectedTracker}
        />
      )}
    </main>
  );
}
