import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Switch from '@radix-ui/react-switch';
import * as Popover from '@radix-ui/react-popover';

import { SquaresPlusIcon, XMarkIcon } from '@heroicons/react/20/solid';

import { Button } from 'src/ui/components';

import { useSaveDashboardSettings } from '../api';
import { useAuth } from 'src/hooks';

import { useHomeStore } from '../store/homeStore';

type WidgetListType = {
  dashSettings: Array<string> | undefined;
};

function WidgetList({ dashSettings }: WidgetListType) {
  const navigate = useNavigate();
  const { isExternalUser } = useAuth();

  const masterDashboardData = useHomeStore(
    useCallback(state => state.masterDashboardData, []),
  );

  const { mutate: saveDashSettings } = useSaveDashboardSettings();

  const filteredMasterData = useMemo(() => {
    if (masterDashboardData && !isExternalUser) {
      return masterDashboardData.filter(item => item !== 'My collegues');
    } else return masterDashboardData;
  }, [isExternalUser, masterDashboardData]);

  const [selectedOptions, setSelectedOptions] = useState<Array<string>>([
    ...filteredMasterData,
  ]);

  function isChecked(widgetItem: string): boolean {
    const item =
      selectedOptions &&
      selectedOptions.find(selectedColumn => selectedColumn === widgetItem);
    if (item) return true;
    else return false;
  }

  function handleWidgetToggle(widgetItem: string) {
    const index = selectedOptions.findIndex(item => item === widgetItem);
    if (index > -1) {
      selectedOptions.splice(index, 1);
      setSelectedOptions([...selectedOptions]);
    } else {
      setSelectedOptions([...selectedOptions, widgetItem]);
    }
  }

  function handleApply() {
    saveDashSettings(
      { saveRequest: selectedOptions },
      {
        onError: ({ message }: { message: string }) => {
          if (message === 'Expired') navigate('/Home/Expired');
        },
      },
    );
  }

  function renderWidgetList(item: string) {
    return (
      <li
        key={item}
        className="flex min-w-0 items-center justify-between gap-3 px-3 py-2"
      >
        <label className="Label font-medium" htmlFor={item}>
          {item === 'My collegues' ? 'My colleagues' : item}
        </label>
        <Switch.Root
          className="relative h-6 w-11 shrink-0 cursor-default rounded-full bg-gray-300 outline-none focus-visible:outline focus-visible:outline-[3px] focus-visible:outline-offset-[3px] focus-visible:outline-light-focus data-[state=checked]:bg-primary-500 dark:bg-neutral-800 dark:focus-visible:outline-dark-focus dark:data-[state=checked]:bg-primary"
          id={item}
          checked={isChecked(item)}
          onCheckedChange={e => handleWidgetToggle(item)}
        >
          <Switch.Thumb className="block h-5 w-5 translate-x-0.5 rounded-full bg-white transition-transform duration-100 will-change-transform data-[state=checked]:translate-x-[22px]" />
        </Switch.Root>
      </li>
    );
  }

  return (
    <div className="flex items-center justify-between gap-3">
      <h3 className="font-medium lg:text-lg">Dashboard</h3>

      <Popover.Root>
        <Popover.Trigger asChild className="group">
          <Button
            onClick={() => {
              if (dashSettings) {
                setSelectedOptions([...dashSettings]);
              }
            }}
          >
            <SquaresPlusIcon className="h-5 w-5 group-data-[state=open]:hidden" />
            <XMarkIcon className="hidden h-5 w-5 group-data-[state=open]:block" />
            <span>Widgets</span>
          </Button>
        </Popover.Trigger>
        <Popover.Portal>
          <Popover.Content
            className="data-[state=open]:data-[side=top]:animate-slideDownAndFade data-[state=open]:data-[side=right]:animate-slideLeftAndFade data-[state=open]:data-[side=bottom]:animate-slideUpAndFade data-[state=open]:data-[side=left]:animate-slideRightAndFade max-w-[300px] rounded-md border bg-white shadow-xl will-change-[transform,opacity] dark:border-dark-medium dark:bg-neutral-600"
            sideOffset={2}
            align="end"
          >
            <>
              <ul className="max-w-xs divide-y divide-light-light dark:divide-dark-medium">
                {filteredMasterData &&
                  filteredMasterData.map(item => renderWidgetList(item))}
              </ul>

              <div className="flex justify-end border-t border-t-light-light p-2 dark:border-t-dark-medium">
                <Popover.Close>
                  <Button
                    variant="primary"
                    onClick={() => {
                      handleApply();
                    }}
                  >
                    Apply
                  </Button>
                </Popover.Close>
              </div>
            </>
          </Popover.Content>
        </Popover.Portal>
      </Popover.Root>
    </div>
  );
}

export { WidgetList };
