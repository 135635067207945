import React, {
  Fragment,
  ReactElement,
  SyntheticEvent,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useQueryClient } from 'react-query';
import {
  ExclamationTriangleIcon,
  XMarkIcon,
  InformationCircleIcon,
} from '@heroicons/react/20/solid';
import { Transition, Dialog } from '@headlessui/react';
import * as Popover from '@radix-ui/react-popover';

import {
  Button,
  Select,
  TextField,
  Checkbox,
  Textarea,
  ReactSelect,
  inputLabelClasses,
  InputMask,
  inputBaseClasses,
  inputSizeClasses,
  Spinner,
  ReactSelectWithFlags,
  TimezoneIntegratedDateRangePicker,
} from 'src/ui/components';

import { useGetTimezone, useGetPhoneType, useGetCountry } from 'src/hooks';
import { useMutableObserver } from 'src/hooks/useMutableObserver';
import {
  useGetCallTakerGroup,
  useGetClinicianList,
  useSubmitScheduledCall,
} from '../api';

import { useActivityStore } from '../store/activityTrackerStore';
import * as utilityFn from 'src/utils/common';
import { leaveMessage, warningMsg } from '../utils/activityTrackerConstants';
import { formatScheduleCall } from '../utils/activityTrackerHelperFn';
import { showErrorToast, showSuccessToast } from 'src/utils/ToastNotification';
import { optionList, getCountryObj } from 'src/utils/countryCodeCalculation';
import { countryCodeSwitchingWarning } from 'src/utils/appConstants';
import { classNames } from 'src/utils/className';
import {
  getOffset,
  getTimeZone,
  getCurrentDate,
} from 'src/utils/datepickerHelper';

import { TextFieldType } from '../types/activityTrackerStore';

type InputType = {
  sliderOpenStatus: boolean;
  setSliderOpenStatus: (val: boolean) => void;
};

function ActivityTrackerScheduleCall({
  sliderOpenStatus,
  setSliderOpenStatus,
}: InputType) {
  const queryClient = useQueryClient();

  const [showPopOver, setShowPopOver] = useState(false);

  const warningMsgRef = useRef<HTMLDivElement | null>(null);
  const dateRangeWrapper = useRef<any>();
  const { isMessageChanged } = useMutableObserver(warningMsgRef);
  const [showPhnNumberError, setShowPhnNumberError] = useState<boolean>(false);
  const { data: timezone, isLoading: isTimeZoneLoading } = useGetTimezone();
  const { data: phoneType, isLoading: isPhoneTypeLoading } = useGetPhoneType();
  const { data: clinicianList, isLoading: isClinicianListLoading } =
    useGetClinicianList();
  const { data: callTakerGroup, isLoading: isCalltakerLoading } =
    useGetCallTakerGroup();
  const { mutate: scheduleCall, isLoading: submitCallLoading } =
    useSubmitScheduledCall();

  const updateScheduledCallDetails = useActivityStore(
    useCallback(state => state.updateScheduledCallDetails, []),
  );

  const scheduledCallDetails = useActivityStore(
    useCallback(state => state.callDetails, []),
  );

  const deleteScheduleCallDetails = useActivityStore(
    useCallback(state => state.deleteScheduleCallDetails, []),
  );

  const showValidationMsg = useActivityStore(
    useCallback(state => state.showValidationMsg, []),
  );

  const isSaveClicked = useActivityStore(
    useCallback(state => state.isSaveClicked, []),
  );

  const setSaveClicked = useActivityStore(
    useCallback(state => state.setSaveClicked, []),
  );

  const isDetailsLoading = useActivityStore(
    useCallback(state => state.isDetailsLoading, []),
  );

  const countryList = useActivityStore(
    useCallback(state => state.countryInfo, []),
  );

  const countryCode = useMemo(() => {
    if (countryList) {
      return utilityFn.countryOptions(countryList);
    }
  }, [countryList]);

  const timeZone = useMemo(() => {
    return utilityFn.formatSelectOption(timezone, 'Id', 'Abbreviation');
  }, [timezone]);

  const phoneTypeList = useMemo(() => {
    return utilityFn.formatSelectOption(phoneType, 'Id', 'Description');
  }, [phoneType]);

  //getting the timezone value to pass on daterangepciker
  const timeZoneValue = useMemo(() => {
    const timeZonVal = timezone?.find(
      item =>
        item.Id?.toString() === scheduledCallDetails?.timezone?.toString(),
    );

    return timeZonVal;
  }, [scheduledCallDetails?.timezone, timezone]);

  //getting the offset value for timezone
  const offset = useMemo(() => {
    return timeZoneValue !== undefined
      ? getOffset(timeZoneValue)
      : getTimeZone();
  }, [timeZoneValue]);

  const isSpinnerShowing =
    isTimeZoneLoading ||
    isPhoneTypeLoading ||
    isClinicianListLoading ||
    isCalltakerLoading ||
    submitCallLoading ||
    isDetailsLoading;

  function handleCancel() {
    setShowPhnNumberError(false);
    setSaveClicked(false);
    setSliderOpenStatus(false);
    deleteScheduleCallDetails();
  }

  function onScheduleCall() {
    const date1 = scheduledCallDetails?.scheduledTime?.split('-')[0];
    const date2 = getCurrentDate(offset);
    if (
      !showValidationMsg('phnNumber') ||
      !showValidationMsg('timezone') ||
      !showValidationMsg('scheduledTime') ||
      !showValidationMsg('caseId') ||
      showPhnNumberError
    )
      return;
    const formattedCall: any =
      clinicianList && formatScheduleCall(scheduledCallDetails, clinicianList);

    scheduleCall(formattedCall, {
      onSuccess: () => {
        setSliderOpenStatus(false);
        showSuccessToast({ message: 'Successfully scheduled the call.' });
        queryClient.invalidateQueries('get_tracker_list');
        deleteScheduleCallDetails();
      },
      onError: (msg: any) => {
        showErrorToast({ message: msg?.Message });
      },
    });
  }

  function emailNotificationOptions() {
    return clinicianList?.filter(x => x.value !== -1);
  }

  //Set mask pattern for input-mask based on country code
  const maskPattern = useMemo(() => {
    if (scheduledCallDetails?.countryId)
      return utilityFn.patternForMask(
        countryCode,
        scheduledCallDetails?.countryId,
      );
    return '999-999-9999';
  }, [countryCode, scheduledCallDetails?.countryId]);

  function onCountryCodeChange(e: string) {
    const countryId = parseInt(e);
    const phoneNumber = scheduledCallDetails?.phoneNumber;

    const isChangingToUSFormat = utilityFn.isCountryCodeInUSFormat(
      scheduledCallDetails?.countryId,
      countryId,
      countryCode,
    );

    updateScheduledCallDetails('countryId', countryId);

    if (!utilityFn.isNullOrUndefined(phoneNumber)) {
      const formattedPhone = utilityFn.formattedPhoneNumber(
        phoneNumber,
        countryId,
      );
      updateScheduledCallDetails('phoneNumber', formattedPhone);
      if (isChangingToUSFormat) {
        updateScheduledCallDetails(
          'comments',
          utilityFn.appendToComments(
            scheduledCallDetails?.comments,
            phoneNumber,
          ),
        );
        setShowPopOver(true);
      }
      onPhoneNumberFocusOut(phoneNumber, countryId);
    }
  }

  function onPhoneNumberFocusOut(phnNumber: any, countryId: any) {
    const isInvalidPhoneNumber = utilityFn.isPhnIsInvalidOrNot(
      phnNumber,
      countryId,
      countryCode,
    );

    setShowPhnNumberError(isInvalidPhoneNumber);
  }

  function renderTextField({
    id,
    value,
    key,
    label,
    type,
    className,
    disabled,
    required,
    errorHighlight,
  }: TextFieldType): ReactElement {
    return (
      <TextField
        id={id}
        label={label}
        type={type}
        className={className}
        disabled={disabled}
        required={required}
        value={value}
        onChange={e => {
          if (type === 'number' && parseInt(e.target.value) <= 0) {
            return;
          }
          key && updateScheduledCallDetails(key, e.target.value);
        }}
        errorHighlight={errorHighlight}
        min={type === 'number' ? 1 : ''}
      />
    );
  }

  function renderDatePicker({
    id,
    title,
    placeholder,
  }: {
    id: string;
    title: string;
    placeholder?: string;
  }): ReactElement {
    const date1 = scheduledCallDetails?.scheduledTime?.split('-')[0];
    const date2 = getCurrentDate(offset);
    return (
      <div>
        <div className="flex items-center gap-2">
          {isSaveClicked && !showValidationMsg('scheduledTime') && (
            <div className="h-3 w-3">
              <span className="relative flex h-3 w-3 items-center justify-center">
                <span className="absolute inline-flex h-full w-full animate-ping rounded-full bg-red-400 opacity-75" />
                <span className="relative inline-flex h-2 w-2 rounded-full bg-red-500" />
              </span>
            </div>
          )}
          <label
            htmlFor="phn-number"
            className={[
              inputLabelClasses,
              'mb-0.5  after:text-red-500 after:content-["*"] dark:after:text-red-400',
            ].join(' ')}
          >
            {title}
          </label>
        </div>
        <TimezoneIntegratedDateRangePicker
          id={id}
          rangeType="future"
          format="MM/DD/YYYY HH:mm:ss"
          placeholder={placeholder}
          className="mt-0.5"
          value={scheduledCallDetails?.scheduledTime}
          setValues={updateScheduledCallDetails}
          keyValue="scheduledTime"
          refferenceClass={dateRangeWrapper}
          timezone={timeZoneValue}
          openPos="left"
        />
      </div>
    );
  }

  function renderSelectField({
    id,
    options,
    label,
    key,
    value,
    required,
    errorHighlight,
  }: {
    id: string;
    options: Array<{ value: number; label: string }> | undefined;
    label: string;
    key: string;
    value?: number | string | undefined;
    required?: boolean;
    errorHighlight?: boolean;
  }): ReactElement {
    return (
      <Select
        id={id}
        className="w-full sm:w-auto"
        options={options}
        value={value}
        onChange={(e: SyntheticEvent<HTMLSelectElement>) => {
          updateScheduledCallDetails(key, e.currentTarget.value);
        }}
        label={label}
        required={required}
        errorHighlight={errorHighlight}
      />
    );
  }

  function renderCheckBox({
    id,
    label,
    key,
    checked,
  }: {
    id: string;
    label: string;
    key: string;
    checked?: boolean;
  }): ReactElement {
    return (
      <Checkbox
        id={id}
        label={label}
        checked={checked}
        onChange={e => {
          updateScheduledCallDetails(key, e.currentTarget.checked);
          if (key === 'linkExistingCase' && e.currentTarget.checked === false) {
            updateScheduledCallDetails('caseId', '');
          }
          if (key === 'assignedToAnyone') {
            updateScheduledCallDetails('AssignedGroupId', '-1');
            updateScheduledCallDetails('AssignedTo', '-1');
          }
        }}
      />
    );
  }

  function renderInputMask(): ReactElement {
    return (
      <>
        <div className="flex w-full items-center gap-2">
          {isSaveClicked && !showValidationMsg('phnNumber') && (
            <div className="h-3 w-3">
              <span className="relative flex h-3 w-3 items-center justify-center">
                <span className="absolute inline-flex h-full w-full animate-ping rounded-full bg-red-400 opacity-75" />
                <span className="relative inline-flex h-2 w-2 rounded-full bg-red-500" />
              </span>
            </div>
          )}

          <div className="w-full space-y-0.5">
            <label
              className={classNames(
                inputLabelClasses,
                'after:text-red-500 after:content-["*"] dark:after:text-red-400',
              )}
            >
              Phone number - Comments (Extension etc.)
            </label>
            <div className="relative flex items-stretch">{renderPopover()}</div>
            {showPhnNumberError && (
              <p className="mt-1 inline-flex items-center gap-1 text-xs text-red-600 dark:text-red-400 md:text-sm">
                <ExclamationTriangleIcon
                  className="-ml-0.5 h-5 w-5"
                  aria-hidden="true"
                />
                {utilityFn.validationErrorMsgForPhone(
                  countryCode,
                  scheduledCallDetails?.countryId,
                )}
              </p>
            )}

            <Textarea
              rows={1}
              srOnlyLabel="Comments"
              placeholder="Comments (Extension etc.)"
              id="contact-comments"
              value={scheduledCallDetails?.comments}
              onChange={e =>
                updateScheduledCallDetails('comments', e.target.value)
              }
            />
          </div>
        </div>
      </>
    );
  }

  function renderFooterButton(): ReactElement {
    return (
      <div className="flex justify-end gap-3 border-t border-light-light p-3 dark:border-dark-medium">
        {isSaveClicked &&
          (!showValidationMsg('scheduledTime') ||
            !showValidationMsg('phnNumber') ||
            !showValidationMsg('timezone') ||
            !showValidationMsg('caseId')) && (
            <div
              className={`flex items-center gap-2 ${
                isMessageChanged ? 'animate-shake' : ''
              }`}
            >
              <span className="relative flex h-3 w-3 shrink-0 items-center justify-center">
                <span className="absolute inline-flex h-full w-full animate-ping rounded-full bg-red-400 opacity-75" />
                <span className="relative inline-flex h-2 w-2 rounded-full bg-red-500" />
              </span>
              <span className="text-sm font-medium text-red-700 dark:text-red-400">
                {warningMsg}
              </span>
            </div>
          )}
        <Button
          className="grow sm:grow-0"
          onClick={() => {
            handleCancel();
          }}
        >
          Cancel
        </Button>
        <Button
          variant="primary"
          className="grow sm:grow-0"
          disabled={submitCallLoading}
          onClick={() => {
            setSaveClicked(true);
            onScheduleCall();
          }}
        >
          Schedule
        </Button>
      </div>
    );
  }

  function renderPopover(): ReactElement {
    return (
      <Popover.Root
        open={showPopOver}
        onOpenChange={() => {
          if (showPopOver) setShowPopOver(false);
        }}
      >
        <Popover.Trigger tabIndex={-1}>
          <ReactSelectWithFlags
            options={optionList(countryList)}
            value={getCountryObj(scheduledCallDetails?.countryId, countryList)}
            setSelectedOptions={(e: any) => {
              onCountryCodeChange(e?.value);
            }}
          />
        </Popover.Trigger>
        <div className="flex-1">
          <InputMask
            id="phn-number"
            className={classNames(
              inputBaseClasses,
              inputSizeClasses.medium,
              '-ml-px h-full !rounded-l-none outline-none',
            )}
            value={scheduledCallDetails?.phoneNumber}
            pattern={maskPattern}
            onChange={(e: any) => {
              updateScheduledCallDetails('phoneNumber', e.target.value);
            }}
            onBlur={(e: any) =>
              onPhoneNumberFocusOut(
                e.target.value,
                scheduledCallDetails?.countryId,
              )
            }
            onDrop={(e: any) => {
              e.preventDefault();
              const droppedValue = e.dataTransfer.getData('Text');
              const isUsFormat = utilityFn.hasUsFormat(
                countryCode,
                scheduledCallDetails?.countryId,
              );
              const phonenumber = utilityFn.formatPhoneNumber(
                droppedValue,
                isUsFormat,
              );

              updateScheduledCallDetails('phoneNumber', phonenumber);
              onPhoneNumberFocusOut(
                phonenumber,
                scheduledCallDetails?.countryId,
              );
            }}
          />
          <Popover.Portal>
            <Popover.Content
              side="top"
              hideWhenDetached={true}
              className="relative max-w-60 rounded border border-sky-400 bg-sky-100 p-4 text-sky-950 shadow-xl dark:border-sky-800 dark:bg-sky-950 dark:text-sky-300"
            >
              <p className="text-balance text-sm">
                {countryCodeSwitchingWarning}
              </p>
              <Popover.Close
                className="PopoverClose absolute right-1 top-1"
                aria-label="Close"
              >
                <XMarkIcon className="h-5 w-5" />
              </Popover.Close>
              <Popover.Arrow className="PopoverArrow fill-sky-600 dark:fill-sky-800" />
            </Popover.Content>
          </Popover.Portal>
        </div>
      </Popover.Root>
    );
  }

  return (
    <Transition.Root show={sliderOpenStatus} as={Fragment}>
      <Dialog
        as="div"
        className="relative"
        onClose={() => {
          setShowPhnNumberError(false);
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-70 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10  ">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto relative w-screen max-w-3xl">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-500"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-500"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-0 top-0 -ml-8 flex pr-2 pt-4 sm:pr-4 lg:-ml-10">
                      <button
                        type="button"
                        className="relative rounded-md bg-black/60  text-white hover:text-gray-100 focus:outline-none focus:ring-2 focus:ring-white dark:bg-black/30"
                        onClick={() => handleCancel()}
                      >
                        <span className="absolute -inset-2.5" />
                        <span className="sr-only">Close panel</span>
                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="relative flex h-full flex-col bg-white pt-4 shadow-xl dark:bg-dark-dark ">
                    {isSpinnerShowing && (
                      <div className="bg-gray-900/8  absolute inset-0 z-50 grid place-items-center backdrop-blur-sm">
                        <Spinner size="large" />
                      </div>
                    )}

                    <div className="border-b border-light-light px-4 pb-4 dark:border-dark-medium sm:px-6">
                      <Dialog.Title className="text-lg font-semibold leading-6 text-gray-900 dark:text-white">
                        Schedule outbound
                      </Dialog.Title>
                    </div>
                    <div
                      className="daterange-wrapper relative my-3 flex-1 space-y-5 overflow-y-scroll px-4 sm:px-6 md:my-5 "
                      ref={dateRangeWrapper}
                    >
                      {renderTextField({
                        id: 'panel-account',
                        label: 'Account',
                        value:
                          scheduledCallDetails?.Account?.AccountNameAndNumber,
                        disabled: true,
                      })}
                      <div className="grid grid-cols-1 gap-3 md:grid-cols-2">
                        {renderTextField({
                          id: 'panel-first-name',
                          label: 'First name',
                          value:
                            scheduledCallDetails?.PersonOfConcern?.FirstName,
                          disabled: true,
                        })}

                        {renderTextField({
                          id: 'panel-last-name',
                          label: 'Last name',
                          value:
                            scheduledCallDetails?.PersonOfConcern?.LastName,
                          disabled: true,
                        })}
                      </div>
                      <div className="grid grid-cols-1 gap-3 md:grid-cols-2">
                        {renderTextField({
                          id: 'panel-preferred-phone',
                          label: 'Preferred phone',
                          value: 'Primary',
                          disabled: true,
                        })}

                        {renderSelectField({
                          label: 'Phone type',
                          id: 'panel-phone-type',
                          options: phoneTypeList,
                          key: 'phoneType',
                          value: scheduledCallDetails?.phoneType,
                        })}
                      </div>
                      <div className="grid grid-cols-1 gap-3 md:grid-cols-2">
                        {renderInputMask()}
                        {renderSelectField({
                          label: 'Is it okay to leave messages',
                          id: 'panel-leave-messages',
                          options: leaveMessage,
                          key: 'leaveMessage',
                          value: scheduledCallDetails?.leaveMessage,
                        })}
                      </div>
                      <div className="grid grid-cols-1 gap-3 md:grid-cols-2">
                        {renderSelectField({
                          label: 'Time zone',
                          id: 'panel-assigned-group',
                          options: timeZone,
                          key: 'timezone',
                          value: scheduledCallDetails?.timezone,
                          required: true,
                          errorHighlight:
                            !showValidationMsg('timezone') && isSaveClicked,
                        })}
                        {renderDatePicker({
                          id: 'schedule-time',
                          placeholder: 'Call scheduled on',
                          title: 'Scheduled time',
                        })}
                      </div>
                      {renderCheckBox({
                        id: 'panel-assign-anyone',
                        label: 'Assigned to anyone',
                        checked: scheduledCallDetails?.assignedToAnyone,
                        key: 'assignedToAnyone',
                      })}

                      {renderSelectField({
                        label:
                          scheduledCallDetails?.assignedToAnyone &&
                          scheduledCallDetails?.assignedToAnyone === true
                            ? 'Assigned user group'
                            : 'Assigned user',
                        id: 'panel-assigned-group',
                        options:
                          scheduledCallDetails?.assignedToAnyone &&
                          scheduledCallDetails?.assignedToAnyone === true
                            ? callTakerGroup
                            : clinicianList,
                        key:
                          scheduledCallDetails?.assignedToAnyone === true
                            ? 'AssignedGroupId'
                            : 'AssignedTo',
                        value:
                          scheduledCallDetails?.assignedToAnyone === true
                            ? scheduledCallDetails?.AssignedGroupId
                            : scheduledCallDetails?.AssignedTo,
                      })}

                      <div className="space-y-0.5">
                        <label
                          htmlFor="primary-phone"
                          className={inputLabelClasses}
                        >
                          Email notification to
                        </label>
                        <ReactSelect
                          id="panel-email-notification"
                          label="Email notification to:"
                          options={emailNotificationOptions()}
                          setSelectedOptions={e => {
                            updateScheduledCallDetails('calltaker', e);
                          }}
                        />
                      </div>

                      {renderCheckBox({
                        id: 'panel-link-existing',
                        label: 'Link existing case',
                        checked: scheduledCallDetails?.linkExistingCase,
                        key: 'linkExistingCase',
                      })}
                      {scheduledCallDetails?.linkExistingCase == true &&
                        renderTextField({
                          id: 'panel-case-id',
                          label: 'Case Id',
                          type: 'number',
                          key: 'caseId',
                          value: scheduledCallDetails?.caseId,
                          required: true,
                          errorHighlight:
                            !showValidationMsg('caseId') && isSaveClicked,
                        })}
                      {renderTextField({
                        id: 'panel-attempts',
                        label: 'How many attempts to contact?',
                        type: 'number',
                        key: 'attempts',
                        value: scheduledCallDetails?.attempts,
                      })}
                      <Textarea
                        id="panel-add-info"
                        label="Additional information"
                        rows={3}
                        onChange={e =>
                          updateScheduledCallDetails(
                            'additionalInfo',
                            e.target.value,
                          )
                        }
                        key="additionalInfo"
                        value={scheduledCallDetails?.additionalInfo}
                      />
                    </div>
                    {renderFooterButton()}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export { ActivityTrackerScheduleCall };
