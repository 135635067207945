//API FOR DOWNLOADING CALL DOCS LESS THAN OR EQUAL TO 15
import { useMutation, UseMutationResult } from 'react-query';

import { downloadCallFileDocs } from 'src/utils/urls';
import { useAuth } from 'src/hooks';

async function downloadCallDoc({
  token,
  tokenType,
  id,
  format,
  includeStickyNote,
}: {
  token: string;
  tokenType: string;
  id: string;
  format: string;
  includeStickyNote: boolean;
}): Promise<TResult> {
  const response = await fetch(
    downloadCallFileDocs(id, format, includeStickyNote),
    {
      method: 'GET',
      headers: {
        Authorization: `${tokenType} ${token}`,
      },
    },
  );
  return response.json();
}

export type TResult = {
  FileContent: string;
  FileName: string;
  Status: string;
};
export type TError = { message: string };

export type TVariables = {
  id: string;
  format: string;
  includeStickyNote: boolean;
};

function useCallDocFileDownload(): UseMutationResult<
  TResult,
  TError,
  TVariables,
  unknown
> {
  const { token, tokenType } = useAuth();
  return useMutation(async ({ id, format, includeStickyNote }: TVariables) => {
    const result = await downloadCallDoc({
      token,
      tokenType,
      id,
      format,
      includeStickyNote,
    });
    if (result) return result;
    else throw result;
  });
}

export { useCallDocFileDownload };
