import { UseMutationResult, useMutation, useQueryClient } from 'react-query';

import { useAuth } from 'src/hooks/useAuth';

import { getPersonDetails } from 'src/utils/urls';

import { PersonType } from 'src/types/personType';

async function updatePersonDetails({
  token,
  tokenType,
  params,
  payload,
}: TVariables): Promise<any> {
  if (params?.AccountId && params?.Id) {
    const result = await fetch(
      getPersonDetails(params?.AccountId, params?.Id),
      {
        method: 'PUT',
        headers: {
          Authorization: `${tokenType} ${token}`,
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: payload,
      },
    );
    return result;
  }
}

type TVariables = {
  token: string;
  tokenType: string;
  params: PersonType;
  payload: any;
};

type TError = {
  message: string;
};

const appendToFormData = (params: any, parentKey: string, formData: any) => {
  for (const key in params) {
    if (params.hasOwnProperty(key)) {
      const currentKey = parentKey ? `${parentKey}[${key}]` : key;

      if (
        typeof params[key] === 'object' &&
        params[key] !== null &&
        params[key] !== undefined
      ) {
        appendToFormData(params[key], currentKey, formData);
      } else {
        if (key === 'Age') formData.append(currentKey, params[key]);
        else
          formData.append(
            currentKey,
            params[key] === null || params[key] === undefined
              ? ''
              : params[key],
          );
      }
    }
  }
  return formData;
};
function getPayload(params: PersonType) {
  let formData: any = new URLSearchParams();
  formData = appendToFormData(params, '', formData);
  return formData;
}
function useEditPersonDetails(): UseMutationResult<
  any,
  TError,
  PersonType,
  unknown
> {
  const { token, tokenType } = useAuth();

  return useMutation(async (params: PersonType) => {
    const payload = getPayload(params);
    const value = await updatePersonDetails({
      token,
      tokenType,
      params,
      payload,
    });

    if (value.status === 200) {
      return value.json();
    } else {
      const message = await value.json();
      throw message;
    }
  });
}
export { useEditPersonDetails };
