import React, { ReactElement } from 'react';
import dayjs from 'dayjs';

import { ActivityLogListType } from '../types/activityLogType';

type ActivityLogItemType = {
  activityLog: ActivityLogListType;
};

function ActivityLogItem({ activityLog }: ActivityLogItemType): ReactElement {
  return (
    <div
      tabIndex={0}
      className={`relative flex flex-nowrap rounded bg-white shadow focus-visible:outline focus-visible:outline-[3px] focus-visible:outline-offset-[3px] focus-visible:outline-blue-600 dark:bg-neutral-800 dark:focus-visible:outline-blue-400`}
    >
      <div className="flex flex-grow flex-wrap px-4 py-2">
        <div className="w-full p-2 sm:w-1/2 md:w-1/3 lg:w-[15%]">
          <label className="block whitespace-nowrap text-[10px] font-medium uppercase tracking-wide opacity-70">
            Activity Date
          </label>
          <p className="text-xs font-bold tracking-wide text-black dark:text-white">
            {dayjs(activityLog?.CreatedOn).format('MM/DD/YYYY H:mm:ss')}
          </p>
        </div>

        <div className="w-full p-2 sm:w-1/2 md:w-1/3 lg:w-[10%]">
          <label className="block whitespace-nowrap text-[10px] font-medium uppercase tracking-wide opacity-70">
            CID
          </label>
          <p className="text-xs font-bold tracking-wide text-black dark:text-white">
            {activityLog?.CID}
          </p>
        </div>

        <div className="w-full p-2 sm:w-1/2 md:w-1/3 lg:w-[10%]">
          <label className="block whitespace-nowrap text-[10px] font-medium uppercase tracking-wide opacity-70">
            PID
          </label>
          <p className="text-xs font-bold tracking-wide text-black dark:text-white">
            {activityLog?.PID}
          </p>
        </div>

        <div className="w-full p-2 sm:w-1/2 md:w-1/3 lg:w-[35%]">
          <label className="block whitespace-nowrap text-[10px] font-medium uppercase tracking-wide opacity-70">
            File Name
          </label>
          <p className="prevent-text-breakout text-xs font-bold tracking-wide text-black dark:text-white">
            {activityLog?.ReportFileName}
          </p>
        </div>

        <div className="w-full p-2 sm:w-1/2 md:w-1/3 lg:w-[15%]">
          <label className="block whitespace-nowrap text-[10px] font-medium uppercase tracking-wide opacity-70">
            Activity
          </label>
          <p className="text-xs font-bold tracking-wide text-black dark:text-white">
            {activityLog?.Activity}
          </p>
        </div>

        <div className="w-full p-2 sm:w-1/2 md:w-1/3 lg:w-[15%] ">
          <label className="block whitespace-nowrap text-[10px] font-medium uppercase tracking-wide opacity-70">
            User
          </label>
          <p className="prevent-text-breakout text-xs font-bold tracking-wide text-black dark:text-white ">
            {activityLog?.CreatedBy}
          </p>
        </div>
      </div>
    </div>
  );
}

export { ActivityLogItem };
