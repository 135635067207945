import { ActivityLogFilterType } from '../types/filterOptionsType';

/*for showing the options in the sort drop down menu */
export const sortOptions = [
  { id: 4, label: 'Activity date', value: 'CreatedOn' },
  { id: 2, label: 'CID', value: 'CID' },
  { id: 1, label: 'PID', value: 'PID' },
  { id: 3, label: 'Activity', value: 'Activity' },
  { id: 5, label: 'User', value: 'CreatedBy' },
  { id: 6, label: 'File name', value: 'ReportFileName' },
];

export const initialState: ActivityLogFilterType = {
  keywordSearch: '',
  sortType: 'CreatedOn',
  sortOrder: 'desc',
  cid: '',
  pid: '',
  reportFileName: '',
  activity: '',
  createdOn: '',
  createdBy: '',
};

export const filterSortOptions: Array<string> = [
  'CID',
  'PID',
  'Activity',
  'CreatedOn',
  'CreatedBy',
  'ReportFileName',
];
