import React, { useRef, useMemo } from 'react';
import Select, { components } from 'react-select';

import { baseClasses as checkboxStyles } from 'src/ui/components/Checkbox';
import { inputLabelClasses } from 'src/ui/components/TextField';

import { MultiSelectInputType } from 'src/features/CallDocuments/types/callDocumentList';

type MultiSelectCheckBoxType = {
  options: Array<MultiSelectInputType>;
  setSelectedOptions: (values: Array<MultiSelectInputType>) => void;
  selectedOptions: Array<MultiSelectInputType> | undefined;
  size: string;
  label: string;
  id: string;
  isSearchable?: boolean;
};
function MultiSelectCheckBox({
  options,
  setSelectedOptions,
  selectedOptions,
  size,
  label,
  id,
  isSearchable,
}: MultiSelectCheckBoxType) {
  const useRes = useRef<any>();
  const { Option } = components;

  //listing checkbox along with dropdown list
  const CheckboxOption = ({ children, ...props }: any) => (
    <Option {...props}>
      <div className="flex items-start space-x-2">
        <input
          type="checkbox"
          checked={props?.isSelected}
          className={`${checkboxStyles} h-5 w-5`}
          onChange={() => {}}
        />
        <label
          className={`${inputLabelClasses}  leading-tight`}
          style={{ overflowWrap: 'anywhere' }}
        >
          {children}
        </label>
      </div>
    </Option>
  );

  const onChange = (value: any, event: any) => {
    if (event.action === 'select-option' && event.option.value === 0) {
      setSelectedOptions && setSelectedOptions([...options]);
    } else if (event.action === 'deselect-option' && event.option.value === 0) {
      setSelectedOptions && setSelectedOptions([]);
    } else if (event.action === 'deselect-option') {
      setSelectedOptions &&
        setSelectedOptions(value.filter((o: any) => o.value !== 0));
    } else if (value.length === options.length) {
      setSelectedOptions && setSelectedOptions([...options]);
    } else {
      setSelectedOptions && setSelectedOptions(value);

      if (selectedOptions?.length === options?.length - 2) {
        setSelectedOptions([...options]);
      }
    }
  };

  //place holder text
  const selected = useMemo(() => {
    return selectedOptions?.length === options?.length
      ? 'Select(All)'
      : `Select(${selectedOptions?.length ? selectedOptions?.length : '0'})`;
  }, [selectedOptions?.length, options?.length]);

  return (
    <Select
      id={id}
      ref={useRes}
      options={options}
      isMulti
      hideSelectedOptions={false}
      components={{
        Option: CheckboxOption,
      }}
      controlShouldRenderValue={false}
      value={selectedOptions}
      closeMenuOnSelect={false}
      blurInputOnSelect={false}
      onChange={onChange}
      openMenuOnClick={true}
      placeholder={selected}
      isSearchable={isSearchable ?? false}
      classNamePrefix="multiselect-dropdown"
      className={`${
        size === 'small'
          ? 'multiselect-dropdown multiselect-dropdown--small'
          : 'multiselect-dropdown'
      }`}
      aria-label={label}
      menuPlacement="auto"
    />
  );
}

export { MultiSelectCheckBox };
