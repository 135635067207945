import React, { ReactElement, useCallback, useMemo, useRef } from 'react';
import dayjs from 'dayjs';
import { Disclosure } from '@headlessui/react';
import {
  ChevronDownIcon,
  InformationCircleIcon,
} from '@heroicons/react/20/solid';

import {
  inputLabelClasses,
  Select,
  Alert,
  ReactTimePicker,
  TextField,
  TimezoneIntegratedDatePicker,
} from 'src/ui/components';
import { renderTextField } from './TextFieldControl';

import { useGetStoredPermissions } from 'src/hooks';

import { useExtracoverageRequestStore } from '../store/extracoverageStore';

import * as utilityFn from 'src/utils/common';
import {
  gridElements,
  recipientsErrorMsg,
} from '../utils/coverageRequestConstants';
import { formatTime } from '../utils/coverageRequestHelper';
import { complexEmailRegex, emailRegex } from 'src/utils/common';

import {
  SelectPropType,
  TimepickerPropType,
  gridItemType,
} from '../types/requestType';
import { ExtraCoverageResponseType } from '../types/saveExtraCoverageRequest';
import { Timezone } from 'src/types/timezone';

type optionType = { value: number; label: string };

type BasicDetailsType = {
  coverageStatus: Array<optionType> | undefined;
  timezoneObj: Array<Timezone> | undefined;
  coverageType: Array<optionType> | undefined;
  datePickerRef: any;
  isApproveMode: boolean;
};

function BasicDetails({
  coverageStatus,
  timezoneObj,
  coverageType,
  datePickerRef,
  isApproveMode,
}: BasicDetailsType): ReactElement {
  const startTimeRef = useRef<any>(null);
  const endTimeRef = useRef<any>(null);

  const permission = useGetStoredPermissions();

  const userMode = useExtracoverageRequestStore(
    useCallback(state => state.userMode, []),
  );

  const coverageRequestDet = useExtracoverageRequestStore(
    useCallback(state => state.extraCoverageRequestDet, []),
  );

  const updateCoverageRequest = useExtracoverageRequestStore(
    useCallback(state => state.updateExtraCoverageReqDet, []),
  );

  const warningMsg = useExtracoverageRequestStore(
    useCallback(state => state.getBasicDetailsWarnings, []),
  );

  const errorMsg = useExtracoverageRequestStore(
    useCallback(state => state.getBasicDetailsErrors, []),
  );

  const isSaveClicked = useExtracoverageRequestStore(
    React.useCallback(state => state.isSaveClicked, []),
  );

  const isAllRequiredFieldsFilled = useExtracoverageRequestStore(
    React.useCallback(state => state.isAllRequiredFieldsFilled, []),
  );

  const setRestrictTimezoneChange = useExtracoverageRequestStore(
    useCallback(state => state.setRestrictTimezoneChange, []),
  );

  const restrictTimezoneChange = useExtracoverageRequestStore(
    useCallback(state => state.restrictTimezoneChange, []),
  );

  const showError = useExtracoverageRequestStore(
    useCallback(state => state.showError, []),
  );

  const warning = useExtracoverageRequestStore(
    useCallback(state => state.warning, []),
  );

  const updateStoreByKey = useExtracoverageRequestStore(
    useCallback(state => state.updateStoreByKey, []),
  );

  const previousSendEmail = useExtracoverageRequestStore(
    useCallback(state => state.previousSendEmail, []),
  );

  const previousPostAccountAlert = useExtracoverageRequestStore(
    useCallback(state => state.previousPostAccountAlert, []),
  );

  const postAlertOnSave = useExtracoverageRequestStore(
    useCallback(state => state.postAlertOnSave, []),
  );

  const sendEmailOnSave = useExtracoverageRequestStore(
    useCallback(state => state.sendEmailOnSave, []),
  );

  const isPostAlertFieldModified = useExtracoverageRequestStore(
    useCallback(state => state.isPostAlertFieldModified, []),
  );

  const isSendEmailFieldModified = useExtracoverageRequestStore(
    useCallback(state => state.isSendEmailFieldModified, []),
  );

  const timeZone = useMemo(() => {
    return utilityFn.formatSelectOption(timezoneObj, 'Id', 'Abbreviation');
  }, [timezoneObj]);

  //getting the timezone value to pass on daterangepciker
  const timeZoneValue = useMemo(() => {
    const timeZonVal = timezoneObj?.find(
      (item: Timezone) =>
        item.Id?.toString() === coverageRequestDet?.TimeZoneId?.toString(),
    );

    return timeZonVal;
  }, [coverageRequestDet?.TimeZoneId, timezoneObj]);

  function gridItemValue(label: string): any {
    switch (label) {
      case 'Start time':
        return formatTime(coverageRequestDet?.StartTime);
      case 'End time':
        return formatTime(coverageRequestDet?.EndTime);

      case 'Date of extra coverage':
        return coverageRequestDet?.ExtraCoverageDate
          ? dayjs(coverageRequestDet?.ExtraCoverageDate).format('MM/DD/YYYY')
          : '';

      default:
        return '';
    }
  }

  function renderBasicDetailsGridItems(): ReactElement {
    return (
      <>
        {gridElements?.map((item: gridItemType, index: number) => {
          return (
            <div
              key={`${item.id + index}`}
              className="sm:max-w-[20ch] lg:max-w-[30ch]"
            >
              <label className="block text-xs font-bold">{item?.label}</label>
              <p
                className="truncate text-sm opacity-75"
                title={gridItemValue(item?.label)}
              >
                {gridItemValue(item?.label) !== '' &&
                gridItemValue(item?.label) !== undefined &&
                gridItemValue(item?.label) !== null
                  ? gridItemValue(item?.label)
                  : '-'}
              </p>
            </div>
          );
        })}
      </>
    );
  }

  function renderHeading(open: boolean): ReactElement {
    return (
      <Disclosure.Button className="flex w-full items-center justify-between gap-2 rounded-lg bg-white px-4 py-2 text-left shadow-sm hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-light-focus disabled:pointer-events-none disabled:cursor-not-allowed disabled:opacity-50 ui-open:rounded-b-none dark:bg-neutral-800 dark:hover:bg-neutral-800/70">
        <div className="flex grow flex-wrap items-center gap-x-8 gap-y-2  pr-2">
          <div className="flex grow flex-wrap items-center gap-x-8 gap-y-2  pr-2">
            <div className="grow">
              <div className="flex items-center gap-2">
                {isSaveClicked &&
                  !isAllRequiredFieldsFilled('basic-details') && (
                    <div className="h-3 w-3">
                      <span className="relative flex h-3 w-3 items-center justify-center">
                        <span className="absolute inline-flex h-full w-full animate-ping rounded-full bg-red-400 opacity-75" />
                        <span className="relative inline-flex h-2 w-2 rounded-full bg-red-500" />
                      </span>
                    </div>
                  )}
                <h3 className="font-medium after:ml-0.5 after:text-red-400 after:content-['*']">
                  Basic details
                </h3>
              </div>
            </div>

            <div className="hidden gap-2 ui-open:hidden sm:flex sm:ui-open:hidden">
              <div className="flex gap-8">{renderBasicDetailsGridItems()}</div>
            </div>
          </div>

          <div className="flex flex-wrap gap-2 self-center">
            {warningMsg(timeZoneValue) !== '' && (
              <Alert variant="warning" size="small">
                {warningMsg(timeZoneValue)}
              </Alert>
            )}
            {errorMsg(timeZoneValue) !== '' && (
              <Alert variant="danger" size="small">
                {errorMsg(timeZoneValue)}
              </Alert>
            )}
          </div>
        </div>

        <ChevronDownIcon
          className={`${
            open ? 'rotate-180 transform' : ''
          } h-5 w-5 shrink-0 text-gray-500 dark:text-neutral-300`}
        />
      </Disclosure.Button>
    );
  }

  function selectChangeHandler(
    e: any,
    key: keyof ExtraCoverageResponseType,
    keyId: keyof ExtraCoverageResponseType,
  ) {
    updateCoverageRequest(keyId, e.target.value);
    if (key === 'TimeZone') {
      if (e.target.value === '-1') {
        setRestrictTimezoneChange(false);
      } else {
        updateCoverageRequest(
          'TimeZone',
          timezoneObj?.find((x: any) => x.Id == parseInt(e.target.value)),
        );
        setRestrictTimezoneChange(true);
      }
    }

    // to reset the Send approval email?/Post account alert?
    if (key === 'TimeZone' && userMode === 'Approve')
      resetPostaccountAndSendEmail();
  }

  function renderSelect({
    id,
    label,
    options,
    value,
    className,
    key,
    keyId,
    required,
  }: SelectPropType): ReactElement {
    return (
      <div className="space-y-2">
        <Select
          id={id}
          label={label}
          options={options}
          value={value}
          className={className}
          onChange={e => {
            selectChangeHandler(e, key, keyId);
          }}
          required={
            key === 'ExtraCoverageRequestType' &&
            coverageRequestDet?.ExtraCoverageRequestStatusId == 2
              ? true
              : required
          }
        ></Select>

        {key === 'TimeZone' &&
          restrictTimezoneChange === false &&
          coverageRequestDet?.TimeZoneId !== '-1' &&
          coverageRequestDet?.TimeZoneId !== -1 &&
          coverageRequestDet?.TimeZoneId !== undefined && (
            <Alert variant="info" size="small">
              {`Field defaults to account's primary time zone, but can be changed.`}
            </Alert>
          )}
      </div>
    );
  }

  function onTimeTickerHandler(e: any, key: any) {
    if (e) updateCoverageRequest(key, e);
    else updateCoverageRequest(key, null);

    if (userMode === 'Approve') resetPostaccountAndSendEmail();
  }

  function resetPostaccountAndSendEmail() {
    if (
      isApproveMode &&
      (previousPostAccountAlert === true || postAlertOnSave)
    ) {
      updateStoreByKey('postAlertOnSave', false);
      updateStoreByKey('previousAlertModalInfo', null);
      updateStoreByKey('hasPostAccountAlertWarning', true);
    }
    if (isApproveMode && (previousSendEmail === true || sendEmailOnSave)) {
      updateStoreByKey('sendEmailOnSave', false);
      updateStoreByKey('hasSendEmailWarning', true);
    }
    if (isApproveMode && !isPostAlertFieldModified && previousPostAccountAlert)
      updateStoreByKey('isPostAlertFieldModified', true);
    if (isApproveMode && !isSendEmailFieldModified && previousSendEmail)
      updateStoreByKey('isSendEmailFieldModified', true);
  }

  function renderTimePicker({
    id,
    label,
    value,
    key,
    ref,
  }: TimepickerPropType): ReactElement {
    return (
      <div>
        <label
          htmlFor={label}
          className={[
            inputLabelClasses,
            'mb-0.5  after:text-red-500 after:content-["*"] dark:after:text-red-400',
          ].join(' ')}
        >
          {label}
        </label>
        <div className="w-full">
          <ReactTimePicker
            value={value}
            onChange={(e: any) => {
              onTimeTickerHandler(e, key);
            }}
          />
        </div>
      </div>
    );
  }

  function onRecipientFocusOutHandler() {
    const emailIds: string[] =
      coverageRequestDet?.AdditionalReturnEmailAddress?.split(',')
        ?.map((elem: any) => elem.trim())
        .filter(Boolean);

    const isValidEmail = emailIds?.every(email => emailRegex.test(email));
    const hasInvalidPattern = emailIds?.every(email =>
      complexEmailRegex.test(email),
    );
    updateStoreByKey(
      'showError',
      emailIds?.length > 0 &&
        warning === '' &&
        (!isValidEmail || !hasInvalidPattern),
    );
  }

  return (
    <Disclosure as="div" id="basic-details" defaultOpen={true}>
      {({ open }) => (
        <>
          {renderHeading(open)}
          <Disclosure.Panel className="space-y-10 rounded-b-lg border-t bg-white p-4 text-sm text-gray-500 shadow dark:border-neutral-700/50 dark:bg-neutral-800">
            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
              <>
                {userMode === 'Approve' && (
                  <>
                    {renderTextField({
                      id: 'submitted-date',
                      label: 'Date submitted',
                      value: dayjs(coverageRequestDet?.SubmittedDate).format(
                        'MM/DD/YYYY h:mm A',
                      ),
                      key: 'SubmittedDate',
                      disabled: true,
                      onChange: updateCoverageRequest,
                    })}
                  </>
                )}

                {renderTextField({
                  id: 'email-address',
                  label: 'Follow-up email address',
                  value: coverageRequestDet?.ReturnEmailAddress,
                  key: 'ReturnEmailAddress',
                  disabled: true,
                  onChange: updateCoverageRequest,
                })}

                <div>
                  <TextField
                    id="text-alt-email"
                    label="Additional follow-up email address(es)"
                    value={coverageRequestDet?.AdditionalReturnEmailAddress}
                    onChange={e => {
                      updateCoverageRequest(
                        'AdditionalReturnEmailAddress',
                        e.target.value,
                      );
                      if (userMode === 'Approve') {
                        if (previousSendEmail === true || sendEmailOnSave) {
                          updateStoreByKey('sendEmailOnSave', false);
                          updateStoreByKey('hasSendEmailWarning', true);
                        }
                        if (previousSendEmail && !isSendEmailFieldModified) {
                          updateStoreByKey('isSendEmailFieldModified', true);
                        }
                      }
                    }}
                    onBlur={() => onRecipientFocusOutHandler()}
                    validationError={
                      showError && warning.length === 0
                        ? recipientsErrorMsg
                        : ''
                    }
                  />
                  <p className="flex items-start gap-1 text-xs text-gray-600 dark:text-neutral-400">
                    <InformationCircleIcon
                      className="relative -top-px h-5 w-5"
                      aria-hidden="true"
                    />
                    <span>
                      For multiple email addresses, please use commas to
                      separate them (e.g. user1@example.com, user2@example.com)
                    </span>
                  </p>
                </div>
                {renderSelect({
                  id: 'timezone',
                  label: 'Time zone',
                  options: timeZone,
                  value: coverageRequestDet?.TimeZoneId,
                  key: 'TimeZone',
                  keyId: 'TimeZoneId',
                  required: true,
                })}
                <div>
                  <label
                    htmlFor="birth-date"
                    className={[
                      inputLabelClasses,
                      'mb-0.5  after:text-red-500 after:content-["*"] dark:after:text-red-400',
                    ].join(' ')}
                  >
                    Date of extra coverage
                  </label>
                  <div className="w-full [&>*]:w-full">
                    <TimezoneIntegratedDatePicker
                      id="birth-date"
                      className="w-full"
                      value={
                        coverageRequestDet?.ExtraCoverageDate
                          ? new Date(coverageRequestDet?.ExtraCoverageDate)
                          : null
                      }
                      onChange={e => {
                        updateCoverageRequest(
                          'ExtraCoverageDate',
                          e !== null ? new Date(e).toISOString() : e,
                        );

                        if (userMode === 'Approve')
                          resetPostaccountAndSendEmail();
                      }}
                      hasMaxDate={true}
                      container={datePickerRef}
                      timeZone={timeZoneValue}
                      // disabled={timeZoneValue === undefined}
                    />
                  </div>
                </div>

                {renderTimePicker({
                  id: 'start-time',
                  label: 'Start time',
                  value: coverageRequestDet?.StartTime,
                  key: 'StartTime',
                  ref: startTimeRef,
                })}

                {renderTimePicker({
                  id: 'end-time',
                  label: 'End time',
                  value: coverageRequestDet?.EndTime,
                  key: 'EndTime',
                  ref: endTimeRef,
                })}

                {userMode === 'Approve' && permission?.AllProtocallUsers && (
                  <>
                    {renderSelect({
                      id: 'request-status',
                      label: 'Request status',
                      options: coverageStatus?.length ? coverageStatus : [],
                      value: coverageRequestDet?.ExtraCoverageRequestStatusId,
                      key: 'ExtraCoverageRequestStatus',
                      keyId: 'ExtraCoverageRequestStatusId',
                    })}

                    {renderSelect({
                      id: 'request-type',
                      label: 'Request type',
                      options: coverageType,
                      value: coverageRequestDet?.ExtraCoverageRequestTypeId,
                      key: 'ExtraCoverageRequestType',
                      keyId: 'ExtraCoverageRequestTypeId',
                    })}
                  </>
                )}
              </>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
export { BasicDetails };
