import React, { TextareaHTMLAttributes, forwardRef } from 'react';
import clsx, { ClassValue } from 'clsx';

import { ExclamationTriangleIcon } from '@heroicons/react/20/solid';
import {
  inputBaseClasses,
  inputLabelClasses,
  inputSizeClasses,
} from '../TextField';

type TextareaProps = TextareaHTMLAttributes<HTMLTextAreaElement> & {
  label?: string;
  id: string;
  inputSize?: 'small' | 'medium' | 'large';
  className?: ClassValue;
  required?: boolean;
  validationError?: string;
  srOnlyLabel?: string;
};

const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(
  (
    {
      label,
      id,
      inputSize = 'medium',
      children,
      className,
      required,
      validationError,
      srOnlyLabel,
      ...rest
    },
    ref,
  ) => {
    const sizeClass = inputSizeClasses[inputSize];
    const userClasses = clsx(className);

    return (
      <div className={userClasses}>
        {label ? (
          <label
            htmlFor={id}
            className={`${inputLabelClasses} ${
              inputSize === 'small' ? 'text-xs' : ''
            } ${
              required
                ? 'after:ml-0.5 after:text-red-500 after:content-["*"] dark:after:text-red-400'
                : ''
            }`}
          >
            {label}
          </label>
        ) : (
          ''
        )}
        {srOnlyLabel ? (
          <label htmlFor={id} className="sr-only">
            {srOnlyLabel}
          </label>
        ) : (
          ''
        )}
        <div className={inputSize === 'small' ? 'mt-0' : 'mt-0.5'}>
          <textarea
            name={id}
            id={id}
            className={`${inputBaseClasses} ${sizeClass} ${userClasses}`}
            draggable={true}
            ref={ref}
            {...rest}
          />
        </div>
        {validationError && validationError?.length !== 0 && (
          <p className="mt-1 inline-flex items-center gap-1 text-xs text-red-600 dark:text-red-400 md:text-sm">
            <ExclamationTriangleIcon
              className="-ml-0.5 h-5 w-5"
              aria-hidden="true"
            />
            {validationError}
          </p>
        )}
      </div>
    );
  },
);

Textarea.displayName = 'Textarea';

export { Textarea };
