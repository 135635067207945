import { useQuery, UseQueryResult } from 'react-query';

import { useAuth } from './useAuth';
import { getPermissionGroupCode } from 'src/utils/urls';

import { PermissionGroupCodeType } from 'src/types/permissionGroupCode';

async function fetchPermissionGroupCode({
  token,
  tokenType,
  groupCodeId,
}: TVaribles): Promise<TResult> {
  const response = await fetch(getPermissionGroupCode(groupCodeId), {
    method: 'GET',
    headers: {
      Authorization: `${tokenType} ${token}`,
    },
  });
  return response.json();
}

export type TResult = Array<PermissionGroupCodeType> | undefined;
export type TError = { message: string };
export type TVaribles = {
  token: string;
  tokenType: string;
  groupCodeId: string;
};

function useGetPermissionGroupCode(
  groupCodeId: string,
): UseQueryResult<TResult, TError> {
  const { token, tokenType } = useAuth();
  return useQuery(
    ['permission_group_code', groupCodeId],
    async () => {
      if (groupCodeId !== '')
        return fetchPermissionGroupCode({
          token,
          tokenType,
          groupCodeId,
        });
    },
    {
      staleTime: 60 * 1000,
      refetchOnWindowFocus: false,
    },
  );
}

export { useGetPermissionGroupCode };
