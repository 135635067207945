const sortOptions: Array<{ value: number; label: string }> = [
  { value: 0, label: 'ID' },
  { value: 1, label: 'Approver' },
  { value: 2, label: 'Status' },
  { value: 3, label: 'Accounts' },
  { value: 4, label: 'Extra coverage date' },
  { value: 7, label: 'Submitted date' },
  { value: 8, label: 'Email sent' },
  { value: 9, label: 'Account alert posted' },
];

const sortOptionsForNonProtocallUser: Array<{ value: number; label: string }> =
  [
    { value: 0, label: 'ID' },
    { value: 2, label: 'Status' },
    { value: 3, label: 'Accounts' },
    { value: 4, label: 'Extra coverage date' },
    { value: 7, label: 'Submitted date' },
  ];

const statusList: Array<{ value: number; label: string }> = [
  { value: 0, label: 'Select All' },
  { value: 1, label: 'Approved' },
  { value: 2, label: 'Denied' },
  { value: 3, label: 'New' },
  { value: 4, label: 'Removed' },
];

const alertMsg =
  'An account alert for the selected extra coverage request was successfully posted.';

const activeStyles =
  "after:pointer-events-none after:absolute after:inset-0 after:rounded-md after:border-2 after:border-primary-500 after:content-['']";

export {
  sortOptions,
  sortOptionsForNonProtocallUser,
  statusList,
  alertMsg,
  activeStyles,
};
