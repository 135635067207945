import { LinkOptionType } from '../types/onBoardingType';

const notificationMsg = `Please select when you would like to get email notification about
completed call documents. Live means you will get individual emails
for documents right away. Otherwise, links to call documents will be
grouped into emails sent at the desired interval or up to four
custom times of day. Select No Emails if you do not wish to receive
email notifications of new calls.`;

const welcomeError: string =
  'Either the link is expired or the password has already been set!. Please log in to continue using the Customer Portal Application, or contact the Administrator/Relationship Manager for help.';

const setPasswordCaption: string = `To protect your customer portal account from unauthorized access, we
          require you to set up your login with a strong password.`;

const setPasswordWarning: string = ` Parts of your name, email address, company name, this service, and commonly-used passwords will not be allowed.`;

const twoFactorHeaderText: string = `Two-factor authentication is an enhanced security measure which
            prompts users to enter a verification code when logging in. You can
            choose whether this code is sent to your email (default) or provided
            by an authentication app.`;
const authenticatorInfo = `Two-factor authentication via an app on your mobile device
                    rather than email. Requires initial one-time setup. Apps
                    using the common TOTP authentication method are expected to
                    work. Popular options include:`;

const links: Array<LinkOptionType> = [
  {
    title: 'Google Authenticator',
    playStoreLink:
      'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2',
    appStoreLink:
      'https://apps.apple.com/us/app/google-authenticator/id388497605',
  },
  {
    title: 'Authy',
    playStoreLink:
      'https://play.google.com/store/apps/details?id=com.authy.authy',
    appStoreLink: 'https://apps.apple.com/us/app/twilio-authy/id494168017',
  },
  {
    title: 'Duo',
    playStoreLink:
      'https://play.google.com/store/apps/details?id=com.duosecurity.duomobile',
    appStoreLink: 'https://apps.apple.com/us/app/duo-mobile/id422663827',
  },
  {
    title: 'Microsoft Authenticator',
    playStoreLink:
      'https://play.google.com/store/apps/details?id=com.azure.authenticator',
    appStoreLink:
      'https://apps.apple.com/in/app/microsoft-authenticator/id983156458',
  },
];

export {
  welcomeError,
  setPasswordCaption,
  setPasswordWarning,
  notificationMsg,
  twoFactorHeaderText,
  links,
  authenticatorInfo,
};
