import React, { ReactElement } from 'react';
import { ColumnInstance } from 'react-table';

import { Button } from 'src/ui/components';

import { defaultPath, ascPath, descPath } from 'src/utils/sortStyleConstants';

import { AccountInfo } from 'src/types/AccountInfo';

export default function accountInfoTableHeader(
  props: {
    column: ColumnInstance<AccountInfo>;
  } & {
    headerUpdateCallBack?: ({
      columnId,
      value,
    }: {
      columnId: string;
      value: string;
    }) => void;
    handleSortChange?: (item: string) => void;
    sortedItem?: { sortKey: string; sortOrder: string };
    columnsSearch?: Record<string, string>;
  },
): ReactElement {
  //header change
  const onChangeText = (value: string) => {
    props.headerUpdateCallBack &&
      props.headerUpdateCallBack({ columnId: props.column.id, value });
  };
  //sort change
  const onSortChange = () => {
    props.handleSortChange && props.handleSortChange(props.column.id);
  };

  const renderSortButton = (pathValue: string) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 320 512"
        fill="currentcolor"
        className="h-3 w-3"
      >
        <path d={pathValue} />
      </svg>
    );
  };

  return (
    <div className="flex">
      <div className="relative min-w-max grow">
        <input
          id={props?.column?.id}
          name={props?.column?.id}
          type="text"
          className="pr-floatingLabel__input peer"
          placeholder={
            props?.column?.id === 'AccountName'
              ? 'Account name'
              : props?.column?.id
          }
          value={
            (props.columnsSearch && props.columnsSearch[props.column.id]) || ''
          }
          onChange={e => onChangeText(e.target.value)}
        />
        <label
          htmlFor={props?.column?.id}
          className="absolute -top-3 left-0 text-xs font-medium leading-6 text-gray-900 transition-all peer-placeholder-shown:top-2 peer-placeholder-shown:text-sm peer-placeholder-shown:text-gray-400 peer-focus:-top-3 peer-focus:text-xs peer-focus:text-gray-600 dark:text-white dark:peer-placeholder-shown:text-neutral-400 dark:peer-focus:text-neutral-400"
        >
          {props?.column?.id === 'AccountName'
            ? 'Account name'
            : props?.column?.id}
        </label>
      </div>

      <Button variant="subtle" size="small" onClick={onSortChange}>
        {props.sortedItem?.sortKey !== props.column.id &&
          renderSortButton(defaultPath)}
        {/* Sort up icon */}
        {props.sortedItem?.sortKey === props.column.id &&
          props.sortedItem?.sortOrder === 'asc' &&
          renderSortButton(ascPath)}
        {/* Sort down icon */}
        {props.sortedItem?.sortKey === props.column.id &&
          props.sortedItem?.sortOrder === 'desc' &&
          renderSortButton(descPath)}
      </Button>
    </div>
  );
}
