import { getCountry, getPersonAlertsDetails } from 'src/utils/urls';

import { PersonAlertsAccountsDetails } from '../types/personAlertsAccounts';

export async function fetchPersonAlertsDetails({
  id,
  accountId,
  token,
  tokenType,
}: Partial<{
  id: number;
  accountId: number;
  token: string;
  tokenType: string;
}>): Promise<PersonAlertsAccountsDetails | undefined> {
  if (id && accountId) {
    const response = await fetch(getPersonAlertsDetails(id, accountId), {
      method: 'GET',
      headers: {
        Authorization: `${tokenType} ${token}`,
      },
    });
    return response.json();
  }
}

export async function fetchCountryDetails({
  token,
  tokenType,
}: Partial<{
  token: string;
  tokenType: string;
}>): Promise<any> {
  const response = await fetch(getCountry, {
    method: 'GET',
    headers: {
      Authorization: `${tokenType} ${token}`,
    },
  });
  return response.json();
}
