import { MultiSelectInputType } from 'src/types/MultiSelectInputType';

const sortOptions: Array<{ id: number; label: string; value: string }> = [
  { id: 1, label: 'User name', value: 'userName' },
  { id: 2, label: 'First name', value: 'firstName' },
  { id: 3, label: 'Last name', value: 'lastName' },
  { id: 5, label: 'CID', value: 'CID' },
  { id: 4, label: 'PID', value: 'PID' },
];

const statusOptions: Array<MultiSelectInputType> = [
  { label: 'Select All', value: 0 },
  { label: 'Active', value: 1 },
  { label: 'Invitation sent', value: 2 },
  { label: 'Invitation expired', value: 3 },
];

export { sortOptions, statusOptions };
