import pDebounce from 'p-debounce';
import { useInfiniteQuery, UseInfiniteQueryResult } from 'react-query';

import { useAuth } from 'src/hooks';

import { pageLength } from 'src/utils/appConstants';
import { personAlerts } from 'src/utils/urls';

export async function fetchPersonAlerts({
  personId,
  accountId,
  pageParam,
  token,
  tokenType,
}: TVariables): Promise<TPage> {
  const response = await fetch(
    personAlerts(personId, accountId, pageParam, pageLength),
    {
      method: 'GET',
      headers: {
        Authorization: `${tokenType} ${token}`,
      },
    },
  );
  return response.json();
}

export type TPage = any;

export type TError = { message: string };

export type TVariables = {
  personId: string;
  accountId: number;
  token: string;
  tokenType: string;
  pageParam: any;
};

const debouncedFetch = pDebounce(fetchPersonAlerts, 500);

function useGetPersonAlerts(
  accountId?: number,
  personId?: string,
): UseInfiniteQueryResult<TPage, unknown> {
  const { token, tokenType } = useAuth();
  return useInfiniteQuery(
    ['person_alert', personId],
    ({ pageParam = 0 }) => {
      if (personId && accountId)
        return debouncedFetch({
          token,
          tokenType,
          personId,
          accountId,
          pageParam,
        });
    },
    {
      getNextPageParam: (lastPage: TPage, allPages: Array<TPage>) => {
        if (lastPage !== null && lastPage?.length === pageLength)
          return allPages?.length * pageLength;
      },
      getPreviousPageParam: (firstPage: TPage, allPages: Array<TPage>) => {
        if (firstPage !== null && firstPage?.length === pageLength)
          return allPages?.length - 1 * pageLength;
      },

      staleTime: 60 * 1000,
      refetchOnWindowFocus: false,
      enabled:
        accountId !== undefined &&
        personId !== undefined &&
        accountId != null &&
        personId !== null,
    },
  );
}

export { useGetPersonAlerts };
