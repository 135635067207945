/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';

export function useMutableObserver(warningMsgRef: any) {
  const [isMessageChanged, setIsMessageChanged] = useState(false);

  useEffect(() => {
    const targetNode = warningMsgRef.current;
    if (!targetNode) return;

    const observer = new MutationObserver(() => {
      setIsMessageChanged(true);
      setTimeout(() => {
        setIsMessageChanged(false);
      }, 1000);
    });
    const config = { childList: true, subtree: true }; // Configure the MutationObserver
    observer.observe(targetNode, config); // Start observing the target node

    return () => {
      observer.disconnect();
    };
  }, []);

  return {
    isMessageChanged,
  };
}
