import React, { ReactElement, useEffect } from 'react';

import { Spinner } from 'src/ui/components';

import { useTitle } from 'src/hooks';
import { useDownloadZip } from '../api/useDownladZip';

import { base64ToArrayBuffer } from 'src/utils/convertToBinaryFormat';

export default function DownloadZip(): ReactElement {
  useTitle('Download');
  const filePath = new URLSearchParams(window.location.search).get('filePath');

  const { data, isLoading } = useDownloadZip(filePath);

  useEffect(() => {
    if (data) {
      if (data?.Status === 'Success') {
        let sample = base64ToArrayBuffer(data?.FileContent);
        let docFile = sample;
        let blob = new Blob([docFile]);
        const link = document.createElement('a');
        const url = window.URL.createObjectURL(blob);
        link.href = url;
        const fileName = data?.FileName;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
        window.location.href = '/home';
      }
    }
  }, [data]);

  return (
    <>
      <div className=" absolute place-items-center">
        <p>Download</p>
      </div>
      {isLoading && (
        <div className="bg-gray-900/8  absolute inset-0 z-50 grid   place-items-center  backdrop-blur-sm">
          <Spinner size="large" />
        </div>
      )}
    </>
  );
}
