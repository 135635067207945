import React from 'react';
import dayjs from 'dayjs';

import { createMarkup } from 'src/utils/common';

function PersonAlertListItem({ alertInfo }: { alertInfo: any }) {
  function getReason(content: Array<any>) {
    return content?.map(item => item.Description)?.join(', ');
  }

  return (
    <article
      className={`overflow-hidden rounded-md border  ${
        alertInfo?.Status === 0
          ? ' border-red-400 dark:border-red-700'
          : 'border-gray-300 dark:border-neutral-950'
      }`}
    >
      <header
        className={`border-b- flex flex-wrap justify-between gap-2 ${
          alertInfo?.Status === 0
            ? ' border-red-500 bg-red-100  dark:border-red-700 dark:bg-red-900'
            : 'border-gray-300 bg-gray-100 dark:border-neutral-950 dark:bg-neutral-900'
        } p-2`}
      >
        <div className="flex items-baseline gap-2">
          <span className="text-sm dark:text-red-300">Alert:</span>
          <strong
            className={`${
              alertInfo?.Status === 0
                ? 'text-red-800 dark:text-white'
                : 'text-gray-600 dark:text-neutral-400'
            }`}
          >
            {alertInfo?.Id}
          </strong>
        </div>
      </header>
      <div className="space-y-4 p-2">
        <div className="flex flex-col">
          <span className="text-sm text-gray-600 dark:text-neutral-400">
            Date range
          </span>
          <strong className="font-semibold">
            From{' '}
            {alertInfo?.StartDate != null
              ? dayjs(alertInfo?.StartDate).format('MM/DD/YYYY H:mm:ss')
              : ''}{' '}
            -{' '}
            {alertInfo?.EndDate != null
              ? dayjs(alertInfo?.EndDate).format('MM/DD/YYYY H:mm:ss')
              : ''}
          </strong>
        </div>
        <div className="flex flex-col">
          <span className="text-sm text-gray-600 dark:text-neutral-400">
            Reasons
          </span>
          <strong className="font-semibold">
            {alertInfo?.Reasons?.length > 0
              ? getReason(alertInfo?.Reasons)
              : ''}
          </strong>
        </div>
        <div className="flex flex-col">
          <span className="text-sm text-gray-600 dark:text-neutral-400">
            Situation description
          </span>
          <strong
            className="font-semibold"
            dangerouslySetInnerHTML={createMarkup(
              alertInfo?.SituationDescription,
            )}
          ></strong>
        </div>
        <div className="flex flex-col">
          <span className="text-sm text-gray-600 dark:text-neutral-400">
            Telephone intervention description
          </span>
          <strong
            className="font-semibold"
            dangerouslySetInnerHTML={createMarkup(
              alertInfo?.TelephoneInterventionDescription,
            )}
          ></strong>
        </div>
      </div>
    </article>
  );
}

export { PersonAlertListItem };
