import { UseQueryResult, useQuery } from 'react-query';

import { useAuth } from 'src/hooks';

import { getClinicianList } from 'src/utils/urls';
import { formatSelectOption } from 'src/utils/common';

import { ClinicianType, TReturnType } from '../types/scheduleCallType';

async function fetchClinicianList({
  token,
  tokenType,
}: {
  token: string;
  tokenType: string;
}): Promise<ApiResult> {
  const response = await fetch(getClinicianList, {
    method: 'GET',
    headers: {
      Authorization: `${tokenType} ${token}`,
    },
  });
  return response.json();
}
type ApiResult = Array<ClinicianType>;
type TError = { message: string };

function useGetClinicianList(): UseQueryResult<TReturnType, TError> {
  const { token, tokenType } = useAuth();
  return useQuery(
    ['get_clinician_list'],
    async () => {
      const result = await fetchClinicianList({ token, tokenType });
      const value = formatSelectOption(result, 'Id', 'Identity', 'clinician');
      return value;
    },
    {
      staleTime: Infinity,
      refetchOnWindowFocus: false,
    },
  );
}
export { useGetClinicianList };
