import React, { ReactElement } from 'react';

import { ResponseType } from 'src/features/PersonRepository/types/QuestionsTabType';

function ChoiceDisplay({ question }: { question: ResponseType }): ReactElement {
  function getResponseValue() {
    if (question?.Value !== null) {
      let values = question?.Value.split('|');
      if (values) {
        return values.map(x => {
          return question.Options.find(y => y.ItemName === x)?.ItemLabel;
        });
      } else {
        return [];
      }
    }
    return [];
  }

  return (
    <>
      {getResponseValue()
        ? getResponseValue().map((x, index) => {
            return (
              <p key={index} className="w-4/6 py-2 text-sm">
                {x}
                <br />
              </p>
            );
          })
        : ''}
    </>
  );
}

export { ChoiceDisplay };
