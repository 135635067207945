import { useMutation, UseMutationResult, useQueryClient } from 'react-query';

import { useAuth } from 'src/hooks';
import { useProgressStore } from 'src/hooks/useProgressStore';

import { getWidgetList } from 'src/utils/urls';

async function saveUserSettings({
  token,
  tokenType,
  saveRequest,
}: TArguments): Promise<TResult> {
  return fetch(getWidgetList, {
    method: 'PUT',
    headers: {
      Authorization: `${tokenType} ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      'Dashboard-Settings': JSON.stringify(saveRequest),
    }),
  });
}

export type TResult = any;

export type TError = { message: string };

export type TArguments = {
  saveRequest: Array<string>;
  token: string;
  tokenType: string;
};

export type TVariables = {
  saveRequest: Array<string>;
};

function useSaveDashboardSettings(): UseMutationResult<
  TResult,
  TError,
  TVariables,
  unknown
> {
  const { token, tokenType } = useAuth();
  const queryClient = useQueryClient();
  const { setLoading } = useProgressStore();
  return useMutation(
    async (params: TVariables) => {
      setLoading('isSaveSettingsLoading', true);
      const result = await saveUserSettings({
        saveRequest: params.saveRequest,
        token,
        tokenType,
      });
      queryClient.invalidateQueries(['get_dashboard_settings']);
      if (result.status === 302 && result.redirected === true)
        throw {
          message: 'Expired',
        };

      return result;
    },
    {
      onSettled: () => {
        setLoading('isSaveSettingsLoading', false);
      },
    },
  );
}

export { useSaveDashboardSettings };
