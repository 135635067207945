import React, {
  ReactElement,
  useEffect,
  useState,
  Fragment,
  useMemo,
  useCallback,
  SyntheticEvent,
} from 'react';
import { useQueryClient } from 'react-query';
import { XMarkIcon } from '@heroicons/react/20/solid';
import { Transition, Dialog } from '@headlessui/react';

import { Button, Select, Checkbox, Spinner } from 'src/ui/components';

import { useUpdateTag } from '../api';
import { useProgressStore } from 'src/hooks/useProgressStore';

import { tagStatusMsg } from '../utils/callDocumentConstants';
import { useCallDocStore } from '../store/callDocStore';

import { CallDocumentType, TagType } from '../types/callDocumentList';

type TagHeaderType = {
  selectedDocs: Array<CallDocumentType>;
  tagList: Array<TagType> | undefined;
};

function TagStatus({ selectedDocs, tagList }: TagHeaderType): ReactElement {
  const [selectedTagStatus, setSelectedTagStatus] = useState<{
    id: number;
    label: string;
    value: string;
  }>({
    id: -1,
    label: 'Please select',
    value: 'Please select',
  });
  const [tagUpdateModal, setTagUpdateModal] = useState(false);

  const queryClient = useQueryClient();

  const { setLoading } = useProgressStore();
  const { mutations } = useProgressStore();

  const { mutate: updateTag } = useUpdateTag();

  const callDocsData = useCallDocStore(
    useCallback(state => state.callDocsData, []),
  );

  /* dropdown for tagstatus selection*/
  const tagStatusOptions = useMemo(() => {
    let result = tagList?.map((tag: TagType) => ({
      value: tag?.TagName,
      label: tag?.TagName,
      id: tag?.Id,
    }));
    result?.unshift({ id: -1, label: 'Please select', value: 'Please select' });
    const updatedObjects = result?.map(obj => {
      if (obj?.label === '' || obj?.label === ' ') {
        return { ...obj, label: '[blank]' };
      }
      return obj;
    });
    if (updatedObjects) return updatedObjects;
    else return [];
  }, [tagList]);

  function wait(millis: number) {
    return new Promise(resolve => {
      setTimeout(resolve, millis);
    });
  }

  //for dynamically setting the tagstatus when the  user select an entry
  useEffect(() => {
    if (selectedDocs.length > 0) {
      const selectedTag: any = callDocsData
        .filter(objB => selectedDocs?.some(objA => objA?.Id === objB?.Id))
        .map(x => x.TagId);

      const isAllCallDocsWithSameTag =
        selectedTag?.length > 0 &&
        selectedTag?.every((x: any) => x === selectedTag[0]);

      if (isAllCallDocsWithSameTag) {
        const tagObj = tagStatusOptions.find(tag => tag.id === selectedTag[0]);
        if (tagObj) setSelectedTagStatus(tagObj);
      } else {
        setSelectedTagStatus({
          id: -1,
          label: 'Please select',
          value: 'Please select',
        });
      }
    }
  }, [callDocsData, selectedDocs, tagStatusOptions]);

  let skipTagChangeWarning = localStorage.getItem('skipTagChangeWarning');

  function onUpdateTagStatus(selectedTag: any) {
    const selectedCallDocs = callDocsData?.filter(objB =>
      selectedDocs?.some(objA => objA?.Id === objB?.Id),
    );
    const hasSameTagStatus = selectedCallDocs?.every(
      x => x.TagId == selectedTag.id,
    );

    if (!hasSameTagStatus) {
      updateTag(
        {
          CallIds: selectedCallDocs?.map(x => x.Id),
          status: selectedTag.id,
        },
        {
          onSuccess: async () => {
            setTagUpdateModal(false);
            await wait(2000);
            setLoading('isTagUpdating', false);
            queryClient.invalidateQueries('get_call_documents');
          },
        },
      );
    } else {
      setTagUpdateModal(false);
    }
  }

  function getTagStatus(value: string) {
    return tagStatusOptions.find(tag => tag.value === value);
  }

  function handleOnChange(selectedTag: string) {
    const selectedTagObj = getTagStatus(selectedTag);
    if (selectedTagObj) {
      setSelectedTagStatus(selectedTagObj);
      if (selectedTagObj?.value !== 'Please select') {
        if (skipTagChangeWarning !== 'true') {
          setTagUpdateModal(true);
        } else {
          onUpdateTagStatus(selectedTagObj);
        }
      }
    }
  }

  /* tagstatus modal*/
  function renderTagStatusModal(): ReactElement {
    return (
      <Transition appear show={tagUpdateModal} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => setTagUpdateModal(false)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="pr-dialog__backdrop" />
          </Transition.Child>

          <div className="pr-dialog__wrapper">
            <div className="pr-dialog__panel__container">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="pr-dialog__panel">
                  <Dialog.Title
                    as="h3"
                    className="py-4 pl-4 pr-16 font-medium leading-6 text-gray-900 dark:text-white"
                  >
                    {tagStatusMsg}
                  </Dialog.Title>
                  <button
                    type="button"
                    className="absolute right-4 top-4 text-gray-400 hover:text-gray-500 sm:right-4 sm:top-4"
                    onClick={() => setTagUpdateModal(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                  <div className="flex flex-col gap-x-[1ch] gap-y-4 border-t bg-gray-50 p-3 dark:border-dark-medium dark:bg-neutral-700/50 sm:flex-row sm:items-center sm:justify-between sm:p-4">
                    <Checkbox
                      id="doNotShow"
                      label="Do not show this again"
                      onClick={e => {
                        if (e.currentTarget?.checked) {
                          localStorage.setItem('skipTagChangeWarning', 'true');
                        } else localStorage.removeItem('skipTagChangeWarning');
                      }}
                    />
                    <div className="flex gap-[1ch] sm:ml-auto">
                      <Button
                        variant="secondary"
                        onClick={() => setTagUpdateModal(false)}
                        className="grow sm:grow-0"
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="primary"
                        onClick={() => {
                          onUpdateTagStatus(selectedTagStatus);
                          setTagUpdateModal(false);
                        }}
                        className="grow sm:grow-0"
                      >
                        Continue
                      </Button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    );
  }

  return (
    <>
      <div className="flex flex-col gap-1 sm:flex-row sm:items-center sm:gap-2">
        <label htmlFor="tag-status" className="text-xs font-medium">
          Tag status
        </label>
        <Select
          inputSize="small"
          id="tag-status"
          options={tagStatusOptions}
          className="w-auto"
          value={selectedTagStatus?.value}
          onChange={(e: SyntheticEvent<HTMLSelectElement>) =>
            handleOnChange(e.currentTarget.value)
          }
        />
      </div>
      {skipTagChangeWarning !== 'true' && renderTagStatusModal()}
      {mutations?.isTagUpdating && (
        <div className="fixed inset-0 z-20 grid place-content-center">
          <Spinner size="large" />
        </div>
      )}
    </>
  );
}

export { TagStatus };
