import React, { ReactElement } from 'react';
import { motion } from 'framer-motion';

import { CheckIcon } from 'src/ui/components/CheckIcon';

type StepType = {
  step: number;
  currentStep: number;
  stepLabel?: string | ReactElement;
  stepIcon?: ReactElement;
  stepNumber: number;
};

export function Step({
  step,
  currentStep,
  stepLabel,
  stepIcon,
  stepNumber,
}: StepType): ReactElement {
  const status =
    currentStep === step
      ? 'active'
      : currentStep < step
      ? 'inactive'
      : 'complete';

  return (
    <div className="flex flex-col items-center gap-2 sm:flex-row sm:gap-3">
      <motion.div animate={status} className="relative">
        <motion.div
          variants={{
            active: {
              scale: 1,
              transition: {
                delay: 0,
                duration: 0.2,
              },
            },
            complete: {
              scale: 1.25,
            },
          }}
          transition={{
            duration: 0.6,
            delay: 0.2,
            type: 'tween',
            ease: 'circOut',
          }}
          className="absolute inset-0 rounded-full bg-primary/50"
        />
        <motion.div
          initial={false}
          transition={{ duration: 0.2 }}
          className={`relative flex h-8 w-8 items-center justify-center rounded-full border-2 font-semibold ${
            status === 'active'
              ? 'border-primary-400 bg-white text-primary-400 dark:bg-neutral-950'
              : status === 'complete'
              ? 'border-primary-400 bg-primary-400 text-primary-400'
              : 'border-gray-200 bg-white text-gray-400 dark:border-gray-600 dark:bg-neutral-950'
          }`}
        >
          <div className="flex items-center justify-center">
            {status === 'complete' ? (
              <CheckIcon className="h-6 w-6 text-white" />
            ) : (
              <span>{stepIcon}</span>
            )}
          </div>
        </motion.div>
      </motion.div>

      <div className="flex flex-col gap-1">
        {step !== 0 && (
          <small className="hidden text-[10px] uppercase leading-none text-gray-500 dark:text-neutral-500 sm:inline-block">
            Step {stepNumber}
          </small>
        )}

        <span className="text-center text-xs font-medium !leading-none dark:text-white sm:text-sm">
          {stepLabel}
        </span>
      </div>
    </div>
  );
}
