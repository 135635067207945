import React, { HTMLAttributes } from 'react';
import clsx, { ClassValue } from 'clsx';

import { classNames } from 'src/utils/className';

type NoWdigetProps = HTMLAttributes<HTMLDivElement> & {
  className?: ClassValue;
};

export function NoWidgetState({ className, ...rest }: NoWdigetProps) {
  const userClasses = clsx(className);

  return (
    <div
      className={classNames(
        'grid grow place-items-center rounded-lg border-2 border-dashed border-gray-400 px-4 py-20 dark:border-dark-medium',
        userClasses,
      )}
      {...rest}
    >
      <p className="text-center text-gray-600 dark:text-neutral-400">
        <span>Click the</span> <span className="font-bold">Widgets</span>{' '}
        <span>button to select desired modules to view here.</span>
      </p>
    </div>
  );
}
