import { useQuery, UseQueryResult } from 'react-query';

import { useAuth } from 'src/hooks';

import { downloadZip } from 'src/utils/urls';

import { CallDocumentsPreviewType } from '../types/callDocumentPreview';

export async function fetchCallDocumentsPreview({
  token,
  tokenType,
  callDocumentId,
}: TVariables): Promise<CallDocumentsPreviewType> {
  const response = await fetch(downloadZip(callDocumentId), {
    method: 'GET',
    headers: {
      Authorization: `${tokenType} ${token}`,
    },
  });
  return response.json();
}

export type TResult = {
  FileContent: string;
  FileName: string;
  Status: string;
};

export type TVariables = {
  token: string;
  tokenType: string;
  callDocumentId: string;
};

export type TError = { message: string };

function useDownloadZip(
  callDocumentId: string | null,
): UseQueryResult<TResult, TError> {
  const { token, tokenType } = useAuth();

  return useQuery(
    'download_zip',
    async () => {
      if (callDocumentId && callDocumentId !== null) {
        const result = await fetchCallDocumentsPreview({
          token,
          tokenType,
          callDocumentId,
        });
        return result;
      }
    },
    {
      staleTime: Infinity,
      refetchOnWindowFocus: false,
    },
  );
}

export { useDownloadZip };
