import { useMutation, UseMutationResult } from 'react-query';

import { useAuth } from 'src/hooks';

import { downloadAsCsv } from 'src/utils/urls';
import { setListRequestData } from '../utils/reqData';

import { FilterOptionsType } from 'src/features/Users/types/usersListType';

async function postDownloadCsv({
  token,
  tokenType,
  requestData,
}: TArguments): Promise<TResult> {
  const response = await fetch(downloadAsCsv, {
    method: 'POST',
    headers: {
      Authorization: `${tokenType} ${token}`,
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: requestData,
  });

  if (response.status === 204) return 'Success';
  else return response.json();
}

export type ValidationError = { Message?: string };
export type TResult = string | ValidationError;
export type TError = { message?: string };

export type TArguments = {
  token: string;
  tokenType: string;
  requestData: any;
};

export type TVariables = {
  filterOptions: FilterOptionsType;
};

function useDownloadCsv(): UseMutationResult<
  TResult,
  TError,
  TVariables,
  unknown
> {
  const { token, tokenType } = useAuth();

  return useMutation(async (params: TVariables) => {
    const requestData = setListRequestData(params.filterOptions);
    const result = await postDownloadCsv({
      token,
      tokenType,
      requestData,
    });
    if (result === 'Success') return result;
    else throw result;
  });
}
export { useDownloadCsv };
