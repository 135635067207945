// Error message for the password requirements
export function getPasswordWarningMsg(password: string) {
  const requirements = [
    { pattern: /\d/, message: 'one digit (0-9)' },
    { pattern: /[a-z]/, message: 'one lowercase character' },
    { pattern: /[A-Z]/, message: 'one uppercase character' },
    { pattern: /[^a-zA-Z0-9]/, message: 'one special character' },
  ];

  const missingRequirements = requirements.filter(
    ({ pattern }) => !pattern.test(password),
  );

  if (missingRequirements.length) {
    const warningMsg = missingRequirements
      .map(({ message }) => message)
      .join(', ');
    return 'Password must have ' + warningMsg;
  }
  return '';
}

export const formatAuthType = (authType: string | null) => {
  return authType?.toLocaleLowerCase() ?? 'email';
};
