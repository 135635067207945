import { UseQueryResult, useQuery } from 'react-query';

import { useAuth } from 'src/hooks';

import { getCallType } from 'src/utils/urls';

import { CallType } from '../types/callDocumentList';

async function fetchCallTypeDescription(
  token: string,

  tokenType: string,
): Promise<ApiResult> {
  const response = await fetch(getCallType, {
    method: 'GET',
    headers: {
      Authorization: `${tokenType} ${token} `,
    },
  });

  return response.json();
}

type TError = {
  message: string;
};

type ApiResult = Array<CallType>;

function useGetCallTypeDescription(): UseQueryResult<ApiResult, TError> {
  const { token, tokenType } = useAuth();
  return useQuery(
    'calltypelist',
    async () => {
      const result = await fetchCallTypeDescription(token, tokenType);
      return result;
    },
    {
      staleTime: 60 * 1000,
      refetchOnWindowFocus: false,
    },
  );
}

export { useGetCallTypeDescription };
