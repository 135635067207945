import dayjs from 'dayjs';

function equatingNullValues(value: any): any {
  if (
    value === null ||
    value === undefined ||
    value === '' ||
    value === '-1' ||
    value == -1
  ) {
    return null;
  }
  if (Array.isArray(value)) {
    return value.map(equatingNullValues);
  }
  if (typeof value === 'object' && value !== null) {
    const normalizedState: any = {};
    for (const key in value) {
      if (value.hasOwnProperty(key)) {
        normalizedState[key] = equatingNullValues(value[key]);
      }
    }
    return normalizedState;
  }
  return value;
}

function hasValidvalue(obj: any) {
  return Object.values(obj).some(
    (value: any) =>
      value !== null &&
      value !== '' &&
      value !== undefined &&
      value?.length !== 0 &&
      Object?.values(value)?.length > 0,
  );
}

//function to compare birth date
function birthDateComparison(alertDeatils: any, initialAlert: any) {
  const initialBirthDate = dayjs(
    initialAlert?.Person?.PersonAlertDetails?.Birthdate,
  ).format('MM/DD/YYYY');
  const updatedBirthDate = dayjs(
    alertDeatils?.Person?.PersonAlertDetails?.Birthdate,
  ).format('MM/DD/YYYY');
  if (initialBirthDate === updatedBirthDate) return true;
  else return false;
}

function formatInitialAlerts(alertDetails: any, accountsInfo: any) {
  const modifiedAlert = {
    ...alertDetails,
    alertDate: alertDetails?.alertDate.replace(/\s+/g, ''),
    Person: {
      ...alertDetails?.Person,
      PersonCallLimit:
        alertDetails?.Person?.PersonCallLimit === null
          ? null
          : {
              MaxCallCount:
                alertDetails?.Person?.PersonCallLimit?.MaxCallCount !== null
                  ? parseInt(
                      alertDetails?.Person?.PersonCallLimit?.MaxCallCount,
                    )
                  : null,
              ResetInterval:
                alertDetails?.Person?.PersonCallLimit?.ResetInterval?.slice(
                  0,
                  5,
                ),
              CallsCalled:
                alertDetails?.Person?.PersonCallLimit?.CallsCalled !== null
                  ? parseInt(alertDetails?.Person?.PersonCallLimit?.CallsCalled)
                  : 0,
              TimeZoneId:
                alertDetails?.Person?.PersonCallLimit?.TimeZoneId !== null &&
                alertDetails?.Person?.PersonCallLimit?.TimeZoneId !== undefined
                  ? parseInt(alertDetails?.Person?.PersonCallLimit?.TimeZoneId)
                  : null,
            },
    },
    accountsInfo: { ...accountsInfo },
    hasCallLimit: alertDetails?.Person?.PersonCallLimit !== null ? true : false,
    newPerson: false,
  };
  return modifiedAlert;
}

function formatcurrentAlert(
  alertDetails: any,
  accountsInfo: any,
  hasCallLimit: boolean,
  newPerson: boolean,
) {
  let newAlertDetails = {
    ...alertDetails,
    accountsInfo: { ...accountsInfo },
    hasCallLimit: hasCallLimit,
    newPerson: newPerson,
    alertDate: alertDetails?.alertDate.replace(/\s+/g, ''),
    Person: {
      ...alertDetails?.Person,
      PersonCallLimit:
        hasCallLimit === false
          ? null
          : {
              MaxCallCount: parseInt(
                alertDetails?.Person?.PersonCallLimit?.MaxCallCount,
              ),
              ResetInterval:
                alertDetails?.Person?.PersonCallLimit?.ResetInterval?.slice(
                  0,
                  5,
                ),
              CallsCalled: alertDetails?.Person?.PersonCallLimit?.CallsCalled
                ? parseInt(alertDetails?.Person?.PersonCallLimit?.CallsCalled)
                : 0,
              TimeZoneId: parseInt(
                alertDetails?.Person?.PersonCallLimit?.TimeZoneId,
              ),
            },
    },
  };
  if (newAlertDetails?.alertDetails?.hasCallLimit === false) {
    let person = {
      ...alertDetails?.Person,
    };
    delete person['PersonCallLimit'];
    newAlertDetails = { ...newAlertDetails, Person: { ...person } };
  }
  return newAlertDetails;
}
export {
  hasValidvalue,
  formatInitialAlerts,
  equatingNullValues,
  formatcurrentAlert,
  birthDateComparison,
};
