import { useMutation, UseMutationResult } from 'react-query';

import { defaultDashboardSettings } from 'src/utils/urls';

async function saveUserSettings({ id }: TVariables): Promise<TResult> {
  return fetch(defaultDashboardSettings(id), {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      'Dashboard-Settings': JSON.stringify(['Clinical call resolution']),
    }),
  });
}

export type TResult = any;

export type TError = { message: string };

export type TVariables = {
  id: string | null;
};

function useSetDefaultDashbordSettings(): UseMutationResult<
  TResult,
  TError,
  TVariables,
  unknown
> {
  return useMutation(async (params: TVariables) => {
    const result = await saveUserSettings({
      id: params.id,
    });
    return result;
  });
}

export { useSetDefaultDashbordSettings };
