import React, { ReactElement, useCallback, useState } from 'react';
import {
  LockClosedIcon,
  EyeIcon,
  XMarkIcon,
  EyeSlashIcon,
} from '@heroicons/react/20/solid';

import {
  inputBaseClasses,
  inputSizeClasses,
  buttonBaseClasses,
  buttonSizeClasses,
  buttonVariantClasses,
  Alert,
  CheckIcon as AnimatedCheckIcon,
} from 'src/ui/components';

import {
  setPasswordCaption,
  setPasswordWarning,
} from '../utils/onBoardingConstants';
import { validatePassword, specialCharArray } from 'src/utils/common';
import { userOnBoardingStore } from 'src/store/userOnboardingStore';

export function SetPassword({
  password,
  setPassword,
}: {
  password: string;
  setPassword: (value: string | ((prevValue: string) => string)) => void;
}) {
  const [passwordType, setPasswordType] = useState<string>('password');

  const passWordErrMsg = userOnBoardingStore(
    useCallback(state => state.passWordErrMsg, []),
  );

  const showPasswordErr = userOnBoardingStore(
    useCallback(state => state.showPasswordErr, []),
  );

  function getIcon(type: string, message: string): ReactElement {
    return (
      <div className="flex items-center gap-1">
        <span>
          {validatePassword(type, password) ? (
            <AnimatedCheckIcon className="h-5 w-5 text-green-600" />
          ) : (
            <XMarkIcon className="h-5 w-5 text-red-600" />
          )}
        </span>
        <span className="grow text-xs font-medium dark:font-normal md:text-sm">
          {message}
        </span>
      </div>
    );
  }

  return (
    <div className="container mx-auto flex max-w-2xl flex-col justify-center gap-y-8 p-4  md:h-full  md:p-6 ">
      <div className="space-y-1">
        <div
          className="mb-2 flex h-10 w-10 items-center justify-center rounded border-primary-600 bg-primary-100/50 p-2 dark:bg-primary-600/20"
          aria-hidden="true"
        >
          <LockClosedIcon className="dark:text-primary-2300 h-5 w-5 text-primary-500" />
        </div>
        <h2 className="text-xl font-medium md:text-2xl">Set password</h2>
        <p className="text-xs text-gray-500 dark:text-neutral-400 md:text-sm">
          {setPasswordCaption}
        </p>
      </div>
      <div className="space-y-2">
        <div className="flex flex-col gap-1">
          <label
            htmlFor="password"
            className="text-sm font-medium leading-none"
          >
            Password
          </label>
          <div className="relative flex animate-shake flex-wrap items-stretch">
            <input
              id="password"
              className={`${[inputBaseClasses, inputSizeClasses.large].join(
                ' ',
              )} !w-max min-w-0 flex-auto !rounded-r-none outline-none`}
              value={password}
              type={passwordType}
              onChange={e => setPassword(e.target.value)}
              onCopy={e => e.preventDefault()}
              onPaste={e => e.preventDefault()}
              onDrag={e => e.preventDefault()}
              onDrop={e => e.preventDefault()}
            />
            <button
              className={`${[
                buttonBaseClasses,
                buttonSizeClasses.large,
                buttonVariantClasses.subtle,
              ].join(
                ' ',
              )} !rounded-l-none ring-1 ring-inset ring-gray-300 dark:ring-neutral-700`}
              title={
                passwordType === 'password' ? 'Show password' : 'Hide password'
              }
              onClick={e => {
                passwordType === 'password'
                  ? setPasswordType('text')
                  : setPasswordType('password');
              }}
            >
              <span className="sr-only">
                {passwordType === 'password'
                  ? 'Show password'
                  : 'Hide password'}
              </span>
              {passwordType === 'text' ? (
                <EyeIcon className="-ml-0.5 h-5 w-4" aria-hidden="true" />
              ) : (
                <EyeSlashIcon className="-ml-0.5 h-5 w-4" aria-hidden="true" />
              )}
            </button>
          </div>
        </div>
        <div className="columns-2 md:space-y-1">
          {getIcon('lowercase', 'One lowercase character')}
          {getIcon('uppercase', 'One uppercase character')}
          {getIcon('reqLength', '8 character minimum')}
          {getIcon('number', 'One number')}
          <div className="space-y-1">
            {getIcon('specialChar', 'One special character')}
            <span className="ml-6 inline-block text-xs leading-4">
              Special characters can include <br />
              {specialCharArray}
            </span>
          </div>
        </div>
      </div>
      <Alert
        variant={showPasswordErr ? 'danger' : 'warning'}
        title={showPasswordErr ? 'Error' : 'Warning'}
        className="text-sm"
      >
        {showPasswordErr ? passWordErrMsg : setPasswordWarning}
      </Alert>
    </div>
  );
}
