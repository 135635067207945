import { UseMutationResult, useMutation, useQueryClient } from 'react-query';

import { useAuth } from 'src/hooks';

import { cancelActivity } from 'src/utils/urls';

async function cacnelActivity({
  token,
  tokenType,
  activityId,
}: TVariables): Promise<any> {
  const result = await fetch(cancelActivity(activityId), {
    method: 'PUT',
    headers: {
      Authorization: `${tokenType} ${token}`,
      'Content-Type': 'application/json',
    },
  });
  return result?.json();
}
type TVariables = {
  token: string;
  tokenType: string;
  activityId: number;
};
type TError = {
  message: string;
};

function useCancelActivity(): UseMutationResult<void, TError, number, unknown> {
  const { token, tokenType } = useAuth();
  const queryClient = useQueryClient();

  return useMutation(async (activityId: number) => {
    cacnelActivity({
      token,
      tokenType,
      activityId,
    });
    queryClient.invalidateQueries('get_tracker_list');
  });
}
export { useCancelActivity };
