/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import $ from 'jquery';
import 'daterangepicker';
import moment from 'moment';
import clsx, { ClassValue } from 'clsx';

import {
  buttonSizeClasses,
  buttonVariantClasses,
  buttonBaseClasses,
  disabledClass,
} from 'src/ui/components/Button';
import { inputBaseClasses, inputSizeClasses } from '../TextField';

import {
  momentPastRanges,
  momentFutureRanges,
  compareDates,
} from 'src/utils/datepickerHelper';

type Size = 'small' | 'medium';
type CalenderType = {
  placeholder?: string;

  value: any;
  setValues?: (keyValue: any, value: string) => void;
  size?: Size;
  format: string;
  rangeType: string;
  className?: ClassValue;
  disabled?: boolean;
  keyValue?: any;
  onChange?: (val: string) => void;
  callScheduler?: string;
  id?: string;
  title?: string;
  refferenceClass?: any;
  placement?: 'left' | 'right' | 'center';
};

function DateRangePicker({
  placeholder,
  value,
  setValues,
  keyValue,
  format,
  size = 'medium',
  rangeType,
  className,
  disabled,
  onChange,
  callScheduler,
  id,
  title,
  refferenceClass,
  placement,
}: CalenderType) {
  const [open, setOpen] = useState<boolean | undefined>(undefined);
  const refValue = useRef<any>();
  const eleRef = useRef<any>();

  //useEffect for clearing the default selected dates in daterangepicker.

  useEffect(() => {
    var drp = $(eleRef?.current).data('daterangepicker');

    let startDate = value?.length && value?.split('-')[0];
    let endDate = value?.length && value?.split('-')[1];

    if (open !== undefined) {
      if (startDate?.length > 0 && endDate?.length > 0) {
        drp?.setStartDate(startDate);
        drp?.setEndDate(endDate);
      } else {
        if (open !== true) {
          $(eleRef.current).val('');
          drp?.setStartDate(moment().startOf('day').format(format));
          drp?.setEndDate(moment().endOf('day').format(format));
        }
      }
    }
  }, [value, open, format]);

  //datepicker initialization function
  useEffect(() => {
    $(eleRef.current).daterangepicker({
      timePicker: true,
      autoUpdateInput: false,
      drops: 'down',
      ...(placement && { opens: 'center' }),
      locale: {
        format: 'MM/DD/YYYY HH:mm',
        cancelLabel: 'Clear',
      },
      startDate:
        value && value !== null && typeof value === 'string'
          ? new Date(value?.split('-')[0])
          : moment().startOf('day').format('MM/DD/YYYY HH:mm:ss'),
      endDate:
        value && value !== null && typeof value === 'string'
          ? new Date(value?.split('-')[1])
          : moment().endOf('day').format('MM/DD/YYYY HH:mm:ss'),

      ranges: rangeType === 'past' ? momentPastRanges : momentFutureRanges,
      alwaysShowCalendars: true,
      timePicker24Hour: true,
      //parentEl: document.querySelector('.daterange-wrapper') ?? '',
      parentEl: refferenceClass?.current ?? '',
      // Styling
      buttonClasses: [
        `${buttonBaseClasses}`,
        `${buttonSizeClasses.small} ml-2`,
        `${disabledClass}`,
      ],
      applyButtonClasses: `${buttonVariantClasses.primary}`,
      cancelButtonClasses: `${buttonVariantClasses.secondary}`,
    });
    $(eleRef.current).on('apply.daterangepicker', function (ev: any, picker) {
      $(eleRef?.current).val(
        picker.startDate.format(format) + ' - ' + picker.endDate.format(format),
      );
      let val = ev.currentTarget.value;
      if (format?.endsWith('s') && ev.currentTarget.value?.slice(-2) === '00') {
        let currentValue = ev.currentTarget.value;
        val = currentValue.slice(0, -2) + '59';
      }

      if (keyValue && keyValue !== '') {
        setValues && setValues(keyValue, val);
      } else onChange && onChange(val);
      refValue.current = val;
    });

    $(eleRef.current).on('cancel.daterangepicker', function (ev, picker) {
      var drp = $(eleRef?.current).data('daterangepicker');
      drp?.setStartDate(moment().startOf('day').format(format));
      drp?.setEndDate(moment().endOf('day').format(format));
      $(eleRef.current).val('');

      if (keyValue && keyValue !== '') {
        setValues && setValues(keyValue, '');
      } else onChange && onChange('');
      refValue.current = '';
    });
    $(eleRef.current).on('show.daterangepicker', function (ev, picker) {
      setOpen(true);
    });
    $(eleRef.current).on('hide.daterangepicker', function (ev, picker) {
      setOpen(false);
    });
  }, [value]);

  function handleDateOnBlur() {
    let startDate;
    let endDate;
    //for scheduling purpose the default date on clearing is today for others it's last 30 days
    if (rangeType && rangeType === 'future') {
      startDate = moment().startOf('day').format(format);
      endDate = moment().endOf('day').format(format);
    } else {
      (startDate = moment().subtract(29, 'days').startOf('day').format(format)),
        (endDate = moment().endOf('day').format(format));
    }

    if (
      dateIsValid(refValue.current) === false &&
      refValue.current?.length > 0
    ) {
      if (keyValue === '') {
        onChange && onChange(`${startDate} - ${endDate}`);
      } else setValues && setValues(keyValue, `${startDate} - ${endDate}`);
    } else if (
      dateIsValid(refValue.current) === undefined &&
      refValue?.current
    ) {
      let newDateVal = refValue.current;
      let datepart = newDateVal.split(' ');

      if (
        datepart.length &&
        moment(datepart[0], 'MM/DD/YYYY', true)?.isValid()
      ) {
        datepart.length == 1
          ? (newDateVal = newDateVal.concat(' 00:00:00 '))
          : null;
      } else {
        newDateVal = moment().startOf('day').format(format);
      }
      if (keyValue === '') {
        onChange && onChange(`${newDateVal} - ${newDateVal}`);
      } else setValues && setValues(keyValue, `${newDateVal} - ${newDateVal}`);
    }
  }

  //for checking whether the date is valid or not (reinitializing date on clearing)
  function dateIsValid(dateValue: any) {
    let startDate = dateValue?.split('-')[0]?.trim();
    let endDate = dateValue?.split('-')[1]?.trim();
    let validStartDate = moment(startDate, format, true);
    let validEndDate = moment(endDate, format, true);
    if (startDate?.length && endDate?.length) {
      if (validStartDate.isValid() && validEndDate?.isValid()) return true;
      else return false;
    } else if (startDate?.length && endDate === undefined) {
      return undefined;
    }
  }

  //on manually changing the date on input field
  function handleDateOnChange(dateValue: any) {
    let newVal = dateValue;
    refValue.current = newVal;
    if (keyValue === '') onChange && onChange(newVal);
    else setValues && setValues(keyValue, newVal);
  }

  const userClasses = clsx(className);

  return (
    <input
      type="text"
      ref={eleRef}
      key={keyValue}
      placeholder={placeholder}
      value={value}
      className={[
        userClasses,
        inputBaseClasses,
        `${
          size === 'small' ? inputSizeClasses.small : inputSizeClasses.medium
        }`,
      ].join(' ')}
      disabled={disabled}
      onChange={(e: any) => {
        if (/^[0-9][0-9\/\-: ]*$/.test(e.target.value)) {
          handleDateOnChange(e.target.value);
        }
      }}
      onBlur={() => handleDateOnBlur()}
    />
  );
}

export { DateRangePicker };
