import React, { ReactElement, useCallback } from 'react';
import { motion } from 'framer-motion';

import { userOnBoardingStore } from 'src/store/userOnboardingStore';

export function ConfirmAuthType(): ReactElement {
  const twofactorAuthType = userOnBoardingStore(
    useCallback(state => state.twofactorAuthType, []),
  );

  return (
    <div className="flex flex-col items-center justify-center gap-2 rounded-lg border border-gray-300 bg-white p-3 shadow dark:border-dark-medium dark:bg-neutral-700 md:p-5">
      <p className="font-medium">
        {twofactorAuthType === 'email' ? 'Email' : 'Authenticator'} is set as an
        authentication type
      </p>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth={1}
        strokeLinecap="round"
        strokeLinejoin="round"
        className="h-28 w-28 text-green-600 dark:text-green-400 md:h-40 md:w-40"
      >
        <motion.path
          initial={{ pathLength: 0 }}
          animate={{ pathLength: 1 }}
          transition={{
            delay: 0.2,
            type: 'tween',
            ease: 'easeOut',
            duration: 1,
          }}
          d="M22 11.08V12a10 10 0 1 1-5.93-9.14"
        />
        <motion.polyline
          initial={{ pathLength: 0 }}
          animate={{ pathLength: 1 }}
          transition={{
            delay: 0.2,
            type: 'tween',
            ease: 'easeOut',
            duration: 1,
          }}
          points="8 10 12 14 23 4"
        />
      </svg>
      <small className="text-sm text-gray-500 dark:text-neutral-400">
        {`From now on you will be able to use ${
          twofactorAuthType === 'email' ? 'Email' : 'Authenticator app'
        } to sign in to Customer Portal.`}
      </small>
    </div>
  );
}
