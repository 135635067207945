import React, { ReactElement, useCallback } from 'react';

import { TooltipItem } from 'src/ui/components';

import { activeStyles } from 'src/utils/appConstants';
import { usePersonRepoStore } from '../store/personRepoStore';

import { PersonRepoType } from '../types/personRepoListTypes';

type ListType = {
  personInfo: PersonRepoType;
  handleRowClick: (item: PersonRepoType) => void;
  setShowViewEditModal: (val: boolean) => void;
};

function PersonRepoListItem({
  personInfo,
  handleRowClick,
  setShowViewEditModal,
}: ListType): ReactElement {
  const selectedPerson = usePersonRepoStore(
    useCallback(state => state.selectedPerson, []),
  );
  const setSelectedPerson = usePersonRepoStore(
    useCallback(state => state.setSelectedPerson, []),
  );

  return (
    <div
      tabIndex={0}
      className={`relative flex flex-wrap gap-x-5 gap-y-3 rounded bg-white p-2 shadow focus-visible:outline  focus-visible:outline-[3px] focus-visible:outline-offset-[3px] focus-visible:outline-blue-600 dark:bg-neutral-800 dark:focus-visible:outline-blue-400 lg:gap-x-5 ${
        selectedPerson?.Id === personInfo?.Id ? activeStyles : ''
      }`}
      onDoubleClick={() => {
        setSelectedPerson(personInfo);
        setShowViewEditModal(true);
      }}
      onClick={() => {
        handleRowClick(personInfo);
      }}
    >
      <div className="basis-[40%] md:basis-[30%] lg:basis-[25%] xl:basis-[8%] 2xl:basis-[10%]">
        <div>
          <label className="block whitespace-nowrap text-[10px] font-medium uppercase tracking-wide opacity-70">
            Person id
          </label>
          <p className="prevent-text-breakout break-words text-xs font-bold tracking-wide text-black dark:text-white">
            {personInfo.Id}
          </p>
        </div>
      </div>
      <div className="basis-[40%] space-y-2 md:basis-[30%] lg:basis-[25%] xl:basis-[11%] 2xl:basis-[10%]">
        <div>
          <label className="block whitespace-nowrap text-[10px] font-medium uppercase tracking-wide opacity-70">
            First name
          </label>
          <p className="prevent-text-breakout break-words text-xs font-bold tracking-wide text-black dark:text-white">
            {personInfo.FirstName}
          </p>
        </div>
        <div>
          <label className="block whitespace-nowrap text-[10px] font-medium uppercase tracking-wide opacity-70">
            Last name
          </label>
          <p className="prevent-text-breakout break-words text-xs font-bold tracking-wide text-black dark:text-white">
            {personInfo.LastName}
          </p>
        </div>
      </div>
      <div className="basis-[40%] md:basis-[30%] lg:basis-[25%] xl:basis-[12%] 2xl:basis-[10%]">
        <div>
          <label className="block whitespace-nowrap text-[10px] font-medium uppercase tracking-wide opacity-70">
            Preferred name
          </label>
          <p className="prevent-text-breakout break-words text-xs font-bold tracking-wide text-black dark:text-white">
            {personInfo.PreferredName}
          </p>
        </div>
      </div>
      <div className="basis-[40%] md:basis-[30%] lg:basis-[25%] xl:basis-[7%] 2xl:basis-[8%]">
        <div>
          <TooltipItem label="CID" values={personInfo.CID} />
        </div>
      </div>
      <div className="basis-[40%] md:basis-[30%] lg:basis-[25%] xl:basis-[7%] 2xl:basis-[8%]">
        <div>
          <TooltipItem label="SID" values={personInfo.SID} />
        </div>
      </div>
      <div className="basis-[40%] md:basis-[30%] lg:basis-[25%] xl:basis-[7%] 2xl:basis-[8%]">
        <div>
          <TooltipItem label="PID" values={personInfo.PID} />
        </div>
      </div>
      <div className="basis-[40%] md:basis-[30%] lg:basis-[25%] xl:basis-[14%] 2xl:basis-[14%]">
        <div>
          <label className="block whitespace-nowrap text-[10px] font-medium uppercase tracking-wide opacity-70">
            Primary phone number
          </label>
          <p className="prevent-text-breakout break-words text-xs font-bold tracking-wide text-black dark:text-white">
            {personInfo.PrimaryPhone}
          </p>
        </div>
      </div>
      <div className="basis-[40%] md:basis-[30%] lg:basis-[25%] xl:basis-[8%] 2xl:basis-[10%]">
        <div>
          <label className="block whitespace-nowrap text-[10px] font-medium uppercase tracking-wide opacity-70">
            City
          </label>
          <p className="prevent-text-breakout break-words text-xs font-bold tracking-wide text-black dark:text-white">
            {personInfo.City}
          </p>
        </div>
      </div>
      <div className="basis-[40%] md:basis-[30%] lg:basis-[25%] xl:basis-[8%] 2xl:basis-[10%]">
        <div>
          <label className="block whitespace-nowrap text-[10px] font-medium uppercase tracking-wide opacity-70">
            State
          </label>
          <p className="prevent-text-breakout break-words text-xs font-bold tracking-wide text-black dark:text-white">
            {personInfo.StateProvince}
          </p>
        </div>
      </div>
    </div>
  );
}

export { PersonRepoListItem };
