import { useMutation, UseMutationResult } from 'react-query';

import { getPersonAlertsDownload } from 'src/utils/urls';
import { useAuth } from 'src/hooks';

async function fetchPersonAlertsDownload({
  token,
  tokenType,
  pageParam,
  requestData,
}: {
  token: string;
  tokenType: string;
  pageParam: number;
  requestData: any;
}): Promise<TResult> {
  requestData.append('start', `${pageParam}`);
  const response = await fetch(getPersonAlertsDownload, {
    method: 'POST',
    headers: {
      Authorization: `${tokenType} ${token}`,
    },
    body: requestData,
  });
  if (response.status === 204) return 'Success';
  else throw response.json();
}

export type TVariables = { pageParam: number; filterOptions: any };

export type TResult = string;

export type TError = { message: string };

function setRequestData(filterOptions: any): FormData {
  const formData: any = new URLSearchParams();
  formData.append('draw', '1');
  formData.append('length', `${100}`);
  formData.append('timezoneOffset', `${new Date().getTimezoneOffset()}`);
  formData.append('columns[0][data]', 'Status');
  formData.append('columns[0][name]', '');
  formData.append('columns[0][searchable]', 'true');
  formData.append('columns[0][orderable]', 'true');
  formData.append('columns[0][search][regex]', 'false');
  formData.append('columns[0][search][ApplyExactMatch]', ''),
    formData.append(
      'columns[0][search][value]',
      filterOptions && filterOptions.status ? filterOptions.status : '',
    );

  formData.append('columns[1][data]', 'AccountId');
  formData.append('columns[1][name]', '');
  formData.append('columns[1][searchable]', 'true');
  formData.append('columns[1][orderable]', 'true');
  formData.append('columns[1][search][regex]', 'false');
  formData.append('columns[1][search][ApplyExactMatch]', ''),
    formData.append(
      'columns[1][search][value]',
      filterOptions && filterOptions.accountId ? filterOptions.accountId : '',
    );

  formData.append('columns[2][data]', 'StartDate');
  formData.append('columns[2][name]', '');
  formData.append('columns[2][searchable]', 'true');
  formData.append('columns[2][orderable]', 'true');
  formData.append('columns[2][search][regex]', 'false');
  formData.append('columns[2][search][ApplyExactMatch]', ''),
    formData.append(
      'columns[2][search][value]',
      filterOptions && filterOptions.startDate ? filterOptions.startDate : '',
    );
  formData.append('columns[3][data]', 'EndDate');
  formData.append('columns[3][name]', '');
  formData.append('columns[3][searchable]', 'true');
  formData.append('columns[3][orderable]', 'true');
  formData.append('columns[3][search][regex]', 'false');
  formData.append('columns[3][search][ApplyExactMatch]', ''),
    formData.append(
      'columns[3][search][value]',
      filterOptions && filterOptions.endDate ? filterOptions.endDate : '',
    );

  formData.append('columns[4][data]', 'EnteredDate');
  formData.append('columns[4][name]', '');
  formData.append('columns[4][searchable]', 'true');
  formData.append('columns[4][orderable]', 'true');
  formData.append('columns[4][search][regex]', 'false');
  formData.append('columns[4][search][ApplyExactMatch]', ''),
    formData.append(
      'columns[4][search][value]',
      filterOptions && filterOptions.enteredDate
        ? filterOptions.enteredDate
        : '',
    );

  formData.append('columns[5][data]', 'CID');
  formData.append('columns[5][name]', '');
  formData.append('columns[5][searchable]', 'true');
  formData.append('columns[5][orderable]', 'true');
  formData.append('columns[5][search][regex]', 'false');
  formData.append('columns[5][search][ApplyExactMatch]', ''),
    formData.append(
      'columns[5][search][value]',
      filterOptions && filterOptions.cid ? filterOptions.cid : '',
    );

  formData.append('columns[6][data]', 'PID');
  formData.append('columns[6][name]', '');
  formData.append('columns[6][searchable]', 'true');
  formData.append('columns[6][orderable]', 'true');
  formData.append('columns[6][search][regex]', 'false');
  formData.append('columns[6][search][ApplyExactMatch]', ''),
    formData.append(
      'columns[6][search][value]',
      filterOptions && filterOptions.pid ? filterOptions.pid : '',
    );
  formData.append('columns[7][data]', 'FirstName');
  formData.append('columns[7][name]', '');
  formData.append('columns[7][searchable]', 'true');
  formData.append('columns[7][orderable]', 'true');
  formData.append('columns[7][search][regex]', 'false');
  formData.append('columns[7][search][ApplyExactMatch]', ''),
    formData.append(
      'columns[7][search][value]',
      filterOptions && filterOptions.firstName ? filterOptions.firstName : '',
    );

  formData.append('columns[8][data]', 'LastName');
  formData.append('columns[8][name]', '');
  formData.append('columns[8][searchable]', 'true');
  formData.append('columns[8][orderable]', 'true');
  formData.append('columns[8][search][regex]', 'false');
  formData.append('columns[8][search][ApplyExactMatch]', ''),
    formData.append(
      'columns[8][search][value]',
      filterOptions && filterOptions.lastName ? filterOptions.lastName : '',
    );

  formData.append('columns[9][data]', 'PreferredName');
  formData.append('columns[9][name]', '');
  formData.append('columns[9][searchable]', 'true');
  formData.append('columns[9][orderable]', 'true');
  formData.append('columns[9][search][regex]', 'false');
  formData.append('columns[9][search][ApplyExactMatch]', ''),
    formData.append(
      'columns[9][search][value]',
      filterOptions && filterOptions.preferredName
        ? filterOptions.preferredName
        : '',
    );

  formData.append('columns[10][data]', 'Birthdate');
  formData.append('columns[10][name]', '');
  formData.append('columns[10]][searchable]', 'true');
  formData.append('columns[10][orderable]', 'true');
  formData.append('columns[10][search][regex]', 'false');
  formData.append('columns[10][search][ApplyExactMatch]', ''),
    formData.append(
      'columns[10][search][value]',
      filterOptions && filterOptions.birthDate ? filterOptions.birthDate : '',
    );

  formData.append('columns[11][data]', 'SituationDescription');
  formData.append('columns[11][name]', '');
  formData.append('columns[11][searchable]', 'true');
  formData.append('columns[11][orderable]', 'true');
  formData.append('columns[11][search][regex]', 'false');
  formData.append('columns[11][search][ApplyExactMatch]', ''),
    formData.append(
      'columns[11][search][value]',
      filterOptions && filterOptions.situationDescription
        ? filterOptions.situationDescription
        : '',
    );

  formData.append('columns[12][data]', 'Id');
  formData.append('columns[12][name]', '');
  formData.append('columns[12][searchable]', 'true');
  formData.append('columns[12][orderable]', 'true');
  formData.append('columns[12][search][regex]', 'false');
  formData.append('columns[12][search][ApplyExactMatch]', ''),
    formData.append(
      'columns[12][search][value]',
      filterOptions && filterOptions.id ? filterOptions.id : '',
    );

  formData.append('order[0][column]', filterOptions.sortType);
  formData.append(
    'order[0][dir]',
    filterOptions.sortOrder ? filterOptions.sortOrder : 'desc',
  );

  formData.append('search[value]', `${filterOptions.keywordSearch}`);
  formData.append('search[regex]', 'false');

  return formData;
}

function usePersonAlertsDownload(): UseMutationResult<
  TResult,
  TError,
  TVariables,
  unknown
> {
  const { token, tokenType } = useAuth();
  return useMutation(async ({ pageParam, filterOptions }) => {
    const requestData = setRequestData(filterOptions);
    const result = await fetchPersonAlertsDownload({
      token,
      tokenType,
      pageParam,
      requestData,
    });
    if (result === 'Success') return result;
    else throw result;
  });
}

export { usePersonAlertsDownload };
