import { getCountry } from 'src/utils/urls';

export async function fetchCountryDetails({
  token,
  tokenType,
}: Partial<{
  token: string;
  tokenType: string;
}>): Promise<any> {
  const response = await fetch(getCountry, {
    method: 'GET',
    headers: {
      Authorization: `${tokenType} ${token}`,
    },
  });
  return response.json();
}
