import { useQuery, UseQueryResult } from 'react-query';

import { useAuth } from 'src/hooks';

import { getTutorials } from 'src/utils/urls';

import { GetTutorials } from 'src/types/tutorialsType';

async function fetchTutorials({
  token,
  tokenType,
}: TVaribles): Promise<TResult> {
  const response = await fetch(getTutorials, {
    method: 'GET',
    headers: {
      Authorization: `${tokenType} ${token}`,
    },
  });
  return response.json();
}

type TResult = GetTutorials;
type TError = { message: string };
type TVaribles = {
  token: string;
  tokenType: string;
};

function useGetTutorials(): UseQueryResult<TResult, TError> {
  const { token, tokenType } = useAuth();
  return useQuery(
    'get_tutorials',
    async () => {
      const result = await fetchTutorials({
        token,
        tokenType,
      });
      return result;
    },
    {
      staleTime: 60 * 1000,
      refetchOnWindowFocus: false,
    },
  );
}

export { useGetTutorials };
