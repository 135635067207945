import React, { ReactElement, useMemo } from 'react';
import { ResponseType } from 'src/features/PersonRepository/types/QuestionsTabType';

export type DateOfBirthCommentsResponseModel = {
  birthdateRaw: string;
  birthdate: Date | undefined;
  age: number;
  comments: string;
};

const dobModel: DateOfBirthCommentsResponseModel = {
  birthdateRaw: '',
  birthdate: undefined,
  age: 0,
  comments: '',
};

function DobCommentsDisplay({
  question,
}: {
  question: ResponseType;
}): ReactElement {
  const dob = useMemo(() => {
    if (question && question.Value !== '') return JSON.parse(question.Value);
    else return dobModel;
  }, [question]);

  return (
    <>
      {dob !== null && (
        <>
          <div>
            <label style={{ fontWeight: 700, marginRight: '8px' }}>
              Birthdate:
            </label>
            <span>{dob?.birthdateRaw}</span>
          </div>
          <div>
            <label style={{ fontWeight: 700, marginRight: '8px' }}>Age:</label>
            <span>{dob?.age}</span>
          </div>
          <div>
            <label style={{ fontWeight: 700, marginRight: '8px' }}>
              Comment:
            </label>
            <span>{dob?.comments}</span>
          </div>
        </>
      )}
    </>
  );
}

export { DobCommentsDisplay };
