import { UseMutationResult, useMutation } from 'react-query';

import { useAuth } from 'src/hooks';

import { getResourceList, removeProvider } from 'src/utils/urls';

import { ResourceList, InputType } from '../types/resourceList';

async function createProvider({
  token,
  tokenType,
  params,
}: TVariables): Promise<any | ResourceList> {
  let url = getResourceList;
  let method = 'POST';

  if (params?.userMode === 'Edit') {
    url = removeProvider(params?.payload?.Provider?.id);
    method = 'PUT';
  }

  const result = await fetch(url, {
    method: method,
    headers: {
      Authorization: `${tokenType} ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(params?.payload),
  });

  //for creating a new provider , returns with 201 status code
  if (result.status === 201) return result.json();
  //for updating an existing provider, return with 204
  else if (result.status === 204) return result;
  else {
    const errorText = await result.json();
    throw errorText;
  }
}
type TVariables = {
  token: string;
  tokenType: string;
  params: PayloadType;
};
type TError = {
  message: string;
};
type PayloadType = {
  payload: InputType;
  userMode: string;
};
function useCreateProvider(): UseMutationResult<
  any,
  TError,
  PayloadType,
  unknown
> {
  const { token, tokenType } = useAuth();

  return useMutation(async (params: PayloadType) => {
    const result = await createProvider({
      token,
      tokenType,
      params,
    });
    return result;
  });
}
export { useCreateProvider };
