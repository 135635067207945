import React, { ReactElement, useCallback, useMemo } from 'react';

import { EmptyState } from 'src/ui/components';

import {
  AddressDisplay,
  ChoiceDisplay,
  DobCommentsDisplay,
  DobDisplay,
  LookupDisplay,
} from './DisplayComponents';

import { restrictedQuestions } from '../../utils/personRepoConstants';

import {
  ResponseType,
  QuestionsDetails,
  Sections,
  SectionsAndQuestions,
} from '../../types/QuestionsTabType';
import { usePersonRepoStore } from '../../store/personRepoStore';

function QuestionsTab({
  // questionDet,
  phoneType,
}: {
  // questionDet: QuestionsDetails | undefined;
  phoneType: Array<{ value: string; label: string }> | undefined;
}): ReactElement {
  const questionDet = usePersonRepoStore(
    useCallback(state => state.question, []),
  );

  // Sections & Questions
  const reportData = useMemo(() => {
    if (questionDet) {
      const { Responses, Survey } = questionDet;
      const optimizedResult = (Survey?.Sections || [])
        .map((section: Sections) => {
          const { Title, Order, Id, Questions } = section;
          const result = {
            Id,
            Title,
            Order,
            Questions: Questions.map(question => {
              const { Value = '', QuestionTemplateId = 0 } =
                Responses?.find(
                  response =>
                    response.QuestionTemplateId === question.QuestionTemplateId,
                ) ?? {};
              return {
                Id: question.Id,
                FieldLabel: question.FieldLabel,
                Order: question.Order,
                Value,
                TemplateId: QuestionTemplateId,
                Type:
                  question.QuestionTemplate?.QuestionType?.Description ?? '',
                Options:
                  question.QuestionTemplate?.QuestionType?.Description ===
                    'Choice' && question?.QuestionOptions.length > 0
                    ? question.QuestionOptions.map(
                        ({ QuestionTemplateOption }) => ({
                          ItemLabel: QuestionTemplateOption.ItemLabel,
                          ItemName: QuestionTemplateOption.ItemName,
                        }),
                      )
                    : question.QuestionTemplate?.QuestionType?.Description ===
                        'Lookup' &&
                      question.QuestionTypeOptions?.MappedLookupColumns &&
                      question.QuestionTypeOptions?.MappedLookupColumns
                        ?.length > 0
                    ? question.QuestionTypeOptions?.MappedLookupColumns
                    : [],
              };
            }),
          };
          return result.Questions.length > 0 ? result : null;
        })
        .filter(Boolean);
      return optimizedResult;
    }
    return [];
  }, [questionDet]);

  function showSection(section: SectionsAndQuestions) {
    let val = 0;
    section?.Questions?.map((item: ResponseType) => {
      if (restrictedQuestions(item)) {
        val = val + 1;
      }
    });
    if (val > 0) return true;
    else return false;
  }

  return (
    <>
      <div className="h-full p-3 lg:p-6">
        {reportData.length > 0 ? (
          <div className="min-w-[450px] space-y-6">
            {reportData.map(
              section =>
                section &&
                showSection(section) && (
                  <dl
                    className="border dark:border-dark-medium"
                    key={section?.Id}
                  >
                    <dt className="sticky -top-px border-b bg-gray-100 px-3 py-2 text-xl font-semibold dark:border-dark-medium dark:bg-neutral-900">
                      {section?.Title}
                    </dt>
                    {section?.Questions.map(
                      (question: ResponseType) =>
                        restrictedQuestions(question) && (
                          <dd
                            className="flex gap-2 border-b dark:border-dark-medium"
                            key={question?.Id}
                          >
                            <h4 className="basis-1/3 px-3 py-2 text-sm font-medium">
                              {question?.FieldLabel}
                            </h4>

                            {question.Type === 'Text' ? (
                              <p className="basis-4/6 whitespace-pre-line px-3 py-2 text-sm">
                                {question.Value}
                              </p>
                            ) : question.Type === 'Choice' ? (
                              <p className="basis-4/6 px-3 py-2 text-sm">
                                <ChoiceDisplay question={question} />
                              </p>
                            ) : question.Type === 'Address' ? (
                              <p className="basis-4/6 px-3 py-2 text-sm">
                                <AddressDisplay question={question} />
                              </p>
                            ) : question.Type === 'Lookup' ? (
                              <p className="basis-4/6 px-3 py-2 text-sm">
                                <LookupDisplay question={question} />
                              </p>
                            ) : question.Type === 'Date' ? (
                              <p className="basis-4/6 px-3 py-2 text-sm">
                                {question.Value}
                              </p>
                            ) : question.Type ===
                              'Date Of Birth With Comment' ? (
                              <p className="basis-4/6 px-3 py-2 text-sm">
                                <DobCommentsDisplay question={question} />
                              </p>
                            ) : question.Type === 'Date Of Birth' ? (
                              <p className="basis-4/6 px-3 py-2 text-sm">
                                <DobDisplay question={question} />
                              </p>
                            ) : (
                              <p className="basis-4/6 px-3 py-2 text-sm"> </p>
                            )}
                          </dd>
                        ),
                    )}
                  </dl>
                ),
            )}
          </div>
        ) : (
          <div className="grid h-full w-full place-content-center">
            <EmptyState message="No information available" />
          </div>
        )}
      </div>
    </>
  );
}

export { QuestionsTab };
