import React, {
  Fragment,
  ReactElement,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import dayjs from 'dayjs';
import { Tab } from '@headlessui/react';

import { EmptyState, Skeleton, Spinner, TooltipItem } from 'src/ui/components';

import { useGetFeedback } from '../api/useGetFeedback';

import { FeedbackType } from '../types/releaseApiTypes';

export function Feedback(): ReactElement {
  const tbodyRef = useRef() as React.MutableRefObject<HTMLTableSectionElement>;
  const {
    data: feedback,
    isLoading: feedbackLoading,
    isFetching,
    hasNextPage,
    fetchNextPage,
  } = useGetFeedback();

  const handleScroll = useCallback(
    (e: any) => {
      if (tbodyRef.current === e.target) {
        const bottom =
          e.target.clientHeight - 10 <
            e.target.scrollHeight - e.target.scrollTop &&
          e.target.scrollHeight - e.target.scrollTop <
            e.target.clientHeight + 10;

        if (bottom) {
          hasNextPage && fetchNextPage();
        }
      }
    },
    [fetchNextPage, hasNextPage],
  );

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, true);
    return () => {
      window.removeEventListener('scroll', handleScroll, true);
    };
  }, [handleScroll]);

  return (
    <Tab.Panel className="flex min-h-0 w-full flex-col">
      <h2 className="hidden shrink-0 truncate px-10 pt-3 text-lg font-semibold text-gray-950 dark:text-white sm:tracking-tight lg:block">
        Feedback
      </h2>
      {feedbackLoading && (
        <div className="fixed inset-0 z-20 grid place-content-center">
          <Spinner size="large" />
        </div>
      )}

      <div className="min-h-0 grow px-5 py-5 lg:px-10">
        <div className="h-full min-h-0 overflow-auto" ref={tbodyRef}>
          {feedback && feedback?.pages && !feedbackLoading ? (
            <table className="w-full min-w-[800px] max-w-full table-fixed divide-y-2 divide-gray-200 overflow-auto overflow-x-auto bg-white text-sm dark:divide-neutral-700 dark:bg-neutral-800">
              <thead className="sticky top-0 z-[1] rounded-t-lg border-b border-gray-200 bg-gray-100 dark:border-neutral-600 dark:bg-neutral-900">
                <tr>
                  <th className="whitespace-nowrap px-4 py-4 text-start font-medium text-gray-900 dark:text-white">
                    User
                  </th>
                  <th className="whitespace-nowrap px-4 py-4 text-start font-medium text-gray-900 dark:text-white">
                    Account
                  </th>
                  <th className="whitespace-nowrap px-4 py-4 text-start font-medium text-gray-900 dark:text-white">
                    Date
                  </th>
                  <th className="whitespace-nowrap px-4 py-4 text-start font-medium text-gray-900 dark:text-white">
                    Response
                  </th>
                </tr>
              </thead>
              <tbody className="relative divide-y  divide-gray-200 dark:divide-neutral-700">
                {!feedbackLoading && feedback?.pages ? (
                  <>
                    {feedback?.pages.map((page: any, index: number) => (
                      <Fragment key={`${page} - ${index + 1}`}>
                        {page?.data && page?.data?.length ? (
                          page?.data.map((item: FeedbackType, indx: number) => (
                            <tr
                              key={item?.Id}
                              className="group odd:bg-gray-50 dark:odd:bg-neutral-800"
                            >
                              <td className="px-4 py-4 font-medium text-gray-900 group-last:rounded-bl-lg dark:text-neutral-200">
                                <p>{item?.Name}</p>
                                <span className="prevent-text-breakout select-all text-xs font-normal text-gray-600 dark:text-neutral-400">
                                  {item?.Email}
                                </span>
                              </td>
                              <td className="whitespace-nowrap px-4 py-4 text-gray-700 dark:text-neutral-400">
                                <TooltipItem
                                  label="CID"
                                  values={item?.CID?.join(', ')}
                                />
                              </td>
                              <td className="whitespace-nowrap px-4 py-4 text-gray-700 dark:text-neutral-400">
                                {dayjs(item?.CreatedAt).format('MM/DD/YYYY')}
                              </td>
                              <td className="max-w-80 px-4 py-4 text-gray-700 group-last:rounded-br-lg dark:text-neutral-400">
                                {item?.Feedback}
                              </td>
                            </tr>
                          ))
                        ) : (
                          <div className="min-h-72">
                            <div className=" absolute inset-0 grid place-content-center">
                              <EmptyState message="No information available" />
                            </div>
                          </div>
                        )}
                      </Fragment>
                    ))}
                  </>
                ) : (
                  (feedbackLoading || isFetching) && <Skeleton count={8} />
                )}
              </tbody>
            </table>
          ) : (
            !feedbackLoading && (
              <div className="absolute inset-0 grid place-content-center">
                <EmptyState message="No information available" />
              </div>
            )
          )}
        </div>
      </div>
    </Tab.Panel>
  );
}
