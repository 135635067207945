import { useMutation, UseMutationResult } from 'react-query';

import { useAuth } from 'src/hooks';

import { submitScheduledCall } from 'src/utils/urls';

import { SubmitCallType } from '../types/scheduleCallType';

async function submitCall({
  token,
  tokenType,
  params,
}: TArguments): Promise<TResult> {
  const response = await fetch(submitScheduledCall, {
    method: 'POST',
    headers: {
      Authorization: `${tokenType} ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(params),
  });
  if (response.status === 200) return 'Success';
  else return response.json();
}

type TResult = any;

export type TError = { Message: string };

export type TVariables = {
  params: SubmitCallType;
};

export type TArguments = {
  token: string;
  tokenType: string;
  params: SubmitCallType;
};

function useSubmitScheduledCall(): UseMutationResult<
  TResult,
  TError,
  SubmitCallType,
  unknown
> {
  const { token, tokenType } = useAuth();
  return useMutation(async (params: SubmitCallType) => {
    const result = await submitCall({
      token,
      tokenType,
      params: params,
    });
    if (result === 'Success') return result;
    else throw result;
  });
}
export { useSubmitScheduledCall };
