import { useMutation, UseMutationResult } from 'react-query';

import { verifyQrCode } from 'src/utils/urls';
import { useProgressStore } from './useProgressStore';

export type TResult = boolean;
export type TError = { message: string };
export type TVariable = {
  userName: string | undefined;
  googleAuthCode: string | null;
};

async function verifyGoogleAuthCode({
  userName,
  googleAuthCode,
}: TVariable): Promise<TResult> {
  const response = await fetch(verifyQrCode(), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ UserName: userName, code: googleAuthCode }),
  });
  return response.json();
}

function useVerifyGoogleAuth(): UseMutationResult<
  TResult,
  TError,
  TVariable,
  unknown
> {
  const { setLoading } = useProgressStore();
  return useMutation(
    async ({ userName, googleAuthCode }: TVariable) => {
      setLoading('isVerifyAuthLoading', true);
      const result = await verifyGoogleAuthCode({
        userName,
        googleAuthCode,
      });
      return result;
    },
    {
      onSettled: () => {
        setLoading('isVerifyAuthLoading', false);
      },
    },
  );
}

export { useVerifyGoogleAuth };
