/* eslint-disable react/jsx-key */
import React, {
  ReactElement,
  useEffect,
  useCallback,
  useRef,
  AllHTMLAttributes,
} from 'react';
import { useTable } from 'react-table';
import clsx from 'clsx';

import { EmptyState, Skeleton, Spinner } from 'src/ui/components';

type NativeProps = AllHTMLAttributes<HTMLElement>;

type SortItemType = {
  sortKey: string;
  sortOrder: string;
};

function ReactTable({
  skeletonCount = 5,
  columns,
  data = [],
  isLoading,
  headerUpdateCallBack,
  headerMultiSelection,
  handleSortChange,
  columnsSearch,
  multiSelection,
  onEndReached,
  sortedItem,
  onRowClick,
  selectedRowId,
  isFetchingNextPage,
  onRowDoubleClick,
  className,
  selectedRowIds,
  rowIndex,
}: {
  skeletonCount?: number;
  columns: Array<any>;
  data?: Array<any>;
  isLoading?: boolean;
  headerUpdateCallBack?: ({
    columnId,
    value,
  }: {
    columnId: string;
    value: string;
  }) => void;
  headerMultiSelection?: ({
    columnId,
    value,
  }: {
    columnId: string;
    value: Array<string>;
  }) => void;
  handleSortChange?: (item: string) => void;
  columnsSearch?: Record<string, string> | undefined;
  multiSelection?: Record<string, Array<string>> | undefined;
  onEndReached?: () => void;
  height?: string;
  style?: NativeProps['style'];
  sortedItem?: SortItemType | Array<SortItemType>;
  onRowClick?: ((row: any, e?: any) => void) | undefined;
  selectedRowId?: number | string;
  isFetchingNextPage?: boolean;
  onRowDoubleClick?: (row: any) => void;
  selectedRowIds?: Array<number | string>;
  className?: string;
  clickableRow?: boolean;
  rowIndex?: number;
}): ReactElement {
  // Use the state and functions returned from useTable to build your UI

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
      // @ts-ignore
      headerUpdateCallBack,
      headerMultiSelection,
      handleSortChange,
      columnsSearch,
      multiSelection,
      sortedItem,
      selectedRowId,
    });

  const tbodyRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  const handleScroll = useCallback(
    (e: any) => {
      if (tbodyRef.current === e.target) {
        const bottom =
          e.target.clientHeight - 10 <
            e.target.scrollHeight - e.target.scrollTop &&
          e.target.scrollHeight - e.target.scrollTop <
            e.target.clientHeight + 10;
        if (bottom) {
          onEndReached && onEndReached();
        }
      }
    },
    [onEndReached, tbodyRef],
  );

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, true);
    return () => {
      window.removeEventListener('scroll', handleScroll, true);
    };
  }, [handleScroll]);

  //for setting the scroll position in to particular element
  useEffect(() => {
    if (rowIndex && rowIndex !== null) {
      const selectedRow = document.getElementById(`row-${rowIndex}`);
      if (selectedRow) {
        selectedRow.scrollIntoView({ behavior: 'auto', block: 'nearest' });
      }
    }
  }, [rowIndex]);

  const userClasses = clsx(className);

  // Render the UI for your table
  return (
    <div ref={tbodyRef} className={className ? className : ''}>
      <table {...getTableProps()} className={`w-full ${userClasses}`}>
        <thead className="sticky top-0 z-[1] border-b border-gray-200 bg-gray-100 dark:border-neutral-600 dark:bg-neutral-900">
          {headerGroups.map((headerGroup, i) => {
            return (
              <tr
                {...headerGroup.getHeaderGroupProps()}
                id={`headerGroup-${i}`}
              >
                {headerGroup.headers.map((column, i) => (
                  <th
                    {...column.getHeaderProps()}
                    className="whitespace-nowrap px-4 pb-2 pt-4 text-start text-gray-900 dark:text-white"
                    id={`column-${i}`}
                  >
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            );
          })}
        </thead>
        {!isLoading && (
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <>
                  <tr
                    {...row.getRowProps()}
                    id={`row-${i}`}
                    onDoubleClick={() => {
                      onRowDoubleClick && onRowDoubleClick(row);
                    }}
                    onClick={e => {
                      onRowClick && onRowClick(row, e);
                    }}
                    className={`relative border-t border-gray-200 hover:bg-gray-100 dark:border-neutral-700 dark:hover:bg-neutral-600 ${
                      (selectedRowId && row.original.Id === selectedRowId) ||
                      (selectedRowId && row.id === selectedRowId) || //condition when there is no unique id in "original" (for tracker part)
                      selectedRowIds?.includes(row.id) ||
                      selectedRowIds?.includes(row.original.Id) ||
                      selectedRowIds?.includes(row.original.AccountId)
                        ? 'h-px !border-primary !bg-primary-100 last:border-b dark:!border-primary-800 dark:!bg-primary-950/70 [&+tr]:border-primary dark:[&+tr]:border-primary-800 [&>*]:font-bold'
                        : row?.original.IsEnabled === true
                        ? 'h-px hover:bg-transparent [&>*]:opacity-40'
                        : ''
                    }`}
                  >
                    {row.cells.map((cell: any, indx: number) => {
                      return (
                        <td
                          className="px-4 py-2 text-light-darkest dark:text-neutral-200"
                          {...cell.getCellProps()}
                        >
                          <span
                            data-text={
                              typeof cell?.value !== 'object' ? cell?.value : ''
                            }
                            className="inline-flex flex-col items-center justify-between after:pointer-events-none after:invisible after:inline-block after:h-0 after:select-none after:overflow-hidden "
                          >
                            {cell.render('Cell')}
                          </span>
                        </td>
                      );
                    })}
                  </tr>
                </>
              );
            })}

            {isFetchingNextPage && (
              <div className="fixed inset-0 z-20 grid place-content-center">
                <Spinner size="large" />
              </div>
            )}
          </tbody>
        )}
      </table>

      {/* Empty state */}
      {!isLoading && !isFetchingNextPage && data && data.length <= 0 ? (
        <div className="p-3">
          <EmptyState message="No data available" />
        </div>
      ) : null}

      {!isFetchingNextPage && isLoading && <Skeleton count={skeletonCount} />}
    </div>
  );
}

export { ReactTable };
