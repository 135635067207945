import { UseMutationResult, useMutation } from 'react-query';

import { useAuth } from './useAuth';

import { getUserEmailNotification } from 'src/utils/urls';

import { UserEmailNotificationType } from 'src/types/emailNotification';

async function updateEmail({
  token,
  tokenType,
  params,
}: TVariables): Promise<any> {
  const result = await fetch(getUserEmailNotification(params?.id), {
    method: 'PUT',
    headers: {
      Authorization: `${tokenType} ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(params?.payload),
  });
  return result?.json();
}

type TParamType = {
  id: number | string;
  payload: UserEmailNotificationType;
};
type TVariables = {
  token: string;
  tokenType: string;
  params: TParamType;
};

type TError = {
  message: string;
};
function useUpdateUserEmailNotificationSettings(): UseMutationResult<
  void,
  TError,
  TParamType,
  unknown
> {
  const { token, tokenType } = useAuth();

  return useMutation(async (params: TParamType) => {
    updateEmail({
      token,
      tokenType,
      params,
    });
  });
}
export { useUpdateUserEmailNotificationSettings };
