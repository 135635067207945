import { useInfiniteQuery, UseInfiniteQueryResult } from 'react-query';
import pDebounce from 'p-debounce';

import { useAuth } from 'src/hooks';

import { getPersonAlerts } from 'src/utils/urls';
import { pageLength } from 'src/utils/appConstants';
import { formatBirthDate } from '../utils/formatter';
import { trimValues } from 'src/utils/common';

import {
  PersonAlertListType,
  AlertFilterOptionType,
} from '../types/personAlerts';

export async function fetchGetPersonAlertList({
  requestData,
  pageParam,
  token,
  tokenType,
}: TVariables): Promise<TPage> {
  requestData.append('start', `${pageParam}`);

  const response = await fetch(getPersonAlerts, {
    method: 'POST',
    headers: {
      Authorization: `${tokenType} ${token}`,
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: requestData,
  });
  return response.json();
}

const debouncedFetch = pDebounce(fetchGetPersonAlertList, 500);

export type TPage = {
  data: Array<PersonAlertListType>;
  draw: number;
  error: Error | null;
  isAutoRefreshEnabled: boolean;
  recordsFiltered: number;
  recordsTotal: number;
};

export type TError = { message: string };

export type TVariables = {
  requestData: any;
  token: string;
  tokenType: string;
  pageParam: any;
};

function setRequestData(filterOptions: AlertFilterOptionType): FormData {
  const formData: any = new URLSearchParams();
  formData.append('draw', '0');
  formData.append('length', `${100}`);
  formData.append('timezoneOffset', `${new Date().getTimezoneOffset()}`);
  formData.append('columns[0][data]', 'Status');
  formData.append('columns[0][name]', '');
  formData.append('columns[0][searchable]', 'true');
  formData.append('columns[0][orderable]', 'true');
  formData.append('columns[0][search][regex]', 'false');
  formData.append(
    'columns[0][search][value]',
    filterOptions && trimValues(filterOptions.status),
  );

  formData.append('columns[1][data]', 'Person.Account.Id');
  formData.append('columns[1][name]', '');
  formData.append('columns[1][searchable]', 'true');
  formData.append('columns[1][orderable]', 'true');
  formData.append('columns[1][search][regex]', 'false');
  formData.append(
    'columns[1][search][value]',
    filterOptions && filterOptions.accountId
      ? trimValues(filterOptions.accountId)
      : '',
  );

  formData.append('columns[2][data]', 'StartDate');
  formData.append('columns[2][name]', '');
  formData.append('columns[2][searchable]', 'true');
  formData.append('columns[2][orderable]', 'true');
  formData.append('columns[2][search][regex]', 'false');
  formData.append(
    'columns[2][search][value]',
    filterOptions && filterOptions.startDate
      ? trimValues(filterOptions.startDate)
      : '',
  );

  formData.append('columns[3][data]', 'EndDate');
  formData.append('columns[3][name]', '');
  formData.append('columns[3][searchable]', 'true');
  formData.append('columns[3][orderable]', 'true');
  formData.append('columns[3][search][regex]', 'false');
  formData.append(
    'columns[3][search][value]',
    filterOptions && filterOptions.expirationDate
      ? trimValues(filterOptions.expirationDate)
      : '',
  );

  formData.append('columns[4][data]', 'EnteredDate');
  formData.append('columns[4][name]', '');
  formData.append('columns[4][searchable]', 'true');
  formData.append('columns[4][orderable]', 'true');
  formData.append('columns[4][search][regex]', 'false');
  formData.append(
    'columns[4][search][value]',
    filterOptions && filterOptions.enteredDate
      ? trimValues(filterOptions.enteredDate)
      : '',
  );

  formData.append('columns[5][data]', 'Person.Account.CID');
  formData.append('columns[5][name]', '');
  formData.append('columns[5][searchable]', 'true');
  formData.append('columns[5][orderable]', 'true');
  formData.append('columns[5][search][regex]', 'false');
  formData.append(
    'columns[5][search][value]',
    filterOptions && filterOptions.cid ? trimValues(filterOptions.cid) : '',
  );

  formData.append('columns[6][data]', 'Person.Account.PID');
  formData.append('columns[6][name]', '');
  formData.append('columns[6][searchable]', 'true');
  formData.append('columns[6][orderable]', 'true');
  formData.append('columns[6][search][regex]', 'false');
  formData.append(
    'columns[6][search][value]',
    filterOptions && filterOptions.pid ? trimValues(filterOptions.pid) : '',
  );
  formData.append('columns[7][data]', 'Person.FirstName');
  formData.append('columns[7][name]', '');
  formData.append('columns[7][searchable]', 'true');
  formData.append('columns[7][orderable]', 'true');
  formData.append('columns[7][search][regex]', 'false');
  formData.append(
    'columns[7][search][value]',
    filterOptions && filterOptions.firstName
      ? trimValues(filterOptions.firstName)
      : '',
  );

  formData.append('columns[8][data]', 'Person.LastName');
  formData.append('columns[8][name]', '');
  formData.append('columns[8][searchable]', 'true');
  formData.append('columns[8][orderable]', 'true');
  formData.append('columns[8][search][regex]', 'false');
  formData.append(
    'columns[8][search][value]',
    filterOptions && filterOptions.lastName
      ? trimValues(filterOptions.lastName)
      : '',
  );

  formData.append('columns[9][data]', 'Person.PreferredName');
  formData.append('columns[9][name]', '');
  formData.append('columns[9][searchable]', 'true');
  formData.append('columns[9][orderable]', 'true');
  formData.append('columns[9][search][regex]', 'false');
  formData.append(
    'columns[9][search][value]',
    filterOptions && filterOptions.preferredName
      ? trimValues(filterOptions.preferredName)
      : '',
  );

  formData.append('columns[10][data]', 'Person.PersonAlertDetails.Birthdate');
  formData.append('columns[10][name]', '');
  formData.append('columns[10][searchable]', 'true');
  formData.append('columns[10][orderable]', 'true');
  formData.append('columns[10][search][regex]', 'false');
  formData.append(
    'columns[10][search][value]',
    filterOptions && filterOptions.birthDate
      ? formatBirthDate(filterOptions.birthDate)
      : '',
  );

  formData.append('columns[11][data]', 'SituationDescription');
  formData.append('columns[11][name]', '');
  formData.append('columns[11][searchable]', 'true');
  formData.append('columns[11][orderable]', 'true');
  formData.append('columns[11][search][regex]', 'false');
  formData.append(
    'columns[11][search][value]',
    filterOptions && filterOptions.situationDescription
      ? trimValues(filterOptions.situationDescription)
      : '',
  );

  formData.append('columns[12][data]', 'Id');
  formData.append('columns[12][name]', '');
  formData.append('columns[12][searchable]', 'true');
  formData.append('columns[12][orderable]', 'true');
  formData.append('columns[12][search][regex]', 'false');
  formData.append(
    'columns[12][search][value]',
    filterOptions?.alertNumber ? trimValues(filterOptions?.alertNumber) : '',
  );

  formData.append('columns[13][data]', 'UpdatedBy');
  formData.append('columns[13][name]', '');
  formData.append('columns[13][searchable]', 'true');
  formData.append('columns[13][orderable]', 'true');
  formData.append('columns[13][search][regex]', 'false');
  formData.append(
    'columns[13][search][value]',
    filterOptions?.updatedBy ? trimValues(filterOptions?.updatedBy) : '',
  );

  formData.append('columns[14][data]', 'UpdatedDate');
  formData.append('columns[14][name]', '');
  formData.append('columns[14][searchable]', 'true');
  formData.append('columns[14][orderable]', 'true');
  formData.append('columns[14][search][regex]', 'false');
  formData.append(
    'columns[14][search][value]',
    filterOptions?.updatedDate ? trimValues(filterOptions?.updatedDate) : '',
  );

  formData.append('order[0][column]', filterOptions.sortType);
  formData.append('order[0][dir]', filterOptions.sortOrder);

  formData.append(
    'search[value]',
    `${trimValues(filterOptions.keywordSearch)}`,
  );
  formData.append('search[regex]', 'false');

  return formData;
}

function useGetPersonAlerts(
  filterOptions: AlertFilterOptionType,
): UseInfiniteQueryResult<TPage, unknown> {
  const { token, tokenType } = useAuth();
  return useInfiniteQuery(
    ['get_person_alerts_list', filterOptions],
    ({ pageParam = 0 }) => {
      const requestData = setRequestData(filterOptions);

      return debouncedFetch({
        token,
        tokenType,
        requestData,
        pageParam,
      });
    },
    {
      getNextPageParam: (lastPage: TPage, allPages: Array<TPage>) => {
        if (lastPage !== null && lastPage?.data?.length === pageLength)
          return allPages?.length * pageLength;
      },
      getPreviousPageParam: (firstPage: TPage, allPages: Array<TPage>) => {
        if (firstPage !== null && firstPage?.data?.length === pageLength)
          return allPages?.length - 1 * pageLength;
      },

      staleTime: 60 * 1000,
      refetchOnWindowFocus: false,
    },
  );
}

export { useGetPersonAlerts };
