import { useQuery, UseQueryResult } from 'react-query';

import { useAuth } from 'src/hooks';

import { getPhoneType } from 'src/utils/urls';

async function fetchPhoneType({
  token,
  tokenType,
}: TVariables): Promise<APIResult> {
  const response = await fetch(getPhoneType, {
    method: 'GET',
    headers: {
      Authorization: `${tokenType} ${token}`,
    },
  });
  return response.json();
}

export type APIResult = Array<{ Description: string; Id: number }>;

type TError = { message: string };
type TVariables = {
  token: string;
  tokenType: string;
};

function useGetPhoneType(): UseQueryResult<APIResult, TError> {
  const { token, tokenType } = useAuth();
  return useQuery(
    ['get_phone_type'],
    async () => {
      const result = await fetchPhoneType({
        token,
        tokenType,
      });
      return result;
    },
    {
      staleTime: Infinity,
      refetchOnWindowFocus: false,
    },
  );
}

export { useGetPhoneType };
