import React, { useMemo } from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { ChartOptions } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

type DoughnutChartProps = {
  labels: Array<string>;
  values: Array<number>;
};

type CustomDoughnutOptions = ChartOptions<'doughnut'> & {
  plugins: {
    legend: { display: boolean };
    tooltip: {
      callbacks: { label: () => string };
    };
  };
};

function DoughnutChart({ labels, values }: DoughnutChartProps) {
  const style = getComputedStyle(document.documentElement);
  const primaryColor = style.getPropertyValue('--color-brand-500');
  const secondaryColor = style.getPropertyValue('--color-brand-200');

  const isNoValuesToPlot = useMemo(() => {
    return values.every(x => x === 0);
  }, [values]);

  const dataToPlot = {
    labels: isNoValuesToPlot ? [''] : labels,
    datasets: [
      {
        data: isNoValuesToPlot ? [100] : values,
        backgroundColor: isNoValuesToPlot
          ? ['	#D8D8D8']
          : [`hsl(${secondaryColor})`, `hsl(${primaryColor})`],
      },
    ],
  };

  const options: ChartOptions<'doughnut'> = {
    radius: '80%',
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  const customOptions: CustomDoughnutOptions = {
    radius: '80%',
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function () {
            return ' No data found.';
          },
        },
      },
    },
  };

  return (
    <Doughnut
      height="100%"
      width="100%"
      data={dataToPlot}
      options={isNoValuesToPlot ? customOptions : options}
    />
  );
}

export { DoughnutChart };
