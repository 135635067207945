import { useQuery, UseQueryResult } from 'react-query';

import { useAuth } from 'src/hooks';

import { getStateProvince } from 'src/utils/urls';

import { StateProvinceType } from 'src/types/AccountInfo';

async function fetchStateProvince({
  token,
  tokenType,
}: TVaribles): Promise<TResult> {
  const response = await fetch(getStateProvince, {
    method: 'GET',
    headers: {
      Authorization: `${tokenType} ${token}`,
    },
  });
  return response.json();
}

type TVaribles = {
  token: string;
  tokenType: string;
};

type TResult = Array<StateProvinceType>;

type TError = { message: string };

function useGetStateProvince(): UseQueryResult<TResult | TError> {
  const { token, tokenType } = useAuth();

  return useQuery(
    ['get_state_province'],
    async () => {
      const result = fetchStateProvince({ token, tokenType });
      return result;
    },
    {
      staleTime: 60 * 1000,
      refetchOnWindowFocus: false,
    },
  );
}

export { useGetStateProvince };
