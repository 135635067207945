import React, { ReactElement } from 'react';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';

import { Badge } from 'src/ui/components';

import { formatDataSyncStatus } from '../utils/resourceHelper';
import { activeStyles } from 'src/utils/appConstants';

import { ResourceListType } from '../types/resourceList';

function ListItem({
  item,
  setSelectedProvider,
  selectedProvider,
}: {
  item: ResourceListType;
  setSelectedProvider: (
    value:
      | ResourceListType
      | undefined
      | ((prevState: ResourceListType | undefined) => ResourceListType),
  ) => void;
  selectedProvider: ResourceListType | undefined;
}): ReactElement {
  const navigate = useNavigate();
  return (
    <>
      <div
        tabIndex={0}
        className={`relative flex flex-wrap gap-10 rounded bg-white p-2 shadow focus-visible:outline focus-visible:outline-[3px] focus-visible:outline-offset-[3px] focus-visible:outline-blue-600 dark:bg-neutral-800 dark:focus-visible:outline-blue-400 lg:gap-x-5 ${
          selectedProvider?.Id === item?.Id ? activeStyles : ''
        }`}
        onClick={() => {
          if (selectedProvider?.Id !== item?.Id) setSelectedProvider(item);
          else setSelectedProvider(undefined);
        }}
        onDoubleClick={() => {
          navigate(`/Resources/EditProvider/${item?.Id}`);
        }}
      >
        <div className="flex basis-[40%] flex-col gap-5 md:basis-[30%] lg:basis-1/4">
          <div>
            <label className="block whitespace-nowrap text-[10px] font-medium uppercase tracking-wide opacity-70">
              Provider name
            </label>
            <p className="prevent-text-breakout break-words text-xs font-bold tracking-wide text-black dark:text-white">
              {item.ProviderName}
            </p>
          </div>
        </div>
        <div className="flex basis-[40%] flex-col gap-5 md:basis-[30%] lg:basis-1/4">
          <div>
            <label className="block whitespace-nowrap text-[10px] font-medium uppercase tracking-wide opacity-70">
              Endpoint
            </label>
            <p className="prevent-text-breakout break-words text-xs font-bold tracking-wide text-black dark:text-white">
              {item.EndPoint}
            </p>
          </div>
        </div>

        <div className="flex basis-[40%] flex-col gap-5 md:basis-[30%] lg:basis-1/5">
          <div>
            <label className="block whitespace-nowrap text-[10px] font-medium uppercase tracking-wide opacity-70">
              Last updated on
            </label>
            <p className="prevent-text-breakout break-words text-xs font-bold tracking-wide text-black dark:text-white">
              {dayjs(item.LastUpdatedOn).format('MM/DD/YYYY H:mm:ss')}
            </p>
          </div>
        </div>
        <div className="flex basis-[40%] flex-col gap-5 md:basis-[30%] lg:basis-1/5">
          <div>
            <label className="block whitespace-nowrap text-[10px] font-medium uppercase tracking-wide opacity-70">
              Data sync
            </label>
            <Badge variant="success">
              {formatDataSyncStatus(item.DataSync)}
            </Badge>
          </div>
        </div>
      </div>
    </>
  );
}

export { ListItem };
