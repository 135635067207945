import React, { useEffect, Fragment, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Transition, Dialog } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/20/solid';

import { Spinner, Button } from 'src/ui/components';

import { AuthServiceSingleton } from 'src/utils/authenticationHelper/authService';
import { fetchUtcTime } from 'src/utils/getUtctime';
import { systemClockMsg } from 'src/utils/appConstants';

export const Callback = () => {
  const navigate = useNavigate();
  const [tagUpdateModal, setTagUpdateModal] = useState(false);

  useEffect(() => {
    async function checkLogin() {
      try {
        const user = await AuthServiceSingleton.getInstance().getUser();
        localStorage.setItem('AUTH_TOKEN', JSON.stringify(user));
        localStorage.setItem(
          'SpecifiedTwoFacterAuthMethod',
          user?.profile?.SpecifiedTwoFacterAuthMethod,
        );
        const lastKnownLocation = localStorage.getItem('LastKnownLocation');
        navigate(lastKnownLocation ?? '/');
      } catch (e) {
        const currentTime = await fetchUtcTime();
        if (new Date(currentTime) !== new Date()) {
          setTagUpdateModal(true);
        }
      }
    }
    checkLogin();
  }, [navigate]);

  function renderAlert() {
    return (
      <Transition appear show={tagUpdateModal} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => setTagUpdateModal(false)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="relative w-full max-w-md transform overflow-hidden rounded-2xl bg-white text-left align-middle shadow-xl transition-all dark:bg-neutral-800">
                  <Dialog.Title
                    as="h3"
                    className="pl-4 pr-16 pt-4 font-medium leading-6 text-gray-900 dark:text-white"
                  >
                    {systemClockMsg}
                  </Dialog.Title>
                  <button
                    type="button"
                    className="absolute right-4 top-4 text-gray-400 hover:text-gray-500 sm:right-4 sm:top-4"
                    onClick={() => setTagUpdateModal(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>

                  <div className="flex gap-[1ch] bg-gray-50 p-2 px-2 py-4 dark:bg-neutral-700/50 sm:justify-end sm:p-3">
                    <Button
                      variant="primary"
                      onClick={() => {
                        window.localStorage.clear();
                        AuthServiceSingleton.getInstance().logout();
                      }}
                      className="grow sm:grow-0"
                    >
                      Ok
                    </Button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    );
  }

  return (
    <>
      {tagUpdateModal ? (
        renderAlert()
      ) : (
        <div className="fixed inset-0 z-20 grid place-content-center">
          <Spinner size="large" />
        </div>
      )}
    </>
  );
};
