const initialState = {
  Title: '',
  Message: '',
  EnteredDate: '',
  ExpirationDate: '',
  EffectiveDate: '',
  Id: 0,
};

const successMsg = 'The Portal alert was successfully created.';

const updateMsg = 'Your changes were successfully saved.';

export { initialState, updateMsg, successMsg };
