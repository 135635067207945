import React from 'react';

export function useTitle(title: string) {
  React.useEffect(() => {
    const prevTitle = document.title;
    document.title = `${title} - ${
      process.env.REACT_APP_THEME === 'proteus'
        ? 'Protocall Customer Portal'
        : 'Linesforlife Customer Portal'
    }`;

    return () => {
      document.title = prevTitle;
    };
  }, [title]);
}
