const sortOptions: Array<string> = [
  'Title',
  'Message',
  'EffectiveDate',
  'ExpirationDate',
];

const options = [
  { id: 0, label: 'Title', value: 'Title' },
  { id: 1, label: 'Message', value: 'Message' },
  { id: 2, label: 'Effective date', value: 'EffectiveDate' },
  { id: 3, label: 'Expiration date', value: 'ExpirationDate' },
];

const activeStyles =
  "after:pointer-events-none after:absolute after:inset-0 after:rounded after:border-2 after:border-primary-500 after:content-['']  md:after:border-3";

export { sortOptions, options, activeStyles };
