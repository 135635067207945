import React, { HTMLAttributes, forwardRef } from 'react';
import clsx, { ClassValue } from 'clsx';

type BadgeProps = HTMLAttributes<HTMLSpanElement> & {
  variant?: 'default' | 'warning' | 'success' | 'info' | 'danger';
  className?: ClassValue;
};

const Badge = forwardRef<HTMLSpanElement, BadgeProps>(
  ({ variant = 'default', children, className, ...rest }, ref) => {
    const variantClasses = {
      default:
        'bg-gray-50 text-gray-600 ring-gray-500/10  dark:bg-neutral-600/50 dark:text-neutral-300',
      warning:
        'bg-yellow-50 text-yellow-800 ring-yellow-600/20  dark:bg-yellow-800/50 dark:text-yellow-200',
      success:
        'bg-green-50 text-green-700 ring-green-600/20  dark:bg-green-800/50 dark:text-green-200',
      info: 'bg-blue-50 text-blue-700 ring-blue-600/10  dark:bg-blue-900/60 dark:text-blue-200',
      danger:
        'bg-red-50 text-red-700 ring-red-600/10 dark:bg-red-800/50 dark:text-red-200',
    };

    const baseClasses =
      'inline-flex items-center rounded-md px-2 py-0.5 text-xs font-medium ring-1 ring-inset ';

    const variantClass = variantClasses[variant] || variantClasses.default;

    const userClasses = clsx(className);

    return (
      <span
        className={`${baseClasses} ${variantClass} ${userClasses}`}
        ref={ref}
        {...rest}
      >
        {children}
      </span>
    );
  },
);
Badge.displayName = 'Badge';

export { Badge };
