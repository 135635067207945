import React, {
  ReactElement,
  useMemo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Disclosure } from '@headlessui/react';
import * as Tooltip from '@radix-ui/react-tooltip';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { Editor } from '@tinymce/tinymce-react';

import {
  Checkbox,
  Button,
  RadioButton,
  Spinner,
  inputLabelClasses,
  Alert,
} from 'src/ui/components';
import { PreviewAccountAlert } from './PreviewAccountAlert';
import { TinymceEditor } from 'src/ui/components/TinymceEditor';
import { SendApprovalEmail } from './SendApprovalEmail';

import { applicationThemeStore } from 'src/store/applicationThemeStore';
import { useExtracoverageRequestStore } from '../store/extracoverageStore';
import { useGetStoredPermissions } from 'src/hooks';
import { useCreateExtraCoverageRequest, useGetDefaultEmail } from '../api';

import {
  disabledReason,
  formatDateToRange,
  getDuration,
  formatCoverageDetailsPayload,
} from '../utils/coverageRequestHelper';
import {
  approveStatusId,
  coverageProcedureInfoMsg,
} from '../utils/coverageRequestConstants';
import { editorContent } from '../utils/storeHelper';
import { classNames } from 'src/utils/className';
import { tinymceTextAreaConfig } from 'src/utils/appConstants';

import { RequestStore } from '../types/requestStoreTypes';
import { Timezone } from 'src/types/timezone';

function RequestDetails({
  accountData,
  timeZone,
  isApproveMode,
}: {
  accountData: any;
  timeZone: any;
  isApproveMode: boolean;
}): ReactElement {
  const coverageReasonRef = useRef<any>();
  const additionalCommentRef = useRef<any>();
  const procedureRef = useRef<any>();
  const permission = useGetStoredPermissions();

  const { mutate: onSaveCoverageRequest, isLoading: isRequestSaving } =
    useCreateExtraCoverageRequest();

  const coverageRequestDet = useExtracoverageRequestStore(
    useCallback(state => state.extraCoverageRequestDet, []),
  );

  const updateCoverageRequest = useExtracoverageRequestStore(
    useCallback(state => state.updateExtraCoverageReqDet, []),
  );

  const userMode = useExtracoverageRequestStore(
    useCallback(state => state.userMode, []),
  );

  const postAlertOnSave = useExtracoverageRequestStore(
    useCallback(state => state.postAlertOnSave, []),
  );

  const sendEmailOnSave = useExtracoverageRequestStore(
    useCallback(state => state.sendEmailOnSave, []),
  );

  const isSaveClicked = useExtracoverageRequestStore(
    React.useCallback(state => state.isSaveClicked, []),
  );

  const isAllRequiredFieldsFilled = useExtracoverageRequestStore(
    React.useCallback(state => state.isAllRequiredFieldsFilled, []),
  );

  const isAlertPreviewModal = useExtracoverageRequestStore(
    React.useCallback(state => state.isAlertPreviewModal, []),
  );

  const updateStoreByKey = useExtracoverageRequestStore(
    useCallback(state => state.updateStoreByKey, []),
  );

  const isShowSendApproval = useExtracoverageRequestStore(
    useCallback(state => state.isShowSendApproval, []),
  );

  const showError = useExtracoverageRequestStore(
    useCallback(state => state.showError, []),
  );

  const previousPostAccountAlert = useExtracoverageRequestStore(
    useCallback(state => state.previousPostAccountAlert, []),
  );

  const previousSendEmail = useExtracoverageRequestStore(
    useCallback(state => state.previousSendEmail, []),
  );

  const colourScheme = applicationThemeStore(
    React.useCallback(state => state.colourScheme, []),
  );

  const errorMsg = useExtracoverageRequestStore(
    useCallback(state => state.getBasicDetailsErrors, []),
  );

  const isPostAlertFieldModified = useExtracoverageRequestStore(
    useCallback(state => state.isPostAlertFieldModified, []),
  );

  const isSendEmailFieldModified = useExtracoverageRequestStore(
    useCallback(state => state.isSendEmailFieldModified, []),
  );

  const isEditorModified = useExtracoverageRequestStore(
    useCallback(state => state.isEditorModified, []),
  );
  const hasPostAccountAlertWarning = useExtracoverageRequestStore(
    useCallback(state => state.hasPostAccountAlertWarning, []),
  );

  const {
    data: emailStructure,
    refetch: onRefetching,
    isLoading: isEmailLoading,
  } = useGetDefaultEmail(coverageRequestDet?.Id);

  useEffect(() => {
    if (emailStructure) {
      updateStoreByKey('defaultEmail', emailStructure);
      updateStoreByKey('previousdefaultEmail', emailStructure);
    }
  }, [emailStructure, updateStoreByKey]);

  //getting the timezone value to pass on daterangepciker
  const timeZoneValue = useMemo(() => {
    const timeZonVal = timeZone?.find(
      (item: Timezone) =>
        item.Id?.toString() === coverageRequestDet?.TimeZoneId?.toString(),
    );
    return timeZonVal;
  }, [coverageRequestDet?.TimeZoneId, timeZone]);

  //getting the PT timezone
  const ptTimeZone = useMemo(() => {
    return timeZone?.find((item: Timezone) => item?.Abbreviation === 'PT');
  }, [timeZone]);

  // enable condition for both post & send approval email checkbox.
  const enablePostAndSendApproval = useCallback(() => {
    if (coverageRequestDet) {
      const { ExtraCoverageRequestStatusId, ExtraCoverageRequestTypeId } =
        coverageRequestDet;

      return (
        ExtraCoverageRequestStatusId &&
        ExtraCoverageRequestTypeId &&
        parseInt(ExtraCoverageRequestStatusId) === approveStatusId &&
        ExtraCoverageRequestTypeId !== -1
      );
    }
  }, [coverageRequestDet]);

  // disable post/ alert check box
  const disablePostAccountAlert = useMemo(() => {
    if (coverageRequestDet) {
      return (
        enablePostAndSendApproval() &&
        coverageRequestDet?.AccountAlertPosted ===
          (previousPostAccountAlert && isPostAlertFieldModified ? true : false)
      );
    }
    return false;
  }, [
    coverageRequestDet,
    enablePostAndSendApproval,
    isPostAlertFieldModified,
    previousPostAccountAlert,
  ]);

  // disable send email check box
  const disableSendEmail = useMemo(() => {
    if (coverageRequestDet) {
      return (
        enablePostAndSendApproval() &&
        coverageRequestDet?.EmailSent ===
          (isSendEmailFieldModified ? true : false)
      );
    }
    return false;
  }, [coverageRequestDet, enablePostAndSendApproval, isSendEmailFieldModified]);

  function onPostAccountAlertClick() {
    updateStoreByKey('hasPostAccountAlertWarning', false);
    const {
      ExtraCoverageDate,
      StartTime,
      EndTime,
      ReasonForCoverage,
      AdditionalComments,
      SpecialProcedures,
      SpecialProcedureMemo,
    } = coverageRequestDet;

    let content = editorContent(
      ReasonForCoverage ?? '',
      AdditionalComments ?? '',
      getDuration(
        ExtraCoverageDate ?? '',
        StartTime,
        EndTime,
        coverageRequestDet?.TimeZone?.Abbreviation,
        timeZoneValue,
        ptTimeZone,
      ),
      SpecialProcedures ?? false,
      SpecialProcedureMemo,
    );

    const postAlertConent = {
      message: content,
      dateRange: formatDateToRange(
        ExtraCoverageDate,
        ExtraCoverageDate,
        StartTime,
        EndTime,
      ),
    };
    updateStoreByKey('alertModalInfo', postAlertConent);
    updateStoreByKey('previousAlertModalInfo', postAlertConent);
  }

  // show the checkbox is checked, if the checkbox is disabled and the EmailSent or AccountAlertPosted is true during edit.
  function isChecked(label: string) {
    const isEmailSent =
      label === 'Send approval email?' && coverageRequestDet?.EmailSent;
    const isAlertPosted =
      label === 'Post account alert?' && coverageRequestDet?.AccountAlertPosted;

    return isEmailSent || isAlertPosted;
  }

  function onSendApprovalEmailClickHandler() {
    updateStoreByKey('hasSendEmailWarning', false);
    updateStoreByKey('isEnteredBy', true);
    updateStoreByKey('isOtherReceipents', true);
    updateStoreByKey('isSystemConfirmation', true);
    updateStoreByKey('isUpdateApiCalled', true);
    const coverageDetails: any = formatCoverageDetailsPayload(
      coverageRequestDet,
      accountData,
      userMode,
      timeZone,
      previousPostAccountAlert,
      isPostAlertFieldModified,
      previousSendEmail,
      isSendEmailFieldModified,
    );

    onSaveCoverageRequest(
      {
        params: coverageDetails,
        action: userMode,
      },
      {
        onSuccess: (data: any) => {
          if (data && data.Approver) {
            updateCoverageRequest('Approver', data.Approver);
          }
          onRefetching().then(() => {
            updateStoreByKey('defaultEmail', emailStructure);
            updateStoreByKey('previousdefaultEmail', emailStructure);
          });
        },
      },
    );
  }

  function resetPostAccountAlert(key: string) {
    if (
      isApproveMode &&
      userMode === 'Approve' &&
      (previousPostAccountAlert === true || postAlertOnSave)
    ) {
      updateStoreByKey('postAlertOnSave', false);
      updateStoreByKey('previousAlertModalInfo', null);

      if (
        (!isPostAlertFieldModified || !hasPostAccountAlertWarning) &&
        ((key === 'ReasonForCoverage' && isEditorModified) ||
          key !== 'ReasonForCoverage')
      ) {
        updateStoreByKey('hasPostAccountAlertWarning', true);
        updateStoreByKey('isPostAlertFieldModified', true);
      }
    }
  }

  function onChangeSpecialProcedure(value: any) {
    updateCoverageRequest('SpecialProcedures', value === 'true' ? true : false);
    if (value === 'false') updateCoverageRequest('SpecialProcedureMemo', '');

    if (userMode === 'Approve' && isApproveMode) {
      if (previousPostAccountAlert === true || postAlertOnSave) {
        updateStoreByKey('postAlertOnSave', false);
        updateStoreByKey('previousAlertModalInfo', null);
        updateStoreByKey('hasPostAccountAlertWarning', true);
      }
      if (previousSendEmail === true || sendEmailOnSave) {
        updateStoreByKey('sendEmailOnSave', false);
        updateStoreByKey('hasSendEmailWarning', true);
      }
      if (!isPostAlertFieldModified && previousPostAccountAlert)
        updateStoreByKey('isPostAlertFieldModified', true);
      if (!isSendEmailFieldModified && previousSendEmail)
        updateStoreByKey('isSendEmailFieldModified', true);
    }
  }

  function renderRadioBtn(
    id: string,
    label: string,
    value: boolean,
    checked: boolean,
  ): ReactElement {
    return (
      <RadioButton
        id={id}
        label={label}
        checked={checked}
        value={value ? 'true' : 'false'}
        onChange={e => onChangeSpecialProcedure(e.target.value)}
        onClick={e => {
          if (
            e.currentTarget.value ===
            coverageRequestDet?.SpecialProcedures?.toString()
          )
            updateCoverageRequest('SpecialProcedures', undefined);
        }}
        radioGroup="special-procedure"
      />
    );
  }

  function renderHeading(open: boolean): ReactElement {
    return (
      <Disclosure.Button className="flex w-full items-center justify-between rounded-lg bg-white px-4 py-2 text-left shadow-sm hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-light-focus ui-open:rounded-b-none dark:bg-neutral-800 dark:hover:bg-neutral-800/70">
        <div className="grow">
          <div className="flex items-center gap-2">
            {isSaveClicked && !isAllRequiredFieldsFilled('request-details') && (
              <div className="h-3 w-3">
                <span className="relative flex h-3 w-3 items-center justify-center">
                  <span className="absolute inline-flex h-full w-full animate-ping rounded-full bg-red-400 opacity-75" />
                  <span className="relative inline-flex h-2 w-2 rounded-full bg-red-500" />
                </span>
              </div>
            )}
            <h3 className="grow font-medium after:ml-0.5 after:text-red-400 after:content-['*']">
              Coverage request details
            </h3>
          </div>
        </div>
        <ChevronDownIcon
          className={`${
            open ? 'rotate-180 transform' : ''
          } h-5 w-5 text-gray-500 dark:text-neutral-300`}
        />
      </Disclosure.Button>
    );
  }

  function hasRequiredError() {
    return (
      !isAllRequiredFieldsFilled('account-info') ||
      !isAllRequiredFieldsFilled('basic-details') ||
      !isAllRequiredFieldsFilled('request-details')
    );
  }

  function renderTooltipWithCheckbox(
    id: string,
    label: string,
    disabled: boolean,
    checked: boolean,
    key: keyof RequestStore,
  ): ReactElement {
    const errorMessage = errorMsg(timeZoneValue);
    const requiredError = hasRequiredError();
    const tooltipContent = disabledReason(
      coverageRequestDet,
      errorMessage,
      requiredError,
      id !== 'emailSent',
      showError,
    );

    return (
      <Tooltip.Provider>
        <Tooltip.Root>
          <Tooltip.Trigger asChild>
            <div className="space-y-1">
              <Checkbox
                id={id}
                label={label}
                checked={disabled ? isChecked(label) : checked}
                onChange={e => {
                  updateStoreByKey(key, e.target.checked);
                  if (id === 'emailSent' && e.target.checked) {
                    onSendApprovalEmailClickHandler();
                  }
                  if (id == 'accountAlertPosted' && e.target.checked) {
                    onPostAccountAlertClick();
                  }
                }}
                disabled={disabled}
              />
              <span className="inline-block text-sm text-gray-600 dark:text-gray-400 lg:hidden">
                {tooltipContent}
              </span>
            </div>
          </Tooltip.Trigger>
          <Tooltip.Portal>
            <Tooltip.Content
              className="max-w-xs select-none rounded bg-white px-3 py-2 text-sm shadow-xl dark:bg-neutral-700"
              sideOffset={5}
            >
              {tooltipContent}
              <Tooltip.Arrow className="fill-white dark:fill-neutral-700" />
            </Tooltip.Content>
          </Tooltip.Portal>
        </Tooltip.Root>
      </Tooltip.Provider>
    );
  }

  return (
    <>
      <Disclosure as="div" id="request-det" className="pb-4" defaultOpen={true}>
        {({ open }) => (
          <>
            {renderHeading(open)}
            <Disclosure.Panel className="mb-4 space-y-10 rounded-b-lg border-t bg-white p-4 text-sm text-gray-500 shadow dark:border-neutral-700/50 dark:bg-neutral-800">
              <div className="space-y-8">
                <div className="space-y-1">
                  <label
                    htmlFor=""
                    className={classNames(
                      inputLabelClasses,
                      'after:ml-0.5 after:text-red-500 after:content-["*"] dark:after:text-red-400',
                    )}
                  >
                    Reason for coverage
                  </label>
                  <TinymceEditor
                    reference={(instance: Editor | null) => {
                      coverageReasonRef.current = instance;
                    }}
                    onChange={(content: string) => {
                      updateCoverageRequest('ReasonForCoverage', content);
                      resetPostAccountAlert('ReasonForCoverage');
                    }}
                    value={coverageRequestDet?.ReasonForCoverage}
                    height="80"
                    name={'Reason for coverage'}
                    config={tinymceTextAreaConfig}
                    key={colourScheme}
                    onDirty={() => {
                      if (!isEditorModified)
                        updateStoreByKey('isEditorModified', true);
                    }}
                  />
                </div>

                <div className="space-y-2">
                  <label
                    htmlFor=""
                    className={classNames(
                      inputLabelClasses,
                      '!font-bold after:ml-0.5 after:text-red-500 after:content-["*"] dark:after:text-red-400',
                    )}
                  >
                    Procedures during this coverage
                  </label>
                  <div className="flex flex-col items-start gap-x-5 gap-y-2 lg:flex-row">
                    <div className="order-2 space-y-2 lg:order-1 lg:shrink-0">
                      {renderRadioBtn(
                        'radio-follow-standard',
                        'Follow standard after-hours procedures',
                        false,
                        coverageRequestDet?.SpecialProcedures === false
                          ? true
                          : false,
                      )}
                      {renderRadioBtn(
                        'radio-follow-special',
                        'Follow special or modified procedures (describe below)',
                        true,
                        coverageRequestDet?.SpecialProcedures ?? false,
                      )}
                    </div>

                    <Alert variant="info" className="grow lg:order-2 ">
                      {coverageProcedureInfoMsg}
                    </Alert>
                  </div>

                  {coverageRequestDet?.SpecialProcedures && (
                    <div className="space-y-1">
                      <label
                        htmlFor=""
                        className={`${inputLabelClasses} 'after:ml-0.5  dark:after:text-red-400' pb-0 after:text-red-500 after:content-["*"]`}
                      >
                        Special procedures
                      </label>
                      <TinymceEditor
                        reference={(instance: Editor | null) => {
                          procedureRef.current = instance;
                        }}
                        onChange={(content: string) => {
                          updateCoverageRequest(
                            'SpecialProcedureMemo',
                            content,
                          );
                          resetPostAccountAlert('SpecialProcedureMemo');
                        }}
                        value={coverageRequestDet?.SpecialProcedureMemo}
                        height="200"
                        name={'Procedurememo'}
                        config={tinymceTextAreaConfig}
                        key={colourScheme}
                      />
                    </div>
                  )}
                </div>

                {coverageRequestDet?.SpecialProcedures !== undefined && (
                  <div className="space-y-1">
                    <label htmlFor="" className={`${inputLabelClasses} pb-0`}>
                      Additional comments
                    </label>
                    <TinymceEditor
                      reference={(instance: Editor | null) => {
                        additionalCommentRef.current = instance;
                      }}
                      onChange={(content: string) => {
                        updateCoverageRequest('AdditionalComments', content);
                        resetPostAccountAlert('AdditionalComments');
                      }}
                      value={coverageRequestDet?.AdditionalComments}
                      height="80"
                      name={'Additional comments'}
                      config={tinymceTextAreaConfig}
                      key={colourScheme}
                    />
                  </div>
                )}

                <div className="space-y-5">
                  <div className="flex items-center gap-1">
                    {userMode === 'Approve' &&
                      permission?.AllProtocallUsers &&
                      renderTooltipWithCheckbox(
                        'emailSent',
                        'Send approval email?',
                        !disableSendEmail ||
                          showError ||
                          errorMsg(timeZoneValue) !== '' ||
                          hasRequiredError(),
                        sendEmailOnSave,
                        'sendEmailOnSave',
                      )}
                    {sendEmailOnSave &&
                      userMode === 'Approve' &&
                      permission?.AllProtocallUsers && (
                        <>
                          <span
                            aria-hidden="true"
                            className="ms-3 inline-block h-5 border-s border-light-light dark:border-neutral-600"
                          />
                          <Button
                            type="button"
                            variant="link"
                            onClick={() => {
                              updateStoreByKey('isShowSendApproval', true);
                            }}
                            disabled={
                              !disableSendEmail ||
                              showError ||
                              errorMsg(timeZoneValue) !== '' ||
                              hasRequiredError()
                            }
                            className="!py-0"
                          >
                            View send approval email details
                          </Button>
                        </>
                      )}
                  </div>
                  <div className="flex items-center gap-1">
                    {userMode === 'Approve' &&
                      permission?.AllProtocallUsers &&
                      renderTooltipWithCheckbox(
                        'accountAlertPosted',
                        'Post account alert?',
                        !disablePostAccountAlert ||
                          errorMsg(timeZoneValue) !== '' ||
                          hasRequiredError(),
                        postAlertOnSave,
                        'postAlertOnSave',
                      )}

                    {postAlertOnSave &&
                      userMode === 'Approve' &&
                      permission?.AllProtocallUsers && (
                        <>
                          <span
                            aria-hidden="true"
                            className="ms-3 inline-block h-5 border-s border-light-light dark:border-neutral-600"
                          />
                          <Button
                            type="button"
                            variant="link"
                            onClick={() =>
                              updateStoreByKey('isAlertPreviewModal', true)
                            }
                            disabled={
                              !disablePostAccountAlert ||
                              errorMsg(timeZoneValue) !== '' ||
                              hasRequiredError()
                            }
                            className="!py-0"
                          >
                            View alert details
                          </Button>
                        </>
                      )}
                  </div>
                </div>
                {isAlertPreviewModal && <PreviewAccountAlert />}
                {isShowSendApproval && !isEmailLoading && <SendApprovalEmail />}
                {(isRequestSaving || isEmailLoading) && (
                  <div className="bg-gray-900/8 absolute inset-0 z-20 grid place-content-center backdrop-blur-sm">
                    <Spinner />
                  </div>
                )}
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </>
  );
}

export { RequestDetails };
