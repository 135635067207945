import { getScheduledCallDetails } from 'src/utils/urls';

import { ParamType } from '../types/activityTrackerStore';
import { ScheduleCall } from '../types/scheduleCallType';

async function fetchScheduledCallDetails({
  token,
  tokenType,
  submissionId,
}: Partial<ParamType>): Promise<ScheduleCall | undefined> {
  if (submissionId) {
    const response = await fetch(getScheduledCallDetails(submissionId), {
      method: 'GET',
      headers: { Authorization: `${tokenType} ${token}` },
    });
    return response.json();
  }
}

export { fetchScheduledCallDetails };
