import { useQuery, UseQueryResult } from 'react-query';

import { useAuth } from 'src/hooks';

import { getCallTakerGroup } from 'src/utils/urls';

import { CalltakerType, TReturnType } from '../types/scheduleCallType';

async function fetchCallTakerList({
  token,
  tokenType,
}: {
  token: string;
  tokenType: string;
}): Promise<ApiResult> {
  const response = await fetch(getCallTakerGroup, {
    method: 'GET',
    headers: {
      Authorization: `${tokenType} ${token}`,
    },
  });
  return response.json();
}

type ApiResult = Array<CalltakerType>;
type TError = { message: string };

function useGetCallTakerGroup(): UseQueryResult<TReturnType, TError> {
  const { token, tokenType } = useAuth();
  return useQuery(
    ['get_calltaker_list'],
    async () => {
      const result = await fetchCallTakerList({ token, tokenType });
      if (result) {
        let formattedValue = result.map(x => ({
          label: x.Name,
          value: x.Id,
        }));
        formattedValue.unshift({ value: -1, label: 'No Group Specified' });
        return formattedValue;
      }
      return [];
    },
    {
      staleTime: Infinity,
      refetchOnWindowFocus: false,
    },
  );
}

export { useGetCallTakerGroup };
