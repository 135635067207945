import React, { ReactElement, useState } from 'react';

import { Alert, Button, Spinner, TextField } from 'src/ui/components';

import { useTitle } from 'src/hooks';
import { useSentEmailLink } from 'src/features/Users/api/useSentEmailLink';

import { validateEmail } from 'src/features/AddEditUsers/utils/userHelper';

export default function ForgotPassword(): ReactElement {
  useTitle('Forgot Password');

  const [email, setEmail] = useState<string>('');
  const [showSuccessMsg, setShowSuccessMsg] = useState<boolean>(false);
  const [showErrorMsg, setShowErrorMsg] = useState<boolean>(false);

  const {
    isLoading,
    mutate: sendEmailLink,
    error: errorMsg,
    isError,
  } = useSentEmailLink();

  function onButtonHandler() {
    sendEmailLink(
      { email },
      {
        onSuccess: () => {
          setShowSuccessMsg(true);
        },
        onError: () => {
          setShowErrorMsg(true);
        },
      },
    );
  }

  function renderTextField() {
    return (
      <div>
        <label
          htmlFor="password"
          className="text-base font-medium leading-none"
        >
          Email
        </label>
        <div className="relative flex animate-shake flex-wrap items-stretch">
          <TextField
            id="email"
            className="!w-max min-w-0 flex-auto !rounded-r-none outline-none"
            autoFocus
            value={email}
            validationError={
              !validateEmail(email) && email !== '' ? 'Invalid email' : ''
            }
            onChange={e => {
              setEmail(e.target.value);
              setShowErrorMsg(false);
              setShowSuccessMsg(false);
            }}
          />
        </div>
      </div>
    );
  }

  return (
    <main className="grid flex-1 place-items-center overflow-y-auto overscroll-contain p-3">
      <div className="container mx-auto max-w-lg gap-y-8 rounded-lg bg-white shadow dark:bg-dark-dark">
        <h2 className="px-4 pt-4 text-xl font-medium md:px-6 md:text-2xl">
          Forgot password
        </h2>
        <p className="px-6 pt-2 text-xs text-gray-500 dark:text-neutral-400 md:text-sm">
          Enter your email below to reset your password.
        </p>

        <div className="p-4 md:p-6">
          {showErrorMsg && isError && !isLoading && (
            <Alert variant="danger" title="Error" className="mb-4">
              {errorMsg.message}
            </Alert>
          )}

          {!isError && !isLoading && showSuccessMsg && (
            <Alert variant="success" title="" className="mb-4">
              An email with instructions for resetting your password has been
              sent.
            </Alert>
          )}

          <div className="space-y-4">{renderTextField()}</div>

          {isLoading && (
            <div className="fixed inset-0 z-20 grid place-content-center">
              <Spinner size="large" />
            </div>
          )}
        </div>

        <div className="flex gap-[1ch] rounded-b-lg bg-gray-50 px-4 py-4 dark:bg-neutral-700/50 sm:justify-end md:px-6">
          <Button
            variant="primary"
            className="grow sm:grow-0"
            onClick={e => onButtonHandler()}
            disabled={
              !validateEmail(email) ||
              email === '' ||
              showSuccessMsg ||
              showErrorMsg ||
              isLoading
            }
          >
            Email link
          </Button>
        </div>
      </div>
    </main>
  );
}
