import React, { ReactElement, useRef, useState } from 'react';
import * as Tooltip from '@radix-ui/react-tooltip';
import { InformationCircleIcon } from '@heroicons/react/20/solid';

export type TooltipItemType = {
  label: string;
  values: string;
  isRead?: boolean;
  containerRef?: any;
};

export function TooltipItem({
  label,
  values,
  isRead,
  containerRef,
}: TooltipItemType): ReactElement {
  function getTooltipValue() {
    if (values !== '' && values !== undefined && values !== null) {
      if (values.includes(',')) return 'Multiple';
      return values;
    }
    return '';
  }
  const [open, setOpen] = useState(false);

  return (
    <>
      <label className="block whitespace-nowrap text-[10px] font-medium uppercase tracking-wide opacity-70">
        {label}
      </label>
      <p
        className={`flex items-center gap-[1ch] text-xs ${
          isRead ? '' : 'font-bold'
        } tracking-wide text-black dark:text-white`}
      >
        {getTooltipValue()}
        {getTooltipValue() === 'Multiple' && (
          <Tooltip.Provider>
            <Tooltip.Root
              open={open}
              delayDuration={0}
              onOpenChange={() => setOpen && setOpen(!open)}
            >
              <Tooltip.Trigger
                asChild
                onBlur={() => setOpen && setOpen(false)}
                onClick={e => {
                  setOpen && setOpen(true);
                }}
              >
                <InformationCircleIcon
                  className="-ml-0.5 h-5 w-5 text-primary"
                  aria-hidden="true"
                />
              </Tooltip.Trigger>
              <Tooltip.Portal>
                <Tooltip.Content
                  className="z-[1] select-none rounded border border-gray-400 bg-white px-3 py-2 text-sm shadow-xl dark:border-transparent dark:bg-neutral-700"
                  sideOffset={5}
                  collisionBoundary={[containerRef?.current]}
                >
                  <div className="max-h-60 max-w-[300px] overflow-y-auto">
                    {values}
                  </div>

                  <Tooltip.Arrow className="fill-gray-400 dark:fill-neutral-700" />
                </Tooltip.Content>
              </Tooltip.Portal>
            </Tooltip.Root>
          </Tooltip.Provider>
        )}
      </p>
    </>
  );
}
