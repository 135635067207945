import { useMutation, UseMutationResult } from 'react-query';

import { onbordingStatusUpdate } from 'src/utils/urls';

export type TResult = boolean | any;
export type TError = { message: string };
export type TVariable = {
  id: string | null;
  status: string;
};

async function updateOnboardingStatus({
  id,
  status,
}: TVariable): Promise<TResult> {
  const response = await fetch(onbordingStatusUpdate(id, status), {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return response;
}

function useUpdateOnboardingStatus(): UseMutationResult<
  TResult,
  TError,
  TVariable,
  unknown
> {
  return useMutation(async ({ id, status }: TVariable) => {
    const result = await updateOnboardingStatus({
      id,
      status,
    });
    return result;
  });
}

export { useUpdateOnboardingStatus };
