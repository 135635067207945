const accountSortConstants = [
  'status',
  'Phone display',
  'CID',
  'SID',
  'PID',
  'Answer script',
  'AccountName',
  '',
  '',
  '',
  'Timezone',
];

export { accountSortConstants };
