import React, { ReactElement, useMemo, useCallback } from 'react';
import { Disclosure } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';

import { TextField } from 'src/ui/components';

import { useUserStore } from '../store/userStore';
import { validateEmail } from '../utils/userHelper';
import { gridElements } from '../utils/userConstants';

import { gridItemType } from '../types/userStoreTypes';

function BasicDetails(): ReactElement {
  const userMode = useUserStore(useCallback(state => state.userMode, []));

  const basicUserDetails = useUserStore(
    useCallback(state => state.basicUserDetails, []),
  );

  const updateBasicUserDetails = useUserStore(
    useCallback(state => state.updateBasicUserDetails, []),
  );

  const enableBasicDetails = useUserStore(
    useCallback(state => state.enableSettings, []),
  );

  const allowReInvite = useUserStore(
    useCallback(state => state.allowReInvite, []),
  );

  const isSaveClicked = useUserStore(
    useCallback(state => state.isSaveClicked, []),
  );

  const setShowValidationErr = useUserStore(
    React.useCallback(state => state.setShowValidationErr, []),
  );

  const showValidationErr = useUserStore(
    React.useCallback(state => state.showValidationErr, []),
  );

  const prevInvalidEmail = useUserStore(
    React.useCallback(state => state.prevInvalidEmail, []),
  );

  const validationError: string = useMemo(() => {
    let validationMsg: string = '';
    if (basicUserDetails?.email && basicUserDetails?.email.length !== 0) {
      if (!validateEmail(basicUserDetails?.email))
        validationMsg = 'Invalid email';
    }
    if (
      showValidationErr ||
      (prevInvalidEmail !== '' && prevInvalidEmail === basicUserDetails.email)
    )
      validationMsg = `Email ID already exist.`;

    return validationMsg;
  }, [basicUserDetails.email, prevInvalidEmail, showValidationErr]);

  function gridItemValue(label: string): string {
    switch (label) {
      case 'First name':
        return basicUserDetails?.firstName;
      case 'Last name':
        return basicUserDetails?.lastName;
      case 'Email address':
        return basicUserDetails?.email;
      default:
        return '';
    }
  }

  function renderBasicDetailsGridItems(): ReactElement {
    return (
      <>
        {gridElements?.map((item: gridItemType, index: number) => {
          // if (gridItemValue(item.label) !== '') {
          return (
            <div
              key={`${item.id + index}`}
              className="sm:max-w-[20ch] lg:max-w-[30ch]"
            >
              <label className="block text-xs font-bold">{item?.label}</label>
              <p
                className="truncate text-sm opacity-75"
                title={gridItemValue(item?.label)}
              >
                {gridItemValue(item?.label) !== '' &&
                gridItemValue(item?.label) !== undefined
                  ? gridItemValue(item?.label)
                  : '-'}
              </p>
            </div>
          );
        })}
      </>
    );
  }

  function renderBasicInfoTextField(): ReactElement {
    return (
      <>
        {gridElements?.map((item: gridItemType, index: number) => (
          <TextField
            key={`${item.id + index}`}
            id={item.id}
            label={item.label}
            type={item.key === 'email' ? 'email' : 'text'}
            value={gridItemValue(item.label)}
            onChange={e => {
              updateBasicUserDetails(item.key, e.target.value);
              if (item.key === 'email' && showValidationErr) {
                setShowValidationErr(false);
              }
            }}
            validationError={item.key === 'email' ? validationError : ''}
            required
            disabled={
              userMode === 'Edit' &&
              item.key === 'email' &&
              basicUserDetails?.status !== 'Invitation sent' &&
              !allowReInvite
                ? true
                : false
            }
            maxLength={400}
          />
        ))}
      </>
    );
  }

  return (
    <Disclosure
      as="div"
      defaultOpen={userMode === 'Edit' ? false : true}
      id="basic-det"
    >
      {({ open }) => (
        <>
          <Disclosure.Button className="flex w-full items-center justify-between rounded-lg bg-white px-4 py-2 text-left shadow-sm hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-light-focus ui-open:rounded-b-none dark:bg-neutral-800 dark:hover:bg-neutral-800/70">
            <div className="flex grow flex-wrap items-center gap-x-8 gap-y-2  pr-2">
              <div className="grow">
                <div className="flex items-center gap-2">
                  {isSaveClicked && !enableBasicDetails('basic-tab') && (
                    <div className="h-3 w-3">
                      <span className="relative flex h-3 w-3 items-center justify-center">
                        <span className="absolute inline-flex h-full w-full animate-ping rounded-full bg-red-400 opacity-75" />
                        <span className="relative inline-flex h-2 w-2 rounded-full bg-red-500" />
                      </span>
                    </div>
                  )}
                  <h3 className="font-medium after:ml-0.5 after:text-red-400 after:content-['*']">
                    Basic details
                  </h3>
                </div>
              </div>

              <div className="hidden gap-2 ui-open:hidden sm:flex sm:ui-open:hidden">
                {/* <div className="h-3 w-3" aria-hidden="true" /> */}
                <div className="flex gap-8">
                  {renderBasicDetailsGridItems()}
                </div>
              </div>
            </div>
            <ChevronDownIcon
              className={`${
                open ? 'rotate-180 transform' : ''
              } h-5 w-5 text-gray-500 dark:text-neutral-300`}
            />
          </Disclosure.Button>
          <Disclosure.Panel className="rounded-b-lg border-t bg-white p-4 text-sm text-gray-500 shadow dark:border-neutral-700/50 dark:bg-neutral-800">
            <div className="grid grid-cols-1 gap-4 md:grid-cols-3">
              {renderBasicInfoTextField()}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}

export { BasicDetails };
