import React, { ReactElement, useRef, useState, Fragment } from 'react';
import { PaperClipIcon } from '@heroicons/react/24/outline';
import {
  ArrowDownTrayIcon as ArrowDownTrayIconSolid,
  TrashIcon,
  XMarkIcon,
  ArrowUpTrayIcon,
} from '@heroicons/react/20/solid';
import { Transition, Dialog } from '@headlessui/react';

import { Button, Spinner } from 'src/ui/components';

import { useGetTutorials, useGetDownloadTutorial } from 'src/hooks';
import { useGetStoredPermissions } from 'src/hooks';
import { useDeleteTutorialFile, useUploadFile } from '../api';

import { showErrorToast, showSuccessToast } from 'src/utils/ToastNotification';
import {
  successMsg,
  deleteMsg,
  nameWarning,
  fileUploadInfo,
  fileErrorMsg,
  fileFormatErrorMsg,
  fileDoesNotExistMsg,
} from '../utils/constants';
import { base64ToArrayBuffer } from 'src/utils/convertToBinaryFormat';

import {
  DownloadFileType,
  TutorialType,
  UploadType,
} from 'src/types/tutorialsType';

export function Tutorials(): ReactElement {
  const {
    data: tutorialFiles,
    isLoading: isTutorialsLoading,
    refetch,
  } = useGetTutorials();

  const { mutate: downloadData, isLoading: isDownloading } =
    useGetDownloadTutorial();

  const { mutate: uploadFile, isLoading: isUploading } = useUploadFile();

  const { mutate: deleteFile, isLoading: isDeleting } = useDeleteTutorialFile();

  const permission = useGetStoredPermissions();

  const [showModal, setShowModal] = useState<boolean>(false);
  const [errorState, setErrorState] = useState({
    errorType: '',
    isError: false,
  });
  const [fileError, setFileError] = useState(false);
  const [fileName, setFileName] = useState<string>('');

  const fileInputRef = useRef<any>(null);

  const isSpinnerLoading =
    isUploading || isDeleting || isDownloading || isTutorialsLoading;

  function handleFileChange(event: any) {
    const file = event.target.files[0];
    var fileName = file.name;
    let fileModules = fileName.split('.');
    let fileExtension = fileModules[fileModules.length - 1];

    if (file.name != null && file.name != '' && file.size < 15728640) {
      let docParam: UploadType;
      let filePath = event.target.value;
      const reader = new FileReader();

      reader.onload = e => {
        if (tutorialFiles && tutorialFiles?.length < 10) {
          var isFileExist = tutorialFiles?.findIndex(
            item => item?.DocumentName === file?.name,
          );

          if (isFileExist === -1) {
            if (fileExtension === 'pdf') {
              docParam = {
                Title: fileName,
                Content: filePath,
                Container: '',
                streamContent: reader?.result,
              };

              uploadFile(docParam, {
                onSuccess: () => {
                  showSuccessToast({ message: successMsg });
                },
              });
            } else {
              setErrorState({ errorType: fileFormatErrorMsg, isError: true });
            }
          } else {
            setErrorState({ errorType: nameWarning, isError: true });
          }
        }
      };
      reader.readAsDataURL(file);
    } else if (file.size > 15728640 || fileExtension !== 'pdf') {
      setFileError(true);
    }
    event.target.value = '';
  }

  //on download
  function onDownload(documentName: string) {
    downloadData(documentName, {
      onSuccess: (data: DownloadFileType) => {
        if (
          data?.Status === 'Success' &&
          data?.FileContent !== null &&
          data?.FileName !== null &&
          data?.FileContent !== '' &&
          data?.FileName !== '' &&
          data?.FileName !== undefined &&
          data?.FileContent !== undefined
        ) {
          let docFile = base64ToArrayBuffer(data?.FileContent);
          let blob = new Blob([docFile], {
            type: 'pdf',
          });

          const link = document.createElement('a');
          const url = window.URL.createObjectURL(blob);
          link.href = url;
          const fileName = data?.FileName;
          link.download = fileName;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);
          refetch();
        } else {
          showErrorToast({
            message: fileDoesNotExistMsg,
          });
        }
      },
      onError: (errorMsg: any) => {
        showErrorToast({ message: errorMsg?.message });
      },
    });
  }

  function onDelete(fileName: string) {
    deleteFile(fileName, {
      onSuccess: () => {
        showSuccessToast({ message: deleteMsg });
      },
      onError: (errorMsg: any) => {
        showErrorToast({ message: errorMsg.message });
      },
    });
  }

  function renderConfirmAlert(): ReactElement {
    return (
      <Transition appear show={showModal} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => setShowModal(false)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="pr-dialog__backdrop" />
          </Transition.Child>

          <div className="pr-dialog__wrapper">
            <div className="pr-dialog__panel__container">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="pr-dialog__panel">
                  <Dialog.Title
                    as="h3"
                    className="pl-4 pr-16 pt-4 font-medium leading-6 text-gray-900 dark:text-white"
                  >
                    Are you sure you want to delete <br />{' '}
                    <strong className="font-bold">{fileName}</strong>?
                  </Dialog.Title>
                  <button
                    type="button"
                    className="absolute right-4 top-4 text-gray-400 hover:text-gray-500 sm:right-4 sm:top-4"
                    onClick={() => setShowModal(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>

                  <div className="mt-4 flex gap-[1ch] bg-gray-50 p-2 px-2 py-4 dark:bg-neutral-700/50 sm:justify-end sm:p-3">
                    <Button
                      variant="secondary"
                      onClick={() => setShowModal(false)}
                      className="grow sm:grow-0"
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="danger"
                      onClick={() => {
                        onDelete(fileName);
                        setShowModal(false);
                      }}
                      className="grow sm:grow-0"
                    >
                      Delete
                    </Button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    );
  }

  function fileExistModal(): ReactElement {
    return (
      <Transition appear show={errorState.isError || fileError} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => setShowModal(false)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="relative w-full max-w-md transform overflow-hidden rounded-2xl bg-white text-left align-middle shadow-xl transition-all dark:bg-neutral-800">
                  <Dialog.Title
                    as="h3"
                    className="pl-4 pr-16 pt-4 font-medium leading-6 text-gray-900 dark:text-white"
                  >
                    {errorState.isError === true
                      ? errorState.errorType
                      : fileError == true
                      ? fileErrorMsg
                      : ''}
                  </Dialog.Title>
                  <button
                    type="button"
                    className="absolute right-4 top-4 text-gray-400 hover:text-gray-500 sm:right-4 sm:top-4"
                    onClick={() => {
                      setFileError(false);
                      setErrorState({ errorType: '', isError: false });
                    }}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                  <div className="flex gap-[1ch] bg-gray-50 p-2 px-2 py-4 dark:bg-neutral-700/50 sm:justify-end sm:p-3">
                    <Button
                      variant="primary"
                      onClick={() => {
                        setErrorState({ errorType: '', isError: false });
                        setFileError(false);
                      }}
                      className="grow sm:grow-0"
                    >
                      Ok
                    </Button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    );
  }

  function renderTutorialList(item: TutorialType, index: number): ReactElement {
    return (
      <>
        <li
          key={`${index}${item?.Id}`}
          className="flex items-center justify-between py-1 pl-2 pr-3 text-sm leading-6 shadow-sm md:pl-3 md:pr-4"
        >
          <div className="flex w-0 flex-1 items-center">
            <PaperClipIcon
              className="hidden h-5 w-5 flex-shrink-0 text-gray-400 md:inline-block"
              aria-hidden="true"
            />
            <div className="ml-2 flex min-w-0 flex-1 gap-2 md:ml-4">
              <span className="truncate font-medium">{item?.DocumentName}</span>
            </div>
          </div>
          <div className="ml-4 flex-shrink-0">
            <Button
              variant="subtle-primary"
              className="flex gap-2"
              onClick={() => {
                setFileName(item?.DocumentName);
                onDownload(item?.DocumentName);
              }}
              title="Download document"
            >
              <ArrowDownTrayIconSolid className="h-5 w-5" />
              <span className="sr-only">Download</span>
            </Button>
            {permission?.AdministratorUsers ? (
              <Button
                variant="subtle-danger"
                className="flex gap-2"
                onClick={() => {
                  setFileName(item?.DocumentName);
                  setShowModal(true);
                }}
                title="Delete document"
              >
                <TrashIcon className="h-5 w-5" />
                <span className="sr-only">Delete</span>
              </Button>
            ) : null}
          </div>
        </li>
      </>
    );
  }

  return (
    <div className="divide-y divide-light-light dark:divide-dark-medium">
      {fileExistModal()}
      {isSpinnerLoading && (
        <div className="bg-gray-900/8  absolute inset-0 z-50 grid   place-items-center  backdrop-blur-sm">
          <Spinner size="large" />
        </div>
      )}
      <h3 className="px-3 py-2 text-xl font-medium md:px-4 md:py-4">
        Tutorials
      </h3>
      <div className="p-3">
        <div className="space-y-2">
          <ul
            role="list"
            className="divide-y divide-gray-100 rounded-md border border-gray-300 bg-white dark:divide-neutral-700 dark:border-neutral-700 dark:bg-neutral-800"
          >
            {tutorialFiles?.map((item, index) =>
              renderTutorialList(item, index),
            )}
          </ul>
          {renderConfirmAlert()}
        </div>
        {permission?.AdministratorUsers ? (
          <>
            <div className="flex gap-[1ch] p-4 sm:justify-center sm:p-3">
              <input
                type="file"
                accept=".pdf"
                onChange={event => {
                  handleFileChange(event);
                }}
                style={{ display: 'none' }}
                ref={fileInputRef}
              />
              <Button
                variant="primary"
                onClick={() => fileInputRef?.current?.click()}
                disabled={tutorialFiles && tutorialFiles?.length >= 10}
                className="flex grow items-center justify-center gap-2 sm:grow-0"
              >
                <ArrowUpTrayIcon className="h-5 w-5" />
                <span>Upload new document</span>
              </Button>
            </div>
            <div className="flex justify-center text-sm text-gray-500 dark:text-neutral-400">
              {fileUploadInfo}
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
}
