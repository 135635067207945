import { useMutation, UseMutationResult } from 'react-query';

import { useAuth } from 'src/hooks';
import { useProgressStore } from 'src/hooks/useProgressStore';

import { updateTag } from 'src/utils/urls';

async function fetchUpdateTag({
  requestData,
  token,
  tokenType,
}: {
  requestData: TVariables;
  token: string;
  tokenType: string;
}): Promise<TResult> {
  const response = await fetch(updateTag, {
    method: 'PUT',
    headers: {
      Authorization: `${tokenType} ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(requestData),
  });
  return response.json();
}

export type TResult = string;
export type TError = { message: string };

export type TVariables = {
  CallIds: Array<number>;
  status: number;
};

function setRequestData(CallIds: Array<number>, status: number) {
  return {
    CallIds: CallIds,
    status: status,
  };
}

function useUpdateTag(): UseMutationResult<
  TResult,
  TError,
  TVariables,
  unknown
> {
  const { token, tokenType } = useAuth();
  const { setLoading } = useProgressStore();

  return useMutation(async (params: TVariables) => {
    const requestData = setRequestData(params?.CallIds, params?.status);
    setLoading('isTagUpdating', true);
    const result = await fetchUpdateTag({
      token: token,
      tokenType: tokenType,
      requestData: requestData,
    });
    return result;
  });
}

export { useUpdateTag };
