import React, { InputHTMLAttributes } from 'react';
import { inputLabelClasses } from '../TextField';
import { classNames } from 'src/utils/className';
import clsx, { ClassValue } from 'clsx';

type RadioProps = InputHTMLAttributes<HTMLInputElement> & {
  label?: string;
  id: string;
  inputSize?: 'small' | 'medium' | 'large';
  className?: ClassValue;
};
export const radioBaseClasses =
  'h-4 w-4 cursor-pointer border-gray-300 bg-white checked:text-primary focus:ring-primary disabled:cursor-default dark:bg-neutral-600 dark:checked:bg-current';

export const RadioButton = ({
  label,
  id,
  inputSize = 'medium',
  className,
  ...rest
}: RadioProps) => {
  const userClasses = clsx(className);
  const sizeClasses = {
    small: 'h-4 w-4',
    medium: 'h-5 w-5',
    large: 'h-6 w-6',
  };

  const sizeClass = sizeClasses[inputSize] || sizeClasses.medium;

  return (
    <div className={classNames('flex items-center space-x-2', userClasses)}>
      <input
        id={id}
        type="radio"
        className={classNames(sizeClass, radioBaseClasses, 'peer')}
        {...rest}
      />
      {label && (
        <label
          className={classNames(
            inputLabelClasses,
            'cursor-pointer peer-disabled:cursor-default peer-disabled:opacity-40',
          )}
          htmlFor={id}
        >
          {label}
        </label>
      )}
    </div>
  );
};

export default RadioButton;
