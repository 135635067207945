import React, { ReactElement, useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import AppRouter from './router';
import 'react-toastify/dist/ReactToastify.css';
import 'rsuite/DatePicker/styles/index.css';
import './index.css';

export default function App(): ReactElement {
  const [queryClient] = React.useState(() => new QueryClient());

  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools />
      <ToastContainer draggable={false} />
      <AppRouter />
    </QueryClientProvider>
  );
}
