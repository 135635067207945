import { gridItemType } from '../types/requestType';

const timeFormat: string = 'h:mm a';

const dateFormat: string = 'MM/DD/YYYY';

const errMsgForTimeDifference: string =
  'Extra coverage requests through the Customer Portal must be made at least 24 hours in advance. Please adjust the date/time or call 800-435-2197 if your extra coverage need is within 24 hours.';

const createSuccessMsg: string =
  'The extra coverage request was successfully created.';

const updateSuccessMsg: string =
  'Extra coverage request has been successfully updated.';

const approveStatusId: number = 2;

const recipientsMsg: string = `For multiple recipients, please use commas to separate email
  addresses (e.g. user1@example.com, user2@example.com)`;

const recipientsErrorMsg: string = `The recipient's email address is invalid`;

const extraCoverageTabs: Array<string> = [
  'account-info',
  'basic-details',
  'request-details',
];

const extraCoverageTabSections: Array<{ message: string; link: string }> = [
  { message: 'Select accounts', link: '#account_info' },
  { message: 'Basic details', link: '#basic-details' },
  { message: 'Coverage Request Details', link: '#request-det' },
];

const gridElements: Array<gridItemType> = [
  {
    id: 'extra-covergae-date',
    label: 'Date of extra coverage',
    key: 'ExtraCoverageDate',
  },
  {
    id: 'start-time',
    label: 'Start time',
    key: 'startTIme',
  },
  {
    id: 'end-time',
    label: 'End time',
    key: 'endTime',
  },
];

const REASON_REGEX = /<strong>REASON:<\/strong>\s*(.*?)<\/p>/;
const ADDITIONAL_COMMENTS_REGEX =
  /<p><strong>ADDITIONAL COMMENTS: <\/strong><em>(.*?)<\/em><\/p>/;

const sendEmailSuccessMsg =
  'The extra coverage request approval was successfully emailed.';

const postAlertMsg: string =
  'The extra coverage request account alert was successfully posted.';

const disbaleDatePickerMsg =
  'Please select a time zone for enabling the date and time picker';

const disableTimePickerMsg =
  'Please select a valid date to enable the time picker';

const invalidTimeMsg = 'Invalid time entered. Please select a valid time';

const emptyInfoMsg = 'Please re-enter the time as the dates have been changed';

const coverageProcedureInfoMsg = ` In most cases, standard after-hours procedures are
                      appropriate for the duration of the extra coverage. Please
                      indicate whether this is the case for you (1st option), or
                      if there are any situations that require us to follow
                      special or modified procedures (2nd option).`;

export {
  timeFormat,
  dateFormat,
  createSuccessMsg,
  updateSuccessMsg,
  errMsgForTimeDifference,
  approveStatusId,
  postAlertMsg,
  recipientsMsg,
  recipientsErrorMsg,
  extraCoverageTabs,
  extraCoverageTabSections,
  gridElements,
  REASON_REGEX,
  ADDITIONAL_COMMENTS_REGEX,
  sendEmailSuccessMsg,
  disbaleDatePickerMsg,
  disableTimePickerMsg,
  invalidTimeMsg,
  emptyInfoMsg,
  coverageProcedureInfoMsg,
};
