import React, {
  Fragment,
  ReactElement,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';
import { Dialog, Transition } from '@headlessui/react';
import { EyeIcon, EyeSlashIcon, XMarkIcon } from '@heroicons/react/20/solid';

import {
  Alert,
  Button,
  inputBaseClasses,
  inputSizeClasses,
  buttonBaseClasses,
  buttonSizeClasses,
  buttonVariantClasses,
} from 'src/ui/components';

import { useResetPassword } from '../api';

import { showSuccessToast } from 'src/utils/ToastNotification';
import {
  passwordChangedSuccessMsg,
  passwordMatchMsg,
} from 'src/utils/appConstants';
import { getPasswordWarningMsg } from '../utils/SettingsHelper';

import { PasswordFieldType } from '../types/SettingsType';

type ResetPasswordModalType = {
  resetPassModal: boolean;
  setResetPassModal: (
    value: boolean | ((prevState: boolean) => boolean),
  ) => void;
};

export function ResetPassword({
  resetPassModal,
  setResetPassModal,
}: ResetPasswordModalType): ReactElement {
  /*user id */
  const { id } = useParams();

  /*initial focus on text feild */
  const passRef = useRef(null);

  const [password, setPassword] = useState({
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
  });

  const [passwordType, setPasswordType] = useState({
    currentPasswordType: 'password',
    newPasswordType: 'password',
    confirmPasswordType: 'password',
  });

  const [passwordErrorMsg, setPasswordErrorMsg] = useState('');

  const { mutate: onResetPassword } = useResetPassword();

  /*open the reset password modal when the page loads if the logged in and selected user are same and click change password from user page*/
  useEffect(() => {
    if (id) {
      setResetPassModal(true);
    }
  }, [id, setResetPassModal]);

  // to disable the save button if all required password conditions not meet.
  function isSaveDisabled(): boolean {
    return (
      Object.values(password).some(x => x === null || x === '') ||
      getValidationErrors().length > 0
    );
  }

  function getValidationErrors(): string {
    const { newPassword, confirmPassword } = password;

    if (newPassword === '') return '';

    if (confirmPassword !== '' && newPassword !== confirmPassword)
      return passwordMatchMsg;

    const warningMsg = getPasswordWarningMsg(newPassword);

    if (warningMsg !== '') return warningMsg;

    if (newPassword.length < 8 && newPassword.length > 0)
      return 'Passwords must be at least 8 characters';

    return '';
  }

  function resetState() {
    setPassword({
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
    });
    setPasswordType({
      currentPasswordType: 'password',
      newPasswordType: 'password',
      confirmPasswordType: 'password',
    });
    setResetPassModal(false);
    setPasswordErrorMsg('');
  }

  function onSaveResetPassword() {
    const params = {
      CurrentPassword: password.currentPassword,
      NewPassword: password.newPassword,
    };

    onResetPassword(params, {
      onSuccess: () => {
        showSuccessToast({
          message: passwordChangedSuccessMsg,
        });
        resetState();
      },
      onError: (error: any) => {
        setPasswordErrorMsg(error);
      },
    });
  }

  function showErrorMsg(): ReactElement {
    return (
      <>
        {passwordErrorMsg.length ? (
          <div className="mt-2 space-y-3 p-4 pb-0">
            <Alert variant="danger">{passwordErrorMsg}</Alert>
          </div>
        ) : null}
      </>
    );
  }

  function renderPasswordBlock({
    label,
    id,
    type,
    value,
    onChange,
    onClick,
    ref,
    onBlur,
  }: PasswordFieldType): ReactElement {
    return (
      <div className="px-2 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
        <dt className="flex items-center text-sm font-medium leading-6 text-gray-900 dark:text-white">
          {label}
        </dt>
        <dd className="mt-1 truncate text-sm leading-6 text-gray-700 dark:text-neutral-400 sm:col-span-2 sm:mt-0">
          <div className="relative flex flex-wrap items-stretch">
            <input
              className={`${[inputBaseClasses, inputSizeClasses.large].join(
                ' ',
              )} !w-max min-w-0 flex-auto !rounded-r-none outline-none`}
              id={id}
              type={type}
              value={value}
              onChange={onChange}
              ref={ref}
              onBlur={onBlur}
              onCopy={e => e.preventDefault()}
              onPaste={e => {
                e.preventDefault();
              }}
              onDrop={e => e.preventDefault()}
              onDrag={e => e.preventDefault()}
            />
            <button
              className={`${[
                buttonBaseClasses,
                buttonSizeClasses.medium,
                buttonVariantClasses.subtle,
              ].join(
                ' ',
              )} !rounded-l-none ring-1 ring-inset ring-gray-300 dark:ring-neutral-700`}
              title={type === 'password' ? 'Show password' : 'Hide password'}
              onClick={onClick}
            >
              <span className="sr-only">
                {type === 'password' ? 'Show password' : 'Hide password'}
              </span>
              {type === 'text' ? (
                <EyeIcon className="-ml-0.5 h-5 w-4" aria-hidden="true" />
              ) : (
                <EyeSlashIcon className="-ml-0.5 h-5 w-4" aria-hidden="true" />
              )}
            </button>
          </div>
        </dd>
      </div>
    );
  }

  function renderFooterButtons(): ReactElement {
    return (
      <div className="flex gap-[1ch] bg-gray-50 p-4 dark:bg-neutral-700/50 sm:justify-end sm:p-3">
        <Button
          variant="secondary"
          onClick={() => {
            resetState();
          }}
          className="grow sm:grow-0"
        >
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            onSaveResetPassword();
          }}
          className="grow sm:grow-0"
          disabled={isSaveDisabled()}
        >
          Save
        </Button>
      </div>
    );
  }

  return (
    <Transition appear show={resetPassModal} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={passRef}
        onClose={() => setResetPassModal(true)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="pr-dialog__backdrop" />
        </Transition.Child>

        <div className="pr-dialog__wrapper">
          <div className="pr-dialog__panel__container">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="pr-dialog__panel">
                <Dialog.Title
                  as="h3"
                  className="px-4 pt-4 text-lg font-medium leading-6 text-gray-900 dark:text-white"
                >
                  Reset password
                </Dialog.Title>
                <button
                  type="button"
                  className="absolute right-4 top-4 text-gray-400 hover:text-gray-500 sm:right-4 sm:top-4"
                  onClick={() => resetState()}
                >
                  <span className="sr-only">Close</span>
                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </button>
                {showErrorMsg()}
                <div className="mt-2 p-4">
                  {renderPasswordBlock({
                    label: 'Current password',
                    id: 'current-password',
                    type:
                      passwordType.currentPasswordType === 'password'
                        ? 'password'
                        : 'text',
                    ref: passRef,
                    value: password.currentPassword,
                    onChange: (e: any) =>
                      setPassword({
                        ...password,
                        currentPassword: e.target.value,
                      }),
                    onClick: () =>
                      setPasswordType({
                        ...passwordType,
                        currentPasswordType:
                          passwordType.currentPasswordType !== 'password'
                            ? 'password'
                            : 'text',
                      }),
                  })}
                  {renderPasswordBlock({
                    label: 'New password',
                    id: 'new-password',
                    type:
                      passwordType.newPasswordType === 'password'
                        ? 'password'
                        : 'text',
                    value: password.newPassword,
                    onChange: (e: any) => {
                      setPassword({
                        ...password,
                        newPassword: e.target.value,
                      });
                      setPasswordErrorMsg('');
                    },
                    onClick: () =>
                      setPasswordType({
                        ...passwordType,
                        newPasswordType:
                          passwordType.newPasswordType !== 'password'
                            ? 'password'
                            : 'text',
                      }),
                    onBlur: () => setPasswordErrorMsg(getValidationErrors()),
                  })}
                  {renderPasswordBlock({
                    label: 'Confirm password',
                    id: 'confirm-password',
                    type:
                      passwordType.confirmPasswordType === 'password'
                        ? 'password'
                        : 'text',
                    value: password.confirmPassword,
                    onChange: (e: any) => {
                      setPassword({
                        ...password,
                        confirmPassword: e.target.value,
                      });
                      setPasswordErrorMsg('');
                    },
                    onClick: () =>
                      setPasswordType({
                        ...passwordType,
                        confirmPasswordType:
                          passwordType.confirmPasswordType !== 'password'
                            ? 'password'
                            : 'text',
                      }),
                    onBlur: () => setPasswordErrorMsg(getValidationErrors()),
                  })}
                </div>

                {renderFooterButtons()}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
