import { useReducer, useEffect, useMemo } from 'react';

import { FilterOptionsType } from '../types/usersListType';
import { MultiSelectInputType } from 'src/types/MultiSelectInputType';

export const initialState: FilterOptionsType = {
  keywordSearch: '',
  sortType: 'PID',
  sortOrder: 'asc',
  userName: '',
  firstName: '',
  lastName: '',
  cid: '',
  pid: '',
  permission: undefined,
  status: undefined,
};

export function filterReducer(
  state: FilterOptionsType,
  action: {
    type: keyof FilterOptionsType | 'reset' | 'preserve';
    value?: any;
    payload?: any;
    newState?: FilterOptionsType | undefined;
  },
) {
  switch (action.type) {
    case 'reset':
      return {
        ...action.value,
      };

    default:
      return {
        ...state,
        [action.type]: action.value,
      };
  }
}

export function useUsersLogFilter(): {
  filterOptions: FilterOptionsType;
  filterCount: number;
  setValues: (type: keyof FilterOptionsType, value: string) => void;
  setPermission: (value: Array<MultiSelectInputType>) => void;
  setStatus: (value: Array<MultiSelectInputType>) => void;
  resetFilters: () => void;
  isFiltersApplied: boolean;
} {
  const [filterOptions, setFilterOptions] = useReducer(
    filterReducer,
    {
      ...JSON.parse(JSON.stringify(initialState)),
    },
    (initial: FilterOptionsType) => {
      const savedUsersLogFilterString =
        window.localStorage.getItem('UsersLogFilter');
      if (savedUsersLogFilterString) {
        const usersLogFilter: FilterOptionsType = JSON.parse(
          savedUsersLogFilterString,
        );
        return {
          ...usersLogFilter,
        };
      }
      return {
        ...initial,
      };
    },
  );

  //for filtercount
  const filterCount = useMemo(() => {
    let count: number = 0;
    const obj1: any = { ...initialState };
    const obj2: any = {
      ...JSON.parse(JSON.stringify(filterOptions)),
      status:
        !filterOptions?.status ||
        filterOptions?.status?.length === 0 ||
        (filterOptions?.status?.length > 0 &&
          filterOptions?.status?.find(
            (item: MultiSelectInputType) => item.value === 0,
          ))
          ? undefined
          : filterOptions?.status,
      permission:
        !filterOptions?.permission ||
        filterOptions?.permission.length === 0 ||
        (filterOptions?.permission.length > 0 &&
          filterOptions?.permission.find(
            (item: MultiSelectInputType) => item.value === 0,
          ))
          ? undefined
          : filterOptions?.permission,
    };

    delete obj1['keywordSearch'];
    delete obj1['sortOrder'];
    delete obj1['sortType'];

    Object.keys(obj1).forEach(key => {
      if (obj1[key] !== obj2[key]) {
        count++;
      }
    });
    return count;
  }, [filterOptions]);

  function setValues(type: keyof FilterOptionsType, value: string) {
    setFilterOptions({
      type,
      value,
    });
  }

  function setPermission(value: Array<MultiSelectInputType>) {
    setFilterOptions({
      type: 'permission',
      value,
    });
  }

  function setStatus(value: Array<MultiSelectInputType>) {
    setFilterOptions({
      type: 'status',
      value,
    });
  }

  useEffect(() => {
    window.localStorage.setItem(
      'UsersLogFilter',
      JSON.stringify(filterOptions),
    );
  }, [filterOptions]);

  function resetFilters() {
    setFilterOptions({
      type: 'reset',
      value: {
        ...JSON.parse(JSON.stringify(initialState)),
      },
    });
  }

  const isFiltersApplied: boolean = useMemo(() => {
    const obj1: Partial<FilterOptionsType> = {
      ...initialState,
    };
    const obj2: Partial<FilterOptionsType> = {
      ...JSON.parse(JSON.stringify(filterOptions)),
    };

    delete obj1['sortOrder'];
    let diff = false;
    (Object.keys(obj1) as Array<keyof FilterOptionsType>).forEach(
      (value: keyof FilterOptionsType) => {
        if (obj1[value] !== obj2[value]) {
          diff = true;
        }
      },
    );
    return diff;
  }, [filterOptions]);

  return {
    filterOptions,
    filterCount,
    setValues,
    setPermission,
    setStatus,
    resetFilters,
    isFiltersApplied,
  };
}
