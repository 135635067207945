import { useMutation, UseMutationResult, useQueryClient } from 'react-query';

import { useAuth } from 'src/hooks';

import { saveExtracoverage, getExtraCoverageDetails } from 'src/utils/urls';

import {
  ExtraCoverageRequestType,
  ExtraCoverageResponseType,
} from '../types/saveExtraCoverageRequest';

async function saveExtraCoverageRequest({
  token,
  tokenType,
  params,
  action,
}: TArguments): Promise<TResult> {
  let id = params?.Id;
  let url = getExtraCoverageDetails(id);
  let method = 'PUT';

  if (action === '' || action === 'Copy' || action === undefined) {
    url = saveExtracoverage;
    method = 'POST';
  }
  const response = await fetch(url, {
    method: method,
    headers: {
      Authorization: `${tokenType} ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(params),
  });
  return response;
}

type TResult = ExtraCoverageResponseType | any;

export type TError = { Message: string };

export type TVariables = {
  params: ExtraCoverageRequestType;
  action?: string;
};

export type TArguments = {
  token: string;
  tokenType: string;
  params: ExtraCoverageRequestType;
  action?: string | undefined;
};

function useCreateExtraCoverageRequest(): UseMutationResult<
  TResult,
  TError,
  TVariables,
  unknown
> {
  const { token, tokenType } = useAuth();
  const queryClient = useQueryClient();
  return useMutation(async (inputValues: TVariables) => {
    const result = await saveExtraCoverageRequest({
      token,
      tokenType,
      params: inputValues?.params,
      action: inputValues?.action,
    });
    queryClient.invalidateQueries(['get_extra_coverage_requests']);
    if (result.status === 400 || result.status === 500) {
      const message = await result.json();
      throw message;
    }
    return result.json();
  });
}
export { useCreateExtraCoverageRequest };
