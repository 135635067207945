import { create } from 'zustand';
import produce from 'immer';

import { fetchPortalAlert } from './apiStore';

import { formatDateToRange } from 'src/utils/datepickerHelper';
import { initialState } from '../utils/constants';
import { isTinymceEditorContentEmpty } from 'src/utils/common';

import { AlertStoreType, PortalAlertObjType } from '../types/storeTypes';

export const usePortalAlertStore = create<AlertStoreType>((set, get) => ({
  isLoading: false,
  userMode: 'Create Portal Alerts',
  PortalAlertObj: initialState,

  setUserMode: value => {
    set(
      produce(state => {
        state.userMode = value;
      }),
    );
  },

  updatePortalAlert: (key, value) => {
    set(
      produce(state => {
        state.PortalAlertObj = { ...state.PortalAlertObj, [key]: value };
      }),
    );
  },

  getPortalAlert: (token, tokenType, id) => {
    set(
      produce(state => {
        state.isLoading = true;
      }),
    );

    fetchPortalAlert({ token, tokenType, id }).then(alertInfo => {
      set(
        produce(state => {
          state.PortalAlertObj = alertInfo;
          state.isLoading = false;
          state.PortalAlertObj.EnteredDate = formatDateToRange(
            alertInfo?.EffectiveDate,
            alertInfo.ExpirationDate,
          );
        }),
      );
    });
  },

  resetStore: () => {
    set(
      produce(state => {
        state.PortalAlertObj = {
          Title: '',
          Message: '',
          EnteredDate: '',
          ExpirationDate: '',
          EffectiveDate: '',
          Id: 0,
        };
      }),
    );
  },

  disableSave: () => {
    const portalAlert: PortalAlertObjType = get().PortalAlertObj;
    return (
      portalAlert.Title === '' ||
      portalAlert.Message === '' ||
      isTinymceEditorContentEmpty(portalAlert.Message) ||
      portalAlert.EnteredDate === '' ||
      portalAlert.EnteredDate === null
    );
  },
}));
