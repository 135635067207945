import React from 'react';
import TimePicker from 'react-time-picker';

import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';

import { inputBaseClasses } from '../TextField';

type TimePickerPropType = {
  value: any;
  onChange: (e: any) => void;
};

function ReactTimePicker({ value, onChange }: TimePickerPropType) {
  return (
    <TimePicker
      value={value}
      onChange={onChange}
      format="hh:mm a"
      hourPlaceholder="hh"
      minutePlaceholder="mm"
      disableClock={true}
      className={inputBaseClasses}
    />
  );
}

export { ReactTimePicker };
