import {
  fieldConfiguration,
  getResourceFields,
  getSearchGroup,
} from 'src/utils/urls';

import { ResourceList } from '../types/resourceList';

import {
  GetConfigArguments,
  ResourceField,
  SearchGroup,
} from '../types/fieldConfigurationStoreTypes';

// get the details of provider
async function fetchProviderFieldConfigurations({
  token,
  tokenType,
  providerId,
}: GetConfigArguments): Promise<ResourceList | undefined> {
  if (providerId) {
    const response = await fetch(fieldConfiguration(providerId), {
      method: 'GET',
      headers: {
        Authorization: `${tokenType} ${token}`,
      },
    });
    return response.json();
  }
}

// get the resource fields list
async function fetchResourceFields({
  token,
  tokenType,
  providerId,
}: GetConfigArguments): Promise<Array<ResourceField> | undefined> {
  if (providerId) {
    const response = await fetch(getResourceFields(providerId), {
      method: 'GET',
      headers: {
        Authorization: `${tokenType} ${token}`,
      },
    });
    return response.json();
  }
}

// get the categories of search group
async function fetchSearchGroups({
  token,
  tokenType,
}: Pick<GetConfigArguments, 'token' | 'tokenType'>): Promise<
  Array<SearchGroup> | undefined
> {
  const response = await fetch(getSearchGroup(), {
    method: 'GET',
    headers: {
      Authorization: `${tokenType} ${token}`,
    },
  });
  return response.json();
}

export {
  fetchProviderFieldConfigurations,
  fetchResourceFields,
  fetchSearchGroups,
};
