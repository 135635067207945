import { useMutation, UseMutationResult, useQueryClient } from 'react-query';

import { getNewUser } from 'src/utils/urls';

import { createNewUser, NewUserReturnType } from '../types/createNewUser';
import { useAuth } from 'src/hooks';

async function saveUserRequest({
  token,
  tokenType,
  params,
}: TArguments): Promise<TResult> {
  const response = await fetch(getNewUser, {
    method: 'POST',
    headers: {
      Authorization: `${tokenType} ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(params),
  });
  return response;
}

type TResult = NewUserReturnType | any;

export type TError = { ErrorMessage: string };

export type TVariables = createNewUser;

export type TArguments = {
  token: string;
  tokenType: string;
  params: createNewUser;
};

function useSaveUserDetails(): UseMutationResult<
  TResult,
  TError,
  TVariables,
  unknown
> {
  const queryClient: any = useQueryClient();
  const { token, tokenType } = useAuth();
  return useMutation(async (params: TVariables) => {
    const result = await saveUserRequest({ token, tokenType, params });
    if (result.status === 400) {
      const message = await result.json();
      throw {
        ErrorMessage: message,
      };
    }
    queryClient.invalidateQueries(['get_users_list']);
    return result.json();
  });
}
export { useSaveUserDetails };
