import React, { forwardRef } from 'react';
import { ExclamationTriangleIcon } from '@heroicons/react/24/solid';

import { useGetDashboardAlerts } from '../api';

import { createMarkup } from 'src/utils/common';

import { PortalAlertsType } from '../types/homeSectionType';

const PortalAlerts = forwardRef<HTMLDivElement>(({ ...rest }, ref) => {
  const { data: alertList, isLoading } = useGetDashboardAlerts();

  function renderAlertList(item: PortalAlertsType, index: number) {
    return (
      <div
        key={`${item?.Id}${index}`}
        className="flex gap-4 px-4 py-4 !text-sm"
        ref={ref}
        {...rest}
      >
        <ExclamationTriangleIcon className=" h-6 w-6 shrink-0 text-yellow-600 dark:text-yellow-500" />
        <div>
          <h4 className="prevent-text-breakout font-semibold leading-snug ">
            {item?.Title}
          </h4>
          <p
            className="prevent-text-breakout prose max-w-full leading-snug dark:prose-invert dark:text-white"
            dangerouslySetInnerHTML={createMarkup(item?.Message || '')}
          ></p>
        </div>
      </div>
    );
  }

  return (
    <>
      {!isLoading && alertList?.length ? (
        <div
          ref={ref}
          id="dash-alerts"
          className="max-h-[55%] shrink-0 overflow-hidden rounded-lg border border-yellow-500 dark:border-yellow-600"
        >
          <div className="h-full overflow-y-auto rounded-lg bg-gradient-to-tl from-yellow-50 to-white shadow dark:from-neutral-800 dark:to-neutral-800">
            <div className="divide-y divide-yellow-600/30 dark:divide-white/10">
              {alertList?.map((item: PortalAlertsType, index: number) => {
                return renderAlertList(item, index);
              })}
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
});

PortalAlerts.displayName = 'PortalAlerts';

export { PortalAlerts };
