import React from 'react';

import { optionType, CountryObj } from 'src/types/countryType';

export function optionList(countryList: Array<CountryObj>) {
  let options: Array<optionType> | undefined = [];
  options = countryList?.map((country: any) => ({
    value: country?.Id,
    label: (
      <div className="flex gap-x-2">
        <img
          alt="United States"
          src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${country?.Abbreviation}.svg`}
          style={{ width: '24px', height: 'auto' }}
        />
        <span className="text-left text-sm">
          ({country?.ISDCode}) {country.Name}
        </span>
      </div>
    ),
    isdCode: country?.ISDCode,
    abbreviation: country?.Abbreviation,
    name: country?.Name,
  }));
  return options;
}

//getting the value for dropdown
export function getCountryObj(countryId: any, countryList: any) {
  if (countryList) {
    const country = countryList?.find((item: any) => {
      if (item?.Id === parseInt(countryId)) return item;
    });

    if (country) {
      const options = {
        value: country?.Id,
        label: (
          <div className="flex gap-x-2">
            <img
              alt="United States"
              src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${country?.Abbreviation}.svg`}
              style={{ width: '24px', height: 'auto' }}
            />

            <span className="dark:text-white">{country?.ISDCode}</span>
          </div>
        ),
        isdCode: country?.ISDCode,
        abbreviation: country?.Abbreviation,
        name: country?.Name,
      };

      return options;
    } else {
      return {};
    }
  }
}
