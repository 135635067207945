import React, { ReactElement, useCallback, useState, useMemo } from 'react';
import dayjs from 'dayjs';
import { Disclosure } from '@headlessui/react';
import { ArrowPathIcon, ChevronDownIcon } from '@heroicons/react/20/solid';

import {
  TextField,
  Checkbox,
  Alert,
  inputLabelClasses,
  ReactTable,
  DateRangePicker,
  Button,
  Spinner,
} from 'src/ui/components';
import { AddressInformation } from './AddressInformation';
import { ContactInformation } from './ContactInformation';
import { PersonDetails } from './PersonDetails';

import { useAuth } from 'src/hooks';
import { useFindPeople, useGetPersonAlertsReason } from '../../api';
import usePersonSearchTableColumns from './usePersonSearchTableColumns';

import { sortItem } from '../../utils/reactTableHelper';
import { isPhoneNumberOrNot } from '../../utils/personInformationHelper';
import {
  personInfoWarning,
  noAccountWarning,
  gridElements,
} from '../../utils/addEditPersonAlertConstant';
import { usePersonAlertStore } from '../../store/personAlertStore';

import { PersonAlertsReasonType } from '../../types/personAlertsAccounts';
import { gridItemType } from '../../types/personAlertUser';
import { TextFieldType } from 'src/types/textFileType';

type RefType = {
  refValue: any;
};

function PersonInformation({ refValue }: RefType): ReactElement {
  const updateAlertDetails = usePersonAlertStore(
    useCallback(state => state.updateAlertDetails, []),
  );

  const alertDetails = usePersonAlertStore(
    useCallback(state => state?.alertDetails, []),
  );

  const accountId = usePersonAlertStore(
    useCallback(state => state?.accountsInfo?.Id, []),
  );

  const accountsInfo = usePersonAlertStore(
    React.useCallback(state => state.accountsInfo, []),
  );

  const userMode = usePersonAlertStore(
    useCallback(state => state.userMode, []),
  );

  const updatePersonInformation = usePersonAlertStore(
    useCallback(state => state.updatePersonInformation, []),
  );

  const updatePersonDetails = usePersonAlertStore(
    useCallback(state => state.updatePersonDetails, []),
  );

  const updateOtherDetails = usePersonAlertStore(
    useCallback(state => state.updateOtherDetails, []),
  );

  const deletePersonDetails = usePersonAlertStore(
    useCallback(state => state?.deletePersonDetails, []),
  );

  const isSaveClicked = usePersonAlertStore(
    useCallback(state => state.isSaveClicked, []),
  );

  const enableAlertTabs = usePersonAlertStore(
    useCallback(state => state.enableAlertTabs, []),
  );

  const isPersonInformationLoading = usePersonAlertStore(
    useCallback(state => state?.isPersonInformationLoading, []),
  );

  const newPerson = usePersonAlertStore(
    useCallback(state => state?.newPerson, []),
  );

  const setNewPerson = usePersonAlertStore(
    useCallback(state => state?.setNewPerson, []),
  );

  const [currentSortItem, setCurrentSortItem] = useState(sortItem);
  const [searchPerson, setSearchPerson] = useState<string>('');
  const [selectedRowId, setSelectedRowId] = useState<number>();
  const [showTable, setShowTable] = useState<boolean>(false);

  //api calls
  const { token, tokenType } = useAuth();
  const { data: personAlertReasons } = useGetPersonAlertsReason();
  const { data, isLoading, hasNextPage, fetchNextPage } = useFindPeople({
    currentSortItem,
    accountId,
    searchPerson,
  });

  const findPeople = useMemo(() => {
    if (data) {
      return data?.pages?.map(page => page?.data).flat();
    } else return [];
  }, [data]);

  const columns = usePersonSearchTableColumns(undefined);

  function onEndReached() {
    if (hasNextPage) {
      fetchNextPage();
    }
  }

  const handleSortChange = useCallback(
    (item: string): void => {
      let sortOrder = 'asc';
      if (
        currentSortItem.sortKey === item &&
        currentSortItem.sortOrder === 'asc'
      ) {
        sortOrder = 'desc';
      }
      setCurrentSortItem({
        sortKey: item,
        sortOrder,
      });
    },
    [setCurrentSortItem, currentSortItem],
  );

  function handleRowClick(item: any) {
    updatePersonInformation(token, tokenType, accountId, item?.original?.Id);
    setSelectedRowId(item?.original?.Id);
    setNewPerson(false);
  }

  //function for checkbox change
  function onCheckBoxChange(checkValue: boolean, item: PersonAlertsReasonType) {
    let tempReason: Array<PersonAlertsReasonType> = alertDetails?.Reasons
      ? [...alertDetails?.Reasons]
      : [];
    const index = tempReason?.findIndex(
      singleVal => singleVal?.Id === item?.Id,
    );
    if (index === -1 && checkValue === true) {
      tempReason?.push({
        ...item,
      });
    } else {
      tempReason?.splice(index, 1);
    }
    updateAlertDetails('Reasons', tempReason);
  }

  function checkBoxValue(item: PersonAlertsReasonType) {
    let tempReason: Array<PersonAlertsReasonType> = alertDetails?.Reasons;
    const indx = tempReason?.findIndex(
      singleEntry => singleEntry?.Id === item?.Id,
    );
    if (indx === -1) return false;
    else return true;
  }

  //setting the phone number and callername when new person check box is checked
  function setCallerNames() {
    if (searchPerson !== '' && !newPerson) {
      const searchString = searchPerson.replace(/\s/g, '');
      if (
        /^[0-9\-]+$/i.test(searchString) ||
        isPhoneNumberOrNot(searchString)
      ) {
        updateOtherDetails('Number', searchString, 'PrimaryPhone');
      } else {
        const tempCaller = searchPerson?.trimStart()?.split(/(?<=^\S+)\s/);
        const firstName = tempCaller && tempCaller[0];
        let lastName = '';
        if (tempCaller && tempCaller[1] !== undefined)
          lastName = tempCaller[1]?.trimEnd();

        updatePersonDetails('FirstName', firstName);
        updatePersonDetails('LastName', lastName);
      }
    }
  }

  function renderDatePicker({
    id,
    title,
    placeholder,
    key,
    value,
    onChangeDate,
    disabled,
  }: {
    id: string;
    title: string;
    placeholder?: string;
    key?: string;
    value?: any;
    onChangeDate?: (key: string, val: string) => void;
    disabled?: boolean;
  }): ReactElement {
    return (
      <DateRangePicker
        rangeType="future"
        format="MM/DD/YYYY HH:mm:ss"
        placeholder={placeholder}
        value={value?.length ? value : []}
        setValues={onChangeDate}
        keyValue={key}
        disabled={disabled}
        title={title}
        id={id}
        refferenceClass={refValue}
      />
    );
  }

  function renderTextField({
    id,
    onChangeText,
    value,
    key,
    label,
    type,
    className,
    disabled,
    required,
  }: TextFieldType): ReactElement {
    return (
      <TextField
        id={id}
        label={label}
        type={type}
        className={className}
        disabled={disabled}
        required={required}
        value={value}
        onChange={e => {
          onChangeText && key && onChangeText(key, e.target.value, '');
        }}
      />
    );
  }

  function gridItemValue(label: string): string | any {
    switch (label) {
      case 'First name':
        return alertDetails?.Person?.FirstName;
      case 'Last name':
        return alertDetails?.Person?.LastName;
      case 'Date of Birth':
        return alertDetails?.Person?.PersonAlertDetails?.Birthdate
          ? dayjs(alertDetails?.Person?.PersonAlertDetails?.Birthdate).format(
              'MM/DD/YYYY',
            )
          : '';
      default:
        return '';
    }
  }
  function renderBasicDetailsGridItems(): ReactElement {
    return (
      <>
        {gridElements?.map((item: gridItemType, index: number) => {
          return (
            <div
              key={`${item.id + index}`}
              className="sm:max-w-[20ch] lg:max-w-[30ch]"
            >
              <label className="block text-xs font-bold">{item?.label}</label>
              <p
                className="truncate text-sm opacity-75"
                title={gridItemValue(item?.label)}
              >
                {gridItemValue(item?.label) !== '' &&
                gridItemValue(item?.label) !== undefined
                  ? gridItemValue(item?.label)
                  : '-'}
              </p>
            </div>
          );
        })}
      </>
    );
  }

  function renderHeading(open: boolean): ReactElement {
    return (
      <Disclosure.Button className="flex w-full items-center justify-between rounded-lg bg-white px-4 py-2 text-left shadow-sm hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-light-focus ui-open:rounded-b-none dark:bg-neutral-800 dark:hover:bg-neutral-800/70">
        <div className="flex grow flex-wrap items-center gap-x-8 gap-y-2  pr-2">
          <div className="grow">
            <div className="flex items-center gap-2">
              {isSaveClicked && !enableAlertTabs('personInformation-tab') && (
                <div className="h-3 w-3">
                  <span className="relative flex h-3 w-3 items-center justify-center">
                    <span className="absolute inline-flex h-full w-full animate-ping rounded-full bg-red-400 opacity-75" />
                    <span className="relative inline-flex h-2 w-2 rounded-full bg-red-500" />
                  </span>
                </div>
              )}
              <h3 className="font-medium after:ml-0.5 after:text-red-400 after:content-['*']">
                Person information
              </h3>
            </div>
          </div>

          <div className="hidden gap-2 ui-open:hidden sm:flex sm:ui-open:hidden">
            {/* <div className="h-3 w-3" aria-hidden="true" /> */}
            <div className="flex gap-8">{renderBasicDetailsGridItems()}</div>
          </div>
        </div>
        <ChevronDownIcon
          className={`${
            open ? 'rotate-180 transform' : ''
          } h-5 w-5 text-gray-500 dark:text-neutral-300`}
        />
      </Disclosure.Button>
    );
  }

  return (
    <Disclosure as="div" id="person-info" className="scroll-m-4">
      {({ open }) => (
        <>
          {renderHeading(open)}

          <Disclosure.Panel className="space-y-10 divide-light-light rounded-b-lg border-t bg-white p-4 text-sm text-gray-500 shadow dark:divide-dark-medium dark:border-neutral-700/50 dark:bg-neutral-800 md:divide-y">
            <Alert variant="warning" title="Warning">
              {personInfoWarning}
            </Alert>
            <div className="relative grid grid-cols-1 gap-4 border-none sm:grid-cols-2 sm:gap-y-2 md:grid-cols-3">
              {userMode === 'Edit' && (
                <>
                  {renderTextField({
                    id: 'alert-id',
                    label: 'Alert ID',
                    value: alertDetails?.Id,
                    disabled: true,
                  })}

                  {renderTextField({
                    id: 'enteredby',
                    label: 'Entered by',
                    value: alertDetails?.EnteredBy,
                    disabled: true,
                  })}
                  <div>
                    <label
                      htmlFor=""
                      className={[inputLabelClasses, 'mb-0.5'].join(' ')}
                    >
                      Entered date
                    </label>
                    {renderDatePicker({
                      id: 'datePicker',
                      title: 'Entered date',
                      placeholder: '',
                      value: dayjs
                        .utc(alertDetails?.EnteredDate)
                        .local()
                        .format('MM/DD/YYYY HH:mm:ss'),
                      disabled: true,
                    })}
                  </div>
                </>
              )}
              <div>
                <label
                  htmlFor=""
                  className={[
                    inputLabelClasses,
                    'mb-1 after:ml-0.5 after:text-red-500 after:content-["*"] dark:after:text-red-400',
                  ].join(' ')}
                >
                  Start date - End date
                </label>
                {renderDatePicker({
                  id: 'datePicker',
                  title: 'Start date- End date',
                  key: 'alertDate',
                  placeholder: '',
                  value: alertDetails?.alertDate,
                  onChangeDate: updateAlertDetails,
                })}
              </div>

              <div className="sm:col-span-2">
                <label
                  htmlFor=""
                  className={[inputLabelClasses, 'mb-1 sm:mb-2.5'].join(' ')}
                >
                  Reason for alert
                </label>
                <div className="flex flex-wrap gap-x-5 gap-y-3">
                  {personAlertReasons?.map((item, index) => {
                    return (
                      <Checkbox
                        key={`${item?.Id}${index}`}
                        id={item?.Description}
                        label={item?.Description}
                        onChange={e => {
                          onCheckBoxChange(e?.currentTarget?.checked, item);
                        }}
                        checked={checkBoxValue(item)}
                      />
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="space-y-3 md:pt-6">
              <div className="flex items-center justify-start gap-4 pb-1">
                <h3 className="text-lg font-medium text-black dark:text-white">
                  Personal details
                </h3>
                {userMode !== 'Edit' && (
                  <Button
                    variant="secondary"
                    size="small"
                    onClick={() => {
                      setSearchPerson('');
                      setSelectedRowId(-1);
                      setCurrentSortItem(sortItem);
                      setNewPerson(false);
                      deletePersonDetails();
                      setShowTable(true);
                    }}
                  >
                    <ArrowPathIcon
                      className="-ml-0.5 h-5 w-5"
                      aria-hidden="true"
                    />
                    <span className="hidden sm:block">Reset</span>
                  </Button>
                )}
              </div>
              {accountsInfo === undefined && (
                <Alert variant="info" className="mb-3">
                  {noAccountWarning}
                </Alert>
              )}

              {userMode !== 'Edit' && (
                <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3">
                  <TextField
                    id="search-person"
                    placeholder="Search"
                    type="search"
                    value={searchPerson}
                    onChange={e => {
                      setSearchPerson(e?.target?.value);
                      setShowTable(false);
                    }}
                    maxLength={400}
                  />
                  <Checkbox
                    id="check"
                    label="New person"
                    checked={newPerson}
                    onChange={e => {
                      setNewPerson(e?.currentTarget?.checked);
                      deletePersonDetails();
                      setCallerNames();
                      setSelectedRowId(-1);
                      setShowTable(true);
                    }}
                    className="self-center"
                  />
                </div>
              )}
              {searchPerson?.length > 0 && !showTable && (
                <div className="max-w-full scroll-smooth">
                  <ReactTable
                    className="max-h-80 max-w-full overflow-x-auto"
                    data={findPeople}
                    columns={columns}
                    onRowClick={handleRowClick}
                    handleSortChange={handleSortChange}
                    isLoading={isLoading}
                    onEndReached={onEndReached}
                    sortedItem={currentSortItem}
                    selectedRowId={selectedRowId}
                  />
                </div>
              )}
              {isPersonInformationLoading && (
                <div className="relative grid h-48 place-content-center">
                  <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
                    <Spinner size="large" />
                  </div>
                </div>
              )}
              {(newPerson ||
                userMode === 'Edit' ||
                (selectedRowId && selectedRowId !== -1)) &&
                !isPersonInformationLoading && (
                  <PersonDetails newPerson={newPerson} refValue={refValue} />
                )}
            </div>
            {(newPerson ||
              userMode === 'Edit' ||
              (selectedRowId && selectedRowId !== -1)) &&
              !isPersonInformationLoading && <AddressInformation />}
            {(newPerson ||
              userMode === 'Edit' ||
              (selectedRowId && selectedRowId !== -1)) &&
              !isPersonInformationLoading && <ContactInformation />}
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
export { PersonInformation };
