import { callDataCount, clinicalCallCount } from 'src/utils/urls';

import { ChartInfoArguments } from '../../types/apiStoreType';

async function fetchClinicalCallCount({
  token,
  tokenType,
  content,
}: Partial<ChartInfoArguments>): Promise<Array<any>> {
  const isCallData = content?.isCallData;
  delete content?.isCallData;
  const response = await fetch(isCallData ? callDataCount : clinicalCallCount, {
    method: 'POST',
    headers: {
      Authorization: `${tokenType} ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(content),
  });
  return response.json();
}

export { fetchClinicalCallCount };
