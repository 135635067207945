import React, {
  Fragment,
  ReactElement,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import {
  DocumentDuplicateIcon,
  InboxArrowDownIcon,
  NoSymbolIcon,
  PencilIcon,
  EnvelopeIcon,
  EyeIcon,
  EllipsisHorizontalIcon,
} from '@heroicons/react/20/solid';
import { Menu, Transition } from '@headlessui/react';

import { Button, ConfirmAlert } from 'src/ui/components';
import { RevokeAccess, ChangePassword, ChangeEmail } from './index';

import { useChangePassword, useCheckUserOnboardingStatus } from '../api';
import { useAuth } from 'src/hooks';

import { classNames } from 'src/utils/className';
import { showSuccessToast, showErrorToast } from 'src/utils/ToastNotification';
import {
  errorMessage,
  changeEmailConfirmationMsg,
  copyUserMsg,
  changeEmailSuccessMsg,
} from '../utils/userConstants';
import {
  passwordChangedSuccessMsg,
  passwordEmptyMsg,
  passwordMatchMsg,
} from 'src/utils/appConstants';
import { emailRegex } from 'src/utils/common';
import { useUserStore } from 'src/features/AddEditUsers/store/userStore';

import {
  UsersListType,
  userActionButtonsType,
  ConfirmModalType,
} from '../types/usersListType';

export type UserActionsType = {
  userData: UsersListType;
  adminPermissionId: number | undefined;
  refetchReinvite: any;
  onEmailChange: any;
  setSelectedUser: (val: UsersListType | undefined) => void;
};

function UserActions({
  userData,
  adminPermissionId,
  refetchReinvite,
  onEmailChange,
  setSelectedUser,
}: UserActionsType): ReactElement {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { userId } = useAuth();

  const [showDialogue, setShowDialogue] = useState(false);

  /*Change Password Modal*/
  const [showPasswordModal, setShowPasswordModal] = useState(false); //modal visibility
  const [password, setPassword] = useState({
    //password fields
    newPassword: '',
    confirmPassword: '',
  });
  const [passwordErrorMsg, setPasswordErrorMsg] = useState(''); // validations messages

  /*Change Email Modal*/
  const [showEmailModal, setShowEmailModal] = useState(false); //email modal visibility
  const [newEmail, setNewEmail] = useState('');
  const [emailErroMsg, setEmailErrorMsg] = useState(''); // validations messages
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [confirmationType, setConfirmationType] = useState('');

  const [showCopyDialogue, setShowCopyDialogue] = useState(false);
  const [dontShowAgain, setDontShowAgain] = useState(false);

  const [revokeAccessModal, setRevokeAccessModal] = useState(false);

  const { refetch: refetchOnBoarding } = useCheckUserOnboardingStatus(
    userData?.Id || '',
  );

  const { mutate: onPasswordChange, isLoading: isPasswordChangeLoading } =
    useChangePassword();

  const updateUserMode = useUserStore(
    useCallback(state => state.updateUserMode, []),
  );

  //for setting useronboarding response loading
  const setIsOnboardingCheckLoading = useUserStore(
    useCallback(state => state.setIsOnboardingCheckLoading, []),
  );

  const setSaveClicked = useUserStore(
    React.useCallback(state => state.setSaveClicked, []),
  );

  // Change password is visible only when logged-in user same as selected or selected user having active status,not an admin & not the logged-in user
  const isChangePasswordVisible = useMemo(() => {
    if (userData.Id === userId) return true;
    else
      return (
        userData?.PermissionIds &&
        !userData?.PermissionIds?.some(
          (x: number) => x === adminPermissionId,
        ) &&
        userData.Id !== userId &&
        userData?.Status === 'Active'
      );
  }, [
    adminPermissionId,
    userData.Id,
    userData?.PermissionIds,
    userData?.Status,
    userId,
  ]);

  const userActionButtons: Array<userActionButtonsType> = [
    {
      label: 'Edit user',
      icon: <PencilIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />,
      action: onEditUserHandler,
      isVisible: true,
    },
    {
      label: 'Revoke access',
      icon: <NoSymbolIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />,
      action: () => {
        setRevokeAccessModal(true);
      },
      isVisible: true,
    },
    {
      label: 'Re-invite',
      icon: (
        <InboxArrowDownIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
      ),
      isVisible: userData?.AllowReInvite,
      action: () => {
        refetchReinvite();
      },
    },
    {
      label: 'Change email',
      icon: <EnvelopeIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />,
      action: () => onChangeEmailModalHandler(),
      isVisible: true, // need to change once logged in user permission is added,
    },
    {
      label: 'Copy user',
      icon: (
        <DocumentDuplicateIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
      ),
      isVisible: true,
      action: () => onCopyHandler(),
    },
    {
      label: 'Change password',
      icon: <EyeIcon fill="currentColor" className="h-5 w-5" />,
      action: () => onChangePasswordModalHandler(),
      isVisible: isChangePasswordVisible, // need to change once logged in user permission is added
    },
  ];

  function onChangePasswordModalHandler() {
    if (userData.Id === userId) {
      window.open(`Settings/${userData?.Id}`, '_self');
    } else {
      //Open modal
      setShowPasswordModal(true);
    }
  }

  function onChangeEmailModalHandler() {
    setShowEmailModal(true);
  }

  function onEditUserHandler() {
    setSaveClicked(false);
    setIsOnboardingCheckLoading(true);
    setShowDialogue(false);
    queryClient.invalidateQueries(['create-edit-user'], {
      refetchActive: false,
    });

    refetchOnBoarding().then((result: any) => {
      setIsOnboardingCheckLoading(false);

      if (
        result &&
        result?.data === true &&
        userData.Status === 'Invitation sent'
      )
        setShowDialogue(true);
      else {
        updateUserMode('Edit');
        navigate(`/User/Edit/${userData?.Id}`);
      }
    });
  }

  function onUpdatePasswordHandler() {
    if (password?.newPassword?.length && password?.confirmPassword?.length) {
      if (password?.newPassword === password?.confirmPassword) {
        setPasswordErrorMsg('');
        const params = {
          UserId: userData.Id,
          Password: password.confirmPassword,
        };
        onPasswordChange(params, {
          onSuccess: () => {
            showSuccessToast({
              message: passwordChangedSuccessMsg,
            });
            setPassword({ newPassword: '', confirmPassword: '' });
            setShowPasswordModal(false);
          },
          onError: (error: any) => {
            setPasswordErrorMsg(error);
          },
        });
      } else {
        setPasswordErrorMsg(passwordMatchMsg);
      }
    } else {
      setPasswordErrorMsg(passwordEmptyMsg);
    }
  }

  function onCloseChangePasswordModal() {
    setShowPasswordModal(false);
    setPasswordErrorMsg('');
    setPassword({ newPassword: '', confirmPassword: '' });
  }

  function onUpdateEmailHandler() {
    if (newEmail.toLowerCase().match(emailRegex)) {
      setShowEmailModal(false);
      setShowConfirmation(true);
      setConfirmationType('confirmation');
    } else {
      setEmailErrorMsg('Please enter valid email address.');
    }
  }

  function onConfirmUpdateEmailHandler() {
    setConfirmationType('');
    const params = {
      UserId: userData.Id,
      NewEmailId: newEmail,
    };
    onEmailChange(params, {
      onSuccess: () => {
        setSelectedUser(undefined);
        showSuccessToast({
          message: changeEmailSuccessMsg,
        });
        setNewEmail('');
        setEmailErrorMsg('');
      },
      onError: (error: any) => {
        setNewEmail('');
        setEmailErrorMsg('');
        showErrorToast({
          message: typeof error === 'string' ? error : error[0],
        });
      },
    });
  }

  function onCloseEmailModal() {
    setNewEmail('');
    setShowEmailModal(false);
    setEmailErrorMsg('');
  }

  function onCopyHandler() {
    setSaveClicked(false);
    setDontShowAgain(false);
    const copyWarning = localStorage.getItem('SkipCopyWarning');
    if (copyWarning === null || copyWarning !== 'true') {
      setShowCopyDialogue(true);
    } else {
      updateUserMode('Copy');
      navigate(`/User/Copy/${userData?.Id}`);
    }
  }

  function onConfirmCopyHandler() {
    if (dontShowAgain) {
      localStorage.setItem('SkipCopyWarning', 'true');
    }
    updateUserMode('Copy');
    navigate(`/User/Copy/${userData?.Id}`);
    setShowCopyDialogue(false);
  }

  function onDontShowConfirmed() {
    setDontShowAgain(prevState => !prevState);
    if (!dontShowAgain) {
      localStorage.setItem('SkipCopyWarning', 'true');
    }
  }

  function renderActionButtons(): ReactElement {
    return (
      <>
        {userActionButtons.map(
          (btn: userActionButtonsType, index: number) =>
            btn.isVisible && (
              <Button
                variant="subtle"
                size="small"
                className="whitespace-nowrap"
                key={`${index + btn.label}`}
                onClick={btn.action}
              >
                {btn.icon}
                {btn.label}
              </Button>
            ),
        )}
      </>
    );
  }

  function renderChangePasswordModal(): ReactElement {
    return (
      <>
        {showPasswordModal && (
          <ChangePassword
            password={password}
            isPasswordChangeLoading={isPasswordChangeLoading}
            setPassword={setPassword}
            passwordErrorMsg={passwordErrorMsg}
            onCloseChangePasswordModal={onCloseChangePasswordModal}
            showPasswordModal={showPasswordModal}
            setShowPasswordModal={setShowPasswordModal}
            onUpdatePasswordHandler={onUpdatePasswordHandler}
          />
        )}
      </>
    );
  }

  function renderChangeEmailModal(): ReactElement {
    return (
      <>
        <ChangeEmail
          emailErroMsg={emailErroMsg}
          showEmailModal={showEmailModal}
          setShowEmailModal={setShowEmailModal}
          onCloseEmailModal={onCloseEmailModal}
          currentEmail={userData.Email}
          newEmail={newEmail}
          setNewEmail={setNewEmail}
          onUpdateEmailHandler={onUpdateEmailHandler}
        />
      </>
    );
  }

  function responsiveUserActions(): ReactElement {
    return (
      <>
        <Menu as="div" className="relative ml-3 md:hidden">
          <Menu.Button as={Button} variant="secondary" size="small">
            <EllipsisHorizontalIcon className="h-5 w-5" aria-hidden="true" />
          </Menu.Button>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-0 z-10 -mr-1 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none dark:bg-neutral-700">
              <>
                {userActionButtons.map(
                  (btn: userActionButtonsType, index: number) =>
                    btn?.isVisible && (
                      <Menu.Item key={`btn-${btn.label}-${index}`}>
                        {({ active }) => (
                          <a
                            className={classNames(
                              active ? 'bg-gray-100 dark:bg-neutral-600' : '',
                              'block cursor-pointer px-4 py-2 text-sm text-gray-800 dark:text-white',
                            )}
                            onClick={btn.action}
                          >
                            {btn.label}
                          </a>
                        )}
                      </Menu.Item>
                    ),
                )}
              </>
            </Menu.Items>
          </Transition>
        </Menu>
      </>
    );
  }

  function renderConfirmAlert({
    message,
    showModel,
    setShowModel,
    type,
    buttonSet,
    hasPreviewAgainCheck,
    previewAgain,
    setPreviewAgain,
  }: ConfirmModalType): ReactElement {
    return (
      <>
        <ConfirmAlert
          message={message}
          showModel={showModel}
          setShowModel={setShowModel}
          type={type}
          buttonSet={buttonSet}
          hasDontshowAgainCheck={hasPreviewAgainCheck}
          dontShowAgain={previewAgain}
          setDontShowAgain={setPreviewAgain}
        />
      </>
    );
  }

  return (
    <>
      <div className="relative hidden h-7 md:block">
        <div className="flex gap-x-4 gap-y-1 text-center">
          {renderActionButtons()}
        </div>
      </div>
      {responsiveUserActions()}

      {showDialogue &&
        renderConfirmAlert({
          message: errorMessage,
          showModel: showDialogue,
          setShowModel: setShowDialogue,
          type: 'warning',
          buttonSet: [
            {
              label: 'Ok',
              handleClick: () => setShowDialogue(false),
              variant: 'primary',
            },
          ],
        })}

      {confirmationType === 'confirmation' &&
        renderConfirmAlert({
          message: changeEmailConfirmationMsg,
          showModel: showConfirmation,
          setShowModel: setShowConfirmation,
          buttonSet: [
            {
              label: 'No',
              handleClick: () => {
                setShowConfirmation(false);
                setNewEmail('');
              },
              variant: 'secondary',
            },
            {
              label: 'Yes',
              handleClick: () => onConfirmUpdateEmailHandler(),
              variant: 'primary',
            },
          ],
        })}

      {/* Message show before copy redirection*/}
      {showCopyDialogue &&
        renderConfirmAlert({
          message: copyUserMsg,
          showModel: showCopyDialogue,
          setShowModel: setShowCopyDialogue,
          buttonSet: [
            {
              label: 'Cancel',
              handleClick: () => {
                setShowCopyDialogue(false);
              },
              variant: 'secondary',
            },
            {
              label: 'Ok',
              handleClick: () => onConfirmCopyHandler(),
              variant: 'primary',
            },
          ],
          hasPreviewAgainCheck: true,
          previewAgain: dontShowAgain,
          setPreviewAgain: () => onDontShowConfirmed(),
        })}

      {renderChangePasswordModal()}
      {renderChangeEmailModal()}

      <RevokeAccess
        userData={userData}
        revokeAccessModal={revokeAccessModal}
        setRevokeAccessModal={setRevokeAccessModal}
        setSelectedUser={setSelectedUser}
      />
    </>
  );
}

export { UserActions };
