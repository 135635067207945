import React, {
  ReactElement,
  useState,
  useCallback,
  useMemo,
  Fragment,
} from 'react';
import { Disclosure } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { useLocation } from 'react-router-dom';

import { Badge, ReactTable } from 'src/ui/components';

import { usePersonAlertsAccounts } from '../../api';
import useAccountInfoColumns from './useAccountInfoColumns';

import { usePersonAlertStore } from '../../store/personAlertStore';
import { noAccountWarning } from '../../utils/addEditPersonAlertConstant';

const sortItem = { sortKey: 'CID', sortOrder: 'asc' };

function AccountSelection(): ReactElement {
  const [columnsSearch, setColumnsSearch] = useState<Record<string, string>>(
    {},
  );
  const [currentSortItem, setCurrentSortItem] = useState(sortItem);
  const [isScroll, setIsScroll] = useState<boolean>(false);
  const [rowIndex, setRowIndex] = useState<number>();

  const userMode = usePersonAlertStore(
    useCallback(state => state.userMode, []),
  );

  const location = useLocation();

  const isEdit = useMemo(() => {
    return location.pathname.split('/').includes('Edit');
  }, [location.pathname]);

  const isSaveClicked = usePersonAlertStore(
    useCallback(state => state.isSaveClicked, []),
  );

  const enableAlertTabs = usePersonAlertStore(
    useCallback(state => state.enableAlertTabs, []),
  );

  const {
    data,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    isLoading: isAccountListLoading,
  } = usePersonAlertsAccounts({
    columnsSearch,
    currentSortItem,
  });

  const filteredData = useMemo(() => {
    if (data) {
      return data?.pages?.map(page => page?.data).flat();
    } else return [];
  }, [data]);

  const accountsInfo = usePersonAlertStore(
    React.useCallback(state => state.accountsInfo, []),
  );

  const updateAccountInfo = usePersonAlertStore(
    React.useCallback(state => state.updateAccountInfo, []),
  );

  //searching function callback
  const headerUpdateCallBack = useCallback(
    ({ columnId, value }: { columnId: string; value: string }) => {
      setColumnsSearch({
        ...columnsSearch,
        [columnId]: value,
      });
    },
    [columnsSearch],
  );

  //if filteres applied
  const isFilterApplied = useMemo(() => {
    if (
      currentSortItem?.sortKey === 'CID' &&
      currentSortItem?.sortOrder === 'asc' &&
      ((Object.keys(columnsSearch).length !== 0 &&
        Object.values(columnsSearch).some((x: any) => x === '')) ||
        Object.keys(columnsSearch).length === 0)
    ) {
      return false;
    } else return true;
  }, [columnsSearch, currentSortItem?.sortKey, currentSortItem?.sortOrder]);

  //sorting function callback
  const handleSortChange = useCallback(
    (item: string): void => {
      let sortOrder = 'asc';
      if (
        currentSortItem.sortKey === item &&
        currentSortItem.sortOrder === 'asc'
      ) {
        sortOrder = 'desc';
      }
      setCurrentSortItem({
        sortKey: item,
        sortOrder,
      });
    },
    [setCurrentSortItem, currentSortItem],
  );

  //React-table column list
  const columns = useAccountInfoColumns(setColumnsSearch, setCurrentSortItem);

  //react table row click
  function handleRowClick(item: any) {
    updateAccountInfo && updateAccountInfo(item?.original);
    setRowIndex(item?.index);
  }

  function onEndReached() {
    if (hasNextPage) {
      setIsScroll(true);
      fetchNextPage();
    } else if (!hasNextPage && isScroll) {
      setIsScroll(false);
    }
  }

  function renderAccountList() {
    return (
      <Fragment>
        <div className="flex grow flex-wrap items-center gap-x-4 gap-y-2  pr-2">
          {isSaveClicked && !enableAlertTabs('accounts-tab') && (
            <div className="h-3 w-3">
              <span className="relative flex h-3 w-3 items-center justify-center">
                <span className="absolute inline-flex h-full w-full animate-ping rounded-full bg-red-400 opacity-75" />
                <span className="relative inline-flex h-2 w-2 rounded-full bg-red-500" />
              </span>
            </div>
          )}
          <div className="grow">
            <h3 className="grow font-medium after:ml-0.5 after:text-red-500 after:content-['*'] dark:after:text-red-400">
              Select account
            </h3>
          </div>

          <div>
            {accountsInfo !== undefined ? (
              <div className="flex flex-wrap items-center gap-2 ">
                <label className="block text-xs font-bold">
                  Selected account
                </label>
                <Badge key={`${accountsInfo?.Id}`}>
                  {accountsInfo?.CID}
                  {accountsInfo.Name !== '' ? `-${accountsInfo?.Name}` : ''}
                </Badge>
              </div>
            ) : (
              !enableAlertTabs('accounts-tab') && (
                <p className="text-xs opacity-80">{noAccountWarning}</p>
              )
            )}
          </div>
        </div>
      </Fragment>
    );
  }

  function renderSelectedAccount() {
    return (
      <div
        tabIndex={0}
        className={`relative flex flex-wrap rounded bg-white shadow focus-visible:outline focus-visible:outline-[3px] focus-visible:outline-offset-[3px] focus-visible:outline-blue-600 dark:bg-neutral-800 dark:focus-visible:outline-blue-400`}
      >
        <div className="w-1/2 min-w-[92px] p-2 sm:grow md:w-1/4">
          <label className="block whitespace-nowrap text-[10px] font-medium uppercase tracking-wide opacity-70">
            Account name
          </label>
          <p className="mt-0.5 text-xs font-bold tracking-wide text-black dark:text-white">
            {accountsInfo?.Name}
          </p>
        </div>
        <div className="w-1/2 min-w-[135px] p-2 sm:grow md:w-1/4">
          <label className="block whitespace-nowrap text-[10px] font-medium uppercase tracking-wide opacity-70">
            CID
          </label>
          <p className="mt-0.5 text-xs font-bold tracking-wide text-black dark:text-white">
            {accountsInfo?.CID}
          </p>
        </div>
        <div className="w-1/2 min-w-[135px] p-2 sm:grow md:w-1/4">
          <label className="block whitespace-nowrap text-[10px] font-medium uppercase tracking-wide opacity-70">
            SID
          </label>
          <p className="mt-0.5 text-xs font-bold tracking-wide text-black dark:text-white">
            {accountsInfo?.SID}
          </p>
        </div>
        <div className="w-1/2 min-w-[155px] p-2 sm:grow md:w-1/4">
          <label className="block whitespace-nowrap text-[10px] font-medium uppercase tracking-wide opacity-70">
            PID
          </label>
          <p className="mt-0.5 text-xs font-bold tracking-wide text-black dark:text-white">
            {accountsInfo?.PID}
          </p>
        </div>
      </div>
    );
  }

  return (
    <Disclosure
      as="div"
      id="account-info"
      defaultOpen={true}
      className="scroll-m-4"
    >
      {({ open }) => (
        <>
          <Disclosure.Button className="flex w-full items-center justify-between rounded-lg bg-white px-4 py-2 text-left shadow-sm hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-light-focus disabled:pointer-events-none disabled:cursor-not-allowed disabled:opacity-50 ui-open:rounded-b-none dark:bg-neutral-800  dark:hover:bg-neutral-800/70">
            {renderAccountList()}
            <ChevronDownIcon
              className={`${
                open ? 'rotate-180 transform' : ''
              } h-5 w-5 text-gray-500`}
            />
          </Disclosure.Button>

          <Disclosure.Panel
            className={`rounded-b-lg border-t bg-gray-50 text-sm text-gray-500 shadow dark:border-neutral-700/50  dark:bg-neutral-800 ${
              userMode === 'Edit' ? ' ' : 'ui-open:min-h-[300px]'
            }`}
          >
            <div className="max-w-full scroll-smooth">
              {userMode === 'Edit' && accountsInfo !== undefined ? (
                <div className="rounded-b-lg bg-gray-100 p-3 dark:bg-neutral-900 ">
                  {renderSelectedAccount()}
                </div>
              ) : (
                <ReactTable
                  columns={columns ? columns : []}
                  data={filteredData ? filteredData : []}
                  columnsSearch={columnsSearch}
                  headerUpdateCallBack={headerUpdateCallBack}
                  handleSortChange={handleSortChange}
                  sortedItem={currentSortItem}
                  isLoading={isAccountListLoading}
                  isFetchingNextPage={isFetchingNextPage}
                  onRowClick={handleRowClick}
                  onEndReached={onEndReached}
                  className="max-h-72 max-w-full overflow-x-auto"
                  selectedRowId={accountsInfo?.Id}
                  rowIndex={rowIndex}
                />
              )}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}

export { AccountSelection };
