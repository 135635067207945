import { useQuery, UseQueryResult } from 'react-query';

import { useAuth } from 'src/hooks';
import { getPersonAlertsPreview } from 'src/utils/urls';

import { PersonAlertsAccountsDetails } from 'src/features/AddEditPersonAlert/types/personAlertsAccounts';

async function fetchPersonAlertPreview({
  token,
  tokenType,
  accountId,
  alertId,
}: TVariables): Promise<TResult> {
  const response = await fetch(getPersonAlertsPreview(accountId, alertId), {
    method: 'GET',
    headers: {
      Authorization: `${tokenType} ${token}`,
    },
  });
  return response.json();
}

export type TResult = PersonAlertsAccountsDetails;

export type TVariables = {
  token: string;
  tokenType: string;
  accountId: number;
  alertId: number;
};

export type TError = { message: string };

function useGetPersonAlertPreview(
  accountId: number | undefined,
  alertId: number | undefined,
  isEnable: boolean,
): UseQueryResult<TResult, TError> {
  const { token, tokenType } = useAuth();

  return useQuery(
    ['get_person_alert_preview', accountId, alertId],
    async () => {
      if (accountId && alertId) {
        const result = await fetchPersonAlertPreview({
          token,
          tokenType,
          accountId,
          alertId,
        });
        return result;
      }
    },
    {
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      enabled: isEnable,
    },
  );
}

export { useGetPersonAlertPreview };
