import { gridItemType } from '../types/personAlertUser';

const sortOptions = [
  'Id',
  'First name',
  'Last name',
  'Alternate name',
  'Primary phone',
  'Secondary phone',
  'Dob',
  'City',
  'Ranking',
];

const personAlertSortOptions = ['CID', 'PID', 'SID', 'AccountName'];

const personInfoWarning: string = `Please note changes made in the person information section will
change information that ${
  process.env.REACT_APP_THEME === 'proteus' ? `Protocall` : `LinesForLife`
} has on file for the person.`;

const personAlertTabs = ['accounts-tab', 'personInformation-tab', 'alert-tab'];

const tabSections = [
  { message: 'Select account', link: '#account-info' },
  { message: 'Person information', link: '#person-info' },
  { message: 'Alert information', link: '#alert-info' },
];

const noAccountWarning: string = 'Please select an account.';

const gridElements: Array<gridItemType> = [
  {
    id: 'first-name',
    label: 'First name',
    key: 'firstName',
  },
  {
    id: 'last-name',
    label: 'Last name',
    key: 'lastName',
  },
  {
    id: 'dob',
    label: 'Date of Birth',
    key: 'dob',
  },
];

export {
  sortOptions,
  personAlertSortOptions,
  personInfoWarning,
  personAlertTabs,
  tabSections,
  noAccountWarning,
  gridElements,
};
