import dayjs from 'dayjs';

import { Timezone } from 'src/types/timezone';
import { AccountData } from '../types/requestType';
import {
  formatToUtcWithOffset,
  getOffset,
  compareDates,
} from 'src/utils/datepickerHelper';
import { isNullOrUndefined } from 'src/utils/common';
import { approveStatusId } from './coverageRequestConstants';

// to return selected option object
function getSelectedOptionInfo(list: any, id: any): any {
  if (id && list) {
    const val = list?.find((x: any) => x.Id === parseInt(id));
    if (val) return val;
  }
}

function disabledReason(
  coverageRequestDet: Record<any, any>,
  errorMessage: string,
  hasRequiredError: boolean,
  isPostAccountAlert: boolean,
  showError?: boolean,
) {
  if (coverageRequestDet) {
    const {
      ExtraCoverageRequestStatusId,
      AccountAlertPosted,
      ExtraCoverageRequestTypeId,
      EmailSent,
    } = coverageRequestDet;

    if (
      coverageRequestDet &&
      parseInt(ExtraCoverageRequestStatusId) !== approveStatusId
    )
      return `The extra coverage request must be approved to${
        isPostAccountAlert ? ' post an account alert.' : ' send email.'
      }`;
    else if (
      ExtraCoverageRequestTypeId === null ||
      ExtraCoverageRequestTypeId === '-1'
    )
      return 'Request type needs to be selected.';
    else if (errorMessage !== '' || (showError === true && !isPostAccountAlert))
      return 'Error found in Basic  details.';
    else if (hasRequiredError) return 'The required fields are not filled.';
    if (isPostAccountAlert ? AccountAlertPosted : EmailSent)
      return `An ${
        isPostAccountAlert ? 'account alert' : 'email'
      } has already been sent for this extra coverage request.`;
    return `${
      isPostAccountAlert ? 'Create an account alert ' : 'Send an approval email'
    } after the extra coverage request is approved.`;
  }
}

//for formatting request payload
function formatCoverageDetailsPayload(
  extraCoverageRequestDet: Record<any, any>,
  accountData: AccountData | undefined,
  mode: string | undefined,
  timezone: Array<Timezone> | undefined,
  isAlertAlreadyPosted: boolean,
  isModified: boolean,
  isSendEmailPosted: boolean,
  isSendEmailFieldModified: boolean,
) {
  let payload = payloadCommonFormat(
    extraCoverageRequestDet,
    accountData,
    timezone,
    mode,
    isAlertAlreadyPosted,
    isModified,
    isSendEmailPosted,
    isSendEmailFieldModified,
  );
  let formattedObject: any = {
    ...payload,
  };
  if (mode === '' || mode === 'Copy') {
    formattedObject = {
      ...formattedObject,
      formatter: formatObject(),
    };
  }
  return formattedObject;
}

export function getTwentyFourHourTime(amPmString: string) {
  var d = new Date('1/1/2013 ' + amPmString);
  return d.getHours() + ':' + d.getMinutes();
}

function formatObject() {
  return {
    dateFormat: 'MM/DD/YYYY',
    timeFormat: 'H:mm:ss',
    timeFormatHH: 'HH:mm',
    timeFormatNoSeconds: 'H:mm',
  };
}

function payloadCommonFormat(
  extraCoverageRequestDet: Record<any, any>,
  accountData: AccountData | undefined,
  timezone: Array<Timezone> | undefined,
  mode: string | undefined,
  isAlertAlreadyPosted: boolean,
  isModified: boolean,
  isSendEmailPosted: boolean,
  isSendEmailFieldModified: boolean,
) {
  let payload: any = {
    ...extraCoverageRequestDet,
    ExtraCoverageDate: dayjs(extraCoverageRequestDet?.ExtraCoverageDate).format(
      'MM/DD/YYYY',
    ),
    ExtraCoverageRequestStatusId:
      mode === '' || mode === 'Copy'
        ? null
        : extraCoverageRequestDet?.ExtraCoverageRequestStatusId &&
          extraCoverageRequestDet?.ExtraCoverageRequestStatusId !== '0'
        ? extraCoverageRequestDet?.ExtraCoverageRequestStatusId
        : null,
    ExtraCoverageRequestTypeId:
      mode === '' || mode === 'Copy'
        ? null
        : extraCoverageRequestDet?.ExtraCoverageRequestTypeId &&
          extraCoverageRequestDet?.ExtraCoverageRequestTypeId !== '-1'
        ? extraCoverageRequestDet?.ExtraCoverageRequestTypeId
        : null,
    Id: mode === '' || mode === 'Copy' ? 0 : extraCoverageRequestDet?.Id,
    Approver:
      mode === '' || mode === 'Copy' ? null : extraCoverageRequestDet?.Approver,
    FaxNumber: extraCoverageRequestDet?.FaxNumber
      ? extraCoverageRequestDet?.FaxNumber
      : '',
    ReturnEmailAddress: extraCoverageRequestDet?.ReturnEmailAddress
      ? extraCoverageRequestDet?.ReturnEmailAddress
      : '',
    RequestedBy: extraCoverageRequestDet?.RequestedBy
      ? extraCoverageRequestDet?.RequestedBy
      : '',
    SubmittedDate: new Date(),
    Accounts: accountData ? [...accountData] : [],
    TimeZoneId:
      extraCoverageRequestDet?.TimeZoneId !== '-1'
        ? parseInt(extraCoverageRequestDet?.TimeZoneId)
        : null,
    TimeZone: timezone?.find(
      item => item.Id === parseInt(extraCoverageRequestDet?.TimeZoneId),
    ),
    StartTime: getTwentyFourHourTime(extraCoverageRequestDet?.StartTime),
    EndTime: getTwentyFourHourTime(extraCoverageRequestDet?.EndTime),
    ReasonForCoverage: extraCoverageRequestDet?.ReasonForCoverage?.trim(),
    Country: null,
    PhoneNumber: null,
    AdditionalReturnEmailAddress:
      extraCoverageRequestDet?.AdditionalReturnEmailAddress
        ? extraCoverageRequestDet?.AdditionalReturnEmailAddress?.split(',')
            ?.map((elem: any) => elem.trim())
            ?.filter(Boolean)
            ?.join(',')
        : '',
    ...(mode === 'Approve' && {
      AccountAlertPosted:
        isAlertAlreadyPosted && isModified
          ? false
          : extraCoverageRequestDet?.AccountAlertPosted,
      EmailSent:
        isSendEmailPosted && isSendEmailFieldModified
          ? false
          : extraCoverageRequestDet?.EmailSent,
    }),
    AccountAlert:
      mode === 'Copy' ? null : extraCoverageRequestDet?.AccountAlert,
    AccountAlertId:
      mode === 'Copy' ? null : extraCoverageRequestDet?.AccountAlertId,
  };
  if (
    extraCoverageRequestDet?.hasOwnProperty('AnticipatedCalls') &&
    isNullOrUndefined(extraCoverageRequestDet?.AnticipatedCalls)
  )
    delete payload['AnticipatedCalls'];

  return payload;
}

function isCommaPresentOrNot(value: string) {
  let occuranceOfChar = (value.match(/\@/g) || []).length;
  let occuranceOfComma = (value.match(/\,/g) || []).length;
  if (
    occuranceOfChar > 1 &&
    occuranceOfChar !== occuranceOfComma &&
    occuranceOfComma < occuranceOfChar
  )
    return occuranceOfComma === occuranceOfChar - 1;
  return true;
}

function formatTime(time: any) {
  if (time) {
    const [hours, minutes] = time?.split(':');
    if (
      isNaN(hours) ||
      isNaN(minutes) ||
      hours < 0 ||
      hours >= 24 ||
      minutes < 0 ||
      minutes >= 60
    ) {
      return '';
    }
    return dayjs().hour(hours).minute(minutes).format('hh:mm A');
  }
}

function extractText(match: any) {
  return match ? match[1].replace(/<[^>]*>/g, '') : '';
}

const TIME_ADJUST = 15;

function formatDateToRange(
  startDate: string,
  endDate: string,
  startTime: string,
  endTime: string,
): string | null {
  if (startDate && endDate) {
    const startDateTime = dayjs(startDate?.split('T')[0] + startTime)
      .subtract(TIME_ADJUST, 'minute')
      .format('MM/DD/YYYY HH:mm:ss');
    const endDateTime = dayjs(endDate?.split('T')[0] + endTime)
      .add(TIME_ADJUST, 'minute')
      .format('MM/DD/YYYY HH:mm:ss');

    return `${startDateTime} - ${endDateTime}`;
  }
  return '';
}

function convertTimeToPT(
  date: any,
  time: any,
  sourceTimezone: Timezone,
  targetTimezone: Timezone | undefined,
) {
  const sourceOffset =
    sourceTimezone && convertOffsetFormat(getOffset(sourceTimezone));
  const targetOffset =
    targetTimezone && convertOffsetFormat(getOffset(targetTimezone));

  const ptDate = convertToTargetTimezone(
    sourceTimezone,
    `${date} ${time}`,
    sourceOffset,
    targetOffset,
  );
  return dayjs(ptDate)?.format('MM/DD/YYYY HH:mm')?.split(' ')[1];
}

function formatToSpecifiedDateRange(
  startDate: string,
  endDate: string,
  startTime: string,
  endTime: string,
  sourceTimezone?: Timezone,
  targetTimezone?: Timezone,
) {
  //formatting start time and end time
  const startTimes = dayjs(`2000-01-01T${startTime}`);
  const newStartTime = startTimes.subtract(dayjs.duration({ minutes: 15 }));
  const formattedNewStartTime = newStartTime.format('HH:mm:ss');

  const endTimes = dayjs(`2000-01-01T${endTime}`);
  const newEndTime = endTimes.add(dayjs.duration({ minutes: 15 }));
  const formattedNewEndTime = newEndTime.format('HH:mm:ss');

  const sourceOffset =
    sourceTimezone && convertOffsetFormat(getOffset(sourceTimezone));
  const targetOffset =
    targetTimezone && convertOffsetFormat(getOffset(targetTimezone));

  const modifiedStartDate = dayjs(startDate).format('MM/DD/YYYY');
  const modifiedEndDate = dayjs(endDate).format('MM/DD/YYYY');
  const formattedStartDateWithOffset = convertToTargetTimezone(
    sourceTimezone,
    `${modifiedStartDate} ${formattedNewStartTime}`,
    sourceOffset,
    targetOffset,
  );
  const formattedEndDateWithOffset = convertToTargetTimezone(
    sourceTimezone,
    `${modifiedEndDate} ${formattedNewEndTime}`,
    sourceOffset,
    targetOffset,
  );

  return `${modifiedStartDate} ${
    dayjs(formattedStartDateWithOffset)
      .format('MM/DD/YYYY HH:mm:ss')
      .split(' ')[1]
  } - ${modifiedEndDate} ${
    dayjs(formattedEndDateWithOffset)
      .format('MM/DD/YYYY HH:mm:ss')
      .split(' ')[1]
  }`;
}

function convertOffsetFormat(offset: any) {
  const [hoursStr, minutesStr] = offset.split(':');

  // Parse hours and minutes as integers
  const hours = parseInt(hoursStr, 10);
  const minutes = parseInt(minutesStr || '0', 10);

  // Format hours with leading zero and ensure minutes are always two digits
  const formattedHours =
    hours >= 0
      ? `+${hours.toString().padStart(2, '0')}`
      : `-${Math.abs(hours).toString().padStart(2, '0')}`;
  const formattedMinutes = minutes.toString().padStart(2, '0');

  // Return the formatted offset
  return `${formattedHours}:${formattedMinutes}`;
}

//change the date and time value from one timezone to another
function convertToTargetTimezone(
  sourceTimeZone: Timezone | undefined,
  datetime: any,
  sourceOffset: any,
  targetOffset: any,
) {
  const sourceDatetime =
    sourceTimeZone?.Abbreviation === 'GMT'
      ? dayjs(datetime).utcOffset(sourceOffset, true)
      : dayjs(datetime).utcOffset(sourceOffset);
  const targetDatetime = sourceDatetime.utcOffset(targetOffset);

  return targetDatetime.format('MM/DD/YYYY hh:mm A');
}

function getDuration(
  date: string,
  startTime: string,
  endTime: string,
  currentTimeZone: string,
  sourceTimeZone: Timezone,
  targetTimeZone: Timezone | undefined,
) {
  const sourceOffset = convertOffsetFormat(getOffset(sourceTimeZone));
  const targetOffset =
    targetTimeZone && convertOffsetFormat(getOffset(targetTimeZone));
  const day = dayjs(date).format('ddd');
  const formattedDate = dayjs(date).format('MM/DD/YYYY');
  const formattedTime = `${formatTime(startTime)} - ${formatTime(endTime)}`;
  const foramttedStartDate = convertToTargetTimezone(
    sourceTimeZone,
    `${formattedDate} ${startTime}`,
    sourceOffset,
    targetOffset,
  );
  const foramttedEndDate = convertToTargetTimezone(
    sourceTimeZone,
    `${formattedDate} ${endTime}`,
    sourceOffset,
    targetOffset,
  );
  const startDateFormattedArray = foramttedStartDate?.split(' ');
  const endDateFormattedArray = foramttedEndDate?.split(' ');
  const utcTime = `${startDateFormattedArray[1]} ${startDateFormattedArray[2]} - ${endDateFormattedArray[1]} ${endDateFormattedArray[2]}`;

  if (
    compareDates(startDateFormattedArray[0], endDateFormattedArray[0]) === null
  ) {
    return `${day} ${startDateFormattedArray[0]}, ${utcTime} PT (${formattedTime} ${currentTimeZone}) `;
  } else {
    return `${dayjs(startDateFormattedArray[0]).format(
      'ddd',
    )} ${foramttedStartDate}- ${dayjs(endDateFormattedArray[0]).format(
      'ddd',
    )} ${foramttedEndDate}  PT (${formattedTime} ${currentTimeZone})  `;
  }
}

function getDurationForGridAlertModal(
  date: string,
  startTime: string,
  endTime: string,
  currentTimeZone: string,
  sourceTimeZone: Timezone,
  targetTimeZone: Timezone | undefined,
) {
  const sourceOffset = convertOffsetFormat(getOffset(sourceTimeZone));
  const targetOffset =
    targetTimeZone && convertOffsetFormat(getOffset(targetTimeZone));

  const formattedDate = dayjs(date).format('MM/DD/YYYY');
  const formattedTime = `${formatTime(startTime)} - ${formatTime(endTime)}`;
  const foramttedStartDate = convertToTargetTimezone(
    sourceTimeZone,
    `${formattedDate} ${startTime}`,
    sourceOffset,
    targetOffset,
  );
  const foramttedEndDate = convertToTargetTimezone(
    sourceTimeZone,
    `${formattedDate} ${endTime}`,
    sourceOffset,
    targetOffset,
  );
  const startDateWeekDay = dayjs(foramttedStartDate).format('ddd');
  const endDateWeekDay = dayjs(foramttedEndDate).format('ddd');

  const startDateFormattedArray = foramttedStartDate?.split(' ');
  const endDateFormattedArray = foramttedEndDate?.split(' ');
  const utcTime = `${startDateFormattedArray[1]} ${startDateFormattedArray[2]} - ${endDateFormattedArray[1]} ${endDateFormattedArray[2]}`;

  if (
    compareDates(startDateFormattedArray[0], endDateFormattedArray[0]) === null
  ) {
    return `${startDateWeekDay} ${startDateFormattedArray[0]}, ${utcTime} PT (${formattedTime} ${currentTimeZone}) `;
  } else {
    return `${startDateWeekDay} ${foramttedStartDate}- ${endDateWeekDay} ${foramttedEndDate}  PT (${formattedTime} ${currentTimeZone})  `;
  }
}

function getAlertDetailsForGrid(
  daterange: any,
  message: string,
  sourceTimeZone: Timezone | undefined,
  targetTimeZone: any,
) {
  const sourceOffset =
    sourceTimeZone && convertOffsetFormat(getOffset(sourceTimeZone));
  const targetOffset =
    targetTimeZone && convertOffsetFormat(getOffset(targetTimeZone));
  const formatStartDate = convertToTargetTimezone(
    sourceTimeZone,
    `${daterange?.split('-')[0]}`,
    sourceOffset,
    targetOffset,
  );
  const formatEndDate = convertToTargetTimezone(
    sourceTimeZone,
    `${daterange?.split('-')[1]}`,
    sourceOffset,
    targetOffset,
  );

  return {
    EffectiveDate: formatStartDate
      ? dayjs(dayjs(formatStartDate).format('MM/DD/YYYY HH:mm')).utc().format()
      : null,
    ExpirationDate: formatEndDate
      ? dayjs(dayjs(formatEndDate).format('MM/DD/YYYY HH:mm')).utc().format()
      : null,
    Message: message,
  };
}

function getAlertDetails(daterange: any, message: string) {
  const formatedDate = formatToUtcWithOffset(daterange);
  return {
    EffectiveDate: formatedDate ? formatedDate[0] : null,
    ExpirationDate: formatedDate ? formatedDate[1] : null,
    Message: message,
  };
}

function findTimeZone(extraCoverageRequestDet: Record<any, any>) {
  const firstValue = extraCoverageRequestDet?.Accounts?.find(
    (item: any) => item.TimeZoneId !== null,
  )?.TimeZoneId;

  const allSame = extraCoverageRequestDet?.Accounts?.every(
    (item: any) => item?.TimeZoneId === firstValue,
  );

  if (allSame) {
    return firstValue;
  } else {
    return '-1';
  }
}

export {
  getSelectedOptionInfo,
  disabledReason,
  formatCoverageDetailsPayload,
  isCommaPresentOrNot,
  formatTime,
  extractText,
  formatDateToRange,
  getDuration,
  getAlertDetails,
  findTimeZone,
  formatToSpecifiedDateRange,
  getDurationForGridAlertModal,
  convertToTargetTimezone,
  getAlertDetailsForGrid,
  convertTimeToPT,
};
