import React, { ReactElement, useCallback, useState } from 'react';

import { Alert, Button, Spinner } from 'src/ui/components';
import { WelcomeGraphics } from 'src/ui/template/UserOnboarding/WelcomeGraphics';

import { useUserVerification } from '../api';

import { welcomeError } from '../utils/onBoardingConstants';
import { capitalizeLetter } from '../utils/onBoardingHelper';
import { userOnBoardingStore } from 'src/store/userOnboardingStore';

type WelcomeType = {
  id: string | null;
  code: string | null;
};

export function Welcome({ id, code }: WelcomeType): ReactElement {
  const [showError, setShowError] = useState<boolean>(false);
  const [errorMessage, setError] = useState<string>('');

  const step = userOnBoardingStore(useCallback(state => state.step, []));

  const setUpdateStore = userOnBoardingStore(
    useCallback(state => state.setUpdateStore, []),
  );

  const userDetails = userOnBoardingStore(
    useCallback(state => state.userDetails, []),
  );

  const { mutate: onVerifyUserCode, isLoading: onVerifyingUser } =
    useUserVerification();

  function onGetStarted() {
    setShowError(false);
    if (code) {
      const token = encodeURI(code);
      onVerifyUserCode(
        { id, type: true, code: token },
        {
          onSuccess: () => {
            if (step === 0) setUpdateStore('step', step + 1);
          },
          onError: () => {
            setShowError(true);
            setError(welcomeError);
          },
        },
      );
    } else {
      setShowError(true);
      setError('Malformed request.');
    }
  }

  return (
    <div className="container relative mx-auto grid h-full max-w-md place-items-center text-center">
      {onVerifyingUser && (
        <div className="bg-gray-900/8  absolute inset-0 z-50 grid   place-items-center  backdrop-blur-sm">
          <Spinner size="large" />
        </div>
      )}
      <div className="flex flex-col gap-6 p-4">
        <div className="flex justify-center">
          <WelcomeGraphics className="max-w-xs" />
        </div>
        <div className="space-y-1">
          <h2 className=" text-xl md:text-3xl">
            Hello,{' '}
            <span className="prevent-text-breakout">
              {capitalizeLetter(userDetails?.FirstName)}{' '}
              {capitalizeLetter(userDetails?.LastName)}
            </span>
          </h2>
          <h3 className="md:text-lg">
            Welcome to the <strong>Customer Portal!</strong>
          </h3>
        </div>
        <Button variant="primary" size="large" onClick={() => onGetStarted()}>
          Get started
        </Button>
        <div>
          {showError && (
            <Alert variant="danger" title="" className="text-left text-sm">
              {errorMessage}
            </Alert>
          )}
        </div>
      </div>
    </div>
  );
}
