import { useMutation, UseMutationResult, useQueryClient } from 'react-query';

import { useAuth } from 'src/hooks';

import { savePersonAlerts, getPersonAlertsDetails } from 'src/utils/urls';

import { PersonAlertListType } from '../../PersonAlerts/types/personAlerts';

async function savePersonAlert({
  token,
  tokenType,
  params,
  action,
}: TArguments): Promise<TResult> {
  const accountId = params?.Person?.AccountId;
  if (accountId) {
    let url = savePersonAlerts(accountId);
    let method = 'POST';
    if (action === 'Edit') {
      const alertId = params?.Id;
      url = getPersonAlertsDetails(alertId, accountId);
      method = 'PUT';
    }
    const response = await fetch(url, {
      method: method,
      headers: {
        Authorization: `${tokenType} ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(params),
    });
    return response;
  }
}

type TResult = any;
export type TError = { Message: string };

export type TVariables = {
  params: any;
  action?: string;
};

export type TArguments = {
  token: string;
  tokenType: string;
  params: PersonAlertListType;
  action?: string;
};

function useSavePersonAlert(): UseMutationResult<
  TResult,
  TError,
  TVariables,
  unknown
> {
  const { token, tokenType } = useAuth();
  const queryClient = useQueryClient();
  return useMutation(async (inputValues: TVariables) => {
    const result = await savePersonAlert({
      token,
      tokenType,
      params: inputValues?.params,
      action: inputValues?.action,
    });
    queryClient.invalidateQueries(['get_person_alerts_accounts_list'], {
      refetchActive: false,
    });
    queryClient.invalidateQueries(['get_person_alerts_list']);
    if (result.status === 200) return result.json();
    else {
      const message = await result.json();
      throw message;
    }
  });
}
export { useSavePersonAlert };
