import React, {
  ReactElement,
  Fragment,
  useState,
  useCallback,
  useRef,
  useEffect,
  useMemo,
} from 'react';
import { useNavigate } from 'react-router';
import { useQueryClient } from 'react-query';
import {
  FunnelIcon,
  ArrowPathIcon,
  DocumentDuplicateIcon,
  PencilIcon,
  EnvelopeIcon,
  EllipsisHorizontalIcon,
  InformationCircleIcon,
  PlusIcon,
  BellAlertIcon,
} from '@heroicons/react/20/solid';
import { Menu, Transition, Disclosure } from '@headlessui/react';

import { Button, Skeleton, EmptyState, Spinner } from 'src/ui/components';
import { ExtraCoverageListItem, ExtraCovergeFilter } from './components/index';
import {
  PreviewAccountAlert,
  SendApprovalEmail,
} from '../AddEditExtraCoverageRequest/components';

import { useGetExtraCoverageRequestLists, usePostAccountAlert } from './api';
import { TPage } from './api/useGetExtraCoverageRequestLists';
import {
  useGetStoredPermissions,
  useAuth,
  useTitle,
  useGetTimezone,
} from 'src/hooks';
import { useGetDefaultEmail } from '../AddEditExtraCoverageRequest/api';

import { useExtracoverageRequestStore } from 'src/features/AddEditExtraCoverageRequest/store/extracoverageStore';
import { classNames } from 'src/utils/className';
import { useCoverageFilterReducer } from './utils/useCoverageFilterReducer';
import { localTzMsg, pageLength } from 'src/utils/appConstants';
import { approveStatusId } from '../AddEditExtraCoverageRequest/utils/coverageRequestConstants';
import { editorContent } from '../AddEditExtraCoverageRequest/utils/storeHelper';
import {
  getDuration,
  formatDateToRange,
} from '../AddEditExtraCoverageRequest/utils/coverageRequestHelper';

import { ExtraCoverageType } from './types/extraCoverageRequestLists';
import { CoverageActionButtonsType } from './types/extraCoverageRequestFilterType';
import { ExtraCoverageResponseType } from '../AddEditExtraCoverageRequest/types/saveExtraCoverageRequest';
import { Timezone } from 'src/types/timezone';

export default function ExtraCoverageRequests(): ReactElement {
  useTitle('Extra Coverage Requests');

  const queryClient: any = useQueryClient();
  const navigate = useNavigate();
  const tbodyRef = useRef() as React.MutableRefObject<HTMLTableSectionElement>;
  const { userInfo } = useAuth();
  const permission = useGetStoredPermissions();

  const [selectedExtraCoverage, setSelectedExtraCoverage] =
    useState<ExtraCoverageType | null>(null);

  const { filterOptions, setValues, resetFilters, filterCount, setStatus } =
    useCoverageFilterReducer(permission.AllProtocallUsers);

  const {
    data: extraCoverageListData,
    isLoading: isListLoading,
    isFetching,
    isFetchingNextPage,
    isRefetching,
    hasNextPage,
    fetchNextPage,
  } = useGetExtraCoverageRequestLists(filterOptions);

  const { mutate: postAccountAlerts, isLoading: isPostAccountAlertsLoading } =
    usePostAccountAlert();

  const { data: timezone, isLoading: isTimezoneLoading } = useGetTimezone();
  //getting the PT timezone
  const ptTimeZone = useMemo(() => {
    if (timezone)
      return timezone.find((item: Timezone) => item?.Abbreviation === 'PT');
  }, [timezone]);

  const {
    data: emailStructure,
    isLoading: isDefaultEmailFetching,
    refetch: getDefaultEmail,
  } = useGetDefaultEmail(selectedExtraCoverage?.Id);

  const deleteCoverageDetails = useExtracoverageRequestStore(
    useCallback(state => state.deleteCoverageDetails, []),
  );

  const updateExtraCoverageReqDet = useExtracoverageRequestStore(
    useCallback(state => state.updateExtraCoverageReqDet, []),
  );

  const isAlertPreviewModal = useExtracoverageRequestStore(
    React.useCallback(state => state.isAlertPreviewModal, []),
  );

  const updateStoreByKey = useExtracoverageRequestStore(
    React.useCallback(state => state.updateStoreByKey, []),
  );

  const setAlertModalInfo = useExtracoverageRequestStore(
    React.useCallback(state => state.setAlertModalInfo, []),
  );

  const isShowSendApproval = useExtracoverageRequestStore(
    React.useCallback(state => state.isShowSendApproval, []),
  );

  const isSpinnerLoading =
    isFetchingNextPage ||
    isRefetching ||
    isPostAccountAlertsLoading ||
    isDefaultEmailFetching;

  const handleScroll = useCallback(
    (e: any) => {
      if (tbodyRef.current === e.target) {
        const bottom =
          e.target.clientHeight - 10 <
            e.target.scrollHeight - e.target.scrollTop &&
          e.target.scrollHeight - e.target.scrollTop <
            e.target.clientHeight + 10;
        if (bottom) {
          hasNextPage && fetchNextPage();
        }
      }
    },
    [fetchNextPage, hasNextPage],
  );

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, true);
    return () => {
      window.removeEventListener('scroll', handleScroll, true);
    };
  }, [handleScroll]);

  // to prevent persisting selection when filter is applied.
  useEffect(() => {
    setSelectedExtraCoverage(null);
  }, [filterOptions]);

  function handleRowClick(data: ExtraCoverageType) {
    if (data?.Id !== selectedExtraCoverage?.Id) setSelectedExtraCoverage(data);
    else setSelectedExtraCoverage(null);
  }

  function postAccountAlert() {
    deleteCoverageDetails();
    if (selectedExtraCoverage?.Id) {
      updateStoreByKey('isFromGrid', true);
      let coverageDate: any = selectedExtraCoverage?.ExtraCoverageDate ?? '';
      setAlertModalInfo(
        'message',
        editorContent(
          selectedExtraCoverage?.ReasonForCoverage ?? '',
          selectedExtraCoverage?.AdditionalComments ?? '',
          getDuration(
            coverageDate,
            selectedExtraCoverage?.StartTime,
            selectedExtraCoverage?.EndTime,
            selectedExtraCoverage?.TimeZone?.Abbreviation,
            selectedExtraCoverage?.TimeZone,
            ptTimeZone,
          ),
          selectedExtraCoverage?.SpecialProcedures ?? false,
          selectedExtraCoverage?.SpecialProcedureMemo,
        ),
      );

      let dateValue = formatDateToRange(
        coverageDate,
        coverageDate,
        selectedExtraCoverage?.StartTime,
        selectedExtraCoverage?.EndTime,
      );
      setAlertModalInfo('dateRange', dateValue);
      updateStoreByKey('isAlertPreviewModal', true);
    }
  }

  function onSendApprovalEmailHandler() {
    deleteCoverageDetails();
    if (selectedExtraCoverage?.Id) {
      updateStoreByKey('isFromGrid', true);
      getDefaultEmail().then(() => {
        updateStoreByKey('isShowSendApproval', true);
        updateStoreByKey('defaultEmail', emailStructure);
      });
    }
  }

  useEffect(() => {
    if (emailStructure) {
      updateStoreByKey('defaultEmail', emailStructure);
      // updateStoreByKey('isShowSendApproval', true);
    }
  }, [emailStructure, updateStoreByKey]);

  // enable or disable post accountalert & send approve email.
  function enableUserAction(key: keyof ExtraCoverageResponseType) {
    return (
      selectedExtraCoverage?.ExtraCoverageRequestStatusId === approveStatusId &&
      !selectedExtraCoverage?.[key]
    );
  }

  //edit button enable on mobile view
  function enableEditBtn() {
    return permission?.CanApproveCoverageRequests &&
      (permission?.NonClinicalAdministrator || permission.Administrators)
      ? true
      : selectedExtraCoverage?.ExtraCoverageRequestStatus === null ||
        selectedExtraCoverage?.ExtraCoverageRequestStatus?.Description !== 'New'
      ? false
      : true;
  }

  //user action buttons
  const coverageActionButtons: Array<CoverageActionButtonsType> = [
    {
      label: 'Copy request',
      icon: (
        <DocumentDuplicateIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
      ),
      action: () => {
        navigate(`/ExtraCoverageRequests/Copy/${selectedExtraCoverage?.Id}`);
      },
      isVisible: selectedExtraCoverage !== null,
      enable: permission?.CanSendCoverageRequests,
    },

    {
      label: 'Edit/Approve request',
      icon: <PencilIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />,
      action: () => {
        updateStoreByKey('isSaveClicked', false);
        navigate(`/ExtraCoverageRequests/Approve/${selectedExtraCoverage?.Id}`);
      },
      isVisible: selectedExtraCoverage !== null,
      enable: enableEditBtn(),
    },
  ];

  function renderPageDetails(): ReactElement {
    return (
      <>
        {extraCoverageListData?.pages &&
          extraCoverageListData.pages.length > 0 &&
          extraCoverageListData?.pages[0]?.data?.length > 0 &&
          extraCoverageListData.pages[extraCoverageListData.pages.length - 1]
            ?.data.length && (
            <p>
              Showing 1 to{' '}
              <strong>
                {extraCoverageListData?.pages[
                  extraCoverageListData?.pages?.length - 1
                ]?.data?.length === pageLength
                  ? extraCoverageListData?.pages?.length * pageLength
                  : (extraCoverageListData?.pages?.length - 1) * pageLength +
                    extraCoverageListData?.pages[
                      extraCoverageListData?.pages?.length - 1
                    ]?.data.length}{' '}
              </strong>
              of{' '}
              <strong>
                {
                  extraCoverageListData?.pages[
                    extraCoverageListData?.pages?.length - 1
                  ]?.recordsFiltered
                }
              </strong>{' '}
              entries (filtered from{' '}
              <strong> {extraCoverageListData?.pages[0]?.recordsTotal} </strong>{' '}
              total entries)
              {isFetching ? ' | Processing' : ''}
            </p>
          )}
      </>
    );
  }

  function sortAndFilterPanel(): ReactElement {
    return (
      <Disclosure as="div" className="relative">
        {({ open }) => (
          <>
            <div className="relative flex flex-col flex-wrap items-start justify-between gap-x-4 gap-y-2 border-t border-gray-200 bg-white px-2 py-1 shadow dark:border-neutral-700/50 dark:bg-neutral-800 sm:px-0 md:flex-row md:items-center">
              <h2 className="truncate text-lg font-semibold text-gray-950 dark:text-white sm:px-3 sm:text-xl sm:tracking-tight lg:px-4">
                Extra coverage requests
              </h2>
              <div className="flex w-full flex-1 justify-between gap-3 sm:px-3 md:w-auto lg:px-4">
                <div className="mt-1 flex items-center gap-6 sm:mt-0 md:gap-4">
                  <div className="relative inline-flex">
                    <Disclosure.Button
                      as={Button}
                      size="small"
                      variant={open ? 'primary' : 'subtle'}
                    >
                      <FunnelIcon
                        className="-ml-0.5 h-5 w-5"
                        aria-hidden="true"
                      />
                      <span className="hidden lg:inline-block">Filter</span>
                    </Disclosure.Button>
                    <span className="absolute right-0 top-0 -mr-1.5 -mt-1 flex h-5 w-5">
                      {filterCount > 0 && (
                        <span
                          className={`relative inline-flex h-5 w-5 items-center justify-center rounded-full bg-red-600 text-xs font-bold leading-none text-white`}
                        >
                          {filterCount}
                        </span>
                      )}
                    </span>
                  </div>
                  <Button
                    variant="subtle"
                    size="small"
                    onClick={() => {
                      resetFilters();
                    }}
                  >
                    <ArrowPathIcon
                      className="-ml-0.5 h-5 w-5"
                      aria-hidden="true"
                    />
                    <span className="hidden lg:block">Reset</span>
                  </Button>
                </div>

                <div className="flex items-center gap-3 sm:gap-2 md:gap-1">
                  {permission?.CanSendCoverageRequests && (
                    <Button
                      variant="primary"
                      size="small"
                      onClick={() => {
                        deleteCoverageDetails();
                        updateExtraCoverageReqDet(
                          'ReturnEmailAddress',
                          userInfo?.email,
                        );
                        navigate('/ExtraCoverageRequests/Create');
                      }}
                    >
                      <PlusIcon
                        className="-ml-0.5 h-5 w-5"
                        aria-hidden="true"
                      />
                      <span className="hidden lg:block">
                        Create new request
                      </span>
                    </Button>
                  )}

                  <div className="w-px self-stretch border border-light-light dark:border-dark-medium" />
                  {permission?.CanApproveCoverageRequests && (
                    <>
                      <Button
                        variant="subtle"
                        size="small"
                        onClick={() => {
                          onSendApprovalEmailHandler();
                        }}
                        disabled={!enableUserAction('EmailSent')}
                      >
                        <EnvelopeIcon
                          className="-ml-0.5 h-5 w-5"
                          aria-hidden="true"
                        />
                        <span className="hidden sm:block">
                          Send approval mail
                        </span>
                      </Button>
                      <Button
                        variant="subtle"
                        size="small"
                        onClick={() => {
                          postAccountAlert();
                        }}
                        disabled={!enableUserAction('AccountAlertPosted')}
                      >
                        <BellAlertIcon
                          className="-ml-0.5 h-5 w-5"
                          aria-hidden="true"
                        />
                        <span className="hidden sm:block">
                          Post account alert
                        </span>
                      </Button>
                    </>
                  )}
                </div>
              </div>
            </div>
            <ExtraCovergeFilter
              filterOptions={filterOptions}
              setValues={setValues}
              setStatus={setStatus}
              permission={permission}
              resetFilters={resetFilters}
            />
          </>
        )}
      </Disclosure>
    );
  }

  function userActions(): ReactElement {
    return (
      <>
        <div className="hidden gap-2 md:flex">
          {selectedExtraCoverage !== null ? (
            <>
              <div className="flex items-center gap-1 sm:gap-2">
                {permission?.CanSendCoverageRequests && (
                  <Button
                    variant="subtle"
                    size="small"
                    onClick={() => {
                      navigate(
                        `/ExtraCoverageRequests/Copy/${selectedExtraCoverage?.Id}`,
                      );
                    }}
                  >
                    <DocumentDuplicateIcon
                      className="-ml-0.5 h-5 w-5"
                      aria-hidden="true"
                    />
                    <span className="hidden sm:block">Copy request</span>
                  </Button>
                )}
              </div>
              <div className="flex items-center gap-1 sm:gap-2">
                {permission?.CanApproveCoverageRequests &&
                (permission?.NonClinicalAdministrator ||
                  permission.Administrators) ? (
                  <Button
                    variant="subtle"
                    size="small"
                    onClick={() => {
                      queryClient.invalidateQueries(
                        ['add-edit-coverage-request'],
                        {
                          refetchActive: false,
                        },
                      );
                      deleteCoverageDetails();
                      navigate(
                        `/ExtraCoverageRequests/Approve/${selectedExtraCoverage?.Id}`,
                      );
                    }}
                    disabled={selectedExtraCoverage === null}
                  >
                    <PencilIcon
                      className="-ml-0.5 h-5 w-5"
                      aria-hidden="true"
                    />
                    <span className="hidden sm:block">
                      Edit/Approve request
                    </span>
                  </Button>
                ) : (
                  <Button
                    variant="subtle"
                    size="small"
                    onClick={() => {
                      navigate(
                        `/ExtraCoverageRequests/Approve/${selectedExtraCoverage?.Id}`,
                      );
                    }}
                    disabled={
                      selectedExtraCoverage?.ExtraCoverageRequestStatus &&
                      selectedExtraCoverage?.ExtraCoverageRequestStatus
                        ?.Description !== 'New'
                    }
                  >
                    <PencilIcon
                      className="-ml-0.5 h-5 w-5"
                      aria-hidden="true"
                    />
                    <span className="hidden sm:block">
                      Edit/Approve request
                    </span>
                  </Button>
                )}
              </div>
            </>
          ) : null}
        </div>
      </>
    );
  }

  return (
    <>
      <main className="flex flex-1 flex-col overflow-y-auto overscroll-contain">
        {sortAndFilterPanel()}{' '}
        <p className="flex min-w-0 items-center justify-end gap-1 border-t bg-blue-50 p-1 font-medium  text-sky-700 shadow dark:border-neutral-700 dark:bg-neutral-800 dark:text-sky-300">
          <InformationCircleIcon
            className="-ml-0.5 hidden h-4 w-4 shrink-0 md:inline-block"
            aria-hidden="true"
          />
          <span className="text-xs">{localTzMsg}</span>
        </p>
        <div className="relative flex min-h-[46px] items-center justify-between gap-4 p-2 sm:px-3 lg:px-4">
          <div className="flex min-w-0 grow flex-wrap gap-x-6 gap-y-1 text-xs text-gray-600 dark:text-neutral-400 md:justify-between">
            {renderPageDetails()}
          </div>
          {userActions()}
          {/* Actions on mobile resolution - start */}
          {selectedExtraCoverage !== null ? (
            <Menu as="div" className="relative ml-3 md:hidden">
              <Menu.Button as={Button} variant="secondary" size="small">
                <EllipsisHorizontalIcon
                  className="h-5 w-5"
                  aria-hidden="true"
                />
              </Menu.Button>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 z-10 -mr-1 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none dark:bg-neutral-700">
                  {coverageActionButtons.map(
                    (btn: CoverageActionButtonsType, index: number) =>
                      btn?.isVisible && (
                        <Menu.Item
                          key={`btn-${btn.label}-${index}`}
                          as="a"
                          className={classNames(
                            // !btn?.enable ? 'bg-gray-100' : '',
                            'block px-4 py-2 text-sm text-gray-800   ui-disabled:opacity-60 dark:text-white',
                          )}
                          onClick={btn.action}
                          disabled={!btn?.enable}
                        >
                          {btn.label}
                        </Menu.Item>
                      ),
                  )}
                </Menu.Items>
              </Transition>
            </Menu>
          ) : null}
        </div>
        <div
          className="relative flex-1 space-y-2 overflow-y-scroll px-2 pb-2 sm:px-3 sm:pb-3 lg:px-4 lg:pb-4"
          ref={tbodyRef}
        >
          {!isListLoading &&
          extraCoverageListData?.pages &&
          extraCoverageListData?.pages.length > 0 ? (
            <>
              {extraCoverageListData?.pages.map(
                (page: TPage, index: number) => (
                  <Fragment key={`${page} - ${index + 1}`}>
                    {page?.data && page?.data?.length ? (
                      page?.data.map(
                        (coverageData: ExtraCoverageType, index: number) => (
                          <ExtraCoverageListItem
                            key={`${coverageData.Id}-${index + 1}`}
                            coverageData={coverageData}
                            selectedExtraCoverage={selectedExtraCoverage}
                            handleRowClick={handleRowClick}
                            permission={permission}
                            ptTimeZone={ptTimeZone}
                          />
                        ),
                      )
                    ) : (
                      <div className="absolute inset-0 grid place-content-center">
                        <EmptyState message="No information available" />
                      </div>
                    )}
                  </Fragment>
                ),
              )}
            </>
          ) : (
            (isListLoading || isFetching) && <Skeleton count={8} />
          )}
          {isSpinnerLoading && (
            <div className="fixed inset-0 z-20 grid place-content-center">
              <Spinner size="large" />
            </div>
          )}
          {isAlertPreviewModal && (
            <PreviewAccountAlert
              Id={selectedExtraCoverage?.Id}
              AccountAlertId={selectedExtraCoverage?.AccountAlertId}
              setSelectedExtraCoverage={setSelectedExtraCoverage}
              selectedExtraCoverage={selectedExtraCoverage}
              ptTimeZone={ptTimeZone}
            />
          )}
          {isShowSendApproval && (
            <SendApprovalEmail
              Id={selectedExtraCoverage?.Id}
              setSelectedExtraCoverage={setSelectedExtraCoverage}
            />
          )}
        </div>
      </main>
    </>
  );
}
