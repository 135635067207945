import { getBasicUserInfo } from 'src/utils/urls';

async function getUserBasicInfo({ id }: { id: string | null }) {
  if (id) {
    const response = await fetch(getBasicUserInfo(id), {
      method: 'GET',
    });
    return response.json();
  }
}

export { getUserBasicInfo };
