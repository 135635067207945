import React, { HTMLAttributes, forwardRef } from 'react';
import clsx, { ClassValue } from 'clsx';
import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
  InformationCircleIcon,
  ExclamationCircleIcon,
} from '@heroicons/react/24/outline';
import * as IconSmall from '@heroicons/react/16/solid';
import * as IconLarge from '@heroicons/react/24/outline';

type AlertProps = HTMLAttributes<HTMLDivElement> & {
  variant?: 'default' | 'warning' | 'success' | 'info' | 'danger';
  title?: string;
  className?: ClassValue;
  icon?: boolean | true;
  size?: 'small' | 'medium' | 'large';
};

export const AlertSizeClasses = {
  small: 'p-1 text-xs',
  medium: 'p-2 text-sm',
  large: 'p-3',
};

const Alert = forwardRef<HTMLDivElement, AlertProps>(
  (
    {
      variant = 'default',
      size = 'medium',
      children,
      className,
      title,
      icon = true,
      ...rest
    },
    ref,
  ) => {
    const sizeClass = AlertSizeClasses[size] || AlertSizeClasses.medium;
    const variantClasses = {
      default:
        'border-gray-500 bg-gray-100 text-gray-800 dark:border-neutral-700 dark:bg-neutral-900 dark:text-neutral-300',
      warning:
        'border-yellow-500 bg-yellow-50 text-yellow-800 dark:border-yellow-800 dark:bg-yellow-900/50 dark:text-yellow-100',
      success:
        'border-green-500 bg-green-100 text-green-800 dark:border-green-700 dark:bg-green-900 dark:text-green-300',
      info: 'border-blue-400 bg-blue-50 text-blue-800 dark:border-blue-700 dark:bg-blue-900/50 dark:text-blue-300',
      danger:
        'border-red-500 bg-red-100 text-red-800 dark:border-red-700 dark:bg-red-900 dark:text-red-300',
    };

    const baseClasses = `grid grid-cols-[auto_1fr] grid-rows-[repeat(3,minmax(min-content,max-content))_1fr] rounded border ${
      (icon && title) || icon ? 'gap-x-2' : ''
    }`;

    const variantClass = variantClasses[variant] || variantClasses.default;

    const userClasses = clsx(className);

    const WarningIcon =
      size === 'small' ? (
        <IconSmall.ExclamationTriangleIcon
          className={`h-4 w-4 text-yellow-600 dark:text-yellow-400`}
        />
      ) : size === 'large' ? (
        <IconLarge.ExclamationTriangleIcon
          className={`h-6 w-6 text-yellow-600 dark:text-yellow-400`}
        />
      ) : (
        <ExclamationTriangleIcon
          className={`h-5 w-5 text-yellow-600 dark:text-yellow-400`}
        />
      );

    const SuccessIcon =
      size === 'small' ? (
        <IconSmall.CheckCircleIcon
          className={`h-4 w-4 text-green-700 dark:text-green-500`}
        />
      ) : size === 'large' ? (
        <IconLarge.CheckCircleIcon
          className={`h-6 w-6 text-green-700 dark:text-green-500`}
        />
      ) : (
        <CheckCircleIcon
          className={`h-5 w-5 text-green-700 dark:text-green-500`}
        />
      );

    const InfoIcon =
      size === 'small' ? (
        <IconSmall.InformationCircleIcon
          className={`h-4 w-4 text-blue-700 dark:text-blue-300`}
        />
      ) : size === 'large' ? (
        <IconLarge.InformationCircleIcon
          className={`h-6 w-6 text-blue-700 dark:text-blue-300`}
        />
      ) : (
        <InformationCircleIcon
          className={`h-5 w-5 text-blue-700 dark:text-blue-300`}
        />
      );

    const DangerIcon =
      size === 'small' ? (
        <IconSmall.ExclamationCircleIcon
          className={`h-4 w-4 text-red-700 dark:text-red-300`}
        />
      ) : size === 'large' ? (
        <IconLarge.ExclamationCircleIcon
          className={`h-6 w-6 text-red-700 dark:text-red-300`}
        />
      ) : (
        <ExclamationCircleIcon
          className={`h-5 w-5 text-red-700 dark:text-red-300`}
        />
      );

    const DefaultIcon =
      size === 'small' ? (
        <IconSmall.InformationCircleIcon
          className={`h-4 w-4 text-gray-700 dark:text-gray-300`}
        />
      ) : size === 'large' ? (
        <IconLarge.InformationCircleIcon
          className={`h-6 w-6 text-gray-700 dark:text-gray-300`}
        />
      ) : (
        <InformationCircleIcon
          className={`h-5 w-5 text-gray-700 dark:text-gray-300`}
        />
      );

    return (
      <div
        className={`${baseClasses} ${variantClass} ${sizeClass} ${userClasses}`}
        style={{
          gridTemplateAreas: `"signifier ."
    "signifier title"
    "signifier content"
    ". content"`,
        }}
        role="alert"
        ref={ref}
        {...rest}
      >
        {icon && (
          <div style={{ gridArea: 'signifier' }} className="flex self-center">
            {variant === 'warning'
              ? WarningIcon
              : variant === 'success'
              ? SuccessIcon
              : variant === 'info'
              ? InfoIcon
              : variant === 'danger'
              ? DangerIcon
              : DefaultIcon}
          </div>
        )}
        <h3
          style={{ gridArea: 'title' }}
          className="peer flex items-center self-center font-bold empty:!col-[1] empty:!row-[2] empty:after:invisible empty:after:content-['x']"
        >
          {title}
        </h3>
        <div
          style={{ gridArea: 'content' }}
          className="leading-tight peer-empty:!row-start-2"
        >
          {children}
        </div>
      </div>
    );
  },
);
Alert.displayName = 'Alert';

export { Alert };
