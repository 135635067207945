import moment from 'moment';
import dayjs from 'dayjs';
import { TimeZone } from 'src/types/AccountInfo';

const momentPastRanges: any = {
  Today: [
    moment().startOf('day').format('MM/DD/YYYY HH:mm:ss'),
    moment().endOf('day').format('MM/DD/YYYY HH:mm:ss'),
  ],
  Yesterday: [
    moment().subtract(1, 'days').startOf('day').format('MM/DD/YYYY HH:mm:ss'),
    moment().subtract(1, 'days').endOf('day').format('MM/DD/YYYY HH:mm:ss'),
  ],
  'Last 7 Days': [
    moment().subtract(6, 'days').startOf('day').format('MM/DD/YYYY HH:mm:ss'),
    moment().endOf('day').format('MM/DD/YYYY HH:mm:ss'),
  ],
  'Last 30 Days': [
    moment().subtract(29, 'days').startOf('day').format('MM/DD/YYYY HH:mm:ss'),
    moment().endOf('day').format('MM/DD/YYYY HH:mm:ss'),
  ],
  'This Month': [
    moment().startOf('month').startOf('day').format('MM/DD/YYYY HH:mm:ss'),
    moment().endOf('month').endOf('day').format('MM/DD/YYYY HH:mm:ss'),
  ],
  'Last Month': [
    moment()
      .subtract(1, 'month')
      .startOf('month')
      .startOf('day')
      .format('MM/DD/YYYY HH:mm:ss'),
    moment()
      .subtract(1, 'month')
      .endOf('month')
      .endOf('day')
      .format('MM/DD/YYYY HH:mm:ss'),
  ],
};

const momentFutureRanges: any = {
  Today: [
    moment().startOf('day').format('MM/DD/YYYY HH:mm:ss'),
    moment().endOf('day').format('MM/DD/YYYY HH:mm:ss'),
  ],
  Tomorrow: [
    moment().add(1, 'days').startOf('day').format('MM/DD/YYYY HH:mm:ss'),
    moment().add(1, 'days').endOf('day').format('MM/DD/YYYY HH:mm:ss'),
  ],
  'Next 7 Days': [
    moment().startOf('day').format('MM/DD/YYYY HH:mm:ss'),
    moment().add(6, 'days').endOf('day').format('MM/DD/YYYY HH:mm:ss'),
  ],
  'Next 30 Days': [
    moment().startOf('day').format('MM/DD/YYYY HH:mm:ss'),
    moment().add(29, 'days').endOf('day').format('MM/DD/YYYY HH:mm:ss'),
  ],
  'This Month': [
    moment().startOf('month').startOf('day').format('MM/DD/YYYY HH:mm:ss'),
    moment().endOf('month').endOf('day').format('MM/DD/YYYY HH:mm:ss'),
  ],
  'Next Month': [
    moment()
      .add(1, 'month')
      .startOf('month')
      .startOf('month')
      .startOf('day')
      .format('MM/DD/YYYY HH:mm:ss'),

    moment()
      .add(1, 'month')
      .startOf('month')
      .endOf('month')
      .endOf('day')
      .format('MM/DD/YYYY HH:mm:ss'),
  ],
};

function formatToUtcWithOffset(inputValue: any) {
  const dateValue = [];
  const newInput = inputValue?.split('-');

  if (inputValue) {
    dateValue[0] = dayjs(newInput[0]).utc().format();
    dateValue[1] = dayjs(newInput[1]).utc().format();
    return dateValue;
  } else return null;
}

function formatDateToRange(StartDate: any, EndDate: any) {
  if (StartDate && StartDate !== '' && EndDate && EndDate !== '') {
    let startDate = dayjs.utc(StartDate).local().format('MM/DD/YYYY HH:mm:ss');
    let endDate = dayjs.utc(EndDate).local().format('MM/DD/YYYY HH:mm:ss');
    return `${startDate}-${endDate}`;
  } else return null;
}

function formatDateWithTime(date: string): string {
  return dayjs(date).format('MM/DD/YYYY H:mm');
}

// get the offset value from the timezone
function getOffset(timeZone: TimeZone) {
  const offsetVal =
    timeZone?.IsDSTFollowed === false
      ? timeZone?.UtcOffsetWithoutDST
      : timeZone?.UtcOffsetWithDST;
  return offsetVal;
}

const currentYear = dayjs().year();
const yestarday = dayjs().subtract(1, 'day').format('YYYY-MM-DD');
const minDobDate = new Date('01/01/1900');

// Compare the dates
function compareDates(date1: any, date2: any) {
  let d1: any = new Date(date1);
  let d2: any = new Date(date2);

  if (d1 < d2 && !isNaN(d1) && !isNaN(d2)) {
    return true;
  } else if (d1 > d2 && !isNaN(d1) && !isNaN(d2)) {
    return false;
  } else {
    return null;
  }
}

//Getting the current date and time in a particular timezone
function getCurrentDateTimeWithOffset(offsetVal: any) {
  let offset = offsetVal === undefined ? getTimeZone() : offsetVal;
  let sign = offset[0];
  let [hours, minutes] = offset?.slice(1)?.split(':')?.map(Number);
  let totalMinutesOffset = (hours * 60 + minutes) * (sign === '+' ? 1 : -1);
  let now = new Date();
  let utc = new Date(now.getTime() + now.getTimezoneOffset() * 60000);
  utc.setMinutes(utc.getMinutes() + totalMinutesOffset);
  return dayjs(utc).format('MM/DD/YYYY HH:mm');
}

//get current time in particular timezone
function getCurrentTime(offset: string | undefined) {
  const dateValue = getCurrentDateTimeWithOffset(offset);
  const dateTimeArray = dateValue.split(' ');

  return dateTimeArray[1];
}
function getCurrentDate(offset: string | undefined) {
  const dateValue = getCurrentDateTimeWithOffset(offset);
  const dateTimeArray = dateValue.split(' ');
  return dateTimeArray[0];
}

//get the mindate for calendar
function minDate(offset: any) {
  let currentDateTimeWithOffset = getCurrentDateTimeWithOffset(offset);
  let value = currentDateTimeWithOffset.split(' ')[0];
  return new Date(value);
}

function getTimeZonePastRanges(offset: any) {
  let currentDate = getCurrentDateTimeWithOffset(offset);
  const momentPastRanges: any = {
    Today: [
      moment(currentDate).startOf('day').format('MM/DD/YYYY HH:mm:ss'),
      moment(currentDate).endOf('day').format('MM/DD/YYYY HH:mm:ss'),
    ],
    Yesterday: [
      moment(currentDate)
        .subtract(1, 'days')
        .startOf('day')
        .format('MM/DD/YYYY HH:mm:ss'),
      moment(currentDate)
        .subtract(1, 'days')
        .endOf('day')
        .format('MM/DD/YYYY HH:mm:ss'),
    ],
    'Last 7 Days': [
      moment(currentDate)
        .subtract(6, 'days')
        .startOf('day')
        .format('MM/DD/YYYY HH:mm:ss'),
      moment(currentDate).endOf('day').format('MM/DD/YYYY HH:mm:ss'),
    ],
    'Last 30 Days': [
      moment(currentDate)
        .subtract(29, 'days')
        .startOf('day')
        .format('MM/DD/YYYY HH:mm:ss'),
      moment(currentDate).endOf('day').format('MM/DD/YYYY HH:mm:ss'),
    ],
    'Last 90 Days': [
      moment(currentDate)
        .subtract(89, 'days')
        .startOf('day')
        .format('MM/DD/YYYY HH:mm:ss'),
      moment(currentDate).endOf('day').format('MM/DD/YYYY HH:mm:ss'),
    ],
    'This Month': [
      moment(currentDate)
        .startOf('month')
        .startOf('day')
        .format('MM/DD/YYYY HH:mm:ss'),
      moment(currentDate)
        .endOf('month')
        .endOf('day')
        .format('MM/DD/YYYY HH:mm:ss'),
    ],
    'Last Month': [
      moment(currentDate)
        .subtract(1, 'month')
        .startOf('month')
        .startOf('day')
        .format('MM/DD/YYYY HH:mm:ss'),
      moment(currentDate)
        .subtract(1, 'month')
        .endOf('month')
        .endOf('day')
        .format('MM/DD/YYYY HH:mm:ss'),
    ],
    'Last 6 Months': [
      moment(currentDate)
        .subtract(6, 'month')
        .startOf('month')
        .startOf('day')
        .format('MM/DD/YYYY HH:mm:ss'),
      moment(currentDate)
        .subtract(1, 'month')
        .endOf('month')
        .endOf('day')
        .format('MM/DD/YYYY HH:mm:ss'),
    ],
  };
  return momentPastRanges;
}

function getTimeZoneFutureRanges(offset: any) {
  let currentDate = getCurrentDateTimeWithOffset(offset);
  const momentFutureRanges: any = {
    Today: [
      moment(currentDate).startOf('day').format('MM/DD/YYYY HH:mm:ss'),
      moment(currentDate).endOf('day').format('MM/DD/YYYY HH:mm:ss'),
    ],
    Tomorrow: [
      moment(currentDate)
        .add(1, 'days')
        .startOf('day')
        .format('MM/DD/YYYY HH:mm:ss'),
      moment(currentDate)
        .add(1, 'days')
        .endOf('day')
        .format('MM/DD/YYYY HH:mm:ss'),
    ],
    'Next 7 Days': [
      moment(currentDate).startOf('day').format('MM/DD/YYYY HH:mm:ss'),
      moment(currentDate)
        .add(6, 'days')
        .endOf('day')
        .format('MM/DD/YYYY HH:mm:ss'),
    ],
    'Next 30 Days': [
      moment(currentDate).startOf('day').format('MM/DD/YYYY HH:mm:ss'),
      moment(currentDate)
        .add(29, 'days')
        .endOf('day')
        .format('MM/DD/YYYY HH:mm:ss'),
    ],
    'Next 90 Days': [
      moment(currentDate).startOf('day').format('MM/DD/YYYY HH:mm:ss'),
      moment(currentDate)
        .add(89, 'days')
        .endOf('day')
        .format('MM/DD/YYYY HH:mm:ss'),
    ],
    'This Month': [
      moment(currentDate)
        .startOf('month')
        .startOf('day')
        .format('MM/DD/YYYY HH:mm:ss'),
      moment(currentDate)
        .endOf('month')
        .endOf('day')
        .format('MM/DD/YYYY HH:mm:ss'),
    ],
    'Next Month': [
      moment(currentDate)
        .add(1, 'month')
        .startOf('month')
        .startOf('month')
        .startOf('day')
        .format('MM/DD/YYYY HH:mm:ss'),
      moment(currentDate)
        .add(1, 'month')
        .startOf('month')
        .endOf('month')
        .endOf('day')
        .format('MM/DD/YYYY HH:mm:ss'),
    ],
    'Next 6 Months': [
      moment(currentDate)
        .add(1, 'month')
        .startOf('month')
        .startOf('month')
        .startOf('day')
        .format('MM/DD/YYYY HH:mm:ss'),
      moment(currentDate)
        .add(6, 'month')
        .startOf('month')
        .endOf('month')
        .endOf('day')
        .format('MM/DD/YYYY HH:mm:ss'),
    ],
  };
  return momentFutureRanges;
}

//check whether the given date and time are in past or not
function isDateTimeInPast(dateTimeString: any, utcOffset: any) {
  if (dateTimeString && utcOffset) {
    const dateValues = dateTimeString?.split('-')[0];
    let dateTime = dayjs(dateValues, 'MM/DD/YYYY HH:mm:ss');
    let currentDateTimeInTimezone = getCurrentDateTimeWithOffset(utcOffset);
    return dateTime?.isBefore(currentDateTimeInTimezone);
  } else return false;
}

//get the current timezone
function getTimeZone() {
  let now = new Date();
  let offsetMinutes = now.getTimezoneOffset();
  let offsetHours = Math.floor(Math.abs(offsetMinutes) / 60);
  let minutes = Math.abs(offsetMinutes) % 60;
  let sign = offsetMinutes > 0 ? '-' : '+';
  let formattedOffset = `${sign}${String(offsetHours).padStart(
    2,
    '0',
  )}:${String(minutes).padStart(2, '0')}`;
  return formattedOffset;
}

export {
  momentPastRanges,
  momentFutureRanges,
  formatToUtcWithOffset,
  formatDateToRange,
  formatDateWithTime,
  yestarday,
  currentYear,
  minDobDate,
  compareDates,
  getCurrentDateTimeWithOffset,
  getTimeZonePastRanges,
  getTimeZoneFutureRanges,
  isDateTimeInPast,
  minDate,
  getCurrentTime,
  getCurrentDate,
  getOffset,
  getTimeZone,
};
