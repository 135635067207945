import React, {
  ReactElement,
  useState,
  useCallback,
  useMemo,
  Fragment,
  useEffect,
} from 'react';
import { Disclosure, Popover } from '@headlessui/react';
import {
  ChevronDownIcon,
  XMarkIcon,
  InformationCircleIcon,
} from '@heroicons/react/20/solid';

import { Button, Badge, TooltipWithBadge, ReactTable } from 'src/ui/components';

import useAccountInfoColumns from './useAccountInfoColumns';
import { useGetAccountList } from 'src/hooks/useGetAccountList';

import { useUserStore } from '../../store/userStore';

import {
  openStateAccountCount,
  closedStateAccountCount,
} from 'src/utils/appConstants';

import { AccountInfo } from 'src/types/AccountInfo';

const sortItem = { sortKey: 'CID', sortOrder: 'desc' };

function AccountSelection(): ReactElement {
  const [columnsSearch, setColumnsSearch] = useState<Record<string, string>>(
    {},
  );
  const [currentSortItem, setCurrentSortItem] = useState(sortItem);
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [isScroll, setIsScroll] = useState<boolean>(false);

  const {
    data,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    isLoading: isAccountListLoading,
  } = useGetAccountList({
    columnsSearch,
    currentSortItem,
  });

  const filteredData = useMemo(() => {
    if (data) {
      return data?.pages?.map(page => page?.data).flat();
    } else return [];
  }, [data]);

  const accountsInfo = useUserStore(
    React.useCallback(state => state.accountsInfo, []),
  );

  const accountList = useUserStore(
    React.useCallback(state => state.accountList, []),
  );

  const updateAccountInfo = useUserStore(
    React.useCallback(state => state.updateAccountInfo, []),
  );

  const updateAccountSettings = useUserStore(
    React.useCallback(state => state.updateAccountSettings, []),
  );

  const enableAccountTab = useUserStore(
    React.useCallback(state => state.enableSettings, []),
  );

  const isSaveClicked = useUserStore(
    React.useCallback(state => state.isSaveClicked, []),
  );

  const updatebulkAccountsDetails = useUserStore(
    React.useCallback(state => state.bulckUpdateAccountSettings, []),
  );

  const userMode = useUserStore(useCallback(state => state.userMode, []));

  const [isOpen, setIsOpen] = useState<boolean>(userMode === '' ? false : true);

  //searching function callback
  const headerUpdateCallBack = useCallback(
    ({ columnId, value }: { columnId: string; value: string }) => {
      setColumnsSearch({
        ...columnsSearch,
        [columnId]: value,
      });
    },
    [columnsSearch],
  );

  //if filteres applied
  const isFilterApplied = useMemo(() => {
    if (
      currentSortItem?.sortKey === 'CID' &&
      currentSortItem?.sortOrder === 'desc' &&
      ((Object.keys(columnsSearch).length !== 0 &&
        Object.values(columnsSearch).some((x: any) => x === '')) ||
        Object.keys(columnsSearch).length === 0)
    ) {
      return false;
    } else return true;
  }, [columnsSearch, currentSortItem?.sortKey, currentSortItem?.sortOrder]);

  //sorting function callback
  const handleSortChange = useCallback(
    (item: string): void => {
      let sortOrder = 'asc';
      if (
        currentSortItem.sortKey === item &&
        currentSortItem.sortOrder === 'asc'
      ) {
        sortOrder = 'desc';
      }
      setCurrentSortItem({
        sortKey: item,
        sortOrder,
      });
    },
    [setCurrentSortItem, currentSortItem],
  );

  //React-table column list
  const columns = useAccountInfoColumns(
    filteredData,
    setColumnsSearch,
    setCurrentSortItem,
    selectAll,
    setSelectAll,
    isFilterApplied,
    isAccountListLoading,
    isScroll,
    setIsOpen,
    isOpen,
    undefined,
  );

  //unselecting accounts
  function handleUnselectAccounts(item: AccountInfo) {
    let tempSelectedAccounts = [...accountsInfo];
    const indx = accountsInfo?.findIndex(obj => obj === item?.AccountId);

    if (indx === -1) {
      tempSelectedAccounts.push(item?.AccountId);
    } else tempSelectedAccounts?.splice(indx, 1);
    tempSelectedAccounts?.length === 0 && setIsOpen(false);
    updateAccountInfo([...tempSelectedAccounts]);
    updateAccountSettings(item?.AccountId, false);
  }

  //return with acocount info details mapping from account list in store
  const selectedAccounts = useMemo(() => {
    if (accountsInfo && accountList) {
      let result = accountList?.filter(o1 =>
        accountsInfo.some(o2 => o1?.AccountId === o2),
      );
      return result;
    }
  }, [accountsInfo, accountList]);

  //react table row click
  function handleRowClick(item: any) {
    let tempSelectedIds: Array<number> =
      accountsInfo?.length > 0 ? [...accountsInfo] : [];

    const indx = tempSelectedIds?.findIndex(
      obj => obj === item?.original?.AccountId,
    );

    if (indx === -1) {
      tempSelectedIds?.push(item?.original?.AccountId);
      // update the account details for account& question section
      updateAccountSettings(item?.original?.AccountId, true);
    } else {
      tempSelectedIds?.splice(indx, 1);
      // remove the account details from account& question section
      updateAccountSettings(item?.original?.AccountId, false);
    }
    tempSelectedIds?.length >= 1
      ? setIsOpen(true)
      : tempSelectedIds?.length === 0
      ? setIsOpen(false)
      : null;
    updateAccountInfo([...tempSelectedIds]);
    //to check whether all elements from record is present in filtereddata
    let checker = filteredData?.every(v =>
      tempSelectedIds.includes(v?.AccountId),
    );

    if (tempSelectedIds?.length === filteredData?.length || checker === true)
      setSelectAll(true);
    else setSelectAll(false);
  }

  function onEndReached() {
    if (hasNextPage) {
      setIsScroll(true);
      fetchNextPage();
    } else if (!hasNextPage && isScroll) {
      setIsScroll(false);
    }
  }

  useEffect(() => {
    if (isScroll && selectAll && !isAccountListLoading) {
      let tempAccountInfo = useUserStore.getState().accountsInfo?.flat();
      const objectValues = filteredData?.map(obj => obj['AccountId']);
      const tempIncludedArray = tempAccountInfo.filter(
        num => !objectValues.includes(num),
      );

      const tempSelectedIds =
        filteredData &&
        filteredData?.map((item: any) => {
          return item?.AccountId;
        });
      tempSelectedIds &&
        updateAccountInfo([...tempSelectedIds, ...tempIncludedArray]);
      updatebulkAccountsDetails([...tempSelectedIds, ...tempIncludedArray]);
    }
  }, [
    filteredData,
    isFilterApplied,
    isScroll,
    selectAll,
    setSelectAll,
    updateAccountInfo,
    updatebulkAccountsDetails,
    isAccountListLoading,
  ]);

  function renderSelectedAccountDisplay() {
    return (
      <Fragment>
        <Disclosure>
          {({ open }) => (
            <>
              <Disclosure.Button
                className={`flex w-full justify-between bg-white px-4 py-2 text-left text-sm font-medium text-light-darkest transition-colors hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-light-focus focus-visible:ring-opacity-75 dark:bg-neutral-800 dark:text-dark-lightest dark:hover:bg-neutral-700 dark:focus-visible:ring-dark-focus ${
                  !isOpen ? 'border-b dark:border-dark-medium' : ''
                }`}
                onClick={e => {
                  e.preventDefault();
                  setIsOpen(!isOpen);
                }}
                disabled={selectedAccounts?.length === 0}
              >
                <span className="inline-flex items-center">
                  Selected accounts{' '}
                  <span className="ml-2 inline-flex rounded-full bg-gray-700 px-2 py-1 text-xs text-white dark:bg-neutral-900">
                    {selectedAccounts?.length ? selectedAccounts?.length : 0}
                  </span>
                </span>

                {selectedAccounts?.length && selectedAccounts?.length > 0 ? (
                  <ChevronDownIcon
                    className={`${
                      isOpen ? 'rotate-180 transform' : ''
                    } h-5 w-5 `}
                  />
                ) : null}
              </Disclosure.Button>
              {isOpen && selectedAccounts && selectedAccounts?.length > 0 && (
                <Disclosure.Panel
                  className="border-b bg-white p-2 text-sm  text-gray-500 dark:border-dark-medium dark:bg-neutral-800/50"
                  static={true}
                >
                  {selectedAccounts?.length && selectedAccounts?.length > 0 ? (
                    <div className="flex max-h-20 flex-wrap gap-2 overflow-y-auto px-2">
                      {selectedAccounts?.map(
                        (item: AccountInfo, index: number) => {
                          return (
                            <span
                              key={`${item?.AccountId}${index}`}
                              className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10  dark:bg-neutral-600/50 dark:text-neutral-300"
                            >
                              <span className="leading-none">
                                {' '}
                                {item?.CID}
                                {item.Name !== '' ? `-${item?.Name}` : ''}
                              </span>
                              <Button
                                variant="subtle"
                                size="small"
                                className="-mr-1 ml-1 px-1"
                                onClick={() => {
                                  handleUnselectAccounts(item);
                                }}
                              >
                                <XMarkIcon className="h-3 w-3" />
                              </Button>
                            </span>
                          );
                        },
                      )}
                    </div>
                  ) : (
                    <div className="grid place-content-center">
                      <p className="text-center text-gray-400 dark:text-neutral-300">
                        No accounts selected
                      </p>
                    </div>
                  )}
                </Disclosure.Panel>
              )}
            </>
          )}
        </Disclosure>
      </Fragment>
    );
  }

  function renderAccountList(isOpen: boolean) {
    const accountCount = isOpen
      ? openStateAccountCount
      : closedStateAccountCount;

    return (
      <Fragment>
        <div className="flex grow flex-wrap items-center gap-x-8 gap-y-2 pr-2">
          <div className="flex grow flex-wrap gap-x-1 gap-y-1">
            <div className="flex items-center gap-2">
              {isSaveClicked && !enableAccountTab('account-tab') && (
                <div className="h-3 w-3">
                  <span className="relative flex h-3 w-3 items-center justify-center">
                    <span className="absolute inline-flex h-full w-full animate-ping rounded-full bg-red-400 opacity-75" />
                    <span className="relative inline-flex h-2 w-2 rounded-full bg-red-500" />
                  </span>
                </div>
              )}
              <h3 className="grow font-medium after:ml-0.5 after:text-red-400 after:content-['*']">
                Select accounts
                {selectedAccounts?.length ? (
                  <span className="ml-1 inline-flex rounded-full bg-gray-700 px-2 py-1 text-xs text-white dark:bg-neutral-700">
                    {selectedAccounts?.length}
                  </span>
                ) : null}
              </h3>
            </div>
            {selectedAccounts && selectedAccounts?.length > 0 && (
              <>
                {selectedAccounts &&
                  selectedAccounts?.map((item, index) => {
                    if (index < accountCount) {
                      return (
                        <div
                          key={`${item?.AccountId}${index}`}
                          className="hidden items-center rounded-md bg-gray-50 px-2 py-0.5 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10 dark:bg-neutral-600/50  dark:text-neutral-300 lg:inline-flex"
                        >
                          <span className="leading-none">
                            {' '}
                            {item?.CID}
                            {item.Name !== '' ? `-${item?.Name}` : ''}
                          </span>
                          <Button
                            variant="subtle"
                            size="small"
                            className="-mr-1 ml-1 px-1"
                            onClick={() => {
                              handleUnselectAccounts(item);
                            }}
                          >
                            <XMarkIcon className="h-3 w-3 shrink-0" />
                          </Button>
                        </div>
                      );
                    }
                  })}

                {selectedAccounts &&
                  selectedAccounts?.length > accountCount && (
                    <Popover>
                      <Popover.Button
                        as={Badge}
                        className="hidden lg:inline-flex"
                      >
                        More
                        <InformationCircleIcon
                          className="-mr-0.5 ml-1 h-5 w-5 text-primary"
                          aria-hidden="true"
                        />
                      </Popover.Button>
                      <Popover.Panel className="absolute z-10 flex max-h-64 w-52 flex-col gap-2 overflow-y-auto rounded-lg bg-white p-2 shadow-xl dark:bg-neutral-700">
                        {selectedAccounts?.map(
                          (item: AccountInfo, index: number) => {
                            if (index >= accountCount) {
                              return (
                                <div
                                  key={`${item?.AccountId}${index}`}
                                  className="flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10  dark:bg-neutral-600/50 dark:text-neutral-300"
                                >
                                  <span className="grow leading-none">
                                    {' '}
                                    {item?.CID}
                                    {item.Name !== '' ? `-${item?.Name}` : ''}
                                  </span>
                                  <Button
                                    variant="subtle"
                                    size="small"
                                    className="-mr-1 ml-1 px-1"
                                    onClick={() => {
                                      handleUnselectAccounts(item);
                                    }}
                                  >
                                    <XMarkIcon className="h-3 w-3" />
                                  </Button>
                                </div>
                              );
                            }
                          },
                        )}
                      </Popover.Panel>
                    </Popover>
                  )}
              </>
            )}
          </div>
        </div>
      </Fragment>
    );
  }

  return (
    <Disclosure
      as="div"
      className="flex flex-col ui-open:min-h-[430px] ui-open:grow"
      id="selected-account"
    >
      {({ open }) => (
        <>
          <Disclosure.Button className="flex w-full items-center justify-between rounded-lg bg-white px-4 py-2 text-left shadow-sm hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-light-focus disabled:pointer-events-none disabled:cursor-not-allowed disabled:opacity-50 ui-open:rounded-b-none dark:bg-neutral-800  dark:hover:bg-neutral-800/70">
            {renderAccountList(open)}
            <ChevronDownIcon
              className={`${
                open ? 'rotate-180 transform' : ''
              } h-5 w-5 shrink-0 text-gray-500 dark:text-white`}
            />
          </Disclosure.Button>
          <Disclosure.Panel className="flex min-h-0 grow flex-col overflow-auto rounded-b-lg border-t bg-gray-50 text-sm text-gray-500 shadow dark:border-neutral-700/50 dark:bg-neutral-800">
            {/* {renderSelectedAccountDisplay()} */}
            <ReactTable
              columns={columns}
              data={filteredData ? filteredData : []}
              columnsSearch={columnsSearch}
              headerUpdateCallBack={headerUpdateCallBack}
              handleSortChange={handleSortChange}
              sortedItem={currentSortItem}
              isLoading={isAccountListLoading}
              isFetchingNextPage={isFetchingNextPage}
              onRowClick={handleRowClick}
              onEndReached={onEndReached}
              selectedRowIds={accountsInfo}
              className="max-w-full overflow-x-auto"
            />
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}

export { AccountSelection };
