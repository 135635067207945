import React, { ReactElement, useRef, useState, useCallback } from 'react';
import { useNavigate } from 'react-router';
import {
  DocumentTextIcon,
  DocumentPlusIcon,
  ClipboardDocumentIcon,
} from '@heroicons/react/20/solid';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import * as Tooltip from '@radix-ui/react-tooltip';
import { Popover } from '@headlessui/react';
import { usePopper } from 'react-popper';

import { TooltipItem, Checkbox, Button } from 'src/ui/components';
import { StickyNote, PrintPreview } from './index';

import { useGetStickyNote } from '../api';
import { useProgressStore } from 'src/hooks/useProgressStore';

import {
  activeStyles,
  stickyNoteMaxLength,
} from '../utils/callDocumentConstants';
import {
  findDescription,
  formatLevelOfCare,
} from '../utils/callDocumentHelperFunctions';

import { useCallDocStore } from '../store/callDocStore';

import {
  CallDocumentType,
  CallTypeAbbreviation,
} from '../types/callDocumentList';
import { CallDocumentsPreviewType } from '../types/callDocumentPreview';

type CallDocListItemType = {
  callDocInfo: CallDocumentType;
  findCallType: (item: string) => Array<CallTypeAbbreviation | undefined>;
  handleRowClick: (item: CallDocumentType) => void;
  selectedDocs: Array<CallDocumentType>;
  callDocuments: Array<CallDocumentType> | undefined;
  indexVal: number;
  onDocumentPreview: () => Promise<any>;
  setPreviewCallId: (item: number | undefined) => void;
  previewContent: CallDocumentsPreviewType | undefined;
  isDocPreviewLoading: boolean;
  setSelectedDocs: (
    value:
      | Array<CallDocumentType>
      | ((prevState: Array<CallDocumentType>) => Array<CallDocumentType>),
  ) => void;
  changeReadStatus: any;
  reqCallDocId: string | undefined | null;
};

dayjs.extend(utc);

function CallDocumentItem({
  callDocInfo,
  findCallType,
  handleRowClick,
  selectedDocs,
  callDocuments,
  indexVal,
  onDocumentPreview,
  previewContent,
  setPreviewCallId,
  isDocPreviewLoading,
  setSelectedDocs,
  changeReadStatus,
  reqCallDocId,
}: CallDocListItemType): ReactElement {
  const navigate = useNavigate();

  const { mutations } = useProgressStore();
  const stickyModalLoading = mutations.isStickyNoteLoading;

  const printButtonRef = useRef<any>(null);

  const [showStickyModal, setShowStickyModal] = useState<boolean>(false);
  const [documentPreviewModal, setDocumentPreviewModal] = useState(false);
  const [currentIndex, setCurrentIndex] = useState<number>(indexVal);
  const [stickyNoteData, setStickyNoteData] = useState<any>();
  const [referenceElement, setReferenceElement] = useState<any>(null);
  const [popperElement, setPopperElement] = useState<any>(null);
  const [arrowElement, setArrowElement] = useState<any>(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [{ name: 'arrow', options: { element: arrowElement } }],
    placement: 'right-start',
  });

  const { mutate: getStickyNote } = useGetStickyNote();

  const setInitialStickyData = useCallDocStore(
    useCallback(state => state.setInitialStickyData, []),
  );
  const setIsPrevCallDocAvailable = useCallDocStore(
    React.useCallback(state => state.setIsPrevCallDocAvailable, []),
  );

  const setIsNextCallDocAvailable = useCallDocStore(
    React.useCallback(state => state.setIsNextCallDocAvailable, []),
  );

  function getStickyDetails(Id: number) {
    getStickyNote(
      { callId: Id },
      {
        onSuccess: data => {
          if (data) {
            setStickyNoteData(data);
            setInitialStickyData(data?.Note);
          }
        },
      },
    );
    setShowStickyModal(true);
  }

  function isChecked(info: CallDocumentType) {
    const checked = selectedDocs?.find(item => item.Id === info.Id);
    if (checked) return true;
    else return false;
  }

  function previewHandler() {
    setPreviewCallId(callDocInfo?.Id);
    setSelectedDocs([callDocInfo]);
    setTimeout(() => {
      onDocumentPreview().then(() => {
        changeStatusToRead();
        setDocumentPreviewModal(true);
      });
    }, 200);
  }

  function onNextClickHandler() {
    const nextItemId = callDocuments?.find(
      (item, index) => index > currentIndex && item.IsPurged !== true,
    )?.Id;

    if (nextItemId) {
      const index = callDocuments?.findIndex(item =>
        Object.values(item).includes(nextItemId),
      );
      index && setCurrentIndex(index);
      setPreviewCallId(nextItemId);
      setIsPrevCallDocAvailable(true);
      setTimeout(() => {
        onDocumentPreview();
      }, 200);
    } else {
      setIsNextCallDocAvailable(false);
    }
  }

  function onPreviousClickHandler() {
    for (let i = currentIndex - 1; i >= 0; i--) {
      if (callDocuments && !callDocuments[i].IsPurged) {
        setCurrentIndex(i);
        setPreviewCallId(callDocuments[i].Id);
        setTimeout(() => {
          onDocumentPreview();
        }, 200);
      }
      return;
    }
    setIsPrevCallDocAvailable(false);
  }

  function onModalClose() {
    setPreviewCallId(undefined);
    setDocumentPreviewModal(false);
    setIsNextCallDocAvailable(true);
    setIsPrevCallDocAvailable(true);
    setSelectedDocs([]);
    if (reqCallDocId) navigate('/CallDocuments');
  }

  function changeStatusToRead() {
    if (!callDocInfo?.IsRead) {
      const callIds = [
        {
          Id: callDocInfo?.Id,
          IsRead: true,
        },
      ];

      changeReadStatus(callIds, {
        onSuccess: () => {
          setSelectedDocs([]);
        },
      });
    }
  }

  function renderCallTypeField(): ReactElement {
    return (
      <div className="basis-[48%] p-2 md:basis-[30%] lg:basis-[25%] xl:basis-[10%] 2xl:basis-[10%]">
        {callDocInfo?.CallType?.split(',')?.length === 1 ? (
          <>
            {' '}
            <label className="block whitespace-nowrap text-[10px] font-medium uppercase tracking-wide opacity-70">
              Call Type
            </label>
            <p
              className={`prevent-text-breakout mt-0.5 text-xs font-bold tracking-wide text-black dark:text-white ${
                callDocInfo.IsRead ? '!font-normal' : ''
              }`}
            >
              {findCallType(callDocInfo?.CallType) !== undefined
                ? findDescription(findCallType(callDocInfo?.CallType))
                : ''}
            </p>
          </>
        ) : (
          <TooltipItem
            label="Call type"
            isRead={callDocInfo.IsRead}
            values={
              findCallType(callDocInfo?.CallType) !== undefined
                ? findDescription(findCallType(callDocInfo?.CallType))
                : ''
            }
          />
        )}
      </div>
    );
  }

  function renderStickyModal(callDocInfo: CallDocumentType): ReactElement {
    return (
      <>
        {callDocInfo &&
        (callDocInfo?.StickyNote === null || callDocInfo?.StickyNote === '') ? (
          <Button
            variant="subtle"
            size="small"
            title={
              stickyNoteData?.Note === undefined ||
              callDocInfo?.StickyNote === null ||
              callDocInfo?.StickyNote === ''
                ? 'Add sticky notes'
                : ''
            }
            className="grow"
            onClick={e => {
              callDocInfo?.IsPurged !== true && setSelectedDocs([callDocInfo]);
              setShowStickyModal(true);
              getStickyDetails(callDocInfo?.Id);
              e.stopPropagation();
            }}
          >
            <DocumentPlusIcon className="h-5 w-5" aria-hidden="true" />
            <span className="sr-only">Add sticky notes</span>
          </Button>
        ) : (
          <>
            <Popover className="flex grow lg:hidden">
              <Popover.Button
                as={Button}
                variant="subtle"
                size="small"
                className="self-stretch"
                ref={setReferenceElement}
              >
                <DocumentTextIcon
                  className="h-5 w-5 text-primary"
                  aria-hidden="true"
                />
                <span className="sr-only">View sticky notes</span>
              </Popover.Button>
              <Popover.Panel
                as="div"
                className="z-10 max-w-72 select-none rounded bg-yellow-100 px-3 py-2 text-sm shadow-lg dark:bg-neutral-700"
                ref={setPopperElement}
                style={styles.popper}
                {...attributes.popper}
              >
                <div ref={setArrowElement} style={styles.arrow} />
                <p className="line-clamp-3">{callDocInfo?.StickyNote}</p>
                <Button
                  variant="link"
                  size="small"
                  className="-ml-2"
                  onClick={e => {
                    (document.activeElement as HTMLElement).blur();
                    callDocInfo?.IsPurged !== true
                      ? printButtonRef.current?.focus()
                      : printButtonRef.current?.nextElementSibling?.focus();
                    callDocInfo?.IsPurged !== true &&
                      setSelectedDocs([callDocInfo]);
                    setShowStickyModal(true);
                    getStickyDetails(callDocInfo?.Id);
                    e.stopPropagation();
                  }}
                >
                  View more
                </Button>
              </Popover.Panel>
            </Popover>
            <Tooltip.Provider>
              <Tooltip.Root disableHoverableContent={false}>
                <Tooltip.Trigger asChild className="hidden lg:block">
                  <Button
                    variant="subtle"
                    size="small"
                    className="grow"
                    onClick={e => {
                      (document.activeElement as HTMLElement).blur();
                      callDocInfo?.IsPurged !== true
                        ? printButtonRef.current?.focus()
                        : printButtonRef.current?.nextElementSibling?.focus();
                      callDocInfo?.IsPurged !== true &&
                        setSelectedDocs([callDocInfo]);
                      setShowStickyModal(true);
                      getStickyDetails(callDocInfo?.Id);
                      e.stopPropagation();
                    }}
                  >
                    <DocumentTextIcon
                      className="h-5 w-5 text-primary"
                      aria-hidden="true"
                    />
                    <span className="sr-only">View sticky notes</span>
                  </Button>
                </Tooltip.Trigger>
                <Tooltip.Portal>
                  <Tooltip.Content
                    className="z-10 max-w-xs select-none rounded bg-yellow-100 px-3 py-2 text-sm shadow-lg dark:bg-neutral-700"
                    sideOffset={5}
                  >
                    <p className="line-clamp-3">{callDocInfo?.StickyNote}</p>
                    {callDocInfo?.StickyNote &&
                      callDocInfo?.StickyNote?.length > stickyNoteMaxLength && (
                        <div className="mt-2">
                          <Button
                            variant="link"
                            size="small"
                            className="-ml-2"
                            onClick={e => {
                              callDocInfo?.IsPurged !== true &&
                                setSelectedDocs([callDocInfo]);
                              setShowStickyModal(true);
                              getStickyDetails(callDocInfo?.Id);
                              e.stopPropagation();
                            }}
                          >
                            View more
                          </Button>
                        </div>
                      )}
                    <Tooltip.Arrow className="fill-yellow-100 dark:fill-neutral-700" />
                  </Tooltip.Content>
                </Tooltip.Portal>
              </Tooltip.Root>
            </Tooltip.Provider>
          </>
        )}
        {stickyNoteData && !stickyModalLoading && (
          <StickyNote
            stickyNoteModal={showStickyModal}
            setStickyNoteModal={setShowStickyModal}
            stickyNoteData={stickyNoteData}
            setStickyNoteData={setStickyNoteData}
            stickyModalLoading={stickyModalLoading}
            setSelectedDocs={setSelectedDocs}
          />
        )}
      </>
    );
  }

  return (
    <>
      <div
        tabIndex={0}
        className={`relative flex flex-nowrap rounded border focus-visible:outline focus-visible:outline-[3px] focus-visible:outline-offset-[3px] focus-visible:outline-blue-600  dark:focus-visible:outline-blue-400 ${
          isChecked(callDocInfo) ? activeStyles : null
        } ${
          callDocInfo.IsRead
            ? 'border-gray-300 bg-gray-200 dark:border-transparent dark:bg-neutral-900'
            : 'border-transparent bg-white shadow dark:bg-neutral-600'
        }`}
        onClick={e => {
          e.stopPropagation();
          handleRowClick(callDocInfo);
        }}
        onDoubleClick={e => {
          e.stopPropagation();
          if (
            callDocInfo?.IsPurged === false &&
            !showStickyModal &&
            !documentPreviewModal
          )
            previewHandler();
        }}
      >
        <div
          className={`relative grid place-content-center border-r p-2 dark:border-neutral-700 ${
            callDocInfo.IsRead ? 'border-gray-300 dark:border-neutral-800' : ''
          }`}
        >
          <Checkbox
            id="itemSelection1"
            onChange={() => handleRowClick(callDocInfo)}
            checked={isChecked(callDocInfo)}
          />
        </div>
        <div
          className={`flex flex-grow flex-wrap gap-1 px-4 py-2 ${
            callDocInfo.IsRead ? 'opacity-90 dark:opacity-70' : ''
          }`}
        >
          <div
            className={`flex min-w-[120px] basis-[48%] flex-col gap-1 p-2 md:basis-[30%] lg:basis-[25%] xl:basis-[3%] 2xl:basis-[7%] `}
          >
            <div className="flex items-center gap-2">
              <label className="block min-w-[42px]  whitespace-nowrap text-[10px] font-medium uppercase tracking-wide opacity-70">
                Call ID
              </label>
              <p
                className={`text-xs font-bold tracking-wide text-black dark:text-white ${
                  callDocInfo.IsRead ? '!font-normal' : ''
                }`}
                title={`${callDocInfo?.Id}`}
              >
                {callDocInfo?.Id}
              </p>
            </div>
            <div className="flex items-center gap-2">
              <label className="block min-w-[42px]  whitespace-nowrap text-[10px] font-medium uppercase tracking-wide opacity-70">
                Case ID
              </label>
              <p
                className={`text-xs font-bold tracking-wide text-black dark:text-white ${
                  callDocInfo.IsRead ? '!font-normal' : ''
                }`}
              >
                {callDocInfo?.CaseId}
              </p>
            </div>
          </div>
          <div className="flex min-w-[85px] basis-[48%] flex-col gap-1 p-2 md:basis-[30%] lg:basis-[25%] xl:basis-[2%] 2xl:basis-[5%]">
            <div className="flex items-center gap-2">
              <label className="block min-w-[22px]  whitespace-nowrap text-[10px] font-medium uppercase tracking-wide opacity-70">
                CID
              </label>
              <p
                className={`text-xs font-bold tracking-wide text-black dark:text-white ${
                  callDocInfo.IsRead ? '!font-normal' : ''
                }`}
              >
                {callDocInfo?.CID}
              </p>
            </div>
            <div className="flex items-center gap-2">
              <label className="block min-w-[22px]  whitespace-nowrap text-[10px] font-medium uppercase tracking-wide opacity-70">
                PID
              </label>
              <p
                className={`text-xs font-bold tracking-wide text-black dark:text-white ${
                  callDocInfo.IsRead ? '!font-normal' : ''
                }`}
              >
                {callDocInfo?.PID}
              </p>
            </div>
          </div>
          <div className="min-w-[210px] basis-[48%] p-2 md:basis-[30%] lg:basis-[25%] xl:basis-[10%] 2xl:basis-[12%]">
            <label className="block whitespace-nowrap text-[10px] font-medium uppercase tracking-wide opacity-70">
              Start time - Duration HH:MM:SS
            </label>
            <p
              className={`prevent-text-breakout mt-0.5 text-xs font-bold tracking-wide text-black dark:text-white ${
                callDocInfo.IsRead ? '!font-normal' : ''
              }`}
            >
              {dayjs(callDocInfo?.Started).format('MM/DD/YYYY H:mm:ss')} -{' '}
              {callDocInfo?.Ended !== null
                ? dayjs
                    .utc(
                      dayjs(callDocInfo?.Ended).diff(
                        dayjs(callDocInfo?.Started),
                      ),
                    )
                    .format('HH:mm:ss')
                : ''}
            </p>
          </div>
          <div className="basis-[48%] p-2 md:basis-[30%] lg:basis-[25%] xl:basis-[8%] 2xl:basis-[10%]">
            <label className="block whitespace-nowrap text-[10px] font-medium uppercase tracking-wide opacity-70">
              Caller
            </label>
            <p
              className={`prevent-text-breakout mt-0.5 text-xs font-bold tracking-wide text-black dark:text-white ${
                callDocInfo?.IsPurged
                  ? 'font-medium text-red-600 !opacity-100 dark:text-red-500'
                  : ''
              } ${callDocInfo.IsRead ? '!font-normal' : ''}`}
              title={
                callDocInfo?.IsPurged
                  ? 'Purged'
                  : `${
                      callDocInfo?.CallerFirstName
                        ? callDocInfo?.CallerFirstName
                        : ''
                    } ${
                      callDocInfo?.CallerLastName
                        ? callDocInfo?.CallerLastName
                        : ''
                    }`
              }
            >
              {callDocInfo?.IsPurged
                ? 'Purged'
                : `${
                    callDocInfo?.CallerFirstName
                      ? callDocInfo?.CallerFirstName
                      : ''
                  } ${
                    callDocInfo?.CallerLastName
                      ? callDocInfo?.CallerLastName
                      : ''
                  }`}
            </p>
          </div>
          <div className="basis-[48%] p-2 md:basis-[30%] lg:basis-[25%] xl:basis-[8%] 2xl:basis-[10%]">
            <label className="block whitespace-nowrap text-[10px] font-medium uppercase tracking-wide opacity-70">
              POC
            </label>
            <p
              className={`prevent-text-breakout mt-0.5 text-xs font-bold tracking-wide text-black dark:text-white ${
                callDocInfo?.IsPurged
                  ? 'font-medium text-red-600 !opacity-100 dark:text-red-500'
                  : ''
              } ${callDocInfo.IsRead ? '!font-normal' : ''}`}
              title={
                callDocInfo?.IsPurged
                  ? 'Purged'
                  : `${
                      callDocInfo?.PersonOfConcernFirstName
                        ? callDocInfo.PersonOfConcernFirstName
                        : ''
                    } ${
                      callDocInfo?.PersonOfConcernLastName
                        ? callDocInfo.PersonOfConcernLastName
                        : ''
                    }`
              }
            >
              {' '}
              {callDocInfo?.IsPurged
                ? 'Purged'
                : `${
                    callDocInfo?.PersonOfConcernFirstName
                      ? callDocInfo.PersonOfConcernFirstName
                      : ''
                  } ${
                    callDocInfo?.PersonOfConcernLastName
                      ? callDocInfo.PersonOfConcernLastName
                      : ''
                  }`}
            </p>
          </div>
          {renderCallTypeField()}
          <div className="basis-[48%] p-2 md:basis-[30%] lg:basis-[25%] xl:max-w-[200px] xl:basis-[10%] 2xl:basis-[10%]">
            <label className="block whitespace-nowrap text-[10px] font-medium uppercase tracking-wide opacity-70">
              LOC
            </label>
            <p
              className={`prevent-text-breakout mt-0.5 text-xs font-bold tracking-wide text-black dark:text-white ${
                callDocInfo.IsRead ? '!font-normal' : ''
              }`}
            >
              {formatLevelOfCare(callDocInfo?.LevelOfCare)}
            </p>
          </div>
          <div className="basis-[48%] p-2 md:basis-[30%] lg:basis-[25%] xl:max-w-[250px] xl:basis-[11%] 2xl:basis-[16%]">
            <label className="block whitespace-nowrap text-[10px] font-medium uppercase tracking-wide opacity-70">
              Clinical outcome
            </label>
            <p
              className={`prevent-text-breakout mt-0.5 text-xs font-bold tracking-wide text-black dark:text-white ${
                callDocInfo.IsRead ? '!font-normal' : ''
              }`}
            >
              {callDocInfo?.ClinicalOutcome}
            </p>
          </div>
          <div className="basis-[48%] p-2 md:basis-[30%] lg:basis-[25%] xl:basis-[14%] 2xl:basis-[10%]">
            <label className="block whitespace-nowrap text-[10px] font-medium uppercase tracking-wide opacity-70">
              Uploaded date &amp; time
            </label>
            <p
              className={`prevent-text-breakout mt-0.5 text-xs font-bold tracking-wide text-black dark:text-white ${
                callDocInfo.IsRead ? '!font-normal' : ''
              }`}
            >
              {dayjs(callDocInfo?.Finalized).format('MM/DD/YYYY H:mm:ss')}
            </p>
          </div>
          {callDocInfo &&
          callDocInfo?.TagId &&
          callDocInfo?.TagName !== '' &&
          callDocInfo?.TagName !== ' ' &&
          callDocInfo?.TagName !== null ? (
            <div className="basis-[48%] p-2 md:basis-[30%] lg:basis-[25%] xl:basis-[3%] 2xl:basis-[10%]">
              <span className="inline-flex items-center whitespace-nowrap rounded-md bg-blue-50 px-2 py-1 text-xs font-medium text-blue-800 ring-1 ring-inset ring-blue-600  dark:bg-blue-900/60 dark:text-blue-200 ">
                {callDocInfo?.TagName}
              </span>
            </div>
          ) : null}
        </div>
        <div
          className={`flex flex-col items-center justify-around gap-0.5 border-l p-2 dark:border-neutral-700 sm:flex-row ${
            callDocInfo.IsRead ? 'border-gray-300 dark:border-neutral-800' : ''
          }`}
        >
          {renderStickyModal(callDocInfo)}

          <Button
            variant="subtle"
            size="small"
            title="Preview"
            className="grow"
            disabled={callDocInfo.IsPurged}
            onClick={e => {
              e.stopPropagation();
              previewHandler();
            }}
            ref={printButtonRef}
          >
            <ClipboardDocumentIcon className="h-5 w-5" aria-hidden="true" />
            <span className="sr-only">Preview</span>
          </Button>
          {callDocInfo.IsPurged ? (
            <a className="sr-only" aria-hidden="true" href="#" />
          ) : null}
          {documentPreviewModal && (
            <PrintPreview
              showPreviewModal={documentPreviewModal}
              report={previewContent}
              onNextClickHandler={onNextClickHandler}
              onPreviousClickHandler={onPreviousClickHandler}
              callDocuments={callDocuments}
              onModalClose={onModalClose}
              isDocPreviewLoading={isDocPreviewLoading}
              setDocumentPreviewModal={setDocumentPreviewModal}
              setSelectedDocs={setSelectedDocs}
            />
          )}
        </div>
      </div>
    </>
  );
}

export { CallDocumentItem };
