import { useQuery, UseQueryResult } from 'react-query';

import { useAuth } from 'src/hooks';

import { getPersonAlertsReason } from 'src/utils/urls';

import { PersonAlertsReasonType } from '../types/personAlertsAccounts';

async function fetchPersonAlertsReason({
  token,
  tokenType,
}: TVariables): Promise<APIResult> {
  const response = await fetch(getPersonAlertsReason, {
    method: 'GET',
    headers: {
      Authorization: `${tokenType} ${token}`,
    },
  });
  return response.json();
}

export type APIResult = Array<PersonAlertsReasonType>;

type TError = { message: string };
type TVariables = {
  token: string;
  tokenType: string;
};

function useGetPersonAlertsReason(): UseQueryResult<APIResult, TError> {
  const { token, tokenType } = useAuth();
  return useQuery(
    ['get_person_alerts_reason'],
    async () => {
      const result = await fetchPersonAlertsReason({
        token,
        tokenType,
      });
      return result;
    },
    {
      staleTime: Infinity,
      refetchOnWindowFocus: false,
    },
  );
}

export { useGetPersonAlertsReason };
