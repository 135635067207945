import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

type AuthRouteType = {
  permission: boolean;
  redirectPath?: string;
  children?: any;
};

const AuthorizationRoute = ({
  permission,
  redirectPath = '/unauthorized',
  children,
}: AuthRouteType) => {
  if (!permission) {
    return <Navigate to={redirectPath} replace />;
  }
  return children ? children : <Outlet />;
};
export { AuthorizationRoute };
