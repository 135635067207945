import { UseMutationResult, useMutation, useQueryClient } from 'react-query';

import { revokeUser } from 'src/utils/urls';
import { useAuth } from 'src/hooks';

async function deleteUser({
  token,
  tokenType,
  userId,
}: TVariables): Promise<any> {
  return await fetch(revokeUser(userId), {
    method: 'DELETE',
    headers: {
      Authorization: `${tokenType} ${token}`,
      'Content-Type': 'application/json',
    },
  });
}
type TVariables = {
  token: string;
  tokenType: string;
  userId: string;
};
type TError = {
  message: string;
};

function useRevokeAccess(): UseMutationResult<void, TError, string, unknown> {
  const { token, tokenType } = useAuth();
  const queryClient = useQueryClient();

  return useMutation(async (userId: string) => {
    deleteUser({
      token,
      tokenType,
      userId,
    });
    queryClient.invalidateQueries('get_users_list');
  });
}
export { useRevokeAccess };
