import { create } from 'zustand';

type ProgressStoreType = {
  mutations: Record<string, boolean>;
  setLoading: (mutationName: string, status: boolean) => void;
};

export const useProgressStore = create<ProgressStoreType>(set => ({
  mutations: {},
  setLoading: (mutationName: string, status: boolean) =>
    set(state => ({
      ...state,
      mutations: {
        ...state.mutations,
        [mutationName]: status,
      },
    })),
}));
