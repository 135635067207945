import { UseMutationResult, useMutation, useQueryClient } from 'react-query';

import { useAuth } from 'src/hooks';

import { useProgressStore } from 'src/hooks/useProgressStore';
import { removeProvider } from 'src/utils/urls';

async function deleteProvider({
  token,
  tokenType,
  providerId,
}: TVariables): Promise<any> {
  return await fetch(removeProvider(providerId), {
    method: 'DELETE',
    headers: {
      Authorization: `${tokenType} ${token}`,
      'Content-Type': 'application/json',
    },
  });
}

type TVariables = {
  token: string;
  tokenType: string;
  providerId: string;
};

type TError = {
  message: string;
};

function useRemoveProvider(): UseMutationResult<void, TError, string, unknown> {
  const { token, tokenType } = useAuth();
  const queryClient = useQueryClient();
  const { setLoading } = useProgressStore();

  return useMutation(
    async (providerId: string) => {
      setLoading('isUpdateProviderLoading', true);
      deleteProvider({
        token,
        tokenType,
        providerId,
      });
    },
    {
      onSettled: () => {
        queryClient.invalidateQueries(['get-resource-list']);
        setLoading('isUpdateProviderLoading', false);
      },
    },
  );
}
export { useRemoveProvider };
