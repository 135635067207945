import { useMutation, UseMutationResult } from 'react-query';

import { useAuth } from 'src/hooks';
import { useProgressStore } from 'src/hooks/useProgressStore';

import { settingsResetPassword } from 'src/utils/urls';

export async function resetPassword({
  requestData,
  token,
  tokenType,
}: {
  requestData: any;
  token: string;
  tokenType: string;
}): Promise<TResult> {
  const response = await fetch(settingsResetPassword, {
    method: 'POST',
    headers: {
      Authorization: `${tokenType} ${token}`,
      'content-type': 'application/x-www-form-urlencoded',
    },
    body: requestData,
  });
  if (response.status === 200) return 'Success';
  else return response.json();
}

export type ValidationError = { Message?: string };
export type TResult = string | ValidationError;
export type TVariables = {
  CurrentPassword: string;
  NewPassword: string;
};
export type TError = {
  message: string;
};
export type TSnapshot = unknown;

function setRequestData(params: TVariables): FormData {
  const formData = new URLSearchParams();
  formData.append('CurrentPassword', `${params.CurrentPassword}`);
  formData.append('NewPassword', `${params.NewPassword}`);
  return formData;
}

function useResetPassword(): UseMutationResult<
  TResult,
  TError,
  TVariables,
  TSnapshot
> {
  const { token, tokenType } = useAuth();
  const { setLoading } = useProgressStore();

  return useMutation(
    async (params: TVariables) => {
      setLoading('isResetPasswordLoading', true);
      const requestData = setRequestData(params);
      const result = await resetPassword({
        requestData: requestData,
        token: token,
        tokenType: tokenType,
      });
      if (result === 'Success') return result;
      else throw result;
    },
    {
      onSettled: () => {
        setLoading('isResetPasswordLoading', false);
      },
    },
  );
}

export { useResetPassword };
