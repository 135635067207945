import React, {
  SyntheticEvent,
  useCallback,
  useRef,
  useEffect,
  useState,
} from 'react';
import Select, { components, StylesConfig, createFilter } from 'react-select';
import { ChevronDownIcon } from '@heroicons/react/16/solid';

import { inputLabelClasses } from '../../../ui/components/TextField';
import {
  buttonBaseClasses,
  buttonSizeClasses,
  buttonVariantClasses,
} from '../../../ui/components/Button';
import {
  baseClasses as checkboxBaseClasses,
  sizeClasses as checkboxSizeClasses,
} from '../../../ui/components/Checkbox';

import { useHomeStore } from 'src/features/Home/store/homeStore';
import { classNames } from 'src/features/Header/utils/getClassNames';

type SearchableMultiSelectCheckboxType = {
  options: any;
  elementRef?: any;
  styles?: StylesConfig;
  width?: number | string;
  minWidth?: number | string;
  maxWidth?: number | string;
  id?: string;
  isAllAccountsLoading?: boolean;
  placeholder?: string;
  size?: any;
  className?: any;
  position?: any;
};

function SearchableMultiSelectCheckbox({
  options,
  placeholder,
  elementRef,
}: SearchableMultiSelectCheckboxType) {
  const [inputState, setInputState] = useState<string | undefined>('');

  const setSelectedOptions = useHomeStore(
    useCallback(state => state.setSelectedOptions, []),
  );
  const selectedOptions = useHomeStore(
    useCallback(state => state.selectedOptions, []),
  );

  const filteredArr = useHomeStore(useCallback(state => state.filteredArr, []));
  const setFilteredArr = useHomeStore(
    useCallback(state => state.setFilteredArr, []),
  );

  function getSelectedItemsCount() {
    const optionsLength = options
      ?.map((item: any) => item?.options)
      .flat().length;
    if (selectedOptions?.length === 0 || selectedOptions?.length === undefined)
      return 'My accounts';
    if (selectedOptions && options) {
      if (
        selectedOptions?.length === optionsLength ||
        selectedOptions?.length > optionsLength
      )
        return 'My accounts';
      return `Select (${
        selectedOptions?.filter((value: any) => value.value !== 0)?.length
      })`;
    }
  }

  const onChange = (value: any, event: any) => {
    if (event.action === 'clear' && setInputState) {
      setInputState('');
      return;
    }
    const optionValue = options.map((item: any) => item?.options).flat();
    if (
      event.action === 'select-option' &&
      event.option.value === 0 &&
      filteredArr?.length === 0
    ) {
      setSelectedOptions && setSelectedOptions([...optionValue]);
    } else if (
      event.action === 'select-option' &&
      event.option.value === 0 &&
      filteredArr?.length !== 0
    ) {
      if (selectedOptions?.length === 0 && setSelectedOptions && filteredArr) {
        setSelectedOptions([...filteredArr]);
      } else {
        let newItems = filteredArr?.filter(
          (value1: any) =>
            !selectedOptions?.some(
              (value2: any) => value1?.label === value2?.label,
            ),
        );
        if (setSelectedOptions && selectedOptions && newItems)
          setSelectedOptions([...selectedOptions, ...newItems]);
      }
    } else if (
      event.action === 'deselect-option' &&
      event.option.value === 0 &&
      filteredArr?.length !== 0
    ) {
      let remainingItems = selectedOptions?.filter(
        (value1: any) =>
          !filteredArr?.some((value2: any) => value1?.label === value2?.label),
      );

      setSelectedOptions &&
        remainingItems &&
        setSelectedOptions([...remainingItems]);
    } else if (
      event.action === 'deselect-option' &&
      event.option.value === 0 &&
      filteredArr?.length === 0
    ) {
      setSelectedOptions && setSelectedOptions([]);
    } else if (event.action === 'deselect-option') {
      setSelectedOptions &&
        setSelectedOptions(value.filter((o: any) => o.value !== 0));
    } else if (value.length === optionValue.length) {
      setSelectedOptions && setSelectedOptions([...optionValue]);
    } else {
      setSelectedOptions && setSelectedOptions(value);
    }
  };

  const InputOption = ({ children, ...props }: any) => {
    const { innerProps, isFocused, ...otherProps } = props;
    const { onMouseMove, onMouseOver, ...otherInnerProps } = innerProps;
    const newProps = { innerProps: { ...otherInnerProps }, ...otherProps };

    return (
      <>
        <components.Option {...newProps} isSelected={props.isSelected}>
          <div className="flex items-start space-x-2">
            {children && (
              <input
                type="checkbox"
                defaultChecked={props.isSelected}
                className={classNames(
                  checkboxBaseClasses,
                  checkboxSizeClasses.small,
                  'peer disabled:opacity-40',
                )}
              />
            )}
            <label
              className={classNames(
                inputLabelClasses,
                'prevent-text-breakout',
                'leading-tight peer-disabled:opacity-40',
              )}
            >
              {children}
            </label>
          </div>
        </components.Option>
      </>
    );
  };

  const Group = (props: any) => {
    const optionsLength = options
      ?.map((item: any) => item?.options)
      .flat().length;

    function isCheckboxSelected() {
      let isAllElementPresent = filteredArr?.filter(
        (value1: any) =>
          !selectedOptions?.some(
            (value2: any) => value1?.label === value2?.label,
          ),
      );

      if (isAllElementPresent?.length === 0 && filteredArr?.length !== 0)
        return true;
      else {
        if (
          (options &&
            selectedOptions &&
            selectedOptions?.length > optionsLength) ||
          selectedOptions?.length === optionsLength
        )
          return true;
        else return false;
      }
    }

    function isSelectAllVisible() {
      if (selectedOptions?.length === 0 && props?.data?.label === '')
        return true;
      else if (
        selectedOptions?.length !== 0 &&
        props?.data?.label === 'Selected Accounts'
      )
        return true;
      else if (
        selectedOptions?.length !== 0 &&
        filteredArr?.filter(item =>
          selectedOptions?.some(option => option?.label === item?.label),
        )?.length === 0 &&
        inputState !== ''
      )
        return true;
    }
    return (
      <div>
        {isSelectAllVisible() ? (
          <div className="flex items-start space-x-2 border-b border-light-light px-3 py-2 dark:border-neutral-600">
            <input
              name="selectAll"
              type="checkbox"
              id="selectAll"
              className={classNames(
                checkboxBaseClasses,
                checkboxSizeClasses.small,
                'peer disabled:opacity-40',
              )}
              checked={isCheckboxSelected()}
              onChange={e => {
                const value =
                  e.currentTarget.checked === true
                    ? {
                        action: 'select-option',
                        option: { value: 0 },
                      }
                    : { action: 'deselect-option', option: { value: 0 } };
                onChange(options, value);
              }}
            />
            <label
              htmlFor="selectAll"
              className={classNames(
                inputLabelClasses,
                'leading-tight peer-disabled:opacity-40',
              )}
            >
              Select all
            </label>
          </div>
        ) : null}
        <components.Group {...props}></components.Group>
      </div>
    );
  };

  const handleInputChange = (value: any, action: any) => {
    const fullOptions = options?.map((item: any) => item?.options).flat();
    if (action.action === 'input-blur' || action.action === 'menu-close')
      return;
    if (action.action === 'input-change' && setInputState) setInputState(value);
    if (value !== '' && action.action === 'input-change') {
      const temp = fullOptions?.filter((item: any) => {
        return item?.label?.toLowerCase().includes(value);
      });

      if (setFilteredArr && temp) setFilteredArr(temp);
    } else {
      if (action.action === 'input-change' && setFilteredArr)
        setFilteredArr([]);
    }
  };

  const selectWrapperRef = useRef() as React.MutableRefObject<HTMLDivElement>;

  const [accountsVisibility, setAccountsVisibility] = useState(false);

  const handleMenuButton = (e: SyntheticEvent) => {
    setAccountsVisibility(!accountsVisibility);
  };

  useEffect(() => {
    elementRef?.current?.focus();

    document.addEventListener('keydown', e => {
      if (e.key === 'Escape') setAccountsVisibility(false);
    });

    const handleClickOutside = (e: any) => {
      if (
        selectWrapperRef.current &&
        !selectWrapperRef.current.contains(e.target)
      ) {
        setAccountsVisibility(false);
      }
    };

    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [elementRef, accountsVisibility]);

  return (
    <div ref={selectWrapperRef} className="relative grow">
      <button
        className={classNames(
          buttonBaseClasses,
          buttonVariantClasses.secondary,
          buttonSizeClasses.medium,
          'w-full justify-between',
        )}
        onClick={handleMenuButton}
      >
        {getSelectedItemsCount()}
        <ChevronDownIcon className="h-4 w-4" />
      </button>
      {accountsVisibility ? (
        <div
          className="absolute z-10 mt-1 w-full overflow-hidden rounded border border-light-light bg-white shadow dark:border-dark-medium dark:bg-neutral-700"
          style={{
            inset: '100% auto auto 0',
          }}
        >
          <Select
            options={options}
            isSearchable={true}
            menuIsOpen={true}
            isMulti
            value={selectedOptions}
            onChange={onChange}
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            placeholder={placeholder}
            components={{
              Group,
              Option: InputOption,
            }}
            controlShouldRenderValue={false}
            filterOption={createFilter({ ignoreAccents: false })} //search optimization
            backspaceRemovesValue={false}
            blurInputOnSelect={false}
            isClearable={false}
            onInputChange={handleInputChange}
            inputValue={inputState}
            classNamePrefix="searchable-multiselect"
            ref={elementRef}
          />
        </div>
      ) : (
        ''
      )}
    </div>
  );
}

export { SearchableMultiSelectCheckbox };
