import React, { ReactElement, Fragment, useMemo } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import dayjs from 'dayjs';
import { XMarkIcon } from '@heroicons/react/20/solid';

import { ReadyToChatIcon, NotReadyToChatIcon } from 'src/assets/icons/index';

import { useGetCountry } from 'src/hooks';

import { getFormName } from '../utils/activityTrackerHelperFn';
import { countryOptions } from 'src/utils/common';

import { ActivityTrackerPreviewType } from '../types/activityTrackerPreviewType';
import { ActivityTrackerListType } from '../types/activityTrackerListType';

type PreviewModalType = {
  documentPreviewModal: boolean;
  setDocumentPreviewModal: (
    value: boolean | ((prevState: boolean) => boolean),
  ) => void;
  previewData: ActivityTrackerPreviewType | undefined;
  phoneType: Array<{ Description: string; Id: number }> | undefined;
  setSelectedTracker: (
    value:
      | ActivityTrackerListType
      | undefined
      | ((
          prevState: ActivityTrackerListType | undefined,
        ) => ActivityTrackerListType | undefined),
  ) => void;
};

export function ActivityTrackerPreview({
  documentPreviewModal,
  setDocumentPreviewModal,
  previewData,
  phoneType,
  setSelectedTracker,
}: PreviewModalType): ReactElement {
  const { data: countryList } = useGetCountry();

  function getCountryCode(id: any) {
    if (countryList && id)
      return countryList.find((x: any) => x.Id == id)?.ISDCode;
    return '';
  }

  function getPhoneDescription(
    id: number | undefined | null,
  ): string | undefined {
    if (id && phoneType) return phoneType?.find(x => x.Id === id)?.Description;
  }

  function renderCallerInformation() {
    return (
      <dl className="border dark:border-dark-medium">
        <dt className="sticky -top-px border-b bg-gray-100 px-3 py-2 text-xl font-semibold dark:border-dark-medium dark:bg-neutral-900">
          Caller information
        </dt>
        <dd className="flex gap-2 border-b dark:border-dark-medium">
          <h4 className="w-1/3 p-2 text-sm font-medium">Caller</h4>
          <div className="w-4/6 border-l border-l-light-light p-2 text-sm dark:border-l-dark-medium">
            <p>
              {`${previewData?.Caller?.FirstName} ${previewData?.Caller?.LastName}`}
            </p>
          </div>
        </dd>
        <dd className="flex gap-2 border-b dark:border-dark-medium">
          <h4 className="w-1/3 p-2 text-sm font-medium">
            Primary phone number
          </h4>
          <div className="w-4/6 border-l border-l-light-light p-2 text-sm dark:border-l-dark-medium">
            {previewData?.Caller?.PrimaryPhone?.Number !== null && (
              <>
                <p>
                  {previewData?.Caller?.PrimaryPhone?.CountryId !== null && (
                    <label>Country code :</label>
                  )}
                  {getCountryCode(previewData?.Caller?.PrimaryPhone?.CountryId)}
                </p>
                <p>
                  {previewData?.Caller?.PrimaryPhone?.PhoneTypeId && (
                    <label className="text-sm font-medium">
                      {getPhoneDescription(
                        previewData?.Caller?.PrimaryPhone?.PhoneTypeId,
                      )}
                      :{' '}
                    </label>
                  )}
                  {previewData?.Caller?.PrimaryPhone?.Number}{' '}
                </p>
                <p>
                  {previewData?.Caller?.PrimaryPhone?.OkToLeaveMessage ===
                  true ? (
                    <>
                      <label className="text-sm font-medium">
                        Is it okay to leave messages:
                      </label>{' '}
                      <ReadyToChatIcon className="ms-1 text-green-700 dark:text-green-400" />
                    </>
                  ) : (
                    previewData?.Caller?.PrimaryPhone?.OkToLeaveMessage ===
                      false && (
                      <>
                        <label className="text-sm font-medium">
                          Is it okay to leave messages:
                        </label>
                        <NotReadyToChatIcon className="ms-1 text-red-700 dark:text-red-400" />
                      </>
                    )
                  )}
                </p>
                <p>
                  {previewData?.Caller?.PrimaryPhone?.Comments !== null && (
                    <label>Comments: </label>
                  )}
                  {previewData?.Caller?.PrimaryPhone?.Comments}
                </p>
              </>
            )}
          </div>
        </dd>
      </dl>
    );
  }

  function renderPocInformation() {
    return (
      <dl className="border dark:border-dark-medium">
        <dt className="sticky -top-px border-b bg-gray-100 px-3 py-2 text-xl font-semibold dark:border-dark-medium dark:bg-neutral-900">
          Person of Concern information
        </dt>
        <dd className="flex gap-2 border-b dark:border-dark-medium">
          <h4 className="w-1/3 p-2 text-sm font-medium">Person of Concern</h4>
          <div className="w-4/6 border-l border-l-light-light p-2 text-sm dark:border-l-dark-medium">
            <p>{`${previewData?.PersonOfConcern?.FirstName} ${previewData?.PersonOfConcern?.LastName}`}</p>
          </div>
        </dd>

        <dd className="flex gap-2  border-b dark:border-dark-medium">
          <h4 className="w-1/3 p-2 text-sm font-medium">
            Legal first name/ Preferred name
          </h4>
          <div className="w-4/6 border-l border-l-light-light p-2 text-sm dark:border-l-dark-medium">
            <p>{previewData?.PersonOfConcern?.PreferredName}</p>
          </div>
        </dd>
        <dd className="flex gap-2  border-b dark:border-dark-medium">
          <h4 className="w-1/3 p-2 text-sm font-medium">
            Primary phone number
          </h4>
          <div className="w-4/6 border-l border-l-light-light p-2 text-sm dark:border-l-dark-medium">
            {previewData?.PersonOfConcern?.PrimaryPhone?.Number !== null && (
              <p>
                <p>
                  {previewData?.PersonOfConcern?.PrimaryPhone?.CountryId !==
                    null && <label>Country code :</label>}
                  {getCountryCode(
                    previewData?.PersonOfConcern?.PrimaryPhone?.CountryId,
                  )}
                </p>
                {previewData?.PersonOfConcern?.PrimaryPhone?.PhoneTypeId && (
                  <label className="text-sm font-medium">
                    {getPhoneDescription(
                      previewData?.PersonOfConcern?.PrimaryPhone?.PhoneTypeId,
                    )}
                    :{' '}
                  </label>
                )}
                {previewData?.PersonOfConcern?.PrimaryPhone?.Number}{' '}
                {previewData?.PersonOfConcern?.PrimaryPhone
                  ?.OkToLeaveMessage === true ? (
                  <p>
                    <label className="text-sm font-medium">
                      Is it okay to leave messages:
                    </label>{' '}
                    <ReadyToChatIcon className="ms-1 text-green-700 dark:text-green-400" />
                  </p>
                ) : (
                  previewData?.PersonOfConcern?.PrimaryPhone
                    ?.OkToLeaveMessage === false && (
                    <p>
                      <label className="text-sm font-medium">
                        Is it okay to leave messages:
                      </label>
                      <NotReadyToChatIcon className="ms-1 text-red-700 dark:text-red-400" />
                    </p>
                  )
                )}
                <p>
                  {previewData?.PersonOfConcern?.PrimaryPhone?.Comments !==
                    null && <label>Comments: </label>}
                  {previewData?.PersonOfConcern?.PrimaryPhone?.Comments}
                </p>
              </p>
            )}
          </div>
        </dd>
        <dd className="flex gap-2  border-b dark:border-dark-medium">
          <h4 className="w-1/3 p-2 text-sm font-medium">
            Secondary phone number
          </h4>
          <div className="w-4/6 border-l border-l-light-light p-2 text-sm dark:border-l-dark-medium">
            {previewData?.PersonOfConcern?.SecondaryPhone?.Number !== null && (
              <p>
                <p>
                  {previewData?.PersonOfConcern?.SecondaryPhone?.CountryId !==
                    null && <label>Country code :</label>}
                  {getCountryCode(
                    previewData?.PersonOfConcern?.SecondaryPhone?.CountryId,
                  )}
                </p>
                {previewData?.PersonOfConcern?.SecondaryPhone?.PhoneTypeId && (
                  <label className="text-sm font-medium">
                    {getPhoneDescription(
                      previewData?.PersonOfConcern?.SecondaryPhone?.PhoneTypeId,
                    )}
                    :{' '}
                  </label>
                )}
                {previewData?.PersonOfConcern?.SecondaryPhone?.Number}{' '}
                {previewData?.PersonOfConcern?.SecondaryPhone
                  ?.OkToLeaveMessage === true ? (
                  <p>
                    <label className="text-sm font-medium">
                      Is it okay to leave messages:
                    </label>
                    <ReadyToChatIcon className="ms-1 text-green-700 dark:text-green-400" />
                  </p>
                ) : (
                  previewData?.PersonOfConcern?.SecondaryPhone
                    ?.OkToLeaveMessage === false && (
                    <p>
                      <label className="text-sm font-medium">
                        Is it okay to leave messages:
                      </label>
                      <NotReadyToChatIcon className="ms-1 text-red-700 dark:text-red-400" />
                    </p>
                  )
                )}
                <p>
                  {previewData?.PersonOfConcern?.SecondaryPhone?.Comments !==
                    null && <label>Comments: </label>}
                  {previewData?.PersonOfConcern?.SecondaryPhone?.Comments}
                </p>
              </p>
            )}
          </div>
        </dd>
        <dd className="flex gap-2  border-b dark:border-dark-medium">
          <h4 className="w-1/3 p-2 text-sm font-medium">Gender identity</h4>
          <div className="w-4/6 border-l border-l-light-light p-2 text-sm dark:border-l-dark-medium">
            <p>{previewData?.PersonOfConcern?.Gender?.Description}</p>
          </div>
        </dd>
        <dd className="flex gap-2  border-b dark:border-dark-medium">
          <h4 className="w-1/3 p-2 text-sm font-medium">Date of Birth</h4>
          <div className="w-4/6 border-l border-l-light-light p-2 text-sm dark:border-l-dark-medium">
            <p>
              {previewData?.PersonOfConcern?.Birthdate !== null
                ? dayjs(previewData?.PersonOfConcern?.Birthdate).format(
                    'MM/DD/YYYY',
                  )
                : ''}
            </p>
          </div>
        </dd>
        <dd className="flex gap-2  border-b dark:border-dark-medium">
          <h4 className="w-1/3 p-2 text-sm font-medium">Age</h4>
          <div className="w-4/6 border-l border-l-light-light p-2 text-sm dark:border-l-dark-medium">
            <p>{previewData?.PersonOfConcern?.Age}</p>
          </div>
        </dd>
        {getFormName(previewData?.AccountId) === 'Hospital Followup' && (
          <dd className="flex gap-2  border-b dark:border-dark-medium">
            <h4 className="w-1/3 p-2 text-sm font-medium">Address</h4>
            <div className="w-4/6 border-l border-l-light-light p-2 text-sm dark:border-l-dark-medium">
              <p>
                Street1: {previewData?.PersonOfConcern?.PrimaryAddress?.Street1}
              </p>
              <p>
                Street2: {previewData?.PersonOfConcern?.PrimaryAddress?.Street2}
              </p>
              <p>
                Street3: {previewData?.PersonOfConcern?.PrimaryAddress?.Street3}
              </p>
              <p>
                City, State/Province:{' '}
                {previewData?.PersonOfConcern?.PrimaryAddress?.City}{' '}
                {previewData?.PersonOfConcern?.PrimaryAddress?.StateProvince !==
                null
                  ? ', '
                  : ''}{' '}
                {
                  previewData?.PersonOfConcern?.PrimaryAddress?.StateProvince
                    ?.Name
                }
              </p>
              <p>
                Zip/Postal code:{' '}
                {previewData?.PersonOfConcern?.PrimaryAddress?.ZipPostalCode}
              </p>
              <p>
                County: {previewData?.PersonOfConcern?.PrimaryAddress?.County}
              </p>
            </div>
          </dd>
        )}
      </dl>
    );
  }

  function renderPocFormResponses() {
    return (
      <dl className="border dark:border-dark-medium">
        <dt className="sticky -top-px border-b bg-gray-100 px-3 py-2 text-xl font-semibold dark:border-dark-medium dark:bg-neutral-900">
          {getFormName(previewData?.AccountId) ===
          'Adolescent Acute Care Clinical Placement'
            ? 'Clinical and Facility information'
            : 'Referring agency and Clinical information'}
        </dt>

        {previewData?.PersonOfConcern?.LatestFormResponses?.map(
          (item, index) => {
            if (
              item?.QuestionTemplateFieldLabel !== 'ADDRESS_POC' &&
              item?.QuestionTemplateFieldLabel !== 'DOB_POC' &&
              item?.QuestionTemplateFieldLabel !== 'GENDER_POC'
            ) {
              return (
                <dd
                  key={`${item?.DateAnswered}${index}`}
                  className="flex gap-2 border-b dark:border-dark-medium"
                >
                  <h4 className="w-1/3 p-2 text-sm font-medium">
                    {item?.FieldLabel}
                  </h4>
                  <div className="w-4/6 border-l border-l-light-light p-2 text-sm dark:border-l-dark-medium">
                    <p>{item?.Value.replace(/\|/gi, ', ')}</p>
                  </div>
                </dd>
              );
            }
          },
        )}
      </dl>
    );
  }

  return (
    <Transition appear show={documentPreviewModal} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => {
          setDocumentPreviewModal(false);
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="pr-dialog__backdrop" />
        </Transition.Child>

        <div className="pr-dialog__wrapper">
          <div className="pr-dialog__panel__container sm:!grid-rows-[1fr_auto_1fr]">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                style={{ maxHeight: 'calc(100svh - 90px)' }}
                className="pr-dialog__panel flex flex-col sm:!max-w-5xl"
              >
                <div className="flex items-center gap-3 border-b p-4 dark:border-dark-medium">
                  <Dialog.Title
                    as="h3"
                    className="grow truncate text-lg font-medium leading-6 text-gray-900 dark:text-white"
                  >
                    {getFormName(previewData?.AccountId)}
                  </Dialog.Title>

                  <button
                    type="button"
                    className=" text-gray-400 hover:text-gray-500 sm:right-4 sm:top-4"
                    onClick={() => {
                      setSelectedTracker(undefined);
                      setDocumentPreviewModal(false);
                    }}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>

                <div className="relative min-h-0 grow overflow-auto scroll-smooth px-4 pb-4">
                  <div className="min-w-[400px] space-y-6">
                    {renderCallerInformation()}
                    {renderPocInformation()}
                    {renderPocFormResponses()}
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
