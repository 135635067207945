import React, { Fragment, ReactElement, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/20/solid';

import { Button, TextField, Alert, Spinner } from 'src/ui/components';

import {
  ButtonProps,
  ChangePasswordModal,
  PasswordFieldType,
} from '../types/usersListType';

function ChangePassword({
  showPasswordModal,
  isPasswordChangeLoading,
  password,
  setPassword,
  passwordErrorMsg,
  onUpdatePasswordHandler,
  onCloseChangePasswordModal,
  setShowPasswordModal,
}: ChangePasswordModal): ReactElement {
  /*initial focus on text feild */
  const passRef = useRef(null);

  function changePasswordMsgHandler(): ReactElement {
    return (
      <>
        {passwordErrorMsg.length ? (
          <div className="mt-2 space-y-3 p-4 pb-0">
            <Alert variant="danger">{passwordErrorMsg}</Alert>
          </div>
        ) : null}
      </>
    );
  }

  function modalButtons({
    variant,
    onClick,
    className,
    buttonName,
    disabled,
  }: ButtonProps): ReactElement {
    return (
      <Button
        variant={variant}
        onClick={onClick}
        className={className}
        disabled={disabled}
      >
        {buttonName}
      </Button>
    );
  }

  function passwordModalField({
    label,
    id,
    type,
    value,
    onChange,
    ref,
  }: PasswordFieldType): ReactElement {
    return (
      <div>
        <div className="px-2 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
          <dt className="flex items-center text-sm font-medium leading-6 text-gray-900 dark:text-white">
            {label}
          </dt>
          <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
            <TextField
              id={id}
              type={type}
              value={value}
              onChange={onChange}
              ref={ref}
              onCopy={e => e.preventDefault()}
              onPaste={e => e.preventDefault()}
              onDrag={e => e.preventDefault()}
              onDrop={e => e.preventDefault()}
            />
          </dd>
        </div>
      </div>
    );
  }

  return (
    <>
      <Transition appear show={showPasswordModal} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={passRef}
          onClose={() => setShowPasswordModal(true)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="pr-dialog__backdrop" />
          </Transition.Child>

          <div className="pr-dialog__wrapper">
            <div className="pr-dialog__panel__container">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="pr-dialog__panel sm:!max-w-[30rem]">
                  <Dialog.Title
                    as="h3"
                    className="px-4 pt-4 text-lg font-medium leading-6 text-gray-900 dark:text-white"
                  >
                    Change password
                  </Dialog.Title>
                  <button
                    type="button"
                    className="absolute right-4 top-4 text-gray-400 hover:text-gray-500 sm:right-4 sm:top-4"
                    onClick={() => onCloseChangePasswordModal()}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                  {changePasswordMsgHandler()}
                  <div className="relative p-4">
                    {passwordModalField({
                      label: 'New password',
                      id: 'new-password',
                      type: 'password',
                      ref: passRef,
                      value: password.newPassword,
                      onChange: (e: any) =>
                        setPassword({
                          ...password,
                          newPassword: e.target.value,
                        }),
                    })}
                    {passwordModalField({
                      label: 'Confirm password',
                      id: 'confirm-password',
                      type: 'password',
                      value: password.confirmPassword,
                      onChange: (e: any) =>
                        setPassword({
                          ...password,
                          confirmPassword: e.target.value,
                        }),
                    })}

                    {isPasswordChangeLoading && (
                      <div className="fixed inset-0 z-20 grid place-content-center">
                        <Spinner />
                      </div>
                    )}
                  </div>
                  <div className="flex gap-[1ch] bg-gray-50 p-2 px-2 py-4 dark:bg-neutral-700/50 sm:justify-end sm:p-3">
                    {modalButtons({
                      variant: 'secondary',
                      onClick: onCloseChangePasswordModal,
                      className: 'grow sm:grow-0',
                      buttonName: 'Cancel',
                    })}
                    {modalButtons({
                      variant: 'primary',
                      onClick: onUpdatePasswordHandler,
                      className: 'grow sm:grow-0',
                      buttonName: 'Save',
                    })}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}

export { ChangePassword };
