import { create } from 'zustand';
import { persist } from 'zustand/middleware';

import produce from 'immer';
import dayjs from 'dayjs';

import {
  fetchEmailNotificationSettings,
  fetchEmailNotificationData,
} from 'src/hooks/useEmailNotificationStore';

import { getUserBasicInfo } from './apiStore/getUserBasicInfo';

import { isValidPassword } from 'src/utils/common';
import { timerPickerDefaultState } from 'src/utils/customEmailNotification';

import {
  OnBoardingStoreType,
  NotificationDetailsType,
  AuthCodeType,
} from 'src/types/onBoardingStoreType';
import { CustomEmailNotificationSettingsType } from 'src/types/emailNotification';

export const userOnBoardingStore = create<OnBoardingStoreType>()(
  persist(
    (set, get) => ({
      step: 0,
      userDetails: undefined,
      showPasswordErr: false,
      passWordErrMsg: '',

      twofactorAuthType: 'email',
      showAuthConfirmation: false,
      showQrCode: false,
      qrCode: undefined,
      showQrCodeKeySection: false,
      googleAuthCode: '',
      emailOptionList: [],
      customEmailNotification: {},
      selectedFrequency: -1,
      customEmailNotificationSettings: [],
      includeEmptyNotification: false,
      getNotificationDetailsLoading: false,
      showInvalidQrCodeError: false,
      userDetailsOnLoading: false,

      fetchUserBasicInfo: (id: string | null) => {
        set(
          produce(state => {
            state.userDetailsOnLoading = true;
          }),
        );
        getUserBasicInfo({ id }).then(response => {
          if (response) {
            set(state => ({
              userDetails: response,
              userDetailsOnLoading: false,
            }));
          }
        });
      },

      setQrCode: (value: AuthCodeType) => {
        set(
          produce(state => {
            state.qrCode = { ...value };
          }),
        );
      },

      getNotificationDetails: async ({
        token,
        tokenType,
        id,
      }: Partial<NotificationDetailsType>) => {
        set(
          produce(state => {
            state.getNotificationDetailsLoading = true;
          }),
        );
        const emailList = await fetchEmailNotificationSettings({
          token,
          tokenType,
        });
        const value = emailList?.map(item => {
          return {
            id: item?.Id,
            label: item?.NotifiedAt,
            value: item?.Id,
          };
        });

        set(
          produce(state => {
            state.emailOptionList = [...value];
          }),
        );
        if (id) {
          const getData = await fetchEmailNotificationData({
            token,
            tokenType,
            id,
          });
          set(
            produce(state => {
              state.customEmailNotification = { ...getData };
              const tempCustomSettings =
                getData?.CustomEmailNotificationSettings?.map(item => {
                  return {
                    NotifyTime: dayjs(item?.NotifyDateTime).format('HH:mm'),
                    NotifyDateTime: item?.NotifyDateTime,
                    EpochTimeValue: item?.EpochTimeValue,
                    Id: item?.Id,
                    UserEmailNotificationId: item?.UserEmailNotificationId,
                  };
                });
              state.selectedFrequency = getData?.EmailNotificationSettingsId;
              state.includeEmptyNotification =
                getData?.IncludeEmptyNotification;
              if (tempCustomSettings && tempCustomSettings?.length !== 0) {
                state.customEmailNotificationSettings = [...tempCustomSettings];
              } else
                state.customEmailNotificationSettings = [
                  { ...timerPickerDefaultState },
                ];
            }),
          );
          set(
            produce(state => {
              state.getNotificationDetailsLoading = false;
            }),
          );
        }
      },

      updateCustomEmailNotificationSettings: (
        value: Array<CustomEmailNotificationSettingsType>,
      ) => {
        set(
          produce(state => {
            state.customEmailNotificationSettings = [...value];
          }),
        );
      },

      disableSaveBtn: (
        step: number,
        password: string = '',
        isConfirmPresent: boolean = false,
      ) => {
        switch (step) {
          case 1:
            return !isValidPassword(password);
          case 2:
            return !isConfirmPresent;

          default:
            return false;
        }
      },

      updateAuthTypeFromSettings: (value: string) => {
        set(
          produce(state => {
            state.twofactorAuthType = value;
          }),
        );
      },

      setUpdateStore: (key: keyof OnBoardingStoreType, value: any) => {
        set(state => ({
          ...state,
          [key]: value,
        }));
      },
    }),
    {
      name: 'onboarding-storage',
      partialize: state => ({
        twofactorAuthType: state.twofactorAuthType,
      }),
    },
  ),
);
