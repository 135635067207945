import React, { ReactElement, useCallback } from 'react';
import { useQuery } from 'react-query';

import { AddressDetails, ContactDetails, PersonDetails } from './index';

import { useAuth } from 'src/hooks';

import { usePersonRepoStore } from '../../../store/personRepoStore';

function PersonDetailsTab({ refValue }: { refValue: any }): ReactElement {
  const { token, tokenType } = useAuth();

  const getPersonInformation = usePersonRepoStore(
    useCallback(state => state.updatePersonInformation, []),
  );

  const selectedPerson = usePersonRepoStore(
    useCallback(state => state.selectedPerson, []),
  );

  //fetch api for person alerts
  useQuery(
    [
      'get-person-details',
      {
        id: selectedPerson?.Id,
        accountId: selectedPerson?.AccountId,
      },
    ],
    () => {
      getPersonInformation(
        selectedPerson?.AccountId,
        selectedPerson?.Id,
        selectedPerson?.EntityId,
        token,
        tokenType,
      );
    },
    { staleTime: Infinity },
  );

  return (
    <>
      <div className="mt-10 grid grid-cols-1 space-y-6 md:space-y-10">
        <PersonDetails refValue={refValue} />
        <AddressDetails />
        <ContactDetails dialogueRef={refValue} />
      </div>
    </>
  );
}

export { PersonDetailsTab };
