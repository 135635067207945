import React, { ReactElement, Fragment, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/20/solid';

import { Button, TextField } from 'src/ui/components';

type EmailModalType = {
  emailErroMsg: string;
  showEmailModal: boolean;
  currentEmail: string;
  newEmail: string;
  setNewEmail: (value: string) => void;
  onCloseEmailModal: () => void;
  onUpdateEmailHandler: () => void;
  setShowEmailModal: (
    value: boolean | ((prevState: boolean) => boolean),
  ) => void;
};

type ButtonProps = {
  variant: any;
  onClick: () => void;
  className: string;
  buttonName: string;
  disabled?: boolean;
};

function ChangeEmail({
  emailErroMsg,
  showEmailModal,
  onCloseEmailModal,
  currentEmail,
  newEmail,
  setNewEmail,
  onUpdateEmailHandler,
  setShowEmailModal,
}: EmailModalType): ReactElement {
  /*initial focus on text feild */
  const emailRef = useRef(null);

  function modalButtons({
    variant,
    onClick,
    className,
    buttonName,
    disabled,
  }: ButtonProps): ReactElement {
    return (
      <Button
        variant={variant}
        onClick={onClick}
        className={className}
        disabled={disabled}
      >
        {buttonName}
      </Button>
    );
  }

  function EmailFields() {
    return (
      <>
        <div className="mt-2 p-4">
          <div>
            <div className="px-2 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-white">
                Current email
              </dt>
              <dd className="mt-1 truncate text-sm leading-6 text-gray-700 dark:text-neutral-400 sm:col-span-2 sm:mt-0">
                {currentEmail}
              </dd>
            </div>
          </div>
          <div>
            <div className="px-2 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="flex items-center text-sm font-medium leading-6 text-gray-900 dark:text-white">
                New email
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                <TextField
                  id="new-email"
                  value={newEmail}
                  ref={emailRef}
                  onChange={(e: any) => setNewEmail(e.target.value)}
                  validationError={
                    showEmailModal === true && emailErroMsg.length
                      ? emailErroMsg
                      : ''
                  }
                />
              </dd>
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <Transition appear show={showEmailModal} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={emailRef}
          onClose={() => setShowEmailModal(true)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="pr-dialog__backdrop" />
          </Transition.Child>
          <div className="pr-dialog__wrapper">
            <div className="pr-dialog__panel__container">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="pr-dialog__panel">
                  <Dialog.Title
                    as="h3"
                    className="px-4 pt-4 text-lg font-medium leading-6 text-gray-900 dark:text-white"
                  >
                    Change user email
                  </Dialog.Title>
                  <button
                    type="button"
                    className="absolute right-4 top-4 text-gray-400 hover:text-gray-500 sm:right-4 sm:top-4"
                    onClick={() => onCloseEmailModal()}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                  {EmailFields()}
                  <div className="flex gap-[1ch] bg-gray-50 p-4 dark:bg-neutral-700/50 sm:justify-end sm:p-3">
                    {modalButtons({
                      variant: 'secondary',
                      onClick: onCloseEmailModal,
                      className: 'grow sm:grow-0',
                      buttonName: 'Cancel',
                    })}
                    {modalButtons({
                      variant: 'primary',
                      onClick: onUpdateEmailHandler,
                      className: 'grow sm:grow-0',
                      buttonName: 'Update',
                    })}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}

export { ChangeEmail };
