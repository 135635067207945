import React, { ReactElement, useCallback, forwardRef } from 'react';
import { useQuery } from 'react-query';
import {
  BookOpenIcon,
  UserGroupIcon,
  PhoneIcon,
  EnvelopeIcon,
  ChatBubbleBottomCenterTextIcon,
  NewspaperIcon,
} from '@heroicons/react/20/solid';

import { ArrowTopRightOnSquareIcon as ArrowTopRightOnSquareIconMicro } from '@heroicons/react/16/solid';

import { EmptyState, TooltipWithBadge } from 'src/ui/components';

import { useAuth } from 'src/hooks';
import { useGetRelationshipManagersInfo } from '../api';

import { useHomeStore } from '../store/homeStore';
import {
  styleIconLearn,
  styleIconCovid,
  mailIcon,
  aboutProtocallIconConstant,
  protocallNewsroomIconConstant,
} from '../utils/homeConstants';
import { formatLabelName } from '../utils/storeHelper';

import { AccountRepo, ValueType } from '../types/apiStoreType';
import { AccountType } from 'src/types/AccountInfo';
import { classNames } from 'src/utils/className';

const RightPanel = forwardRef<HTMLDivElement>(({ ...rest }, ref) => {
  const { token, tokenType } = useAuth();

  const { data: accountRep } = useGetRelationshipManagersInfo();

  const getSetionInfo = useHomeStore(
    useCallback(state => state.getSectionInfo, []),
  );

  const assistanceInfo = useHomeStore(
    useCallback(state => state.assistanceInfo, []),
  );

  const customerCommunication = useHomeStore(
    useCallback(state => state.customerCommunication, []),
  );

  useQuery(
    ['get-section-details'],
    () => {
      getSetionInfo({
        token,
        tokenType,
      });
    },
    {
      staleTime: Infinity,
    },
  );

  function getToolTipContents(accounts: Array<AccountType>) {
    let tempArray: Array<AccountType> = accounts?.slice(3, accounts?.length);
    let accountList = tempArray?.map((item: AccountType, index: number) => {
      return (
        <div key={`${item?.CID}${index}`}>
          {`${item?.CID} - ${item?.SID} - ${item.PID}`}
        </div>
      );
    });
    return accountList;
  }

  //render customer communication icons
  function renderBtn(pathValue: string, className: string) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height={24}
        viewBox="0 -960 960 960"
        width={24}
        className={className}
      >
        <path d={pathValue} />
      </svg>
    );
  }

  //render customer communication part
  function renderCustomerCommunication() {
    return (
      <div className="grow rounded-lg bg-white shadow dark:bg-neutral-800">
        <h4 className="border-b border-light-light px-2 py-2 text-lg font-semibold dark:border-dark-medium md:px-4">
          Customer communication
        </h4>
        <div className="divide-y text-sm dark:divide-neutral-700">
          {customerCommunication?.map((item, index) => {
            return (
              <a
                key={`${index}`}
                href={item?.value}
                target="_blank"
                rel="noreferrer"
                className="group flex items-center gap-3 px-2 py-2 last:rounded-b-lg hover:bg-sky-50 active:bg-sky-100 dark:hover:bg-neutral-700/50 md:px-4"
              >
                {item?.image === protocallNewsroomIconConstant ? (
                  <NewspaperIcon className="h-5 w-5 text-gray-400 dark:text-neutral-500" />
                ) : item?.image === aboutProtocallIconConstant ? (
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5 fill-gray-400 dark:fill-neutral-500"
                  >
                    <path d="M3 12V2H12C14 2 17 3 17 6.5C17 10 14 11 12 11V6H6L9 9V18L3 12Z" />
                  </svg>
                ) : (
                  renderBtn(
                    styleIconLearn,
                    'h-8 w-8 fill-gray-400 dark:fill-neutral-300',
                  )
                )}

                <span
                  className={classNames(
                    'font-medium leading-none text-sky-700 group-hover:underline group-active:translate-y-px dark:text-sky-400',
                  )}
                >
                  {item.label !== 'About Protocall'
                    ? formatLabelName(item?.label)
                    : item?.label}
                </span>

                <ArrowTopRightOnSquareIconMicro
                  title="This link will open in a new tab"
                  className="h-4 w-4 shrink-0 opacity-0 group-hover:opacity-50 [@media(hover:none)]:opacity-50"
                />
              </a>
            );
          })}
        </div>
      </div>
    );
  }

  function renderContactDetails(assistanceInfo: ValueType) {
    return (
      <div className="flex items-center gap-3 px-2 py-2 md:px-4">
        {assistanceInfo?.elementId === mailIcon ? (
          <EnvelopeIcon className="h-5 w-5 shrink-0 text-gray-400 dark:text-neutral-500" />
        ) : (
          <ChatBubbleBottomCenterTextIcon className="h-5 w-5 shrink-0 text-gray-400 dark:text-neutral-500" />
        )}
        <div>
          <h5 className="font-semibold">
            {formatLabelName(assistanceInfo?.label)}
          </h5>
          <p className="select-all truncate">{assistanceInfo?.value}</p>
        </div>
      </div>
    );
  }

  //render phone details
  function renderPhoneDetails() {
    return (
      <div className="flex items-center gap-3 px-2 py-2 md:px-4">
        <PhoneIcon className="h-5 w-5 text-gray-400 dark:text-neutral-500" />
        <div>
          <h5 className="font-semibold">
            {formatLabelName(assistanceInfo[1]?.label)}
          </h5>
          <p className="select-all">{assistanceInfo[1]?.value}</p>
        </div>
      </div>
    );
  }

  //render user guide
  function renderUserGuide() {
    return (
      <a
        href={assistanceInfo[0]?.value}
        target="_blank"
        rel="noreferrer"
        className="group flex items-center gap-3 px-2 py-2 hover:bg-sky-50 active:bg-sky-100 dark:hover:bg-neutral-700/50 md:px-4"
      >
        <BookOpenIcon className="h-5 w-5 text-gray-400 dark:text-neutral-500" />
        <span className="font-medium leading-none text-sky-700 group-hover:underline group-active:translate-y-px  dark:text-sky-400">
          {formatLabelName(assistanceInfo[0]?.label)}
        </span>
        <ArrowTopRightOnSquareIconMicro
          title="This link will open in a new tab"
          className="h-4 w-4 shrink-0 group-hover:opacity-50 lg:opacity-0 [@media(hover:none)]:opacity-50"
        />
      </a>
    );
  }

  /*Account Representatives */
  function renderAcccountRep() {
    return (
      <div className="flex min-h-[100px] grow gap-3 px-2 py-2 md:px-4">
        <div aria-hidden="true">
          <UserGroupIcon className="h-5 w-5 text-gray-400 dark:text-neutral-500" />
        </div>
        <div className="flex min-h-0 min-w-0 grow flex-col">
          <h5 className="font-semibold">Account representative(s)</h5>
          <div className="divide-y overflow-y-auto dark:divide-neutral-700">
            {accountRep && accountRep?.length ? (
              accountRep?.map((item: AccountRepo) => {
                return (
                  <div key={item.RelationshipManager?.Id} className="py-2">
                    <p>
                      {item.Accounts?.map((accountItem, index) => {
                        if (index < 3)
                          return (
                            <span
                              key={accountItem?.Id}
                              title={`${accountItem?.CID} - ${accountItem?.SID} - ${accountItem.PID}`}
                            >
                              {accountItem?.CID}
                              {item.Accounts.length > 1 &&
                              index !== item.Accounts.length - 1
                                ? ', '
                                : ' '}
                            </span>
                          );
                      })}
                      {item.Accounts.length >= 4 && (
                        <TooltipWithBadge
                          label="More"
                          values={getToolTipContents(item.Accounts)}
                        />
                      )}
                    </p>
                    <div className="flex gap-2">
                      <span
                        className="select-all truncate"
                        title={item?.RelationshipManager?.Email}
                      >
                        {item?.RelationshipManager?.Email}
                      </span>
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="space-y-2 pb-3 pr-12 pt-4">
                <div className="mx-auto max-w-[80px]">
                  <EmptyState />
                </div>
                <p className="text-center text-sm text-gray-500 dark:text-neutral-300">
                  No information available
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      id="dash-assistance"
      ref={ref}
      className="flex min-w-[300px] scroll-my-2 flex-col gap-2 sm:flex-row md:w-1/5 md:flex-col md:gap-4"
      {...rest}
    >
      <div className="flex min-h-0 grow flex-col rounded-lg bg-white shadow dark:bg-neutral-800">
        <h4 className="border-b border-light-light px-2 py-2 text-lg font-semibold dark:border-dark-medium md:px-4">
          Assistance info
        </h4>
        <div className="flex min-h-0 flex-col divide-y overflow-y-auto text-sm dark:divide-neutral-700">
          {assistanceInfo && assistanceInfo[0] && renderUserGuide()}
          {renderAcccountRep()}
          {assistanceInfo && assistanceInfo[1] && renderPhoneDetails()}
          {assistanceInfo &&
            assistanceInfo[2] &&
            renderContactDetails(assistanceInfo[2])}
          {assistanceInfo &&
            assistanceInfo[3] &&
            renderContactDetails(assistanceInfo[3])}
        </div>
      </div>
      {renderCustomerCommunication()}
    </div>
  );
});

RightPanel.displayName = 'RightPanel';

export { RightPanel };
