import { gridItemType } from 'src/features/AddEditUsers/types/userStoreTypes';

const emailNotificationMsg: string =
  'This user will begin receiving email notifications only after they accept the invite and set up their password.';

const emailNotificationToolTipData: string =
  'Email notifications are controlled on access and control basis. Using the below drop-down list, you can select whether you would like to receive notifications in real-time or whether you would like them consolidated and sent to you at a time interval you specify.';

const lookupValidationMsg: string =
  'Please select options if you select limited access for an account.';

const timeFrameValidationMsg: string = 'Please choose at least one time frame.';

const adminTooltipMsg: string = 'Not authorized to change permission.';

const editUserSuccessMsg: string =
  'User updated successfully. A new email has been sent to the user with an updated link for onboarding.';

const reactTableSkeletonCount = 3;

const gridElements: Array<gridItemType> = [
  {
    id: 'first-name',
    label: 'First name',
    key: 'firstName',
  },
  {
    id: 'last-name',
    label: 'Last name',
    key: 'lastName',
  },
  {
    id: 'email',
    label: 'Email address',
    key: 'email',
  },
];

const userTabs = ['basic-tab', 'account-tab', 'permission-tab'];
const userTabSections = [
  { message: 'Basic details', link: '#basic-det' },
  { message: 'Select accounts', link: '#selected-account' },
  { message: 'User settings', link: '#user-settings' },
];

const extractMailRegex = /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi;

export {
  emailNotificationMsg,
  gridElements,
  emailNotificationToolTipData,
  lookupValidationMsg,
  timeFrameValidationMsg,
  adminTooltipMsg,
  reactTableSkeletonCount,
  editUserSuccessMsg,
  userTabs,
  userTabSections,
  extractMailRegex,
};
