import { create } from 'zustand';

type ApplicationThemeStore = {
  colourScheme: string;
  setColourScheme: (val: string) => void;
};

export const applicationThemeStore = create<ApplicationThemeStore>(set => ({
  colourScheme: 'light',

  setColourScheme: (val: string) => {
    set(() => ({
      colourScheme: val,
    }));
  },
}));
