import { useMutation, UseMutationResult } from 'react-query';

import { useProgressStore } from './useProgressStore';

import { getQrCode } from 'src/utils/urls';

import { QrCodeType } from '../features/UserOnBoarding/types/apiTypes';

export type TResult = QrCodeType;
export type TError = { message: string };
export type TVariable = {
  userName: string | undefined;
};

async function fetchQrCode({ userName }: TVariable): Promise<TResult> {
  const response = await fetch(getQrCode(), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ UserName: userName }),
  });
  return response.json();
}

function useGetQrCode(): UseMutationResult<
  TResult,
  TError,
  TVariable,
  unknown
> {
  const { setLoading } = useProgressStore();
  return useMutation(
    async ({ userName }: TVariable) => {
      setLoading('isFechQrCodeLoading', true);
      const result = await fetchQrCode({
        userName,
      });
      return result;
    },
    {
      onSettled: () => {
        setLoading('isFechQrCodeLoading', false);
      },
    },
  );
}

export { useGetQrCode };
