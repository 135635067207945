import { create } from 'zustand';
import produce from 'immer';

import { fetchScheduledCallDetails } from './apiStore';

import { validationMsg } from '../utils/activityTrackerHelperFn';

import { ActivityType, ParamType } from '../types/activityTrackerStore';
import { formattedPhoneNumber, getComments } from 'src/utils/common';
import { fetchCountryDetails } from 'src/store/apiStore';

export const useActivityStore = create<ActivityType>((set, get) => ({
  isDetailsLoading: false,
  callDetails: {},
  isSaveClicked: false,
  countryInfo: [],

  getScheduledCallDetails: async (params: Partial<ParamType>) => {
    const { token, tokenType, submissionId } = params;
    const countryCode = await fetchCountryDetails({ token, tokenType });

    set(
      produce(state => {
        state.isDetailsLoading = true;
        state.countryInfo = countryCode;
      }),
    );

    if (submissionId) {
      const scheduledCallDetails = await fetchScheduledCallDetails({
        token,
        tokenType,
        submissionId,
      });

      const primaryPhone = scheduledCallDetails?.PersonOfConcern?.PrimaryPhone;

      set(
        produce(state => {
          state.callDetails = { ...scheduledCallDetails };
          state.callDetails.leaveMessage =
            primaryPhone?.OkToLeaveMessage === true
              ? 1
              : primaryPhone?.OkToLeaveMessage === false
              ? 2
              : -1;
          state.callDetails.phoneType = primaryPhone?.PhoneTypeId;
          state.callDetails.phoneNumber =
            primaryPhone?.CountryId === null
              ? formattedPhoneNumber(primaryPhone?.Number, 1)
              : primaryPhone?.Number ?? '';

          state.callDetails.countryId = primaryPhone?.CountryId ?? 1;
          state.callDetails.comments = getComments(
            primaryPhone,
            primaryPhone?.CountryId,
            get().countryInfo,
          );
          if (
            primaryPhone?.CountryId === null &&
            primaryPhone.Number !== null
          ) {
            state.callDetails.comments =
              primaryPhone?.Comments !== null
                ? primaryPhone?.Comments + primaryPhone?.Number
                : primaryPhone?.Number;
          } else {
            state.callDetails.comments = primaryPhone?.Comments ?? '';
          }
          state.isDetailsLoading = false;
        }),
      );
    }
  },

  updateScheduledCallDetails: (key: string, value: any) => {
    set(
      produce(state => {
        state.callDetails = {
          ...state.callDetails,
          [key]: value,
        };
      }),
    );
  },

  showValidationMsg: (type: string): boolean => {
    switch (type) {
      case 'phnNumber':
        return validationMsg(get().callDetails, 'phoneNumber');
      case 'scheduledTime':
        return validationMsg(get().callDetails, 'scheduledTime');
      case 'timezone':
        return validationMsg(get().callDetails, 'timezone');
      case 'caseId':
        return validationMsg(get().callDetails, 'caseId');
      default:
        return false;
    }
  },

  setSaveClicked: (value: boolean) => {
    set(
      produce(state => {
        state.isSaveClicked = value;
      }),
    );
  },

  deleteScheduleCallDetails: () => {
    set((state: any) => {
      return {
        ...state,
        callDetails: undefined,
        isDetailsLoading: false,
        isSaveClicked: false,
      };
    });
  },
}));
