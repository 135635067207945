import { useQuery, UseQueryResult } from 'react-query';

import { useAuth } from 'src/hooks';

import { getGender } from 'src/utils/urls';

async function fetchGender({
  token,
  tokenType,
}: TVariables): Promise<APIResult> {
  const response = await fetch(getGender, {
    method: 'GET',
    headers: {
      Authorization: `${tokenType} ${token}`,
    },
  });
  return response.json();
}

export type APIResult = Array<{ Description: string; Id: number }>;

type TError = { message: string };
type TVariables = {
  token: string;
  tokenType: string;
};

function useGetGenders(): UseQueryResult<APIResult, TError> {
  const { token, tokenType } = useAuth();
  return useQuery(
    ['get_gender'],
    async () => {
      const result = await fetchGender({
        token,
        tokenType,
      });
      return result;
    },
    {
      staleTime: Infinity,
      refetchOnWindowFocus: false,
    },
  );
}

export { useGetGenders };
