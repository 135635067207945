export function getStartTimezoneOffset(inputStartDate: string) {
  if (inputStartDate === '') return null;
  const startDate = inputStartDate
    ? inputStartDate.split('-')[0].split('/')
    : [];
  const startTime = startDate?.length
    ? startDate[2].split(' ')[1].split(':')
    : [];
  const timezoneOffset =
    startDate.length > 0
      ? new Date(
          +startDate[2].split(' ')[0],
          +startDate[0] - 1,
          +startDate[1],
          +startTime[0],
          +startTime[1],
          +startTime[2],
        ).getTimezoneOffset()
      : new Date().getTimezoneOffset();
  return timezoneOffset;
}

export function getEndTimezoneOffset(inputEndDate: string) {
  if (inputEndDate === '') return null;
  const endDate = inputEndDate ? inputEndDate.split('-')[1].split('/') : [];
  const endTime = endDate?.length ? endDate[2].split(' ')[1].split(':') : [];
  const endtimezoneOffset =
    endDate.length > 0
      ? new Date(
          +endDate[2].split(' ')[0],
          +endDate[0] - 1,
          +endDate[1],
          +endTime[0],
          +endTime[1],
          +endTime[2],
        ).getTimezoneOffset()
      : new Date().getTimezoneOffset();
  return endtimezoneOffset;
}
