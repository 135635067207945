import React, { ReactElement, useMemo, useState, useCallback } from 'react';
import { useNavigate } from 'react-router';
import { InformationCircleIcon, PlusIcon } from '@heroicons/react/20/solid';

import { ListItem, UserActions } from './components';
import { EmptyState, Skeleton, Button } from 'src/ui/components';

import { useTitle } from 'src/hooks';
import { useGetResourceList } from './api';

import { useProgressStore } from 'src/hooks/useProgressStore';
import { useAddEditProviderStore } from './store/addEditProviderStore';
import { localTzMsg } from 'src/utils/appConstants';

import { ResourceListType, ResourceList } from './types/resourceList';

export default function Resources(): ReactElement {
  useTitle('Provider list');
  const navigate = useNavigate();

  const { mutations } = useProgressStore();

  const { data: resources, isLoading } = useGetResourceList();

  const [selectedProvider, setSelectedProvider] = useState<
    ResourceListType | undefined
  >(undefined);

  const clearProviderStore = useAddEditProviderStore(
    useCallback(state => state.clearProviderStore, []),
  );

  const loadSpinner = isLoading || mutations?.isUpdateProviderLoading;

  const resourceList = useMemo(() => {
    if (resources) {
      return resources.map((elem: ResourceList) => ({
        ProviderName: elem.name,
        EndPoint: elem.endpoint,
        LastUpdatedOn: elem.updatedDatetimeUtc,
        DataSync: elem.dataSyncStatus,
        Id: elem.id,
      }));
    }
    return [];
  }, [resources]);

  return (
    <>
      <main className="flex flex-1 flex-col overflow-y-auto overscroll-contain">
        <div className="flex items-center justify-between gap-4 border-t border-gray-200 bg-white px-2 py-1 shadow dark:border-neutral-700/50 dark:bg-neutral-800 sm:px-3 lg:px-4">
          <div className="flex min-w-0 flex-1 items-end gap-4 sm:gap-6">
            <h2 className="truncate text-lg font-semibold text-gray-950 dark:text-white sm:text-xl sm:tracking-tight">
              External data providers
            </h2>
          </div>
          <div className="flex gap-4">
            <Button
              variant="primary"
              size="small"
              onClick={() => {
                clearProviderStore();
                navigate('/Resources/AddProvider');
              }}
            >
              <PlusIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
              <span className="hidden sm:block">Add new provider</span>
            </Button>
          </div>
        </div>
        <p className="flex min-w-0 items-center justify-end gap-1 border-t bg-blue-50 p-1 font-medium  text-sky-700 shadow dark:border-neutral-700 dark:bg-neutral-800 dark:text-sky-300">
          <InformationCircleIcon
            className="-ml-0.5 hidden h-4 w-4 shrink-0 md:inline-block"
            aria-hidden="true"
          />
          <span className="text-xs">{localTzMsg}</span>
        </p>
        <div className="relative flex min-h-[46px] items-center justify-end gap-4 p-2 sm:px-3 lg:px-4">
          <UserActions
            selectedProvider={selectedProvider}
            setSelectedProvider={setSelectedProvider}
          />
        </div>

        <div className="relative flex-1 space-y-2 overflow-y-scroll px-2 pb-2 sm:px-3 sm:pb-3 lg:px-4 lg:pb-4">
          {!loadSpinner && resourceList.length > 0
            ? resourceList.map((provider: any) => (
                <ListItem
                  key={provider.Id}
                  item={provider}
                  setSelectedProvider={setSelectedProvider}
                  selectedProvider={selectedProvider}
                />
              ))
            : !loadSpinner && (
                <div className="absolute inset-0 grid place-content-center">
                  <EmptyState message="No information available" />
                </div>
              )}
          {loadSpinner && <Skeleton count={8} />}
        </div>
      </main>
    </>
  );
}
