export const twoFText = `Two-factor authentication is an enhanced security measure which
prompts users to enter a verification code when logging in. You
can choose whether this code is sent to your email (default) or
provided by an authentication app.`;

export const primaryEmailText = `Once you changed the email address, you will be asked to
Re-onboard into the application using the link sent to the new
email address. You must follow the setup process to complete this
change.`;

export const changeSettingsEmailMsg = `Are you sure, you want to change your email address? An email will be sent to the new email address. You must follow the setup process to complete this change.`;

export const changeSettingsEmailSuccessMsg = `The user's email address was successfully changed, and a confirmation email with an onboarding link was sent to your new email address.`;

export const successMsg = 'File uploaded successfully.';

export const deleteMsg = 'Document deleted successfully.';

export const nameWarning = 'Unable to save. File name already exist.';

export const fileErrorMsg = 'Maximum file size should be 15MB';

export const fileUploadInfo =
  'File should be PDF and maximum file size is 15MB. You can upload only up to 10 files.';

export const fileFormatErrorMsg = 'File format is not supported.';

export const fileDoesNotExistMsg =
  'The file you are trying to download does not exist. Please notify the technical team of the same. Apologies for the inconvenience caused.';
