const errorMessage: string =
  'The user is currently onboarding, you will be able to make edits once the onboarding process is done.';

const sortOptions: Array<string> = [
  'userName',
  'lastName',
  'firstName',
  'PID',
  'CID',
];

const changeEmailConfirmationMsg: string =
  "Are you sure you want to alter the user's email address? It will be updated once the user goes through the onboarding process again using the link sent to their new email address.";

const copyUserMsg: string =
  'User accounts and permissions will be copied. Please enter new name and email address.';

const changeEmailSuccessMsg: string =
  'The user`s email address was successfully changed, and a confirmation email with an onboarding link was sent to the user.';
const activeStyles: string =
  "after:pointer-events-none after:absolute after:inset-0 after:rounded-md after:border-2 after:border-primary-500 after:content-['']  md:after:border-3";

export {
  errorMessage,
  sortOptions,
  changeEmailConfirmationMsg,
  copyUserMsg,
  changeEmailSuccessMsg,
  activeStyles,
};
