import React, { Fragment, ReactElement } from 'react';
import { useQueryClient } from 'react-query';
import { Transition, Dialog } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/20/solid';

import { Button } from 'src/ui/components';

import { useRevokeAccess } from '../api';

import { UsersListType } from '../types/usersListType';

type RevokeAccessType = {
  userData: UsersListType;
  revokeAccessModal: boolean;
  setRevokeAccessModal: (
    value: boolean | ((prevState: boolean) => boolean),
  ) => void;
  setSelectedUser: (val: UsersListType | undefined) => void;
};

function RevokeAccess({
  userData,
  revokeAccessModal,
  setRevokeAccessModal,
  setSelectedUser,
}: RevokeAccessType): ReactElement {
  const queryClient = useQueryClient();

  const { mutate: revokeUserAccess } = useRevokeAccess();

  //on revoke access
  function onRevokeAccess() {
    revokeUserAccess(userData?.Id, {
      onSuccess: () => {
        setSelectedUser(undefined);
        setRevokeAccessModal(false);
        queryClient.invalidateQueries('get_users_list');
      },
    });
  }

  //revoke modal footer button display
  function revokeModalFooterButtons(): ReactElement {
    return (
      <div className="flex gap-[1ch] bg-gray-50 p-2 px-2 py-4 dark:bg-neutral-700/50 sm:justify-end sm:p-3">
        <Button
          variant="secondary"
          onClick={() => setRevokeAccessModal(false)}
          className="grow sm:grow-0"
        >
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={onRevokeAccess}
          className="grow sm:grow-0"
        >
          Revoke
        </Button>
      </div>
    );
  }

  //revoke modal table display
  function tableBodyDisplay(): ReactElement {
    return (
      <div className="max-h-80 max-w-full overflow-x-auto">
        <table className="w-full table-fixed ">
          <thead className="sticky top-0 border-b border-gray-200 bg-gray-100 dark:border-neutral-600 dark:bg-neutral-900">
            <tr>
              <th className="p-2">CID</th>
              <th className="p-2">PID</th>
            </tr>
          </thead>
          <tbody>
            {userData?.AccountCIDs?.map((item: string, index: number) => {
              return (
                <tr
                  key={`${index}${userData?.Id}`}
                  className="odd:bg-white even:bg-slate-50 dark:odd:bg-neutral-800 dark:even:bg-neutral-700"
                >
                  <td className="p-2">{item}</td>
                  <td className="p-2">{userData?.AccountPIDs[index]}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }

  return (
    <Transition appear show={revokeAccessModal} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => setRevokeAccessModal(true)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="pr-dialog__backdrop" />
        </Transition.Child>

        <div className="pr-dialog__wrapper">
          <div className="pr-dialog__panel__container">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="pr-dialog__panel">
                <Dialog.Title
                  as="h3"
                  className="px-4 pt-4 text-lg font-medium leading-6 text-gray-900 dark:text-white"
                >
                  Revoke account access
                </Dialog.Title>
                <button
                  type="button"
                  className="absolute right-4 top-4 text-gray-400 hover:text-gray-500 sm:right-4 sm:top-4"
                  onClick={() => setRevokeAccessModal(false)}
                >
                  <span className="sr-only">Close</span>
                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </button>
                <div className="mt-2 p-4">
                  <p>
                    Revoke{' '}
                    <strong className="inline-block max-w-full truncate align-bottom font-semibold">
                      {userData?.UserName}
                    </strong>{' '}
                    access to the following accounts
                  </p>
                  <div className="mt-2 max-w-full scroll-smooth">
                    {tableBodyDisplay()}
                  </div>
                </div>
                {revokeModalFooterButtons()}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

export { RevokeAccess };
