import { getCaller } from 'src/utils/urls';

import { PersonType } from 'src/types/personType';

async function fetchCaller({
  token,
  tokenType,
  accountId,
  personId,
}: {
  token: string;
  tokenType: string;
  accountId: number | undefined;
  personId: number | undefined;
}): Promise<PersonType> {
  const response = await fetch(getCaller(accountId, personId), {
    method: 'GET',
    headers: {
      Authorization: `${tokenType} ${token}`,
    },
  });
  return response.json();
}

export { fetchCaller };
