import dayjs from 'dayjs';
import { ValueType, SectionValuesType, Section } from '../types/apiStoreType';

function getFormattedArray(values: Array<SectionValuesType>) {
  const newArray: Array<ValueType> = [];

  values.forEach((item: any) => {
    const elementId = item.ElementId;
    const existingElement = newArray.find(
      element => element.elementId === elementId,
    );

    if (existingElement) {
      // If the element already exists, update its label and value
      if (item.Key === 'label') {
        existingElement.label = item.Value;
      } else if (
        item.Key === 'phone' ||
        item.Key === 'email' ||
        item.Key === 'href'
      ) {
        existingElement.value = item.Value;
      } else if (item.Key === 'image') {
        existingElement.image = item.Value;
      }
    } else {
      // If the element does not exist, create a new one
      const newElement = {
        elementId: elementId,
        label: '',
        value: '',
        image: '',
      };

      if (item.Key === 'label') {
        newElement.label = item.Value;
      } else if (
        item.Key === 'phone' ||
        item.Key === 'email' ||
        item.Key === 'href'
      ) {
        newElement.value = item.Value;
      } else if (item.Key === 'image') {
        newElement.image = item.Value;
      }

      newArray.push(newElement);
    }
  });
  return newArray;
}

function formatLabelName(input: string) {
  input = input?.trim();
  let words = input?.split(/\s+/);
  if (words) {
    words[0] =
      words[0]?.charAt(0)?.toUpperCase() + words[0]?.slice(1)?.toLowerCase();
    for (let i = 1; i < words.length; i++) {
      words[i] =
        words[i]?.charAt(0)?.toLowerCase() + words[i]?.slice(1)?.toLowerCase();
    }

    return words.join(' ');
  }
}

function getSectionValues(array1: Array<Section>, key: string) {
  return array1.find((x: Section) => x.description === key);
}

// To show the date range text
function getDateRange() {
  return `${dayjs()
    .subtract(30, 'day')
    .startOf('day')
    .format('DD MMM YYYY')} - ${dayjs()
    .endOf('day')
    .utc()
    .format('DD MMM YYYY')}`;
}
export { getFormattedArray, formatLabelName, getSectionValues, getDateRange };
