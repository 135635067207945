import React, { InputHTMLAttributes } from 'react';
import { inputLabelClasses } from '../TextField';
import clsx, { ClassValue } from 'clsx';
import { classNames } from 'src/utils/className';

type CheckboxProps = InputHTMLAttributes<HTMLInputElement> & {
  label?: string;
  id: string;
  inputSize?: 'small' | 'medium' | 'large';
  title?: string;
  className?: ClassValue;
};

export const baseClasses =
  'rounded dark:bg-neutral-600 checked:dark:bg-primary-500 border-gray-300 text-primary dark:text-primary-400 focus:ring-light-focus dark:focus:ring-dark-focus disabled:cursor-default';

export const sizeClasses = {
  small: 'h-4 w-4',
  medium: 'h-5 w-5',
  large: 'h-6 w-6',
};

export const Checkbox = ({
  label,
  id,
  inputSize = 'medium',
  title,
  className,
  ...rest
}: CheckboxProps) => {
  const sizeClass = sizeClasses[inputSize];
  const userClasses = clsx(className);

  return (
    <div
      className={classNames('flex items-start space-x-2', userClasses)}
      title={title}
    >
      <input
        id={id}
        type="checkbox"
        className={classNames(
          sizeClass,
          baseClasses,
          'peer disabled:opacity-40',
        )}
        {...rest}
      />
      {label && (
        <label
          className={classNames(
            inputLabelClasses,
            'mt-0.5 leading-tight peer-disabled:opacity-40',
          )}
          htmlFor={id}
        >
          {label}
        </label>
      )}
    </div>
  );
};

export default Checkbox;
