import React from 'react';
import { Column, TableInstance, ColumnInstance } from 'react-table';

import { Checkbox, Button } from 'src/ui/components';

import accountInfoTableHeader from './accountInfoTableHeader';

import { useUserStore } from '../../store/userStore';

import { AccountInfo } from 'src/types/AccountInfo';

function contains(array: Array<string>, string: string): boolean {
  const index = array.findIndex(item => item === string);
  return index !== -1;
}

export default function useAccountInfoColumns(
  records: Array<AccountInfo> | [],

  setColumnsSearch: (
    value:
      | Record<string, string>
      | ((prevState: Record<string, string>) => Record<string, string>),
  ) => void,
  setCurrentSortItem: (val: { sortKey: string; sortOrder: string }) => void,
  selectAll: boolean,
  setSelectAll: (value: boolean | ((prevState: boolean) => boolean)) => void,
  isFilterApplied: boolean,
  isLoading: boolean,
  isScroll: boolean,
  setIsOpen: (value: boolean | ((prevState: boolean) => boolean)) => void,
  isOpen: boolean,
  //  btnOpen: boolean,
  columnsList?: Array<string>,
): Array<Column<AccountInfo>> {
  const accountsInfo = useUserStore(
    React.useCallback(state => state.accountsInfo, []),
  );

  const updateAccountInfo = useUserStore(
    React.useCallback(state => state.updateAccountInfo, []),
  );

  const updatebulkAccountsDetails = useUserStore(
    React.useCallback(state => state.bulckUpdateAccountSettings, []),
  );

  const updateAccountSettings = useUserStore(
    React.useCallback(state => state.updateAccountSettings, []),
  );

  return React.useMemo((): Array<Column<AccountInfo>> => {
    if (columnsList) {
      const columns = [];
      if (contains(columnsList, 'selection'))
        columns.push(
          getCheckBoxHeader(
            records,
            setSelectAll,
            selectAll,
            accountsInfo,
            updateAccountInfo,
            updatebulkAccountsDetails,
            accountInfoTableHeader,
            isFilterApplied,
            isScroll,
            updateAccountSettings,
            isLoading,
            setIsOpen,
            isOpen,
            // btnOpen,
          ),
        );
      if (contains(columnsList, 'AccountName'))
        columns.push(getAccountNameHeader(accountInfoTableHeader));
      if (contains(columnsList, 'CID'))
        columns.push(getCidHeader(accountInfoTableHeader));
      if (contains(columnsList, 'SID'))
        columns.push(getSidHeader(accountInfoTableHeader));
      if (contains(columnsList, 'PID'))
        columns.push(getPidHeader(accountInfoTableHeader));
      if (contains(columnsList, 'Reset'))
        columns.push(
          getResetHeader(
            accountInfoTableHeader,
            setColumnsSearch,
            setCurrentSortItem,
          ),
        );
      return columns;
    }
    return [
      getCheckBoxHeader(
        records,
        setSelectAll,
        selectAll,
        accountsInfo,
        updateAccountInfo,
        updatebulkAccountsDetails,
        accountInfoTableHeader,
        isFilterApplied,
        isScroll,
        updateAccountSettings,
        isLoading,
        setIsOpen,
        isOpen,
        // btnOpen,
      ),
      getAccountNameHeader(accountInfoTableHeader),
      getCidHeader(accountInfoTableHeader),
      getSidHeader(accountInfoTableHeader),
      getPidHeader(accountInfoTableHeader),
      getResetHeader(
        accountInfoTableHeader,
        setColumnsSearch,
        setCurrentSortItem,
      ),
    ];
  }, [
    columnsList,
    records,
    setSelectAll,
    selectAll,
    accountsInfo,
    updateAccountInfo,
    updatebulkAccountsDetails,
    isFilterApplied,
    isLoading,
    setColumnsSearch,
    setCurrentSortItem,
    updateAccountSettings,
    isScroll,
    setIsOpen,
    isOpen,
    // btnOpen,
  ]);
}

function getAccountNameHeader(
  Header: (
    props: TableInstance<AccountInfo> & {
      column: ColumnInstance<AccountInfo>;
    },
  ) => JSX.Element,
): Column<AccountInfo> {
  return {
    Header: Header,
    accessor: (row: AccountInfo) => `${row.Name || ''}`,
    id: 'AccountName',
  };
}

function getCidHeader(
  Header: (
    props: TableInstance<AccountInfo> & {
      column: ColumnInstance<AccountInfo>;
    },
  ) => JSX.Element,
): Column<AccountInfo> {
  return {
    Header: Header,
    accessor: (row: AccountInfo) => `${row.CID || ''}`,
    id: 'CID',
  };
}

function getSidHeader(
  Header: (
    props: TableInstance<AccountInfo> & {
      column: ColumnInstance<AccountInfo>;
    },
  ) => JSX.Element,
): Column<AccountInfo> {
  return {
    Header: Header,
    accessor: (row: AccountInfo) => `${row.SID || ''}`,
    id: 'SID',
  };
}

function getPidHeader(
  Header: (
    props: TableInstance<AccountInfo> & {
      column: ColumnInstance<AccountInfo>;
    },
  ) => JSX.Element,
): Column<AccountInfo> {
  return {
    Header: Header,
    accessor: (row: AccountInfo) => `${row.PID || ''}`,
    id: 'PID',
  };
}

function getCheckBoxHeader(
  records: Array<AccountInfo> | undefined,
  setSelectAll: (val: boolean) => void,
  selectAll: boolean,
  accountsInfo: Array<number>,
  updateAccountInfo: (val: Array<number>) => void,
  updatebulkAccountsDetails: (val: Array<number>) => void,

  Header: (
    props: TableInstance<AccountInfo> & {
      column: ColumnInstance<AccountInfo>;
    },
  ) => JSX.Element,
  isFilterApplied: boolean,
  isScroll: boolean,
  updateAccountSettings: (id: number, isUpdate: boolean) => void,
  isLoading: boolean,
  setIsOpen: (value: boolean | ((prevState: boolean) => boolean)) => void,
  isOpen: boolean,
  // btnOpen: boolean,
): Column<AccountInfo> {
  function handleSelectAll(e: any) {
    if (e?.target.checked === true) {
      setSelectAll(true);
      const tempSelectedIds =
        records &&
        records?.map(item => {
          return item?.AccountId;
        });
      if (tempSelectedIds) {
        const updatedAccounts = accountsInfo
          ? [...tempSelectedIds, ...accountsInfo]
          : [...tempSelectedIds];
        // btnOpen === false && setIsOpen(true);
        setIsOpen(true);
        updateAccountInfo(updatedAccounts);
        updatebulkAccountsDetails(updatedAccounts);
      }
    } else if (e?.target.checked === false) {
      setSelectAll(false);
      let tempIds = [...accountsInfo];
      const objectIds = records?.map(obj => obj['AccountId']);
      let newIds = tempIds.filter(id => !objectIds?.includes(id));
      if (newIds?.length > 0 && !isFilterApplied) {
        updateAccountInfo([]);
        updatebulkAccountsDetails([]);
      } else {
        updateAccountInfo([...newIds]);
        updatebulkAccountsDetails([...newIds]);
      }
      setIsOpen(false);
    }
  }

  function handleRowClicks(row: any) {
    let tempSelectedIds: Array<number> =
      accountsInfo?.length > 0 ? [...accountsInfo] : [];

    const indx = tempSelectedIds?.findIndex(obj => obj === row?.AccountId);

    if (indx === -1) {
      tempSelectedIds?.push(row?.AccountId);
      updateAccountSettings(row?.AccountId, true);
    } else {
      updateAccountSettings(row?.AccountId, false);
      tempSelectedIds?.splice(indx, 1);
    }

    updateAccountInfo([...tempSelectedIds]);

    let checker = records?.every(v => tempSelectedIds.includes(v?.AccountId));
    if (tempSelectedIds?.length === records?.length || checker === true)
      setSelectAll(true);
    else setSelectAll(false);
  }

  function checkedvalue() {
    // to check whether all the elements in records present in accountInfo
    let checker = records?.every(v => accountsInfo?.includes(v?.AccountId));

    if (isLoading) return;
    if (checker === true) {
      setSelectAll(true);
      return true;
    }
    //check box need to uncheked when scrolling occurs
    else if (!isScroll && !isLoading) {
      setSelectAll(false);
      return false;
    } else return false;
  }

  return {
    id: 'selection',
    Header:
      records?.length !== 0 ? (
        ({}) => (
          <div>
            <Checkbox
              id="selectAll"
              label=""
              checked={checkedvalue()}
              onChange={e => handleSelectAll(e)}
            />
          </div>
        )
      ) : (
        <div>
          <Checkbox id="select-all" label="" checked={false} disabled={true} />
        </div>
      ),

    accessor: row => (
      <div>
        <Checkbox
          id={row?.AccountId?.toString()}
          onChange={e => {
            e.stopPropagation();
            handleRowClicks && handleRowClicks(row);
          }}
          checked={
            accountsInfo?.find(obj => obj === row?.AccountId) ? true : false
          }
        />
      </div>
    ),
  };
}

function getResetHeader(
  Header: (
    props: TableInstance<AccountInfo> & {
      column: ColumnInstance<AccountInfo>;
    },
  ) => JSX.Element,
  setColumnsSearch: (val: Record<string, string>) => void,
  setCurrentSortItem: (val: { sortKey: string; sortOrder: string }) => void,
): Column<AccountInfo> {
  const handleReset = () => {
    setColumnsSearch({});
    setCurrentSortItem({ sortKey: 'CID', sortOrder: 'desc' });
  };

  return {
    Header: ({}) => (
      <div>
        <Button size="small" onClick={() => handleReset()}>
          Reset
        </Button>
      </div>
    ),
    id: 'Reset',
  };
}
