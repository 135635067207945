import React, { ReactElement, useCallback } from 'react';
import { Disclosure } from '@headlessui/react';
import {
  ChevronDownIcon,
  PlusSmallIcon,
  MinusSmallIcon,
} from '@heroicons/react/20/solid';

import { Alert, Checkbox, RadioButton, ReactTable } from 'src/ui/components';

import { useAuth } from 'src/hooks';

import { useUserStore } from '../../store/userStore';
import {
  lookupValidationMsg,
  reactTableSkeletonCount,
} from '../../utils/userConstants';
import { noAccountWarning } from 'src/utils/appConstants';

import {
  AccountSettings,
  LimitedAccessRecords,
} from '../../types/userStoreTypes';
import { ColumnHeader } from '../../types/accountInfoTable';
import { DtAccessRadioType } from '../../types/limitedAccessRecords';

export type SettingsType = {
  open: boolean;
};

export default function AccountQuestionSettings({
  open,
}: SettingsType): ReactElement {
  const { token, tokenType, isExternalUser } = useAuth();

  const accountQuestionSettings = useUserStore(
    useCallback(state => state?.accountQuestionSettings, []),
  );

  const updateDtAccessSettings = useUserStore(
    useCallback(state => state?.updateDtAccessSettings, []),
  );

  const fetchLimittedAccessLookup = useUserStore(
    useCallback(state => state?.fetchLimittedAccessLookup, []),
  );

  const fetchLookupRecords = useUserStore(
    useCallback(state => state?.fetchLookupRecords, []),
  );

  const selectedRecordId = useUserStore(
    useCallback(state => state?.selectedRecordId, []),
  );

  const setSelectedRecords = useUserStore(
    useCallback(state => state?.setSelectedRecords, []),
  );

  const selectedOptions = useUserStore(
    useCallback(state => state?.selectedOptions, []),
  );

  const setSelectedOptions = useUserStore(
    useCallback(state => state?.setSelectedOptions, []),
  );
  const isSaveClicked = useUserStore(
    React.useCallback(state => state.isSaveClicked, []),
  );

  const isLookupOptionLoading = useUserStore(
    useCallback(state => state?.isLookupOptionLoading, []),
  );

  const loggedUserDTPermission = useUserStore(
    useCallback(state => state?.loggedUserDTPermission, []),
  );

  function isQuestionVisible(questionId: number, accountId: number): boolean {
    const accessType = loggedUserDTPermission.find(
      x => x.AccountId === accountId,
    )?.AccessTypeId;
    if (accessType === 0) return true;
    else
      return (
        loggedUserDTPermission
          .flatMap(x => x.Questions)
          .find(question => question.Id === questionId)?.IsVisible ?? false
      );
  }

  function isDisableOption(option: any) {
    if (isExternalUser) {
      const accessType = loggedUserDTPermission.find(
        x => x.AccountId === option.AccountId,
      )?.AccessTypeId;
      if (accessType === 0) return false;
      const hasAccess =
        loggedUserDTPermission
          .find(acc => acc.AccountId === option.AccountId)
          ?.Questions?.flatMap((question: any) => question.Records)
          ?.some(
            (record: any) =>
              record.Id === option.OptionId && record.HasAccess === true,
          ) || false;

      return !hasAccess;
    }
    return false;
  }

  // setting column heading array for lookup react-table
  function getColumnsHeader(columns: Array<string>): Array<ColumnHeader> {
    const selectionHeader: Array<ColumnHeader> = [
      {
        id: 'selection',
        Header: '',
        accessor: (row: any) => (
          <div>
            <Checkbox
              id={row?.OptionId}
              onChange={e => {
                rowClickHandler(row);
              }}
              checked={
                selectedRecordId?.find(x => x === row.OptionId) ? true : false
              }
              disabled={isDisableOption(row)}
            />
          </div>
        ),
      },
    ];

    const columnHeadings: Array<ColumnHeader> = columns?.map(
      (column: string) => ({
        Header: column,
        accessor: column,
        id: column,
      }),
    );
    return [...selectionHeader, ...columnHeadings];
  }

  // triggered when react table options choosed
  function rowClickHandler(row: any): any {
    if (
      selectedRecordId.length > 0 &&
      selectedRecordId.find(x => x === row?.OptionId)
    ) {
      setSelectedRecords(selectedRecordId.filter(x => x !== row?.OptionId));
      setSelectedOptions(
        selectedOptions.filter(x => x.OptionId !== row?.OptionId),
      );
    } else {
      setSelectedRecords([...selectedRecordId, row?.OptionId]);
      setSelectedOptions([
        ...selectedOptions,
        {
          AccountId: row?.AccountId,
          OptionId: row?.OptionId,
          QuestionId: row?.QuestionId,
        },
      ]);
    }
  }

  // setting react-table data
  function getTableRecords(
    lookup: LimitedAccessRecords | undefined,
    columns: Array<string> | undefined,
    accId: number,
  ): Array<any> {
    if (lookup && columns && lookup?.Records) {
      const responseArray = lookup?.Records?.map(record => {
        const newObj: any = { ...record };
        newObj['AccountId'] = accId;
        newObj['QuestionId'] = lookup.Id;
        newObj['OptionId'] = record.Id;
        newObj['IsEnabled'] = isDisableOption(newObj);
        columns.forEach((column, index) => {
          newObj[column] = record.Values[index];
        });
        return newObj;
      });
      return responseArray;
    }
    return [];
  }

  function renderLookupQuestions(
    settings: AccountSettings,
    lookup: LimitedAccessRecords,
    open: boolean,
    index: number,
  ): ReactElement {
    return (
      <>
        <>
          <Disclosure.Button
            key={`${lookup.Id}-${index}`}
            className="flex w-full border-b border-light-light px-4 py-2 text-left text-sm font-medium text-primary-600 last:border-b-0 hover:bg-gray-100/80 hover:text-primary-700 focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75 dark:border-dark-medium dark:bg-neutral-900 dark:text-primary-400 dark:hover:bg-neutral-950 dark:hover:text-primary-300"
            onClick={() => {
              !open &&
                fetchLookupRecords(
                  token,
                  tokenType,
                  settings.AccountId,
                  lookup.Id,
                );
            }}
          >
            {open ? (
              <MinusSmallIcon className="-ml-1 mr-2 h-5 w-5 text-primary-600 dark:text-primary" />
            ) : (
              <PlusSmallIcon className="-ml-1 mr-2 h-5 w-5 text-primary-600 dark:text-primary" />
            )}
            <span>{lookup.TableName}</span>
          </Disclosure.Button>
          <Disclosure.Panel className="bg-white text-sm text-gray-500 dark:bg-neutral-900">
            {renderRecordOptions(lookup, settings)}
          </Disclosure.Panel>
        </>
      </>
    );
  }

  function onRecordOptionClick(item: any): any {
    //restrict row selection for the external user not having permission
    if (isExternalUser && isDisableOption(item?.original)) return;
    rowClickHandler(item?.original);
  }

  function disableFullAccess(accountId: number): boolean {
    return loggedUserDTPermission.find(acc => acc.AccountId === accountId)
      .isDisableFullAccess;
  }

  function renderRecordOptions(
    lookup: LimitedAccessRecords,
    settings: AccountSettings,
  ): ReactElement {
    return (
      <div className="max-w-full scroll-smooth">
        <ReactTable
          skeletonCount={reactTableSkeletonCount}
          key={`${lookup.Id}`}
          columns={getColumnsHeader(lookup.Columns)}
          data={getTableRecords(lookup, lookup?.Columns, settings.AccountId)}
          isLoading={isLookupOptionLoading}
          onRowClick={onRecordOptionClick}
          className="max-h-72 max-w-full overflow-x-auto"
        />
      </div>
    );
  }

  function renderRadioBtn({
    id,
    label,
    name,
    value,
    checked,
    disabled,
    accountId,
  }: DtAccessRadioType) {
    return (
      <>
        <RadioButton
          id={id}
          label={label}
          name={name}
          value={value}
          checked={checked}
          disabled={disabled}
          onChange={e => {
            e.stopPropagation();
            if (value === 'limittedaccess') {
              updateDtAccessSettings('AccessTypeId', 1, accountId);
              fetchLimittedAccessLookup(token, tokenType, accountId);
            } else {
              updateDtAccessSettings('AccessTypeId', 0, accountId);
            }
          }}
        />
      </>
    );
  }

  function renderAccountsAndDtAccess(
    settings: AccountSettings,
    open: boolean,
  ): ReactElement {
    const indxPosition = selectedOptions?.findIndex(
      item => item?.AccountId === settings?.AccountId,
    );

    return (
      <div className="flex w-full items-center gap-3">
        <div className="flex max-w-[90%] grow flex-wrap justify-between gap-x-4 gap-y-2 sm:max-w-none md:flex-nowrap">
          <div className="flex min-w-0 max-w-full items-center gap-2">
            {indxPosition === -1 &&
              isSaveClicked &&
              settings.AccessTypeId === 1 && (
                <div className="h-3 w-3">
                  <span className="relative flex h-3 w-3 items-center justify-center">
                    <span className="absolute inline-flex h-full w-full animate-ping rounded-full bg-red-400 opacity-75" />
                    <span className="relative inline-flex h-2 w-2 rounded-full bg-red-500" />
                  </span>
                </div>
              )}
            <span className="truncate text-xs font-medium text-gray-900 dark:text-white sm:text-base md:text-sm">
              {settings.CID}-{settings.SID}-{settings.PID}_
              {settings.AccountName}
            </span>
          </div>
          <div className="flex flex-shrink-0 gap-3">
            {renderRadioBtn({
              id: `fullAccess${settings.AccountId}`,
              label: 'Full access',
              name: `access-${settings.AccountId}`,
              value: 'fullaccess',
              checked: settings.AccessTypeId === 0,
              disabled: isExternalUser
                ? disableFullAccess(settings.AccountId)
                : false,
              accountId: settings?.AccountId,
            })}
            {renderRadioBtn({
              id: `limitedAccess${settings.AccountId}`,
              label: 'Limited access',
              name: `access-${settings.AccountId}`,
              value: 'limittedaccess',
              checked: settings.AccessTypeId === 1,
              disabled: settings?.HasLimitedAccessLookups === false,
              accountId: settings?.AccountId,
            })}
          </div>
        </div>
        <ChevronDownIcon
          className={`${
            open &&
            settings.AccessTypeId == 1 &&
            settings?.Questions?.length !== 0 &&
            settings?.Questions !== null
              ? 'rotate-180 transform'
              : ''
          }${
            settings.AccessTypeId == 0 ||
            ((settings?.Questions?.length === 0 ||
              settings?.Questions === null) &&
              settings.AccessTypeId == 1)
              ? 'opacity-0'
              : ''
          }   h-5 w-5 shrink-0 text-gray-500`}
        />
      </div>
    );
  }

  return (
    <div>
      <h3 className="mb-1 font-medium text-gray-800 dark:font-normal dark:text-white sm:text-lg">
        Accounts &amp; questions
      </h3>
      <Alert variant="info" className="mb-3">
        {accountQuestionSettings?.length
          ? lookupValidationMsg
          : noAccountWarning}
      </Alert>
      <ul className="divide-y divide-light-light rounded-md border border-gray-200 bg-white dark:divide-dark-medium dark:border-dark-medium dark:bg-neutral-800">
        <>
          {accountQuestionSettings &&
            accountQuestionSettings.map(
              (settings: AccountSettings, index: number) => (
                <Disclosure key={`${index}-${settings.AccountId}`}>
                  {({ open }) => (
                    <>
                      {/* py-4 pl-4 pr-5 */}
                      <Disclosure.Button
                        as="li"
                        className="flex items-center justify-between px-3 py-2 text-sm leading-6 md:px-4 md:py-3"
                      >
                        {renderAccountsAndDtAccess(settings, open)}
                      </Disclosure.Button>
                      {settings.AccessTypeId === 1 &&
                        settings?.Questions?.length !== 0 &&
                        settings?.Questions !== null && (
                          <Disclosure.Panel className="bg-gray-50 p-2 text-sm text-gray-500 dark:bg-neutral-900">
                            <div className="divide-y divide-light-light dark:divide-dark-medium">
                              {settings?.Questions &&
                                settings.Questions.length > 0 &&
                                settings.Questions?.map(
                                  (
                                    lookup: LimitedAccessRecords,
                                    index: number,
                                  ) => (
                                    <div key={`${index}-${lookup.Id}`}>
                                      {(isExternalUser
                                        ? isQuestionVisible(
                                            lookup.Id,
                                            settings.AccountId,
                                          )
                                        : true) && (
                                        <Disclosure>
                                          {({ open }) => (
                                            <>
                                              {renderLookupQuestions(
                                                settings,
                                                lookup,
                                                open,
                                                index,
                                              )}
                                            </>
                                          )}
                                        </Disclosure>
                                      )}
                                    </div>
                                  ),
                                )}
                            </div>
                          </Disclosure.Panel>
                        )}
                    </>
                  )}
                </Disclosure>
              ),
            )}
        </>
      </ul>
    </div>
  );
}
