import { useInfiniteQuery, UseInfiniteQueryResult } from 'react-query';
import pDebounce from 'p-debounce';

import { useAuth } from 'src/hooks';

import { getFeedback } from 'src/utils/urls';
import { pageLength } from 'src/utils/appConstants';

import { FeedbackType } from '../types/releaseApiTypes';

async function fetchFeedback({
  requestData,
  token,
  tokenType,
  pageParam,
}: TVariables): Promise<TPage> {
  requestData.append('start', `${pageParam}`);

  const response = await fetch(getFeedback, {
    method: 'POST',
    headers: {
      Authorization: `${tokenType} ${token}`,
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: requestData,
  });
  return response.json();
}

export type TVariables = {
  requestData: any;
  token: string;
  tokenType: string;
  pageParam: number;
};

export type TError = { message: string };

export type TPage = {
  data: Array<FeedbackType>;
  draw: number;
  error: null;
  isAutoRefreshEnabled: boolean;
  pageNumber: number;
  recordsFiltered: number;
  recordsTotal: number;
};

const debouncedFetch = pDebounce(fetchFeedback, 500);

function useGetFeedback(): UseInfiniteQueryResult<TPage, unknown> {
  const { token, tokenType } = useAuth();

  return useInfiniteQuery(
    ['get-feedback'],
    ({ pageParam = 0 }) => {
      const requestData = setListRequestData();
      return debouncedFetch({ token, tokenType, requestData, pageParam });
    },
    {
      getNextPageParam: (lastPage: TPage, allPages: Array<TPage>) => {
        if (lastPage !== null && lastPage?.data?.length === pageLength)
          return allPages?.length * pageLength;
      },
      getPreviousPageParam: (firstPage: TPage, allPages: Array<TPage>) => {
        if (firstPage !== null && firstPage?.data?.length === pageLength)
          return allPages?.length - 1 * pageLength;
      },

      staleTime: 60 * 1000,
      refetchOnWindowFocus: false,
    },
  );
}

export function setListRequestData(): FormData {
  const formData: any = new URLSearchParams();

  formData.append('draw', '1');
  formData.append('length', `${pageLength}`);
  formData.append('timezoneOffset', `${new Date().getTimezoneOffset()}`);
  formData.append('columns[0][data]', 'Id');
  formData.append('columns[0][name]', '');
  formData.append('columns[0][searchable]', 'true');
  formData.append('columns[0][orderable]', 'true');
  formData.append('columns[0][search][regex]', 'false');
  formData.append('columns[0][search][ApplyExactMatch]', ''),
    formData.append('columns[0][search][value]', '');

  formData.append('columns[1][data]', 'UserId');
  formData.append('columns[1][name]', '');
  formData.append('columns[1][searchable]', 'true');
  formData.append('columns[1][orderable]', 'true');
  formData.append('columns[1][search][regex]', 'false');
  formData.append('columns[1][search][ApplyExactMatch]', ''),
    formData.append('columns[1][search][value]', '');

  formData.append('columns[2][data]', 'Feedback');
  formData.append('columns[2][name]', '');
  formData.append('columns[2][searchable]', 'true');
  formData.append('columns[2][orderable]', 'true');
  formData.append('columns[2][search][regex]', 'false');
  formData.append('columns[2][search][ApplyExactMatch]', ''),
    formData.append('columns[2][search][value]', '');

  formData.append('columns[3][data]', 'CreatedAt');
  formData.append('columns[3][name]', '');
  formData.append('columns[3][searchable]', 'true');
  formData.append('columns[3][orderable]', 'true');
  formData.append('columns[3][search][regex]', 'false');
  formData.append('columns[3][search][ApplyExactMatch]', ''),
    formData.append('columns[3][search][value]', '');

  formData.append('order[0][column]', 3);
  formData.append('order[0][dir]', 'desc');

  formData.append('search[value]', '');
  formData.append('search[regex]', 'false');

  return formData;
}

export { useGetFeedback };
