import {
  getLocLabel,
  getCallTypeLabel,
  getTagLabel,
} from '../callDocumentHelperFunctions';
import {
  getEndTimezoneOffset,
  getStartTimezoneOffset,
} from 'src/utils/dayLightSavingsHelper';

import { CallType } from '../../types/callDocumentList';
import { CallDocumentFilterType } from '../../types/filterOptionsType';

export function setCallDocDownloadRequestData(
  filterOptions: CallDocumentFilterType,
  callTypeDescription: Array<CallType> | undefined,
  includeStickyNotes: boolean,
  exportFormat: String,
  selectedDocIds: Array<number>,
): any {
  const formData: any = new URLSearchParams();
  selectedDocIds.map(item => formData.append('CallIds[]', [item]));
  formData.append('ExportFormat', exportFormat);
  formData.append('Parameters[draw]', '1');
  formData.append('Parameters[length]', `${100}`);
  formData.append(
    'Parameters[timezoneOffset]',
    `${getStartTimezoneOffset(filterOptions?.startDate)}`,
  );
  formData.append('Parameters[columns][0][data]', 'Id');
  formData.append('Parameters[columns][0][name]', 'Id');
  formData.append('Parameters[columns][0][searchable]', 'true');
  formData.append('Parameters[columns][0][orderable]', 'true');
  formData.append('Parameters[columns][0][search][regex]', 'false');
  formData.append('Parameters[columns][0][search][ApplyExactMatch]', ''),
    formData.append(
      'Parameters[columns][0][search][value]',
      filterOptions && filterOptions.callId ? filterOptions.callId : '',
    );

  formData.append('Parameters[columns][1][data]', 'CaseId');
  formData.append('Parameters[columns][1][name]', '');
  formData.append('Parameters[columns][1][searchable]', 'true');
  formData.append('Parameters[columns][1][orderable]', 'true');
  formData.append('Parameters[columns][1][search][regex]', 'false');
  formData.append('Parameters[columns][1][search][ApplyExactMatch]', ''),
    formData.append(
      'Parameters[columns][1][search][value]',
      filterOptions && filterOptions.caseId ? filterOptions.caseId : '',
    );

  formData.append('Parameters[columns][2][data]', 'Started');
  formData.append('Parameters[columns][2][name]', '');
  formData.append('Parameters[columns][2][searchable]', 'true');
  formData.append('Parameters[columns][2][orderable]', 'true');
  formData.append('Parameters[columns][2][search][regex]', 'false');
  formData.append('Parameters[columns][2][search][ApplyExactMatch]', ''),
    formData.append(
      'Parameters[columns][2][search][value]',
      filterOptions && filterOptions.startDate ? filterOptions.startDate : '',
    );
  formData.append(
    'Parameters[columns][2][StartTimezoneOffset]',
    `${getStartTimezoneOffset(filterOptions?.startDate)}`,
  );
  formData.append(
    'Parameters[columns][2][EndTimezoneOffset]',
    `${getEndTimezoneOffset(filterOptions?.startDate)}`,
  );

  formData.append('Parameters[columns][3][data]', 'Finalized');
  formData.append('Parameters[columns][3][name]', '');
  formData.append('Parameters[columns][3][searchable]', 'true');
  formData.append('Parameters[columns][3][orderable]', 'true');
  formData.append('Parameters[columns][3][search][regex]', 'false');
  formData.append('Parameters[columns][3][search][ApplyExactMatch]', ''),
    formData.append(
      'Parameters[columns][3][search][value]',
      filterOptions && filterOptions.uploadedDate
        ? filterOptions.uploadedDate
        : '',
    );
  formData.append(
    'Parameters[columns][3][StartTimezoneOffset]',
    `${getStartTimezoneOffset(filterOptions?.uploadedDate)}`,
  );
  formData.append(
    'Parameters[columns][3][EndTimezoneOffset]',
    `${getEndTimezoneOffset(filterOptions?.uploadedDate)}`,
  );

  formData.append('Parameters[columns][4][data]', 'CID');
  formData.append('Parameters[columns][4][name]', '');
  formData.append('Parameters[columns][4][searchable]', 'true');
  formData.append('Parameters[columns][4][orderable]', 'true');
  formData.append('Parameters[columns][4][search][regex]', 'false');
  formData.append('Parameters[columns][4][search][ApplyExactMatch]', ''),
    formData.append(
      'Parameters[columns][4][search][value]',
      filterOptions && filterOptions.cid ? filterOptions.cid : '',
    );

  formData.append('Parameters[columns][5][data]', 'PID');
  formData.append('Parameters[columns][5][name]', '');
  formData.append('Parameters[columns][5][searchable]', 'true');
  formData.append('Parameters[columns][5][orderable]', 'true');
  formData.append('Parameters[columns][5][search][regex]', 'false');
  formData.append('Parameters[columns][5][search][ApplyExactMatch]', ''),
    formData.append(
      'Parameters[columns][5][search][value]',
      filterOptions && filterOptions.pid ? filterOptions.pid : '',
    );
  formData.append('Parameters[columns][6][data]', 'CallerFirstName');
  formData.append('Parameters[columns][6][name]', '');
  formData.append('Parameters[columns][6][searchable]', 'true');
  formData.append('Parameters[columns][6][orderable]', 'true');
  formData.append('Parameters[columns][6][search][regex]', 'false');
  formData.append('Parameters[columns][6][search][ApplyExactMatch]', ''),
    formData.append(
      'Parameters[columns][6][search][value]',
      filterOptions && filterOptions.callerFirstName
        ? filterOptions.callerFirstName
        : '',
    );

  formData.append('Parameters[columns][7][data]', 'CallerLastName');
  formData.append('Parameters[columns][7][name]', '');
  formData.append('Parameters[columns][7][searchable]', 'true');
  formData.append('Parameters[columns][7][orderable]', 'true');
  formData.append('Parameters[columns][7][search][regex]', 'false');
  formData.append('Parameters[columns][7][search][ApplyExactMatch]', ''),
    formData.append(
      'Parameters[columns][7][search][value]',
      filterOptions && filterOptions.callerLastName
        ? filterOptions.callerLastName
        : '',
    );

  formData.append('Parameters[columns][8][data]', 'PersonOfConcernFirstName');
  formData.append('Parameters[columns][8][name]', '');
  formData.append('Parameters[columns][8][searchable]', 'true');
  formData.append('Parameters[columns][8][orderable]', 'true');
  formData.append('Parameters[columns][8][search][regex]', 'false');
  formData.append('Parameters[columns][8][search][ApplyExactMatch]', ''),
    formData.append(
      'Parameters[columns][8][search][value]',
      filterOptions && filterOptions.pocFirstName
        ? filterOptions.pocFirstName
        : '',
    );

  formData.append('Parameters[columns][9][data]', 'PersonOfConcernLastName');
  formData.append('Parameters[columns][9][name]', '');
  formData.append('Parameters[columns][9][searchable]', 'true');
  formData.append('Parameters[columns][9][orderable]', 'true');
  formData.append('Parameters[columns][9][search][regex]', 'false');
  formData.append('Parameters[columns][9][search][ApplyExactMatch]', ''),
    formData.append(
      'Parameters[columns][9][search][value]',
      filterOptions && filterOptions.pocLastName
        ? filterOptions.pocLastName
        : '',
    );

  formData.append('Parameters[columns][10][data]', 'CallType');
  formData.append('Parameters[columns][10][name]', '');
  formData.append('Parameters[columns][10][searchable]', 'true');
  formData.append('Parameters[columns][10][orderable]', 'true');
  formData.append('Parameters[columns][10][search][regex]', 'false');
  formData.append('Parameters[columns][10][search][ApplyExactMatch]', ''),
    formData.append(
      'Parameters[columns][10][search][value]',
      filterOptions && filterOptions.callType
        ? getCallTypeLabel(filterOptions?.callType, callTypeDescription)
        : '',
    );

  formData.append('Parameters[columns][11][data]', 'LevelOfCare');
  formData.append('Parameters[columns][11][name]', '');
  formData.append('Parameters[columns][11][searchable]', 'true');
  formData.append('Parameters[columns][11][orderable]', 'true');
  formData.append('Parameters[columns][11][search][regex]', 'false');
  formData.append('Parameters[columns][11][search][ApplyExactMatch]', ''),
    formData.append(
      'Parameters[columns][11][search][value]',
      filterOptions && filterOptions.levelOfCare
        ? getLocLabel(filterOptions.levelOfCare)
        : '',
    );

  formData.append('Parameters[columns][12][data]', 'ClinicalOutcome');
  formData.append('Parameters[columns][12][name]', '');
  formData.append('Parameters[columns][12][searchable]', 'true');
  formData.append('Parameters[columns][12][orderable]', 'true');
  formData.append('Parameters[columns][12][search][regex]', 'false');
  formData.append('Parameters[columns][12][search][ApplyExactMatch]', ''),
    formData.append(
      'Parameters[columns][12][search][value]',
      filterOptions && filterOptions.clinicalOutcome
        ? filterOptions.clinicalOutcome
        : '',
    );

  formData.append('Parameters[columns][13][data]', 'TagId');
  formData.append('Parameters[columns][13][name]', '');
  formData.append('Parameters[columns][13][searchable]', 'true');
  formData.append('Parameters[columns][13][orderable]', 'true');
  formData.append('Parameters[columns][13][search][regex]', 'false');
  formData.append('Parameters[columns][13][search][ApplyExactMatch]', ''),
    formData.append(
      'Parameters[columns][13][search][value]',
      filterOptions && filterOptions.tagStatus
        ? getTagLabel(filterOptions?.tagStatus)
        : '',
    );

  formData.append('Parameters[columns][14][data]', 'StickyNote');
  formData.append('Parameters[columns][14][name]', '');
  formData.append('Parameters[columns][14][searchable]', 'true');
  formData.append('Parameters[columns][14][orderable]', 'true');
  formData.append('Parameters[columns][14][search][regex]', 'false');
  formData.append('Parameters[columns][14][search][ApplyExactMatch]', ''),
    formData.append(
      'Parameters[columns][14][search][value]',
      filterOptions && filterOptions.stickyNotes
        ? filterOptions.stickyNotes
        : '',
    );

  formData.append('Parameters[order][0][column]', filterOptions.sortType);
  formData.append(
    'Parameters[order][0][dir]',
    filterOptions.sortOrder ? filterOptions.sortOrder : 'desc',
  );

  formData.append(
    'Parameters[search][value]',
    `${filterOptions.keywordSearch}`,
  );
  formData.append('Parameters[search][regex]', 'false');
  formData.append('IncludeStickyNote', includeStickyNotes);

  return formData;
}
