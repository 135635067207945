import React, { ReactElement, useCallback, useMemo } from 'react';

import {
  CustomLookupRecord,
  LookupColumnResponse,
  ResponseType,
} from 'src/features/PersonRepository/types/QuestionsTabType';

function LookupDisplay({ question }: { question: ResponseType }): ReactElement {
  const getResponseValues = useCallback(
    (record: any) => {
      let responseValues: Array<LookupColumnResponse> = [];
      const mappedLookupColumns = question.Options;

      if (record === null) return [];

      for (const [mappingIdx, mappedColumn] of Object.entries(
        mappedLookupColumns,
      )) {
        if (mappedColumn.SendToAccount) {
          responseValues.push({
            ColumnName: mappedColumn?.LookupColumnName,
            Response: record?.Values[mappingIdx],
          });
        }
      }
      return responseValues;
    },
    [question],
  );

  const result = useMemo(() => {
    let result: Array<LookupColumnResponse> = [];

    if (question.Value !== null && question.Value !== '') {
      let lookupResponse: Array<CustomLookupRecord> = JSON.parse(
        question.Value,
      );

      // Mapping lookup columns and response values
      if (Array.isArray(lookupResponse)) {
        lookupResponse?.forEach(record => {
          let responseValues = getResponseValues(record);
          responseValues?.forEach((responseValue: any) => {
            result.push({
              ColumnName: responseValue.ColumnName,
              Response: responseValue.Response,
            });
          });

          if (record.EmailTo) {
            result.push({ ColumnName: 'Email to', Response: record.EmailTo });
          }
          result.push({ ColumnName: 'LineBreaker', Response: '' });
        });
      } else {
        let responseValues = getResponseValues(lookupResponse);
        responseValues?.forEach((responseValue: any) => {
          result.push({
            ColumnName: responseValue.ColumnName,
            Response: responseValue.Response,
          });
        });
        if (lookupResponse['EmailTo']) {
          result.push({
            ColumnName: 'Email to',
            Response: lookupResponse['EmailTo'],
          });
        }
        result.push({ ColumnName: 'LineBreaker', Response: '' });
      }
    }

    return result;
  }, [getResponseValues, question.Value]);

  return (
    <>
      <div style={{ width: '100%' }}>
        {result?.length > 0
          ? result.map((item: LookupColumnResponse, indx) => {
              return (
                <>
                  <div
                    key={indx}
                    style={{
                      paddingBlockEnd: `${
                        item.ColumnName === 'LineBreaker' ? '16px' : '0'
                      }`,
                    }}
                  >
                    {item.ColumnName !== 'LineBreaker' && (
                      <>
                        <span>
                          <label
                            style={{ fontWeight: 700, marginRight: '8px' }}
                          >
                            {item?.ColumnName}:
                          </label>
                          <label>{item?.Response}</label>
                        </span>
                      </>
                    )}
                  </div>
                </>
              );
            })
          : null}
      </div>
    </>
  );
}

export { LookupDisplay };
