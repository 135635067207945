import { create } from 'zustand';

import { CallDocumentType } from '../types/callDocumentList';
import { CallDocStoreType } from '../types/callDocStoreTypes';

export const useCallDocStore = create<CallDocStoreType>(set => ({
  showDocPreviewModal: false,
  listPageParam: 0,

  setShowDocPreviewModal: (val: boolean) => {
    set(() => ({
      showDocPreviewModal: val,
    }));
  },

  isFromEmail: false,
  setIsFromEmail: (val: boolean) => {
    set(() => ({
      isFromEmail: val,
    }));
  },

  navigationDir: '',
  setNavigationDir: (val: string) => {
    set(() => ({
      navigationDir: val,
    }));
  },

  showConfirmation: false,
  setShowConfirmation: (val: boolean) => {
    set(() => ({
      showConfirmation: val,
    }));
  },

  isPrevCallDocAvailable: true,
  isNextCallDocAvailable: true,

  initialStickyData: '',

  isAllChecked: false,
  callDocsData: [],

  setIsPrevCallDocAvailable: (val: boolean) => {
    set(() => ({
      isPrevCallDocAvailable: val,
    }));
  },

  setIsNextCallDocAvailable: (val: boolean) => {
    set(() => ({
      isNextCallDocAvailable: val,
    }));
  },

  setInitialStickyData: (val: string) => {
    set(() => ({
      initialStickyData: val,
    }));
  },

  setAllChecked: (val: boolean) => {
    set(() => ({
      isAllChecked: val,
    }));
  },

  setCallDocsData: (val: Array<CallDocumentType>) => {
    set(() => ({
      callDocsData: [...val],
    }));
  },

  setListPageParam: (val: number) => {
    set(() => ({
      listPageParam: val,
    }));
  },
}));
