import React, { ReactElement, Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { CheckCircleIcon, XMarkIcon } from '@heroicons/react/20/solid';
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';

import { Button, Checkbox } from 'src/ui/components';

type ButtonItem = {
  label: string;
  handleClick: (cb?: () => void) => void;
  variant?: any;
};

export type ConfirmAlertTypes = {
  message: string;
  type?: string;
  showModel: boolean;
  // setShowModel: (value: boolean | ((prevState: boolean) => boolean)) => void;
  setShowModel: any;
  buttonSet: Array<ButtonItem>;
  hasDontshowAgainCheck?: boolean;
  dontShowAgain?: boolean | undefined;
  setDontShowAgain?: () => void | undefined;
  setValueEmpty?: (val: string) => void;
};

export type IconType = {
  type: string;
  icon: ReactElement;
  bgColor: string;
};

function ConfirmAlert({
  message,
  type,
  showModel,
  setShowModel,
  buttonSet,
  hasDontshowAgainCheck,
  dontShowAgain,
  setDontShowAgain,
  setValueEmpty,
}: ConfirmAlertTypes): ReactElement {
  const icons: Array<IconType> = [
    {
      type: 'warning',
      icon: (
        <ExclamationCircleIcon
          className="h-6 w-6 text-red-600"
          aria-hidden="true"
        />
      ),
      bgColor: 'bg-red-100',
    },
    {
      type: 'success',
      icon: (
        <CheckCircleIcon
          className="h-6 w-6 text-green-600"
          aria-hidden="true"
        />
      ),
      bgColor: 'bg-green-100',
    },
  ];

  function getIcon(type: string, key: keyof IconType) {
    return icons.find(x => x.type === type)?.[key];
  }

  return (
    <Transition appear show={showModel} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => {
          setShowModel(true);
          setValueEmpty && setValueEmpty('');
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="pr-dialog__backdrop" />
        </Transition.Child>
        <div className="pr-dialog__wrapper">
          <div className="pr-dialog__panel__container">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="pr-dialog__panel">
                <button
                  type="button"
                  className="absolute right-4 top-4 text-gray-400 hover:text-gray-500 sm:right-3 sm:top-3"
                  onClick={() => {
                    setShowModel(false);
                    setValueEmpty && setValueEmpty('');
                  }}
                >
                  <span className="sr-only">Close</span>
                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </button>

                <div className="pb-4 pl-4 pr-10 pt-5 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    {type && (
                      <div
                        className={`mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full ${getIcon(
                          type,
                          'bgColor',
                        )} sm:mx-0 sm:h-10 sm:w-10`}
                      >
                        {getIcon(type, 'icon')}
                      </div>
                    )}

                    <div>
                      <Dialog.Description
                        as="h3"
                        className="pr-4 text-base leading-6 text-gray-900 dark:text-neutral-200"
                      >
                        {message}
                      </Dialog.Description>
                    </div>
                  </div>
                </div>

                <div className="flex flex-col gap-x-[1ch] gap-y-4 border-t bg-gray-50 p-3 dark:border-dark-medium dark:bg-neutral-700/50 sm:flex-row sm:items-center sm:justify-between sm:p-4">
                  {hasDontshowAgainCheck &&
                    dontShowAgain !== undefined &&
                    setDontShowAgain !== undefined && (
                      <Checkbox
                        id="dontshowChk"
                        label="Do not show this again"
                        checked={dontShowAgain}
                        onChange={() => setDontShowAgain()}
                        className="py-3"
                      />
                    )}
                  <div className="flex gap-[1ch] sm:ml-auto">
                    {buttonSet.map((button, i) => (
                      <Button
                        variant={button?.variant}
                        key={i}
                        onClick={() => button.handleClick()}
                        className="grow sm:grow-0"
                      >
                        {button.label}
                      </Button>
                    ))}
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

export { ConfirmAlert };
