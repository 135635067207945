import { SecretType } from '../types/resourceList';

const urlPattern = /^(https?:\/\/)?([\w-]+\.)+[\w-]+(\/[\w- .\/?%&=]*)?$/;

function formatDataSyncStatus(status: number) {
  switch (status) {
    case 1:
      return 'Not Synced';
    case 2:
      return 'Queued';
    case 3:
      return 'Syncing';
    case 4:
      return 'Extracted';
    case 5:
      return 'Parsing';
    case 6:
      return 'Success';
    case 7:
      return 'Failure';
    default:
      return '';
  }
}

function disableSyncBtn(statusId: number | undefined) {
  const statusIds = [1, 6, 7];
  if (statusId) {
    return !statusIds.includes(statusId);
  }
}

const authenticationType = [
  { label: 'Select', value: -1 },
  {
    label: 'Basic Auth',
    value: 1,
  },
  {
    label: 'ApiKey Auth',
    value: 2,
  },
  {
    label: 'No Auth',
    value: 3,
  },
];

const httpMethods = [
  { label: 'Select', value: -1 },
  {
    label: 'POST',
    value: 1,
  },
  { label: 'GET', value: 2 },
];

// formatting provider create and edit payload
function formatCreatePayload(providerDetails: any, secret: SecretType) {
  const provider = {
    ...providerDetails,
    id: providerDetails?.id ? providerDetails?.id : '',
    accountIdList: null,
    name: providerDetails?.name ? providerDetails?.name?.trim() : '',
    endpoint: providerDetails?.endpoint?.trim(),
    authenticationType:
      providerDetails?.authenticationType !== '-1' &&
      providerDetails?.authenticationType
        ? parseInt(providerDetails?.authenticationType)
        : null,
    httpVerb:
      providerDetails?.httpVerb !== '-1'
        ? parseInt(providerDetails?.httpVerb)
        : null,
    timeInterval: providerDetails?.timeInterval?.toString(),
    searchableFields: [],
    addToReport: [],
    addToSearchResult: [],
  };
  return {
    Provider: { ...provider },
    Secret: { ...secret },
  };
}

const successMsg = 'Provider added successfully.';
const updateMsg = 'Provider updated successfully.';
const errorOccuredMsg =
  'Error occured while adding provider details. Please try again!';
const warningMsg = 'Please fill the required fields.';

//validation
function checkValidation(
  providerDetails: any,
  secret: SecretType,
  userMode: string,
  authenticationIndication: number | null,
) {
  let authType = providerDetails?.authenticationType
    ? parseInt(providerDetails?.authenticationType)
    : null;

  if (
    providerDetails?.name?.trim() !== '' &&
    providerDetails?.name !== undefined &&
    providerDetails?.endpoint?.trim() !== '' &&
    providerDetails?.endpoint !== undefined &&
    providerDetails?.authenticationType !== '' &&
    providerDetails?.authenticationType !== undefined &&
    providerDetails?.authenticationType !== '-1' &&
    providerDetails?.httpVerb !== null &&
    providerDetails?.httpVerb !== undefined &&
    providerDetails?.httpVerb !== '-1' &&
    providerDetails?.timeInterval !== null &&
    providerDetails?.timeInterval !== undefined &&
    ((secret?.ApiKey?.trim() !== '' && secret?.ApiKey !== undefined) ||
      (secret?.Username?.trim() !== '' &&
        secret?.Password?.trim() !== '' &&
        secret?.Password !== undefined &&
        secret?.Username !== undefined) ||
      (userMode === 'Edit' &&
        authenticationIndication === authType &&
        (authenticationIndication === 2 || authenticationIndication === 1)) ||
      providerDetails?.authenticationType === '3' ||
      providerDetails?.authenticationType === 3)
  ) {
    return true;
  } else return false;
}

function mapDefaultProviderConfig(
  mappedArray: Array<any>,
  key: string,
): {
  [key: string]: any;
} {
  return {
    [key]: mappedArray.map(elem => ({
      label: elem.fullHeirarchialName,
      value: elem.resourceFieldId,
      resourceFieldType: elem.resourceFieldType,
    })),
  };
}

export {
  urlPattern,
  formatDataSyncStatus,
  disableSyncBtn,
  mapDefaultProviderConfig,
  formatCreatePayload,
  checkValidation,
  authenticationType,
  httpMethods,
  successMsg,
  errorOccuredMsg,
  updateMsg,
  warningMsg,
};
