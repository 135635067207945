import React, { ReactElement, useRef, useCallback, useState } from 'react';
import { Disclosure } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';

import { TextField } from 'src/ui/components';
import { CallLimit } from './CallLimit';
import EditorAndAlerts from './EditorAndAlerts';

import { useAuth } from 'src/hooks';

import { usePersonAlertStore } from '../../store/personAlertStore';

function AlertInformations(): ReactElement {
  const { isExternalUser } = useAuth();

  const situationDescriptionRef = useRef<any>();
  const telePhnInterventionRef = useRef<any>();

  const alertInfo = usePersonAlertStore(
    useCallback(state => state?.alertDetails, []),
  );

  const UpdateAlertInfo = usePersonAlertStore(
    useCallback(state => state.updateAlertDetails, []),
  );

  const isSaveClicked = usePersonAlertStore(
    useCallback(state => state.isSaveClicked, []),
  );

  const enableAlertTabs = usePersonAlertStore(
    useCallback(state => state.enableAlertTabs, []),
  );

  function renderTextField(
    id: string,
    label: string,
    key: string,
  ): ReactElement {
    return (
      <TextField
        id={id}
        label={label}
        value={alertInfo?.[key]}
        onChange={e => UpdateAlertInfo(key, e.target.value)}
      />
    );
  }

  return (
    <Disclosure as="div" id="alert-info" className="scroll-m-4">
      {({ open }) => (
        <>
          <Disclosure.Button className="flex w-full items-center justify-between rounded-lg bg-white px-4 py-2 text-left shadow-sm hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-light-focus ui-open:rounded-b-none dark:bg-neutral-800 dark:hover:bg-neutral-800/70">
            <div className="flex grow flex-wrap items-center gap-x-4 gap-y-2  pr-2">
              {isSaveClicked && !enableAlertTabs('alert-tab') && (
                <div className="h-3 w-3">
                  <span className="relative flex h-3 w-3 items-center justify-center">
                    <span className="absolute inline-flex h-full w-full animate-ping rounded-full bg-red-400 opacity-75" />
                    <span className="relative inline-flex h-2 w-2 rounded-full bg-red-500" />
                  </span>
                </div>
              )}
              <h3 className="dark:after:text-red-400sm:text-lg font-medium after:text-red-500 after:content-['*']">
                Alert information
              </h3>
            </div>
            <ChevronDownIcon
              className={`${
                open ? 'rotate-180 transform' : ''
              } h-5 w-5 text-gray-500 dark:text-neutral-300`}
            />
          </Disclosure.Button>

          <Disclosure.Panel className="space-y-10 rounded-b-lg border-t bg-white p-4 text-sm text-gray-500 shadow dark:border-neutral-700/50 dark:bg-neutral-800">
            <div className="space-y-5">
              <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 sm:gap-y-2 md:grid-cols-2">
                {renderTextField(
                  'counselor',
                  'Counselor or case manager (If applicable)',
                  'CounselorOrCaseManager',
                )}

                {renderTextField(
                  'participant',
                  'Special program participant',
                  'SpecialProgramsParticipant',
                )}
              </div>
              <EditorAndAlerts
                title="Situation description"
                keyVal="SituationDescription"
                editorRef={situationDescriptionRef}
                required={true}
              />
              <EditorAndAlerts
                title="Telephone intervention description"
                keyVal="TelephoneInterventionDescription"
                editorRef={telePhnInterventionRef}
              />
            </div>
            {!isExternalUser && <CallLimit />}
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}

export { AlertInformations };
