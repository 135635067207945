import React, { ReactElement, useCallback, useState } from 'react';

import { Select, TextField } from 'src/ui/components';

import { useGetAddress, useGetCountry, useGetStateProvince } from 'src/hooks';

import { usePersonRepoStore } from '../../../store/personRepoStore';
import { formatSelectOptions } from 'src/utils/common';

import { TextFieldType } from 'src/types/textFileType';

function AddressDetails() {
  const [postalCode, setPostalCode] = useState<string>('');
  const [hideCard, setHideCard] = useState<boolean>(false);

  const { data: postalAddressList } = useGetAddress(postalCode);
  const { data: stateprovince } = useGetStateProvince();
  const { data: countryList } = useGetCountry();

  const stateProvinceDropDownList = formatSelectOptions(stateprovince);
  const countryDropDownList = formatSelectOptions(countryList);

  const userMode = usePersonRepoStore(useCallback(state => state.userMode, []));

  const personDetails = usePersonRepoStore(
    useCallback(state => state.personDetails, []),
  );

  const updatePersonDetails = usePersonRepoStore(
    useCallback(state => state.updatePersonDetails, []),
  );

  const updateAddressDetails = usePersonRepoStore(
    useCallback(state => state.updateAddressDetails, []),
  );

  function renderTextField({
    id,
    value,
    key,
    label,
    type,
    className,
    required,
  }: TextFieldType): ReactElement {
    return (
      <TextField
        id={id}
        label={label}
        type={type}
        className={className}
        required={required}
        value={value}
        onChange={e => {
          updatePersonDetails &&
            key &&
            updatePersonDetails(key, e.target.value, 'PrimaryAddress');
        }}
        disabled={userMode === 'Edit'}
      />
    );
  }

  function renderSelectField({
    id,
    value,
    key,
    options,
    label,
    personDetailKey,
    className,
  }: {
    id: string;
    value: string;
    key: string;
    options: Array<{ label: string; value: number }>;
    label?: string;
    personDetailKey: string;
    className?: string;
  }): ReactElement {
    return (
      <Select
        id={id}
        options={options}
        label={label}
        onChange={e => {
          updatePersonDetails(key, e.currentTarget.value, personDetailKey);
        }}
        value={value}
        className={className}
        disabled={userMode === 'Edit'}
      />
    );
  }

  return (
    <div className="space-y-2">
      <h4 className="text-xl text-black  dark:text-white">Address</h4>
      <div className="grid grid-cols-1 gap-x-6 gap-y-3 sm:grid-cols-2 lg:grid-cols-3">
        <div className="relative">
          <TextField
            id="search-by-zip"
            label="Search by zip/postal code"
            type="search"
            placeholder="Search by zip/postal code"
            onChange={e => {
              setPostalCode(e?.target?.value);
              setHideCard(true);
            }}
            value={
              personDetails?.PrimaryAddress?.Code
                ? personDetails?.PrimaryAddress?.Code
                : postalCode
            }
            disabled={userMode === 'Edit'}
          />

          {postalAddressList && postalAddressList?.length > 0 && hideCard && (
            <div className="absolute inset-x-0 top-full mt-2 max-h-52 overflow-y-auto overflow-x-hidden rounded-lg border bg-white shadow-lg dark:border-neutral-600 dark:bg-neutral-700">
              <ul className="divide-y text-black dark:divide-neutral-800 dark:text-white ">
                {postalAddressList?.map((item, index) => {
                  return (
                    <li
                      className="cursor-pointer px-4 py-2 hover:bg-gray-100 dark:hover:bg-neutral-600"
                      key={`${item?.Code}${index}`}
                      onClick={() => {
                        updateAddressDetails(item);
                        setHideCard(false);
                      }}
                    >
                      <p className="truncate font-medium">
                        {item?.Code}:{item?.PlaceName}
                      </p>
                      <p className="opacity-60">
                        {item?.StateProvince?.Name},{item?.Country?.Name}
                      </p>
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
        </div>
      </div>
      <div className="grid grid-cols-1  gap-x-6 gap-y-3 sm:grid-cols-2 lg:grid-cols-3 ">
        {renderTextField({
          id: 'street1',
          label: 'Street 1',
          key: 'Street1',
          value: personDetails?.PrimaryAddress?.Street1,
        })}
        {renderTextField({
          id: 'street2',
          label: 'Street 2',
          key: 'Street2',
          value: personDetails?.PrimaryAddress?.Street2,
        })}
        {renderTextField({
          id: 'street3',
          label: 'Street 3',
          key: 'Street3',
          value: personDetails?.PrimaryAddress?.Street3,
        })}
        {renderTextField({
          id: 'city',
          label: 'City',
          key: 'City',
          value: personDetails?.PrimaryAddress?.City,
        })}
        {renderSelectField({
          id: 'state-province',
          label: 'State/Province',
          key: 'StateProvinceId',
          personDetailKey: 'PrimaryAddress',
          options: stateProvinceDropDownList,
          value: personDetails?.PrimaryAddress?.StateProvinceId
            ? personDetails?.PrimaryAddress?.StateProvinceId
            : -1,
        })}
        {renderTextField({
          id: 'Zip/Postal Code',
          label: 'Zip/Postal Code',
          key: 'ZipPostalCode',
          value: personDetails?.PrimaryAddress?.ZipPostalCode,
        })}
        {renderSelectField({
          id: 'country',
          label: 'Country',
          key: 'CountryId',
          personDetailKey: 'PrimaryAddress',
          options: countryDropDownList,
          value: personDetails?.PrimaryAddress?.CountryId
            ? personDetails?.PrimaryAddress?.CountryId
            : '',
        })}
        {renderTextField({
          id: 'county',
          label: 'County',
          key: 'County',
          value: personDetails?.PrimaryAddress?.County,
        })}
      </div>
    </div>
  );
}

export { AddressDetails };
