import { isEmpty } from 'lodash';
import { useQuery, UseQueryResult } from 'react-query';

import { useAuth } from 'src/hooks';

import { getWidgetList } from 'src/utils/urls';

async function fetchWidgetsList({
  token,
  tokenType,
}: TVariables): Promise<APIResult> {
  const response = await fetch(getWidgetList, {
    method: 'GET',
    headers: {
      Authorization: `${tokenType} ${token}`,
    },
  });
  return response.json();
}

export type TVariables = {
  token: string;
  tokenType: string;
};

export type APIResult = {
  'Dashboard-Settings': string;
};
export type TResult = Array<string>;

export type TError = { message: string };

function useGetDashboardSettings(): UseQueryResult<TResult, TError> {
  const { token, tokenType } = useAuth();
  return useQuery(
    ['get_dashboard_settings'],
    async () => {
      const fetchResponse = await fetchWidgetsList({ token, tokenType });
      if (isEmpty(fetchResponse)) return [];
      else {
        const result = JSON.parse(fetchResponse['Dashboard-Settings']);
        return result;
      }
    },
    {
      staleTime: 60 * 1000,
      refetchOnWindowFocus: false,
      retry: false,
    },
  );
}

export { useGetDashboardSettings };
