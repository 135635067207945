import { useQuery, UseQueryResult } from 'react-query';

import { useAuth } from 'src/hooks';

import { getDefaultEmail } from 'src/utils/urls';

import { EmailType } from '../types/requestType';

export type TResult = EmailType;

export type TError = { message: string };

export type TVaribles = {
  token: string;
  tokenType: string;
  id: number;
};

async function fetchDefaultEmail({
  token,
  tokenType,
  id,
}: TVaribles): Promise<TResult> {
  const response = await fetch(getDefaultEmail(id), {
    method: 'GET',
    headers: {
      Authorization: `${tokenType} ${token}`,
    },
  });
  return response.json();
}

function useGetDefaultEmail(
  id: number | undefined,
): UseQueryResult<TResult, TError> {
  const { token, tokenType } = useAuth();
  return useQuery(
    ['default_email', id],
    async () => {
      if (id) {
        const result = await fetchDefaultEmail({
          token,
          tokenType,
          id,
        });
        return result;
      }
    },
    {
      staleTime: 60 * 1000,
      enabled: false,
      refetchOnWindowFocus: false,
    },
  );
}

export { useGetDefaultEmail };
