import React, { Fragment, ReactElement, useState, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/20/solid';

import { Button, ConfirmAlert, TextField } from 'src/ui/components';

import { useAuth } from 'src/hooks';
import { useChangeEmail } from 'src/features/Users/api';

import { emailRegex } from 'src/utils/common';
import {
  changeSettingsEmailMsg,
  changeSettingsEmailSuccessMsg,
} from '../utils/constants';
import { showErrorToast, showSuccessToast } from 'src/utils/ToastNotification';

import { ConfirmModalType } from 'src/features/Users/types/usersListType';

type ChangeEmailModalType = {
  changeEmailModal: boolean;
  setChangeEmailModal: (
    value: boolean | ((prevState: boolean) => boolean),
  ) => void;
};

export function ChangeEmail({
  changeEmailModal,
  setChangeEmailModal,
}: ChangeEmailModalType): ReactElement {
  /*initial focus on text feild */
  const emailRef = useRef(null);

  const { userInfo, userId } = useAuth();

  const [newEmailId, setNewEmailId] = useState<string>('');
  const [isConfirm, setIsConfirm] = useState<boolean>(false);

  const { mutate: onEmailChange } = useChangeEmail();

  function onConfirmUpdateEmailHandler() {
    setIsConfirm(false);
    const params = {
      UserId: userId,
      NewEmailId: newEmailId,
      fromSettings: true,
    };
    onEmailChange(params, {
      onSuccess: () => {
        showSuccessToast({
          message: changeSettingsEmailSuccessMsg,
        });
        setNewEmailId('');
      },
      onError: (error: any) => {
        showErrorToast({
          message: error[0],
        });
        setChangeEmailModal(true);
      },
    });
  }

  function renderCurrentEmail() {
    return (
      <div className="px-2 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
        <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-white">
          Current email
        </dt>
        <dd className="mt-1 truncate text-sm leading-6 text-gray-700 dark:text-neutral-400 sm:col-span-2 sm:mt-0">
          {userInfo?.email}
        </dd>
      </div>
    );
  }

  function renderNewEmail() {
    return (
      <div className="px-2 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
        <dt className="flex items-center text-sm font-medium leading-6 text-gray-900 dark:text-white">
          New email
        </dt>
        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
          <TextField
            id="new-email"
            value={newEmailId}
            ref={emailRef}
            onChange={e => {
              setNewEmailId(e.target.value);
            }}
            validationError={
              !newEmailId.toLowerCase().match(emailRegex) && newEmailId?.length
                ? 'Invalid email'
                : ''
            }
          />
        </dd>
      </div>
    );
  }

  function renderConfirmAlert({
    message,
    showModel,
    setShowModel,
    type,
    buttonSet,
    hasPreviewAgainCheck,
    previewAgain,
    setPreviewAgain,
  }: ConfirmModalType): ReactElement {
    return (
      <>
        <ConfirmAlert
          message={message}
          showModel={showModel}
          setShowModel={setShowModel}
          type={type}
          buttonSet={buttonSet}
          hasDontshowAgainCheck={hasPreviewAgainCheck}
          dontShowAgain={previewAgain}
          setDontShowAgain={setPreviewAgain}
          setValueEmpty={setNewEmailId}
        />
      </>
    );
  }

  function renderFooterButtons() {
    return (
      <div className="flex gap-[1ch] bg-gray-50 p-4 dark:bg-neutral-700/50 sm:justify-end sm:p-3">
        <Button
          variant="secondary"
          onClick={() => {
            setNewEmailId('');
            setChangeEmailModal(false);
          }}
          className="grow sm:grow-0"
        >
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            setIsConfirm(true);
            setChangeEmailModal(false);
          }}
          className="grow sm:grow-0"
          disabled={
            !newEmailId.toLowerCase().match(emailRegex) ||
            newEmailId?.length === 0
          }
        >
          Update
        </Button>
      </div>
    );
  }

  return (
    <>
      <Transition appear show={changeEmailModal} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={emailRef}
          onClose={() => {
            setChangeEmailModal(true);
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="pr-dialog__backdrop" />
          </Transition.Child>

          <div className="pr-dialog__wrapper">
            <div className="pr-dialog__panel__container">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="pr-dialog__panel">
                  <Dialog.Title
                    as="h3"
                    className="px-4 pt-4 text-lg font-medium leading-6 text-gray-900 dark:text-white"
                  >
                    Change user email
                  </Dialog.Title>
                  <button
                    type="button"
                    className="absolute right-4 top-4 text-gray-400 hover:text-gray-500 sm:right-4 sm:top-4"
                    onClick={() => {
                      setNewEmailId('');
                      setChangeEmailModal(false);
                    }}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>

                  <div className="mt-2 p-4">
                    {renderCurrentEmail()}
                    {renderNewEmail()}
                  </div>

                  {renderFooterButtons()}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
      {isConfirm &&
        renderConfirmAlert({
          message: changeSettingsEmailMsg,
          showModel: isConfirm,
          setShowModel: setIsConfirm,
          buttonSet: [
            {
              label: 'No',
              handleClick: () => {
                setIsConfirm(false);
                setNewEmailId('');
              },
              variant: 'secondary',
            },
            {
              label: 'Yes',
              handleClick: () => onConfirmUpdateEmailHandler(),
              variant: 'primary',
            },
          ],
        })}
    </>
  );
}
