import { useMutation, UseMutationResult } from 'react-query';
import { emailAsTwoFactor } from 'src/utils/urls';
import { useProgressStore } from './useProgressStore';

export type TResult = boolean | undefined;
export type TError = { message: string };
export type TVariable = {
  userName: string | undefined;
};

async function sendEmailAsTwofactorAuth({
  userName,
}: TVariable): Promise<TResult> {
  const response = await fetch(emailAsTwoFactor(), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ UserName: userName }),
  });
  return response.json();
}

function useEmailAsTwofactorAuth(): UseMutationResult<
  TResult,
  TError,
  TVariable,
  unknown
> {
  const { setLoading } = useProgressStore();
  return useMutation(
    async ({ userName }: TVariable) => {
      setLoading('isEmailAsAuthLoading', true);
      const result = await sendEmailAsTwofactorAuth({
        userName,
      });
      return result;
    },
    {
      onSettled: () => {
        setLoading('isEmailAsAuthLoading', false);
      },
    },
  );
}

export { useEmailAsTwofactorAuth };
