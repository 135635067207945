import React, { ReactElement, useCallback, useState } from 'react';

import { TextField, Select, Spinner } from 'src/ui/components';

import {
  useGetStateProvince,
  useGetCountry,
  useGetAddress,
} from 'src/hooks/index';

import { usePersonAlertStore } from '../../store/personAlertStore';
import { formatSelectOptions } from 'src/utils/common';
import { convertToNumber } from '../../utils/personStoreHelper';

import { TextFieldType } from 'src/types/textFileType';

function AddressInformation(): ReactElement {
  const [showCard, setShowCard] = useState<boolean>(false);

  const person = usePersonAlertStore(
    useCallback(state => state?.alertDetails?.Person?.PersonAlertDetails, []),
  );

  const updateAddressDetails = usePersonAlertStore(
    useCallback(state => state.updateAddressDetails, []),
  );

  const updateOtherDetails = usePersonAlertStore(
    useCallback(state => state.updateOtherDetails, []),
  );

  const [postalCode, setPostalCode] = useState<string>('');

  const { data: postalAddressList, isLoading: isAddressLoading } =
    useGetAddress(postalCode);
  const { data: stateprovince } = useGetStateProvince();
  const { data: countryList } = useGetCountry();

  const stateProvinceDropDownList = formatSelectOptions(stateprovince);
  const countryDropDownList = formatSelectOptions(countryList);

  function renderTextField({
    id,
    onChangeText,
    value,
    key,
    label,
    className,
    personDetailKey,
    disabled,
    required,
  }: TextFieldType): ReactElement {
    return (
      <TextField
        id={id}
        type="search"
        label={label}
        value={value}
        onChange={e => {
          onChangeText &&
            key &&
            onChangeText(key, e.target.value, personDetailKey);
        }}
        className={className}
        disabled={disabled}
        required={required}
      />
    );
  }

  function renderSelectField({
    id,
    onChangeOption,
    value,
    key,
    options,
    label,
    personDetailKey,
    className,
  }: {
    id: string;
    onChangeOption: (key: string, val: any, personDetailKey?: string) => void;
    value: string;
    key: string;
    options: Array<{ label: string; value: number }>;
    label?: string;
    personDetailKey?: string;
    className?: string;
  }): ReactElement {
    return (
      <Select
        id={id}
        options={options}
        label={label}
        onChange={e => {
          onChangeOption(
            key,
            convertToNumber(e.currentTarget.value),
            personDetailKey,
          );
        }}
        value={value}
        className={className}
      />
    );
  }

  return (
    <div className="space-y-3 md:pt-6">
      <h3 className="pb-1 text-lg font-medium text-black dark:text-white">
        Address
      </h3>
      <div className="grid grid-cols-1 gap-4 pb-3 sm:grid-cols-2 md:grid-cols-3">
        <div className="relative">
          <TextField
            id="search-by-zip"
            label="Search by zip/postal code"
            type="search"
            placeholder="Search by zip/postal code"
            onChange={e => {
              setPostalCode(e?.target?.value);
              setShowCard(true);
            }}
            value={
              person?.PrimaryAddress?.Code
                ? person?.PrimaryAddress?.Code
                : postalCode
            }
          />
          {postalCode !== '' && showCard && (
            <div className="absolute inset-x-0 top-full mt-2 max-h-52 overflow-y-auto overflow-x-hidden rounded-lg border bg-white shadow-lg dark:border-neutral-600 dark:bg-neutral-700">
              {isAddressLoading && (
                <div className="grid place-items-center p-4">
                  <Spinner />
                </div>
              )}
              <ul className="divide-y text-black dark:divide-neutral-800 dark:text-white ">
                {postalAddressList?.length
                  ? postalAddressList?.map((item, index) => {
                      return (
                        <li
                          className="cursor-pointer px-4 py-2 hover:bg-gray-100 dark:hover:bg-neutral-600"
                          key={`${item?.Code}${index}`}
                          onClick={() => {
                            updateAddressDetails(item);
                            setShowCard(false);
                          }}
                        >
                          <p className="truncate font-medium">
                            {item?.Code}:{item?.PlaceName}
                          </p>
                          <p className="opacity-60">
                            {item?.StateProvince?.Name},{item?.Country?.Name}
                          </p>
                        </li>
                      );
                    })
                  : postalCode !== '' &&
                    !isAddressLoading && (
                      <p className="p-3 text-center opacity-80">{`No result found for ${postalCode}`}</p>
                    )}
              </ul>
            </div>
          )}
        </div>
      </div>
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 sm:gap-y-2 md:grid-cols-3">
        {renderTextField({
          id: 'street1',
          label: 'Street 1',
          key: 'Street1',
          personDetailKey: 'PrimaryAddress',
          value: person?.PrimaryAddress?.Street1
            ? person?.PrimaryAddress?.Street1
            : '',
          onChangeText: updateOtherDetails,
        })}

        {renderTextField({
          id: 'street2',
          label: 'Street 2',
          key: 'Street2',
          personDetailKey: 'PrimaryAddress',
          value: person?.PrimaryAddress?.Street2
            ? person?.PrimaryAddress?.Street2
            : '',
          onChangeText: updateOtherDetails,
        })}

        {renderTextField({
          id: 'street3',
          label: 'Street 3',
          key: 'Street3',
          personDetailKey: 'PrimaryAddress',
          value: person?.PrimaryAddress?.Street3
            ? person?.PrimaryAddress?.Street3
            : '',
          onChangeText: updateOtherDetails,
        })}

        {renderTextField({
          id: 'city',
          label: 'City',
          key: 'City',
          personDetailKey: 'PrimaryAddress',
          value: person?.PrimaryAddress?.City
            ? person?.PrimaryAddress?.City
            : '',
          onChangeText: updateOtherDetails,
        })}

        {renderSelectField({
          id: 'state-province',
          label: 'State/Province',
          key: 'StateProvinceId',
          personDetailKey: 'PrimaryAddress',
          options: stateProvinceDropDownList,
          onChangeOption: updateOtherDetails,
          value: person?.PrimaryAddress?.StateProvinceId
            ? person?.PrimaryAddress?.StateProvinceId
            : -1,
        })}

        {renderTextField({
          id: 'postalcode',
          label: 'Zip/Postal code',
          key: 'ZipPostalCode',
          personDetailKey: 'PrimaryAddress',
          value: person?.PrimaryAddress?.ZipPostalCode
            ? person?.PrimaryAddress?.ZipPostalCode
            : '',
          onChangeText: updateOtherDetails,
        })}

        {renderSelectField({
          id: 'country',
          label: 'Country',
          key: 'CountryId',
          personDetailKey: 'PrimaryAddress',
          options: countryDropDownList,
          onChangeOption: updateOtherDetails,
          value: person?.PrimaryAddress?.CountryId
            ? person?.PrimaryAddress?.CountryId
            : '',
        })}

        {renderTextField({
          id: 'county',
          label: 'County',
          key: 'County',
          personDetailKey: 'PrimaryAddress',
          value: person?.PrimaryAddress?.County
            ? person?.PrimaryAddress?.County
            : '',
          onChangeText: updateOtherDetails,
        })}
      </div>
    </div>
  );
}

export { AddressInformation };
