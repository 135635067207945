import { useMutation, UseMutationResult, useQueryClient } from 'react-query';

import { useAuth } from 'src/hooks';

import { changeReadStatus } from 'src/utils/urls';

async function fetchUpdateTag({
  requestData,
  token,
  tokenType,
}: {
  requestData: TParam;
  token: string;
  tokenType: string;
}): Promise<TResult> {
  const response = await fetch(changeReadStatus, {
    method: 'POST',
    headers: {
      Authorization: `${tokenType} ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(requestData),
  });
  return response.json();
}

export type TResult = string;
export type TError = { message: string };

export type TParam = Array<{
  Id: number;
  IsRead: boolean;
}>;

function useChangeReadStatus(): UseMutationResult<
  TResult,
  TError,
  TParam,
  unknown
> {
  const { token, tokenType } = useAuth();
  const queryClient = useQueryClient();
  return useMutation(async (params: TParam) => {
    // const requestData = setRequestData(params);
    const result = await fetchUpdateTag({
      token: token,
      tokenType: tokenType,
      requestData: params,
    });
    queryClient.invalidateQueries('get_call_documents');
    return result;
  });
}

export { useChangeReadStatus };
