import { UseQueryResult, useQuery } from 'react-query';

import { useAuth } from 'src/hooks';

import { formatStatus } from '../utils/ExtraCoverageFilterHelper';

import { getExtraCoverageStatusList } from 'src/utils/urls';

async function fetchExtraCoverageRequestStatuses(
  token: string,
  tokenType: string,
): Promise<StatusType> {
  const response = await fetch(getExtraCoverageStatusList, {
    method: 'GET',
    headers: {
      Authorization: `${tokenType} ${token} `,
    },
  });
  return response.json();
}

type TError = {
  message: string;
};

type StatusType = Array<{
  Description: string;
  Id: number;
}>;

type ApiResult = StatusType;

function useGetExtraCoverageRequestStatuses(): UseQueryResult<
  ApiResult,
  TError
> {
  const { token, tokenType } = useAuth();
  return useQuery(
    'extracoverage_request_statuses',
    async () => {
      const result = await fetchExtraCoverageRequestStatuses(token, tokenType);
      return result;
    },
    {
      staleTime: 60 * 1000,
      refetchOnWindowFocus: false,
    },
  );
}

export { useGetExtraCoverageRequestStatuses };
