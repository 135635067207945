import { useQuery, UseQueryResult } from 'react-query';

import { useAuth } from 'src/hooks';

import { getActivityLog } from 'src/utils/urls';
import { pageLength } from 'src/utils/appConstants';
import { setListRequestData } from '../utils/documentReqData';

import { ActivityLogFilterType } from 'src/features/ActivityLog/types/filterOptionsType';
import { ActivityLogListType } from 'src/features/ActivityLog/types/activityLogType';

async function fetchActivityLog({
  requestData,
  token,
  tokenType,
}: TVariables): Promise<TPage> {
  const response = await fetch(getActivityLog, {
    method: 'POST',
    headers: {
      Authorization: `${tokenType} ${token}`,
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: requestData,
  });
  return response.json();
}

export type TVariables = {
  requestData: any;
  token: string;
  tokenType: string;
};

export type TError = { message: string };

export type TPage = {
  data: Array<ActivityLogListType>;
  draw: number;
  error: null;
  isAutoRefreshEnabled: boolean;
  pageNumber: number;
  recordsFiltered: number;
  recordsTotal: number;
};

function useGetDocumentActivity(
  filterOptions: ActivityLogFilterType,
): UseQueryResult<Array<ActivityLogListType>, unknown> {
  const { token, tokenType } = useAuth();
  return useQuery(
    ['get_recent_document_activity', filterOptions],
    async () => {
      const requestData = setListRequestData(filterOptions);
      const result = await fetchActivityLog({ token, tokenType, requestData });
      return result.data;
    },
    {
      staleTime: 60 * 1000,
      refetchOnWindowFocus: false,
    },
  );
}

export { useGetDocumentActivity };
