const minDigitLen = 10;

// To check the searchvalue is a phone number.
function isPhoneNumberOrNot(str: string | undefined): boolean {
  const searchValue = str ? str.replace(/[^a-zA-Z0-9 ]/g, '') : ''; //to remove special characters
  if (searchValue.length >= minDigitLen)
    return (
      searchValue.substring(0, minDigitLen).replace(/[^0-9]/g, '').length >=
      minDigitLen
    );
  else return false;
}

export { isPhoneNumberOrNot };
