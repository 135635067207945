import { useQuery, UseQueryResult } from 'react-query';

import { useAuth } from 'src/hooks';

import { getTagList } from 'src/utils/urls';

import { TagType } from '../types/callDocumentList';

async function fetchTagList(
  token: string,
  tokenType: string,
): Promise<ApiResult> {
  const response = await fetch(getTagList, {
    method: 'GET',
    headers: {
      Authorization: `${tokenType} ${token}`,
    },
  });
  return response.json();
}

export type ApiResult = Array<TagType>;
export type TError = { message: string };

type TResult = Array<{ value: number; label: string }>;

function useGetTagList(): UseQueryResult<ApiResult, TError> {
  const { token, tokenType } = useAuth();
  return useQuery(
    'getTagList',
    async () => {
      const result = await fetchTagList(token, tokenType);
      return result;
    },
    {
      staleTime: 60 * 1000,
      refetchOnWindowFocus: false,
    },
  );
}
export { useGetTagList };
