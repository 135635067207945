import React, { ReactElement, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  TrashIcon,
  AdjustmentsHorizontalIcon,
  PencilIcon,
  PlayIcon,
  EllipsisHorizontalIcon,
} from '@heroicons/react/20/solid';
import { Menu, Transition } from '@headlessui/react';
import { useQueryClient } from 'react-query';

import { Button, ConfirmAlert } from 'src/ui/components';

import { useRemoveProvider, useSyncProvider } from '../api';

import { showErrorToast, showSuccessToast } from 'src/utils/ToastNotification';
import { disableSyncBtn } from '../utils/resourceHelper';
import { classNames } from 'src/utils/className';

import { useAddEditProviderStore } from '../store/addEditProviderStore';
import { useFieldConfigurationStore } from '../store/fieldConfigurationStore';

import { ResourceListType } from '../types/resourceList';

function UserActions({
  selectedProvider,
  setSelectedProvider,
}: {
  selectedProvider: ResourceListType | undefined;
  setSelectedProvider: (
    value:
      | ResourceListType
      | undefined
      | ((prevState: ResourceListType | undefined) => ResourceListType),
  ) => void;
}): ReactElement {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [removeConfirmModal, setRemoveConfirmModal] = useState(false);

  const { mutate: removeProvider } = useRemoveProvider();

  const { mutate: syncProvider } = useSyncProvider();

  const clearProviderStore = useAddEditProviderStore(
    useCallback(state => state.clearProviderStore, []),
  );

  const deleteProviderStore = useFieldConfigurationStore(
    useCallback(state => state.deleteProviderStore, []),
  );

  function onRemoveProviderHandler() {
    if (selectedProvider) {
      setRemoveConfirmModal(false);
      removeProvider(selectedProvider?.Id, {
        onSuccess: () => {
          queryClient.invalidateQueries(['get-resource-list'], {
            refetchActive: false,
          });
          setSelectedProvider(undefined);
          showSuccessToast({ message: 'Provider deleted successfully.' });
        },
        onError: message => {
          showErrorToast({ message: message?.message });
        },
      });
    }
  }

  function onSyncProviderHandler() {
    if (selectedProvider)
      syncProvider(
        { id: selectedProvider.Id },
        {
          onSuccess: () => {
            setSelectedProvider(undefined);
            showSuccessToast({
              message: 'Manual sync successfully triggered.',
            });
          },
          onError: () => {
            showErrorToast({
              message: 'Error occured while manual sync. Please try again',
            });
          },
        },
      );
  }

  function ConfirmationModal(): ReactElement {
    return (
      <ConfirmAlert
        message="Are you sure you want to remove this provider?"
        showModel={removeConfirmModal}
        setShowModel={setRemoveConfirmModal}
        buttonSet={[
          {
            label: 'No',
            handleClick: () => {
              setRemoveConfirmModal(false);
            },
            variant: 'secondary',
          },
          {
            label: 'Yes',
            handleClick: () => onRemoveProviderHandler(),
            variant: 'primary',
          },
        ]}
      />
    );
  }

  function responsiveUserActions(): ReactElement {
    return (
      <>
        <Menu as="div" className="relative z-10 ml-3 md:hidden">
          <Menu.Button
            as={Button}
            variant="secondary"
            size="small"
            className={classNames(
              selectedProvider ? '' : 'pointer-events-none opacity-50',
            )}
            disabled={selectedProvider ? false : true}
          >
            <EllipsisHorizontalIcon className="h-5 w-5" aria-hidden="true" />
          </Menu.Button>

          <Transition
            enter="transition ease-out duration-200"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-0 z-10 -mr-1 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none dark:bg-neutral-700">
              <Menu.Item>
                <a
                  className={
                    'block px-4 py-2 text-sm text-gray-800 dark:text-white'
                  }
                  onClick={() => {
                    clearProviderStore();
                    navigate(`/Resources/EditProvider/${selectedProvider?.Id}`);
                  }}
                >
                  Edit provider
                </a>
              </Menu.Item>
              <Menu.Item>
                <a
                  className={
                    'block px-4 py-2 text-sm text-gray-800 aria-disabled:pointer-events-none aria-disabled:opacity-50 dark:text-white'
                  }
                  onClick={() => {
                    setRemoveConfirmModal(true);
                  }}
                >
                  Remove provider
                </a>
              </Menu.Item>

              <Menu.Item>
                <a
                  className={
                    'block px-4 py-2 text-sm text-gray-800 dark:text-white'
                  }
                  onClick={() => {
                    navigate(
                      `/Resources/FieldConfiguration/${selectedProvider?.Id}`,
                    );
                  }}
                >
                  Field configuration
                </a>
              </Menu.Item>
              <Menu.Item
                as="a"
                className={
                  'block px-4 py-2 text-sm text-gray-800 aria-disabled:pointer-events-none aria-disabled:opacity-50 dark:text-white'
                }
                onClick={() => onSyncProviderHandler()}
                disabled={disableSyncBtn(selectedProvider?.DataSync)}
              >
                Sync provider
              </Menu.Item>
            </Menu.Items>
          </Transition>
        </Menu>
      </>
    );
  }

  return (
    <>
      <div className="hidden gap-4 md:flex">
        {selectedProvider ? (
          <>
            <Button
              variant="subtle"
              size="small"
              onClick={() => {
                clearProviderStore();
                navigate(`/Resources/EditProvider/${selectedProvider?.Id}`);
              }}
            >
              <PencilIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
              <span className="hidden sm:block">Edit provider</span>
            </Button>
            <Button
              variant="subtle"
              size="small"
              onClick={() => {
                setRemoveConfirmModal(true);
              }}
            >
              <TrashIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
              <span className="hidden sm:block">Remove provider</span>
            </Button>
            <Button
              variant="subtle"
              size="small"
              onClick={() => {
                deleteProviderStore();
                navigate(
                  `/Resources/FieldConfiguration/${selectedProvider.Id}`,
                );
              }}
            >
              <AdjustmentsHorizontalIcon
                className="-ml-0.5 h-5 w-5"
                aria-hidden="true"
              />
              <span className="hidden sm:block">Field configuration</span>
            </Button>
            <Button
              variant="subtle"
              size="small"
              onClick={() => onSyncProviderHandler()}
              disabled={disableSyncBtn(selectedProvider.DataSync)}
            >
              <PlayIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
              <span className="hidden sm:block">Sync provider</span>
            </Button>
          </>
        ) : null}

        {removeConfirmModal === true && <ConfirmationModal />}
      </div>
      {responsiveUserActions()}
    </>
  );
}

export { UserActions };
