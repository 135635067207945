import { create } from 'zustand';
import produce from 'immer';

import { fecthProviderDetails } from './apiStore';

import { checkValidation, urlPattern } from '../utils/resourceHelper';

import { AddEditProviderStore } from '../types/addEditProvider';

export const useAddEditProviderStore = create<AddEditProviderStore>(
  (set, get) => ({
    errorMessage: '',
    providerDetails: {
      httpVerb: null,
      timeInterval: null,
      endpoint: '',
      name: '',
      authenticationType: null,
    },
    secret: {
      ProviderId: '',
      Username: '',
      Password: '',
      ApiKey: '',
    },
    userMode: '',
    saveClicked: false,
    isResponseLoading: false,
    authenticationIndication: null,

    updateProviderDetails: (key: string, value: string | number) => {
      set(
        produce(state => {
          state.providerDetails[key] = value;
        }),
      );
    },

    updateSecretDetails: (key: string, value: string | number) => {
      set(
        produce(state => {
          state.secret[key] = value;
        }),
      );
    },

    fetchProviderDetails: async (
      token: string,
      tokenType: string,
      id: string,
    ) => {
      set({
        isResponseLoading: true,
      });
      const response = await fecthProviderDetails({ token, tokenType, id });
      if (!urlPattern.test(response.endpoint)) {
        set(
          produce(state => {
            state.errorMessage = 'Invalid URL';
          }),
        );
      }
      set(
        produce(state => {
          state.providerDetails = { ...response };
          state.authenticationIndication = response?.authenticationType;
          state.isResponseLoading = false;
        }),
      );
    },

    updateUserMode: (value: string) => {
      set(
        produce(state => {
          state.userMode = value;
        }),
      );
    },

    setSaveClicked: (value: boolean) => {
      set(
        produce(state => {
          state.saveClicked = value;
        }),
      );
    },
    disableSave: () => {
      const value = checkValidation(
        get().providerDetails,
        get().secret,
        get().userMode,
        get().authenticationIndication,
      );
      return value;
    },

    clearProviderStore: () => {
      set(state => {
        return {
          ...state,
          providerDetails: {},
          secret: {
            ProviderId: '',
            Username: '',
            Password: '',
            ApiKey: '',
          },
          saveClicked: false,
          authenticationIndication: null,
          errorMessage: '',
        };
      });
    },
    setErrorMessage: (val: string) => {
      set(
        produce(state => {
          state.errorMessage = val;
        }),
      );
    },
  }),
);
