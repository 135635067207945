import React, { ReactElement } from 'react';

import { Button } from 'src/ui/components';

import { useTitle } from 'src/hooks';

import { AuthServiceSingleton } from 'src/utils/authenticationHelper/authService';

export default function Expired(): ReactElement {
  useTitle('Expired');
  return (
    <main className="grid flex-1 place-items-center overflow-y-auto overscroll-contain px-6 py-24 sm:py-32 lg:px-8">
      <div className="text-center">
        <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 dark:text-neutral-100 sm:text-5xl">
          Expired
        </h1>
        <p className="mt-6 text-base leading-7 text-gray-600 dark:text-neutral-500">
          Due to inactivity, your session has expired. <br />
          If you would like to sign back in, use the Sign in button below.
        </p>
        <div className="mt-10 flex items-center justify-center gap-x-6">
          <Button
            variant="primary"
            size="large"
            onClick={() => AuthServiceSingleton.getInstance().signIn()}
          >
            Sign in
          </Button>
        </div>
      </div>
    </main>
  );
}
