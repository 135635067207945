import { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { debounce } from 'lodash';

import { SILENT_SIGN_IN_LOADING } from './authConstants';
import { AuthServiceSingleton } from './authService';

const silentRenewTime = 5 * 60 * 1000; // silent renew token in every 5 min

export function useRenewTimer(isOnBoarding: boolean) {
  const activeTimeRef = useRef<number | undefined>();
  const navigate = useNavigate();

  useEffect(() => {
    if (isOnBoarding) return;
    const setLastActiveTime = () => {
      const token = localStorage.getItem('AUTH_TOKEN') || '';
      const expiryTime = token ? JSON.parse(token)?.expires_at * 1000 : 0;
      // const delay = 2 * 60 * 1000;
      if (expiryTime < Date.now()) {
        //show expiry
        navigate('/Home/Expired');
        return;
      }
      if (
        activeTimeRef.current &&
        Date.now() - activeTimeRef.current > 5 * 60 * 1000
      ) {
        triggerSilentSign();
      }
      activeTimeRef.current = Date.now();
    };
    const debounceStorageUpdate = debounce(setLastActiveTime, 1000, {
      trailing: true,
    });
    function trackLastActivity() {
      window.onload = debounceStorageUpdate;
      window.onmousemove = debounceStorageUpdate;
      window.onmousedown = debounceStorageUpdate;
      window.ontouchstart = debounceStorageUpdate;
      window.ontouchmove = debounceStorageUpdate;
      window.onclick = debounceStorageUpdate;
      window.onkeydown = debounceStorageUpdate;
      window.addEventListener('scroll', debounceStorageUpdate, true);
    }
    trackLastActivity();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isOnBoarding) return;
    const interval = setInterval(() => {
      const lastActiveTime = activeTimeRef.current;
      if (!lastActiveTime || Date.now() - lastActiveTime < 5 * 60 * 1000)
        triggerSilentSign();
    }, silentRenewTime);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}

function triggerSilentSign() {
  localStorage.setItem(SILENT_SIGN_IN_LOADING, 'TRUE');
  AuthServiceSingleton.getInstance().signinSilent();
  setTimeout(() => {
    localStorage.removeItem(SILENT_SIGN_IN_LOADING);
  }, 3 * 60 * 1000);
}
