import React, { ReactElement, useEffect, useRef } from 'react';
import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';

import { Spinner } from 'src/ui/components';

import { useGetPersonAlertPreview } from 'src/features/PersonAlerts/api';
import { useTitle, useGetCountry } from 'src/hooks';

import { createMarkup, calculateAgeAsString } from 'src/utils/common';
import { NotReadyToChatIcon, ReadyToChatIcon } from 'src/assets/icons';

export default function PrintPrevew(): ReactElement {
  useTitle('Print Preview');

  const { id } = useParams();

  const accountId = new URLSearchParams(window.location.search).get(
    'accountId',
  );

  const printRef = useRef(false);

  const { data: personAlert, isLoading } = useGetPersonAlertPreview(
    accountId ? parseInt(accountId) : undefined,
    id ? parseInt(id) : undefined,
    true,
  );

  const { data: countryList } = useGetCountry();

  useEffect(() => {
    if (!printRef.current && !isLoading) {
      printRef.current = true;
      setTimeout(() => {
        window.print();
      }, 1000);
    }
  }, [isLoading]);

  function getCountryCode(countryId: number | undefined) {
    if (countryId)
      return countryList?.find((item: any) => item?.Id === countryId)?.ISDCode;
    else return '+1';
  }

  function renderPersonInformation() {
    return (
      <dl className="border dark:border-dark-medium">
        <dt className="sticky -top-px border-b bg-gray-100 px-3 py-2 text-xl font-semibold dark:border-dark-medium dark:bg-neutral-900">
          Person information
        </dt>
        <dd className="flex gap-2 border-b dark:border-dark-medium">
          <h4 className="w-1/3 p-2 text-sm font-medium">First name</h4>
          <div className="w-4/6 border-l border-l-light-light p-2 text-sm dark:border-l-dark-medium">
            <p>{personAlert?.Person.FirstName}</p>
          </div>
        </dd>
        <dd className="flex gap-2  border-b dark:border-dark-medium">
          <h4 className="w-1/3 p-2 text-sm font-medium">Last name</h4>
          <div className="w-4/6 border-l border-l-light-light p-2 text-sm dark:border-l-dark-medium">
            <p>{personAlert?.Person.LastName}</p>
          </div>
        </dd>
        <dd className="flex gap-2  border-b dark:border-dark-medium">
          <h4 className="w-1/3 p-2 text-sm font-medium">Alternative name</h4>
          <div className="w-4/6 border-l border-l-light-light p-2 text-sm dark:border-l-dark-medium">
            <p>{personAlert?.Person.PreferredName}</p>
          </div>
        </dd>
        <dd className="flex gap-2  border-b dark:border-dark-medium">
          <h4 className="w-1/3 p-2 text-sm font-medium">Address</h4>
          <div className="w-4/6 border-l border-l-light-light p-2 text-sm dark:border-l-dark-medium">
            {/* <div className="w-4/6 p-2 text-sm"> */}
            <p>{personAlert?.Person?.PrimaryAddress?.Street1}</p>
            <p>{personAlert?.Person?.PrimaryAddress?.Street2}</p>
            <p>{personAlert?.Person?.PrimaryAddress?.Street3}</p>
            <p>{personAlert?.Person?.PrimaryAddress?.City}</p>
            <p>{personAlert?.Person?.PrimaryAddress?.ZipPostalCode}</p>
          </div>
        </dd>
        <dd className="flex gap-2  border-b dark:border-dark-medium">
          <h4 className="w-1/3 p-2 text-sm font-medium">
            Primary phone number
          </h4>
          <div className="w-4/6 border-l border-l-light-light p-2 text-sm dark:border-l-dark-medium">
            <p>
              {personAlert?.Person?.PrimaryPhone?.Number !== null && (
                <>
                  ({' '}
                  {getCountryCode(personAlert?.Person?.PrimaryPhone?.CountryId)}{' '}
                  ) {''} {personAlert?.Person?.PrimaryPhone?.Number}{' '}
                  {personAlert?.Person?.PrimaryPhone?.OkToLeaveMessage ===
                    true && (
                    <ReadyToChatIcon className="text-green-700 dark:text-green-400" />
                  )}
                  {personAlert?.Person?.PrimaryPhone?.OkToLeaveMessage ===
                    false && (
                    <NotReadyToChatIcon className="ms-1 text-red-700 dark:text-red-400" />
                  )}
                </>
              )}
            </p>
          </div>
        </dd>
        <dd className="flex gap-2  border-b dark:border-dark-medium">
          <h4 className="w-1/3 p-2 text-sm font-medium">
            Secondary phone number
          </h4>
          <div className="w-4/6 border-l border-l-light-light p-2 text-sm dark:border-l-dark-medium">
            <p>
              {personAlert?.Person?.SecondaryPhone?.Number !== null && (
                <p>
                  ({' '}
                  {getCountryCode(
                    personAlert?.Person?.SecondaryPhone?.CountryId,
                  )}{' '}
                  ) {''} {personAlert?.Person?.SecondaryPhone?.Number}{' '}
                  {personAlert?.Person?.SecondaryPhone?.OkToLeaveMessage ===
                    true && (
                    <ReadyToChatIcon className="ms-1 text-green-700 dark:text-green-400" />
                  )}
                  {personAlert?.Person?.SecondaryPhone?.OkToLeaveMessage ===
                    false && (
                    <NotReadyToChatIcon className="ms-1 text-red-700 dark:text-red-400" />
                  )}
                </p>
              )}
            </p>
          </div>
        </dd>
        <dd className="flex gap-2  border-b dark:border-dark-medium">
          <h4 className="w-1/3 p-2 text-sm font-medium">Gender</h4>
          <div className="w-4/6 border-l border-l-light-light p-2 text-sm dark:border-l-dark-medium">
            <p>{personAlert?.Person?.Gender?.Description}</p>
          </div>
        </dd>
        <dd className="flex gap-2  border-b dark:border-dark-medium">
          <h4 className="w-1/3 p-2 text-sm font-medium">Date of Birth</h4>
          <div className="flex w-4/6 gap-3 border-l border-l-light-light p-2 text-sm dark:border-l-dark-medium">
            <p>
              {personAlert?.Person?.Birthdate != null ? (
                <>
                  <label className="text-sm font-medium">Birth date: </label>
                  <span>
                    {dayjs(personAlert?.Person?.Birthdate).format('MM/DD/YYYY')}
                  </span>
                </>
              ) : (
                ''
              )}
            </p>

            {personAlert?.Person?.Birthdate !== null ||
            (personAlert?.Person?.Age !== null &&
              personAlert?.Person?.Age !== '') ? (
              <div>
                <label className="text-sm font-medium">Age: </label>
                <span>
                  {personAlert?.Person?.Age !== null
                    ? personAlert?.Person?.Age
                    : calculateAgeAsString(
                        personAlert?.Person?.Birthdate || '',
                      )}
                </span>
              </div>
            ) : null}
          </div>
        </dd>
      </dl>
    );
  }

  function renderAlertInformation() {
    return (
      <dl className="border dark:border-dark-medium">
        <dt className="sticky -top-px border-b bg-gray-100 px-3 py-2 text-xl font-semibold dark:border-dark-medium dark:bg-neutral-900">
          Information
        </dt>
        <dd className="flex gap-2 border-b dark:border-dark-medium">
          <h4 className="w-1/3 p-2 text-sm font-medium">
            Counselor or Case manager (if applicable)
          </h4>
          <div className="w-4/6 border-l border-l-light-light p-2 text-sm dark:border-l-dark-medium">
            <p>{personAlert?.CounselorOrCaseManager}</p>
          </div>
        </dd>
        <dd className="flex gap-2  border-b dark:border-dark-medium">
          <h4 className="w-1/3 p-2 text-sm font-medium">
            Special program participant
          </h4>
          <div className="w-4/6 border-l border-l-light-light p-2 text-sm dark:border-l-dark-medium">
            <p>{personAlert?.SpecialProgramsParticipant}</p>
          </div>
        </dd>
        <dd className="flex gap-2  border-b dark:border-dark-medium">
          <h4 className="w-1/3 p-2 text-sm font-medium">
            Situation description
          </h4>
          <div className="w-4/6 border-l border-l-light-light p-2 text-sm dark:border-l-dark-medium">
            <div
              className="prose max-w-[80ch] dark:prose-invert"
              dangerouslySetInnerHTML={createMarkup(
                personAlert?.SituationDescription || '',
              )}
            ></div>
          </div>
        </dd>
        <dd className="flex gap-2  border-b dark:border-dark-medium">
          <h4 className="w-1/3 p-2 text-sm font-medium">
            Telephone intervention description
          </h4>

          <div className="w-4/6 border-l border-l-light-light p-2 text-sm dark:border-l-dark-medium">
            <div
              className="prose max-w-[80ch] dark:prose-invert"
              dangerouslySetInnerHTML={createMarkup(
                personAlert?.TelephoneInterventionDescription || '',
              )}
            ></div>
          </div>
        </dd>
      </dl>
    );
  }

  function renderBasicInformation() {
    return (
      <dl className="border dark:border-dark-medium">
        <dt className="sticky -top-px border-b bg-gray-100 px-3 py-2 text-xl font-semibold dark:border-dark-medium dark:bg-neutral-900">
          Information
        </dt>
        <dd className="flex gap-2 border-b dark:border-dark-medium">
          <h4 className="w-1/3 p-2 text-sm font-medium">Alert ID</h4>
          <div className="w-4/6 border-l border-l-light-light p-2 text-sm dark:border-l-dark-medium">
            <p>{personAlert?.Id}</p>
          </div>
        </dd>
        <dd className="flex gap-2  border-b dark:border-dark-medium">
          <h4 className="w-1/3 p-2 text-sm font-medium">Entered by</h4>
          <div className="w-4/6 border-l border-l-light-light p-2 text-sm dark:border-l-dark-medium">
            <p>{personAlert?.EnteredBy}</p>
          </div>
        </dd>
        <dd className="flex gap-2  border-b dark:border-dark-medium">
          <h4 className="w-1/3 p-2 text-sm font-medium">Entered date</h4>
          <div className="w-4/6 border-l border-l-light-light p-2 text-sm dark:border-l-dark-medium">
            <p>
              {dayjs(personAlert?.EnteredDate).format('MM/DD/YYYY H:mm:ss')}
            </p>
          </div>
        </dd>
        <dd className="flex gap-2  border-b dark:border-dark-medium">
          <h4 className="w-1/3 p-2 text-sm font-medium">Account ID</h4>
          <div className="w-4/6 border-l border-l-light-light p-2 text-sm dark:border-l-dark-medium">
            <p>{`${personAlert?.Person?.Account?.Name} (${personAlert?.Person?.Account?.CID}-${personAlert?.Person?.Account?.SID}-${personAlert?.Person?.Account?.PID})`}</p>
          </div>
        </dd>
        <dd className="flex gap-2  border-b dark:border-dark-medium">
          <h4 className="w-1/3 p-2 text-sm font-medium">Start/End date</h4>
          <div className="w-4/6 border-l border-l-light-light p-2 text-sm dark:border-l-dark-medium">
            <p>
              {dayjs(personAlert?.StartDate).format('MM/DD/YYYY H:mm:ss')} -{' '}
              {dayjs(personAlert?.EndDate).format('MM/DD/YYYY H:mm:ss')}
            </p>
          </div>
        </dd>
        <dd className="flex gap-2  border-b dark:border-dark-medium">
          <h4 className="w-1/3 p-2 text-sm font-medium">Reason for alert</h4>
          <div className="w-4/6 border-l border-l-light-light p-2 text-sm dark:border-l-dark-medium">
            <p>
              {personAlert?.Reasons?.length
                ? personAlert?.Reasons.map(
                    (elem: any) => elem?.Description,
                  ).join(', ')
                : ''}
            </p>
          </div>
        </dd>
      </dl>
    );
  }

  return (
    <>
      {isLoading && (
        <div className="bg-gray-900/8  absolute inset-0 z-50 grid place-items-center backdrop-blur-sm">
          <Spinner size="large" />
        </div>
      )}
      <div className="p-4" id="print-div">
        <div className="flex items-center justify-between space-y-1 rounded bg-primary px-3 py-2 text-primary-950 dark:bg-primary-600 dark:text-white">
          <h3 className="text-xl font-medium">Person alert</h3>
          <div className="text-right">
            <p className="text-base font-bold">
              {`${personAlert?.Person.FirstName} ${personAlert?.Person.LastName}`}
            </p>
          </div>
        </div>
      </div>
      <div className="relative min-h-0 grow overflow-auto scroll-smooth px-4 pb-4 print:overflow-visible">
        <div className="min-w-[400px] space-y-6">
          {renderBasicInformation()}
          {renderPersonInformation()}
          {renderAlertInformation()}
        </div>
      </div>
    </>
  );
}
