import { useInfiniteQuery, UseInfiniteQueryResult } from 'react-query';
import pDebounce from 'p-debounce';

import { useAuth } from 'src/hooks';

import { getColumnFromId } from 'src/utils/filterHelper';
import { pageLength } from 'src/utils/appConstants';
import { trimValues } from 'src/utils/common';
import { getPortalAlerts } from 'src/utils/urls';
import { sortOptions } from '../utils/constants';
import { portalAlertStore } from '../store/portalAlertStore';

import { FilterOptionsType, PortalAlertListType } from '../types/portalAlerts';

export async function fetchPortalAlerts({
  requestData,
  pageParam,
  token,
  tokenType,
}: TVariables): Promise<TPage> {
  requestData.append('start', `${pageParam}`);

  const response = await fetch(getPortalAlerts, {
    method: 'POST',
    headers: {
      Authorization: `${tokenType} ${token}`,
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: requestData,
  });
  return response.json();
}

const debouncedFetch = pDebounce(fetchPortalAlerts, 500);

export type TPage = PortalAlertListType;

export type TError = { message: string };

export type TVariables = {
  requestData: any;
  token: string;
  tokenType: string;
  pageParam: any;
};

function setRequestData(filterOptions: FilterOptionsType): FormData {
  const formData: any = new URLSearchParams();
  formData.append('draw', '0');
  formData.append('length', `${100}`);
  formData.append('timezoneOffset', `${new Date().getTimezoneOffset()}`);
  formData.append('columns[0][data]', 'Title');
  formData.append('columns[0][name]', '');
  formData.append('columns[0][searchable]', 'true');
  formData.append('columns[0][orderable]', 'true');
  formData.append('columns[0][search][regex]', 'false');
  formData.append(
    'columns[0][search][value]',
    filterOptions && filterOptions.Title ? trimValues(filterOptions.Title) : '',
  );

  formData.append('columns[1][data]', 'Message');
  formData.append('columns[1][name]', '');
  formData.append('columns[1][searchable]', 'true');
  formData.append('columns[1][orderable]', 'true');
  formData.append('columns[1][search][regex]', 'false');
  formData.append(
    'columns[1][search][value]',
    filterOptions && filterOptions.Message
      ? trimValues(filterOptions.Message)
      : '',
  );

  formData.append('columns[2][data]', 'EffectiveDate');
  formData.append('columns[2][name]', '');
  formData.append('columns[2][searchable]', 'true');
  formData.append('columns[2][orderable]', 'true');
  formData.append('columns[2][search][regex]', 'false');
  formData.append(
    'columns[2][search][value]',
    filterOptions && filterOptions.EffectiveDate
      ? trimValues(filterOptions.EffectiveDate)
      : '',
  );

  formData.append('columns[3][data]', 'ExpirationDate');
  formData.append('columns[3][name]', '');
  formData.append('columns[3][searchable]', 'true');
  formData.append('columns[3][orderable]', 'true');
  formData.append('columns[3][search][regex]', 'false');
  formData.append(
    'columns[3][search][value]',
    filterOptions && filterOptions.ExpirationDate
      ? trimValues(filterOptions.ExpirationDate)
      : '',
  );

  formData.append(
    'order[0][column]',
    getColumnFromId(filterOptions.SortType, sortOptions),
  );
  formData.append('order[0][dir]', filterOptions.SortOrder);

  formData.append(
    'search[value]',
    `${trimValues(filterOptions.KeywordSearch)}`,
  );
  formData.append('search[regex]', 'false');

  return formData;
}

function useGetPortalAlerts(
  filterOptions: FilterOptionsType,
): UseInfiniteQueryResult<TPage, unknown> {
  const { token, tokenType } = useAuth();
  return useInfiniteQuery(
    ['get_portal_alert_List', filterOptions],
    ({ pageParam = 0 }) => {
      const requestData = setRequestData(filterOptions);
      portalAlertStore?.getState().setPageParam(pageParam);
      return debouncedFetch({
        token,
        tokenType,
        requestData,
        pageParam,
      });
    },
    {
      getNextPageParam: (lastPage: TPage, allPages: Array<TPage>) => {
        if (lastPage !== null && lastPage?.data?.length === pageLength)
          return allPages?.length * pageLength;
      },
      getPreviousPageParam: (firstPage: TPage, allPages: Array<TPage>) => {
        if (firstPage !== null && firstPage?.data?.length === pageLength)
          return allPages?.length - 1 * pageLength;
      },

      staleTime: 60 * 1000,
      refetchOnWindowFocus: false,
    },
  );
}

export { useGetPortalAlerts };
