import { UseQueryResult, useQuery } from 'react-query';

import { useAuth } from 'src/hooks';

import { getColleagues } from 'src/utils/urls';

import { ColleagueType } from '../types/homeSectionType';

async function fetchColleagues({
  token,
  tokenType,
}: TVariables): Promise<ApiResult> {
  const response = await fetch(getColleagues, {
    method: 'GET',
    headers: {
      Authorization: `${tokenType} ${token}`,
    },
  });
  return response.json();
}

type TError = { message: string };
type TVariables = {
  token: string;
  tokenType: string;
};
type ApiResult = Array<ColleagueType>;

function useGetColleagues(): UseQueryResult<ApiResult, TError> {
  const { token, tokenType } = useAuth();
  return useQuery(
    ['get_colleagues'],
    async () => {
      const result = await fetchColleagues({ token, tokenType });
      return result;
    },
    {
      staleTime: Infinity,
      refetchOnWindowFocus: false,
    },
  );
}

export { useGetColleagues };
