import React, { Fragment, ReactElement, useCallback, useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { Menu, Transition } from '@headlessui/react';
import {
  ArrowDownTrayIcon,
  EllipsisHorizontalIcon,
  UserPlusIcon,
} from '@heroicons/react/20/solid';

import { Button } from 'src/ui/components';

import { classNames } from 'src/utils/className';
import { usePortalAlertStore } from 'src/features/AddEditPortalAlerts/store/portalAlertStore';

type UserActionType = {
  contentLength: number | undefined;
  alertId: number | undefined;
  downloadAsCsv: any;
};

function UserActions({
  contentLength,
  alertId,
  downloadAsCsv,
}: UserActionType): ReactElement {
  const navigate = useNavigate();
  const queryClient: any = useQueryClient();

  const resetStore = usePortalAlertStore(
    useCallback(state => state?.resetStore, []),
  );

  useEffect(() => {
    return () => {
      queryClient.invalidateQueries(['get_portal_alerts'], {
        refetchActive: false,
      });
    };
  }, [queryClient]);

  return (
    <>
      <div className="flex gap-4">
        <Button
          variant="primary"
          size="small"
          onClick={() => {
            resetStore();
            navigate('/PortalAlert/Create');
          }}
        >
          <UserPlusIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
          <span className="hidden sm:block">New alert</span>
        </Button>

        {contentLength ? (
          <Button
            variant="subtle"
            size="small"
            onClick={() => {
              downloadAsCsv();
            }}
          >
            <ArrowDownTrayIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
            <span className="hidden sm:block">Download as CSV</span>
          </Button>
        ) : null}
      </div>

      {/* {alertId && (
        <>
          <Menu as="div" className="relative ml-3 md:hidden">
            <Menu.Button as={Button} variant="secondary" size="small">
              <EllipsisHorizontalIcon className="h-5 w-5" aria-hidden="true" />
            </Menu.Button>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-0 z-10 -mr-1 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none dark:bg-neutral-700">
                <>
                  <Menu.Item>
                    {({ active }) => (
                      <a
                        className={classNames(
                          active ? 'bg-gray-100' : '',
                          'block px-4 py-2 text-sm text-gray-800 dark:text-white',
                        )}
                        onClick={() => navigate(`/PortalAlert/Edit/${alertId}`)}
                      >
                        Edit Alert
                      </a>
                    )}
                  </Menu.Item>
                </>
              </Menu.Items>
            </Transition>
          </Menu>
        </>
      )} */}
    </>
  );
}

export { UserActions };
