/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import $ from 'jquery';
import 'daterangepicker';
import moment from 'moment';
import clsx, { ClassValue } from 'clsx';

import { inputBaseClasses, inputSizeClasses } from '../TextField';
import {
  buttonSizeClasses,
  buttonVariantClasses,
  buttonBaseClasses,
  disabledClass,
} from 'src/ui/components/Button';

import {
  compareDates,
  getOffset,
  getTimeZone,
  getCurrentDateTimeWithOffset,
  getTimeZoneFutureRanges,
  getTimeZonePastRanges,
  isDateTimeInPast,
} from 'src/utils/datepickerHelper';

type Size = 'small' | 'medium';
type CalenderType = {
  placeholder?: string;
  value: any;
  setValues: (keyValue: any, value: string) => void;
  id: string;
  size?: Size;
  format: string;
  rangeType: string;
  className?: ClassValue;
  keyValue?: any;
  refferenceClass?: any;
  timezone?: any;
  openPos?: any;
};

function TimezoneIntegratedDateRangePicker({
  placeholder,
  value,
  id,
  setValues,
  keyValue,
  format,
  size = 'medium',
  rangeType,
  className,
  refferenceClass,
  timezone,
  openPos = 'right',
}: CalenderType) {
  const [open, setOpen] = useState<boolean | undefined>(undefined);

  const offset = timezone !== undefined ? getOffset(timezone) : getTimeZone();

  const refValue = useRef<any>();
  const eleRef = useRef<any>();
  const userClasses = clsx(className);

  //useEffect for clearing the default selected dates in daterangepicker.

  useEffect(() => {
    var drp = $(eleRef?.current).data('daterangepicker');

    let startDate = value?.length && value?.split('-')[0];
    let endDate = value?.length && value?.split('-')[1];

    if (open !== undefined) {
      if (startDate?.length > 0 && endDate?.length > 0) {
        drp?.setStartDate(startDate);
        drp?.setEndDate(endDate);
      } else {
        if (open !== true) {
          $(eleRef.current).val('');
          if (rangeType === 'future') {
            drp?.setStartDate(new Date(getCurrentDateTimeWithOffset(offset)));
            drp?.setEndDate(
              moment(getCurrentDateTimeWithOffset(offset))
                .endOf('day')
                .format('MM/DD/YYYY HH:mm:ss'),
            );
          } else {
            drp?.setStartDate(moment().startOf('day').format(format));
            drp?.setEndDate(moment().endOf('day').format(format));
          }
        }
      }
    }
  }, [value, open, format]);

  //datepicker initialization function
  useEffect(() => {
    $(eleRef.current).daterangepicker({
      timePicker: true,
      autoUpdateInput: false,

      locale: {
        format: 'MM/DD/YYYY HH:mm:ss',
        cancelLabel: 'Clear',
      },
      startDate:
        value && value !== null && typeof value === 'string'
          ? new Date(value?.split('-')[0])
          : rangeType === 'future'
          ? new Date(getCurrentDateTimeWithOffset(offset))
          : moment().startOf('day').format('MM/DD/YYYY HH:mm:ss'),
      endDate:
        value && value !== null && typeof value === 'string'
          ? new Date(value?.split('-')[1])
          : rangeType === 'future'
          ? moment(getCurrentDateTimeWithOffset(offset))
              .endOf('day')
              .format('MM/DD/YYYY HH:mm:ss')
          : moment().endOf('day').format('MM/DD/YYYY HH:mm:ss'),

      // ranges: getMomentFutureRanges(timezone),
      ranges:
        rangeType === 'past'
          ? getTimeZonePastRanges(offset)
          : getTimeZoneFutureRanges(offset),
      // minDate:
      //   rangeType === 'future'
      //     ? new Date(getCurrentDateTimeWithOffset(offset))
      //     : new Date('01/01/1900'),
      alwaysShowCalendars: true,
      timePicker24Hour: true,
      parentEl: refferenceClass?.current ?? '',

      // Styling
      buttonClasses: [
        `${buttonBaseClasses}`,
        `${buttonSizeClasses.small} ml-2`,
        `${disabledClass}`,
      ],
      applyButtonClasses: `${buttonVariantClasses.primary}`,
      cancelButtonClasses: `${buttonVariantClasses.secondary}`,
      opens: openPos,
      // timePickerSeconds: format?.endsWith("s") ? true : false,
    });

    $(eleRef.current).on('apply.daterangepicker', function (ev: any, picker) {
      $(eleRef?.current).val(
        picker.startDate.format(format) + ' - ' + picker.endDate.format(format),
      );
      let val = ev.currentTarget.value;
      if (format?.endsWith('s') && ev.currentTarget.value?.slice(-2) === '00') {
        let currentValue = ev.currentTarget.value;
        val = currentValue.slice(0, -2) + '59';
      }

      if (keyValue && keyValue !== '') {
        setValues && setValues(keyValue, val);
      }
      refValue.current = val;
    });

    $(eleRef.current).on('cancel.daterangepicker', function (ev, picker) {
      var drp = $(eleRef?.current).data('daterangepicker');
      drp?.setStartDate(moment().startOf('day').format(format));
      drp?.setEndDate(moment().endOf('day').format(format));
      $(eleRef.current).val('');

      if (keyValue && keyValue !== '') setValues && setValues(keyValue, '');

      refValue.current = '';
    });
    $(eleRef.current).on('show.daterangepicker', function (ev, picker) {
      setOpen(true);
    });
    $(eleRef.current).on('hide.daterangepicker', function (ev, picker) {
      setOpen(false);
    });

    //for closing datepicker on scrolling
    var drp: any = $(eleRef?.current).data('daterangepicker');

    const handleScroll = () => {
      if (refferenceClass && refferenceClass?.current && eleRef?.current) {
        drp?.hide();
      }
    };

    if (refferenceClass && refferenceClass?.current) {
      refferenceClass?.current.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (refferenceClass && refferenceClass?.current) {
        refferenceClass?.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, [value, timezone]);

  // //for retreving in to old date when clearing occurs
  function handleDateOnBlur() {
    let startDate;
    let endDate;
    //for scheduling purpose the default date on clearing is today for others it's last 30 days
    if (rangeType === 'future') {
      const currentDate = getCurrentDateTimeWithOffset(offset);
      startDate = currentDate;
      endDate = currentDate?.split(' ')[0] + ' 23:59:59';
    } else {
      (startDate = moment().subtract(29, 'days').startOf('day').format(format)),
        (endDate = moment().endOf('day').format(format));
    }

    if (
      refValue?.current === undefined ||
      refValue?.current === '' ||
      value === ''
    )
      return;

    //compare if the first date is in past with the end date'
    if (
      compareDates(
        refValue.current?.split('-')[0],
        refValue.current?.split('-')[1],
      ) === true
    ) {
      if (
        dateIsValid(refValue.current) === false &&
        refValue.current?.length > 0
      ) {
        setValues(keyValue, `${startDate} - ${endDate}`);
      } else if (
        dateIsValid(refValue.current) === undefined &&
        refValue?.current
      ) {
        let newDateVal = refValue.current;
        if (
          !newDateVal.includes(':') &&
          moment(newDateVal, 'MM/DD/YYYY', true)?.isValid()
        ) {
          newDateVal = newDateVal.concat(' 00:00:00 ');
        } else {
          newDateVal = moment().startOf('day').format(format);
        }
        setValues(keyValue, `${newDateVal} - ${newDateVal}`);
      }
      //if the date is in past and, date is valid replace it with today
      // else if (
      //   isDateTimeInPast(refValue.current, offset) === true &&
      //   dateIsValid(refValue.current) === true &&
      //   rangeType === 'future'
      // ) {
      //   setValues(keyValue, `${startDate} - ${endDate}`);
      // }
    } else {
      if (rangeType !== 'future') {
        startDate = moment().startOf('day').format(format);
        endDate = moment().endOf('day').format(format);
      }

      setValues(keyValue, `${startDate} - ${endDate}`);
    }
  }

  //for checking whether the date is valid or not (reinitializing date on clearing)
  function dateIsValid(dateValue: any) {
    let startDate = dateValue?.split('-')[0]?.trim();
    let endDate = dateValue?.split('-')[1]?.trim();
    let validStartDate = moment(startDate, format, true);
    let validEndDate = moment(endDate, format, true);

    let validStartDateForEdit = moment(startDate, 'MM/DD/YYYY HH:mm:ss', true);
    let validEndDateForEdit = moment(endDate, 'MM/DD/YYYY HH:mm:ss', true);

    if (startDate?.length && endDate?.length) {
      if (
        (validStartDate.isValid() && validEndDate?.isValid()) ||
        (validStartDateForEdit.isValid() && validEndDateForEdit.isValid())
      )
        return true;
      else return false;
    } else if (startDate?.length && endDate === undefined) {
      return undefined;
    }
  }
  //on manually changing the date on input field
  function handleDateOnChange(dateValue: any) {
    let newVal = dateValue;
    refValue.current = newVal;
    setValues(keyValue, newVal);
  }

  return (
    <input
      type="text"
      ref={eleRef}
      key={keyValue}
      placeholder={placeholder}
      value={value}
      className={[
        userClasses,
        inputBaseClasses,
        `${
          size === 'small' ? inputSizeClasses.small : inputSizeClasses.medium
        }`,
      ].join(' ')}
      onChange={(e: any) => {
        if (/^[0-9][0-9\/\-: ]*$/.test(e.target.value)) {
          handleDateOnChange(e.target.value);
        }
      }}
      onBlur={() => handleDateOnBlur()}
    />
  );
}

export { TimezoneIntegratedDateRangePicker };
