import { toast } from 'react-toastify';

const options = {
  autoClose: 50000,
  hideProgressBar: true,
  position: toast.POSITION.TOP_RIGHT,
};

type ToastType = {
  message: string;
  autoClose?: number;
  toastId?: number;
};

const dismissAll = () => toast.dismiss();

export const showSuccessToast = ({ message, autoClose }: ToastType): void => {
  dismissAll(); // to remove all active toast
  const delay = autoClose !== undefined ? autoClose : 5000;
  toast.success(message, { ...options, autoClose: delay });
};

export const showErrorToast = ({
  message,
  autoClose,
  toastId,
}: ToastType): void => {
  if (toastId) {
    const activeId = toast.isActive(toastId);
    if (activeId) return;
  }
  const delay = autoClose !== undefined ? autoClose : 5000;
  toast.error(message, { ...options, autoClose: delay, toastId });
};

export const showInfoToast = ({ message, autoClose }: ToastType): void => {
  const delay = autoClose !== undefined ? autoClose : 5000;
  toast.info(message, { ...options, autoClose: delay });
};

export const showWarningToast = ({ message, autoClose }: ToastType): void => {
  const delay = autoClose !== undefined ? autoClose : 5000;
  toast.warning(message, { ...options, autoClose: delay });
};
