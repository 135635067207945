import React, { ReactElement } from 'react';
import {
  BookOpenIcon,
  PaperClipIcon,
  ArrowDownTrayIcon,
} from '@heroicons/react/20/solid';

import { Button, Alert, Spinner } from 'src/ui/components';

import { useGetTutorials, useGetDownloadTutorial } from 'src/hooks';

import { base64ToArrayBuffer } from 'src/utils/convertToBinaryFormat';
import { showErrorToast } from 'src/utils/ToastNotification';

import { DownloadFileType, TutorialType } from 'src/types/tutorialsType';

function Tutorials() {
  const { data: tutorialFiles, isLoading: isTutorialsLoading } =
    useGetTutorials();

  const { mutate: downloadData, isLoading: isDownloading } =
    useGetDownloadTutorial();

  //on download
  function onDownload(documentName: string) {
    downloadData(documentName, {
      onSuccess: (data: DownloadFileType) => {
        if (data?.Status === 'Success') {
          let docFile = base64ToArrayBuffer(data?.FileContent);
          let blob = new Blob([docFile], {
            type: 'pdf',
          });

          const link = document.createElement('a');
          const url = window.URL.createObjectURL(blob);
          link.href = url;
          const fileName = data?.FileName;
          link.download = fileName;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);
        }
      },
      onError: (errorMsg: any) => {
        showErrorToast({ message: errorMsg?.message });
      },
    });
  }

  function renderTutorialList(item: TutorialType, index: number): ReactElement {
    return (
      <li
        key={index - item?.Id}
        className="flex items-center justify-between py-1 pl-2 pr-3 text-sm leading-6 shadow-sm md:pl-3 md:pr-4"
      >
        <div className="flex w-0 flex-1 items-center">
          <PaperClipIcon
            className="hidden h-5 w-5 flex-shrink-0 text-gray-400 md:inline-block"
            aria-hidden="true"
          />
          <div className="ml-2 flex min-w-0 flex-1 gap-2 md:ml-4">
            <span className="truncate font-medium">{item?.DocumentName}</span>
          </div>
        </div>
        <div className="ml-4 flex-shrink-0">
          <Button
            variant="subtle-primary"
            className="flex gap-2"
            onClick={() => {
              onDownload(item?.DocumentName);
            }}
          >
            <ArrowDownTrayIcon className="h-5 w-5 md:hidden" />
            <span className="hidden md:inline-block">Download</span>
          </Button>
        </div>
      </li>
    );
  }

  return (
    <div className="container mx-auto flex max-w-2xl flex-col justify-center gap-y-8 px-4 py-3 md:h-full md:px-6 md:py-5">
      <div className="space-y-1">
        {(isTutorialsLoading || isDownloading) && (
          <div className="bg-gray-900/8  absolute inset-0 z-50 grid   place-items-center  backdrop-blur-sm">
            <Spinner size="large" />
          </div>
        )}
        <div
          className="mb-2 hidden h-10 w-10 items-center justify-center rounded border-primary-600 bg-primary-100/50 p-2 dark:bg-primary-600/20 sm:flex"
          aria-hidden="true"
        >
          <BookOpenIcon className="h-5 w-5 text-primary-500 dark:text-primary-300" />
        </div>
        <h2 className="text-xl font-medium md:text-2xl">Tutorial</h2>
        <p className="text-xs text-gray-500 dark:text-neutral-400 md:text-sm">
          To help you get started, we&apos;ve included several tutorial
          documents below.
        </p>
      </div>
      <div className="space-y-2">
        <Alert variant="info">
          <span className="text-sm !leading-none md:text-base">
            You can always find these tutorials under settings page.
          </span>
        </Alert>
        <ul
          role="list"
          className="divide-y divide-gray-100 rounded-md border border-gray-300 bg-white dark:divide-neutral-700 dark:border-neutral-700 dark:bg-neutral-800"
        >
          {tutorialFiles?.map((item, index) => renderTutorialList(item, index))}
        </ul>
      </div>
    </div>
  );
}
export { Tutorials };
