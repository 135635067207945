import { UseMutationResult, useMutation, useQueryClient } from 'react-query';

import { useAuth } from 'src/hooks';

import { fieldConfiguration } from 'src/utils/urls';

import { ResourceList } from '../types/resourceList';
import { GetConfigArguments } from '../types/fieldConfigurationStoreTypes';

type TVariables = Pick<GetConfigArguments, 'token' | 'tokenType'> & {
  provider: ResourceList;
};

type TError = {
  message: string;
};

async function saveConfiguartion({
  token,
  tokenType,
  provider,
}: TVariables): Promise<any> {
  const result = await fetch(fieldConfiguration(provider.id), {
    method: 'PUT',
    headers: {
      Authorization: `${tokenType} ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(provider),
  });
  if (result.status === 201 || result.status === 204) {
    return 'Success';
  } else {
    const errorText = await result.text();
    throw { message: errorText };
  }
}

function useSaveFieldConfiguration(): UseMutationResult<
  void,
  TError,
  ResourceList,
  unknown
> {
  const { token, tokenType } = useAuth();
  const queryClient = useQueryClient();
  return useMutation(async (provider: ResourceList) => {
    const result = await saveConfiguartion({
      token,
      tokenType,
      provider,
    });
    queryClient.invalidateQueries('get-resource-list');
    return result;
  });
}
export { useSaveFieldConfiguration };
