import { useInfiniteQuery, UseInfiniteQueryResult } from 'react-query';
import pDebounce from 'p-debounce';

import { useAuth } from 'src/hooks';

import { getExtraCoverageList } from 'src/utils/urls';
import { pageLength } from 'src/utils/appConstants';

import { ExtraCoverageType } from '../types/extraCoverageRequestLists';
import { ExtraCoverageFilterType } from '../types/extraCoverageRequestFilterType';

export async function fetExtraCoverageRequestList({
  requestData,
  pageParam,
  token,
  tokenType,
}: TVariables): Promise<TPage> {
  requestData.append('start', `${pageParam}`);

  const response = await fetch(getExtraCoverageList, {
    method: 'POST',
    headers: {
      Authorization: `${tokenType} ${token}`,
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: requestData,
  });
  return response.json();
}

export type TPage = {
  data: Array<ExtraCoverageType>;
  draw: number;
  error: Error | null;
  isAutoRefreshEnabled: boolean;
  recordsFiltered: number;
  recordsTotal: number;
};

export type TError = { message: string };

export type TVariables = {
  requestData: any;
  token: string;
  tokenType: string;
  pageParam: number;
};

const debouncedFetch = pDebounce(fetExtraCoverageRequestList, 500);

function statusList(
  status: Array<{ lable: string; value: number }> | undefined,
) {
  let valueArray = status?.filter(item => item?.value !== 0);
  let result = valueArray?.map(item => item?.value);
  return result?.join(',');
}
function setRequestData(filterOptions: ExtraCoverageFilterType): FormData {
  const formData: any = new URLSearchParams();

  formData.append('draw', '1');
  formData.append('length', `${100}`);
  formData.append('timezoneOffset', `${new Date().getTimezoneOffset()}`);
  formData.append('columns[0][data]', 'Id');
  formData.append('columns[0][name]', '');
  formData.append('columns[0][searchable]', 'true');
  formData.append('columns[0][orderable]', 'true');
  formData.append('columns[0][search][regex]', 'false');
  formData.append('columns[0][search][ApplyExactMatch]', ''),
    formData.append(
      'columns[0][search][value]',
      filterOptions && filterOptions.id ? filterOptions.id : '',
    );

  formData.append('columns[1][data]', 'Approver');
  formData.append('columns[1][name]', '');
  formData.append('columns[1][searchable]', 'true');
  formData.append('columns[1][orderable]', 'true');
  formData.append('columns[1][search][regex]', 'false');
  formData.append('columns[1][search][ApplyExactMatch]', ''),
    formData.append(
      'columns[1][search][value]',
      filterOptions && filterOptions.approver ? filterOptions.approver : '',
    );

  formData.append('columns[2][data]', 'ExtraCoverageRequestStatus.Id');
  formData.append('columns[2][name]', '');
  formData.append('columns[2][searchable]', 'true');
  formData.append('columns[2][orderable]', 'true');
  formData.append('columns[2][search][regex]', 'false');
  formData.append('columns[2][search][ApplyExactMatch]', ''),
    formData.append(
      'columns[2][search][value]',
      filterOptions && filterOptions.extraCoverageRequestStatusId
        ? statusList(filterOptions?.extraCoverageRequestStatusId)
        : '',
    );

  formData.append('columns[3][data]', 'Accounts');
  formData.append('columns[3][name]', 'AccountList');
  formData.append('columns[3][searchable]', 'true');
  formData.append('columns[3][orderable]', 'true');
  formData.append('columns[3][search][regex]', 'false');
  formData.append('columns[3][search][ApplyExactMatch]', ''),
    formData.append(
      'columns[3][search][value]',
      filterOptions && filterOptions.accounts ? filterOptions.accounts : '',
    );

  formData.append('columns[4][data]', 'ExtraCoverageDate');
  formData.append('columns[4][name]', 'ExtraCoverageStart');
  formData.append('columns[4][searchable]', 'true');
  formData.append('columns[4][orderable]', 'true');
  formData.append('columns[4][search][regex]', 'false');
  formData.append('columns[4][search][ApplyExactMatch]', ''),
    formData.append(
      'columns[4][search][value]',
      filterOptions && filterOptions.extraCoverageDate
        ? filterOptions.extraCoverageDate
        : '',
    );

  formData.append('columns[5][data]', 'StartTime');
  formData.append('columns[5][name]', '');
  formData.append('columns[5][searchable]', 'true');
  formData.append('columns[5][orderable]', 'true');
  formData.append('columns[5][search][regex]', 'false');
  formData.append('columns[5][search][ApplyExactMatch]', ''),
    formData.append(
      'columns[5][search][value]',
      filterOptions && filterOptions.startTime ? filterOptions.startTime : '',
    );

  formData.append('columns[6][data]', 'EndTime');
  formData.append('columns[6][name]', '');
  formData.append('columns[6][searchable]', 'true');
  formData.append('columns[6][orderable]', 'true');
  formData.append('columns[6][search][regex]', 'false');
  formData.append('columns[6][search][ApplyExactMatch]', ''),
    formData.append(
      'columns[6][search][value]',
      filterOptions && filterOptions.endTime ? filterOptions.endTime : '',
    );

  formData.append('columns[7][data]', 'SubmittedDate');
  formData.append('columns[7][name]', '');
  formData.append('columns[7][searchable]', 'true');
  formData.append('columns[7][orderable]', 'true');
  formData.append('columns[7][search][regex]', 'false');
  formData.append('columns[7][search][ApplyExactMatch]', ''),
    formData.append(
      'columns[7][search][value]',
      filterOptions && filterOptions.submittedDate
        ? filterOptions.submittedDate
        : '',
    );

  formData.append('columns[8][data]', 'EmailSent');
  formData.append('columns[8][name]', '');
  formData.append('columns[8][searchable]', 'true');
  formData.append('columns[8][orderable]', 'true');
  formData.append('columns[8][search][regex]', 'false');
  formData.append('columns[8][search][ApplyExactMatch]', ''),
    formData.append(
      'columns[8][search][value]',
      filterOptions &&
        filterOptions.emailSent === true &&
        filterOptions?.notEmailSent == true
        ? ' '
        : filterOptions?.notEmailSent === true &&
          filterOptions?.emailSent === false
        ? false
        : filterOptions?.emailSent === true &&
          filterOptions?.notEmailSent === false
        ? true
        : '',
    );

  formData.append('columns[9][data]', 'AccountAlertPosted');
  formData.append('columns[9][name]', '');
  formData.append('columns[9][searchable]', 'true');
  formData.append('columns[9][orderable]', 'true');
  formData.append('columns[9][search][regex]', 'false');
  formData.append('columns[9][search][ApplyExactMatch]', ''),
    formData.append(
      'columns[9][search][value]',
      filterOptions &&
        filterOptions.accountAlertPosted === true &&
        filterOptions?.notAccountAlertPosted == true
        ? ' '
        : filterOptions?.accountAlertPosted === true &&
          filterOptions?.notAccountAlertPosted === false
        ? true
        : filterOptions?.notAccountAlertPosted === true &&
          filterOptions?.accountAlertPosted === false
        ? false
        : '',
    );

  formData.append('order[0][column]', filterOptions.sortType);
  formData.append(
    'order[0][dir]',
    filterOptions.sortOrder ? filterOptions.sortOrder : 'desc',
  );

  formData.append('search[value]', `${filterOptions.keywordSearch}`);
  formData.append('search[regex]', 'false');

  return formData;
}

function useGetExtraCoverageRequestLists(
  filterOptions: ExtraCoverageFilterType,
): UseInfiniteQueryResult<TPage, unknown> {
  const { token, tokenType } = useAuth();
  return useInfiniteQuery(
    ['get_extra_coverage_requests', filterOptions],
    ({ pageParam = 0 }) => {
      const requestData = setRequestData(filterOptions);
      return debouncedFetch({
        token,
        tokenType,
        requestData,
        pageParam,
      });
    },
    {
      getNextPageParam: (lastPage: TPage, allPages: Array<TPage>) => {
        if (lastPage !== null && lastPage?.data?.length === pageLength)
          return allPages?.length * pageLength;
      },
      getPreviousPageParam: (firstPage: TPage, allPages: Array<TPage>) => {
        if (firstPage !== null && firstPage?.data?.length === pageLength)
          return allPages?.length - 1 * pageLength;
      },

      staleTime: 60 * 1000,
      refetchOnWindowFocus: false,
    },
  );
}
export { useGetExtraCoverageRequestLists };
