import { UseQueryResult, useQuery } from 'react-query';

import { useAuth } from 'src/hooks';

import { getRelManagerInfo } from 'src/utils/urls';

import { AccountRepo } from '../types/apiStoreType';

async function fetchAccountManagersInfo({
  token,
  tokenType,
}: TVariables): Promise<Array<AccountRepo>> {
  const response = await fetch(getRelManagerInfo, {
    method: 'GET',
    headers: {
      Authorization: `${tokenType} ${token}`,
      'Content-Type': 'application/json',
    },
  });
  return response.json();
}

type TError = { message: string };
type TVariables = {
  token: string;
  tokenType: string;
};

function useGetRelationshipManagersInfo(): UseQueryResult<
  Array<AccountRepo>,
  TError
> {
  const { token, tokenType } = useAuth();
  return useQuery(
    ['get-rel-manager-accounts'],
    async () => {
      const result = fetchAccountManagersInfo({
        token,
        tokenType,
      });
      return result;
    },
    {
      staleTime: Infinity,
      refetchOnWindowFocus: false,
    },
  );
}

export { useGetRelationshipManagersInfo };
