import { useMutation, UseMutationResult, useQueryClient } from 'react-query';

import { postStickyNote } from 'src/utils/urls';
import { useAuth } from 'src/hooks';
import { useProgressStore } from 'src/hooks/useProgressStore';

export async function fetchStickyNote({
  payload,
  token,
  tokenType,
}: TArguments): Promise<TResult> {
  const response = await fetch(postStickyNote, {
    method: 'POST',
    headers: {
      Authorization: `${tokenType} ${token}`,
      'content-type': 'application/json',
    },
    body: JSON.stringify(payload),
  });
  return response.json();
}

export type ValidationError = { Message?: string };
export type TResult = string | ValidationError;
export type TArguments = {
  payload: {
    CallId: number | undefined;
    Note: string | undefined;
  };
  token: string;
  tokenType: string;
};

export type TVariables = {
  CallId: number | undefined;
  Note: string | undefined;
};
export type TError = {
  message: string;
};

function useAddStickyNote(): UseMutationResult<
  TResult,
  TError,
  TVariables,
  unknown
> {
  const { token, tokenType } = useAuth();
  const queryClient = useQueryClient();
  const { setLoading } = useProgressStore();
  return useMutation(
    async (params: TVariables) => {
      setLoading('isSaveStickyNoteLoading', true);
      const result = await fetchStickyNote({
        token: token,
        tokenType: tokenType,
        payload: { CallId: params?.CallId, Note: params?.Note },
      });
      queryClient.invalidateQueries('get_call_documents');
      return result;
    },
    {
      onSettled: () => {
        setLoading('isSaveStickyNoteLoading', false);
      },
    },
  );
}

export { useAddStickyNote };
