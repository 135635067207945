import { accountsInfo } from 'src/utils/urls';

import { TVariables } from '../../types/apiStoreType';
import { AccountInfo } from 'src/types/AccountInfo';

async function fetchAccounts({
  token,
  tokenType,
}: Partial<TVariables>): Promise<Array<AccountInfo>> {
  const response = await fetch(accountsInfo, {
    method: 'GET',
    headers: {
      Authorization: `${tokenType} ${token}`,
      'Content-Type': 'application/json',
    },
  });
  return response.json();
}

export { fetchAccounts };
