import { useQuery, UseQueryResult } from 'react-query';

import { useAuth } from 'src/hooks';

import { getPortalAlertList } from 'src/utils/urls';

import { PortalAlertsList } from '../types/homeSectionType';

async function fetchAlerts({
  token,
  tokenType,
}: TVariables): Promise<PortalAlertsList> {
  const response = await fetch(getPortalAlertList, {
    method: 'GET',
    headers: {
      Authorization: `${tokenType} ${token}`,
    },
  });
  return response.json();
}

type TError = { message: string };
type TVariables = {
  token: string;
  tokenType: string;
};

function useGetDashboardAlerts(): UseQueryResult<PortalAlertsList, TError> {
  const { token, tokenType } = useAuth();
  return useQuery(
    ['get_dashboard_alerts'],
    async () => {
      const result = await fetchAlerts({
        token,
        tokenType,
      });
      return result;
    },
    {
      staleTime: 60 * 1000,
      refetchOnWindowFocus: false,
    },
  );
}

export { useGetDashboardAlerts };
