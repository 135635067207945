import { useMutation, UseMutationResult } from 'react-query';

import { useAuth } from 'src/hooks';

import { downloadCallDocAsCsv } from 'src/utils/urls';
import { setCallDocDownloadCsvRequestData } from '../utils/apiHelper/callDocDownloadAsCsvData';
import { useCallDocStore } from '../store/callDocStore';

import { CallDocumentFilterType } from '../types/filterOptionsType';
import { CallType } from '../types/callDocumentList';

async function downloadCallDocCsv({
  requestData,
  token,
  tokenType,
}: {
  requestData: any;
  token: string;
  tokenType: string;
}): Promise<TResult> {
  const pageParam = useCallDocStore.getState().listPageParam;
  requestData.append('start', `${0}`);
  requestData.append('length', `${pageParam === 0 ? 100 : pageParam * 2}`);
  const response = await fetch(downloadCallDocAsCsv, {
    method: 'POST',
    headers: {
      Authorization: `${tokenType} ${token}`,
    },
    body: requestData,
  });
  if (response.status === 204) return 'Success';
  else return response.json();
}

export type TResult = string;
export type TError = { message: string };

export type TVariables = {
  filterOptions: CallDocumentFilterType;
  callTypeDescription: Array<CallType> | undefined;
};

function useCallDocDownloadAsCsv(): UseMutationResult<
  TResult,
  TError,
  TVariables,
  unknown
> {
  const { token, tokenType } = useAuth();
  return useMutation(async ({ filterOptions, callTypeDescription }) => {
    const requestData = setCallDocDownloadCsvRequestData(
      filterOptions,
      callTypeDescription,
    );

    const result = await downloadCallDocCsv({
      token,
      tokenType,
      requestData,
    });
    if (result === 'Success') return result;
    else throw result;
  });
}

export { useCallDocDownloadAsCsv };
