import React, { ReactElement, SyntheticEvent } from 'react';
import {
  ArrowSmallDownIcon,
  ArrowSmallUpIcon,
  XMarkIcon,
} from '@heroicons/react/20/solid';
import { Disclosure } from '@headlessui/react';

import { Button, Select, TextField, DateRangePicker } from 'src/ui/components';

import { sortOptions } from '../utils/activityFilterConstants';

import { ActivityLogFilterType } from '../types/filterOptionsType';

type ActivityLogFilterPageType = {
  filterOptions?: ActivityLogFilterType;
  setValues: (type: keyof ActivityLogFilterType, value: string) => void;
  resetFilters: () => void;
};

function ActivityLogFilter({
  filterOptions,
  setValues,
  resetFilters,
}: ActivityLogFilterPageType): ReactElement {
  function renderSort(): ReactElement {
    return (
      <div className="flex items-end gap-2">
        <Select
          inputSize="small"
          label="Sort by"
          id="filter-status"
          className="w-full sm:w-auto"
          options={sortOptions}
          value={filterOptions?.sortType}
          onChange={(e: SyntheticEvent<HTMLSelectElement>) =>
            setValues('sortType', e.currentTarget.value)
          }
        />
        <Button
          variant={filterOptions?.sortOrder === 'asc' ? 'primary' : 'secondary'}
          size="small"
          onClick={() => setValues('sortOrder', 'asc')}
        >
          <ArrowSmallUpIcon className="h-5 w-5" aria-hidden="true" />
          <span className="sr-only">Sort in ascending order</span>
        </Button>
        <Button
          variant={
            filterOptions?.sortOrder === 'desc' ? 'primary' : 'secondary'
          }
          size="small"
          onClick={() => setValues('sortOrder', 'desc')}
        >
          <ArrowSmallDownIcon className="h-5 w-5" aria-hidden="true" />
          <span className="sr-only">Sort in descending order</span>
        </Button>
      </div>
    );
  }

  function renderDatePicker({
    id,
    key,
    value,
    title,
  }: {
    id: string;
    title: string;
    key?: string;
    value?: string;
  }): ReactElement {
    return (
      <DateRangePicker
        rangeType="past"
        format="MM/DD/YYYY HH:mm:ss"
        size="small"
        placeholder={title}
        id={id}
        value={value?.length ? value : []}
        setValues={setValues}
        keyValue={key}
      />
    );
  }

  function renderTextField({
    label,
    id,
    value,
  }: {
    label: string;
    id: keyof ActivityLogFilterType;
    value: any;
  }) {
    return (
      <TextField
        inputSize="small"
        className="w-full sm:w-auto"
        type="search"
        label={label}
        placeholder={label}
        id={id}
        value={value}
        onChange={e => setValues(id, e.target.value)}
      />
    );
  }

  return (
    <Disclosure.Panel
      as="div"
      className="fixed bottom-0 left-0 top-0 z-10 flex w-full flex-col bg-white shadow dark:bg-neutral-800 sm:static sm:bottom-auto sm:left-auto sm:top-auto sm:block sm:w-auto"
    >
      {({ close }) => (
        <>
          <div className="flex items-center border-b border-b-light-light py-1 pe-1 ps-2 dark:border-b-dark-medium sm:hidden">
            <h3 className="grow text-lg font-medium">Filter</h3>
            <Button
              variant="subtle"
              onClick={() => {
                close();
                resetFilters();
              }}
            >
              <XMarkIcon className="h-5 w-5" />
            </Button>
          </div>
          <div className="min-h-0 grow overflow-y-auto p-2 pb-3 sm:overflow-y-visible sm:px-3 lg:px-4">
            {renderSort()}
            <div
              className="my-4 border-b border-gray-200 dark:border-neutral-700/50"
              aria-hidden="true"
            ></div>
            <div className="flex flex-wrap gap-3">
              <div className="flex w-full flex-col sm:w-auto">
                <label
                  htmlFor=""
                  className="block text-xs font-medium leading-6 text-gray-900 dark:text-white"
                >
                  Activity date
                </label>
                {renderDatePicker({
                  id: 'createdOn',
                  title: 'Activity date',
                  key: 'createdOn',
                  value: filterOptions?.createdOn,
                })}
              </div>
              {renderTextField({
                label: 'CID',
                id: 'cid',
                value: filterOptions?.cid,
              })}
              {renderTextField({
                label: 'PID',
                id: 'pid',
                value: filterOptions?.pid,
              })}
              {renderTextField({
                label: 'File name',
                id: 'reportFileName',
                value: filterOptions?.reportFileName,
              })}
              {renderTextField({
                label: 'Activity',
                id: 'activity',
                value: filterOptions?.activity,
              })}
              {renderTextField({
                label: 'User',
                id: 'createdBy',
                value: filterOptions?.createdBy,
              })}
            </div>
          </div>

          <div className="flex gap-1 border-t border-t-light-light p-2 dark:border-b-dark-medium sm:hidden">
            <Button
              className="grow"
              onClick={() => {
                close();
                resetFilters();
              }}
            >
              Cancel
            </Button>
            <Button
              className="grow"
              variant="primary"
              onClick={() => {
                close();
              }}
            >
              Apply
            </Button>
          </div>
        </>
      )}
    </Disclosure.Panel>
  );
}

export { ActivityLogFilter };
