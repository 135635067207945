import { useMutation, UseMutationResult } from 'react-query';

import { useAuth } from 'src/hooks';
import { activityStore } from '../store/activityStore';

import { activityLogDownloadAsCsv } from 'src/utils/urls';
import { trimValues } from 'src/utils/common';

import { ActivityLogFilterType } from '../types/filterOptionsType';
import { getColumnFromId } from 'src/utils/filterHelper';
import { filterSortOptions } from '../utils/activityFilterConstants';

async function postDownloadCsv({
  token,
  tokenType,
  requestData,
}: TArguments): Promise<TResult> {
  const pageParam = activityStore.getState().pageParam;
  requestData.append('start', `${0}`);
  requestData.append('length', `${pageParam === 0 ? 100 : pageParam * 2}`);
  const response = await fetch(activityLogDownloadAsCsv, {
    method: 'POST',
    headers: {
      Authorization: `${tokenType} ${token}`,
    },
    body: requestData,
  });

  if (response.status === 204) return 'Success';
  else return response.json();
}

export type ValidationError = { Message?: string };
export type TResult = string | ValidationError;
export type TError = { message?: string };

export type TArguments = {
  token: string;
  tokenType: string;
  requestData: any;
};

export type TVariables = {
  filterOptions: ActivityLogFilterType;
  pageParam: number;
};

function setDownloadCsvRequestData(
  filterOptions: ActivityLogFilterType,
): FormData {
  const formData: any = new URLSearchParams();
  formData.append('draw', '1');
  formData.append('columns[0][data]', 'CID');
  formData.append('columns[0][name]', '');
  formData.append('columns[0][searchable]', 'true');
  formData.append('columns[0][orderable]', 'true');
  formData.append('columns[0][search][regex]', 'false');
  formData.append('columns[0][search][ApplyExactMatch]', ''),
    formData.append(
      'columns[0][search][value]',
      filterOptions && filterOptions.cid ? trimValues(filterOptions.cid) : '',
    );

  formData.append('columns[1][data]', 'PID');
  formData.append('columns[1][name]', '');
  formData.append('columns[1][searchable]', 'true');
  formData.append('columns[1][orderable]', 'true');
  formData.append('columns[1][search][regex]', 'false');
  formData.append('columns[1][search][ApplyExactMatch]', ''),
    formData.append(
      'columns[1][search][value]',
      filterOptions && filterOptions.pid ? trimValues(filterOptions.pid) : '',
    );

  formData.append('columns[2][data]', 'Activity');
  formData.append('columns[2][name]', '');
  formData.append('columns[2][searchable]', 'true');
  formData.append('columns[2][orderable]', 'true');
  formData.append('columns[2][search][regex]', 'false');
  formData.append('columns[2][search][ApplyExactMatch]', ''),
    formData.append(
      'columns[2][search][value]',
      filterOptions && filterOptions.activity
        ? trimValues(filterOptions.activity)
        : '',
    );

  formData.append('columns[3][data]', 'CreatedOn');
  formData.append('columns[3][name]', '');
  formData.append('columns[3][searchable]', 'true');
  formData.append('columns[3][orderable]', 'true');
  formData.append('columns[3][search][regex]', 'false');
  formData.append('columns[3][search][ApplyExactMatch]', ''),
    formData.append(
      'columns[3][search][value]',
      filterOptions && filterOptions.createdOn
        ? trimValues(filterOptions.createdOn)
        : '',
    );

  formData.append('columns[4][data]', 'CreatedBy');
  formData.append('columns[4][name]', '');
  formData.append('columns[4][searchable]', 'true');
  formData.append('columns[4][orderable]', 'true');
  formData.append('columns[4][search][regex]', 'false');
  formData.append('columns[4][search][ApplyExactMatch]', ''),
    formData.append(
      'columns[4][search][value]',
      filterOptions && filterOptions.createdBy
        ? trimValues(filterOptions.createdBy)
        : '',
    );

  formData.append('columns[5][data]', 'ReportFileName');
  formData.append('columns[5][name]', '');
  formData.append('columns[5][searchable]', 'true');
  formData.append('columns[5][orderable]', 'true');
  formData.append('columns[5][search][regex]', 'false');
  formData.append('columns[5][search][ApplyExactMatch]', ''),
    formData.append(
      'columns[5][search][value]',
      filterOptions && filterOptions.reportFileName
        ? trimValues(filterOptions.reportFileName)
        : '',
    );

  formData.append(
    'order[0][column]',
    getColumnFromId(filterOptions.sortType, filterSortOptions),
  );
  formData.append(
    'order[0][dir]',
    filterOptions.sortOrder ? filterOptions.sortOrder : 'desc',
  );

  formData.append('search[regex]', 'false');

  return formData;
}

function useDownloadCsv(): UseMutationResult<
  TResult,
  TError,
  TVariables,
  unknown
> {
  const { token, tokenType } = useAuth();

  return useMutation(async (params: TVariables) => {
    const requestData = setDownloadCsvRequestData(params.filterOptions);

    const result = await postDownloadCsv({
      token,
      tokenType,
      requestData,
    });
    if (result === 'Success') return result;
    else throw result;
  });
}
export { useDownloadCsv };
