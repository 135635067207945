//API FOR DOWNLOADING CALL DOCS ABOVE 15
import { useMutation, UseMutationResult } from 'react-query';

import { useAuth } from 'src/hooks';

import { downloadCallDocs } from 'src/utils/urls';
import { setCallDocDownloadRequestData } from '../utils/apiHelper/callDocDownloadData';
import { useCallDocStore } from '../store/callDocStore';

import { CallDocumentFilterType } from '../types/filterOptionsType';
import { CallType } from '../types/callDocumentList';

async function downloadCallDoc({
  requestData,
  token,
  tokenType,
}: {
  requestData: any;
  token: string;
  tokenType: string;
}): Promise<TResult> {
  const pageParamList = useCallDocStore.getState().listPageParam;
  requestData.append('Parameters[start]', `${pageParamList}`);
  const response = await fetch(downloadCallDocs, {
    method: 'POST',
    headers: {
      Authorization: `${tokenType} ${token}`,
    },
    body: requestData,
  });
  if (response.status === 204) return 'Success';
  else return response.json();
}

export type TResult = string;
export type TError = { message: string };

export type TVariables = {
  filterOptions: CallDocumentFilterType;
  callTypeDescription: Array<CallType> | undefined;
  includeStickyNotes: boolean;
  exportFormat: String;
  selectedDocIds: Array<number>;
};

function useCallDocDownload(): UseMutationResult<
  TResult,
  TError,
  TVariables,
  unknown
> {
  const { token, tokenType } = useAuth();
  return useMutation(
    async ({
      filterOptions,
      callTypeDescription,
      includeStickyNotes,
      exportFormat,
      selectedDocIds,
    }) => {
      const requestData = setCallDocDownloadRequestData(
        filterOptions,
        callTypeDescription,
        includeStickyNotes,
        exportFormat,
        selectedDocIds,
      );

      const result = await downloadCallDoc({
        token,
        tokenType,
        requestData,
      });
      if (result === 'Success') return result;
      else throw result;
    },
  );
}

export { useCallDocDownload };
