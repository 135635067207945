export const widgets: Array<string> = [
  'Clinical call resolution',
  'Recent document activity',
  'My collegues',
];

export const dashboardNav = [
  {
    id: '#dash-alerts',
    nav: 'Alerts',
  },
  {
    id: '#dash-info',
    nav: 'Info',
  },
  {
    id: '#dash-widgets',
    nav: 'Widget',
  },
  {
    id: '#dash-assistance',
    nav: 'Assistance',
  },
];
