import { OptionType } from '../types/personAlerts';

const sortOptions: Array<{
  value: number;
  label: string;
}> = [
  { value: 5, label: 'CID' },
  { value: 6, label: 'PID' },
  { value: 12, label: 'Alert number' },
  { value: 7, label: 'First name' },
  { value: 8, label: 'Last name' },
  { value: 9, label: 'Alternate name' },
  { value: 10, label: 'Birth date' },
  { value: 4, label: 'Entered date' },
  { value: 2, label: 'Start date' },
  { value: 3, label: 'Expiration date' },
  { value: 11, label: 'Situation description' },
  { value: 13, label: 'Updated by' },
  { value: 14, label: 'Updated date' },
];

const options: Array<OptionType> = [
  { label: 'All', value: '' },
  { label: 'Active', value: 'Active' },
  { label: 'Future', value: 'Future' },
  { label: 'Expired', value: 'Expired' },
];

const downloadSuccessMsg =
  'You will receive an email with a link once the file is ready to be downloaded.';

const calllimitWarningMsg =
  'Please note that this info is not visible to the customers';

const activeStyles =
  "after:pointer-events-none after:absolute after:inset-0 after:rounded-md after:border-2 after:border-primary-500 after:content-['']";

export {
  sortOptions,
  options,
  downloadSuccessMsg,
  calllimitWarningMsg,
  activeStyles,
};
