import { create } from 'zustand';

type UserPermissionStoreType = {
  isLoading: boolean;
  setIsLoading: (val: boolean) => void;
};

export const userPermissionStore = create<UserPermissionStoreType>(set => ({
  isLoading: false,

  setIsLoading: (val: boolean) => {
    set(() => ({
      isLoading: val,
    }));
  },
}));
