import { useQuery, UseQueryResult } from 'react-query';

import { useAuth } from 'src/hooks';

import { getPostalCode } from 'src/utils/urls';

import { AddressType } from '../features/AddEditPersonAlert/types/findPersonInformation';

async function fetchAddress({
  token,
  tokenType,
  code,
}: TVariables): Promise<TResult> {
  const response = await fetch(getPostalCode(code), {
    method: 'GET',
    headers: {
      Authorization: `${tokenType} ${token}`,
    },
  });
  return response.json();
}

type TVariables = {
  token: string;
  tokenType: string;
  code: string;
};

export type TResult = Array<AddressType>;

export type TError = { message: string };

function useGetAddress(code: string): UseQueryResult<TResult, TError> {
  const { token, tokenType } = useAuth();

  return useQuery(
    ['get_address', code],
    async () => {
      if (code && code?.length >= 3) {
        const result = fetchAddress({ token, tokenType, code });
        return result;
      }
    },
    {
      staleTime: 60 * 1000,
      refetchOnWindowFocus: false,
    },
  );
}

export { useGetAddress };
