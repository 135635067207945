import React from 'react';
import { useLocation } from 'react-router-dom';

import {
  AUTH_TOKEN,
  SILENT_SIGN_IN_LOADING,
} from 'src/utils/authenticationHelper/authConstants';
import { AuthConsumer } from 'src/utils/authenticationHelper/authProvider';
import { AuthServiceSingleton } from 'src/utils/authenticationHelper/authService';

export const SilentRenew = () => {
  const location = useLocation();
  const { hash } = location;
  AuthServiceSingleton.getInstance().signinSilentCallback();
  const params = new URLSearchParams(hash.replaceAll('#', '?'));
  const user = JSON.parse(localStorage.getItem(AUTH_TOKEN) || '{}');
  const token = params.get('access_token');
  const parsedObj = AuthServiceSingleton.getInstance().parseJwt(token || '');
  const newUser = {
    ...user,
    access_token: params.get('access_token'),
    expires_at: parsedObj.exp,
    id_token: params.get('id_token'),
    session_state: params.get('session_state'),
    token_type: params.get('token_type'),
  };

  localStorage.setItem(AUTH_TOKEN, JSON.stringify(newUser));
  localStorage.removeItem(SILENT_SIGN_IN_LOADING);

  return (
    <AuthConsumer>
      {({ signinSilentCallback }) => {
        return <span>loading</span>;
      }}
    </AuthConsumer>
  );
};
