import { useQuery, UseQueryResult } from 'react-query';

import { useAuth } from 'src/hooks';
import { getActivityTrackerPreDet } from 'src/utils/urls';

import { ActivityTrackerPreviewType } from '../types/activityTrackerPreviewType';

async function fetchActivityTrackerPreview({
  token,
  tokenType,
  activityTrackerId,
}: TVariables): Promise<TResult> {
  const response = await fetch(getActivityTrackerPreDet(activityTrackerId), {
    method: 'GET',
    headers: {
      Authorization: `${tokenType} ${token}`,
    },
  });
  return response.json();
}

export type TResult = ActivityTrackerPreviewType;

export type TVariables = {
  token: string;
  tokenType: string;
  activityTrackerId: number;
};

export type TError = { message: string };

function useGetActivityTrackerPreview(
  activityTrackerId: number | undefined,
): UseQueryResult<TResult, TError> {
  const { token, tokenType } = useAuth();
  return useQuery(
    'get_activity_tracker_preview',
    async () => {
      if (activityTrackerId) {
        const result = await fetchActivityTrackerPreview({
          token,
          tokenType,
          activityTrackerId,
        });
        return result;
      }
    },
    {
      staleTime: 60 * 1000,
      refetchOnWindowFocus: false,
    },
  );
}

export { useGetActivityTrackerPreview };
