import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import { updateUserDetails } from 'src/utils/urls';

import { createNewUser, NewUserReturnType } from '../types/createNewUser';
import { useAuth } from 'src/hooks';

async function updateUser({
  token,
  tokenType,
  data,
  id,
}: TArguments): Promise<TResult> {
  const response = await fetch(updateUserDetails(id), {
    method: 'PUT',
    headers: {
      Authorization: `${tokenType} ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  return response;
}

type TResult = NewUserReturnType | any;

export type TError = any;

export type TVariables = createNewUser;

export type TArguments = {
  token: string;
  tokenType: string;
  data: createNewUser;
  id: string;
};

function useUpdateUser(): UseMutationResult<
  TResult,
  TError,
  TVariables,
  unknown
> {
  const queryClient: any = useQueryClient();
  const { token, tokenType } = useAuth();
  return useMutation(async (params: TVariables) => {
    const result = await updateUser({
      token,
      tokenType,
      data: params,
      id: params.Id,
    });
    if (result.status === 400) {
      const err = await result.json();
      throw err;
    }
    queryClient.invalidateQueries(['get_users_list']);
    return result.json();
  });
}
export { useUpdateUser };
