import { useMutation, UseMutationResult } from 'react-query';

import { postPortalAlert, updatePortalAlerts } from 'src/utils/urls';

import { useAuth } from 'src/hooks';

import { PortalAlerts } from 'src/features/PortalAlerts/types/portalAlerts';

async function savePortalAlert({
  token,
  tokenType,
  params,
}: TArguments): Promise<TResult> {
  const response = await fetch(
    params.Id !== 0 ? updatePortalAlerts(params.Id) : postPortalAlert,
    {
      method: params.Id !== 0 ? 'PUT' : 'POST',
      headers: {
        Authorization: `${tokenType} ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(params),
    },
  );
  return response;
}

type TResult = PortalAlerts | any;

export type TError = { ErrorMessage: string };

export type TVariables = PortalAlerts;

export type TArguments = {
  token: string;
  tokenType: string;
  params: TVariables;
};

function useSavePortalAlert(): UseMutationResult<
  TResult,
  TError,
  TVariables,
  unknown
> {
  const { token, tokenType } = useAuth();
  return useMutation(async (params: TVariables) => {
    const result = await savePortalAlert({ token, tokenType, params });
    if (result.status === 400) {
      const message = await result.json();
      throw {
        ErrorMessage: message,
      };
    }
    return result.json();
  });
}
export { useSavePortalAlert };
