import React, { ReactElement, SyntheticEvent } from 'react';

import { DateRangePicker, TextField, Button } from 'src/ui/components';

import { FilterOptionsType, SortandFilterType } from '../types/portalAlerts';

function PortalAlertFilter({
  filterOptions,
  setValues,
}: SortandFilterType): ReactElement {
  function renderTextField({
    label,
    id,
    placeholder,
    value,
    key,
  }: {
    label: string;
    id: string;
    placeholder: string;
    value: string | number;
    key: keyof FilterOptionsType;
  }) {
    return (
      <TextField
        inputSize="small"
        type="search"
        className="w-full sm:w-auto"
        label={label}
        id={id}
        placeholder={placeholder}
        value={value}
        onChange={(e: SyntheticEvent<HTMLInputElement>) => {
          setValues(key, e.currentTarget.value);
        }}
      />
    );
  }

  function renderDatePicker({
    placeholder,
    key,
    value,
  }: {
    placeholder: string;
    key?: string;
    value?: string;
  }): ReactElement {
    return (
      <DateRangePicker
        rangeType="future"
        format="MM/DD/YYYY HH:mm:ss"
        size="small"
        placeholder={placeholder}
        value={value?.length ? value : []}
        setValues={setValues}
        keyValue={key}
      />
    );
  }

  return (
    <>
      <div className="flex flex-wrap gap-3">
        {renderTextField({
          label: 'Title',
          id: 'title',
          placeholder: 'Title',
          value: filterOptions.Title,
          key: 'Title',
        })}

        {renderTextField({
          label: 'Message',
          id: 'message',
          placeholder: 'Message',
          value: filterOptions.Message,
          key: 'Message',
        })}

        <div className="flex w-full flex-col sm:w-auto">
          <label
            htmlFor=""
            className="block text-xs font-medium leading-6 text-gray-900 dark:text-white"
          >
            Effective date
          </label>
          {renderDatePicker({
            placeholder: 'Effective date',
            key: 'EffectiveDate',
            value: filterOptions.EffectiveDate,
          })}
        </div>

        <div className="flex w-full flex-col sm:w-auto">
          <label
            htmlFor=""
            className="block text-xs font-medium leading-6 text-gray-900 dark:text-white"
          >
            Expiration date
          </label>
          {renderDatePicker({
            placeholder: 'Expiration date',
            key: 'ExpirationDate',
            value: filterOptions.ExpirationDate,
          })}
        </div>
      </div>
    </>
  );
}

export { PortalAlertFilter };
