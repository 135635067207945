import React, { ReactElement, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  CheckCircleIcon,
  EyeIcon,
  EyeSlashIcon,
  XMarkIcon,
} from '@heroicons/react/20/solid';

import {
  Alert,
  Button,
  Spinner,
  buttonBaseClasses,
  buttonSizeClasses,
  buttonVariantClasses,
  inputBaseClasses,
  inputSizeClasses,
  CheckIcon as AnimatedCheckIcon,
} from 'src/ui/components';

import { useResetPassword } from 'src/features/UserOnBoarding/api';
import { useTitle } from 'src/hooks';

import {
  passwordChangedSuccessMsg,
  passwordEmptyMsg,
  passwordMatchMsg,
} from 'src/utils/appConstants';
import {
  validatePassword,
  specialCharArray,
  isValidPassword,
} from 'src/utils/common';

type PasswordBlockType = {
  label: string;
  id: string;
  type: string;
  value: string;
  onChange: (e: any) => void;
  onClick: (e: any) => void;
  autoFocus?: boolean;
  onBlur?: (e: any) => void;
};

export default function ResetPassword(): ReactElement {
  useTitle('Reset Password');

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const { pathname } = location;
  const parts = pathname.split('/');

  const id = parts[parts.length - 1];
  const code = queryParams.get('code');

  const [password, setPassword] = useState<{
    type: string;
    value: string;
  }>({
    type: 'password',
    value: '',
  });

  const [confirmPswd, setConfirmPswd] = useState<{
    type: string;
    value: string;
  }>({
    type: 'password',
    value: '',
  });

  const [passwordErrorMsg, setPasswordErrorMsg] = useState<string>('');
  const [passwordSuccessMsg, setPasswordSuccessMsg] = useState<string>('');

  const { mutate: resetPassword, isLoading } = useResetPassword();

  function onSaveHandler() {
    if (password.value.length && confirmPswd.value.length) {
      if (password.value === confirmPswd.value) {
        resetPassword(
          {
            id,
            isNewUserOnBoarding: true,
            code: code ? encodeURI(code) : '',
            password: password.value,
          },
          {
            onSuccess: () => {
              setPasswordSuccessMsg(passwordChangedSuccessMsg);
            },
            onError: err => {
              setPasswordErrorMsg(err.message);
            },
          },
        );
      } else {
        setPasswordErrorMsg(passwordMatchMsg);
      }
    } else {
      setPasswordErrorMsg(passwordEmptyMsg);
    }
  }

  function renderPasswordBlock({
    label,
    id,
    type,
    value,
    onChange,
    onClick,
    autoFocus,
    onBlur,
  }: PasswordBlockType) {
    return (
      <div>
        <label
          htmlFor="password"
          className="text-base font-medium leading-none"
        >
          {label}
        </label>
        <div className="relative flex animate-shake flex-wrap items-stretch">
          <input
            id={id}
            autoFocus={autoFocus}
            className={`${[inputBaseClasses, inputSizeClasses.large].join(
              ' ',
            )} !w-max min-w-0 flex-auto !rounded-r-none outline-none`}
            type={type}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            onCopy={e => e.preventDefault()}
            onPaste={e => e.preventDefault()}
            onDrag={e => e.preventDefault()}
            onDrop={e => e.preventDefault()}
          />

          <button
            className={`${[
              buttonBaseClasses,
              buttonSizeClasses.large,
              buttonVariantClasses.subtle,
            ].join(
              ' ',
            )} !rounded-l-none ring-1 ring-inset ring-gray-300 dark:ring-neutral-700`}
            title={type === 'password' ? 'Show password' : 'Hide password'}
            onClick={onClick}
          >
            <span className="sr-only">
              {type === 'password' ? 'Show password' : 'Hide password'}
            </span>
            {type === 'text' ? (
              <EyeIcon className="-ml-0.5 h-5 w-4" aria-hidden="true" />
            ) : (
              <EyeSlashIcon className="-ml-0.5 h-5 w-4" aria-hidden="true" />
            )}
          </button>
        </div>
      </div>
    );
  }

  function getIcon(type: string, message: string): ReactElement {
    return (
      <div className="flex items-center gap-1">
        <span>
          {validatePassword(type, password.value) ? (
            <AnimatedCheckIcon className="h-5 w-5 text-green-600" />
          ) : (
            <XMarkIcon className="h-5 w-5 text-red-600" />
          )}
        </span>
        <span className="grow text-xs font-medium dark:font-normal md:text-sm">
          {message}
        </span>
      </div>
    );
  }

  function renderPasswordValidations() {
    return (
      <div className="columns-2 md:space-y-1">
        {getIcon('lowercase', 'One lowercase character')}
        {getIcon('uppercase', 'One uppercase character')}
        {getIcon('reqLength', '8 character minimum')}
        {getIcon('number', 'One number')}
        <div className="space-y-1">
          {getIcon('specialChar', 'One special character')}
          <span className="ml-6 inline-block text-xs leading-4">
            Special characters can include <br />
            {specialCharArray}
          </span>
        </div>
      </div>
    );
  }

  return (
    <main className="grid flex-1 place-items-center overflow-y-auto overscroll-contain">
      <div className="container mx-auto max-w-lg gap-y-8 rounded-lg bg-white shadow dark:bg-dark-dark">
        <h2 className="px-4 pt-4 text-xl font-medium md:px-6 md:text-2xl">
          Set password
        </h2>

        <div className="p-4 md:p-6">
          {passwordErrorMsg !== '' && (
            <Alert variant="danger" className="mb-4">
              {passwordErrorMsg}
            </Alert>
          )}

          {passwordSuccessMsg !== '' && (
            <div className="px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
              <div className="sm:flex sm:items-center">
                <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-green-100 dark:bg-green-800/50 sm:mx-0 sm:h-10 sm:w-10">
                  <CheckCircleIcon
                    className="h-6 w-6 text-green-600 dark:text-green-400"
                    aria-hidden="true"
                  />
                </div>
                <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                  <h3 className="text-base leading-6 text-gray-900 dark:text-neutral-200">
                    Your password has been successfully updated.
                  </h3>
                  <Link
                    className="font-medium leading-none text-sky-700 hover:underline dark:text-sky-400"
                    to={'/Home'}
                  >
                    Click here
                  </Link>
                  <span> to sign in</span>
                </div>
              </div>
            </div>
          )}

          {passwordSuccessMsg === '' && code !== null && (
            <div className="space-y-4">
              {renderPasswordBlock({
                label: 'Password',
                id: 'password',
                type: password.type === 'password' ? 'password' : 'text',
                autoFocus: true,
                value: password.value,
                onChange: (e: any) => {
                  setPassword({ ...password, value: e.target.value });
                  setPasswordErrorMsg('');
                  setPasswordSuccessMsg('');
                },
                onClick: () => {
                  password.type === 'password'
                    ? setPassword({ ...password, type: 'text' })
                    : setPassword({ ...password, type: 'password' });
                },
                onBlur: () => {
                  if (
                    password.value !== confirmPswd.value &&
                    confirmPswd?.value.length > 0
                  ) {
                    setPasswordErrorMsg("Password doesn't match.");
                  }
                },
              })}

              {renderPasswordValidations()}
              {renderPasswordBlock({
                label: 'Confirm password',
                id: 'confirmpassword',
                type: confirmPswd.type === 'password' ? 'password' : 'text',
                value: confirmPswd.value,
                onChange: (e: any) => {
                  setConfirmPswd({ ...confirmPswd, value: e.target.value });
                  setPasswordErrorMsg('');
                  setPasswordSuccessMsg('');
                },
                onClick: () => {
                  confirmPswd.type === 'password'
                    ? setConfirmPswd({ ...confirmPswd, type: 'text' })
                    : setConfirmPswd({ ...confirmPswd, type: 'password' });
                },
                onBlur: () => {
                  if (password.value !== confirmPswd.value) {
                    setPasswordErrorMsg("Password doesn't match.");
                  }
                },
              })}
            </div>
          )}

          {code === null && <Alert variant="danger">Malformed request.</Alert>}

          {isLoading && (
            <div className="fixed inset-0 z-20 grid place-content-center">
              <Spinner size="large" />
            </div>
          )}
        </div>

        {passwordSuccessMsg === '' && code !== null && (
          <div className="flex gap-[1ch] rounded-b-lg bg-gray-50 px-4 py-4 dark:bg-neutral-700/50 sm:justify-end md:px-6">
            <Button
              variant="primary"
              className="grow sm:grow-0"
              onClick={() => onSaveHandler()}
              disabled={
                password.value !== confirmPswd.value ||
                password.value?.length <= 0 ||
                !isValidPassword(password.value)
              }
            >
              Save
            </Button>
          </div>
        )}
      </div>
    </main>
  );
}
