import React, { forwardRef } from 'react';
import { ExclamationTriangleIcon } from '@heroicons/react/24/solid';

const Welcome = forwardRef<HTMLDivElement>(({ ...rest }, ref) => {
  return (
    <div id="dash-info" className="flex scroll-my-2 gap-4" ref={ref} {...rest}>
      <div className="hidden items-center justify-center rounded-lg bg-white p-4 shadow dark:bg-neutral-800 lg:flex">
        <h1 className="whitespace-nowrap text-xl dark:font-thin">
          Welcome to your Customer Portal
        </h1>
      </div>
      <div className="flex grow flex-col justify-center gap-2 rounded-lg bg-sky-50 px-3 py-2 text-cyan-900 shadow dark:bg-sky-950/50 dark:text-sky-200">
        <div className="flex gap-2 text-sm">
          <ExclamationTriangleIcon className="relative -bottom-0.5 h-5 w-6 shrink-0 text-yellow-600 dark:text-yellow-500" />
          <div className="space-y-3">
            <p>
              <strong className="font-medium">Notice of PHI:</strong> The Call
              documents and Person alerts area of this system contains PHI, and
              the Users area contains Personal information. Users with access to
              these areas should exercise all appropriate caution.
            </p>
            <p>
              <strong className="font-medium">Please note:</strong> After 15
              minutes of inactivity you will be logged out and any unsaved work
              will be lost.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
});
Welcome.displayName = 'Welcome';

export { Welcome };
