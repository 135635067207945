import React, { useEffect, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';

import { useExternalScripts } from 'src/hooks';

import { applicationThemeStore } from 'src/store/applicationThemeStore';

function TinymceEditor({
  onChange,
  value,
  height,
  name,
  reference,
  config,
  key,
  onDirty,
}: {
  onChange: (content: string, editor: any) => void;
  value: any;
  height?: string;
  name?: string | undefined;
  reference: (instance: Editor | null) => void;
  config?: any;
  key?: string;
  onDirty?: any;
}) {
  useExternalScripts(
    'https://cdn.tiny.cloud/1/qiu5z2v4blb5bj2osks7butrjd3q4ms2rrnht9tfoa277k6j/tinymce/6.2.0/plugins.min.js?a11ychecker=sdk&advcode=sdk&advtable=sdk&casechange=sdk&checklist=sdk&export=sdk&formatpainter=sdk&permanentpen=sdk&powerpaste=sdk&tinycomments=sdk',
  );
  const defaultConfig = config ?? {
    menubar: false,
    statusbar: false,
    toolbar:
      'undo redo | formatselect | bold italic | bullist numlist | alignleft aligncenter alignright alignjustify | outdent indent | link image',
  };

  const colourScheme = applicationThemeStore(
    React.useCallback(state => state.colourScheme, []),
  );

  return (
    <>
      <Editor
        ref={reference}
        value={value}
        textareaName={name}
        init={{
          ...defaultConfig,
          contextmenu: false,
          height: height,
          toolbar_drawer: false,
          forced_root_block: 'p',
          plugins: ['advlist autolink link image lists charmap print preview'],
          skin: colourScheme == 'dark' ? 'oxide-dark' : 'oxide',
          content_css: colourScheme == 'dark' ? 'dark' : 'default',
          content_style: 'body {font-size: 10pt;};',
          forced_root_block_attrs: {
            style: 'margin: 0',
          },
        }}
        tinymceScriptSrc="/js/tinymce/tinymce.min.js"
        onEditorChange={onChange}
        onKeyPress={onDirty}
      />
    </>
  );
}

export { TinymceEditor };
