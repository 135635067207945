import { useReducer, useEffect, useMemo } from 'react';

import { AlertFilterOptionType } from '../types/personAlerts';

export const initialState: AlertFilterOptionType = {
  keywordSearch: '',
  sortType: 5,
  sortOrder: 'asc',
  cid: '',
  pid: '',
  status: 'Active',
  situationDescription: '',
  alertNumber: '',
  firstName: '',
  lastName: '',
  preferredName: '',
  birthDate: '',
  startDate: '',
  expirationDate: '',
  enteredDate: '',
  id: '',
  accountId: '',
  updatedBy: '',
  updatedDate: '',
};

export function filterReducer(
  state: AlertFilterOptionType,
  action: {
    type: keyof AlertFilterOptionType | 'reset' | 'preserve';
    value?: any;
    payload?: any;
    newState?: AlertFilterOptionType | undefined;
  },
) {
  switch (action.type) {
    case 'reset':
      return {
        ...action.value,
      };

    default:
      return {
        ...state,
        [action.type]: action.value,
      };
  }
}

export function usePersonAlertFilter(): {
  filterOptions: AlertFilterOptionType;
  filterCount: number;
  setValues: (type: keyof AlertFilterOptionType, value: string) => void;
  resetFilters: () => void;
  isFiltersApplied: boolean;
} {
  const [filterOptions, setFilterOptions] = useReducer(
    filterReducer,
    {
      ...JSON.parse(JSON.stringify(initialState)),
    },
    (initial: AlertFilterOptionType) => {
      const savedUsersLogFilterString =
        window.localStorage.getItem('PersonAlertFilter');
      if (savedUsersLogFilterString) {
        const usersLogFilter: AlertFilterOptionType = JSON.parse(
          savedUsersLogFilterString,
        );
        return {
          ...usersLogFilter,
          birthDate:
            usersLogFilter.birthDate !== ''
              ? new Date(usersLogFilter.birthDate)
              : '',
        };
      }
      return {
        ...initial,
      };
    },
  );

  //for filtercount
  const filterCount = useMemo(() => {
    let count: number = 0;
    const obj1: any = { ...initialState };
    const obj2: any = {
      ...JSON.parse(JSON.stringify(filterOptions)),
    };

    delete obj1['keywordSearch'];
    delete obj1['sortOrder'];
    delete obj1['sortType'];
    delete obj1['status'];

    Object.keys(obj1).forEach(key => {
      if (obj1[key] !== obj2[key]) {
        count++;
      }
    });
    return count;
  }, [filterOptions]);

  function setValues(type: keyof AlertFilterOptionType, value: string) {
    setFilterOptions({
      type,
      value,
    });
  }

  useEffect(() => {
    window.localStorage.setItem(
      'PersonAlertFilter',
      JSON.stringify(filterOptions),
    );
  }, [filterOptions]);

  function resetFilters() {
    setFilterOptions({
      type: 'reset',
      value: {
        ...JSON.parse(JSON.stringify(initialState)),
      },
    });
  }

  const isFiltersApplied: boolean = useMemo(() => {
    const obj1: Partial<AlertFilterOptionType> = {
      ...initialState,
    };
    const obj2: Partial<AlertFilterOptionType> = {
      ...JSON.parse(JSON.stringify(filterOptions)),
    };

    delete obj1['sortOrder'];
    let diff = false;
    (Object.keys(obj1) as Array<keyof AlertFilterOptionType>).forEach(
      (value: keyof AlertFilterOptionType) => {
        if (obj1[value] !== obj2[value]) {
          diff = true;
        }
      },
    );
    return diff;
  }, [filterOptions]);

  return {
    filterOptions,
    filterCount,
    setValues,
    resetFilters,
    isFiltersApplied,
  };
}
