import { useInfiniteQuery, UseInfiniteQueryResult } from 'react-query';
import pDebounce from 'p-debounce';

import { useAuth } from 'src/hooks/useAuth';

import { getPersonAlertsAccountsList } from 'src/utils/urls';
import { pageLength } from 'src/utils/appConstants';
import { personAlertSortOptions } from '../utils/addEditPersonAlertConstant';
import { getColumnFromId } from 'src/utils/filterHelper';
import { trimValues } from 'src/utils/common';

import { AccountType } from 'src/types/AccountInfo';

async function fetchAccountList({
  requestData,
  pageParam,
  token,
  tokenType,
}: TVariables & { pageParam: number }): Promise<TPage> {
  requestData.append('start', `${pageParam}`);
  const response = await fetch(getPersonAlertsAccountsList, {
    method: 'POST',
    headers: {
      Authorization: ` ${tokenType} ${token}`,
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    },
    body: requestData,
  });
  return response.json();
}

const debouncedFetch = pDebounce(fetchAccountList, 500);

export type TPage =
  | {
      data: Array<AccountType>;
      draw: number;
      error: Error | null;
      isAutoRefreshEnabled: boolean;
      pageNumber: number;
      recordsFiltered: number;
      recordsTotal: number;
    }
  | undefined;

export type TError = { message: string };

export type TVariables = {
  requestData: any;
  token: string;
  tokenType: string;
};

function setRequestData({
  columnsSearch,
  currentSortItem,
}: inputType): FormData {
  const formData: any = new URLSearchParams();
  formData.append('draw', '0');
  formData.append('length', `${pageLength}`);
  formData.append('columns[0][data]', 'CID');
  formData.append('columns[0][name]', '');
  formData.append('columns[0][searchable]', 'true');
  formData.append('columns[0][orderable]', 'true');
  formData.append('columns[0][search][regex]', 'false');
  formData.append('columns[0][search][ApplyExactMatch]', '');
  formData.append(
    'columns[0][search][value]',
    columnsSearch?.CID ? `${trimValues(columnsSearch?.CID)}` : '',
  );

  formData.append('columns[1][data]', 'PID');
  formData.append('columns[1][name]', '');
  formData.append('columns[1][searchable]', 'true');
  formData.append('columns[1][orderable]', 'true');
  formData.append('columns[1][search][regex]', 'false');
  formData.append('columns[1][search][ApplyExactMatch]', '');
  formData.append(
    'columns[1][search][value]',
    columnsSearch?.PID ? `${trimValues(columnsSearch?.PID)}` : '',
  );

  formData.append('columns[2][data]', 'SID');
  formData.append('columns[2][name]', '');
  formData.append('columns[2][searchable]', 'true');
  formData.append('columns[2][orderable]', 'true');
  formData.append('columns[2][search][regex]', 'false');
  formData.append('columns[2][search][ApplyExactMatch]', '');
  formData.append(
    'columns[2][search][value]',
    columnsSearch?.SID ? `${trimValues(columnsSearch?.SID)}` : '',
  );

  formData.append('columns[3][data]', 'Name');
  formData.append('columns[3][name]', 'Name');
  formData.append('columns[3][searchable]', 'true');
  formData.append('columns[3][orderable]', 'true');
  formData.append('columns[3][search][regex]', 'false');
  formData.append('columns[3][search][ApplyExactMatch]', '');
  formData.append(
    'columns[3][search][value]',
    columnsSearch?.AccountName
      ? `${trimValues(columnsSearch?.AccountName)}`
      : '',
  );

  formData.append(
    'order[0][column]',
    currentSortItem?.sortKey
      ? getColumnFromId(currentSortItem?.sortKey, personAlertSortOptions)
      : '2',
  );
  formData.append(
    'order[0][dir]',
    currentSortItem?.sortOrder ? currentSortItem?.sortOrder : 'asc',
  );
  formData.append('search[value]', ' ');
  formData.append('search[regex]', 'false');

  return formData;
}

type inputType = {
  columnsSearch: Record<string, string>;
  currentSortItem: { sortKey: string; sortOrder: string };
};

function usePersonAlertsAccounts({
  columnsSearch,
  currentSortItem,
}: inputType): UseInfiniteQueryResult<TPage, unknown> {
  const { token, tokenType } = useAuth();

  return useInfiniteQuery(
    ['get_person_alerts_accounts_list', columnsSearch, currentSortItem],
    ({ pageParam = 0 }) => {
      const requestData = setRequestData({ columnsSearch, currentSortItem });
      return debouncedFetch({ requestData, pageParam, token, tokenType });
    },
    {
      getNextPageParam: (lastPage: TPage, allPages: Array<TPage>) => {
        if (lastPage !== null && lastPage?.data?.length === pageLength)
          return allPages?.length * pageLength;
      },
      getPreviousPageParam: (firstPage: TPage, allPages: Array<TPage>) => {
        if (firstPage !== null && firstPage?.data?.length === pageLength)
          return allPages?.length - 1 * pageLength;
      },
      staleTime: 60 * 1000,
      refetchOnWindowFocus: false,
    },
  );
}

export { usePersonAlertsAccounts };
