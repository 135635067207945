import { getColumnFromId } from 'src/utils/filterHelper';
import { sortOptions } from '../utils/userConstants';
import { trimValues } from 'src/utils/common';
import { getLocLabel } from 'src/features/CallDocuments/utils/callDocumentHelperFunctions';

import { FilterOptionsType } from '../types/usersListType';

export function setListRequestData(filterOptions: FilterOptionsType): FormData {
  const formData: any = new URLSearchParams();
  formData.append('draw', '0');
  formData.append('length', `${100}`);
  formData.append('timezoneOffset', `${new Date().getTimezoneOffset()}`);
  formData.append('columns[0][data]', 'UserName');
  formData.append('columns[0][name]', '');
  formData.append('columns[0][searchable]', 'true');
  formData.append('columns[0][orderable]', 'true');
  formData.append('columns[0][search][regex]', 'false');
  formData.append(
    'columns[0][search][value]',
    filterOptions && filterOptions.userName
      ? trimValues(filterOptions.userName)
      : '',
  );

  formData.append('columns[1][data]', 'LastName');
  formData.append('columns[1][name]', '');
  formData.append('columns[1][searchable]', 'true');
  formData.append('columns[1][orderable]', 'true');
  formData.append('columns[1][search][regex]', 'false');
  formData.append(
    'columns[1][search][value]',
    filterOptions && filterOptions.lastName
      ? trimValues(filterOptions.lastName)
      : '',
  );

  formData.append('columns[2][data]', 'FirstName');
  formData.append('columns[2][name]', '');
  formData.append('columns[2][searchable]', 'true');
  formData.append('columns[2][orderable]', 'true');
  formData.append('columns[2][search][regex]', 'false');
  formData.append(
    'columns[2][search][value]',
    filterOptions && filterOptions.firstName
      ? trimValues(filterOptions.firstName)
      : '',
  );

  formData.append('columns[3][data]', 'AccountPIDs');
  formData.append('columns[3][name]', '');
  formData.append('columns[3][searchable]', 'true');
  formData.append('columns[3][orderable]', 'true');
  formData.append('columns[3][search][regex]', 'false');
  formData.append(
    'columns[3][search][value]',
    filterOptions && filterOptions.pid ? trimValues(filterOptions.pid) : '',
  );

  formData.append('columns[4][data]', 'AccountCIDs');
  formData.append('columns[4][name]', '');
  formData.append('columns[4][searchable]', 'true');
  formData.append('columns[4][orderable]', 'true');
  formData.append('columns[4][search][regex]', 'false');
  formData.append(
    'columns[4][search][value]',
    filterOptions && filterOptions.cid ? trimValues(filterOptions.cid) : '',
  );

  formData.append('columns[5][data]', 'Permissions');
  formData.append('columns[5][name]', '');
  formData.append('columns[5][searchable]', 'true');
  formData.append('columns[5][orderable]', 'true');
  formData.append('columns[5][search][regex]', 'false');
  formData.append(
    'columns[5][search][value]',
    filterOptions && filterOptions.permission
      ? getLocLabel(filterOptions.permission)
      : '',
  );

  formData.append('columns[6][data]', 'Status');
  formData.append('columns[6][name]', '');
  formData.append('columns[6][searchable]', 'true');
  formData.append('columns[6][orderable]', 'true');
  formData.append('columns[6][search][regex]', 'false');
  formData.append(
    'columns[6][search][value]',
    filterOptions && filterOptions.status
      ? getLocLabel(filterOptions.status)
      : '',
  );

  formData.append(
    'order[0][column]',
    getColumnFromId(filterOptions.sortType, sortOptions),
  );
  formData.append('order[0][dir]', filterOptions.sortOrder);

  formData.append(
    'search[value]',
    `${trimValues(filterOptions.keywordSearch)}`,
  );
  formData.append('search[regex]', 'false');

  return formData;
}
