function convertToNumber(num: any) {
  return parseInt(num);
}

function addressInfo(item: any) {
  return {
    City: item?.PlaceName,
    StateProvinceId: item?.StateProvinceId,
    StateProvince: item?.StateProvince,
    ZipPostalCode: item?.Code,
    CountryId: item?.CountryId,
    Country: item?.Country,
    County: item?.County,
    Id: item?.Id,
  };
}

export { convertToNumber, addressInfo };
