import React from 'react';
import clsx, { ClassValue } from 'clsx';
import MaskInput from 'react-input-mask';

type Selection = {
  end: number;
  start: number;
  length: number;
};

type MaskedStateType = {
  selection: Selection;
  value: string;
};

type MaskInputType = {
  id?: string;
  value: string;
  disabled?: boolean;
  placeHolder?: string;
  className?: ClassValue;
  onChange?: (value: string) => void;
  onBlur?: (value: string) => void;
  type?:
    | 'number'
    | 'search'
    | 'time'
    | 'text'
    | 'email'
    | 'password'
    | 'tel'
    | 'url'
    | 'date'
    | undefined;
  pattern?: string;
  onDrop?: any;
};

const maskCharCountForUs = 12; //no of alpahanumeric char in a phone number.
const maskCharCountForOthers = 15; //start index of alphabets inside phone number

function InputMask({
  id,
  value,
  disabled,
  className,
  type,
  onChange,
  pattern,
  onBlur,
  onDrop,
}: MaskInputType) {
  //To restrict removing characters from starting of input
  const beforeMaskedValueChange = (
    newState: MaskedStateType,
    oldState: MaskedStateType,
    inputValue: any,
  ): MaskInputType => {
    const maxMaskCharCount =
      pattern === '999999999999999'
        ? maskCharCountForOthers
        : maskCharCountForUs;

    if (inputValue) {
      let userInput = inputValue?.replace(/[^0-9]/g, '');
      let { value, selection } = oldState;
      newState = {
        ...newState,
        value:
          value.length < maxMaskCharCount
            ? copyPasteString(
                value,
                selection.start,
                userInput,
                selection.length,
              )
            : oldState.value,
      };
    }

    return {
      ...newState,
      value:
        pattern === '999999999999999'
          ? newState.value.replace(/([^\w ]|_)/g, '').replace(/ /g, '')
          : formatPhnumber(newState.value),
    };
  };

  const formatPhnumber = (phnnumber: string) => {
    let formattedStr: string = '';
    const phnNumber = phnnumber.replace(/([^\w ]|_)/g, '').replace(/ /g, '');
    const digitCount =
      pattern === '999999999999999'
        ? maskCharCountForOthers
        : maskCharCountForUs - 2;

    for (let i = 0; i < phnNumber.length && i < digitCount; i++) {
      if (
        (i == 3 && phnNumber.length >= 3) ||
        (i == 6 && phnNumber.length >= 7)
      ) {
        formattedStr += '-';
      }
      formattedStr += phnNumber[i];
    }
    return formattedStr;
  };

  const copyPasteString = (
    str: string,
    start: number,
    input: string,
    length: number,
  ): string => {
    return str.substring(0, start) + input + str.substring(start + length);
  };

  const userClasses = clsx(className);

  return (
    <>
      <MaskInput
        id={id}
        mask={pattern}
        type={type}
        disabled={disabled}
        className={userClasses}
        value={value}
        onChange={(e: any) => {
          onChange && onChange(e);
        }}
        onBlur={(e: any) => {
          onBlur && onBlur(e);
        }}
        onDrop={onDrop}
        onDragOver={(e: any) => e.preventDefault()}
        beforeMaskedValueChange={beforeMaskedValueChange}
      ></MaskInput>
    </>
  );
}

export { InputMask };
