import { trimValues } from 'src/utils/common';
import { pageLength } from 'src/utils/appConstants';

import { FilterOptionsType } from '../types/personRepoListTypes';

export function setListRequestData(filterOptions: FilterOptionsType): FormData {
  const formData: any = new URLSearchParams();
  formData.append('draw', '0');
  formData.append('length', `${pageLength}`);
  formData.append('timezoneOffset', `${new Date().getTimezoneOffset()}`);
  formData.append('columns[0][data]', 'Id');
  formData.append('columns[0][name]', 'Id');
  formData.append('columns[0][searchable]', 'true');
  formData.append('columns[0][orderable]', 'true');
  formData.append('columns[0][search][regex]', 'false');
  formData.append(
    'columns[0][search][value]',
    filterOptions && filterOptions.id ? trimValues(filterOptions.id) : '',
  );

  formData.append('columns[1][data]', 'AccountId');
  formData.append('columns[1][name]', '');
  formData.append('columns[1][searchable]', 'true');
  formData.append('columns[1][orderable]', 'true');
  formData.append('columns[1][search][regex]', 'false');
  formData.append('columns[1][search][value]', '');

  formData.append('columns[2][data]', 'CID');
  formData.append('columns[2][name]', '');
  formData.append('columns[2][searchable]', 'true');
  formData.append('columns[2][orderable]', 'true');
  formData.append('columns[2][search][regex]', 'false');
  formData.append(
    'columns[2][search][value]',
    filterOptions && filterOptions.cid ? trimValues(filterOptions.cid) : '',
  );

  formData.append('columns[3][data]', 'SID');
  formData.append('columns[3][name]', '');
  formData.append('columns[3][searchable]', 'true');
  formData.append('columns[3][orderable]', 'true');
  formData.append('columns[3][search][regex]', 'false');
  formData.append(
    'columns[3][search][value]',
    filterOptions && filterOptions.sid ? trimValues(filterOptions.sid) : '',
  );

  formData.append('columns[4][data]', 'PID');
  formData.append('columns[4][name]', '');
  formData.append('columns[4][searchable]', 'true');
  formData.append('columns[4][orderable]', 'true');
  formData.append('columns[4][search][regex]', 'false');
  formData.append(
    'columns[4][search][value]',
    filterOptions && filterOptions.pid ? trimValues(filterOptions.pid) : '',
  );

  formData.append('columns[5][data]', 'EntityId');
  formData.append('columns[5][name]', '');
  formData.append('columns[5][searchable]', 'true');
  formData.append('columns[5][orderable]', 'true');
  formData.append('columns[5][search][regex]', 'false');
  formData.append('columns[5][search][value]', '');

  formData.append('columns[6][data]', 'FirstName');
  formData.append('columns[6][name]', '');
  formData.append('columns[6][searchable]', 'true');
  formData.append('columns[6][orderable]', 'true');
  formData.append('columns[6][search][regex]', 'false');
  formData.append(
    'columns[6][search][value]',
    filterOptions && filterOptions.firstName
      ? trimValues(filterOptions.firstName)
      : '',
  );

  formData.append('columns[7][data]', 'LastName');
  formData.append('columns[7][name]', '');
  formData.append('columns[7][searchable]', 'true');
  formData.append('columns[7][orderable]', 'true');
  formData.append('columns[7][search][regex]', 'false');
  formData.append(
    'columns[7][search][value]',
    filterOptions && filterOptions.lastName
      ? trimValues(filterOptions.lastName)
      : '',
  );

  formData.append('columns[8][data]', 'PreferredName');
  formData.append('columns[8][name]', '');
  formData.append('columns[8][searchable]', 'true');
  formData.append('columns[8][orderable]', 'true');
  formData.append('columns[8][search][regex]', 'false');
  formData.append(
    'columns[8][search][value]',
    filterOptions && filterOptions.alternateName
      ? trimValues(filterOptions.alternateName)
      : '',
  );

  formData.append('columns[9][name]', 'BirthDate');
  formData.append('columns[9][searchable]', 'true');
  formData.append('columns[9][orderable]', 'true');
  formData.append('columns[9][search][regex]', 'false');
  formData.append('columns[9][data]', 'PreferredName');
  formData.append('columns[9][search][value]', '');

  formData.append('columns[10][data]', 'PrimaryPhone');
  formData.append('columns[10][name]', '');
  formData.append('columns[10][searchable]', 'true');
  formData.append('columns[10][orderable]', 'true');
  formData.append('columns[10][search][regex]', 'false');
  formData.append(
    'columns[10][search][value]',
    filterOptions && filterOptions.primaryPhone
      ? trimValues(filterOptions.primaryPhone)?.replace(/[^\d]/g, '')
      : '',
  );

  formData.append('columns[11][name]', 'SecondaryPhone');
  formData.append('columns[11][searchable]', 'true');
  formData.append('columns[11][orderable]', 'true');
  formData.append('columns[11][search][regex]', 'false');
  formData.append('columns[11][data]', 'PreferredName');
  formData.append('columns[11][search][value]', '');

  formData.append('columns[12][name]', 'Gender');
  formData.append('columns[12][searchable]', 'true');
  formData.append('columns[12][orderable]', 'true');
  formData.append('columns[12][search][regex]', 'false');
  formData.append('columns[12][data]', 'PreferredName');
  formData.append('columns[12][search][value]', '');

  formData.append('columns[13][name]', 'City');
  formData.append('columns[13][searchable]', 'true');
  formData.append('columns[13][orderable]', 'true');
  formData.append('columns[13][search][regex]', 'false');
  formData.append('columns[13][data]', 'PreferredName');
  formData.append(
    'columns[13][search][value]',
    filterOptions && filterOptions.city ? trimValues(filterOptions.city) : '',
  );

  formData.append('columns[14][name]', 'StateProvince');
  formData.append('columns[14][searchable]', 'true');
  formData.append('columns[14][orderable]', 'true');
  formData.append('columns[14][search][regex]', 'false');
  formData.append('columns[14][data]', 'PreferredName');
  formData.append(
    'columns[14][search][value]',
    filterOptions && filterOptions.state ? trimValues(filterOptions.state) : '',
  );

  formData.append('columns[15][name]', 'Alerts');
  formData.append('columns[15][searchable]', 'true');
  formData.append('columns[15][orderable]', 'true');
  formData.append('columns[15][search][regex]', 'false');
  formData.append('columns[15][data]', 'PreferredName');
  formData.append('columns[15][search][value]', '');

  formData.append('columns[16][name]', 'HasAlerts');
  formData.append('columns[16][searchable]', 'true');
  formData.append('columns[16][orderable]', 'true');
  formData.append('columns[16][search][regex]', 'false');
  formData.append('columns[16][data]', 'PreferredName');
  formData.append('columns[16][search][value]', '');

  formData.append('order[0][column]', filterOptions.sortType);
  formData.append('order[0][dir]', filterOptions.sortOrder);

  formData.append(
    'search[value]',
    `${trimValues(filterOptions.keywordSearch)}`,
  );
  formData.append('search[regex]', 'false');

  return formData;
}
