import {
  QueryClient,
  UseMutationResult,
  useMutation,
  useQueryClient,
} from 'react-query';

import { useAuth } from 'src/hooks';

import { deleteTutorial } from 'src/utils/urls';

async function deleteFile({
  token,
  tokenType,
  fileName,
}: {
  token: string;
  tokenType: string;
  fileName: string;
}): Promise<any> {
  const response = await fetch(deleteTutorial(fileName), {
    method: 'GET',
    headers: {
      Authorization: `${tokenType} ${token}`,
      'Content-Type': 'application/json',
    },
  });
  return response;
}

type TError = {
  message: string;
};

function useDeleteTutorialFile(): UseMutationResult<
  void,
  TError,
  string,
  unknown
> {
  const queryClient = useQueryClient();
  const { token, tokenType } = useAuth();
  return useMutation(async (fileName: string) => {
    const result = await deleteFile({ token, tokenType, fileName });
    if (result?.status === 200) {
      queryClient?.invalidateQueries('get_tutorials');
      return result;
    } else {
      const errorMsg = await result.text();
      throw { message: errorMsg };
    }
  });
}
export { useDeleteTutorialFile };
