import React from 'react';
import Select from 'react-select';

import { optionType } from 'src/types/countryType';

type MultiSelectInput = {
  options: Array<optionType> | undefined;
  size?: string;
  label?: string;
  id?: string;
  setSelectedOptions: (e: any) => void;
  value: any;
  isDisabled?: boolean;
};

function ReactSelectWithFlags({
  options,
  setSelectedOptions,
  size,
  id,
  value,
  isDisabled,
}: MultiSelectInput) {
  const customFilter = (option: any, rawInput: string): boolean => {
    const inputValue = rawInput.toLowerCase().trim();
    if (inputValue !== '') {
      const tempItem =
        `${option?.data?.abbreviation?.toLowerCase()}${option?.data?.isdCode?.toLowerCase()}${option?.data?.name?.toLowerCase()}`?.replace(
          /\s+/g,
          '',
        );
      const replacedValue = inputValue?.replace(/\s+/g, '');
      return tempItem?.includes(replacedValue);
    } else return true;
  };
  return (
    <Select
      id={id}
      options={options}
      isMulti={false}
      closeMenuOnSelect={true}
      onChange={e => {
        setSelectedOptions(e);
      }}
      classNamePrefix="multiselect-dropdown"
      className={
        size === 'small'
          ? 'multiselect-dropdown pr-multiselect-countrycode multiselect-dropdown--small'
          : 'multiselect-dropdown pr-multiselect-countrycode '
      }
      filterOption={customFilter}
      isSearchable={true}
      value={value}
      getOptionLabel={(option: any) => option?.label}
      getOptionValue={(option: any) => option?.value}
      maxMenuHeight={250}
      isDisabled={isDisabled}
    />
  );
}

export { ReactSelectWithFlags };
