import { useReducer, useEffect, useMemo } from 'react';

import { FilterOptionsType } from '../types/portalAlerts';

export const initialState: FilterOptionsType = {
  KeywordSearch: '',
  SortType: 'EffectiveDate',
  SortOrder: 'asc',
  EffectiveDate: '',
  ExpirationDate: '',
  Message: '',
  Title: '',
};

export function filterReducer(
  state: FilterOptionsType,
  action: {
    type: keyof FilterOptionsType | 'reset' | 'preserve';
    value?: any;
    payload?: any;
    newState?: FilterOptionsType | undefined;
  },
) {
  switch (action.type) {
    case 'reset':
      return {
        ...action.value,
      };

    default:
      return {
        ...state,
        [action.type]: action.value,
      };
  }
}

export function usePortalAlertFilter(): {
  filterOptions: FilterOptionsType;
  filterCount: number;
  setValues: (type: keyof FilterOptionsType, value: string) => void;
  resetFilters: () => void;
  isFiltersApplied: boolean;
} {
  const [filterOptions, setFilterOptions] = useReducer(
    filterReducer,
    {
      ...JSON.parse(JSON.stringify(initialState)),
    },
    (initial: FilterOptionsType) => {
      const savedPortalALertFilterString =
        window.localStorage.getItem('PortalAlertFilter');
      if (savedPortalALertFilterString) {
        const portalAlertFilter: FilterOptionsType = JSON.parse(
          savedPortalALertFilterString,
        );
        return {
          ...portalAlertFilter,
        };
      }
      return {
        ...initial,
      };
    },
  );

  //for filtercount
  const filterCount = useMemo(() => {
    let count: number = 0;
    const obj1: any = { ...initialState };
    const obj2: any = {
      ...JSON.parse(JSON.stringify(filterOptions)),
    };

    delete obj1['KeywordSearch'];
    delete obj1['SortOrder'];
    delete obj1['SortType'];

    Object.keys(obj1).forEach(key => {
      if (obj1[key] !== obj2[key]) {
        count++;
      }
    });
    return count;
  }, [filterOptions]);

  function setValues(type: keyof FilterOptionsType, value: string) {
    setFilterOptions({
      type,
      value,
    });
  }

  useEffect(() => {
    window.localStorage.setItem(
      'PortalAlertFilter',
      JSON.stringify(filterOptions),
    );
  }, [filterOptions]);

  function resetFilters() {
    setFilterOptions({
      type: 'reset',
      value: {
        ...JSON.parse(JSON.stringify(initialState)),
      },
    });
  }

  const isFiltersApplied: boolean = useMemo(() => {
    const obj1: Partial<FilterOptionsType> = {
      ...initialState,
    };
    const obj2: Partial<FilterOptionsType> = {
      ...JSON.parse(JSON.stringify(filterOptions)),
    };

    delete obj1['SortOrder'];
    let diff = false;
    (Object.keys(obj1) as Array<keyof FilterOptionsType>).forEach(
      (value: keyof FilterOptionsType) => {
        if (obj1[value] !== obj2[value]) {
          diff = true;
        }
      },
    );
    return diff;
  }, [filterOptions]);

  return {
    filterOptions,
    filterCount,
    setValues,
    resetFilters,
    isFiltersApplied,
  };
}
