import { getCaller, getQuestionsAndSection } from 'src/utils/urls';
import { QuestionsDetails } from '../types/QuestionsTabType';

type TResult = any | QuestionsDetails;

async function fetchQuestionsAndSections({
  token,
  tokenType,
  accountId,
  entityId,
}: {
  token: string;
  tokenType: string;
  accountId: number | undefined;
  entityId: string | undefined;
}): Promise<TResult> {
  const response = await fetch(getQuestionsAndSection(accountId, entityId), {
    method: 'GET',
    headers: {
      Authorization: `${tokenType} ${token}`,
    },
  });
  return response.json();
}

export { fetchQuestionsAndSections };
