import { UseMutationResult, useMutation } from 'react-query';

import { sentEmailLink } from 'src/utils/urls';

async function postEmailLink({ email }: TVaribles): Promise<TResult> {
  const response = await fetch(sentEmailLink(email), {
    method: 'GET',
  });
  if (response.status !== 200) {
    let error = await response.json();
    throw { message: error[0] };
  }
  return response;
}

export type TResult = any;
export type TError = { message: string };
export type TVaribles = {
  email: string;
};

function useSentEmailLink(): UseMutationResult<
  TResult,
  TError,
  TVaribles,
  unknown
> {
  return useMutation(async (params: TVaribles) => {
    const result = await postEmailLink({
      email: encodeURIComponent(params.email),
    });
    if (result) return result;
    else throw result;
  });
}

export { useSentEmailLink };
