function useGetStoredPermissions(isBlockAuthflow: boolean = false) {
  let permissionArray = [];
  if (isBlockAuthflow) return [];
  const permissionSet = localStorage.getItem('userPermissions');
  if (permissionSet) {
    permissionArray = JSON.parse(permissionSet);
  }
  return permissionArray;
}

export { useGetStoredPermissions };
