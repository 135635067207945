import { UseMutationResult, useMutation } from 'react-query';

import { useAuth } from 'src/hooks';

import { postAccountAlert } from 'src/utils/urls';

async function fetchPostAccountAlert({
  token,
  tokenType,
  params,
}: TVariables): Promise<any> {
  const action = params?.isModified ? 'PUT' : 'POST';
  return await fetch(postAccountAlert(params.Id), {
    method: action,
    headers: {
      Authorization: `${tokenType} ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(params.AccountAlert),
  });
}

type TVariables = {
  token: string;
  tokenType: string;
  params: TParams;
};

type TError = any;

type TParams = {
  AccountAlert: any;
  Id: number;
  isModified?: boolean;
};

function usePostAccountAlert(): UseMutationResult<
  void,
  TError,
  TParams,
  unknown
> {
  const { token, tokenType } = useAuth();

  return useMutation(async params => {
    const result: any = await fetchPostAccountAlert({
      token,
      tokenType,
      params,
    });

    if ((result && result?.status === 400) || result?.status === 500) {
      throw result.statusText;
    }
    return result;
  });
}
export { usePostAccountAlert };
