import React, { ReactElement } from 'react';
import clsx, { ClassValue } from 'clsx';

type ReadyToChatIconType = {
  className?: ClassValue;
};
function ReadyToChatIcon({ className }: ReadyToChatIconType): ReactElement {
  const userClasses = clsx(className);
  return (
    <>
      <svg
        viewBox="0 0 20 20"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
        className={`${userClasses} inline-block h-5 w-5`}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18 5V13C18 13.5304 17.7893 14.0391 17.4142 14.4142C17.0391 14.7893 16.5304 15 16 15H11L6 19V15H4C3.46957 15 2.96086 14.7893 2.58579 14.4142C2.21071 14.0391 2 13.5304 2 13V5C2 4.46957 2.21071 3.96086 2.58579 3.58579C2.96086 3.21071 3.46957 3 4 3H16C16.5304 3 17.0391 3.21071 17.4142 3.58579C17.7893 3.96086 18 4.46957 18 5ZM7 8H5L7 12H9L8.5 10L7 8ZM15 5L8.5 10L9 12L15 7.5V5Z"
          fill="currentColor"
        />
      </svg>
    </>
  );
}
export { ReadyToChatIcon };
