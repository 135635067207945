import React, { SelectHTMLAttributes } from 'react';
import clsx, { ClassValue } from 'clsx';
import {
  inputBaseClasses,
  inputLabelClasses,
} from 'src/ui/components/TextField';

export type OptionItem = {
  // id: number;
  label: string;
  value: string | number;
};

type SelectProps = SelectHTMLAttributes<HTMLSelectElement> & {
  label?: string;
  id: string;
  inputSize?: 'small' | 'medium' | 'large';
  className?: ClassValue;
  srOnlyLabel?: string;
  options: Array<OptionItem> | undefined;
  required?: boolean;
  errorHighlight?: boolean;
};

export const selectBaseClasses = `${inputBaseClasses}  pr-10`;

export const selectInputSizeClasses = {
  small: 'pt-1 pb-1.5 pl-3 pr-8 text-xs leading-5 !rounded',
  medium: 'py-1.5 pl-3 pr-10 text-sm leading-6',
  large: 'pt-2 pb-3 pl-3 pr-11 text-md leading-6',
};

const Select = ({
  label,
  id,
  inputSize = 'medium',
  children,
  className,
  options,
  srOnlyLabel,
  required,
  errorHighlight,
  ...rest
}: SelectProps) => {
  const sizeClass = selectInputSizeClasses[inputSize];

  const userClasses = clsx(className);

  return (
    <div className={userClasses}>
      {label ? (
        <label htmlFor={id}>
          <div className="flex items-center gap-1.5">
            {errorHighlight && (
              <div className="h-3 w-3">
                <span className="relative flex h-3 w-3 items-center justify-center">
                  <span className="absolute inline-flex h-full w-full animate-ping rounded-full bg-red-400 opacity-75" />
                  <span className="relative inline-flex h-2 w-2 rounded-full bg-red-500" />
                </span>
              </div>
            )}
            <span
              className={`${inputLabelClasses} ${
                inputSize === 'small' ? 'text-xs' : ''
              } ${
                required
                  ? 'after:ml-0.5 after:text-red-500 after:content-["*"] dark:after:text-red-400'
                  : ''
              }`}
            >
              {label}
            </span>
          </div>
        </label>
      ) : (
        ''
      )}
      {srOnlyLabel ? (
        <label htmlFor={id} className="sr-only">
          {srOnlyLabel}
        </label>
      ) : (
        ''
      )}
      <select
        name={id}
        id={id}
        className={`${selectBaseClasses} ${sizeClass} ${
          inputSize === 'small' ? 'mt-0' : 'mt-0.5'
        }`}
        {...rest}
      >
        {options?.map(option => (
          <option key={option?.value} value={option?.value}>
            {option?.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export { Select };
