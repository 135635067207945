import { useInfiniteQuery, UseInfiniteQueryResult } from 'react-query';
import pDebounce from 'p-debounce';

import { useAuth } from 'src/hooks/useAuth';

import { findPersonInformation } from 'src/utils/urls';
import { pageLength } from 'src/utils/appConstants';
import { getColumnFromId } from 'src/utils/filterHelper';
import { sortOptions } from '../utils/addEditPersonAlertConstant';

import { PersonInformation } from '../types/findPersonInformation';

async function fetchFindPeople({
  requestData,
  pageParam,
  token,
  tokenType,
  accountId,
}: TVariables & { pageParam: number }): Promise<TPage> {
  requestData.append('start', `${pageParam}`);
  const response = await fetch(findPersonInformation(accountId), {
    method: 'POST',
    headers: {
      Authorization: ` ${tokenType} ${token}`,
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: requestData,
  });
  return response.json();
}

const debouncedFetch = pDebounce(fetchFindPeople, 500);

export type TResult = {
  pages: Array<TPage> | [];
};

export type TPage = {
  data: Array<PersonInformation>;
  draw: number;
  error: Error | null;
  isAutoRefreshEnabled: boolean;
  pageNumber: number;
  recordsFiltered: number;
  recordsTotal: number;
};

export type TError = { message: string };

export type TVariables = {
  requestData: any;
  token: string;
  tokenType: string;
  accountId: number;
};

function setRequestData({
  currentSortItem,
  searchPerson,
}: {
  currentSortItem: { sortKey: string; sortOrder: string };
  searchPerson: string;
}): FormData {
  const formData: any = new URLSearchParams();
  formData.append('draw', '0');
  formData.append('length', `${100}`);
  formData.append('columns[0][data]', 'Id');
  formData.append('columns[0][name]', '');
  formData.append('columns[0][searchable]', 'true');
  formData.append('columns[0][orderable]', 'true');
  formData.append('columns[0][search][regex]', 'false');
  formData.append('columns[0][search][value]', '');

  formData.append('columns[1][data]', 'FirstName');
  formData.append('columns[1][name]', '');
  formData.append('columns[1][searchable]', 'true');
  formData.append('columns[1][orderable]', 'true');
  formData.append('columns[1][search][regex]', 'false');
  formData.append('columns[1][search][value]', '');

  formData.append('columns[2][data]', 'LastName');
  formData.append('columns[2][name]', '');
  formData.append('columns[2][searchable]', 'true');
  formData.append('columns[2][orderable]', 'true');
  formData.append('columns[2][search][regex]', 'false');
  formData.append('columns[2][search][value]', '');

  formData.append('columns[3][data]', 'PreferredName');
  formData.append('columns[3][name]', '');
  formData.append('columns[3][searchable]', 'true');
  formData.append('columns[3][orderable]', 'true');
  formData.append('columns[3][search][regex]', 'false');
  formData.append('columns[3][search][value]', '');

  formData.append('columns[4][data]', 'PhoneNumber');
  formData.append('columns[4][name]', '');
  formData.append('columns[4][searchable]', 'true');
  formData.append('columns[4][orderable]', 'true');
  formData.append('columns[4][search][regex]', 'false');
  formData.append('columns[4][search][value]', '');

  formData.append('columns[5][data]', 'SecondaryPhoneNumber');
  formData.append('columns[5][name]', '');
  formData.append('columns[5][searchable]', 'true');
  formData.append('columns[5][orderable]', 'true');
  formData.append('columns[5][search][regex]', 'false');
  formData.append('columns[5][search][value]', '');

  formData.append('columns[6][data]', 'DateOfBirth');
  formData.append('columns[6][name]', '');
  formData.append('columns[6][searchable]', 'true');
  formData.append('columns[6][orderable]', 'true');
  formData.append('columns[6][search][regex]', 'false');
  formData.append('columns[6][search][value]', '');

  formData.append('columns[7][data]', 'City');
  formData.append('columns[7][name]', '');
  formData.append('columns[7][searchable]', 'true');
  formData.append('columns[7][orderable]', 'true');
  formData.append('columns[7][search][regex]', 'false');
  formData.append('columns[7][search][value]', '');

  formData.append('columns[8][data]', 'Ranking');
  formData.append('columns[8][name]', '');
  formData.append('columns[8][searchable]', 'true');
  formData.append('columns[8][orderable]', 'true');
  formData.append('columns[8][search][regex]', 'false');
  formData.append('columns[8][search][value]', '');

  formData.append(
    'order[0][column]',
    currentSortItem?.sortKey
      ? getColumnFromId(currentSortItem?.sortKey, sortOptions)
      : '2',
  );
  formData.append(
    'order[0][dir]',
    currentSortItem?.sortOrder ? currentSortItem?.sortOrder : 'asc',
  );
  formData.append(
    'search[value]',
    searchPerson ? searchPerson.replace(/[^a-zA-Z0-9]/g, '') : '',
  );
  formData.append('search[regex]', 'false');

  return formData;
}

type inputType = {
  currentSortItem: { sortKey: string; sortOrder: string };
  accountId: number | undefined;
  searchPerson: string;
};

function useFindPeople({
  currentSortItem,
  accountId,
  searchPerson,
}: inputType): UseInfiniteQueryResult<TPage, unknown> {
  const { token, tokenType } = useAuth();

  return useInfiniteQuery(
    ['find_people', currentSortItem, searchPerson, accountId],
    ({ pageParam = 0 }) => {
      const requestData = setRequestData({
        currentSortItem,
        searchPerson,
      });
      return debouncedFetch({
        accountId,
        requestData,
        pageParam,
        token,
        tokenType,
      });
    },

    {
      getNextPageParam: (lastPage: TPage, allPages: Array<TPage>) => {
        if (lastPage !== null && lastPage?.data?.length === pageLength)
          return allPages?.length * pageLength;
      },
      getPreviousPageParam: (firstPage: TPage, allPages: Array<TPage>) => {
        if (firstPage !== null && firstPage.data?.length === pageLength)
          return allPages?.length - 1 * pageLength;
      },
      staleTime: 60 * 1000,
      refetchOnWindowFocus: false,
      enabled: accountId !== undefined && searchPerson !== '' ? true : false,
    },
  );
}

export { useFindPeople };
