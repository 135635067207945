import React, { ReactElement } from 'react';
import {
  ArrowSmallDownIcon,
  ArrowSmallUpIcon,
} from '@heroicons/react/20/solid';

import { Button, Select } from 'src/ui/components';

import { options } from '../utils/constants';

import { SortandFilterType } from '../types/portalAlerts';

function PortalAlertSort({
  filterOptions,
  setValues,
}: SortandFilterType): ReactElement {
  return (
    <div className="flex items-end gap-2">
      <Select
        inputSize="small"
        label="Sort by"
        id="filter-status"
        options={options}
        className="w-full sm:w-auto"
        onChange={e => {
          setValues('SortType', e.target.value);
        }}
        value={filterOptions?.SortType}
      />
      <Button
        variant={filterOptions?.SortOrder === 'asc' ? 'primary' : 'secondary'}
        size="small"
        onClick={() => setValues('SortOrder', 'asc')}
      >
        <ArrowSmallUpIcon className="h-5 w-5" aria-hidden="true" />
        <span className="sr-only">Sort in ascending order</span>
      </Button>
      <Button
        variant={filterOptions?.SortOrder === 'desc' ? 'primary' : 'secondary'}
        size="small"
        onClick={() => setValues('SortOrder', 'desc')}
      >
        <ArrowSmallDownIcon className="h-5 w-5" aria-hidden="true" />
        <span className="sr-only">Sort in descending order</span>
      </Button>
    </div>
  );
}

export { PortalAlertSort };
