import React, { useCallback, useRef } from 'react';
import { ArrowPathIcon } from '@heroicons/react/16/solid';

import {
  Button,
  EmptyState,
  SearchableMultiSelectCheckbox,
  Spinner,
} from 'src/ui/components';
import { DoughnutChart } from 'src/ui/components/DoughnutChart';

import { useAuth } from 'src/hooks';

import { useHomeStore } from '../store/homeStore';
import { getDateRange } from '../utils/storeHelper';
import { classNames } from 'src/utils/className';

type ClinicalCallResolutionType = {
  widgetInfo?: Array<string>;
};

function ClinicalCallResolution({ widgetInfo }: ClinicalCallResolutionType) {
  const { isExternalUser } = useAuth();
  const selectRef = useRef<any>();

  const chartType = useHomeStore(
    useCallback(state => state.clinicalCallChartType, []),
  );

  const clinicalCallResolution = useHomeStore(
    useCallback(state => state.clinicalCallResolution, []),
  );

  const accountOptions = useHomeStore(
    useCallback(state => state.accountOptions, []),
  );

  const setSelectedOptions = useHomeStore(
    useCallback(state => state.setSelectedOptions, []),
  );
  const selectedOptions = useHomeStore(
    useCallback(state => state.selectedOptions, []),
  );

  const isChartInfoLoading = useHomeStore(
    useCallback(state => state.isChartInfoLoading, []),
  );

  const chartData = useHomeStore(useCallback(state => state.chartData, []));

  const groupedOptions = [
    {
      label: 'Selected Accounts',
      options: selectedOptions,
    },
    {
      label: '',
      options: accountOptions?.filter(
        item => !selectedOptions?.some(data => item?.value === data?.value),
      ),
    },
  ];

  function isChartEmpty() {
    if (chartType !== 'call-data-chart') {
      return (
        clinicalCallResolution?.FacilitatedConnectionToHigherlevelCount +
          clinicalCallResolution?.ResolvedByProtoCallCount +
          clinicalCallResolution?.NACount ===
        0
      );
    } else {
      return (
        clinicalCallResolution?.ClinicalCallsCount +
          clinicalCallResolution?.NonClinicalCallsCount ===
        0
      );
    }
  }

  return (
    <div
      className={classNames(
        'relative flex flex-col rounded-lg bg-white shadow  dark:bg-neutral-800',
        !isExternalUser &&
          widgetInfo?.length === 1 &&
          widgetInfo?.includes('Clinical call resolution')
          ? 'lg:col-span-2'
          : '',
        isExternalUser ? '' : 'lg:col-span-1 xl:col-span-2',
        isExternalUser &&
          widgetInfo?.length === 1 &&
          widgetInfo?.includes('Clinical call resolution')
          ? 'lg:col-span-2'
          : '',
      )}
    >
      <div className="flex flex-wrap items-center justify-between gap-x-2 gap-y-1 border-b border-light-light px-2 py-2 dark:border-dark-medium md:px-4">
        <h4 className="text-lg font-semibold">Clinical call resolution</h4>
      </div>
      <div className="relative flex grow flex-col gap-4 px-4 py-2">
        <>
          <div className="flex flex-wrap items-center justify-between gap-x-4 gap-y-1">
            <span className="text-sm font-semibold">{getDateRange()}</span>
            <div className="flex min-w-[250px] max-w-[350px] grow gap-2">
              <SearchableMultiSelectCheckbox
                options={groupedOptions}
                elementRef={selectRef}
              />
              <Button
                variant="secondary"
                size="small"
                onClick={() => setSelectedOptions([])}
              >
                <ArrowPathIcon className="h-4 w-4" />
              </Button>
            </div>
          </div>

          <div
            className={classNames(
              'flex w-full grow flex-wrap items-center justify-center gap-3 @container xl:justify-between',
              chartType !== 'call-data-chart' ? '' : 'mx-auto max-w-[550px]',
            )}
          >
            {isChartEmpty() ? (
              <div className="grid h-full w-full place-content-center">
                <EmptyState message="No information available" />
              </div>
            ) : (
              <>
                {chartType !== 'call-data-chart' ? (
                  <div className="flex min-w-max shrink grow basis-[max(250px,100%/4_+_0.1%)] flex-col items-center space-y-4 @[554px]:items-start">
                    <dl>
                      <div
                        className={classNames(
                          'flex items-baseline justify-center gap-2 @[554px]:justify-start',
                        )}
                      >
                        <dt className={classNames('font-semibold')}>
                          Total clinical calls:
                        </dt>
                        <dd className={classNames('font-semibold')}>
                          {clinicalCallResolution?.FacilitatedConnectionToHigherlevelCount +
                            clinicalCallResolution?.ResolvedByProtoCallCount}
                        </dd>
                      </div>
                      <div
                        className={classNames(
                          'flex items-baseline justify-center gap-2 text-gray-600 @[554px]:justify-start dark:text-neutral-400',
                        )}
                      >
                        <dt
                          className={classNames(
                            "text-sm before:me-1 before:inline-block before:h-3 before:w-3 before:rounded-full before:bg-primary-500 before:content-['']",
                          )}
                        >
                          Resolved by Protocall:
                        </dt>
                        <dd className={classNames('text-sm font-semibold')}>
                          {clinicalCallResolution?.ResolvedByProtoCallCount}
                        </dd>
                      </div>
                      <div
                        className={classNames(
                          'flex items-baseline justify-center gap-2 text-gray-600 @[554px]:justify-start dark:text-neutral-400',
                        )}
                      >
                        <dt
                          className={classNames(
                            "text-sm before:me-1 before:inline-block before:h-3 before:w-3 before:rounded-full before:bg-primary-200 before:content-['']",
                          )}
                        >
                          Facilitated connection to higher level:
                        </dt>
                        <dd className={classNames('text-sm font-semibold')}>
                          {
                            clinicalCallResolution?.FacilitatedConnectionToHigherlevelCount
                          }
                        </dd>
                      </div>
                    </dl>
                    <dl>
                      <div className={classNames('flex items-baseline gap-2')}>
                        <dt className={classNames('font-semibold')}>
                          Non clinical calls:
                        </dt>
                        <dd className={classNames('font-semibold')}>
                          {clinicalCallResolution?.NACount}
                        </dd>
                      </div>
                    </dl>
                    <dl>
                      <div
                        className={classNames(
                          'flex items-baseline gap-2 text-lg',
                        )}
                      >
                        <dt className={classNames('font-bold')}>
                          Total calls:
                        </dt>
                        <dd className={classNames('font-bold')}>
                          {clinicalCallResolution?.FacilitatedConnectionToHigherlevelCount +
                            clinicalCallResolution?.ResolvedByProtoCallCount +
                            clinicalCallResolution?.NACount}
                        </dd>
                      </div>
                    </dl>
                  </div>
                ) : (
                  <div className="flex min-w-max shrink grow basis-[max(250px,100%/4_+_0.1%)] flex-col items-center space-y-4 @[542px]:items-start">
                    <dl>
                      <div
                        className={classNames(
                          'flex items-baseline justify-center gap-2 @[542px]:justify-start',
                        )}
                      >
                        <dt className={classNames('font-semibold')}>
                          Total calls:
                        </dt>
                        <dd className={classNames('font-semibold')}>
                          {clinicalCallResolution?.ClinicalCallsCount +
                            clinicalCallResolution?.NonClinicalCallsCount}
                        </dd>
                      </div>
                      <div
                        className={classNames(
                          'flex items-baseline justify-center gap-2 text-gray-600 @[542px]:justify-start dark:text-neutral-400',
                        )}
                      >
                        <dt
                          className={classNames(
                            "text-sm before:me-1 before:inline-block before:h-3 before:w-3 before:rounded-full before:bg-primary-500 before:content-['']",
                          )}
                        >
                          Clinical calls:
                        </dt>
                        <dd className={classNames('text-sm font-semibold')}>
                          {clinicalCallResolution?.ClinicalCallsCount}
                        </dd>
                      </div>
                      <div
                        className={classNames(
                          'flex items-baseline justify-center gap-2 text-gray-600 @[542px]:justify-start dark:text-neutral-400',
                        )}
                      >
                        <dt
                          className={classNames(
                            "text-sm before:me-1 before:inline-block before:h-3 before:w-3 before:rounded-full before:bg-primary-200 before:content-['']",
                          )}
                        >
                          Non clinical calls:
                        </dt>
                        <dd className={classNames('text-sm font-semibold')}>
                          {clinicalCallResolution?.NonClinicalCallsCount}
                        </dd>
                      </div>
                    </dl>
                  </div>
                )}
                <div className="flex shrink grow basis-[max(280px,100%/4_+_0.1%)] items-center justify-center">
                  <div className="relative h-full w-full max-w-[280px]">
                    <DoughnutChart
                      labels={chartData?.labels ?? []}
                      values={chartData?.data ?? []}
                    />
                  </div>
                </div>
              </>
            )}
          </div>
        </>

        {isChartInfoLoading && (
          <div className="bg-gray-900/8 absolute inset-0 z-10 grid place-items-center backdrop-blur-sm">
            <Spinner />
          </div>
        )}
      </div>
    </div>
  );
}

export { ClinicalCallResolution };
