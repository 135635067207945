import { useInfiniteQuery, UseInfiniteQueryResult } from 'react-query';
import pDebounce from 'p-debounce';

import { useAuth } from 'src/hooks';

import { getUsersList } from 'src/utils/urls';
import { pageLength } from 'src/utils/appConstants';
import { setListRequestData } from '../utils/reqData';

import { UsersListType, FilterOptionsType } from '../types/usersListType';

export async function fetchGetUsersList({
  requestData,
  pageParam,
  token,
  tokenType,
}: TVariables): Promise<TPage> {
  requestData.append('start', `${pageParam}`);

  const response = await fetch(getUsersList, {
    method: 'POST',
    headers: {
      Authorization: `${tokenType} ${token}`,
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: requestData,
  });
  return response.json();
}

const debouncedFetch = pDebounce(fetchGetUsersList, 500);

export type TPage = {
  data: Array<UsersListType>;
  draw: number;
  error: Error | null;
  isAutoRefreshEnabled: boolean;
  recordsFiltered: number;
  recordsTotal: number;
};

export type TError = { message: string };

export type TVariables = {
  requestData: any;
  token: string;
  tokenType: string;
  pageParam: any;
};

function useGetUsersList(
  filterOptions: FilterOptionsType,
): UseInfiniteQueryResult<TPage, unknown> {
  const { token, tokenType } = useAuth();
  return useInfiniteQuery(
    ['get_users_list', filterOptions],
    ({ pageParam = 0 }) => {
      const requestData = setListRequestData(filterOptions);
      localStorage.setItem('userPageParam', pageParam);

      return debouncedFetch({
        token,
        tokenType,
        requestData,
        pageParam,
      });
    },
    {
      getNextPageParam: (lastPage: TPage, allPages: Array<TPage>) => {
        if (lastPage !== null && lastPage?.data?.length === pageLength)
          return allPages?.length * pageLength;
      },
      getPreviousPageParam: (firstPage: TPage, allPages: Array<TPage>) => {
        if (firstPage !== null && firstPage?.data?.length === pageLength)
          return allPages?.length - 1 * pageLength;
      },

      staleTime: 60 * 1000,
      refetchOnWindowFocus: false,
    },
  );
}

export { useGetUsersList };
