import React, { ReactElement, useState } from 'react';

import { ArrowLongRightIcon } from '@heroicons/react/20/solid';

import FeedbackForm from './components/FeedbackForm';

import { useAuth } from 'src/hooks';

export default function Footer(): ReactElement {
  const { isExternalUser } = useAuth();

  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <div className="flex items-center justify-center border-t border-light-light bg-white px-3 py-2 text-right shadow dark:border-dark-medium dark:bg-neutral-800 md:justify-end md:px-2 md:py-1">
        <a
          className="flex cursor-pointer items-center gap-0.5 text-xs font-bold text-sky-700 hover:underline active:translate-y-px dark:font-semibold dark:text-sky-400"
          onClick={() => {
            isExternalUser
              ? setIsOpen(true)
              : location.pathname === '/About'
              ? window.open(
                  `${process.env.REACT_APP_OLD_CP_URL}/Home${location.pathname}`,
                  '_self',
                )
              : window.open(
                  `${process.env.REACT_APP_OLD_CP_URL}${location.pathname}`,
                  '_self',
                );
          }}
        >
          <span>Go back to legacy version</span>
          <ArrowLongRightIcon className="h-4 w-4" />
        </a>
      </div>
      <FeedbackForm isOpen={isOpen} setIsOpen={setIsOpen} />
    </>
  );
}
