import React, {
  ReactElement,
  Fragment,
  useState,
  useMemo,
  useCallback,
  useRef,
  useEffect,
} from 'react';
import { useNavigate } from 'react-router';
import { Dialog, Transition } from '@headlessui/react';
import {
  XMarkIcon,
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
} from '@heroicons/react/20/solid';

import { Button, Checkbox, EmptyState } from 'src/ui/components';

import { useCallDocStore } from '../store/callDocStore';

import { CallDocumentsPreviewType } from '../types/callDocumentPreview';
import { CallDocumentType } from '../types/callDocumentList';

export type PreviewModalType = {
  showPreviewModal?: boolean;
  report: CallDocumentsPreviewType | undefined;
  onNextClickHandler?: () => void;
  onPreviousClickHandler?: () => void;
  callDocuments: Array<CallDocumentType> | undefined;
  onModalClose?: () => void;
  isDocPreviewLoading: boolean;
  setDocumentPreviewModal?: (
    value: boolean | ((prevState: boolean) => boolean),
  ) => void;
  onDocumentPreview?: () => Promise<any>;
  setPreviewCallId?: any;
  changeReadStatus?: any;
  setSelectedDocs?: (
    value:
      | Array<CallDocumentType>
      | ((prevState: Array<CallDocumentType>) => Array<CallDocumentType>),
  ) => void;
  reqCallDocId?: string | undefined | null;
};

function PrintPreview({
  showPreviewModal,
  report,
  onNextClickHandler,
  onPreviousClickHandler,
  callDocuments,
  onModalClose,
  isDocPreviewLoading,
  setDocumentPreviewModal,
  onDocumentPreview,
  setPreviewCallId,
  changeReadStatus,
  setSelectedDocs,
  reqCallDocId,
}: PreviewModalType): ReactElement {
  const navigate = useNavigate();

  const [showstickyNotes, setShowstickyNote] = useState<boolean>(false);

  const closBtnRef = useRef<any>(null);

  const isPrevCallDocAvailable = useCallDocStore(
    useCallback(state => state.isPrevCallDocAvailable, []),
  );

  const isNextCallDocAvailable = useCallDocStore(
    useCallback(state => state.isNextCallDocAvailable, []),
  );

  const isFromEmail = useCallDocStore(
    useCallback(state => state.isFromEmail, []),
  );

  const showDocPreviewModal = useCallDocStore(
    useCallback(state => state.showDocPreviewModal, []),
  );

  const setShowDocPreviewModal = useCallDocStore(
    useCallback(state => state.setShowDocPreviewModal, []),
  );

  const setNavigationDir = useCallDocStore(
    useCallback(state => state.setNavigationDir, []),
  );

  const setIsFromEmail = useCallDocStore(
    useCallback(state => state.setIsFromEmail, []),
  );

  const dataUri = useMemo(() => {
    if (report?.Content) {
      return `data:text/html,${encodeURIComponent(report?.Content)}`;
    } else return '';
  }, [report?.Content]);

  function hasStickyNotes() {
    return (
      callDocuments?.find(x => x.Id === report?.CallId)?.StickyNote === null
    );
  }

  function onPrintReport() {
    if (report?.Content) {
      let content = report?.Content;
      if (!showstickyNotes) {
        const parser = new DOMParser();
        const doc = parser.parseFromString(report?.Content, 'text/html');
        if (doc && doc.getElementById('StickyNote')) {
          doc.getElementById('StickyNote')?.remove();
        }
        content = doc.documentElement.innerHTML;
      }
      const printWindow = window.open('height=800,width=1090', '_blank');
      printWindow?.document.write(content);
      printWindow?.focus();
      printWindow?.print();
    }
  }

  /* to update the status of documents to "read" when the doc is accessed from an email*/
  useEffect(() => {
    if (isFromEmail && report) {
      const callIds = [
        {
          Id: report?.CallId,
          IsRead: true,
        },
      ];

      changeReadStatus && changeReadStatus(callIds);
    }
  }, [changeReadStatus, isFromEmail, report]);

  function handleClose() {
    setShowDocPreviewModal && setShowDocPreviewModal(false);
    setDocumentPreviewModal && setDocumentPreviewModal(false);
    setPreviewCallId && setPreviewCallId(undefined);
    setSelectedDocs && setSelectedDocs([]);
    if (reqCallDocId) navigate('/CallDocuments');
  }

  return (
    <div>
      <Transition
        appear
        show={isFromEmail ? showDocPreviewModal : showPreviewModal}
        as={Fragment}
      >
        <Dialog
          as="div"
          initialFocus={closBtnRef}
          className="pr-dialog"
          onClose={() => {
            handleClose();
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="pr-dialog__backdrop" />
          </Transition.Child>

          <div className="pr-dialog__wrapper">
            <div className="pr-dialog__panel__container sm:!grid-rows-[1fr_auto_1fr]">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel
                  style={{ height: 'calc(100svh - 90px)' }}
                  className="pr-dialog__panel flex flex-col sm:!max-w-5xl"
                >
                  <div className="flex items-center gap-3 border-b px-4 py-2 dark:border-dark-medium">
                    <Dialog.Title
                      as="h3"
                      className="grow truncate text-lg font-medium leading-6 text-gray-900 dark:text-white"
                    >
                      {report?.FileName}
                    </Dialog.Title>
                    <div className="flex gap-1">
                      {localStorage.getItem('isFromDashboard') !== 'true' && (
                        <>
                          <div className="relative">
                            {!isPrevCallDocAvailable && (
                              <span
                                className="absolute inset-0"
                                title="No previous call document found."
                              ></span>
                            )}
                            <Button
                              variant="subtle"
                              title="Previous document"
                              onClick={() => {
                                setShowstickyNote(false);
                                if (isFromEmail) {
                                  setNavigationDir('prev');
                                  setTimeout(() => {
                                    onDocumentPreview &&
                                      onDocumentPreview().then(data => {
                                        setPreviewCallId(data.data.CallId);
                                      });
                                  });
                                } else {
                                  onPreviousClickHandler &&
                                    onPreviousClickHandler();
                                }
                              }}
                              disabled={
                                isDocPreviewLoading || !isPrevCallDocAvailable
                              }
                            >
                              <ChevronDoubleLeftIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                              <span className="sr-only">Previous</span>
                            </Button>
                          </div>
                          <div className="relative">
                            {!isNextCallDocAvailable && (
                              <span
                                className="absolute inset-0"
                                title="No next call document found."
                              ></span>
                            )}
                            <Button
                              variant="subtle"
                              title="Next document"
                              onClick={() => {
                                setShowstickyNote(false);
                                if (isFromEmail) {
                                  setNavigationDir('next');
                                  setTimeout(() => {
                                    onDocumentPreview &&
                                      onDocumentPreview().then(data => {
                                        setPreviewCallId(data.data.CallId);
                                      });
                                  });
                                } else {
                                  onNextClickHandler && onNextClickHandler();
                                }
                              }}
                              disabled={
                                isDocPreviewLoading || !isNextCallDocAvailable
                              }
                            >
                              <ChevronDoubleRightIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                              <span className="sr-only">Next</span>
                            </Button>
                          </div>
                        </>
                      )}
                    </div>
                    <button
                      type="button"
                      className=" text-gray-400 hover:text-gray-500 sm:right-4 sm:top-4"
                      onClick={() => {
                        if (isFromEmail) {
                          setShowDocPreviewModal(false);
                          setIsFromEmail(false);
                          setPreviewCallId && setPreviewCallId(undefined);
                          setSelectedDocs && setSelectedDocs([]);
                          localStorage.setItem('isFromDashboard', 'false');
                          if (reqCallDocId) navigate('/CallDocuments');
                        } else {
                          onModalClose && onModalClose();
                        }
                      }}
                      ref={closBtnRef}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div className="relative min-h-0 grow overflow-auto scroll-smooth">
                    {dataUri === '' ? (
                      <div className="absolute inset-0 grid place-content-center">
                        <EmptyState message="No information available" />
                      </div>
                    ) : (
                      <iframe
                        title="Dynamic HTML Content"
                        src={dataUri}
                        width="100%"
                        height="100%"
                      />
                    )}
                  </div>

                  <div className="flex flex-col gap-[1ch] border-t bg-gray-50 p-2 px-4 dark:border-dark-medium dark:bg-neutral-700/50 sm:flex-row sm:items-center sm:justify-between">
                    <Checkbox
                      id="addStickyNotes"
                      label="Would you like Call Document(s) to include sticky notes?"
                      checked={showstickyNotes}
                      className="py-3 sm:py-0"
                      onChange={() =>
                        setShowstickyNote(prevState => !prevState)
                      }
                      disabled={hasStickyNotes() && !isDocPreviewLoading}
                    />
                    <div className="flex gap-[1ch]">
                      <Button
                        variant="primary"
                        onClick={() => onPrintReport()}
                        className="w-full grow sm:w-auto sm:grow-0"
                        disabled={isDocPreviewLoading}
                      >
                        Print report
                      </Button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
}

export { PrintPreview };
