import { useMutation, UseMutationResult } from 'react-query';

import { useAuth } from 'src/hooks';

import { resetPassword } from 'src/utils/urls';

import { ResetPasswordType } from '../types/apiTypes';

export type ValidationError = { Message?: string };

export type TResult = any;

export type TArguments = ResetPasswordType;

export type TVariable = ResetPasswordType;

export type TError = any;

export async function setPassword({
  id,
  isNewUserOnBoarding,
  code,
  password,
}: TArguments): Promise<TResult> {
  const data: any = new URLSearchParams();
  data.append('', password);
  const response = await fetch(resetPassword(id, code, isNewUserOnBoarding), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    },
    body: data,
  });
  return response;
}

function useResetPassword(): UseMutationResult<
  TResult,
  TError,
  TVariable,
  unknown
> {
  const { token, tokenType } = useAuth();
  return useMutation(
    async ({ id, isNewUserOnBoarding, code, password }: TVariable) => {
      const result = await setPassword({
        id,
        isNewUserOnBoarding,
        code,
        password,
      });
      if (result.status === 400) {
        const responseBody = await result.json();
        throw new Error(responseBody[0]);
      }
      if (result.status === 500) {
        const responseBody = await result.json();
        throw new Error(responseBody.Message);
      }
      return result;
    },
  );
}

export { useResetPassword };
