import { useQuery, UseQueryResult } from 'react-query';

import { useAuth } from './useAuth';
import { getUserPermissions } from 'src/utils/urls';

async function fetchUserPermission({
  token,
  tokenType,
}: TVaribles): Promise<TResult> {
  const response = await fetch(getUserPermissions, {
    method: 'GET',
    headers: {
      Authorization: `${tokenType} ${token}`,
    },
  });
  return response.json();
}

export type TResult = Array<string>;
export type TError = { message: string };
export type TVaribles = {
  token: string;
  tokenType: string;
};

function useGetUserPermissions(
  isOnboarding: boolean,
): UseQueryResult<TResult, TError> {
  const { token, tokenType } = useAuth();
  return useQuery(
    'get_user_permissions',
    async () => {
      const result = await fetchUserPermission({ token, tokenType });
      return result;
    },
    {
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      enabled: !isOnboarding,
    },
  );
}

export { useGetUserPermissions };
