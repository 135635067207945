import { trimValues } from 'src/utils/common';
import {
  getLocLabel,
  getCallTypeLabel,
  getTagLabel,
} from '../callDocumentHelperFunctions';
import {
  getEndTimezoneOffset,
  getStartTimezoneOffset,
} from 'src/utils/dayLightSavingsHelper';

import { CallType } from '../../types/callDocumentList';
import { CallDocumentFilterType } from '../../types/filterOptionsType';

export function setCallDocDownloadCsvRequestData(
  filterOptions: CallDocumentFilterType,
  callTypeDescription: Array<CallType> | undefined,
): FormData {
  const formData: any = new URLSearchParams();

  formData.append('draw', '1');
  formData.append(
    'timezoneOffset',
    `${getStartTimezoneOffset(filterOptions?.startDate)}`,
  );
  formData.append('columns[0][data]', 'Id');
  formData.append('columns[0][name]', 'Id');
  formData.append('columns[0][searchable]', 'true');
  formData.append('columns[0][orderable]', 'true');
  formData.append('columns[0][search][regex]', 'false');
  formData.append('columns[0][search][ApplyExactMatch]', ''),
    formData.append(
      'columns[0][search][value]',
      filterOptions && filterOptions.callId
        ? trimValues(filterOptions.callId)
        : '',
    );

  formData.append('columns[1][data]', 'CaseId');
  formData.append('columns[1][name]', '');
  formData.append('columns[1][searchable]', 'true');
  formData.append('columns[1][orderable]', 'true');
  formData.append('columns[1][search][regex]', 'false');
  formData.append('columns[1][search][ApplyExactMatch]', ''),
    formData.append(
      'columns[1][search][value]',
      filterOptions && filterOptions.caseId
        ? trimValues(filterOptions.caseId)
        : '',
    );

  formData.append('columns[2][data]', 'Started');
  formData.append('columns[2][name]', '');
  formData.append('columns[2][searchable]', 'true');
  formData.append('columns[2][orderable]', 'true');
  formData.append('columns[2][search][regex]', 'false');
  formData.append('columns[2][search][ApplyExactMatch]', ''),
    formData.append(
      'columns[2][search][value]',
      filterOptions && filterOptions.startDate
        ? trimValues(filterOptions.startDate)
        : '',
    );
  formData.append(
    'columns[2][StartTimezoneOffset]',
    `${getStartTimezoneOffset(filterOptions?.startDate)}`,
  );
  formData.append(
    'columns[2][EndTimezoneOffset]',
    `${getEndTimezoneOffset(filterOptions?.startDate)}`,
  );

  formData.append('columns[3][data]', 'Finalized');
  formData.append('columns[3][name]', '');
  formData.append('columns[3][searchable]', 'true');
  formData.append('columns[3][orderable]', 'true');
  formData.append('columns[3][search][regex]', 'false');
  formData.append('columns[3][search][ApplyExactMatch]', ''),
    formData.append(
      'columns[3][search][value]',
      filterOptions && filterOptions.uploadedDate
        ? trimValues(filterOptions.uploadedDate)
        : '',
    );
  formData.append(
    'columns[3][StartTimezoneOffset]',
    `${getStartTimezoneOffset(filterOptions?.uploadedDate)}`,
  );
  formData.append(
    'columns[3][EndTimezoneOffset]',
    `${getEndTimezoneOffset(filterOptions?.uploadedDate)}`,
  );

  formData.append('columns[4][data]', 'CID');
  formData.append('columns[4][name]', '');
  formData.append('columns[4][searchable]', 'true');
  formData.append('columns[4][orderable]', 'true');
  formData.append('columns[4][search][regex]', 'false');
  formData.append('columns[4][search][ApplyExactMatch]', ''),
    formData.append(
      'columns[4][search][value]',
      filterOptions && filterOptions.cid ? trimValues(filterOptions.cid) : '',
    );

  formData.append('columns[5][data]', 'PID');
  formData.append('columns[5][name]', '');
  formData.append('columns[5][searchable]', 'true');
  formData.append('columns[5][orderable]', 'true');
  formData.append('columns[5][search][regex]', 'false');
  formData.append('columns[5][search][ApplyExactMatch]', ''),
    formData.append(
      'columns[5][search][value]',
      filterOptions && filterOptions.pid ? trimValues(filterOptions.pid) : '',
    );

  formData.append('columns[6][data]', 'CallerFirstName');
  formData.append('columns[6][name]', '');
  formData.append('columns[6][searchable]', 'true');
  formData.append('columns[6][orderable]', 'true');
  formData.append('columns[6][search][regex]', 'false');
  formData.append('columns[6][search][ApplyExactMatch]', ''),
    formData.append(
      'columns[6][search][value]',
      filterOptions && filterOptions.callerFirstName
        ? trimValues(filterOptions.callerFirstName)
        : '',
    );

  formData.append('columns[7][data]', 'CallerLastName');
  formData.append('columns[7][name]', '');
  formData.append('columns[7][searchable]', 'true');
  formData.append('columns[7][orderable]', 'true');
  formData.append('columns[7][search][regex]', 'false');
  formData.append('columns[7][search][ApplyExactMatch]', ''),
    formData.append(
      'columns[7][search][value]',
      filterOptions && filterOptions.callerLastName
        ? trimValues(filterOptions.callerLastName)
        : '',
    );

  formData.append('columns[8][data]', 'PersonOfConcernFirstName');
  formData.append('columns[8][name]', '');
  formData.append('columns[8][searchable]', 'true');
  formData.append('columns[8][orderable]', 'true');
  formData.append('columns[8][search][regex]', 'false');
  formData.append('columns[8][search][ApplyExactMatch]', ''),
    formData.append(
      'columns[8][search][value]',
      filterOptions && filterOptions.pocFirstName
        ? trimValues(filterOptions.pocFirstName)
        : '',
    );

  formData.append('columns[9][data]', 'PersonOfConcernLastName');
  formData.append('columns[9][name]', '');
  formData.append('columns[9][searchable]', 'true');
  formData.append('columns[9][orderable]', 'true');
  formData.append('columns[9][search][regex]', 'false');
  formData.append('columns[9][search][ApplyExactMatch]', ''),
    formData.append(
      'columns[9][search][value]',
      filterOptions && filterOptions.pocLastName
        ? trimValues(filterOptions.pocLastName)
        : '',
    );

  formData.append('columns[10][data]', 'CallType');
  formData.append('columns[10][name]', '');
  formData.append('columns[10][searchable]', 'true');
  formData.append('columns[10][orderable]', 'true');
  formData.append('columns[10][search][regex]', 'false');
  formData.append('columns[10][search][ApplyExactMatch]', ''),
    formData.append(
      'columns[10][search][value]',
      filterOptions && filterOptions.callType
        ? getCallTypeLabel(filterOptions?.callType, callTypeDescription)
        : '',
    );

  formData.append('columns[11][data]', 'LevelOfCare');
  formData.append('columns[11][name]', '');
  formData.append('columns[11][searchable]', 'true');
  formData.append('columns[11][orderable]', 'true');
  formData.append('columns[11][search][regex]', 'false');
  formData.append('columns[11][search][ApplyExactMatch]', ''),
    formData.append(
      'columns[11][search][value]',
      filterOptions && filterOptions.levelOfCare
        ? getLocLabel(filterOptions.levelOfCare)
        : '',
    );

  formData.append('columns[12][data]', 'ClinicalOutcome');
  formData.append('columns[12][name]', '');
  formData.append('columns[12][searchable]', 'true');
  formData.append('columns[12][orderable]', 'true');
  formData.append('columns[12][search][regex]', 'false');
  formData.append('columns[12][search][ApplyExactMatch]', ''),
    formData.append(
      'columns[12][search][value]',
      filterOptions && filterOptions.clinicalOutcome
        ? trimValues(filterOptions.clinicalOutcome)
        : '',
    );

  formData.append('columns[13][data]', 'TagId');
  formData.append('columns[13][name]', '');
  formData.append('columns[13][searchable]', 'true');
  formData.append('columns[13][orderable]', 'true');
  formData.append('columns[13][search][regex]', 'false');
  formData.append('columns[13][search][ApplyExactMatch]', ''),
    formData.append(
      'columns[13][search][value]',
      filterOptions && filterOptions.tagStatus
        ? getTagLabel(filterOptions?.tagStatus)
        : '',
    );

  formData.append('columns[14][data]', 'StickyNote');
  formData.append('columns[14][name]', '');
  formData.append('columns[14][searchable]', 'true');
  formData.append('columns[14][orderable]', 'true');
  formData.append('columns[14][search][regex]', 'false');
  formData.append('columns[14][search][ApplyExactMatch]', ''),
    formData.append(
      'columns[14][search][value]',
      filterOptions && filterOptions.stickyNotes
        ? trimValues(filterOptions.stickyNotes)
        : '',
    );

  formData.append('order[0][column]', filterOptions.sortType);
  formData.append(
    'order[0][dir]',
    filterOptions.sortOrder ? filterOptions.sortOrder : 'desc',
  );

  formData.append(
    'search[value]',
    `${trimValues(filterOptions.keywordSearch)}`,
  );
  formData.append('search[regex]', 'false');

  return formData;
}
