import React, { ReactElement, useCallback } from 'react';
import { ShieldCheckIcon } from '@heroicons/react/20/solid';

import { Alert, Button, TextField } from 'src/ui/components';
import { ConfirmAuthType } from './ConfirmAuthType';
import { QrCodeGenerator } from './QrCodeGenerator';
import { radioBaseClasses } from 'src/ui/components/RadioButton';

import {
  useEmailAsTwofactorAuth,
  useGetQrCode,
  useVerifyGoogleAuth,
} from 'src/hooks';

import { userOnBoardingStore } from 'src/store/userOnboardingStore';
import {
  authenticatorInfo,
  links,
  twoFactorHeaderText,
} from '../../utils/onBoardingConstants';
import { classNames } from 'src/utils/className';

import { QrCodeType } from '../../types/apiTypes';

export function TwoFAuth(): ReactElement {
  const twofactorAuthType = userOnBoardingStore(
    useCallback(state => state.twofactorAuthType, []),
  );

  const setUpdateStore = userOnBoardingStore(
    useCallback(state => state.setUpdateStore, []),
  );

  const showAuthConfirmation = userOnBoardingStore(
    useCallback(state => state.showAuthConfirmation, []),
  );

  const showQrCode = userOnBoardingStore(
    useCallback(state => state.showQrCode, []),
  );

  const setQrCode = userOnBoardingStore(
    useCallback(state => state.setQrCode, []),
  );

  const showQrCodeKeySection = userOnBoardingStore(
    useCallback(state => state.showQrCodeKeySection, []),
  );

  const googleAuthCode = userOnBoardingStore(
    useCallback(state => state.googleAuthCode, []),
  );

  const showInvalidQrCodeError = userOnBoardingStore(
    useCallback(state => state.showInvalidQrCodeError, []),
  );

  const userDetails = userOnBoardingStore(
    useCallback(state => state.userDetails, []),
  );

  const { mutate: setEmailAsTwoFactorAuth, isLoading: isSetEmailLoading } =
    useEmailAsTwofactorAuth();

  const { mutate: getQRCode, isLoading: isLoadingQrCode } = useGetQrCode();

  const { mutate: verifyAuthCode, isLoading: isLoadingVerifyAuthcode } =
    useVerifyGoogleAuth();

  const isLoading =
    isLoadingQrCode || isLoadingVerifyAuthcode || isSetEmailLoading;

  function renderRadioBtn(value: string): ReactElement {
    return (
      <input
        type="radio"
        id="authType-email"
        name="authType"
        className={classNames(
          'mt-0.5 self-start justify-self-center',
          radioBaseClasses,
        )}
        value={value}
        checked={twofactorAuthType === value}
        onChange={() => setUpdateStore('twofactorAuthType', value)}
      />
    );
  }

  function twoFactorHeader(): ReactElement {
    return (
      <div className="space-y-1">
        <div
          className="mb-2 flex h-10 w-10 items-center justify-center rounded border-primary-600 bg-primary-100/50 p-2 dark:bg-primary-600/20"
          aria-hidden="true"
        >
          <ShieldCheckIcon className="dark:text-primary-2300 h-5 w-5 text-primary-500" />
        </div>
        <h2 className="text-xl font-medium md:text-2xl">
          Two-factor authentication
        </h2>
        <p className="text-xs text-gray-500 dark:text-neutral-400 md:text-sm">
          {twoFactorHeaderText}
        </p>
      </div>
    );
  }

  function externalLink(): ReactElement {
    return (
      <>
        {links.map(item => (
          <li key={item.title}>
            {item.title} (
            <a
              href={item.playStoreLink}
              className="text-sky-700 after:content-['_↗'] dark:text-sky-400"
              target="_blank"
              rel="noreferrer"
            >
              PlayStore
            </a>{' '}
            or{' '}
            <a
              href={item.appStoreLink}
              className="text-sky-700 after:content-['_↗'] dark:text-sky-400"
              target="_blank"
              rel="noreferrer"
            >
              AppStore
            </a>
            )
          </li>
        ))}
      </>
    );
  }

  function onContinueHandler() {
    if (twofactorAuthType === 'email' && !showAuthConfirmation) {
      setEmailAsTwoFactorAuth(
        { userName: userDetails?.UserName },
        {
          onSuccess: () => {
            setUpdateStore('showAuthConfirmation', true);
          },
          onError: () => {},
        },
      );
    } else {
      if (!showQrCode && !showQrCodeKeySection && !showAuthConfirmation) {
        //generate qr code
        getQRCode(
          { userName: userDetails?.UserName },
          {
            onSuccess: (code: QrCodeType) => {
              setQrCode({
                authenticatorUri: code.authenticatorUri,
                sharedKey: code.sharedKey,
              });
              setUpdateStore('showQrCode', true);
            },
            onError: () => {},
          },
        );
      } else if (showQrCode) {
        setUpdateStore('showQrCode', false);
        setUpdateStore('showQrCodeKeySection', true);
      } else if (!showQrCode && showQrCodeKeySection && !showAuthConfirmation) {
        // verify auth code
        verifyAuthCode(
          {
            userName: userDetails?.UserName,
            googleAuthCode,
          },
          {
            onSuccess: response => {
              if (response) {
                setUpdateStore('showQrCodeKeySection', false);
                setUpdateStore('showAuthConfirmation', true);
              } else {
                setUpdateStore('showInvalidQrCodeError', true);
              }
            },
            onError: () => {},
          },
        );
      }
    }
  }

  function onCancelHandler() {
    if (showQrCode) {
      setUpdateStore('showQrCode', false);
    }
    if (showQrCodeKeySection) {
      setUpdateStore('showQrCodeKeySection', false);
      setUpdateStore('showQrCode', true);
      setUpdateStore('googleAuthCode', '');
      setUpdateStore('showInvalidQrCodeError', false);
    }
  }

  return (
    <div className="container mx-auto grid max-w-2xl place-items-center gap-y-8 p-4 md:h-full md:p-6">
      <div className="space-y-4">
        {twoFactorHeader()}
        {showQrCode && <QrCodeGenerator />}

        {showAuthConfirmation && <ConfirmAuthType />}

        {!showAuthConfirmation && !showQrCode && !showQrCodeKeySection && (
          <fieldset className="grid shrink-0 gap-px overflow-hidden rounded-lg border border-gray-300 bg-gray-200 shadow transition-shadow duration-300 dark:border-neutral-700 dark:bg-neutral-700">
            <div className="grid grid-cols-[theme(space.8)_1fr] gap-4 bg-white px-4 py-4 focus-within:bg-gray-100 dark:bg-neutral-800 dark:focus-within:bg-neutral-700">
              {renderRadioBtn('email')}
              <div className="grid gap-1">
                <label
                  htmlFor="authType-email"
                  className="contents leading-none"
                >
                  <h3 className="font-medium">
                    Email{' '}
                    <small className="font-normal text-gray-500 dark:text-neutral-400">
                      (Default)
                    </small>
                  </h3>
                  <small className="text-sm text-gray-500 dark:text-neutral-400">
                    Verification codes will be sent to{' '}
                    <strong>{userDetails?.Email}</strong>
                  </small>
                </label>
              </div>
            </div>
            <div className="grid grid-cols-[theme(space.8)_1fr] gap-4 bg-white px-4 py-4 focus-within:bg-gray-100 dark:bg-neutral-800 dark:focus-within:bg-neutral-700">
              {renderRadioBtn('authenticator')}
              <div className="grid gap-1">
                <label
                  htmlFor="authType-app"
                  className="contents space-y-1 leading-none"
                >
                  <h3 className="font-medium">Authenticator app </h3>
                  <small className="text-sm text-gray-500 dark:text-neutral-400">
                    {authenticatorInfo}
                  </small>
                  <ul className="ml-8 text-sm text-gray-500 dark:text-neutral-400">
                    {externalLink()}
                  </ul>
                </label>
              </div>
            </div>
          </fieldset>
        )}
        <div className="space-y-2">
          {showQrCodeKeySection && (
            <>
              <TextField
                id="2fa-id"
                label="Enter 6-digit code from your Authenticator to verify"
                inputSize="large"
                maxLength={6}
                value={googleAuthCode}
                onChange={e => setUpdateStore('googleAuthCode', e.target.value)}
              />
              {showInvalidQrCodeError && (
                <Alert variant="danger" title="" className="text-left text-sm">
                  Invalid Authenticator Code
                </Alert>
              )}
            </>
          )}

          <div className="flex justify-end gap-3 p-2">
            {(showQrCode || showQrCodeKeySection) && (
              <Button
                variant="subtle"
                size="small"
                onClick={() => onCancelHandler()}
              >
                Cancel
              </Button>
            )}
            {!showAuthConfirmation && (
              <Button
                size="small"
                onClick={() => {
                  onContinueHandler();
                }}
                disabled={
                  (showQrCodeKeySection ? googleAuthCode.length < 6 : false) ||
                  isLoading
                }
              >
                Continue
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
