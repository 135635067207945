import { convertToNumber } from 'src/features/AddEditPersonAlert/utils/personStoreHelper';

import { formatToUtcWithOffset } from 'src/utils/datepickerHelper';
import { form1AccountId, form2AccountId } from './activityTrackerConstants';

import { TReturnType } from '../types/scheduleCallType';

//get the formname
function getFormName(accountId: number | undefined) {
  if (accountId === parseInt(form1AccountId)) {
    return 'Adolescent Acute Care Clinical Placement';
  } else if (accountId === parseInt(form2AccountId)) {
    return 'Hospital Followup';
  } else 'All';
}

//class name for badge
function varientName(status: string | undefined) {
  switch (status) {
    case 'Cancelled':
      return 'danger';
    case 'Completed':
      return 'success';
    case 'Scheduled':
      return 'warning';
    case 'Pending':
      return 'info';
  }
}

function validationMsg(value: any, key: string) {
  if (
    value?.hasOwnProperty(key) &&
    value[key] !== '' &&
    value[key] !== '-1' &&
    value[key] !== null
  ) {
    return true;
  } else if (key === 'caseId') {
    if (
      value?.linkExistingCase === true &&
      (value?.caseId === undefined || value?.caseId === '')
    ) {
      return false;
    }
    return true;
  } else return false;
}

//for formatting scheduleCall
function formatScheduleCall(
  schedulerCallDetails: any,
  clinicianList: TReturnType,
) {
  const dateValue = formatToUtcWithOffset(schedulerCallDetails?.scheduledTime);
  const formattedvalue = {
    AdditionalInfo: schedulerCallDetails?.additionalInfo
      ? schedulerCallDetails?.additionalInfo
      : '',
    AssignToAnyone: schedulerCallDetails?.assignedToAnyone
      ? schedulerCallDetails?.assignedToAnyone
      : false,
    AssignedGroupId:
      schedulerCallDetails?.AssignedGroupId &&
      schedulerCallDetails?.assignedToAnyone === true &&
      schedulerCallDetails?.AssignedGroupId !== '-1'
        ? convertToNumber(schedulerCallDetails?.AssignedGroupId)
        : null,
    AssignedTo: formatAssignedToParam(schedulerCallDetails, clinicianList),
    AttemptsToContact: schedulerCallDetails?.attempts
      ? schedulerCallDetails?.attempts
      : '',
    CallId: null,
    CallsCount: null,
    CancelNote: '',
    CaseId: schedulerCallDetails?.caseId ? schedulerCallDetails?.caseId : null,
    EndDate: dateValue && dateValue[1],
    FormId: schedulerCallDetails?.Id ? schedulerCallDetails?.Id : '',
    Id: 0,
    IsChecked: false,
    ModeOfCreation: 'Hospital Form',
    Person: formattedPerson(schedulerCallDetails),
    PersonId: schedulerCallDetails?.PersonOfConcernId,
    PhoneNumber: schedulerCallDetails?.phoneNumber
      ? schedulerCallDetails?.phoneNumber
      : null,
    PreferredPhone: 'Primary',
    StartDate: dateValue && dateValue[0],
    Status: 'Pending',
    SupervisorsEmail: formatSupervisorsName(schedulerCallDetails),
    TimeInterval: schedulerCallDetails?.scheduledTime,
    TimeZoneId: schedulerCallDetails?.timezone
      ? convertToNumber(schedulerCallDetails?.timezone)
      : null,
  };

  return formattedvalue;
}
function formattedPerson(schedulerCallDetails: any) {
  const val = {
    ...schedulerCallDetails?.PersonOfConcern,

    Account: null,
    PrimaryPhone: {
      ...schedulerCallDetails?.PersonOfConcern?.PrimaryPhone,
      PhoneType: null,
      OkToLeaveMessage:
        schedulerCallDetails?.leaveMessage === 1 ||
        schedulerCallDetails?.leaveMessage === '1'
          ? true
          : schedulerCallDetails?.leaveMessage === '2' ||
            schedulerCallDetails?.leaveMessage === 2
          ? false
          : undefined,
      Comments: schedulerCallDetails?.comments,
      CountryId: schedulerCallDetails?.countryId ?? 1,
      Number: schedulerCallDetails?.phoneNumber,
    },
  };
  return val;
}
function formatAssignedToParam(
  schedulerCallDetails: any,
  clinicianList: TReturnType,
) {
  const value =
    (schedulerCallDetails?.AssignedTo &&
      schedulerCallDetails?.assignedToAnyone === false) ||
    !schedulerCallDetails.hasOwnProperty('assignedToAnyone')
      ? clinicianList?.find(
          (item: any) =>
            item?.value?.toString() === schedulerCallDetails?.AssignedTo,
        )?.label
      : '';
  return value;
}
function formatSupervisorsName(schedulerCallDetails: any) {
  const value = schedulerCallDetails?.calltaker
    ? schedulerCallDetails?.calltaker?.map(
        (item: { value: number; label: string }) => {
          return item?.label;
        },
      )
    : [];
  return value;
}

export { getFormName, varientName, formatScheduleCall, validationMsg };
