import moment from 'moment';
import dayjs, { utc } from 'dayjs';
import duration from 'dayjs/plugin/duration';

import { dateFormat, timeFormat } from './coverageRequestConstants';
import {
  isNullOrUndefined,
  isTinymceEditorContentEmpty,
} from 'src/utils/common';
import { getCurrentDateTimeWithOffset } from 'src/utils/datepickerHelper';

dayjs.extend(duration);

// to convert time in 12 hr format
function convertTo12HourFormat(time: any) {
  const dummyDate = '2000-01-01 ';
  const formattedTime = `${dummyDate}${time}`;
  const timeObj = moment(formattedTime, 'YYYY-MM-DD HH:mm:ss');
  return timeObj.format(timeFormat);
}

// to check end time is less than start time
function isEndTimeAfterStartTime(startTime: any, endTime: any) {
  return moment(endTime, timeFormat).isBefore(moment(startTime, timeFormat));
}

//check if all values are present
function hasAllValuesPresent(
  obj: Record<string, any>,
  isProtocallUser: boolean,
): boolean {
  const obj1: Record<string, any> = { ...obj };
  let startTime = obj1?.StartTime;
  let endTime = obj1?.EndTime;
  let timeZoneId = obj1?.TimeZoneId;
  let extraCoverageDate = obj1?.ExtraCoverageDate;
  let requestType = obj1?.ExtraCoverageRequestTypeId;

  if (
    startTime === undefined ||
    startTime === '' ||
    startTime === null ||
    endTime === undefined ||
    endTime === '' ||
    endTime === null ||
    timeZoneId === null ||
    timeZoneId === '-1' ||
    timeZoneId === undefined ||
    timeZoneId === -1 ||
    extraCoverageDate === undefined ||
    extraCoverageDate === '' ||
    extraCoverageDate === null ||
    (isProtocallUser === true &&
      obj1?.ExtraCoverageRequestStatusId == '2' &&
      (requestType === null || requestType === '-1'))
  ) {
    return true;
  }
  return false;
}

//check if reason is submitted or not
function requestDetailsValidation(obj: Record<string, any>): boolean {
  const obj1: Record<string, any> = { ...obj };
  if (
    obj1.hasOwnProperty('ReasonForCoverage') &&
    obj1.hasOwnProperty('SpecialProcedures')
  ) {
    const isFieldEmpty: boolean =
      isTinymceEditorContentEmpty(obj1['ReasonForCoverage']) ||
      obj1['ReasonForCoverage'] === undefined ||
      obj1['SpecialProcedures'] === undefined ||
      (obj1['SpecialProcedures'] === true &&
        isTinymceEditorContentEmpty(obj1['SpecialProcedureMemo']?.trim()));

    if (isFieldEmpty) return true;
    else return false;
  }
  return true;
}

function timeDifferenceInHours(createdOn: any, time: any, utcOffset: any) {
  const timestamp1 = createdOn.concat(' ').concat(time);
  const timestamp2 = getCurrentDateTimeWithOffset(utcOffset);

  const date1 = dayjs(timestamp1, 'MM/DD/YYYY HH:mm');
  const date2 = dayjs(timestamp2, 'MM/DD/YYYY HH:mm');
  const diffMilliseconds = date1.diff(date2);
  const diffHours = diffMilliseconds / (1000 * 60 * 60);

  return diffHours;
}

function isPastDate(createdOn: string): boolean {
  return (
    Date.parse(createdOn) <
    Date.parse(dayjs().startOf('day').format(dateFormat))
  );
}

function editorContent(
  reason: string,
  comments: string,
  duration: string,
  isDefault: boolean,
  procedure: string,
): string {
  return ` <p>
          <strong style="pointer-event: none;" contenteditable="false">EXTRA COVERAGE ALERT:</strong>
          </p>
          <p>
            <strong style="pointer-event: none;" contenteditable="false">REASON:</strong>${reason.replaceAll(
              '<p style="pointer-event: none;" contenteditable="false">&nbsp;</p>',
              '',
            )}
          </p>
          <p style="pointer-event: none;" contenteditable="false">
            <strong >DURATION:</strong> ${duration}
          </p>
          ${
            isDefault && procedure !== null && procedure !== ''
              ? `<p style="pointer-event: none;" contenteditable="false">
            <strong >Special Procedures:</strong> ${procedure ?? ''}
          </p>`
              : ''
          }
          <p>
            <strong style="pointer-event: none;" contenteditable="false">ADDITIONAL COMMENTS:</strong>
            ${
              !isDefault
                ? `<div>Follow standard after-hours procedures</div>`
                : ''
            }
            ${comments.trim()}
          </p>`;
}

export {
  convertTo12HourFormat,
  isEndTimeAfterStartTime,
  hasAllValuesPresent,
  requestDetailsValidation,
  timeDifferenceInHours,
  isPastDate,
  editorContent,
};
