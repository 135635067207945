import React from 'react';
import { Column, TableInstance, ColumnInstance } from 'react-table';

import { Button } from 'src/ui/components';

import accountInfoTableHeader from './accountInfoTableHeader';

import { usePersonAlertStore } from '../../store/personAlertStore';

import { AccountType } from 'src/types/AccountInfo';

function contains(array: Array<string>, string: string): boolean {
  const index = array.findIndex(item => item === string);
  return index !== -1;
}

export default function useAccountInfoColumns(
  setColumnsSearch: (
    value:
      | Record<string, string>
      | ((prevState: Record<string, string>) => Record<string, string>),
  ) => void,
  setCurrentSortItem: (val: { sortKey: string; sortOrder: string }) => void,
  columnsList?: Array<string>,
): Array<Column<AccountType>> | undefined {
  const updateAccountInfo = usePersonAlertStore(
    React.useCallback(state => state.updateAccountInfo, []),
  );

  return React.useMemo((): Array<Column<AccountType>> | undefined => {
    if (columnsList) {
      const columns = [];
      if (contains(columnsList, 'selection'))
        if (contains(columnsList, 'AccountName'))
          columns.push(getAccountNameHeader(accountInfoTableHeader));
      if (contains(columnsList, 'CID'))
        columns.push(getCidHeader(accountInfoTableHeader));
      if (contains(columnsList, 'SID'))
        columns.push(getSidHeader(accountInfoTableHeader));
      if (contains(columnsList, 'PID'))
        columns.push(getPidHeader(accountInfoTableHeader));
      if (contains(columnsList, 'Reset') && updateAccountInfo)
        columns.push(
          getResetHeader(
            accountInfoTableHeader,
            setColumnsSearch,
            setCurrentSortItem,
            updateAccountInfo,
          ),
        );
      return columns;
    }
    if (updateAccountInfo)
      return [
        getAccountNameHeader(accountInfoTableHeader),
        getCidHeader(accountInfoTableHeader),
        getSidHeader(accountInfoTableHeader),
        getPidHeader(accountInfoTableHeader),
        getResetHeader(
          accountInfoTableHeader,
          setColumnsSearch,
          setCurrentSortItem,
          updateAccountInfo,
        ),
      ];
  }, [columnsList, setColumnsSearch, setCurrentSortItem, updateAccountInfo]);
}

function getAccountNameHeader(
  Header: (
    props: TableInstance<AccountType> & {
      column: ColumnInstance<AccountType>;
    },
  ) => JSX.Element,
): Column<AccountType> {
  return {
    Header: Header,
    accessor: (row: AccountType) => `${row.Name || ''}`,
    id: 'AccountName',
  };
}

function getCidHeader(
  Header: (
    props: TableInstance<AccountType> & {
      column: ColumnInstance<AccountType>;
    },
  ) => JSX.Element,
): Column<AccountType> {
  return {
    Header: Header,
    accessor: (row: AccountType) => `${row.CID || ''}`,
    id: 'CID',
  };
}

function getSidHeader(
  Header: (
    props: TableInstance<AccountType> & {
      column: ColumnInstance<AccountType>;
    },
  ) => JSX.Element,
): Column<AccountType> {
  return {
    Header: Header,
    accessor: (row: AccountType) => `${row.SID || ''}`,
    id: 'SID',
  };
}

function getPidHeader(
  Header: (
    props: TableInstance<AccountType> & {
      column: ColumnInstance<AccountType>;
    },
  ) => JSX.Element,
): Column<AccountType> {
  return {
    Header: Header,
    accessor: (row: AccountType) => `${row.PID || ''}`,
    id: 'PID',
  };
}

function getResetHeader(
  Header: (
    props: TableInstance<AccountType> & {
      column: ColumnInstance<AccountType>;
    },
  ) => JSX.Element,
  setColumnsSearch: (val: Record<string, string>) => void,
  setCurrentSortItem: (val: { sortKey: string; sortOrder: string }) => void,
  updateAccountInfo: (val: AccountType | undefined) => void,
): Column<AccountType> {
  const handleReset = () => {
    setColumnsSearch({});
    setCurrentSortItem({ sortKey: 'CID', sortOrder: 'asc' });
    updateAccountInfo && updateAccountInfo(undefined);
  };

  return {
    Header: ({}) => (
      <div>
        <Button size="small" onClick={() => handleReset()}>
          Reset
        </Button>
      </div>
    ),
    id: 'Reset',
  };
}
