function userPermissionHelper(permissions: Array<string>) {
  const temp = [...permissions];
  const isRelationshipManager = temp.includes('RelationshipManagers');
  const isSuperAdministrators = temp.includes('SuperAdministrators');
  const isCallCenterSupervisors = temp.includes('CallCenterSupervisors');
  const isAdministrators = temp.includes('AdministerUsersPermission');
  const isAllProtocallUsers = temp.includes('AllProtoCallUsers');
  const isAdmin = temp.includes('IsAdmin');
  const isAdministratorsUsers = temp.includes('Administrators');
  const canViewDocumentTransmission = temp.includes(
    'ViewDocumentTransmissionPermission',
  );
  const canViewCoverageRequests = temp.includes(
    'ViewCoverageRequestsPermission',
  );
  const canSendCoverageRequests = temp.includes(
    'SendCoverageRequestsPermission',
  );
  const canApproveCoverageRequests = temp.includes(
    'CanApproveCoverageRequestsPermission',
  );
  const canViewPersonAlerts = temp.includes('ViewPersonAlertsPermission');
  const canCreatePersonalAlerts = temp.includes('CanCreatePersonAlert');
  const canManagePortalAlerts = temp.includes('ManagePortalAlertsPermission');
  const canSetUpPersonAlerts = temp.includes('SetupPersonAlertsPermission');
  const isNonClinicalAdministrator = temp.includes('NonClinicalAdministrators');
  const canEditForms = temp.includes('CanEditForms');
  const isCustomerUser = temp.includes('IsCustomerUser');

  const userPermissions = {
    RelationshipManager: isRelationshipManager,
    SuperAdministrators: isSuperAdministrators,
    Administrators: isAdministrators,
    AdministratorUsers: isAdministratorsUsers,
    AllProtocallUsers: isAllProtocallUsers,
    Admin: isAdmin,
    CanViewDocumentTransmission: canViewDocumentTransmission,
    CallCenterSupervisors: isCallCenterSupervisors,
    CanViewCoverageRequests: canViewCoverageRequests,
    CanSendCoverageRequests: canSendCoverageRequests,
    CanApproveCoverageRequests: canApproveCoverageRequests,
    CanViewPersonAlerts: canViewPersonAlerts,
    CanCreatePersonAlerts: canCreatePersonalAlerts,
    CanManagePortalAlerts: canManagePortalAlerts,
    CanSetUpPersonAlerts: canSetUpPersonAlerts,
    NonClinicalAdministrator: isNonClinicalAdministrator,
    CanEditForms: canEditForms,
    IsCustomerUser: isCustomerUser,
  };
  localStorage.setItem('userPermissions', JSON.stringify(userPermissions));
  return { ...userPermissions };
}

export { userPermissionHelper };
