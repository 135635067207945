import { useQuery, UseQueryResult } from 'react-query';

import { useAuth } from './useAuth';
import { getPortalUserGroupCode } from 'src/utils/urls';

async function fetchPortalUserGroupCode({
  token,
  tokenType,
}: TVariables): Promise<TResult> {
  const response = await fetch(getPortalUserGroupCode, {
    method: 'GET',
    headers: {
      Authorization: `${tokenType} ${token}`,
    },
  });
  return response.json();
}

export type TResult = string;
export type TError = { message: string };

export type TVariables = {
  token: string;
  tokenType: string;
};
function useGetPortalUserGroupCode(): UseQueryResult<TResult, TError> {
  const { token, tokenType } = useAuth();
  return useQuery(
    'get_portal_user_groupcode',
    () => {
      return fetchPortalUserGroupCode({ token, tokenType });
    },
    {
      staleTime: Infinity,
      refetchOnWindowFocus: false,
    },
  );
}

export { useGetPortalUserGroupCode };
