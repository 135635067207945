import { useQuery } from 'react-query';

import { useAuth } from 'src/hooks';

import { getResourceList } from 'src/utils/urls';

import { ResourceList } from '../types/resourceList';

async function fetchResourceList({
  token,
  tokenType,
}: TVariables): Promise<Array<ResourceList>> {
  const response = await fetch(getResourceList, {
    method: 'GET',
    headers: {
      Authorization: `${tokenType} ${token}`,
      'Content-Type': 'application/json;charset=utf-8',
    },
  });
  return response.json();
}

type TVariables = {
  token: string;
  tokenType: string;
};

function useGetResourceList() {
  const { token, tokenType } = useAuth();
  return useQuery(
    'get-resource-list',
    async () => {
      const result = await fetchResourceList({ token, tokenType });
      return result;
    },
    {
      staleTime: 60 * 1000,
      refetchOnWindowFocus: false,
    },
  );
}
export { useGetResourceList };
