import { NavItemType } from '../types/navTypes';
import { UserPermissionType } from 'src/types/userPermission';

function getNavigationItems(
  permissions: UserPermissionType,
  isExternalUser: boolean,
): Array<NavItemType> {
  return [
    { name: 'Home', to: '', isVisible: true },
    { name: 'Users', to: 'User', isVisible: permissions.Administrators },
    {
      name: 'Call documents',
      to: 'CallDocuments',
      isVisible: permissions.CanViewDocumentTransmission,
    },
    {
      name: 'Activity log',
      to: 'ActivityLog',
      isVisible:
        permissions?.Administrators && permissions?.CanViewDocumentTransmission,
    },
    {
      name: 'Person alerts',
      to: 'PersonAlert',
      isVisible: permissions?.CanViewPersonAlerts,
    },
    {
      name: 'Extra coverage requests',
      to: 'ExtraCoverageRequests',
      isVisible: permissions?.CanSendCoverageRequests,
    },
    {
      name: 'Portal alerts',
      to: 'PortalAlert',
      isVisible: permissions?.CanManagePortalAlerts, //only for protocall users
    },
    {
      name: 'Activity tracker',
      to: 'ActivityTracker',
      isVisible:
        permissions?.RelationshipManager || permissions?.CallCenterSupervisors,
    },
    {
      name: 'Resources',
      to: 'Resources',
      isVisible:
        (permissions?.RelationshipManager ||
          (permissions?.Administrators && permissions?.CanEditForms)) &&
        process.env.REACT_APP_THEME !== 'proteus',
    },
    {
      name: 'Person repository',
      to: 'PersonRepository',
      isVisible:
        !isExternalUser &&
        (permissions?.RelationshipManager ||
          permissions?.Administrators ||
          permissions?.CanViewPersonAlerts),
    },
    { name: 'About', to: 'Home/About', isVisible: true },
  ];
}

export { getNavigationItems };
