import React, { ReactElement } from 'react';
import { Tab } from '@headlessui/react';
import { DocumentTextIcon, FaceSmileIcon } from '@heroicons/react/24/outline';

import { Feedback, ReleaseNotes } from './components/';

import { useTitle, useGetStoredPermissions } from 'src/hooks';

import { classNames } from 'src/utils/className';

export default function About(): ReactElement {
  useTitle('About');

  const permission = useGetStoredPermissions();

  return (
    <div className="flex h-full min-h-0 flex-col">
      <div className="z-[1] flex items-center justify-between gap-4 border-t border-gray-200 bg-white px-2 py-1 shadow dark:border-neutral-700/50 dark:bg-neutral-800 sm:px-3 lg:px-4">
        <h2 className="truncate text-lg font-semibold text-gray-950 dark:text-white sm:text-xl sm:tracking-tight">
          About
        </h2>
      </div>
      <Tab.Group
        as="div"
        className="flex min-h-0 grow flex-col lg:flex-row"
        vertical
      >
        <Tab.List className="flex shrink-0 flex-row justify-center border-b border-gray-300 bg-white dark:border-neutral-700/50 dark:bg-neutral-800 lg:basis-[240px] lg:flex-col lg:justify-start lg:border-b-0 lg:border-e lg:py-4">
          <Tab
            className={classNames(
              'ui-selected:border-primary ui-selected:text-black ui-not-selected:text-gray-500 dark:ui-selected:bg-neutral-700 dark:ui-selected:text-white dark:ui-not-selected:text-neutral-400 lg:ui-selected:bg-gray-100',
              'flex items-center gap-2 border-b-4 border-transparent px-5 py-3 text-start text-sm font-semibold focus-visible:outline focus-visible:outline-[3px] focus-visible:outline-offset-[3px] focus-visible:outline-light-focus dark:focus-visible:outline-dark-focus lg:border-b-0 lg:border-s-4',
            )}
          >
            <DocumentTextIcon className="h-5 w-5" />
            {process.env.REACT_APP_THEME === 'lfl'
              ? 'Release notes'
              : 'Our accreditations'}
          </Tab>
          {permission?.RelationshipManager && (
            <Tab
              className={classNames(
                'ui-selected:border-primary ui-selected:text-black ui-not-selected:text-gray-500 dark:ui-selected:bg-neutral-700 dark:ui-selected:text-white dark:ui-not-selected:text-neutral-400 lg:ui-selected:bg-gray-100',
                'flex items-center gap-2 border-b-4 border-transparent px-5 py-3 text-start text-sm font-semibold focus-visible:outline focus-visible:outline-[3px] focus-visible:outline-offset-[3px] focus-visible:outline-light-focus dark:focus-visible:outline-dark-focus lg:border-b-0 lg:border-s-4',
              )}
            >
              <FaceSmileIcon className="h-5 w-5" />
              Feedback
            </Tab>
          )}
        </Tab.List>
        <Tab.Panels as="div" className="flex min-h-0 grow">
          <ReleaseNotes />
          <Feedback />
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
}
