import { UserManager, WebStorageStateStore, Log, User } from 'oidc-client';

import { AUTH_TOKEN, IDENTITY_CONFIG, METADATA_OIDC } from './authConstants';

export default class AuthService {
  UserManager;
  user: User | null = null;

  constructor() {
    this.UserManager = new UserManager({
      ...IDENTITY_CONFIG,
      userStore: new WebStorageStateStore({ store: window.sessionStorage }),
      metadata: {
        ...METADATA_OIDC,
      },
    });
    // Logger
    Log.logger = console;
    Log.level = Log.DEBUG;
    this.UserManager.events.addUserLoaded(user => {
      if (window.location.href.indexOf('signin-oidc') !== -1) {
        this.navigateToScreen();
      }
    });
    this.UserManager.events.addSilentRenewError(e => {
      console.log('silent renew error', e.message);
    });

    this.UserManager.events.addAccessTokenExpired(() => {
      console.log('token expired');
      this.signinSilent();
    });
  }

  signinRedirectCallback = () => {
    this.UserManager.signinRedirectCallback().then((user: User) => {
      this.user = user ?? null;
    });
  };

  async signIn() {
    await this.UserManager.clearStaleState();
    /*preserve the path when navigated from ab/cc and also on refresh */
    const signinRequest = await this.UserManager.createSigninRequest();
    this.UserManager.signinRedirect({});
    let path = '';
    let pathname = window.location.pathname;
    if (window.location.search === '') path = pathname;
    else path = pathname.concat(window.location.search);
    if (path === '/Home/Expired') return;
    else localStorage.setItem('LastKnownLocation', path);
  }

  getUser = async () => {
    let user = await this.UserManager.signinRedirectCallback();
    return user;
  };

  parseJwt = (token: string) => {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
  };

  signinRedirect = () => {
    localStorage.setItem('redirectUri', window.location.pathname);
    this.UserManager.signinRedirect({});
  };

  navigateToScreen = () => {
    window.location.replace('/home');
  };

  isAuthenticated = () => {
    const store = sessionStorage.getItem(
      `oidc.user:${process.env.REACT_APP_AUTH_URL!}:${process.env
        .REACT_APP_IDENTITY_CLIENT_ID!}`,
    );
    if (store) {
      const oidcStorage = JSON.parse(store);
      return !!oidcStorage && !!oidcStorage.access_token;
    }
    return false;
  };

  signinSilent = () => {
    this.UserManager.signinSilent()
      .then(async user => {
        const user1 = await AuthServiceSingleton.getInstance().getUser();
        console.log('user', user, user1);
        this.user = user ?? null;
      })
      .catch(err => {
        console.log('signinSilent', err);
      });
  };

  signinSilentCallback = () => {
    this.UserManager.signinSilentCallback()
      .then(async (user: User | undefined) => {
        const user1 = await AuthServiceSingleton.getInstance().getUser();
        console.log('user', user);
        if (user) this.user = user;
      })
      .catch(err => {
        console.log('signinSilentCallback', err);
      });
  };

  createSigninRequest = () => {
    return this.UserManager.createSigninRequest();
  };

  logout = () => {
    this.UserManager.signoutRedirect({
      id_token_hint: localStorage.getItem('id_token'),
    });
    localStorage.removeItem(AUTH_TOKEN);
    this.UserManager.clearStaleState();
  };

  signoutRedirectCallback = () => {
    this.UserManager.signoutRedirectCallback().then(() => {
      localStorage.clear();
      window.location.replace(process.env.REACT_APP_PUBLIC_URL!);
    });
    this.UserManager.clearStaleState();
  };
}

export const AuthServiceSingleton = (function () {
  let instance: AuthService;

  function createInstance() {
    let object = new AuthService();
    return object;
  }

  return {
    getInstance: function () {
      if (!instance) {
        instance = createInstance();
      }
      return instance;
    },
  };
})();
