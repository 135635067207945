import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

import {
  OptionType,
  AccountSettings,
  PermissionsType,
} from 'src/features/AddEditUsers/types/userStoreTypes';

import { emailRegex } from 'src/utils/common';

function validateEmail(email: string): boolean {
  if (!emailRegex.test(email.trim())) return false;
  return true;
}

function hasNonEmptyValues(obj: Record<string, any>): boolean {
  const obj1: Record<string, any> = { ...obj };
  delete obj1.id;
  delete obj1.status;
  for (const key in obj1) {
    if (obj1.hasOwnProperty(key) && obj1[key] !== '') {
      return true;
    }
  }
  return false;
}

function hasAllValuesPresent(obj: Record<string, any>): boolean {
  const obj1: Record<string, any> = { ...obj };
  delete obj1.id;
  delete obj1.status;
  for (let key in obj1) {
    if (obj1.hasOwnProperty(key)) {
      const value = obj1[key];
      const trimmedValue = value?.trim();
      if (trimmedValue === undefined || trimmedValue === '') {
        return false;
      }
    }
  }
  return true;
}

function getAllLimittedAccAccounts(
  accountQuestionSettings: Array<AccountSettings> | undefined,
): Array<number> | undefined {
  return accountQuestionSettings
    ?.filter(settings => settings.AccessTypeId === 1)
    ?.map(({ AccountId }) => AccountId);
}

function hasLimittedAccess(
  accountQuestionSettings: Array<AccountSettings> | undefined,
): boolean {
  let questions = accountQuestionSettings?.filter(
    settings => settings.AccessTypeId === 1,
  );
  if (questions && questions?.length > 0 && questions !== null) return true;
  return false;
}

function validateRequest(
  selectedOptions: Array<OptionType>,
  accountQuestionSettings: Array<AccountSettings> | undefined,
  userSettingsPermissions: Array<PermissionsType> | undefined,
): boolean {
  const allElementsPresent = getAllLimittedAccAccounts(
    accountQuestionSettings,
  )?.every(element => {
    return selectedOptions.some(item => item.AccountId === element);
  });

  let documentPresent = userSettingsPermissions?.findIndex(
    item => item?.PermissionName === 'Documents',
  );

  if (documentPresent === -1 || documentPresent === undefined) return false;
  if (
    ((hasLimittedAccess(accountQuestionSettings) &&
      selectedOptions.length === 0) ||
      !allElementsPresent) &&
    documentPresent !== -1 &&
    documentPresent !== undefined
  )
    return true;
  else return false;
}

export {
  validateEmail,
  hasNonEmptyValues,
  hasAllValuesPresent,
  validateRequest,
};
