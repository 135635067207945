import { useQuery, UseQueryResult } from 'react-query';

import { useAuth } from 'src/hooks';
import { getUserOnboardingStatus } from 'src/utils/urls';

async function fetchOnBoardingStatus({
  token,
  tokenType,
  id,
}: TVaribles): Promise<TResult> {
  const response = await fetch(getUserOnboardingStatus(id), {
    method: 'GET',
    headers: {
      Authorization: `${tokenType} ${token}`,
      'Content-Type': 'application/json',
    },
  });
  return response.json();
}

export type TResult = boolean | undefined;
export type TError = { message: string };
export type TVaribles = {
  token: string;
  tokenType: string;
  id: string;
};

function useCheckUserOnboardingStatus(
  id: string,
): UseQueryResult<TResult, TError> {
  const { token, tokenType } = useAuth();
  return useQuery(
    'get_onboarding_status',
    async () => {
      const result = await fetchOnBoardingStatus({ token, tokenType, id });
      return result;
    },
    {
      staleTime: 60 * 1000,
      refetchOnWindowFocus: false,
      enabled: false,
    },
  );
}

export { useCheckUserOnboardingStatus };
