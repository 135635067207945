import {
  getUserEmailNotificationSettings,
  getUserEmailNotification,
} from 'src/utils/urls';

import {
  UserEmailNotificationType,
  EmailNotificationSetting,
} from 'src/types/emailNotification';

type EmailNotificationEditType = {
  token: string;
  tokenType: string;
  id?: string;
};
type TParam = {
  id: string;
  payload: UserEmailNotificationType;
};

type TVariables = {
  token: string;
  tokenType: string;
  params: TParam;
};

// all email notification settings
export async function fetchEmailNotificationSettings({
  token,
  tokenType,
}: Partial<EmailNotificationEditType>): Promise<
  Array<EmailNotificationSetting>
> {
  const response = await fetch(getUserEmailNotificationSettings, {
    method: 'GET',
    headers: {
      Authorization: `${tokenType} ${token}`,
    },
  });
  return response.json();
}

//update email notification setting
export async function updateEmailNotificationSettings({
  token,
  tokenType,
  params,
}: TVariables): Promise<any | string> {
  const result = await fetch(getUserEmailNotification(params?.id), {
    method: 'PUT',
    headers: {
      Authorization: `${tokenType} ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(params?.payload),
  });
  return result?.json();
}

// fetch email notification data only during edit
export async function fetchEmailNotificationData({
  token,
  tokenType,
  id,
}: Partial<EmailNotificationEditType>): Promise<
  UserEmailNotificationType | undefined
> {
  if (id !== undefined) {
    const response = await fetch(getUserEmailNotification(id), {
      method: 'GET',
      headers: {
        Authorization: `${tokenType} ${token}`,
      },
    });
    return response.json();
  }
}
