import React, { ReactElement, useCallback, SyntheticEvent } from 'react';
import {
  InformationCircleIcon,
  XMarkIcon,
  PlusIcon,
} from '@heroicons/react/20/solid';
import { Tooltip } from 'src/ui/components/Tooltip';
import dayjs from 'dayjs';

import {
  Alert,
  Button,
  Checkbox,
  Select,
  inputBaseClasses,
  inputSizeClasses,
} from 'src/ui/components';

import { useUserStore } from '../../store/userStore';
import {
  emailNotificationMsg,
  emailNotificationToolTipData,
} from '../../utils/userConstants';
import {
  dailyAtSettingsId,
  noDocumentSettingsIds,
  timerPickerDefaultState,
} from 'src/utils/customEmailNotification';
import { classNames } from 'src/utils/className';

function EmailNotificationSettings(): ReactElement {
  const userMode = useUserStore(useCallback(state => state?.userMode, []));

  const userSettingsPermissions = useUserStore(
    useCallback(state => state?.userSettingsPermissions, []),
  );

  const includeEmptyNotification = useUserStore(
    useCallback(state => state?.includeEmptyNotification, []),
  );

  const customEmailNotificationSettings = useUserStore(
    useCallback(state => state?.customEmailNotificationSettings, []),
  );

  const updateIncludeEmptyNotification = useUserStore(
    useCallback(state => state.updateIncludeEmptyNotification, []),
  );

  const updateCustomEmailNotificationSettings = useUserStore(
    useCallback(state => state.updateCustomEmailNotificationSettings, []),
  );

  /*to get the default email settings */
  const defaultEmailNotification = useUserStore(
    useCallback(state => state.defaultEmailNotification, []),
  );

  /*array - which has all the email notification: custom, no email, live */
  const emailNotificationSettings = useUserStore(
    useCallback(state => state.emailNotificationSettings, []),
  );

  const updateDefaultNotification = useUserStore(
    useCallback(state => state.updateDefaultNotification, []),
  );

  /*handle the email frequency drop down on change */
  function handleEmailFrequency(id: number) {
    let selectedFrequency = emailNotificationSettings?.find(
      item => item?.value === id,
    );

    if (selectedFrequency !== undefined && updateDefaultNotification) {
      updateDefaultNotification(selectedFrequency);

      if (
        selectedFrequency?.id === dailyAtSettingsId &&
        (customEmailNotificationSettings === undefined ||
          customEmailNotificationSettings?.length === 0)
      ) {
        updateCustomEmailNotificationSettings([timerPickerDefaultState]);
      }
    }
  }

  // adding extra time picker if add button is clicked
  function addTimePicker() {
    if (
      userSettingsPermissions &&
      customEmailNotificationSettings &&
      customEmailNotificationSettings.length !== 4
    ) {
      updateCustomEmailNotificationSettings([
        ...customEmailNotificationSettings,
        { ...timerPickerDefaultState },
      ]);
    }
  }

  // function for removing the time picker on clicking close
  function onRemoveTimePicker(index: number) {
    if (customEmailNotificationSettings) {
      let updatedTimeValues = customEmailNotificationSettings.filter(
        (item, i) => i !== index,
      );

      updateCustomEmailNotificationSettings([...updatedTimeValues]);
    }
  }

  function timeInputHandler(e: any, index: number | number) {
    if (
      customEmailNotificationSettings !== undefined &&
      customEmailNotificationSettings?.length !== 0
    ) {
      let existingObjects = [...customEmailNotificationSettings];
      const itemExists = existingObjects?.findIndex((item, i) => i === index);
      if (itemExists !== -1) {
        existingObjects[itemExists] = {
          NotifyTime: e.target.value,
          NotifyDateTime: dayjs(new Date(0)).utc().format(),

          EpochTimeValue: existingObjects[itemExists]?.EpochTimeValue,
          Id: existingObjects[itemExists]?.Id,
          UserEmailNotificationId: null,
        };
      }

      updateCustomEmailNotificationSettings([...existingObjects]);
    }
  }

  return (
    <div className="space-y-3">
      {userMode !== 'Edit' && (
        <Alert variant="info" className="mb-3">
          {emailNotificationMsg}
        </Alert>
      )}

      <div className="grid grid-cols-1 gap-2 gap-y-2 sm:gap-x-4 md:grid-cols-4 lg:grid-cols-6 xl:grid-cols-7">
        <div>
          <div className="flex items-center gap-1">
            <label className="flex gap-[1ch] text-sm font-medium leading-6 text-gray-900 dark:text-white">
              Email frequency{' '}
            </label>
            <Tooltip
              trigger={
                <InformationCircleIcon
                  className="-mb-0.5 -ml-0.5 mr-1 h-5 w-5 text-primary"
                  aria-hidden="true"
                />
              }
              content={emailNotificationToolTipData}
            />
          </div>
          {defaultEmailNotification &&
            defaultEmailNotification !== undefined && (
              <Select
                id="user-filter-status"
                options={
                  emailNotificationSettings !== undefined
                    ? emailNotificationSettings
                    : []
                }
                value={
                  defaultEmailNotification && defaultEmailNotification?.value
                }
                onChange={(e: SyntheticEvent<HTMLSelectElement>) =>
                  handleEmailFrequency(parseInt(e.currentTarget.value))
                }
              />
            )}
        </div>
        {defaultEmailNotification !== undefined &&
          defaultEmailNotification?.id === dailyAtSettingsId && (
            <>
              {customEmailNotificationSettings !== undefined &&
                customEmailNotificationSettings?.map((item, index) => {
                  return (
                    <div className="relative" key={index}>
                      <label
                        htmlFor="email-at-2"
                        className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
                      >
                        Email at
                      </label>
                      <input
                        id={`time${item?.Id}`}
                        className={classNames(
                          inputBaseClasses,
                          inputSizeClasses.medium,
                          'mt-0.5 outline-0',
                          customEmailNotificationSettings?.length > 1
                            ? 'pr-9'
                            : '',
                        )}
                        type="time"
                        value={item?.NotifyTime}
                        onChange={(e: any) => timeInputHandler(e, index)}
                      />

                      {customEmailNotificationSettings?.length > 1 && (
                        <div className="absolute inset-y-0 bottom-[0.25rem] right-1 flex items-end">
                          <Button
                            size="small"
                            variant="subtle"
                            onClick={e => onRemoveTimePicker(index)}
                          >
                            <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                            <span className="sr-only">Clear</span>
                          </Button>
                        </div>
                      )}
                    </div>
                  );
                })}

              <div className="flex items-end">
                <Button
                  onClick={() => addTimePicker()}
                  disabled={customEmailNotificationSettings?.length === 4}
                >
                  <PlusIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
                  Add
                </Button>
              </div>
            </>
          )}
      </div>
      {/* Not shown for live & no email settings */}
      {defaultEmailNotification &&
        !noDocumentSettingsIds.includes(defaultEmailNotification.id) && (
          <Checkbox
            id="noNewDocuments"
            label="Get an email even when there are no new documents"
            checked={includeEmptyNotification}
            onChange={e =>
              updateIncludeEmptyNotification &&
              updateIncludeEmptyNotification(!includeEmptyNotification)
            }
          />
        )}
    </div>
  );
}

export default EmailNotificationSettings;
