import React, { ReactElement, Fragment, useCallback, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/20/solid';

import { Button, Textarea } from 'src/ui/components';

import { useAddStickyNote } from '../api';

import { useCallDocStore } from '../store/callDocStore';

import { StickyNotesType } from '../types/stickynotes';
import { CallDocumentType } from '../types/callDocumentList';

type StickModalType = {
  stickyNoteModal: boolean;
  setStickyNoteModal: (value: boolean) => void;
  stickyNoteData: StickyNotesType | undefined;
  stickyModalLoading: boolean;
  setStickyNoteData: (value: StickyNotesType | undefined) => void;
  setSelectedDocs: (
    value:
      | Array<CallDocumentType>
      | ((prevState: Array<CallDocumentType>) => Array<CallDocumentType>),
  ) => void;
};

function StickyNote({
  stickyNoteModal,
  setStickyNoteModal,
  stickyNoteData,
  stickyModalLoading,
  setStickyNoteData,
  setSelectedDocs,
}: StickModalType): ReactElement {
  /*ref creared for focusing the sticky note text area */
  const textAreaRef = useRef(null);

  const { mutate: addStickyNote } = useAddStickyNote();

  const initialStickyData = useCallDocStore(
    useCallback(state => state.initialStickyData, []),
  );
  const setInitialStickyData = useCallDocStore(
    useCallback(state => state.setInitialStickyData, []),
  );

  const isAllCheckedStatus = useCallDocStore(
    useCallback(state => state.isAllChecked, []),
  );

  function saveStickyNote() {
    addStickyNote({
      CallId: stickyNoteData?.CallId,
      Note: stickyNoteData?.Note,
    });
    setStickyNoteModal(false);
    !isAllCheckedStatus && setSelectedDocs([]);
  }

  function resetSticky() {
    setStickyNoteModal(false);
    setStickyNoteData(undefined);
    setSelectedDocs([]);
  }

  return (
    <>
      <div className="flex flex-wrap gap-3">
        {stickyNoteData?.Note !== undefined && !stickyModalLoading && (
          <Transition appear show={stickyNoteModal} as={Fragment}>
            <Dialog
              as="div"
              className="pr-dialog"
              initialFocus={
                stickyNoteData?.Note === '' ? textAreaRef : undefined
              }
              onClose={() => {
                resetSticky();
              }}
            >
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="pr-dialog__backdrop" />
              </Transition.Child>

              <div className="pr-dialog__wrapper">
                <div className="pr-dialog__panel__container">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                  >
                    <Dialog.Panel className="pr-dialog__panel sm:!max-w-xl">
                      <Dialog.Title
                        as="h3"
                        className="pl-4 pr-10 pt-4 text-sm font-medium leading-6 text-gray-900 dark:text-white "
                        title={stickyNoteData?.ReportFileName}
                      >
                        {stickyNoteData?.ReportFileName}
                      </Dialog.Title>
                      <button
                        type="button"
                        className="absolute right-4 top-4 text-gray-400 hover:text-gray-500 sm:right-4 sm:top-4"
                        onClick={e => {
                          resetSticky();
                          e.stopPropagation();
                        }}
                      >
                        <span className="sr-only">Close</span>
                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                      <div className="mt-2 space-y-3 p-4">
                        <Textarea
                          label="Notes"
                          id="notes-id"
                          rows={10}
                          value={stickyNoteData?.Note}
                          ref={textAreaRef}
                          onChange={e =>
                            setStickyNoteData({
                              ...stickyNoteData,
                              Note: e.currentTarget.value,
                            })
                          }
                        />
                      </div>
                      <div className="flex gap-[1ch] bg-gray-50 p-2 px-2 py-4 dark:bg-neutral-700/50 sm:justify-end sm:p-3">
                        <Button
                          variant="secondary"
                          onClick={e => {
                            resetSticky();
                            e.stopPropagation();
                          }}
                          className="grow sm:grow-0"
                        >
                          Cancel
                        </Button>
                        <Button
                          variant="primary"
                          onClick={e => {
                            saveStickyNote();
                            setStickyNoteModal(false);
                            setInitialStickyData('');
                            e.stopPropagation();
                          }}
                          className="grow sm:grow-0"
                          disabled={
                            initialStickyData === '' &&
                            stickyNoteData?.Note === ''
                          }
                        >
                          Save
                        </Button>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition>
        )}
      </div>
    </>
  );
}

export { StickyNote };
