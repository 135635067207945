import { useReducer, useEffect, useMemo, useCallback } from 'react';
import dayjs from 'dayjs';

import { ExtraCoverageFilterType } from '../types/extraCoverageRequestFilterType';

export const initialState: ExtraCoverageFilterType = {
  keywordSearch: '',
  sortType: '4',
  sortOrder: 'desc',
  id: '',
  approver: '',
  accounts: '',
  extraCoverageDate: '',
  startTime: '',
  endTime: '',
  submittedDate: '',
  emailSent: false,
  accountAlertPosted: false,
  notEmailSent: false,
  notAccountAlertPosted: false,
  extraCoverageRequestStatusId: [{ value: 1, label: 'New' }],
};

export function filterReducer(
  state: ExtraCoverageFilterType,
  action: {
    type: keyof ExtraCoverageFilterType | 'reset' | 'preserve';
    value?: any;
    payload?: any;
    newState?: ExtraCoverageFilterType | undefined;
  },
) {
  switch (action.type) {
    case 'reset':
      return {
        ...action.value,
      };

    default:
      return {
        ...state,
        [action.type]: action.value,
      };
  }
}

export function useCoverageFilterReducer(isProtocallUser: boolean): {
  filterOptions: ExtraCoverageFilterType;
  filterCount: number;
  setValues: (
    type: keyof ExtraCoverageFilterType,
    value: string | boolean,
  ) => void;
  setStatus: (value: any) => void;
  resetFilters: () => void;
} {
  // set initial obj based on user permission (protocall/non-protocall)
  const getInitialState = useCallback(() => {
    if (isProtocallUser) {
      return { ...initialState };
    } else {
      return {
        ...initialState,
        extraCoverageRequestStatusId: [],
        extraCoverageDate: coverageDateForNonProtocallUser(),
      };
    }
  }, [isProtocallUser]);

  //set default date for non protocall user.
  function coverageDateForNonProtocallUser() {
    const startDate = dayjs().startOf('day');
    return (
      startDate.add(1, 'day').format('MM/DD/YYYY H:mm') +
      '-' +
      startDate.add(1, 'year').add(1, 'day').format('MM/DD/YYYY H:mm')
    );
  }

  const [filterOptions, setFilterOptions] = useReducer(
    filterReducer,
    {
      ...JSON.parse(JSON.stringify(getInitialState())),
    },
    (initial: ExtraCoverageFilterType) => {
      const savedExtraCoverageFilter = window.localStorage.getItem(
        'ExtraCoverageRequestFilter',
      );
      if (savedExtraCoverageFilter) {
        const extraCoverageFilter: ExtraCoverageFilterType = JSON.parse(
          savedExtraCoverageFilter,
        );
        return {
          ...extraCoverageFilter,
        };
      }
      return {
        ...initial,
      };
    },
  );

  //for filtercount
  const filterCount = useMemo(() => {
    let count: number = 0;
    const obj1: any = getInitialState();
    const obj2: any = {
      ...JSON.parse(JSON.stringify(filterOptions)),
    };

    delete obj1['keywordSearch'];
    delete obj1['sortOrder'];
    delete obj1['sortType'];

    Object.keys(obj1).forEach(key => {
      if (obj1[key] !== obj2[key]) {
        count++;
      }
    });
    if (obj2['emailSent'] === true && obj2['notEmailSent'] === true)
      count = count - 2;
    if (
      obj2['accountAlertPosted'] === true &&
      obj2['notAccountAlertPosted'] === true
    )
      count = count - 2;

    if (obj2['extraCoverageRequestStatusId']?.length === 0) {
      count = count - 1;
    }

    if (!isProtocallUser) {
      if (obj2['extraCoverageDate'] === obj1['extraCoverageDate'])
        count = count + 1;
      if (obj2['extraCoverageDate'] === '') count = count - 2;
    }

    return count;
  }, [filterOptions, getInitialState, isProtocallUser]);

  function setValues(
    type: keyof ExtraCoverageFilterType,
    value: string | boolean,
  ) {
    setFilterOptions({
      type,
      value,
    });
  }

  function setStatus(
    value: Array<{ value: number | string; label: string | undefined }>,
  ) {
    setFilterOptions({
      type: 'extraCoverageRequestStatusId',
      value,
    });
  }

  useEffect(() => {
    window.localStorage.setItem(
      'ExtraCoverageRequestFilter',
      JSON.stringify(filterOptions),
    );
  }, [filterOptions]);

  function resetFilters() {
    setFilterOptions({
      type: 'reset',
      value: {
        ...JSON.parse(JSON.stringify(getInitialState())),
      },
    });
  }

  return {
    filterOptions,
    filterCount,
    setValues,
    resetFilters,
    setStatus,
  };
}
