import { useMutation, UseMutationResult, useQueryClient } from 'react-query';

import { useAuth } from 'src/hooks';

import { alertsDownloadAsCsv } from 'src/utils/urls';
import { getColumnFromId } from 'src/utils/filterHelper';
import { pageLength } from 'src/utils/appConstants';
import { trimValues } from 'src/utils/common';
import { sortOptions } from '../utils/constants';
import { portalAlertStore } from '../store/portalAlertStore';

import { FilterOptionsType } from '../types/portalAlerts';

async function postDownloadCsv({
  token,
  tokenType,
  requestData,
}: TArguments): Promise<TResult> {
  const response = await fetch(alertsDownloadAsCsv, {
    method: 'POST',
    headers: {
      Authorization: `${tokenType} ${token}`,
      'Content-Type': 'application/json; charset=utf-8',
    },
    body: JSON.stringify(requestData),
  });

  if (response.status === 204) return 'Success';
  else return response.json();
}

export type ValidationError = { Message?: string };
export type TResult = string | ValidationError;
export type TError = { message?: string };

export type TArguments = {
  token: string;
  tokenType: string;
  requestData: any;
};

export type TVariables = {
  filterOptions: FilterOptionsType;
  pageParam: number;
};

function setRequestData(filterOptions: FilterOptionsType): any {
  return {
    start: portalAlertStore?.getState().pageParam,
    draw: 1,
    length: pageLength,
    timezoneOffset: new Date().getTimezoneOffset(),
    columns: [
      {
        data: 'Title',
        name: '',
        searchable: true,
        orderable: true,
        search: {
          regex: false,
          value:
            filterOptions && filterOptions.Title
              ? trimValues(filterOptions.Title)
              : '',
        },
      },
      {
        data: 'Message',
        name: '',
        searchable: true,
        orderable: true,
        search: {
          regex: false,
          value:
            filterOptions && filterOptions.Message
              ? trimValues(filterOptions.Message)
              : '',
        },
      },
      {
        data: 'EffectiveDate',
        name: '',
        searchable: true,
        orderable: true,
        search: {
          regex: false,
          value:
            filterOptions && filterOptions.EffectiveDate
              ? trimValues(filterOptions.EffectiveDate)
              : '',
        },
      },
      {
        data: 'ExpirationDate',
        name: '',
        searchable: true,
        orderable: true,
        search: {
          regex: false,
          value:
            filterOptions && filterOptions.ExpirationDate
              ? trimValues(filterOptions.ExpirationDate)
              : '',
        },
      },
    ],
    order: [
      {
        column: getColumnFromId(filterOptions?.SortType, sortOptions),
        dir: filterOptions?.SortOrder,
      },
    ],
    search: {
      value: trimValues(filterOptions.KeywordSearch),
      regex: false,
    },
  };
}

function useDownloadCsv(): UseMutationResult<
  TResult,
  TError,
  TVariables,
  unknown
> {
  const { token, tokenType } = useAuth();

  return useMutation(async (params: TVariables) => {
    const requestData = setRequestData(params.filterOptions);

    const result = await postDownloadCsv({
      token,
      tokenType,
      requestData,
    });
    if (result === 'Success') return result;
    else throw result;
  });
}
export { useDownloadCsv };
