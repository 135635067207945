import { useMutation, UseMutationResult, useQueryClient } from 'react-query';

import { verifyEmail, verifyUser } from 'src/utils/urls';

import { UserCodeverificationType } from '../types/apiTypes';

export type ValidationError = { Message?: string };

export type TResult = any;

export type TArguments = UserCodeverificationType;

export type TVariables = UserCodeverificationType;

export type TError = {
  message: string;
};

export async function verifyUserDetails({
  id,
  type,
  code,
}: TArguments): Promise<TResult> {
  const response = await fetch(
    type ? verifyUser(id, code) : verifyEmail(id, code),
    {
      method: 'POST',
    },
  );
  if (response.status === 400) {
    return { status: 400, message: 'invalid token' };
  }
  return response;
}

function useUserVerification(): UseMutationResult<
  TResult,
  TError,
  TVariables,
  unknown
> {
  return useMutation(async ({ id, type, code }: TVariables) => {
    if (code) {
      const result = await verifyUserDetails({
        id,
        type,
        code,
      });
      if (result.status === 400) {
        throw result.message;
      }
      return result;
    }
  });
}

export { useUserVerification };
