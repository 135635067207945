import React, { ReactElement, useCallback } from 'react';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';
import { XMarkIcon, BellIcon } from '@heroicons/react/20/solid';
import dayjs from 'dayjs';

import {
  Button,
  inputBaseClasses,
  inputSizeClasses,
  Alert,
  Checkbox,
  radioBaseClasses,
} from 'src/ui/components';

import { useAuth } from 'src/hooks';

import { userOnBoardingStore } from 'src/store/userOnboardingStore';
import { notificationMsg } from '../utils/onBoardingConstants';
import {
  timerPickerDefaultState,
  dailyAtSettingsId,
  noDocumentSettingsIds,
  maximumPickerLength,
} from 'src/utils/customEmailNotification';
import { classNames } from 'src/utils/className';

import { CustomEmailNotificationSettingsType } from 'src/types/emailNotification';

function Notification() {
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get('id');

  const { token, tokenType } = useAuth();

  const emailOptionList = userOnBoardingStore(
    useCallback(state => state.emailOptionList, []),
  );

  const customEmailNotificationSettings = userOnBoardingStore(
    useCallback(state => state.customEmailNotificationSettings, []),
  );

  const getNotificationDetails = userOnBoardingStore(
    useCallback(state => state.getNotificationDetails, []),
  );

  const updateCustomEmailNotificationSettings = userOnBoardingStore(
    useCallback(state => state.updateCustomEmailNotificationSettings, []),
  );

  const selectedFrequency = userOnBoardingStore(
    useCallback(state => state.selectedFrequency, []),
  );

  const setUpdateStore = userOnBoardingStore(
    useCallback(state => state.setUpdateStore, []),
  );

  const includeEmptyNotification = userOnBoardingStore(
    useCallback(state => state.includeEmptyNotification, []),
  );

  useQuery(
    ['onboarding-process', id],
    () => {
      getNotificationDetails({
        id,
        token,
        tokenType,
      });
    },
    { staleTime: Infinity },
  );

  function renderRadioButton({
    id,
    label,
    labelId,
  }: {
    id: string;
    label: string;
    labelId: number;
  }): ReactElement {
    return (
      <div className="grid grid-cols-[theme(space.8)_1fr] gap-2 bg-white p-3 focus-within:bg-gray-100 dark:bg-neutral-800 dark:focus-within:bg-neutral-700">
        <input
          type="radio"
          id={id}
          name="authType"
          // className="mt-0.5 self-center justify-self-center"
          className={classNames(
            'mt-0.5 self-start justify-self-center',
            radioBaseClasses,
          )}
          onChange={e => setUpdateStore('selectedFrequency', labelId)}
          checked={selectedFrequency === labelId ? true : false}
        />
        <div className="grid gap-1">
          <label className="self-center text-sm font-medium" htmlFor={id}>
            {label}
          </label>
        </div>
      </div>
    );
  }

  function onRemoveTimePicker(index: number) {
    if (customEmailNotificationSettings) {
      let updatedTimeValues = customEmailNotificationSettings.filter(
        (item, i) => i !== index,
      );
      updateCustomEmailNotificationSettings([...updatedTimeValues]);
    }
  }

  function timeInputHandler(e: any, index: number | number) {
    if (
      customEmailNotificationSettings !== undefined &&
      customEmailNotificationSettings?.length !== 0
    ) {
      let existingObjects = [...customEmailNotificationSettings];
      const itemExists = existingObjects?.findIndex((item, i) => i === index);

      if (itemExists !== -1) {
        existingObjects[itemExists] = {
          NotifyTime: e.target.value,
          NotifyDateTime: dayjs(new Date(0)).utc().format(),
          EpochTimeValue: 0,
          Id: existingObjects[itemExists]?.Id,
          UserEmailSetting: null,
        };
      }

      updateCustomEmailNotificationSettings([...existingObjects]);
    }
  }

  function addTimePicker() {
    if (
      customEmailNotificationSettings &&
      customEmailNotificationSettings.length !== 4
    ) {
      updateCustomEmailNotificationSettings([
        ...customEmailNotificationSettings,
        { ...timerPickerDefaultState },
      ]);
    }
  }

  function renderTimepicker(
    item: CustomEmailNotificationSettingsType,
    index: number,
  ): ReactElement {
    return (
      <div className="relative" key={`${index}${item?.NotifyDateTime}`}>
        <input
          id={`time${item?.Id}-${item?.EpochTimeValue}`}
          value={item?.NotifyTime}
          className={`${inputBaseClasses} ${inputSizeClasses.medium} mt-0.5 ${
            customEmailNotificationSettings?.length > 1 ? 'pr-10' : ''
          } outline-0`}
          type="time"
          onChange={(e: any) => timeInputHandler(e, index)}
        />

        {customEmailNotificationSettings?.length > 1 ? (
          <div className="absolute inset-y-0 bottom-[0.25rem] right-1 flex items-end">
            <Button
              size="small"
              variant="subtle"
              onClick={e => onRemoveTimePicker(index)}
            >
              <XMarkIcon className="h-5 w-5" aria-hidden="true" />
              <span className="sr-only">Clear</span>
            </Button>
          </div>
        ) : null}
      </div>
    );
  }

  return (
    <div className="container relative mx-auto grid max-w-2xl place-items-center gap-y-8 p-4 md:p-6">
      <div className=" space-y-4">
        <div className="space-y-1">
          <div
            className="mb-2 flex h-10 w-10 items-center justify-center rounded border-primary-600 bg-primary-100/50 p-2 dark:bg-primary-600/20"
            aria-hidden="true"
          >
            <BellIcon className="dark:text-primary-2300 h-5 w-5 text-primary-500" />
          </div>
          <h2 className="text-xl font-medium md:text-2xl">Notification</h2>
          <p className="text-xs text-gray-500 dark:text-neutral-400 md:text-sm">
            {notificationMsg}
          </p>
        </div>

        <div className="flex flex-col gap-3 sm:flex-row">
          <fieldset className="grid flex-1 shrink-0 basis-2/3 gap-px overflow-hidden rounded-lg border border-gray-300 bg-gray-200 shadow transition-shadow duration-300 dark:border-neutral-700 dark:bg-neutral-700">
            {emailOptionList?.map(item =>
              renderRadioButton({
                id: item?.label?.toLocaleLowerCase(),
                label: item?.label,
                labelId: item?.value,
              }),
            )}
          </fieldset>

          <div className="flex basis-1/3 flex-col gap-5">
            {selectedFrequency !== null &&
              !noDocumentSettingsIds.includes(selectedFrequency) && (
                <Checkbox
                  label="Get an email when there are no new documents"
                  id="email-no-documents"
                  checked={includeEmptyNotification}
                  onChange={e => {
                    setUpdateStore(
                      'includeEmptyNotification',
                      e.currentTarget.checked,
                    );
                  }}
                />
              )}

            {selectedFrequency === dailyAtSettingsId && (
              <>
                <Alert variant="info" icon={false} className="!p-1 text-sm">
                  Times are shown in your timezone
                </Alert>

                <div className="space-y-2">
                  <label
                    htmlFor="email-at-2"
                    className="block text-sm font-medium leading-none text-gray-900 dark:text-white"
                  >
                    Send emails at
                  </label>
                  {customEmailNotificationSettings?.map(
                    (
                      item: CustomEmailNotificationSettingsType,
                      index: number,
                    ) => {
                      return renderTimepicker(item, index);
                    },
                  )}
                  <Button
                    variant="secondary"
                    onClick={() => addTimePicker()}
                    disabled={
                      customEmailNotificationSettings?.length ===
                      maximumPickerLength
                    }
                    className="w-full"
                  >
                    Add
                  </Button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export { Notification };
