function warningMessageSections(
  tabs: Array<string>,
  tabContents: Array<{ message: string; link: string }>,
  enableTab: any,
): Array<{ message: string; link: string }> | [] {
  return tabs.flatMap((tab, index) =>
    enableTab(tab) ? [] : [tabContents[index]],
  );
}

export { warningMessageSections };
