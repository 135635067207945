import {
  CallType,
  CallTypeAbbreviation,
  MultiSelectInputType,
} from '../types/callDocumentList';

/*get calltype label*/
function getCallTypeLabel(
  callTypeArray: Array<MultiSelectInputType> | undefined,
  callTypeDescription: Array<CallType> | undefined,
) {
  const indx = callTypeArray?.findIndex(item => item?.label === 'Select All');
  if (indx !== -1) return '';
  else {
    const labelArray = callTypeArray
      ?.map(item => {
        return callTypeDescription?.filter(type => {
          if (item?.label === type.Description) return type?.Abbreviation;
        });
      })
      .flat();
    const result = labelArray?.map(item => item?.Abbreviation).join(',');
    const blankIndx = callTypeArray?.findIndex(item => item?.label === 'Blank');
    if (blankIndx !== -1) return result?.concat(',Blank');
    return result;
  }
}

/*get tab label*/
function getTagLabel(tagType: Array<MultiSelectInputType> | undefined) {
  const indx = tagType?.findIndex(item => item?.label === 'Select All');
  if (indx !== -1) return '';
  else {
    const labelArray = tagType?.map(item => item?.label).join(',');
    return labelArray;
  }
}

/*get Loc Label*/
function getLocLabel(locList: Array<MultiSelectInputType> | undefined) {
  const indx = locList?.findIndex(item => item?.label === 'Select All');
  if (indx !== -1) return '';
  else {
    const labelArray = locList?.map(item => item?.label).join(',');
    return labelArray;
  }
}

/*get locs */
function formatLevelOfCare(data: number | null): string {
  if (data === 0) return 'Routine';
  else if (data === 1) return 'Urgent';
  else if (data === 2) return 'Emergent';
  else return '';
}

/*get the call types */
function findDescription(
  value: Array<CallTypeAbbreviation | undefined>,
): string {
  if (value) {
    if (value && value[0] && value?.length === 1) {
      return value[0].Description;
    } else {
      return value
        ?.map((item: CallTypeAbbreviation | undefined) => item?.Description)
        .join(', ');
    }
  } else return '';
}

function formatSelectOptions(arrObj: Array<any>, isCallType: boolean = false) {
  const blankObject = { value: -1, label: 'Blank' };
  const optionArray = isCallType
    ? arrObj?.map(item => ({
        value: item?.Id,
        label: item?.Description,
      }))
    : arrObj?.map(item => ({
        value: item?.Id,
        label: item?.TagName,
      }));
  optionArray?.splice(0, 0, blankObject);
  const newResult = optionArray.filter(
    item => item?.label !== '' && item?.label !== null,
  );
  newResult?.unshift({ value: 0, label: 'Select All' });
  return newResult;
}

//Remove duplicates
function removeDuplicates<T>(array: T[], prop: keyof T) {
  const seen = new Set();
  return array.filter(item => {
    const key = item[prop];
    return seen.has(key) ? false : seen.add(key);
  });
}

export {
  getCallTypeLabel,
  getLocLabel,
  getTagLabel,
  formatLevelOfCare,
  findDescription,
  formatSelectOptions,
  removeDuplicates,
};
