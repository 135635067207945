import { getColumnFromId } from 'src/utils/filterHelper';
import { filterSortOptions } from '../utils/activityFilterConstants';
import { trimValues } from 'src/utils/common';

import { ActivityLogFilterType } from '../types/filterOptionsType';

export function setListRequestData(
  filterOptions: ActivityLogFilterType,
): FormData {
  const formData: any = new URLSearchParams();

  formData.append('draw', '1');
  formData.append('length', `${100}`);
  formData.append('timezoneOffset', `${new Date().getTimezoneOffset()}`);
  formData.append('columns[0][data]', 'CID');
  formData.append('columns[0][name]', '');
  formData.append('columns[0][searchable]', 'true');
  formData.append('columns[0][orderable]', 'true');
  formData.append('columns[0][search][regex]', 'false');
  formData.append('columns[0][search][ApplyExactMatch]', ''),
    formData.append(
      'columns[0][search][value]',
      filterOptions && filterOptions.cid ? trimValues(filterOptions.cid) : '',
    );

  formData.append('columns[1][data]', 'PID');
  formData.append('columns[1][name]', '');
  formData.append('columns[1][searchable]', 'true');
  formData.append('columns[1][orderable]', 'true');
  formData.append('columns[1][search][regex]', 'false');
  formData.append('columns[1][search][ApplyExactMatch]', ''),
    formData.append(
      'columns[1][search][value]',
      filterOptions && filterOptions.pid ? trimValues(filterOptions.pid) : '',
    );

  formData.append('columns[2][data]', 'Activity');
  formData.append('columns[2][name]', '');
  formData.append('columns[2][searchable]', 'true');
  formData.append('columns[2][orderable]', 'true');
  formData.append('columns[2][search][regex]', 'false');
  formData.append('columns[2][search][ApplyExactMatch]', ''),
    formData.append(
      'columns[2][search][value]',
      filterOptions && filterOptions.activity
        ? trimValues(filterOptions.activity)
        : '',
    );

  formData.append('columns[3][data]', 'CreatedOn');
  formData.append('columns[3][name]', '');
  formData.append('columns[3][searchable]', 'true');
  formData.append('columns[3][orderable]', 'true');
  formData.append('columns[3][search][regex]', 'false');
  formData.append('columns[3][search][ApplyExactMatch]', ''),
    formData.append(
      'columns[3][search][value]',
      filterOptions && filterOptions.createdOn
        ? trimValues(filterOptions.createdOn)
        : '',
    );

  formData.append('columns[4][data]', 'CreatedBy');
  formData.append('columns[4][name]', 'CreatedBy');
  formData.append('columns[4][searchable]', 'true');
  formData.append('columns[4][orderable]', 'true');
  formData.append('columns[4][search][regex]', 'false');
  formData.append('columns[4][search][ApplyExactMatch]', ''),
    formData.append(
      'columns[4][search][value]',
      filterOptions && filterOptions.createdBy
        ? trimValues(filterOptions.createdBy)
        : '',
    );

  formData.append('columns[5][data]', 'ReportFileName');
  formData.append('columns[5][name]', '');
  formData.append('columns[5][searchable]', 'true');
  formData.append('columns[5][orderable]', 'true');
  formData.append('columns[5][search][regex]', 'false');
  formData.append('columns[5][search][ApplyExactMatch]', ''),
    formData.append(
      'columns[5][search][value]',
      filterOptions && filterOptions.reportFileName
        ? trimValues(filterOptions.reportFileName)
        : '',
    );

  formData.append(
    'order[0][column]',
    getColumnFromId(filterOptions.sortType, filterSortOptions),
  );
  formData.append(
    'order[0][dir]',
    filterOptions.sortOrder ? filterOptions.sortOrder : 'desc',
  );

  formData.append(
    'search[value]',
    `${trimValues(filterOptions.keywordSearch)}`,
  );
  formData.append('search[regex]', 'false');

  return formData;
}
