import React, {
  ReactElement,
  SyntheticEvent,
  useCallback,
  useState,
  useMemo,
  useEffect,
  useRef,
} from 'react';

import {
  PortalAlerts,
  Welcome,
  RightPanel,
  WidgetList,
  MyCollegues,
  DocumentActivity,
  NoWidgetState,
  ClinicalCallResolution,
} from './components';
import { Spinner } from 'src/ui/components';

import { useAuth, useTitle } from 'src/hooks';
import { useGetDashboardSettings } from './api';
import { useProgressStore } from 'src/hooks';

import { useHomeStore } from './store/homeStore';
import { classNames } from 'src/utils/className';
import { dashboardNav } from './utils/widgetListConstants';

export default function Home(): ReactElement {
  useTitle('Home');

  const { isExternalUser } = useAuth();

  const { mutations } = useProgressStore();

  const { data: dashSettings, isLoading: isSettingsLoading } =
    useGetDashboardSettings();

  const isResponseLoading = useHomeStore(
    useCallback(state => state.isResponseLoading, []),
  );

  const masterDashboardData = useHomeStore(
    useCallback(state => state.masterDashboardData, []),
  );

  const filteredMasterData = useMemo(() => {
    if (masterDashboardData && !isExternalUser) {
      return masterDashboardData.filter(item => item !== 'My collegues');
    } else return masterDashboardData;
  }, [isExternalUser, masterDashboardData]);

  const [firstVisibleSection, setFirstVisibleSection] = useState('dash-alerts');
  const sectionRefs = useRef<HTMLDivElement[]>([]);
  const scrollTimeout = useRef<any>(null);

  const handleNav = (id: string) => {
    setFirstVisibleSection(id);
  };

  const tbodyRef = useRef<any>();
  const handleScroll = useCallback((e: any) => {
    clearTimeout(scrollTimeout.current);
    scrollTimeout.current = setTimeout(() => {
      if (sectionRefs && sectionRefs?.current) {
        const sectionPositions = sectionRefs?.current?.map(ref => {
          const { top } = ref?.getBoundingClientRect();
          return { ref, top };
        });
        if (sectionPositions && sectionPositions?.length) {
          const closestSection = sectionPositions?.reduce((prev, curr) => {
            return Math.abs(curr.top - 16) < Math.abs(prev.top) ? curr : prev;
          });

          setFirstVisibleSection(closestSection.ref.id);
        }
      } else return;
    }, 150);
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, true);
    return () => {
      window.removeEventListener('scroll', handleScroll, true);
      clearTimeout(scrollTimeout.current);
    };
  }, [handleScroll]);

  return (
    <>
      <main
        ref={tbodyRef}
        className="flex flex-1 scroll-my-2 flex-col gap-4 overflow-y-auto overscroll-contain scroll-smooth p-2 md:flex-row md:p-4 lg:gap-1"
      >
        {(isResponseLoading ||
          isSettingsLoading ||
          mutations.isSaveSettingsLoading) && (
          <div className="bg-gray-900/8 absolute inset-0 z-50 grid place-items-center backdrop-blur-sm">
            <Spinner size="large" />
          </div>
        )}

        <div className="flex w-full flex-col gap-4 md:overflow-y-scroll md:pe-2">
          <PortalAlerts ref={(ref: any) => (sectionRefs.current[0] = ref)} />
          <Welcome ref={(ref: any) => (sectionRefs.current[1] = ref)} />

          <div
            id="dash-widgets"
            ref={(ref: any) => (sectionRefs.current[2] = ref)}
            className="scroll-my-2"
          >
            <div className="flex flex-col">
              <WidgetList dashSettings={dashSettings} />
            </div>
          </div>
          {!dashSettings?.length ? <NoWidgetState /> : null}
          {!isSettingsLoading && dashSettings?.length !== 0 && (
            <div
              className={classNames(
                'grid min-h-0 flex-auto grow gap-5',
                'grid-cols-[repeat(1,1fr)] lg:grid-cols-[repeat(2,1fr)] xl:grid-cols-[repeat(3,1fr)]',
                dashSettings?.length === 1
                  ? 'lg:!grid-cols-[repeat(1,1fr)] xl:!grid-cols-[repeat(3,1fr)]'
                  : 'lg:grid-cols-[repeat(2,1fr)]',
              )}
            >
              {dashSettings?.includes('Clinical call resolution') && (
                <ClinicalCallResolution widgetInfo={dashSettings} />
              )}
              {dashSettings?.includes('Recent document activity') && (
                <DocumentActivity />
              )}
              {dashSettings?.includes('My collegues') && isExternalUser && (
                <MyCollegues />
              )}
              {dashSettings &&
              dashSettings?.length < filteredMasterData?.length ? (
                <NoWidgetState
                  className={classNames(
                    'hidden xl:grid',
                    dashSettings?.length === 1 &&
                      dashSettings?.includes('Clinical call resolution')
                      ? 'xl:[&:last-child:nth-child(3n-1)]:col-[span_1]'
                      : 'xl:[&:last-child:nth-child(3n-1)]:col-[span_2]',
                    'xl:[&:last-child:nth-child(3n-2)]:col-[span_3]',
                  )}
                />
              ) : null}
            </div>
          )}
        </div>

        <RightPanel ref={(ref: any) => (sectionRefs.current[3] = ref)} />
      </main>
      <nav className="flex justify-center border-t border-light-light bg-white shadow-lg dark:border-dark-medium dark:bg-neutral-600 sm:hidden">
        <ul className="flex list-none justify-center gap-3">
          {dashboardNav.map(({ id, nav }) => {
            return (
              <li key={id}>
                <a
                  href={id}
                  className={`inline-block border-b-4 px-3 py-2 font-medium ${
                    id?.slice(1) === firstVisibleSection
                      ? 'border-b-primary-500  text-primary-500'
                      : 'border-b-transparent'
                  }`}
                  onClick={() => {
                    handleNav(id?.slice(1));
                  }}
                >
                  {nav}
                </a>
              </li>
            );
          })}
        </ul>
      </nav>
    </>
  );
}
