import { useQuery, UseQueryResult } from 'react-query';

import { useAuth } from 'src/hooks';

import { getRequestType } from 'src/utils/urls';

import { formatSelectOption } from 'src/utils/common';

async function fetchRequestType({
  token,
  tokenType,
}: TVaribles): Promise<ApiResult> {
  const response = await fetch(getRequestType, {
    method: 'GET',
    headers: {
      Authorization: `${tokenType} ${token}`,
    },
  });
  return response.json();
}

export type TResult = Array<{ value: number; label: string }>;
export type ApiResult = Array<{ Id: number; Description: string }>;
export type TError = { message: string };
export type TVaribles = {
  token: string;
  tokenType: string;
};

function useGetRequestType(): UseQueryResult<TResult, TError> {
  const { token, tokenType } = useAuth();
  return useQuery(
    'request_type',
    async () => {
      const result = await fetchRequestType({
        token,
        tokenType,
      });

      return formatSelectOption(result, 'Id', 'Description');
    },
    {
      staleTime: 60 * 1000,
      refetchOnWindowFocus: false,
    },
  );
}

export { useGetRequestType };
