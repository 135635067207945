import { ResponseType } from '../types/QuestionsTabType';

const sortOptions: Array<{
  key: string;
  value: number;
  label: string;
}> = [
  {
    key: 'id',
    value: 0,
    label: 'ID',
  },

  {
    key: 'firstName',
    value: 6,
    label: 'First name',
  },

  {
    key: 'lastName',
    value: 7,
    label: 'Last name',
  },
  {
    key: 'alternateName',
    value: 8,
    label: 'Preferred name',
  },
  {
    key: 'cid',
    value: 2,
    label: 'CID',
  },
  {
    key: 'sid',
    value: 4,
    label: 'SID',
  },
  {
    key: 'pid',
    value: 3,
    label: 'PID',
  },

  {
    key: 'primaryPhone',
    value: 10,
    label: 'Primary phone number',
  },
  {
    key: 'city',
    value: 13,
    label: 'City',
  },
  {
    key: 'state',
    value: 14,
    label: 'State',
  },
];

const successMsg: string = 'Person updated successfully.';

const warningMsg: string =
  'There are required field(s) to be filled in Person information.';

const selectOptions: Array<{ value: string; label: string }> = [
  { value: '', label: '' },
  { value: 'Yes', label: 'Yes' },
  { value: 'No', label: 'No' },
];

function getphnType(
  phoneTypeList: Array<{ Id: number; Description: string }> | undefined,
  id: string,
) {
  if (phoneTypeList && id) {
    const value = phoneTypeList?.find(
      (item: any) => item?.Id?.toString() === id,
    );
    return value;
  }
}

//restricted questions check

function restrictedQuestions(item: ResponseType) {
  if (
    item?.Type !== 'Scheduler' &&
    item?.Type !== 'Resources' &&
    item?.Type !== 'Bed Request' &&
    item?.Type !== 'PDF' &&
    item?.Type !== 'Link' &&
    item?.Type !== 'Outbound Call' &&
    item?.Type !== 'Preliminary Report' &&
    item?.Type !== 'Tracker' &&
    item?.Type !== 'Display Only'
  ) {
    return true;
  } else return false;
}

export {
  sortOptions,
  successMsg,
  warningMsg,
  selectOptions,
  getphnType,
  restrictedQuestions,
};
