import React, {
  useRef,
  useEffect,
  ReactElement,
  useState,
  useCallback,
} from 'react';
import { useQueryClient } from 'react-query';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Disclosure } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import { EllipsisHorizontalIcon } from '@heroicons/react/20/solid';
import { ChevronDownIcon as ChevronDownIconMicro } from '@heroicons/react/16/solid';
import pDebounce from 'p-debounce';

import { useAuth, useGetStoredPermissions } from 'src/hooks';

import { Button, ColorScheme } from 'src/ui/components';
import Profile from './components/Profile';

import ProtoCallLogo from 'src/assets/images/ProtoCall-Logo.png';
import LflLogo from 'src/assets/images/LFL-logo.png';

import {
  navBaseClasses,
  navBaseClassesResponsive,
} from './utils/getClassNames';
import { getNavigationItems } from './utils/getNavigationItems';
import { classNames } from 'src/utils/className';
import { cancelWarningMsg } from 'src/utils/appConstants';

import { usePersonAlertStore } from 'src/features/AddEditPersonAlert/store/personAlertStore';

import { NavItemType, RenderNavItmType } from './types/navTypes';

export default function Header({
  isBlockAuthflow,
}: {
  isBlockAuthflow: boolean;
}): ReactElement {
  const queryClient = useQueryClient();
  const location = useLocation();
  const navigate = useNavigate();
  const { isExternalUser } = useAuth();

  const permissions = useGetStoredPermissions(isBlockAuthflow);

  const isForgotEmailLink = location?.pathname
    .split('/')
    .includes('ForgotPassword');
  const isResetPassword = location?.pathname
    .split('/')
    .includes('ResetPassword');

  const navUlRef = useRef<HTMLUListElement>(null);
  const navRef = useRef<HTMLDivElement>(null);
  const navLinks = useRef<Array<HTMLLIElement | null>>([]);
  const moreMenuRef = useRef<HTMLDivElement>(null);
  const moreBtnRef = useRef<HTMLButtonElement>(null);
  const moreMenuUlRef = useRef<HTMLUListElement>(null);

  const handleResize = useCallback(() => {
    const availableSpace =
      navRef?.current!.offsetWidth - moreBtnRef?.current!.offsetWidth - 30;
    let navLinksWidth = 0;

    // Calculate the total width of nav links
    navUlRef.current?.querySelectorAll('li').forEach(li => {
      navLinksWidth += li.offsetWidth;
    });

    if (navLinksWidth > availableSpace) {
      const lastItem: HTMLLIElement | null =
        navUlRef.current?.querySelector('li:last-child')!;

      if (lastItem) {
        lastItem.dataset.width = lastItem.offsetWidth.toString();
        moreMenuUlRef.current?.prepend(lastItem);
        handleResize(); // Recalculate after moving an item
      }
    } else {
      const firstMoreElement: HTMLLIElement | null =
        moreMenuUlRef.current?.querySelector('li:first-child')!;

      if (firstMoreElement) {
        if (
          navLinksWidth + Number(firstMoreElement.dataset.width) <
          availableSpace
        ) {
          navUlRef.current?.append(firstMoreElement);
          handleResize(); // Recalculate after moving an item back
        }
      }
    }

    // Update visibility of more menu
    const moreMenuLinks = moreMenuUlRef.current?.querySelectorAll('li');
    if (moreMenuLinks && moreMenuLinks.length > 0) {
      moreMenuRef.current?.classList.remove('hidden');
    } else {
      moreMenuRef.current?.classList.add('hidden');
    }
  }, []);

  // const debouncedResize = pDebounce(handleResize, 100); // Adjust debounce delay as needed

  // useEffect(() => {
  //   // Initial call to handleResize
  //   // handleResize();

  //   const initialResizeTimeout = setTimeout(() => {
  //     handleResize();
  //     navRef.current?.classList.remove('opacity-0');
  //   }, 1000);

  //   // Add resize event listener
  //   window.addEventListener('resize', () => {
  //     handleResize();
  //   });

  //   // Cleanup: Remove event listener
  //   return () => {
  //     window.removeEventListener('resize', handleResize);
  //     clearTimeout(initialResizeTimeout);
  //   };
  // }, [handleResize]);

  // Above code is kept intact as the below solution is a trial - 25June2024
  const constructedNavLinks = React.useMemo(() => {
    if (permissions && Object.keys(permissions)?.length > 0) {
      const value = getNavigationItems(permissions, isExternalUser)?.filter(
        item => item?.isVisible === true,
      );
      return value;
    }
  }, [isExternalUser, permissions]);

  useEffect(() => {
    if (constructedNavLinks && permissions) {
      let resizeTimer: number | null = null;
      let attempts = 0;
      const maxAttempts = 10;

      const checkAndResize = () => {
        handleResize();
        attempts++;

        // Check if the layout is as expected
        const visibleItems =
          navUlRef.current?.querySelectorAll('li').length || 0;
        const moreItems =
          moreMenuUlRef.current?.querySelectorAll('li').length || 0;

        if (attempts < maxAttempts) {
          resizeTimer = window.setTimeout(checkAndResize, 50);
        }
      };

      // Initial resize check
      resizeTimer = window.setTimeout(() => {
        checkAndResize();
        navRef.current?.classList.remove('opacity-0');
      }, 10);

      // Handle window resize
      const handleWindowResize = () => {
        if (resizeTimer) {
          clearTimeout(resizeTimer);
        }
        resizeTimer = window.setTimeout(checkAndResize, 50);
      };

      window.addEventListener('resize', handleWindowResize);

      return () => {
        if (resizeTimer) {
          clearTimeout(resizeTimer);
        }
        window.removeEventListener('resize', handleWindowResize);
      };
    }
  }, [handleResize, permissions, constructedNavLinks]);

  const navClass = (currentItem: boolean): string => {
    const navActiveClasses = 'bg-primary text-on-primary ring-primary';
    const navInActiveClasses =
      'text-neutral-600 hover:bg-neutral-100 hover:text-neutral-900 dark:text-neutral-400 dark:hover:bg-neutral-900 dark:hover:text-neutral-100';
    const common = currentItem ? navActiveClasses : navInActiveClasses;
    return common;
  };

  const navClassResponsive = (currentItem: boolean): string => {
    const navActiveClasses = 'bg-primary text-on-primary';
    const navInActiveClasses =
      'text-neutral-600 hover:bg-neutral-100 hover:text-neutral-900 dark:text-neutral-400 dark:hover:bg-neutral-900 dark:hover:text-neutral-100';
    const common = currentItem ? navActiveClasses : navInActiveClasses;
    return common;
  };

  const isDirty = usePersonAlertStore(useCallback(state => state.isDirty, []));
  const setIsDirty = usePersonAlertStore(
    useCallback(state => state.setIsDirty, []),
  );
  const setAllowFetch = usePersonAlertStore(
    useCallback(state => state.setAllowFetch, []),
  );

  //navigating conditionally
  function customNavigation(event: any, nvItm: any) {
    if (isDirty && location.pathname !== '/PersonAlert') {
      event.preventDefault();
      const confirmLeave = window.confirm(cancelWarningMsg);
      if (confirmLeave) {
        navigate(nvItm);
        setAllowFetch(false);
        setIsDirty(false);
      } else {
        setAllowFetch(true);
      }
    } else {
      setIsDirty(false);
      navigate(nvItm);
      setAllowFetch(false);
    }
  }

  function RenderNavItems({ nvItm }: RenderNavItmType) {
    return (
      <Link
        to={nvItm.to === 'About' ? 'Home/About' : nvItm.to}
        className={`${navClass(
          location.pathname === `${'/'}${nvItm.to}`,
        )} ${navBaseClasses}`}
        onClick={event => {
          queryClient.invalidateQueries(['create-edit-user']);
          queryClient.invalidateQueries(['add-edit-coverage-request']);
          queryClient.invalidateQueries(['get_person_alerts_list']);
          queryClient.invalidateQueries(['add-edit-person-alert']);
          queryClient.invalidateQueries(['get_portal_alerts']);
          customNavigation(event, nvItm.to);
        }}
      >
        {nvItm.name}
      </Link>
    );
  }

  function RenderNavItemResponsive({ nvItm }: RenderNavItmType) {
    return (
      <Disclosure.Button
        as={Link}
        to={nvItm.to}
        className={`${navClassResponsive(
          location.pathname === `${'/'}${nvItm.to}` ? true : false,
        )} ${navBaseClassesResponsive}`}
        onClick={event => {
          queryClient.invalidateQueries(['create-edit-user']);
          queryClient.invalidateQueries(['add-edit-coverage-request']);
          queryClient.invalidateQueries(['get_person_alerts_list']);
          queryClient.invalidateQueries(['add-edit-person-alert']);
          queryClient.invalidateQueries(['get_portal_alerts']);
          customNavigation(event, nvItm.to);
        }}
      >
        {nvItm.name}
      </Disclosure.Button>
    );
  }

  return (
    <Disclosure
      as="header"
      className="bg-white shadow dark:bg-neutral-800 print:hidden"
    >
      {({ open }) => (
        <>
          <div className="px-2 sm:px-3 lg:px-4">
            <div className="relative flex h-11 items-center justify-between">
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                {/* Mobile menu button*/}
                <Disclosure.Button as={Button} variant="subtle" size="small">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                <div className="min-w-[90px] shrink-0 self-center">
                  <img
                    src={
                      process.env.REACT_APP_THEME === 'proteus'
                        ? ProtoCallLogo
                        : LflLogo
                    }
                    alt="Logo"
                    className="max-h-[30px] dark:mix-blend-screen dark:hue-rotate-180 dark:invert"
                  />
                </div>
                <div className="hidden flex-1 sm:ml-6 sm:flex">
                  <nav
                    className="flex flex-1 opacity-0 transition-opacity duration-700"
                    ref={navRef}
                  >
                    <ul ref={navUlRef} id="global-nav">
                      {permissions &&
                        Object.keys(permissions)?.length > 0 &&
                        constructedNavLinks?.map((nvItm, index) => (
                          <li
                            className="relative inline-flex px-2"
                            key={`${nvItm}${index}`}
                            ref={el => (navLinks.current[index] = el)}
                          >
                            <RenderNavItems nvItm={nvItm} />
                          </li>
                        ))}
                    </ul>
                    <div ref={moreMenuRef} className="group hidden">
                      <button
                        ref={moreBtnRef}
                        className={classNames(
                          navBaseClasses,
                          'text-neutral-600 hover:bg-neutral-100 hover:text-neutral-900 dark:text-neutral-400 dark:hover:bg-neutral-900 dark:hover:text-neutral-100',
                          'peer h-full',
                        )}
                      >
                        <span>More</span>{' '}
                        <ChevronDownIconMicro className="-mt-1 inline-block h-4 w-4" />
                      </button>
                      <div className="relative -top-2 hidden group-hover:block group-focus:block peer-hover:block peer-focus:block">
                        <ul
                          ref={moreMenuUlRef}
                          className="absolute right-0 z-[11] mt-2 flex w-56 origin-top-right flex-col justify-stretch space-y-1 rounded-md bg-white px-1 py-2 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none dark:bg-neutral-700"
                        ></ul>
                      </div>
                    </div>
                  </nav>
                </div>
              </div>
              <div className="absolute inset-y-0 right-0 flex items-center gap-3 pr-2 sm:static sm:inset-auto sm:ml-6 sm:gap-1 sm:pr-0">
                {!isForgotEmailLink && !isResetPassword && (
                  <>
                    <ColorScheme />
                    <Profile />
                  </>
                )}
              </div>
            </div>
          </div>
          {/* responsive side bar menus */}
          <Disclosure.Panel className="absolute z-20 w-full bg-white shadow-xl dark:bg-neutral-800 sm:hidden">
            <div className="space-y-1 px-2 pb-3 pt-2">
              {getNavigationItems(permissions, isExternalUser)?.map(
                (nvItm: NavItemType, index: number) =>
                  nvItm?.isVisible && (
                    <RenderNavItemResponsive
                      key={`${nvItm}${index}`}
                      nvItm={nvItm}
                    />
                  ),
              )}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
