import React, { ReactElement, Fragment, useState } from 'react';
import { useQueryClient } from 'react-query';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon, InformationCircleIcon } from '@heroicons/react/20/solid';

import {
  inputLabelClasses,
  Button,
  Spinner,
  TextField,
  Alert,
  Checkbox,
} from 'src/ui/components';

import { useSendEmail } from '../api';

import { useExtracoverageRequestStore } from '../store/extracoverageStore';
import { complexEmailRegex, createMarkup, emailRegex } from 'src/utils/common';
import {
  recipientsErrorMsg,
  recipientsMsg,
  sendEmailSuccessMsg,
} from '../utils/coverageRequestConstants';
import { showErrorToast, showSuccessToast } from 'src/utils/ToastNotification';

function SendApprovalEmail({
  Id,
  setSelectedExtraCoverage,
}: {
  Id?: number;
  setSelectedExtraCoverage?: (value: any) => void;
}): ReactElement {
  const queryClient = useQueryClient();

  const [showError, setShowError] = useState<boolean>(false);
  const [warningMsg, setWarningMsg] = useState<string>('');

  const isEnteredBy = useExtracoverageRequestStore(
    React.useCallback(state => state.isEnteredBy, []),
  );
  const isOtherReceipents = useExtracoverageRequestStore(
    React.useCallback(state => state.isOtherReceipents, []),
  );
  const isSystemConfirmation = useExtracoverageRequestStore(
    React.useCallback(state => state.isSystemConfirmation, []),
  );

  const isShowSendApproval = useExtracoverageRequestStore(
    React.useCallback(state => state.isShowSendApproval, []),
  );

  const updateStoreByKey = useExtracoverageRequestStore(
    React.useCallback(state => state.updateStoreByKey, []),
  );

  const isFromGrid = useExtracoverageRequestStore(
    React.useCallback(state => state.isFromGrid, []),
  );

  const defaultEmail = useExtracoverageRequestStore(
    React.useCallback(state => state.defaultEmail, []),
  );

  const previousSendEmail = useExtracoverageRequestStore(
    React.useCallback(state => state.previousdefaultEmail, []),
  );

  const { mutate: onSendEmail, isLoading: isSendingProgress } = useSendEmail();

  function onRecipientFocusOutHandler() {
    const emailIds: string[] = defaultEmail.AdditionalRecipients.split(',')
      .map(elem => elem.trim())
      .filter(Boolean);

    const isValidEmail = emailIds?.every(email => emailRegex.test(email));
    const hasInvalidPattern = emailIds?.every(email =>
      complexEmailRegex.test(email),
    );
    setShowError(
      emailIds?.length > 0 &&
        warningMsg === '' &&
        (!isValidEmail || !hasInvalidPattern),
    );
  }

  function onSendApprovalMail() {
    const body = {
      ...defaultEmail,
      Recipients: isEnteredBy ? defaultEmail?.Recipients : '',
      AdditionalRecipients: isOtherReceipents
        ? defaultEmail?.AdditionalRecipients !== null &&
          defaultEmail?.AdditionalRecipients.length > 0
          ? defaultEmail?.AdditionalRecipients?.split(',')
              ?.map(elem => elem.trim())
              ?.filter(Boolean)
              ?.join(',')
          : ''
        : '',
      SystemEmailAddress: isSystemConfirmation
        ? defaultEmail?.SystemEmailAddress
        : '',
    };

    onSendEmail(
      { ...body, id: Id },
      {
        onSuccess: () => {
          setSelectedExtraCoverage && setSelectedExtraCoverage(null);
          updateStoreByKey('isShowSendApproval', false);
          updateStoreByKey('isFromGrid', false);
          queryClient.invalidateQueries('get_extra_coverage_requests');
          showSuccessToast({
            message: sendEmailSuccessMsg,
          });
        },
        onError: err => {
          if (Array.isArray(err) && err.length) {
            showErrorToast({ message: err[0] });
          } else {
            showErrorToast({ message: err?.Message });
          }
        },
      },
    );
  }

  function onCancelHandler() {
    updateStoreByKey('isShowSendApproval', false);
    updateStoreByKey('isFromGrid', false);
    updateStoreByKey('isEnteredBy', true);
    updateStoreByKey('isOtherReceipents', true);
    updateStoreByKey('isSystemConfirmation', true);
    updateStoreByKey('defaultEmail', previousSendEmail);
  }

  return (
    <>
      <Transition appear show={isShowSendApproval} as={Fragment}>
        <Dialog
          as="div"
          className="max-w- relative z-10 w-11/12"
          onClose={() => {
            onCancelHandler();
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/50" />
          </Transition.Child>

          <div className="fixed inset-0 w-screen overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-3xl transform overflow-hidden rounded-2xl bg-white text-left align-middle shadow-xl transition-all dark:bg-neutral-800">
                  <div className="flex items-center justify-between gap-3 border-b p-4 dark:border-dark-medium">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6"
                    >
                      Email extra coverage approval
                    </Dialog.Title>
                    <button
                      type="button"
                      className=" text-gray-400 hover:text-gray-500 dark:text-neutral-500 dark:hover:text-neutral-300 sm:right-4 sm:top-4"
                      onClick={() => onCancelHandler()}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div className="space-y-8 p-4">
                    <div className="space-y-2">
                      <div className="flex gap-3">
                        <Checkbox
                          id="check-enteredBy"
                          label="Entered by"
                          className="mt-1.5 min-w-44"
                          checked={isEnteredBy}
                          onChange={e =>
                            updateStoreByKey('isEnteredBy', e.target.checked)
                          }
                        />
                        <div className="grow">
                          <TextField
                            srOnlyLabel="Recipient(s)"
                            id="recipients"
                            className="w-full"
                            value={defaultEmail?.Recipients}
                            onChange={e =>
                              updateStoreByKey('defaultEmail', {
                                ...defaultEmail,
                                Recipients: e.target.value,
                              })
                            }
                            onBlur={() => onRecipientFocusOutHandler()}
                            disabled
                          />
                        </div>
                      </div>

                      <div className="flex gap-3">
                        <Checkbox
                          id="check-otherRecipients"
                          label="Other recipients"
                          className="mt-1.5 min-w-44"
                          checked={isOtherReceipents}
                          onChange={e =>
                            updateStoreByKey(
                              'isOtherReceipents',
                              e.target.checked,
                            )
                          }
                        />
                        <div>
                          <TextField
                            srOnlyLabel="Other recipients"
                            id="otherRecipients"
                            className="w-full"
                            value={defaultEmail?.AdditionalRecipients}
                            onChange={e =>
                              updateStoreByKey('defaultEmail', {
                                ...defaultEmail,
                                AdditionalRecipients: e.target.value,
                              })
                            }
                            onBlur={() => onRecipientFocusOutHandler()}
                            validationError={
                              showError && warningMsg.length === 0
                                ? recipientsErrorMsg
                                : ''
                            }
                            disabled={!isOtherReceipents}
                          />
                          <p className="flex items-start gap-1 text-xs text-gray-600 dark:text-neutral-400">
                            <InformationCircleIcon
                              className="relative -top-px h-5 w-5"
                              aria-hidden="true"
                            />
                            <span>{recipientsMsg}</span>
                          </p>
                          {warningMsg.length > 0 && (
                            <Alert variant="warning" className="">
                              {warningMsg}
                            </Alert>
                          )}
                        </div>
                      </div>

                      <div className="flex gap-3">
                        <Checkbox
                          id="check-systemConfirmation"
                          label="System confirmation"
                          className="mt-1.5 min-w-44"
                          checked={isSystemConfirmation}
                          onChange={e =>
                            updateStoreByKey(
                              'isSystemConfirmation',
                              e.target.checked,
                            )
                          }
                        />
                        <TextField
                          srOnlyLabel="System confirmation"
                          id="systemConfirmation"
                          className="grow"
                          value={defaultEmail?.SystemEmailAddress}
                          disabled
                        />
                      </div>
                    </div>
                    <div>
                      <label
                        htmlFor=""
                        className={[inputLabelClasses, 'mb-1 after:ml-0'].join(
                          ' ',
                        )}
                      >
                        Subject
                      </label>
                      <label className="w-full sm:w-auto">
                        {defaultEmail?.Subject}
                      </label>
                    </div>
                    <div>
                      <label
                        htmlFor=""
                        className={[
                          inputLabelClasses,
                          'mb-1 after:ml-0.5 after:text-red-500 after:content-["*"] dark:after:text-red-400',
                        ].join(' ')}
                      >
                        Messages
                      </label>
                      <div
                        className="pr-coverage-email-wrapper overflow-auto rounded text-black"
                        dangerouslySetInnerHTML={createMarkup(
                          defaultEmail?.Body?.replace(
                            /<style>[\s\S]*?<\/style>/gi,
                            '',
                          ),
                        )}
                        onBlur={e => {
                          updateStoreByKey('defaultEmail', {
                            ...defaultEmail,
                            Body: e.currentTarget.innerHTML,
                          });
                        }}
                      />
                    </div>
                  </div>

                  <div className="flex justify-end gap-3 bg-gray-100 px-4 py-3 dark:bg-neutral-700/30">
                    <Button
                      onClick={() => onCancelHandler()}
                      disabled={isSendingProgress}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="primary"
                      onClick={() =>
                        isFromGrid
                          ? onSendApprovalMail()
                          : updateStoreByKey('isShowSendApproval', false)
                      }
                      disabled={
                        showError ||
                        !(
                          isEnteredBy ||
                          isSystemConfirmation ||
                          isOtherReceipents
                        ) ||
                        isSendingProgress
                      }
                    >
                      {isFromGrid ? 'Send Email' : 'Save'}
                    </Button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
          {isSendingProgress && (
            <div className="fixed inset-0 z-20 grid place-content-center">
              <Spinner size="large" />
            </div>
          )}
        </Dialog>
      </Transition>
    </>
  );
}
export { SendApprovalEmail };
