import React, { ReactElement } from 'react';
import dayjs from 'dayjs';
import { Tab } from '@headlessui/react';

import { EmptyState, Spinner } from 'src/ui/components';

import { useGetReleaseNotes } from '../api';

import { VersionType } from '../types/releaseApiTypes';

export function ReleaseNotes(): ReactElement {
  const { data, isLoading } = useGetReleaseNotes();

  //render version list
  function renderReleaseNotes(item: VersionType): ReactElement {
    return (
      <div className="prose mb-3 rounded-lg bg-white p-4 shadow dark:bg-neutral-800">
        <h3 className="">
          <span className="text-black dark:text-white">{item?.Version} </span>
          <small className="text-xs text-gray-500 dark:text-neutral-400">
            (ReleasedOn:
            <span className="font-bold">
              {' '}
              {dayjs(item?.ReleasedOn).format('YYYY-MM-DD')}
            </span>{' '}
            )
          </small>
        </h3>
        <ul className="list-disc">
          {item?.ReleaseNotes?.map((item, index) => {
            return (
              <li
                className="leading-normal text-black dark:text-white"
                key={`${index}${item}`}
              >
                {item}
              </li>
            );
          })}
        </ul>
      </div>
    );
  }

  function renderImages(): ReactElement {
    return (
      <>
        <div className="flex grow items-center justify-center px-[6vw] pb-[3.125vw] pt-[2.5vw] ">
          <div className="flex flex-wrap items-center justify-center gap-[50px] p-4 lg:gap-[3.125vw]">
            <img
              className="h-auto max-h-[120px] w-auto min-w-[200px] max-w-[300px] object-contain mix-blend-multiply dark:mix-blend-screen dark:hue-rotate-180 dark:invert"
              src="https://protocallservices.com/wp-content/uploads/2022/09/CARF_Seal.jpg"
              alt="logo of CARF Accredited"
              width="900"
              height="899"
              loading="eager"
            />
            <img
              className="h-auto max-h-[120px] w-auto min-w-[200px] max-w-[300px] object-contain dark:mix-blend-screen dark:hue-rotate-180 dark:invert"
              src="https://protocallservices.com/wp-content/uploads/2022/09/AAS-Full-Logo.png"
              alt="logo of American Association of Suicidology"
              width="820"
              height="150"
              loading="eager"
            />
            <img
              className="h-auto max-h-[120px] w-auto min-w-[200px] max-w-[300px] object-contain dark:mix-blend-screen dark:hue-rotate-180 dark:invert"
              src="https://protocallservices.com/wp-content/uploads/2022/09/988_NSPL.png"
              alt="logo of 998 Suicide & Crisis Lifeline"
              width="1200"
              height="1208"
              loading="eager"
            />
            <img
              className="h-auto max-h-[120px] w-auto min-w-[200px] max-w-[300px] object-contain dark:mix-blend-screen dark:hue-rotate-180 dark:invert"
              src="https://protocallservices.com/wp-content/uploads/2022/09/WA-State.png"
              alt="Logo of Washington Department of Social & Health Services"
              width="1200"
              height="423"
              loading="eager"
            />
            <img
              className="h-auto max-h-[120px] w-auto min-w-[200px] max-w-[300px] object-contain dark:mix-blend-screen dark:hue-rotate-180 dark:invert"
              src="https://protocallservices.com/wp-content/uploads/2022/11/hitrust-logo-r-color-139.png"
              alt="Logo of HITRUST"
              width="435"
              height="127"
              loading="eager"
            />
          </div>
        </div>
      </>
    );
  }
  return (
    <Tab.Panel as="div" className="w-full overflow-y-auto">
      {isLoading && (
        <div className="fixed inset-0 z-20 grid place-content-center">
          <Spinner size="large" />
        </div>
      )}
      {process.env.REACT_APP_THEME === 'lfl' && !isLoading ? (
        <>
          <h2 className="hidden truncate px-10 pt-3 text-lg font-semibold text-gray-950 dark:text-white sm:tracking-tight lg:block">
            Release notes
          </h2>
          <div className=" px-5 py-5 lg:px-10">
            {data?.map((item: VersionType) => {
              return renderReleaseNotes(item);
            })}
            {data?.length === 0 && (
              <EmptyState message="No information available" />
            )}
          </div>
        </>
      ) : (
        !isLoading && <>{renderImages()}</>
      )}
    </Tab.Panel>
  );
}
