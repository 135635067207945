import { create } from 'zustand';
import { produce } from 'immer';

import * as ProviderConfigApi from './fieldConfigApiStore';

import { mapDefaultProviderConfig } from '../utils/resourceHelper';

import {
  FieldConfiguration,
  GetConfigArguments,
} from '../types/fieldConfigurationStoreTypes';

export const useFieldConfigurationStore = create<FieldConfiguration>()(
  (set, get) => ({
    isLoading: true,
    basicConfiguration: undefined,
    resourceFields: [],
    searchGroups: undefined,
    resourceFieldOptions: undefined,
    selectedOptions: {},
    sameAsSearchResult: false,

    getProviderConfiguration: async ({
      token,
      tokenType,
      providerId,
    }: GetConfigArguments) => {
      set(state => ({ isLoading: true }));
      await ProviderConfigApi.fetchSearchGroups({ token, tokenType }).then(
        searchGroups => {
          if (searchGroups) set(state => ({ searchGroups: [...searchGroups] }));
        },
      );

      await ProviderConfigApi.fetchResourceFields({
        token,
        tokenType,
        providerId,
      }).then(resource => {
        if (resource) {
          const resourceValue = resource.sort((a, b) =>
            a.fullHeirarchialName.localeCompare(b.fullHeirarchialName),
          );
          set(state => ({
            resourceFields: [...resourceValue],
            resourceFieldOptions: resourceValue.map(item => ({
              value: item.id,
              label: item.fullHeirarchialName,
              resourceFieldType: item.resourceFieldType,
            })),
          }));
        }
      });

      await ProviderConfigApi.fetchProviderFieldConfigurations({
        token,
        tokenType,
        providerId,
      }).then(result => {
        if (result) {
          const searchableFields = result.searchableFields;
          let defaultOption = {};
          // To set already configured values as selected in the searchable fields.
          if (searchableFields !== null && searchableFields.length > 0) {
            searchableFields.forEach(searchField => {
              defaultOption = {
                ...defaultOption,
                ...mapDefaultProviderConfig(
                  searchField.fields,
                  searchField.groupId,
                ),
              };
            });
          }
          // To set already configured values as selected in the add to search results.
          if (result.addToSearchResult && result.addToSearchResult.length > 0) {
            defaultOption = {
              ...defaultOption,
              ...mapDefaultProviderConfig(
                result.addToSearchResult,
                'addToSearchResult',
              ),
            };
          }

          // To set already configured values as selected in the add to report.
          if (result.addToReport && result.addToReport.length > 0) {
            defaultOption = {
              ...defaultOption,
              ...mapDefaultProviderConfig(result.addToReport, 'addToReport'),
            };
          }
          set(() => ({
            basicConfiguration: { ...result },
            selectedOptions: defaultOption,
          }));
        }
        set(state => ({ isLoading: false }));
      });
    },

    setSelectedOptions: (key: string, option: any) => {
      const selectedItems = get().selectedOptions;
      if (selectedItems.hasOwnProperty(key) && option.length > 5) return;
      set(
        produce(state => {
          state.selectedOptions = {
            ...state.selectedOptions,
            [key]: option || undefined,
          };
        }),
      );
    },

    setSameAsSearchResult: (val: boolean) => {
      set(state => ({ sameAsSearchResult: val }));
      if (val) {
        // if sameAsSearchResult is true, then addToReport having the same value of addToSearchResult
        const options = get().selectedOptions;
        set(state => ({
          selectedOptions: {
            ...options,
            addToReport: options['addToSearchResult'],
          },
        }));
      }
    },
    deleteProviderStore: () => {
      set(state => {
        return {
          ...state,
          basicConfiguration: undefined,
          resourceFields: [],
          searchGroups: undefined,
          resourceFieldOptions: undefined,
          selectedOptions: {},
          sameAsSearchResult: false,
        };
      });
    },
  }),
);
