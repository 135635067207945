import { getExtraCoverageDetails } from 'src/utils/urls';

import { GetRequestType } from '../types/requestStoreTypes';
import { ExtraCoverageType } from './../../ExtraCoverageRequests/types/extraCoverageRequestLists.d';

//fetch details of an extra coverage requset.
const fetchExtraCoverageRequestInfo = async ({
  token,
  tokenType,
  id,
}: Partial<GetRequestType>): Promise<ExtraCoverageType | undefined> => {
  if (id) {
    const response = await fetch(getExtraCoverageDetails(parseInt(id)), {
      method: 'GET',
      headers: {
        Authorization: `${tokenType} ${token}`,
      },
    });
    return response.json();
  }
};

export { fetchExtraCoverageRequestInfo };
