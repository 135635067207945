import { useQuery, UseQueryResult, useQueryClient } from 'react-query';

import { useAuth } from 'src/hooks/useAuth';
import { getReInviteUser } from 'src/utils/urls';
import { showSuccessToast } from 'src/utils/ToastNotification';

async function fetchReinviteUser({
  token,
  tokenType,
  id,
}: TVaribles): Promise<TResult> {
  const response = await fetch(getReInviteUser(id), {
    method: 'GET',
    headers: {
      Authorization: `${tokenType} ${token}`,
    },
  });
  return response.json();
}

export type TResult = boolean;
export type TError = { message: string };
export type TVaribles = {
  token: string;
  tokenType: string;
  id: string;
};

function useGetReInvite(id: string): UseQueryResult<TResult, TError> {
  const { token, tokenType } = useAuth();
  const queryClient = useQueryClient();
  return useQuery(
    'get_user_re_invite',
    async () => {
      const result = await fetchReinviteUser({ token, tokenType, id });
      queryClient.invalidateQueries('get_users_list');
      showSuccessToast({
        message: 'Re-invitation send successfully.',
      });

      return result;
    },
    {
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      enabled: false,
    },
  );
}

export { useGetReInvite };
