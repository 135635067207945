import React, { Component } from 'react';
import { User } from 'oidc-client';

import { AuthServiceSingleton } from './authService';

export type AuthContextType = {
  signinRedirectCallback: () => void;
  logout: () => void;
  signoutRedirectCallback: () => void;
  isAuthenticated: () => boolean;
  signinRedirect: () => void;
  signinSilentCallback: () => void;
  createSigninRequest: () => void;
  user: User | null;
  getUser: () => void;
};

export const AuthContext: React.Context<AuthContextType> =
  React.createContext<AuthContextType>({
    signinRedirectCallback: () => {},
    logout: () => {},
    signoutRedirectCallback: () => {},
    isAuthenticated: () => false,
    signinRedirect: () => {},
    signinSilentCallback: () => {},
    createSigninRequest: () => {},
    getUser: () => {},
    user: null,
  });

export const AuthConsumer = AuthContext.Consumer;

export class AuthProvider extends Component<any, any> {
  authService;

  constructor(props: any) {
    super(props);
    this.authService = AuthServiceSingleton.getInstance();
  }
  render() {
    return (
      <AuthContext.Provider value={this.authService}>
        {this.props.children}
      </AuthContext.Provider>
    );
  }
}
