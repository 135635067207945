import { useQuery, UseQueryResult } from 'react-query';

import { useAuth } from 'src/hooks';

import { getReleaseNotes } from 'src/utils/urls';

import { ReleaseType } from '../types/releaseApiTypes';

async function fetchReleaseNotes({
  token,
  tokenType,
}: {
  token: string;
  tokenType: string;
}): Promise<ReleaseType> {
  const response = await fetch(getReleaseNotes, {
    method: 'GET',
    headers: {
      Authorization: `${tokenType} ${token}`,
    },
  });
  return response.json();
}
type TError = { message: string };

function useGetReleaseNotes(): UseQueryResult<ReleaseType, TError> {
  const { token, tokenType } = useAuth();
  const { userInfo } = useAuth();
  return useQuery(
    ['get-abot-api'],
    async () => {
      const result = await fetchReleaseNotes({ token, tokenType });

      return result;
    },
    {
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      enabled: process.env.REACT_APP_THEME !== 'proteus',
    },
  );
}

export { useGetReleaseNotes };
