import React, { InputHTMLAttributes, forwardRef } from 'react';
import clsx, { ClassValue } from 'clsx';

import { ExclamationTriangleIcon } from '@heroicons/react/20/solid';

export type TextFieldProps = InputHTMLAttributes<HTMLInputElement> & {
  label?: string;
  id: string;
  inputSize?: 'small' | 'medium' | 'large';
  className?: ClassValue;
  required?: boolean;
  validationError?: string;
  srOnlyLabel?: string;
  type?: InputHTMLAttributes<HTMLInputElement>['type'];
  errorHighlight?: boolean;
};

export const inputBaseClasses =
  'block w-full rounded-md bg-white border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-light-focus dark:bg-neutral-900 dark:text-white dark:ring-neutral-700 dark:placeholder:text-neutral-600 dark:focus:ring-dark-focus disabled:opacity-50 dark:disabled:opacity-30';

export const inputLabelClasses =
  'block text-sm font-medium leading-6 text-gray-900 dark:text-white';

export const inputSizeClasses = {
  small: 'pt-1 pb-1.5 px-3 text-base lg:text-xs leading-5 !rounded',
  medium: 'py-1.5 px-3 text-base lg:text-sm leading-6',
  large: 'pt-2 pr-3 pb-3 pl-3 text-md leading-6',
};

const TextField = forwardRef<HTMLInputElement, TextFieldProps>(
  (
    {
      label,
      id,
      inputSize = 'medium',
      children,
      className,
      required,
      validationError,
      srOnlyLabel,
      type = 'text',
      errorHighlight,
      ...rest
    },
    ref,
  ) => {
    const sizeClass = inputSizeClasses[inputSize];
    const userClasses = clsx(className);
    return (
      <div className={userClasses}>
        {label ? (
          <label htmlFor={id}>
            <div className="flex items-center gap-1.5">
              {errorHighlight && (
                <div className="h-3 w-3">
                  <span className="relative flex h-3 w-3 items-center justify-center">
                    <span className="absolute inline-flex h-full w-full animate-ping rounded-full bg-red-400 opacity-75" />
                    <span className="relative inline-flex h-2 w-2 rounded-full bg-red-500" />
                  </span>
                </div>
              )}
              <span
                className={`${inputLabelClasses} ${
                  inputSize === 'small' ? 'text-xs' : ''
                } ${
                  required
                    ? 'after:ml-0.5 after:text-red-500 after:content-["*"] dark:after:text-red-400'
                    : ''
                }`}
              >
                {label}
              </span>
            </div>
          </label>
        ) : (
          ''
        )}
        {srOnlyLabel ? (
          <label htmlFor={id} className="sr-only">
            {srOnlyLabel}
          </label>
        ) : (
          ''
        )}
        <input
          type={type}
          name={id}
          id={id}
          className={`${inputBaseClasses} ${sizeClass} ${
            inputSize === 'small' ? 'mt-0' : 'mt-0.5'
          }`}
          ref={ref}
          {...rest}
        />
        {validationError && validationError?.length !== 0 && (
          <p className="mt-1 inline-flex items-start gap-1 text-xs text-red-600 dark:text-red-400 md:text-sm">
            <ExclamationTriangleIcon
              className="relative -bottom-[2px] -ml-0.5 h-5 w-5 shrink-0"
              aria-hidden="true"
            />
            {validationError}
          </p>
        )}
      </div>
    );
  },
);

TextField.displayName = 'TextField';

export { TextField };
