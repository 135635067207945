import { useState } from 'react';
import { Profile } from 'oidc-client';

import { AUTH_TOKEN } from 'src/utils/authenticationHelper/authConstants';
import useInterval from './useInterval';

export type AuthInfo = {
  userInfo: Profile | undefined;
  token: string;
  tokenType: string;
  userId: string;
  isExternalUser: boolean;
};

const token_type = 'Bearer';

export function retrieveAndParseToken(showDialog: boolean = true) {
  const auth = localStorage.getItem(AUTH_TOKEN);
  const parsed = auth ? JSON.parse(auth) : null;
  return parsed;
}

export function getTokenWithType() {
  return `${token_type} ${getToken()}`;
}

export function getTokenData() {
  return { token_type, token: getToken() };
}

export function getToken() {
  const parsedAuth = retrieveAndParseToken();
  return parsedAuth?.access_token;
}

function useAuth(): AuthInfo {
  const [oidc, setOidc] = useState<any>(() => {
    return retrieveAndParseToken(false);
  });

  useInterval(() => {
    const data = retrieveAndParseToken();

    if (data?.access_token !== oidc?.access_token) setOidc(data);
  }, 5 * 1000);

  return {
    userInfo: oidc?.profile,
    token: oidc?.access_token ?? '',
    tokenType: token_type,
    userId: oidc?.profile?.sub,
    isExternalUser: oidc?.profile?.idp === 'idsrv' ? true : false,
  };
}

export { useAuth };
