import React, { ReactElement, useCallback, useRef } from 'react';
import { Editor } from '@tinymce/tinymce-react';

import {
  TextField,
  inputLabelClasses,
  DateRangePicker,
} from 'src/ui/components';
import { TinymceEditor } from 'src/ui/components/TinymceEditor';

import { usePortalAlertStore } from '../store/portalAlertStore';
import { applicationThemeStore } from 'src/store/applicationThemeStore';

function PortalAlertContent(): ReactElement {
  const msgEditorRef = useRef<any>();

  const portalAlert = usePortalAlertStore(
    useCallback(state => state?.PortalAlertObj, []),
  );

  const updatePortalAlert = usePortalAlertStore(
    useCallback(state => state?.updatePortalAlert, []),
  );

  const colourScheme = applicationThemeStore(
    React.useCallback(state => state.colourScheme, []),
  );

  return (
    <div className="grow overflow-y-scroll p-3 lg:p-4">
      <div className="container mx-auto space-y-2 sm:space-y-4">
        <div>
          <>
            {portalAlert && (
              <div className="rounded-b-lg border-t bg-white p-4 text-sm text-gray-500 shadow dark:border-neutral-700/50 dark:bg-neutral-800">
                <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
                  <TextField
                    id="title"
                    label="Title"
                    value={portalAlert?.Title}
                    onChange={e => updatePortalAlert('Title', e.target.value)}
                    required
                  />
                  <div>
                    <label
                      htmlFor=""
                      className={[
                        inputLabelClasses,
                        'mb-1 after:ml-0.5 after:text-red-500 after:content-["*"] dark:after:text-red-400',
                      ].join(' ')}
                    >
                      Effective date - Expiration date
                    </label>
                    <DateRangePicker
                      rangeType="future"
                      placeholder=""
                      format="MM/DD/YYYY HH:mm:ss"
                      value={portalAlert?.EnteredDate}
                      setValues={updatePortalAlert}
                      keyValue="EnteredDate"
                      title="Effective date - Expiration date"
                      id="date-picker"
                    />
                  </div>
                </div>
                <div className="mt-4">
                  <label
                    className={`${inputLabelClasses}  after:text-red-500  after:content-["*"] dark:after:text-red-400`}
                  >
                    Message
                  </label>

                  <TinymceEditor
                    reference={(instance: Editor | null) => {
                      msgEditorRef.current = instance;
                    }}
                    onChange={(content: string) => {
                      updatePortalAlert('Message', content);
                    }}
                    value={portalAlert?.Message}
                    height="270"
                    name={'Message'}
                    key={colourScheme}
                  />
                </div>
              </div>
            )}
          </>
        </div>
      </div>
    </div>
  );
}

export { PortalAlertContent };
