const usPhoneNumberFormat = /(\d{3})(\d{3})(\d{4}).*/;

const allPhnNumFormat = /(\d{3})(\d{3})(\d{7})/;

const usPhnValidationMsg =
  'Please enter a valid phone number (e.g., xxx-xxx-xxxx)';

const otherPhnValidationMsg =
  'Please enter a valid phone number between 8 and 15 digits';

export {
  usPhoneNumberFormat,
  usPhnValidationMsg,
  otherPhnValidationMsg,
  allPhnNumFormat,
};
