import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery, useQueryClient } from 'react-query';

import { Button, Spinner, CustomCancelModal } from 'src/ui/components';
import { PortalAlertContent } from './components/AlertContent';

import { useAuth, useTitle } from 'src/hooks';
import { useSavePortalAlert } from './api/usePostPortalAlerts';

import { usePortalAlertStore } from './store/portalAlertStore';
import { toCapitalise } from 'src/utils/common';
import { successMsg, updateMsg } from './utils/constants';
import { formatToUtcWithOffset } from 'src/utils/datepickerHelper';
import { showErrorToast, showSuccessToast } from 'src/utils/ToastNotification';

export default function AddEditPortalAlert(): ReactElement {
  const navigate = useNavigate();
  const { id } = useParams();
  const { token, tokenType } = useAuth();
  const queryClient: any = useQueryClient();

  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);

  const getPortalAlert = usePortalAlertStore(
    useCallback(state => state?.getPortalAlert, []),
  );

  const isLoading = usePortalAlertStore(
    useCallback(state => state?.isLoading, []),
  );

  const userMode = usePortalAlertStore(
    useCallback(state => state?.userMode, []),
  );

  const portalAlertObj = usePortalAlertStore(
    useCallback(state => state?.PortalAlertObj, []),
  );

  const updateUserMode = usePortalAlertStore(
    useCallback(state => state?.setUserMode, []),
  );

  const resetStore = usePortalAlertStore(
    useCallback(state => state?.resetStore, []),
  );

  const isDisable = usePortalAlertStore(
    useCallback(state => state?.disableSave, []),
  );

  useQuery(
    ['get_portal_alerts', id],
    () => {
      getPortalAlert(token, tokenType, id);
    },
    {
      staleTime: Infinity,
      enabled: id !== undefined,
    },
  );

  const { mutate: savePortalAlert, isLoading: isSaving } = useSavePortalAlert();

  useEffect(() => {
    if (location?.pathname.split('/').includes('Edit'))
      updateUserMode('Edit portal alert');
    else updateUserMode('Create portal alert');
  }, [updateUserMode]);

  useTitle(toCapitalise(userMode));

  function onSaveHandler() {
    const formatedDate = formatToUtcWithOffset(portalAlertObj?.EnteredDate);

    const payloadValue = {
      ...portalAlertObj,
      EffectiveDate: formatedDate && formatedDate[0],
      ExpirationDate: formatedDate && formatedDate[1],
    };
    delete payloadValue['EnteredDate'];

    savePortalAlert(payloadValue, {
      onSuccess: () => {
        showSuccessToast({
          message: userMode === 'Edit portal alert' ? updateMsg : successMsg,
        });

        queryClient.invalidateQueries(['get_portal_alert_List'], {
          refetchActive: false,
        });

        navigate('/PortalAlert');
        resetStore();
      },
      onError: ({ ErrorMessage }) => {
        showErrorToast({ message: ErrorMessage[0] });
      },
    });
  }

  function handleCancel() {
    resetStore();
    queryClient.invalidateQueries(['get_portal_alerts'], {
      refetchActive: false,
    });
    navigate('/PortalAlert');
  }

  return (
    <main className="flex flex-1 flex-col overflow-y-auto overscroll-contain">
      <div className="flex flex-wrap items-center justify-between gap-2 border-t border-gray-200 bg-white p-2 shadow dark:border-neutral-700/50 dark:bg-neutral-800 sm:gap-4 sm:px-3 lg:px-4">
        <div className="flex flex-1 shrink-0 items-end gap-4 sm:gap-8">
          <h2 className="truncate text-lg font-semibold text-gray-950 dark:text-white sm:text-xl sm:tracking-tight">
            {userMode}
          </h2>
        </div>
      </div>
      <PortalAlertContent />

      <div className="border-t border-light-light bg-white dark:border-dark-medium dark:bg-neutral-800">
        <div className="container mx-auto flex gap-[1ch] p-2 sm:justify-end sm:p-3">
          <Button
            className="grow sm:grow-0"
            onClick={() => {
              setShowCancelModal(true);
            }}
          >
            Cancel
          </Button>
          <Button
            className="grow sm:grow-0"
            variant="primary"
            onClick={() => onSaveHandler()}
            disabled={isDisable()}
          >
            {userMode === 'Edit portal alert' ? 'Update' : 'Save'}
          </Button>
        </div>
        {showCancelModal === true && (
          <CustomCancelModal
            showCancelModal={showCancelModal}
            setShowCancelModal={setShowCancelModal}
            handleCancel={handleCancel}
          />
        )}
      </div>
      {(isSaving || isLoading) && (
        <div className=" bg-gray-900/8 absolute inset-0 z-20 grid place-content-center backdrop-blur-sm">
          <Spinner />
        </div>
      )}
    </main>
  );
}
