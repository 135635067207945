/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import clsx, { ClassValue } from 'clsx';

import 'react-datepicker/dist/react-datepicker.css';
import {
  TextFieldProps,
  inputBaseClasses,
  inputSizeClasses,
} from '../TextField';

import { minDobDate } from 'src/utils/datepickerHelper';

type DatepickerType = {
  id?: any;
  placeholder?: string;
  className?: ClassValue;
  value?: any;
  format?: string;
  onChange?: (e: any) => void;
  inputSize?: TextFieldProps['inputSize'];
  hasMaxDate?: boolean;
  disabled?: boolean;
  container?: any;
};

function CustomDatePicker({
  value,
  placeholder,
  className,
  inputSize = 'medium',
  onChange,
  hasMaxDate = false,
  disabled = false,
  container,
}: DatepickerType) {
  const getMaxDate = () => {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    return yesterday;
  };

  const userClasses = clsx(className);

  //to close date picker on scroll
  const datePickerRef = useRef<any>();
  useEffect(() => {
    const handleScroll = () => {
      if (container && container?.current && datePickerRef?.current) {
        datePickerRef?.current?.setOpen(false);
      }
    };

    if (container && container?.current) {
      container?.current.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (container && container?.current) {
        container?.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  function onKeyDownHandler(e: React.KeyboardEvent<HTMLElement>) {
    if (e.key === 'Tab') {
      datePickerRef.current.setOpen(false);
      // Find the next focusable element dynamically
      const nextElement = document?.activeElement?.nextElementSibling;
      if (nextElement) {
        // Ensure that the nextElement is an HTMLElement before calling focus
        if (nextElement instanceof HTMLElement) {
          nextElement.focus();
        }
      }
    }
  }

  return (
    <DatePicker
      selected={value}
      onChange={e => {
        onChange && onChange(e);
      }}
      dateFormat="MM/dd/yyyy"
      placeholderText={placeholder}
      className={`${userClasses} ${inputBaseClasses} ${
        inputSize === 'small'
          ? inputSizeClasses.small
          : inputSize === 'medium'
          ? inputSizeClasses.medium
          : inputSize === 'large'
          ? inputSizeClasses.large
          : ''
      }`}
      maxDate={hasMaxDate ? null : getMaxDate()}
      disabled={disabled}
      minDate={minDobDate}
      ref={datePickerRef}
      onKeyDown={e => onKeyDownHandler(e)}
    />
  );
}

export { CustomDatePicker };
