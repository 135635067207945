import { useMutation, UseMutationResult } from 'react-query';

import { useAuth } from 'src/hooks';

import { downloadTutorialFiles } from 'src/utils/urls';

import { DownloadFileType } from 'src/types/tutorialsType';

async function downloadTutorial({
  token,
  tokenType,
  documentName,
}: TVariables): Promise<any | undefined> {
  const response = await fetch(downloadTutorialFiles(documentName), {
    method: 'GET',
    headers: {
      Authorization: `${tokenType} ${token}`,
    },
  });
  return response;
}

type TResult = DownloadFileType;

type TVariables = {
  token: string;
  tokenType: string;
  documentName: string;
};

type TError = { message: string };

function useGetDownloadTutorial(): UseMutationResult<
  TResult,
  TError,
  string,
  unknown
> {
  const { token, tokenType } = useAuth();
  return useMutation(async (documentName: string) => {
    const value = await downloadTutorial({
      token,
      tokenType,
      documentName,
    });

    if (value.status === 200) return value.json();
    else {
      const errorMsg = await value.text();
      throw { message: errorMsg };
    }
  });
}

export { useGetDownloadTutorial };
