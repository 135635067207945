import React, { ReactElement, useCallback, useMemo } from 'react';

import {
  DateOfBirthPicker,
  TextField,
  inputLabelClasses,
} from 'src/ui/components';

import { useGetStoredPermissions } from 'src/hooks';

import { usePersonAlertStore } from '../../store/personAlertStore';
import { calculateAgeAsString, shouldDisableDate } from 'src/utils/common';
import { minDobDate } from 'src/utils/datepickerHelper';

import { TextFieldType, RefType } from 'src/types/textFileType';

function PersonDetails({ newPerson, refValue }: RefType): ReactElement {
  const permissions = useGetStoredPermissions();

  const updatePersonDetails = usePersonAlertStore(
    useCallback(state => state.updatePersonDetails, []),
  );

  const alertDetails = usePersonAlertStore(
    useCallback(state => state?.alertDetails, []),
  );

  const person = usePersonAlertStore(
    useCallback(state => state?.alertDetails?.Person?.PersonAlertDetails, []),
  );

  const userMode = usePersonAlertStore(
    useCallback(state => state.userMode, []),
  );

  const updateOtherDetails = usePersonAlertStore(
    useCallback(state => state.updateOtherDetails, []),
  );

  //age and birth date update function
  function updateAgeFunction(dateOfBirth: string | any) {
    updateOtherDetails('Birthdate', dateOfBirth, '');
    if (dateOfBirth !== null) {
      const age = calculateAgeAsString(dateOfBirth);
      updateOtherDetails('Age', age?.toString(), '');
    } else updateOtherDetails('Age', '', '');
  }

  function handleClose(value: any) {
    if (
      value instanceof Date &&
      !isNaN(value.getTime()) &&
      !shouldDisableDate(value)
    ) {
      return;
    } else {
      updateOtherDetails('Birthdate', null, '');
      updateOtherDetails('Age', '', '');
    }
  }

  //Enable the person details when a RM belongs to LFL.
  const isDisbleTextField = useMemo(() => {
    if (process.env.REACT_APP_THEME === 'lfl')
      return (userMode === '' && permissions?.RelationshipManager) || newPerson;
    else return newPerson && userMode === '';
  }, [newPerson, permissions?.RelationshipManager, userMode]);

  function renderTextField({
    id,
    onChangeText,
    value,
    key,
    label,
    type,
    className,
    personDetailKey,
    disabled,
    required,
    readOnly,
  }: TextFieldType): ReactElement {
    return (
      <TextField
        id={id}
        label={label}
        type={type}
        className={className}
        disabled={disabled}
        required={required}
        readOnly={readOnly}
        value={value}
        onChange={e => {
          if (
            type === 'number' &&
            (parseInt(e.target.value) <= 0 ||
              parseInt(e.target.value) > 100 ||
              e.target.value.includes('e'))
          )
            return;
          onChangeText &&
            key &&
            onChangeText(key, e.target.value, personDetailKey);
        }}
        min={0}
        max={100}
        maxLength={
          id === 'firstName' || id === 'lastName'
            ? 400
            : id === 'alternativeName'
            ? 256
            : undefined
        }
        onKeyDown={e => {
          if (type === 'number') {
            if (e.key === '-' || e.key === 'e') e.preventDefault();
          }
        }}
      />
    );
  }

  return (
    <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 sm:gap-y-2 md:grid-cols-3">
      {renderTextField({
        id: 'firstName',
        label: 'First name',
        key: 'FirstName',
        required: true,
        disabled: userMode === 'Edit' ? true : !isDisbleTextField,
        value: alertDetails?.Person?.FirstName,
        onChangeText: updatePersonDetails,
      })}

      {renderTextField({
        id: 'lastName',
        label: 'Last name',
        key: 'LastName',
        required: true,
        disabled: userMode === 'Edit' ? true : !isDisbleTextField,
        value: alertDetails?.Person?.LastName,
        onChangeText: updatePersonDetails,
      })}

      {renderTextField({
        id: 'alternativeName',
        label: 'Alternative name',
        key: 'PreferredName',
        disabled: userMode === 'Edit' ? true : !isDisbleTextField,
        value: alertDetails?.Person?.PreferredName
          ? alertDetails?.Person?.PreferredName
          : '',
        onChangeText: updatePersonDetails,
      })}

      <div>
        <label
          htmlFor="birth-date"
          className={[inputLabelClasses, 'mb-0.5'].join(' ')}
        >
          Date of Birth
        </label>
        <div className="w-full [&>*]:w-full">
          <DateOfBirthPicker
            onChange={e => updateAgeFunction(e)}
            value={person?.Birthdate}
            handleClose={() => {
              handleClose(person?.Birthdate);
            }}
            container={refValue}
            onKeyDown={e => {
              if (e.key === 'Tab') {
                if (
                  new Date(e.target.value) > new Date() ||
                  new Date(e.target.value) < minDobDate
                ) {
                  updateOtherDetails('Birthdate', null, '');
                  updateOtherDetails('Age', '', '');
                }
              }
            }}
          />
        </div>
      </div>

      {renderTextField({
        id: 'age',
        label: 'Age',
        key: 'Age',
        type: 'number',
        className: 'sm:max-w-[100px]',
        personDetailKey: '',
        value: person?.Age ? person?.Age : '',
        onChangeText: updateOtherDetails,
      })}
    </div>
  );
}

export { PersonDetails };
