import {
  useInfiniteQuery,
  UseInfiniteQueryResult,
  useQueryClient,
} from 'react-query';
import pDebounce from 'p-debounce';

import { useAuth } from 'src/hooks';

import { setListRequestData } from '../utils/payloadRequest';
import { getPersonRepo } from 'src/utils/urls';
import { pageLength } from 'src/utils/appConstants';

import {
  FilterOptionsType,
  PersonRepoType,
} from '../types/personRepoListTypes';

export async function fetchPersonRepo({
  requestData,
  pageParam,
  token,
  tokenType,
}: TVariables): Promise<TPage> {
  requestData.append('start', `${pageParam}`);

  const response = await fetch(getPersonRepo, {
    method: 'POST',
    headers: {
      Authorization: `${tokenType} ${token}`,
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: requestData,
  });
  return response.json();
}

const debouncedFetch = pDebounce(fetchPersonRepo, 500);

export type TPage = {
  data: Array<PersonRepoType>;
  draw: number;
  error: Error | null;
  isAutoRefreshEnabled: boolean;
  recordsFiltered: number;
  recordsTotal: number;
};

export type TError = { message: string };

export type TVariables = {
  requestData: any;
  token: string;
  tokenType: string;
  pageParam: any;
};

function useFetchPersonRepoList(
  filterOptions: FilterOptionsType,
): UseInfiniteQueryResult<TPage, unknown> {
  const { token, tokenType } = useAuth();
  const queryClient = useQueryClient();

  return useInfiniteQuery(
    ['get_person_repo_list', filterOptions],
    ({ pageParam = 0 }) => {
      const requestData = setListRequestData(filterOptions);

      const value = debouncedFetch({
        token,
        tokenType,
        requestData,
        pageParam,
      });

      queryClient.invalidateQueries(['get-person-details'], {
        refetchActive: false,
      });
      return value;
    },
    {
      getNextPageParam: (lastPage: TPage, allPages: Array<TPage>) => {
        if (lastPage !== null && lastPage?.data?.length === pageLength)
          return allPages?.length * pageLength;
      },
      getPreviousPageParam: (firstPage: TPage, allPages: Array<TPage>) => {
        if (firstPage !== null && firstPage?.data?.length === pageLength)
          return allPages?.length - 1 * pageLength;
      },

      staleTime: 60 * 1000,
      refetchOnWindowFocus: false,
    },
  );
}

export { useFetchPersonRepoList };
