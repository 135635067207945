const statusArray = [
  { label: 'All', value: 0 },
  { label: 'Pending', value: 1 },
  { label: 'Scheduled', value: 2 },
  { label: 'Completed', value: 3 },
  { label: 'Cancelled', value: 4 },
];

const formNameList = [
  { label: 'All', value: 0 },
  {
    label: 'Adolescent Acute Care Clinical Placement',
    value: process.env.REACT_APP_FORM1_ACCOUNTID ?? '',
  },
  {
    label: 'Hospital Followup',
    value: process.env.REACT_APP_FORM2_ACCOUNTID ?? '',
  },
];

const cancelSuccessMsg = 'Call Successfully Cancelled.';

const leaveMessage = [
  { value: -1, label: '' },
  { value: 1, label: 'Yes' },
  { value: 2, label: 'No' },
];

const warningMsg = 'Please fill the required fields.';
const pastDateMsg = 'Please note: Scheduled dates are in the past ';
const pastTimeMsg = 'Please note: Scheduled times are in the past';

const form1AccountId = process.env.REACT_APP_FORM1_ACCOUNTID ?? '';
const form2AccountId = process.env.REACT_APP_FORM2_ACCOUNTID ?? '';

const sortOptions: Array<{ id: number; label: string; value: string }> = [
  { id: 1, label: 'Submission number', value: '3' },
  { id: 2, label: 'Form name', value: '5' },
  { id: 3, label: 'First name', value: '0' },
  { id: 4, label: 'Last name', value: '1' },
  { id: 5, label: 'Submission date', value: '2' },
  { id: 6, label: 'Status', value: '4' },
];

export {
  statusArray,
  formNameList,
  cancelSuccessMsg,
  leaveMessage,
  warningMsg,
  form1AccountId,
  form2AccountId,
  pastDateMsg,
  pastTimeMsg,
  sortOptions,
};
