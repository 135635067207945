import { create } from 'zustand';

type ActivityStoreType = {
  pageParam: number;
  setPageParam: (val: number) => void;
};

export const activityStore = create<ActivityStoreType>(set => ({
  pageParam: 0,

  setPageParam: (val: number) => {
    set(() => ({
      pageParam: val,
    }));
  },
}));
