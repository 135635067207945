import React from 'react';

const situationDescriptionMsg: JSX.Element = (
  <div className="select-none space-y-4">
    <p>A simple info alert—check it out If Applicable, include:</p>
    <ul className="space-y-2 ps-5">
      <li>
        Information about suicide or violence potential (describe history,
        weapon access, etc)
      </li>
      <li>
        Other information such as mental health diagnosis, recent
        hospitalization, medication, drug/alcohol issues, health condition,
        living situation etc.
      </li>
    </ul>
  </div>
);

const telePhoneIntevensionMsg: JSX.Element = (
  <div className="select-none space-y-2">
    <p>
      {`${
        process.env.REACT_APP_THEME === 'proteus'
          ? `Protocall's`
          : `LinesForLife's`
      }`}{' '}
      standard telephone intervention procedures include crisis assessment and
      stabilization.
    </p>
    <p>
      Describe additional desired interventions such as: limiting number or
      duration of calls, redirecting client to own clinician or self-care plan,
      referring to specific facility, contacting on-call, providing specific
      referral or other information, etc.
    </p>
  </div>
);

const calllimitAlertMsg: JSX.Element = (
  <div className="select-none space-y-4">
    <p>
      The Call Limit feature is used to track the number of calls a caller has
      made in a 24-hour time period, giving the call taker information about the
      caller&apos;s call patterns in order to possibly limit their calls through
      manual intervention. A few notes about this feature:
    </p>
    <ul className="space-y-2 ps-5">
      <li>
        The call counted is manually incremented by the call taker at a prompt
        at the end of their call.
      </li>
      <li>
        <p>
          {`"Reset Time" is the time that the call counter will reset every day.`}
        </p>
      </li>
      <li>
        <p>{`If the caller has more than one person alert, setting a Call
							Limit here will associate a limit to all their person alerts.
							The limit will not expire until the last active person alert has
							expired or the "Set Call Limit" checkbox is unchecked.`}</p>
      </li>
    </ul>
  </div>
);

export { situationDescriptionMsg, telePhoneIntevensionMsg, calllimitAlertMsg };
