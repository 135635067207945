import React, { AllHTMLAttributes } from 'react';
import clsx, { ClassValue } from 'clsx';

type NativeProps = AllHTMLAttributes<HTMLDivElement>;

type EmptyStateProps = NativeProps & {
  message?: string;
  className?: ClassValue;
};

function EmptyState({ message, className, ...rest }: EmptyStateProps) {
  const userClasses = clsx(className);
  return (
    <>
      <div
        className={`${userClasses} flex flex-col items-center justify-center gap-4`}
        {...rest}
      >
        <svg
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 647.63626 632.17383"
          style={{ width: 'min(100px, 400px)' }}
        >
          <path
            d="M411.146 142.174h-174.51a15.018 15.018 0 0 0-15 15v387.85l-2 .61-42.81 13.11a8.007 8.007 0 0 1-9.99-5.31l-127.34-415.95a8.003 8.003 0 0 1 5.31-9.99l65.97-20.2 191.25-58.54 65.97-20.2a7.99 7.99 0 0 1 9.99 5.3l32.55 106.32Z"
            className="fill-gray-300/50 dark:fill-neutral-800"
          />
          <path
            d="m449.226 140.174-39.23-128.14a16.994 16.994 0 0 0-21.23-11.28l-92.75 28.39-191.24 58.55-92.75 28.4a17.015 17.015 0 0 0-11.28 21.23l134.08 437.93a17.027 17.027 0 0 0 16.26 12.03 16.79 16.79 0 0 0 4.97-.75l63.58-19.46 2-.62v-2.09l-2 .61-64.17 19.65a15.015 15.015 0 0 1-18.73-9.95L2.666 136.734a14.98 14.98 0 0 1 9.95-18.73l92.75-28.4 191.24-58.54 92.75-28.4a15.156 15.156 0 0 1 4.41-.66 15.015 15.015 0 0 1 14.32 10.61l39.05 127.56.62 2h2.08Z"
            className="fill-gray-400/50 dark:fill-dark-medium"
          />
          <path
            d="M122.68 127.82a9.016 9.016 0 0 1-8.61-6.366l-12.88-42.072a8.999 8.999 0 0 1 5.97-11.24L283.1 14.278a9.009 9.009 0 0 1 11.24 5.971l12.88 42.072a9.01 9.01 0 0 1-5.97 11.241l-175.94 53.864a8.976 8.976 0 0 1-2.63.395Z"
            className="fill-primary"
          />
          <circle cx="190.154" cy="24.955" r="20" className="fill-primary" />
          <circle
            cx="190.154"
            cy="24.955"
            r="12.665"
            className="fill-white dark:fill-black"
          />
          <path
            d="M602.636 582.174h-338a8.51 8.51 0 0 1-8.5-8.5v-405a8.51 8.51 0 0 1 8.5-8.5h338a8.51 8.51 0 0 1 8.5 8.5v405a8.51 8.51 0 0 1-8.5 8.5Z"
            className="fill-gray-300/50 dark:fill-neutral-700"
          />
          <path
            d="M447.136 140.174h-210.5a17.024 17.024 0 0 0-17 17v407.8l2-.61v-407.19a15.018 15.018 0 0 1 15-15h211.12Zm183.5 0h-394a17.024 17.024 0 0 0-17 17v458a17.024 17.024 0 0 0 17 17h394a17.024 17.024 0 0 0 17-17v-458a17.024 17.024 0 0 0-17-17Zm15 475a15.018 15.018 0 0 1-15 15h-394a15.018 15.018 0 0 1-15-15v-458a15.018 15.018 0 0 1 15-15h394a15.018 15.018 0 0 1 15 15Z"
            className="fill-gray-400/50 dark:fill-dark-medium"
          />
          <path
            d="M525.636 184.174h-184a9.01 9.01 0 0 1-9-9v-44a9.01 9.01 0 0 1 9-9h184a9.01 9.01 0 0 1 9 9v44a9.01 9.01 0 0 1-9 9Z"
            className="fill-primary"
          />
          <circle cx="433.636" cy="105.174" r="20" className="fill-primary" />
          <circle
            cx="433.636"
            cy="105.174"
            r="12.182"
            className="fill-white dark:fill-black"
          />
        </svg>
        {message ? (
          <span className="ml-8 inline-block text-center text-lg opacity-50">
            {message}
          </span>
        ) : (
          ''
        )}
      </div>
    </>
  );
}

export { EmptyState };
