import React, {
  ReactElement,
  Fragment,
  useRef,
  useCallback,
  useEffect,
} from 'react';
import {
  FunnelIcon,
  ArrowPathIcon,
  ArrowDownTrayIcon,
  InformationCircleIcon,
} from '@heroicons/react/20/solid';
import { Disclosure } from '@headlessui/react';

import { Button, EmptyState, Skeleton, Spinner } from 'src/ui/components';
import { ActivityLogFilter, ActivityLogItem } from './components';

import { useTitle } from 'src/hooks';
import { useGetActivityLog, useDownloadCsv } from './api';

import { useActivityLogFilterReducer } from './utils/useFilterReducer';
import {
  localTzMsg,
  adminNoteMsg,
  pageLength,
  downloadCsvMsg,
} from 'src/utils/appConstants';
import { showSuccessToast } from 'src/utils/ToastNotification';

import { ActivityLogListType } from './types/activityLogType';

export default function ActivityLog(): ReactElement {
  useTitle('View Activity Log');

  const tbodyRef = useRef() as React.MutableRefObject<HTMLTableSectionElement>;

  const { filterOptions, filterCount, resetFilters, setValues } =
    useActivityLogFilterReducer();

  const { mutate: onDownload, isLoading: isDownloading } = useDownloadCsv();

  const {
    data,
    isLoading,
    isFetching,
    isRefetching,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  } = useGetActivityLog(filterOptions);

  const handleScroll = useCallback(
    (e: any) => {
      if (tbodyRef.current === e.target) {
        const bottom =
          e.target.clientHeight - 10 <
            e.target.scrollHeight - e.target.scrollTop &&
          e.target.scrollHeight - e.target.scrollTop <
            e.target.clientHeight + 10;
        if (bottom) {
          hasNextPage && fetchNextPage();
        }
      }
    },
    [fetchNextPage, hasNextPage],
  );

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, true);
    return () => {
      window.removeEventListener('scroll', handleScroll, true);
    };
  }, [handleScroll]);

  //download functionality
  function downloadAsCsv() {
    const params = {
      filterOptions: filterOptions,
      pageParam: pageLength,
    };
    onDownload(params, {
      onSuccess: () => {
        showSuccessToast({
          message: downloadCsvMsg,
        });
      },
    });
  }

  function renderHeader() {
    return (
      <Disclosure>
        {({ open }) => (
          <>
            <div className="flex items-center justify-between gap-4 border-t border-gray-200 bg-white px-2 py-1 shadow dark:border-neutral-700/50 dark:bg-neutral-800 sm:px-3 lg:px-4">
              <div className="flex min-w-0 flex-1 items-end gap-4 sm:gap-8">
                <h2 className="truncate text-lg font-semibold text-gray-950 dark:text-white sm:text-xl sm:tracking-tight">
                  Activity log
                </h2>
                <div className="mt-1 flex items-center gap-4 sm:mt-0">
                  <div className="relative inline-flex">
                    <Disclosure.Button
                      as={Button}
                      size="small"
                      variant={open ? 'primary' : 'subtle'}
                    >
                      <FunnelIcon
                        className="-ml-0.5 h-5 w-5"
                        aria-hidden="true"
                      />
                      <span className="hidden sm:inline-block">Filter</span>
                    </Disclosure.Button>
                    <span className="absolute right-0 top-0 -mr-1.5 -mt-1.5 flex h-5 w-5">
                      {filterCount > 0 ? (
                        <span
                          className={`relative inline-flex h-5 w-5 items-center justify-center rounded-full bg-red-600 text-xs font-bold leading-none text-white`}
                        >
                          {filterCount}
                        </span>
                      ) : null}
                    </span>
                  </div>

                  <Button
                    variant="subtle"
                    size="small"
                    onClick={() => resetFilters()}
                  >
                    <ArrowPathIcon
                      className="-ml-0.5 h-5 w-5"
                      aria-hidden="true"
                    />
                    <span className="hidden sm:block">Reset</span>
                  </Button>
                </div>
              </div>

              {data && data.pages[0].data?.length ? (
                <div className="flex gap-4">
                  <Button
                    variant="subtle"
                    size="small"
                    onClick={() => downloadAsCsv()}
                  >
                    <ArrowDownTrayIcon
                      className="-ml-0.5 h-5 w-5"
                      aria-hidden="true"
                    />
                    <span className="hidden sm:block">Download as CSV</span>
                  </Button>
                </div>
              ) : null}
            </div>

            <ActivityLogFilter
              filterOptions={filterOptions}
              setValues={setValues}
              resetFilters={resetFilters}
            />
          </>
        )}
      </Disclosure>
    );
  }

  function renderInfoText() {
    return (
      <>
        <p className="flex min-w-0 flex-wrap items-center justify-center gap-x-4 gap-y-1 border-t  bg-blue-50 p-1 text-center text-xs font-medium  text-sky-700 shadow dark:border-neutral-700 dark:bg-neutral-800 dark:text-sky-300 md:justify-end ">
          <span className="inline-flex gap-1">
            <InformationCircleIcon
              className="-ml-0.5 hidden h-4 w-4 shrink-0 md:inline-block"
              aria-hidden="true"
            />
            {localTzMsg}
          </span>
          <span className="hidden divide-x self-stretch sm:inline-block">
            |
          </span>

          <span className="inline-flex gap-1">
            <InformationCircleIcon
              className="-ml-0.5 hidden h-4 w-4 shrink-0 md:inline-block"
              aria-hidden="true"
            />
            {adminNoteMsg}
          </span>
        </p>
      </>
    );
  }

  return (
    <main className="flex flex-1 flex-col overflow-y-auto overscroll-contain">
      {renderHeader()}
      {renderInfoText()}
      <div
        className="relative flex-1 space-y-2 overflow-y-scroll px-2 py-2 sm:px-3 sm:py-3 lg:px-4 lg:py-4"
        ref={tbodyRef}
      >
        {!isLoading && data?.pages ? (
          <>
            {data?.pages.map((page: any, index: number) => (
              <Fragment key={`${page} - ${index + 1}`}>
                {page?.data && page?.data?.length ? (
                  page?.data.map(
                    (activityLog: ActivityLogListType, indx: number) => (
                      <ActivityLogItem
                        key={`${activityLog.Id}-${indx + 1}`}
                        activityLog={activityLog}
                      />
                    ),
                  )
                ) : (
                  <div className="absolute inset-0 grid place-content-center">
                    <EmptyState message="No information available" />
                  </div>
                )}
              </Fragment>
            ))}
          </>
        ) : (
          (isLoading || isFetching) && <Skeleton count={8} />
        )}
        {(isFetchingNextPage || isRefetching || isDownloading) && (
          <div className="fixed inset-0 z-20 grid place-content-center">
            <Spinner size="large" />
          </div>
        )}
      </div>
    </main>
  );
}
